import { Api } from 'api/Api';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback, useContext } from 'react';
import { useAuthUser } from 'react-auth-kit';

/** Updates current user profile in AppContext by reloading data from server */
export const useSetUserProfile = () => {
  const { appContextDispatch } = useContext(AppContext);
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const setUserProfile = useCallback(
    async (sellerCompanyId: number | null) => {
      const user = await Api.getUser(
        currentUser.companyId,
        currentUser.id,
        sellerCompanyId
      );

      appContextDispatch({
        type: 'USER_SET_PROFILE',
        userProfile: user,
      });

      return user;
    },
    [appContextDispatch, currentUser.companyId, currentUser.id]
  );

  return setUserProfile;
};

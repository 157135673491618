import 'bootstrap/dist/css/bootstrap.min.css';
import { Trash } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { SellerApi } from 'api/SellerApi';
import { getAllStocksSharedToPartners } from 'components/seller/settings/stocks/StocksSharedToPartners/utilsStocksSharedToPartners';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  stock: StocksSharedToPartnersModel;
  currentUser: LoginResponseUserModel;
  isDisabled: boolean;
  setStocks: (values: StocksSharedToPartnersModel[]) => void;
};

export const RemoveSharedStock = ({
  stock,
  currentUser,
  isDisabled,
  setStocks,
}: PropTypes) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { t } = useTranslation('components');

  const handleStopSharingStock = async () => {
    toast.dismiss();
    await SellerApi.deleteStockSharedToPartner(currentUser.companyId, stock.id);
    handleClose();

    getAllStocksSharedToPartners(currentUser, setStocks);

    toast.info(t('stockSettings.Stock deleted'));
  };

  return (
    <>
      <Button
        variant='btn btn-eleczap-danger-noBorder'
        disabled={isDisabled}
        onClick={handleShow}
      >
        <Trash />
      </Button>
      <Modal
        size='sm'
        show={show}
        onHide={handleClose}
        id='buyerUserDeactivate'
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('stockSettings.hd_RemoveSharedStock')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans
            i18nKey={t('stockSettings.inf_YourSharedStocks_RemoveStock', {
              stockName: stock.stockName,
              partnerName: stock.partnerCompanyName,
            })}
          ></Trans>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='danger' onClick={handleStopSharingStock}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

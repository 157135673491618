import { SellerApi } from 'api/SellerApi';
import {
  InquiryDisabled,
  InquiryNoReplySeller,
  InquiryOfferSent,
  InquirySending,
  OfferExpired,
} from 'assets/icons/StatusIcons';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  InquiryModel,
  InquiryRowsModel,
} from 'models/seller/documents/InquiryModels';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Col, Form, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import './Inquiry.css';

type UrlParamsType = {
  inquiryId: string;
};

export const Inquiry = () => {
  const [inquiry, setInquiry] = useState<InquiryModel>({} as InquiryModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const { t } = useTranslation('components');
  const validateUnitExists = useValidateUnitExists();

  let { inquiryId } = useParams<UrlParamsType>();

  useEffect(() => {
    setIsLoading(true);
    const getInquiry = async (): Promise<void> => {
      const response = await SellerApi.getInquiry(
        currentUser.companyId,
        parseInt(inquiryId),
        userLocaleId
      );

      setInquiry(response);
      setIsLoading(false);
    };
    getInquiry();
  }, [currentUser.companyId, inquiryId, userLocaleId]);

  const columns: TableColumn<InquiryRowsModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      sortable: true,
      width: '60px',
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode,
      cell: (row) =>
        row.productId === null ? (
          <span>{row.productCode}</span>
        ) : (
          <Link
            to={`/seller/product-details/${row.productId}`}
            className='btn-link-bold'
          >
            {row.productCode}
          </Link>
        ),
      sortable: true,
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name,
      sortable: true,
      grow: 6,
    },
    {
      name: t('documents.lbl_Qty'),
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          <span>
            {row.quantity} <small>{validateUnitExists(row.unit)}</small>
          </span>
        </>
      ),
      sortable: true,
      right: true,
    },
    {
      name: t('documents.lbl_ListPrice'),
      selector: (row) => row.priceWithDiscount,
      cell: (row) =>
        `${formatMonetaryValue(
          inquiry.currencyCode,
          row.priceWithDiscount,
          appState,
          row.decimalPlaces
        )}`,
      sortable: true,
      right: true,
    },
    {
      name: t('documents.lbl_TargetPrice'),
      selector: (row) => row.targetPrice,
      cell: (row) =>
        row.targetPrice !== null
          ? `${formatMonetaryValue(
              inquiry.currencyCode,
              row.targetPrice,
              appState,
              row.decimalPlaces
            )}`
          : '-',
      sortable: true,
      center: true,
    },
    {
      name: t('documents.lbl_AllowReplacements'),
      selector: (row) => row.allowReplacement,
      cell: (row) => (
        <Form.Check
          disabled
          type='switch'
          checked={row.allowReplacement}
          aria-label='radio 1'
        />
      ),
      sortable: true,
      center: true,
    },
  ];

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className='container-fluid p-2'>
        <Card>
          <Card.Header>
            <Row>
              <Col lg={4} />
              <Col className='text-center' lg={4}>
                <h2>
                  {t('documents.hd_Inquiry')} {inquiry.code}
                </h2>
              </Col>
              <Col
                className='text-center d-flex justify-content-lg-end justify-content-sm-center'
                lg={4}
              >
                <h3>
                  {inquiry.status === 'Sending' && (
                    <>
                      {t('documents.lbl_Sending')}{' '}
                      <InquirySending className='icon-ez-gray' />
                    </>
                  )}
                  {inquiry.status === 'Unsent' && (
                    <>
                      {t('documents.lbl_Unsent')}{' '}
                      <InquiryDisabled className='icon-ez-gray' />
                    </>
                  )}
                  {inquiry.status === 'NoReply' && (
                    <>
                      {t('documents.lbl_NoReply')}{' '}
                      <InquiryNoReplySeller className='icon-ez-yellow' />
                    </>
                  )}
                  {inquiry.status === 'OfferMade' && (
                    <>
                      {t('documents.lbl_OfferMade')}{' '}
                      <InquiryOfferSent className='icon-ez' />
                    </>
                  )}

                  {inquiry.status === 'Expired' && (
                    <>
                      {t('documents.lbl_Expired')}{' '}
                      <OfferExpired className='icon-ez-gray' />
                    </>
                  )}
                </h3>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_Date')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(inquiry.documentDate).format('DD.MM.YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentBy')}:
                  </Col>
                  <Col>
                    <strong>
                      {inquiry.sentByContactName}{' '}
                      {inquiry.guestCompany &&
                        ` / ${inquiry.guestCompany.contactName}`}
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'></Col>
                  <Col>
                    <Link
                      to={`/seller/a-customer?id=${inquiry.buyerCompanyId}`}
                      className='btn-link-bold'
                    >
                      {inquiry.sentByCompanyName}{' '}
                      {inquiry.guestCompany &&
                        ` / ${inquiry.guestCompany.companyName}`}
                    </Link>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row className='flex-nowrap'>
                  <Col className='text-end pe-0 text-nowrap'>
                    <Row className='mb-0'>
                      <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                    </Row>
                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_MyInquiryNumber')}:</Col>
                    </Row>
                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_OfferDeadline')}:</Col>
                    </Row>
                    <Row className='mb-0'>
                      <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                    </Row>
                  </Col>

                  <Col
                    className='pe-3 d-flex flex-column'
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        <span className='btn-link-bold-no-link'>
                          {inquiry.projectName}
                        </span>
                      </Col>
                    </Row>
                    <Row className='mb-0'>
                      <span className='text-start document-header-right-col'>
                        {inquiry.customerInquiryCode !== null &&
                        inquiry.customerInquiryCode !== '' ? (
                          <strong>{inquiry.customerInquiryCode}</strong>
                        ) : (
                          <br />
                        )}
                      </span>
                    </Row>
                    <Row className='mb-0'>
                      <span className='text-start document-header-right-col'>
                        {inquiry.requestedDeliveryDate !== null ? (
                          <strong>
                            {dayjs(inquiry.requestedDeliveryDate).format(
                              'DD.MM.YYYY'
                            )}
                          </strong>
                        ) : (
                          <br />
                        )}
                      </span>
                    </Row>
                    <Row className='mb-0'>
                      <span className='text-start document-header-right-col d-flex flex-column text-nowrap'>
                        <strong>{inquiry.deliveryAddressLine1}</strong>
                        <strong>{inquiry.deliveryAddressLine2}</strong>
                        <strong>{inquiry.deliveryAddressLine3}</strong>
                      </span>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='my-5'>
              <DataTable
                noDataComponent={
                  <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
                }
                columns={columns}
                data={inquiry.rows}
              />
            </Row>

            <Row className='justify-content-between'>
              <Col lg={6}>
                <Form.Group className='mb-3' controlId='comments'>
                  <Form.Label>{t('common.lbl_Comments')}</Form.Label>
                  <Form.Control
                    readOnly
                    as='textarea'
                    rows={4}
                    value={inquiry.comment}
                  />
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Row className='mt-3'>
                  <Col>
                    <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                  </Col>
                  <Col>
                    <h3 className='text-end'>
                      {useFormatMonetaryValue(
                        inquiry.currencyCode,
                        inquiry.total
                      )}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-end'>
                    {t('common.inf_TotalPriceSubtext')}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

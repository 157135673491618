import { useQueryClient } from '@tanstack/react-query';
import { BuyerApi } from 'api/BuyerApi';
import { ProjectSupplierTotalRow } from 'components/buyer/project/ProjectSupplierTotalRow';
import { ProjectOrderHeader } from 'components/buyer/projectOrder/ProjectOrderHeader/ProjectOrderHeader';
import { ProjectOrderProductsGridGrouped } from 'components/buyer/projectOrder/ProjectOrderProductsGrid/ProjectOrderProductsGridGrouped';
import { AppContext } from 'contexts/user/AppContext';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { useUserCanOrder } from 'hooks/useUserCanOrder';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import {
  OrderCreateForSessionModel,
  OrderRowModel,
} from 'models/buyer/documents/OrderModels';
import { SumForEachSupplier } from 'models/buyer/project/ProjectModels';
import {
  ProjectOrderModel,
  ProjectOrderRowGroupedModel,
  ProjectOrderRowModel,
} from 'models/buyer/projectOrder/ProjectOrderModels';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { SellerCreditInfoModel } from 'models/buyer/suppliers/SellerCreditInfoModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  useFormikHookForProjectOrder,
  useGetProjectOrderDetails,
} from 'pages/buyer/ProjectOrder/hooksProjectOrder';
import { getAllProjectOrderRows } from 'pages/buyer/ProjectOrder/utilsProjectOrder';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { CartFill, Trash } from 'react-bootstrap-icons';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { getUrlParams } from 'utils/getUrlParams';
import './projectOrder.css';

type UrlParamsType = {
  projectId: string;
  projectOrderId: string;
};

type PropTypes = {
  passedProjectId?: number | null;
  passedProjectOrderId?: number | null;
  handleHide?: () => void;
};

export const ProjectOrder = ({
  passedProjectId,
  passedProjectOrderId,
  handleHide,
}: PropTypes) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectDetails, setProjectDetails] = useState<ProjectOrderModel>(
    {} as ProjectOrderModel
  );
  const [creditInfo, setCreditInfo] = useState<SellerCreditInfoModel>(
    {} as SellerCreditInfoModel
  );
  const [dataSource, setDataSource] = useState<ProjectOrderRowGroupedModel[]>(
    []
  );
  const [isError, setIsError] = useState<boolean>(true);
  const [canOrder, cannotOrderExplanation] = useUserCanOrder();

  const [comment, setComment] = useState<string>('');

  const [showDisabledRows, setShowDisabledRows] = useState<boolean>(true);
  const canSeePrices = useUserCanSeePrices();

  const queryClient = useQueryClient();

  let { projectId, projectOrderId } = useParams<UrlParamsType>();

  if (passedProjectId && passedProjectOrderId) {
    projectId = passedProjectId.toString();
    projectOrderId = passedProjectOrderId.toString();
  }

  const isQuickOrder = getUrlParams('isQuickOrder');

  const history = useHistory();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState, appContextDispatch } = useContext(AppContext);

  const { t } = useTranslation('components');
  const setUserProfile = useSetUserProfile();

  const getDataSource = useCallback(async () => {
    if (appState.userProfile?.currentProject?.orderProjectId) {
      const data = await getAllProjectOrderRows(
        appState.userProfile?.currentProject?.id,
        appState.userProfile?.currentProject?.orderProjectId,
        appState.buyerCurrentSuppliers,
        currentUser
      );
      setDataSource(data);

      appContextDispatch({
        type: 'BUYER_SET_CURRENT_ORDER_PROJECT_ROWS',
        currentOrderProjectRows: data.length,
      });

      const errorClassName = document.querySelectorAll('.supplier-qty-error');
      setIsError(errorClassName.length !== 0);
    }
  }, [
    appContextDispatch,
    appState.buyerCurrentSuppliers,
    appState.userProfile?.currentProject?.id,
    appState.userProfile?.currentProject?.orderProjectId,
    currentUser,
  ]);

  const formik = useFormikHookForProjectOrder(
    currentUser,
    projectDetails,
    setProjectDetails,
    parseInt(projectId),
    parseInt(projectOrderId),
    t
  );

  useGetProjectOrderDetails(
    currentUser,
    setProjectDetails,
    setIsLoading,
    parseInt(projectId),
    parseInt(projectOrderId)
  );

  let sumForEachSupplier: SumForEachSupplier[] = [];

  const prepareOrder = useCallback(
    (supplier: BuyerCurrentSuppliers, data, orders, marketplaceId) => {
      let order: OrderCreateForSessionModel = {} as OrderCreateForSessionModel;

      order.project = projectDetails;
      order.paymentTerm = creditInfo.paymentTerm;
      order.deliveryTerm = creditInfo.deliveryTerm;

      order.parentProjectId = parseInt(projectId);

      /* Get attachment IDs in an array */
      const attachmentIds = projectDetails.attachments?.map(
        (attachment) => attachment.id
      );

      order.attachmentIds = attachmentIds;

      let suppliersOrderRows: OrderRowModel[] = [];
      let filteredRowsBySupplier: ProjectOrderRowGroupedModel[] = [];

      for (let idx = 0; idx < data.length; ++idx) {
        const rows = data[idx].rows.filter(
          (r: ProjectOrderRowModel) => r.sellerId === supplier.value
        );
        if (rows.length > 0) {
          filteredRowsBySupplier.push({
            productCode: data[idx].productCode,
            rows: rows,
          });
        }
      }

      filteredRowsBySupplier.forEach((topRow) => {
        topRow.rows.forEach((row) => {
          const priceToInsert =
            row.productId === null
              ? null
              : [...row.moqPrices]
                  .reverse()
                  .find((moq) => row.supplierQuantity >= moq.moq)?.price ||
                row.moqPrices[0].price;

          const priceWithDiscountToInsert =
            row.productId === null
              ? null
              : [...row.moqPrices]
                  .reverse()
                  .find((moq) => row.supplierQuantity >= moq.moq)?.price ||
                row.moqPrices[0].price;

          const total =
            row.productId === null
              ? null
              : row.supplierQuantity *
                ([...row.moqPrices]
                  .reverse()
                  .find((moq) => row.supplierQuantity >= moq.moq)?.price ||
                  row.moqPrices[0].price);
          const orderRow: OrderRowModel = {
            id: row.id,
            productId: row.productId,
            rowNo: row.rowNo,
            productCode: row.productCode,
            name: row.name,
            quantity: row.supplierQuantity,
            unit: row.unit,
            price: priceToInsert,
            priceWithDiscount: priceWithDiscountToInsert,
            deliveries: [],
            discount: 0,
            total,
            requestedDeliveryTime: '',
            deliveryDate: '',
            deliveredCount: 0,
            invoicedCount: 0,
            decimalPlaces: row.moqPrices[0].decimalPlaces,
            isReplaced: false,
            initialCode: null,
            initialName: null,
          };
          orderRow.quantity > 0 && suppliersOrderRows.push(orderRow);
        });
      });

      let sum = 0;

      if (isQuickOrder) {
        filteredRowsBySupplier.forEach((row) => {
          row.rows.forEach((x) => {
            sum += x.totalPrice;
          });
        });
      } else {
        sum =
          sumForEachSupplier.find(
            (seller) => seller.sellerId === supplier.value
          )?.totalSum ?? 0;
      }

      order.totalSum = sum;
      order.currencyCode =
        filteredRowsBySupplier[0]?.rows[0].moqPrices[0]?.currencyCode;

      order.sentId = null;

      order.rows = suppliersOrderRows;

      order.isExternalSeller = supplier.isExternalSeller;
      order.isSubSeller = supplier.isSubSeller;

      order.sellerCompanyId = supplier.value;
      order.sellerCompanyName = supplier.name;
      order.marketplaceId = supplier.isSubSeller ? marketplaceId : null;

      order.requestedDeliveryTime = projectDetails.requestedDeliveryDate;
      order.deliveryAddressId = projectDetails.deliveryAddressId;

      order.rows.length > 0 && orders.push(order);
    },
    [
      creditInfo.deliveryTerm,
      creditInfo.paymentTerm,
      isQuickOrder,
      projectDetails,
      projectId,
      sumForEachSupplier,
    ]
  );

  const handleOrder = useCallback(async (): Promise<void> => {
    /* Map project rows to order rows */
    const data = [...dataSource];

    let orders: OrderCreateForSessionModel[] = [];

    /* Make seperate scope for each seller used in project rows */

    appState.buyerCurrentSuppliers
      ?.filter(
        (supplier) =>
          supplier.isSelected && supplier.isActive && !supplier.isMarketplace
      )
      .forEach((supplier) => {
        prepareOrder(supplier, data, orders, null);
      });

    appState.buyerCurrentSuppliers
      ?.filter((supplier) => supplier.isMarketplace && supplier.isActive)
      .forEach((supplier) => {
        supplier.subSellers
          ?.filter((subSeller) => subSeller.isSelected && subSeller.isActive)
          .forEach((subSeller) => {
            prepareOrder(
              { ...subSeller, name: `${supplier.name} / ${subSeller.name}` },
              data,
              orders,
              supplier.value
            );
          });
      });

    sessionStorage.removeItem('orders_unsent');

    sessionStorage.setItem('orders_unsent', JSON.stringify(orders));

    handleHide && handleHide();

    isQuickOrder
      ? history.push(`/buyer/order-unsent?isQuickOrder=true`)
      : history.push(`/buyer/order-unsent`);
  }, [
    appState.buyerCurrentSuppliers,
    dataSource,
    handleHide,
    history,
    isQuickOrder,
    prepareOrder,
  ]);

  const handleDelete = async () => {
    if (window.confirm(`${t('projectSettings.lbl_DeleteProject')}?`)) {
      await BuyerApi.projectOrder.deleteProjectOrder(
        currentUser.companyId,

        projectDetails.id,
        parseInt(projectId)
      );

      queryClient.invalidateQueries({ queryKey: ['deals'] });

      await setUserProfile(appState.buyerCurrentSupplierId || null);

      handleHide && handleHide();

      history.replace('/buyer/deals');
    }
  };

  appState.buyerCurrentSuppliers
    ?.filter(
      (supplier) =>
        supplier.isSelected && supplier.isActive && !supplier.isMarketplace
    )
    .forEach((seller) => {
      let sum = 0;
      let currencyCode = '';

      dataSource.forEach((row) => {
        row.rows
          .filter((r) => r.sellerId === seller.value)
          .forEach((prod) => {
            if (prod.sellerId === seller.value) {
              sum += prod.totalPrice;
              currencyCode = prod.moqPrices[0].currencyCode;
            }
          });
      });

      sumForEachSupplier.push({
        sellerId: seller.value,
        sellerName: seller.name,
        totalSum: sum,
        isExternalSeller: seller.isExternalSeller,
        isSubSeller: false,
        currencyCode,
      });
    });

  appState.buyerCurrentSuppliers
    ?.filter(
      (supplier) =>
        supplier.isSelected && supplier.isActive && supplier.isMarketplace
    )
    .forEach((seller) => {
      seller.subSellers?.forEach((subSeller) => {
        let sum = 0;
        let currencyCode = '';

        dataSource.forEach((row) => {
          row.rows
            .filter((r) => r.sellerId === subSeller.value)
            .forEach((prod) => {
              if (prod.sellerId === subSeller.value) {
                sum += prod.totalPrice;
                currencyCode = prod.moqPrices[0].currencyCode;
              }
            });
        });

        sumForEachSupplier.push({
          sellerId: subSeller.value,
          sellerName: subSeller.name,
          totalSum: sum,
          isExternalSeller: subSeller.isExternalSeller,
          isSubSeller: true,
          currencyCode,
        });
      });
    });

  const filteredData = useCallback(
    (data: ProjectOrderRowGroupedModel[]): ProjectOrderRowGroupedModel[] => {
      const filter = data.map((x) => {
        return showDisabledRows ? x : x;
        //  {
        //     productCode: x.productCode,
        //     rows: x.rows.filter((x) => !x.isDisabled),
        //   };
      });

      return filter;
    },

    [showDisabledRows]
  );

  /* If there is at least 1 enabled row with supplier quantity greater than 0 */
  const productRowsOk = useMemo(() => {
    return filteredData(dataSource).some((row) => {
      return row.rows.some(
        (r) =>
          !appState?.buyerCurrentSuppliers?.find(
            (supplier) => supplier.value === r.sellerId
          )?.isMarketplace &&
          !r.isDisabled &&
          r.supplierQuantity > 0
      );
    });
  }, [appState?.buyerCurrentSuppliers, dataSource, filteredData]);

  return !isLoading ? (
    <div className='container-fluid p-3'>
      {/* <ProjectViewGuide /> */}

      <Card className='px-2'>
        {/* User filter and search */}

        <Card.Body>
          <div
            className='pb-0 mb-0 project-product-grid-container'
            style={{ maxWidth: '1553px', margin: '0 auto' }}
          >
            <ProjectOrderHeader
              setProjectDetails={setProjectDetails}
              getDataSource={getDataSource}
              formik={formik}
              projectDetails={projectDetails}
              projectId={parseInt(projectId)}
              setShowDisabledRows={setShowDisabledRows}
              showDisabledRows={showDisabledRows}
            />
          </div>
        </Card.Body>
      </Card>

      <div
        style={{ maxWidth: '1553px', margin: '0 auto' }}
        className='d-flex flex-grow-1 flex-column justify-content-between'
      >
        <div
          style={{ height: 'fit-content' }}
          className='py-2 px-0 pb-3 mb-3 pt-0 project-product-grid-container'
        >
          <div style={{ minWidth: '950px' }} className='project-inquiry-grid'>
            <ProjectOrderProductsGridGrouped
              showDisabledRows={showDisabledRows}
              setDataSource={setDataSource}
              getDataSource={getDataSource}
              dataSource={dataSource}
              projectDetails={projectDetails}
              filteredData={filteredData}
              projectId={parseInt(projectId)}
            />
          </div>
        </div>

        <Card className='py-2 px-0 pb-3 mb-4 pt-0 project-product-grid-container'>
          <Row className='p-3 pe-4 pt-5 justify-content-between align-items-end'>
            <Col lg={6}></Col>
            <Col className='project-totals'>
              {sumForEachSupplier.map(
                (seller) =>
                  seller.currencyCode !== '' && (
                    <ProjectSupplierTotalRow seller={seller} />
                  )
              )}
              <Row>
                <Col className='text-end'>
                  {t('common.inf_TotalPriceSubtext')}{' '}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='justify-content-end'>
            <Col lg={6}></Col>
            <Col>
              <div className='d-flex flex-column align-items-end'>
                <Button
                  style={{ width: '30%' }}
                  disabled={isError || !productRowsOk || isLoading}
                  onClick={handleOrder}
                  className='btn btn-eleczap mt-1 px-3'
                >
                  <CartFill /> {t('projectSettings.lbl_Order')}
                </Button>

                <Button
                  style={{ width: '30%' }}
                  onClick={handleDelete}
                  className='btn-outline-danger mt-1 px-3'
                >
                  <Trash className='icon-ez-red' /> {t('common.btn_Discard')}
                </Button>
              </div>
            </Col>
          </Row>
        </Card>

        <Card className='py-2 px-0 pb-3 mb-4 pt-0 project-product-grid-container'></Card>
      </div>
    </div>
  ) : null;
};

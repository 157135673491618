import { useGetSupplierCompanyInfo } from 'components/buyer/supplierInfo/hooksSupplierInfo';
import { SupplierProfileCard } from 'components/buyer/supplierInfo/SupplierProfileCard';
import { getSupplierCreditInfo } from 'components/buyer/supplierInfo/utilsSupplierInfo';
import { AppContext } from 'contexts/user/AppContext';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { SumForEachSupplier } from 'models/buyer/project/ProjectModels';
import { SellerCreditInfoModel } from 'models/buyer/suppliers/SellerCreditInfoModel';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';

type PropTypes = {
  seller: SumForEachSupplier;
};

export const ProjectSupplierTotalRow = ({ seller }: PropTypes) => {
  const [creditInfo, setCreditInfo] = useState<SellerCreditInfoModel>(
    {} as SellerCreditInfoModel
  );

  const [company, setCompany] = useState<SupplierCompanyModel>(
    {} as SupplierCompanyModel
  );

  const canSeePrices = useUserCanSeePrices();

  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  useGetSupplierCompanyInfo(setCompany, currentUser, seller.sellerId);

  useEffect(() => {
    const getCreditInfo = async (): Promise<void> => {
      seller.sellerId !== null &&
        seller.sellerId !== 0 &&
        !seller.isSubSeller &&
        (await getSupplierCreditInfo(
          setCreditInfo,
          currentUser,
          seller.sellerId
        ));
    };

    getCreditInfo();
  }, [currentUser, seller.isSubSeller, seller.sellerId]);

  const popover = (
    <Popover className='p-0' id='popover-basic'>
      <div className='col-side-menu-supplier-customer'>
        <SupplierProfileCard company={company} creditInfo={creditInfo} />
      </div>
    </Popover>
  );

  return (
    <Row className=''>
      <Col className='d-flex align-items-end' style={{ maxWidth: '100px' }}>
        <h5>{t('common.lbl_Total')}</h5>
      </Col>
      <Col>
        <OverlayTrigger trigger='hover' overlay={popover} placement='left'>
          <h4 className='text-end'>
            <span className='link-eleczap'>{seller.sellerName}</span>:
          </h4>
        </OverlayTrigger>
      </Col>
      <Col style={{ maxWidth: '120px' }}>
        <h4 className='text-end'>
          {canSeePrices(
            formatMonetaryValue(seller.currencyCode, seller.totalSum, appState)
          )}
        </h4>
      </Col>
      <hr />
    </Row>
  );
};

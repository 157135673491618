import { SellerApi } from 'api/SellerApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StocksSharedWithMeArrivingToggle } from 'components/seller/settings/stocks/stocksModals/StocksSharedWithMeArrivingToggle/StocksSharedWithMeArrivingToggle';
import { StocksSharedWithMeVisibleToggle } from 'components/seller/settings/stocks/stocksModals/StocksSharedWithMeVisibleToggle/StocksSharedWithMeVisibleToggle';
import { getAllStocksSharedWithMe } from 'components/seller/settings/stocks/StocksSharedWithMe/utilsStocksSharedWithMe';
import { AnyAdminRole, useRBAC } from 'hooks/useRBAC';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksSharedWithMeModel } from 'models/shared/stocksSharedWithMe/StocksSharedWithMeModel';
import { StocksSharedWithMeUpdateModel } from 'models/shared/stocksSharedWithMe/StocksSharedWithMeUpdateModel';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import Card from 'react-bootstrap/Card';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { EditSharedStock } from '../stocksModals/EditSharedStock/EditSharedStock';
import { RemoveSharedStockWithMe } from '../stocksModals/RemoveSharedStockWithMe';
import { useGetAllStocksSharedWithMe } from './hooksStocksSharedWithMe';

export const StocksSharedWithMe = (): JSX.Element => {
  const [stocks, setStocks] = useState<StocksSharedWithMeModel[]>([]);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const hasRightsToEdit = useRBAC(AnyAdminRole);

  useGetAllStocksSharedWithMe(currentUser, setStocks);

  const { t } = useTranslation('components');

  const handleSubmit = async (
    values: StocksSharedWithMeUpdateModel,
    stock: StocksSharedWithMeModel
  ): Promise<void> => {
    toast.dismiss();
    await SellerApi.updateStockSharedWithMe(
      currentUser.companyId,
      values,
      stock.id
    );
    toast.info(t('stockSettings.inf_StockSaved'));
  };

  const handleVisibleOrArrivingChange = async (
    checked: boolean,
    stock: StocksSharedWithMeModel,
    field: 'isVisible' | 'showArriving'
  ) => {
    const updatedStock: StocksSharedWithMeUpdateModel = {
      name: stock.name,
      isVisible:
        (field === 'showArriving' && checked) || field === 'isVisible'
          ? checked
          : stock.isVisible,
      showArriving:
        (field === 'isVisible' && !checked) || field === 'showArriving'
          ? checked
          : stock.showArriving,
      isSharingAccepted: stock.isSharingAccepted,
      displayDetails: stock.displayDetails,
    };

    await handleSubmit(updatedStock, stock);
    await getAllStocksSharedWithMe(currentUser, setStocks);
  };

  const columns = [
    {
      name: t('addressSettings.lbl_Name'),
      selector: (row: StocksSharedWithMeModel) => row.name,
      sortable: true,
    },
    {
      name: t('stockSettings.lbl_Partner'),
      selector: (row: StocksSharedWithMeModel) => row.stock.companyName,
      sortable: true,
      maxWidth: '150px',
      minWidth: '150px',
    },
    {
      name: t('stockSettings.lbl_DisplayedName'),
      selector: (row: StocksSharedWithMeModel) => row.name,
      sortable: true,
    },

    {
      name: t('addressSettings.lbl_Address'),
      selector: (row: StocksSharedWithMeModel) => row.stock.fullAddress,
      sortable: true,
      right: false,
      maxWidth: '400px',
      minWidth: '400px',
    },
    {
      name: t('stockSettings.lbl_AdditionalInfo'),
      selector: (row: StocksSharedWithMeModel) => row.stock.additionalInfo,
      cell: (row: StocksSharedWithMeModel) =>
        row.isSharingAccepted ? (
          row.stock.additionalInfo
        ) : (
          <span style={{ color: 'var(--bs-red)' }}>
            {t('common.inf_InvitationPending')}
          </span>
        ),
      sortable: true,
      maxWidth: '250px',
      minWidth: '250px',
    },

    {
      name: t('addressSettings.lbl_Visible'),
      selector: (row: StocksSharedWithMeModel) => row.isVisible,
      cell: (row: StocksSharedWithMeModel) =>
        row.isSharingAccepted ? (
          <StocksSharedWithMeVisibleToggle
            handleVisibleOrArrivingChange={handleVisibleOrArrivingChange}
            row={row}
            hasRightsToEdit={hasRightsToEdit}
          />
        ) : (
          <EditSharedStock
            currentUser={currentUser}
            isDisabled={!hasRightsToEdit}
            setStocks={setStocks}
            t={t}
            stock={{ ...row, isSharingAccepted: true }}
            isAcceptingInvite
          />
        ),
      sortable: true,
      center: true,
      // maxWidth: '100px',
      // minWidth: '100px',
    },
    {
      name: (
        <span className='text-center'>
          {t('stockSettings.lbl_ShowArriving')}
        </span>
      ),
      selector: (row: StocksSharedWithMeModel) => row.showArriving,
      cell: (row: StocksSharedWithMeModel) =>
        row.isSharingAccepted ? (
          <StocksSharedWithMeArrivingToggle
            handleVisibleOrArrivingChange={handleVisibleOrArrivingChange}
            row={row}
            hasRightsToEdit={hasRightsToEdit}
          />
        ) : null,
      sortable: true,
      center: true,
      // maxWidth: '130px',
      // minWidth: '130px',
    },
    {
      name: '',
      cell: (row: StocksSharedWithMeModel) =>
        row.isSharingAccepted ? (
          <EditSharedStock
            currentUser={currentUser}
            isDisabled={!hasRightsToEdit}
            setStocks={setStocks}
            t={t}
            stock={row}
          />
        ) : null,
      center: true,
      maxWidth: '40px',
      minWidth: '40px',
    },
    {
      name: '',
      cell: (row: StocksSharedWithMeModel) => (
        <RemoveSharedStockWithMe
          currentUser={currentUser}
          isDisabled={!hasRightsToEdit}
          setStocks={setStocks}
          stock={row}
        />
      ),
      center: true,
      maxWidth: '40px',
      minWidth: '40px',
    },
  ];

  return (
    <Card className='p-3'>
      <Card.Header>
        <h4>{t('stockSettings.hd_StocksSharedWithYou')}</h4>
      </Card.Header>
      <Card.Body>
        <DataTable
          noDataComponent={
            <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
          }
          striped
          highlightOnHover
          responsive
          columns={columns}
          data={stocks}
        />
      </Card.Body>
    </Card>
  );
};

import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import { CheckCircle } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { BuyersCompaniesAndUsersDataModel } from "models/seller/customers/BuyersCompaniesAndUsersModel";
import { useTranslation } from "react-i18next";
import { SellerApi } from "api/SellerApi";
import { useAuthUser } from "react-auth-kit";
import { LoginResponseUserModel } from "models/identity/LoginResponseUserModel";

type PropsType = {
  company: BuyersCompaniesAndUsersDataModel;
  statusChanged: (id: number, isActive: boolean) => Promise<void>;
};

export const SellerBuyerDeactivate = ({ company, statusChanged }: PropsType) => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const disableBuyer = async () => {
    let result = await SellerApi.setBuyerActive(
      currentUser.companyId,
      company.id,
      false
    );

    if (result !== undefined) {
      setShow(false);
      statusChanged(company.id, false);
    }
  };

  //Modal

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation("components");

  return (
    <>
      <Button variant="btn btn-eleczap-noBorder" onClick={handleShow}>
        <CheckCircle className="status-icon-success" size={20}></CheckCircle>
      </Button>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        id="buyerUserDeactivate"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("customers.lbl_DeactivateBuyer")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("customers.inf_disable_confirm1", { company: company.name })}
          <br />
          {t("customers.inf_disable_confirm2")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("common.btn_Close")}
          </Button>
          <Button variant="danger" onClick={disableBuyer}>
            {t("common.btn_Ok")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


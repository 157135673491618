import 'bootstrap/dist/css/bootstrap.min.css';
import { UserModelById } from 'models/shared/user/UserModelById';
import { useState } from 'react';
import { XCircle } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

type ProptTypes = {
  user: UserModelById;
  isDisabled: boolean;
  t: any;
  handleStatusChange: (
    value: boolean,
    user: UserModelById,
    field: string
  ) => Promise<void>;
};

export const UserActivate = ({
  user,
  isDisabled,
  handleStatusChange,
  t,
}: ProptTypes) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleActivateUser = async () => {
    await handleStatusChange(true, user, 'status');
    handleClose();
  };

  return (
    <>
      <Button
        variant='btn btn-eleczap-noBorder'
        disabled={isDisabled}
        onClick={handleShow}
      >
        <XCircle className='status-icon-danger' size={20}></XCircle>
      </Button>

      <Modal size='sm' show={show} onHide={handleClose} id='buyerUserActivate'>
        <Modal.Header closeButton>
          <Modal.Title>{t('usersSettings.hd_ActivateUser')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('usersSettings.inf_SureToActivateUserPart1')} {user.firstName}{' '}
          {user.lastName} <br />
          {t('usersSettings.inf_SureToActivateUserPart2')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='btn btn-eleczap' onClick={handleActivateUser}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import 'bootstrap/dist/css/bootstrap.min.css';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksMyModel } from 'models/shared/stocksMy/StocksMyModel';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Share } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useFormikHookForShareStockWithPartner } from './hooksShareStockWithPartner';

type PropTypes = {
  stock: StocksMyModel;
  currentUser: LoginResponseUserModel;
  isDisabled: boolean;
  t: any;
  setStocksSharedToPartners: (values: StocksSharedToPartnersModel[]) => void;
};

export const ShareStockWithPartner = ({
  stock,
  currentUser,
  isDisabled,
  t,
  setStocksSharedToPartners,
}: PropTypes) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const formik = useFormikHookForShareStockWithPartner(
    currentUser,
    stock,
    setShow,
    t,
    setStocksSharedToPartners
  );

  return (
    <>
      <Button
        variant='btn btn-eleczap-noBorder'
        disabled={isDisabled}
        onClick={handleShow}
      >
        <Share />
      </Button>

      <Modal
        size='sm'
        show={show}
        onHide={handleClose}
        id='buyerUserDeactivate'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('stockSettings.hd_ShareStock')} {stock.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('stockSettings.inf_SharingStockPart1')}
          <br />
          <br />
          {t('stockSettings.inf_SharingStockPart2')}
          <Form>
            <Form.Group className='mb-3 mt-3' controlId='Form.partnerAddress'>
              <Form.Label className='required'>
                {t('stockSettings.plh_Email')}
              </Form.Label>
              <Form.Control
                required
                onChange={formik.handleChange}
                value={formik.values.userName}
                name='userName'
                isInvalid={formik.touched.userName && !!formik.errors.userName}
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.userName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3 mt-3' controlId='Form.priceformulaCode'>
              <Form.Label className='required'>
                {t('stockSettings.lbl_PriceListCode')}
              </Form.Label>
              <Form.Control
                required
                onChange={formik.handleChange}
                value={formik.values.priceformulaCode}
                name='priceformulaCode'
                isInvalid={
                  formik.touched.priceformulaCode &&
                  !!formik.errors.priceformulaCode
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.priceformulaCode}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Check
              name='shareArriving'
              type='switch'
              id='custom-switch'
              label={t('stockSettings.lbl_ShareArriving')}
              onChange={formik.handleChange}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='bnt btn-eleczap' onClick={formik.handleSubmit}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

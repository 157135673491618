import { SellerApi } from 'api/SellerApi';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';

export const getAllStocksSharedToPartners = async (
  currentUser: LoginResponseUserModel,
  setStocks: (values: StocksSharedToPartnersModel[]) => void
) => {
  const allStocks: StocksSharedToPartnersModel[] =
    await SellerApi.getAllStocksSharedToPartners(currentUser.companyId);

  setStocks(allStocks);
};

import { SellerApi } from 'api/SellerApi';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { BuyerCreditInfoModel } from 'models/seller/customers/BuyerCreditInfoModel';
import { BuyerExternalStocksModel } from 'models/seller/customers/BuyerExternalStocksModel';
import {
  BuyerStocksModel,
  BuyerUpdateStocksModel,
} from 'models/seller/customers/BuyerStocksModel';
import { CustomerCompanyModel } from 'models/seller/customers/CustomerCompanyModel';

export const getCustomerCompanyInfo = async (
  setCompany: (value: CustomerCompanyModel) => void,
  currentUser: LoginResponseUserModel,
  customerId: number
): Promise<void> => {
  const response = await SellerApi.getCustomerCompanyInfo(
    currentUser.companyId,
    customerId
  );

  setCompany(response);
};

export const getCustomerCreditInfo = async (
  setCreditInfo: (value: BuyerCreditInfoModel) => void,
  currentUser: LoginResponseUserModel,
  customerId: number
): Promise<void> => {
  const response = await SellerApi.getCustomerCreditInfo(
    currentUser.companyId,
    customerId
  );

  setCreditInfo(response);
};

export const getBuyerStocks = async (
  setBuyerStocks: (value: BuyerStocksModel[]) => void,
  currentUser: LoginResponseUserModel,
  customerId: number
): Promise<void> => {
  const response = await SellerApi.getBuyerStocks(
    currentUser.companyId,
    customerId
  );

  setBuyerStocks(response);
};

export const getBuyerExternalStocks = async (
  setBuyerExternalStocks: (value: BuyerExternalStocksModel[]) => void,
  currentUser: LoginResponseUserModel,
  customerId: number
): Promise<void> => {
  const response = await SellerApi.getBuyerExternalStocks(
    currentUser.companyId,
    customerId
  );

  setBuyerExternalStocks(response);
};

export const updateBuyerStock = async (
  currentUser: LoginResponseUserModel,
  customerId: number,
  stock: BuyerUpdateStocksModel,
  stockId: number
): Promise<void> => {
  await SellerApi.updateBuyerStocks(
    currentUser.companyId,
    customerId,
    stock,
    stockId
  );
};

export const updateBuyerExternalStock = async (
  currentUser: LoginResponseUserModel,
  customerId: number,
  stock: BuyerUpdateStocksModel,
  stockId: number
): Promise<void> => {
  await SellerApi.updateBuyerExternalStocks(
    currentUser.companyId,
    customerId,
    stock,
    stockId
  );
};

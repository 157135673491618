import { BuyerApi } from 'api/BuyerApi';
import { UserProfileImageUpload } from 'components/shared/settings/UserProfileImageUpload';
import { ExternalSupplierModel } from 'models/buyer/suppliers/ExternalSupplierModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  company: ExternalSupplierModel;
  supplierId: number;
};

export const ExternalSupplierProfileCard = ({
  company,
  supplierId,
}: PropTypes) => {
  const [profileImage, setProfileImage] = useState<string | null>(null);

  useEffect(() => {
    setProfileImage(company.logo);
  }, [company.logo]);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');

  const handleCompanyProfileImageUpload = async (
    currentUser: LoginResponseUserModel,
    t: any,
    setProfileImage: (value: string) => void,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    toast.dismiss();

    if (event.target.files) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        if (selectedFile.size > 500 * 1024) {
          toast.error(t('account.err_ProfileImageSizeTooBig'));
          event.target.value = '';
          return;
        }
        const data = new FormData();
        data.append('file', selectedFile);
        const imageLocation = await BuyerApi.uploadExternalSellerLogo(
          currentUser.companyId,
          supplierId,
          data
        );
        setProfileImage(imageLocation);

        toast.info(t('account.inf_ProfileImageUploaded'));
      }
    }
  };

  const handleCompanyProfileImageDelete = async (
    currentUser: LoginResponseUserModel,
    t: any,
    setProfileImage: (value: string | null) => void,
    profileImage: string | null
  ) => {
    toast.dismiss();

    if (profileImage) {
      await BuyerApi.deleteExternalSellerLogo(
        currentUser.companyId,
        supplierId
      );
      setProfileImage(null);

      toast.info(t('account.inf_ProfileImageDeleted'));
    }
  };

  const handleCompanyImageUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    await handleCompanyProfileImageUpload(
      currentUser,
      t,
      setProfileImage,
      event
    );
  };

  const handleCompanyImageDelete = async () => {
    await handleCompanyProfileImageDelete(
      currentUser,
      t,
      setProfileImage,
      profileImage
    );
  };

  return (
    <Card className='border mb-0'>
      <Card.Body>
        <Row className='justify-content-center'>
          <UserProfileImageUpload
            profileImage={profileImage}
            initials={company.initials}
            handleImageUpload={handleCompanyImageUpload}
            handleImageDelete={handleCompanyImageDelete}
          />
        </Row>

        <Row>
          <h4 className='text-center mt-4'>{company.name}</h4>
        </Row>
      </Card.Body>
    </Card>
  );
};

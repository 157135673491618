import {
  useGetBuyerExternalStocks,
  useGetBuyerStocks,
} from 'components/seller/aCustomer/hooksACustomer';
import {
  getBuyerExternalStocks,
  getBuyerStocks,
  updateBuyerExternalStock,
  updateBuyerStock,
} from 'components/seller/aCustomer/utilsACustomer';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  BuyerExternalStocksModel,
  BuyerUpdateExternalStocksModel,
} from 'models/seller/customers/BuyerExternalStocksModel';
import {
  BuyerStocksModel,
  BuyerUpdateStocksModel,
} from 'models/seller/customers/BuyerStocksModel';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Form } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getUrlParams } from 'utils/getUrlParams';

export const AcustomerStock = (): JSX.Element => {
  const [buyerStocks, setBuyerStocks] = useState<BuyerStocksModel[]>([]);
  const [buyerExternalStocks, setBuyerExternalStocks] = useState<
    BuyerExternalStocksModel[]
  >([]);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');

  const customerId = getUrlParams('id');

  useGetBuyerStocks(setBuyerStocks, currentUser, customerId);
  useGetBuyerExternalStocks(setBuyerExternalStocks, currentUser, customerId);

  const handleStockUpdate = async (
    field: string,
    check: boolean,
    id: number
  ) => {
    const index = buyerStocks.findIndex((stock) => stock.id === id);

    const stockUpdate: BuyerUpdateStocksModel = {
      isVisible: field === 'isVisible' ? check : buyerStocks[index].isVisible,
      showArriving:
        field === 'showArriving' ? check : buyerStocks[index].showArriving,
    };

    customerId !== null &&
      (await updateBuyerStock(
        currentUser,
        parseInt(customerId),
        stockUpdate,
        id
      ));

    customerId !== null &&
      (await getBuyerStocks(setBuyerStocks, currentUser, parseInt(customerId)));

    toast.success(t('stockSettings.inf_StockSaved'));
  };

  const handleExternalStockUpdate = async (
    field: string,
    check: boolean,
    id: number
  ) => {
    const index = buyerExternalStocks.findIndex((stock) => stock.id === id);

    const stockUpdate: BuyerUpdateExternalStocksModel = {
      isVisible:
        field === 'isVisible' ? check : buyerExternalStocks[index].isVisible,
      showArriving:
        field === 'showArriving'
          ? check
          : buyerExternalStocks[index].showArriving,
    };

    customerId !== null &&
      (await updateBuyerExternalStock(
        currentUser,
        parseInt(customerId),
        stockUpdate,
        id
      ));

    customerId !== null &&
      (await getBuyerExternalStocks(
        setBuyerExternalStocks,
        currentUser,
        parseInt(customerId)
      ));

    toast.success(t('stockSettings.inf_StockSaved'));
  };

  const buyerStockColumns: TableColumn<BuyerStocksModel>[] = [
    {
      name: t('stockSettings.lbl_DisplayedName'),
      selector: (row) => row.name,
      sortable: true,
      maxWidth: '15rem',
      minWidth: '15rem',
    },
    {
      name: t('addressSettings.lbl_Address'),
      selector: (row) => row.address,
      sortable: true,
    },

    {
      name: t('stockSettings.lbl_AdditionalInfo'),
      selector: (row) => row.additionalInfo,
      sortable: true,
      maxWidth: '250px',
      minWidth: '250px',
    },
    {
      name: t('addressSettings.lbl_Visible'),
      selector: (row) => row.isVisible,
      cell: (row) => (
        <Form.Check
          checked={row.isVisible}
          onChange={(event) =>
            handleStockUpdate('isVisible', event.target.checked, row.id)
          }
          type='switch'
          id='defaultAadress'
        />
      ),
      sortable: true,
      center: true,
      maxWidth: '100px',
      minWidth: '100px',
    },
    {
      name: t('stockSettings.lbl_ShowArriving'),
      selector: (row) => row.showArriving,
      cell: (row) => (
        <Form.Check
          checked={row.showArriving}
          onChange={(event) =>
            handleStockUpdate('showArriving', event.target.checked, row.id)
          }
          type='switch'
          id='defaultAadress'
        />
      ),
      sortable: true,
      center: true,
      maxWidth: '130px',
      minWidth: '130px',
    },
  ];

  const buyerExternalStockColumns: TableColumn<BuyerExternalStocksModel>[] = [
    {
      name: t('stockSettings.lbl_DisplayedName'),
      selector: (row) => row.name,
      sortable: true,
      maxWidth: '15rem',
      minWidth: '15rem',
    },
    {
      name: t('addressSettings.lbl_Address'),
      selector: (row) => row.address,
      sortable: true,
    },

    {
      name: t('stockSettings.lbl_AdditionalInfo'),
      selector: (row) => row.additionalInfo,
      sortable: true,
      maxWidth: '250px',
      minWidth: '250px',
    },
    {
      name: t('addressSettings.lbl_Visible'),
      selector: (row) => row.isVisible,
      cell: (row) => (
        <Form.Check
          checked={row.isVisible}
          onChange={(event) =>
            handleExternalStockUpdate('isVisible', event.target.checked, row.id)
          }
          type='switch'
          id='defaultAadress'
        />
      ),
      sortable: true,
      center: true,
      maxWidth: '100px',
      minWidth: '100px',
    },
    {
      name: t('stockSettings.lbl_ShowArriving'),
      selector: (row) => row.showArriving,
      cell: (row) => (
        <Form.Check
          checked={row.showArriving}
          onChange={(event) =>
            handleExternalStockUpdate(
              'showArriving',
              event.target.checked,
              row.id
            )
          }
          type='switch'
          id='defaultAadress'
        />
      ),
      sortable: true,
      center: true,
      maxWidth: '130px',
      minWidth: '130px',
    },
  ];

  return (
    <>
      <Card className='p-3'>
        <Card.Header>
          <h4>{t('stockSettings.hd_MyStocks')}</h4>
        </Card.Header>
        <DataTable
          noDataComponent={
            <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
          }
          striped
          highlightOnHover
          responsive
          columns={buyerStockColumns}
          data={buyerStocks}
        />
      </Card>
      <Card className='p-3 mt-2'>
        <Card.Header>
          <h4>{t('stockSettings.hd_PartnerStocks')}</h4>
        </Card.Header>
        <DataTable
          noDataComponent={
            <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
          }
          striped
          highlightOnHover
          responsive
          columns={buyerExternalStockColumns}
          data={buyerExternalStocks}
        />
      </Card>
    </>
  );
};

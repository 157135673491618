import { useInfiniteQuery } from '@tanstack/react-query';
import { BuyerApi } from 'api/BuyerApi';
import axios from 'axios';
import { BuyerProductList } from 'components/buyer/products/Product/BuyerProductList';
import {
  ProductBreadcrumb,
  ProductBreadcrumbItem,
} from 'components/shared/products/ProductBreadcrumb';
import { AppContext } from 'contexts/user/AppContext';
import { useMergeProductsAndPricesSearchMain } from 'hooks/useMergeProductsAndPrices';
import { usePrevious } from 'hooks/usePrevious';
import { ProductListGuide } from 'introGuides/ProductListGuide';
import {
  BuyerGroupedProductListPagedResult,
  GroupedProductModel,
} from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductModel } from 'models/shared/product/ProductModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Spinner } from 'react-bootstrap';
import { CaretDownFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import './BuyerProducts.css';

type UrlParamsType = {
  classId: string;
};

export const Products = () => {
  let { classId } = useParams<UrlParamsType>();

  const [moreDealsLoading, setMoreDealsLoading] = useState<boolean>(false);

  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<string>('');
  const [selectedManufacturers, setSelectedManufacturers] = useState<string[]>(
    []
  );

  const [totalPages, setTotalPages] = useState<number>(0);

  const [pages, setPages] = useState<BuyerGroupedProductListPagedResult[]>([]);

  const [productBreadCrumbItems, setproductBreadCrumbItems] = useState<
    ProductBreadcrumbItem[]
  >([]);

  const [mounted, setMounted] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState, appContextDispatch } = useContext(AppContext);

  const { t } = useTranslation('components');

  const searchString = '';
  const pageSize = 20;

  const prevValue = usePrevious(orderBy);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const findProductsInfo = useCallback(
    async ({ pageParam = 1 }) => {
      setPageNumber(pageParam);
      let sellerIds =
        appState.buyerCurrentSuppliers === null
          ? null
          : appState.buyerCurrentSuppliers
              .filter(
                (supplier) => supplier.isSelected && !supplier.isMarketplace
              )
              .map((filteredSupplier) => filteredSupplier.value);

      appState.buyerCurrentSuppliers
        ?.filter((supplier) => supplier.isSelected && supplier.isMarketplace)
        .forEach((filteredSubSeller) =>
          filteredSubSeller.subSellers?.forEach((subSeller) =>
            sellerIds?.push(subSeller.value)
          )
        );

      if (
        appState.buyerProductsMainSearchSource &&
        typeof appState.buyerProductsMainSearchSource.cancel === 'function'
      ) {
        appState.buyerProductsMainSearchSource.cancel(
          'Operation canceled due to new request.'
        );
      }

      const source = axios.CancelToken.source();

      appContextDispatch({
        type: 'BUYER_SET_NEW_SOURCE_SEARCH_PRODUCTS',
        buyerProductsMainSearchSource: source,
      });

      // Make pagination
      const productsResponse = await BuyerApi.findProductsSimple(
        currentUser.companyId,
        sellerIds,
        searchString,
        prevValue !== orderBy ? 1 : pageParam,
        orderBy,
        pageSize,
        userLocaleId,
        false,
        selectedManufacturers,
        appState.buyerCurrentSupplierId ?? -1,
        classId
      );
      setTotalPages(productsResponse.totalPages);

      setMoreDealsLoading(false);
      setIsInitialLoading(false);

      return productsResponse;
    },
    [
      appContextDispatch,
      appState.buyerCurrentSupplierId,
      appState.buyerCurrentSuppliers,
      appState.buyerProductsMainSearchSource,
      classId,
      currentUser.companyId,
      orderBy,
      prevValue,
      selectedManufacturers,
      userLocaleId,
    ]
  );

  const getProducts = useCallback(
    async ({ pageParam = 1 }) => {
      setPageNumber(pageParam);

      let sellerIds =
        appState.buyerCurrentSuppliers === null
          ? null
          : appState.buyerCurrentSuppliers
              .filter(
                (supplier) => supplier.isSelected && !supplier.isMarketplace
              )
              .map((filteredSupplier) => filteredSupplier.value);

      appState.buyerCurrentSuppliers
        ?.filter((supplier) => supplier.isSelected && supplier.isMarketplace)
        .forEach((filteredSubSeller) =>
          filteredSubSeller.subSellers?.forEach((subSeller) =>
            sellerIds?.push(subSeller.value)
          )
        );

      const productsResponse = await BuyerApi.getProductsGrouped(
        currentUser.companyId,
        sellerIds,
        classId,
        searchString,
        prevValue !== orderBy ? 1 : pageParam,
        orderBy,
        pageSize,
        userLocaleId,
        false,
        appState.buyerCurrentSupplierId ?? -1,
        selectedManufacturers
      );

      // Process the fetched data and return it
      let breadCrumbItems: ProductBreadcrumbItem[] = [];

      if (
        productsResponse.parents !== null &&
        productsResponse.parents !== undefined
      ) {
        breadCrumbItems = productsResponse.parents.map<ProductBreadcrumbItem>(
          (x) => {
            return {
              name: x.name,
              classId: x.id,
              classLevel: x.level,
              linkType: x.linkType,
              code: x.code,
            };
          }
        );

        setproductBreadCrumbItems(breadCrumbItems);
      }

      setMoreDealsLoading(false);
      setIsInitialLoading(false);

      return productsResponse;
    },
    [
      appState.buyerCurrentSupplierId,
      appState.buyerCurrentSuppliers,
      classId,
      currentUser.companyId,
      orderBy,
      prevValue,
      selectedManufacturers,
      userLocaleId,
    ]
  );

  const productsQuery = useInfiniteQuery(
    [
      `products_info_${classId}`,
      currentUser.companyId,
      userLocaleId,
      orderBy,
      appState.buyerCurrentSuppliers,
      selectedManufacturers,
    ],
    findProductsInfo,
    {
      enabled: !!currentUser.companyId,
      staleTime: 1000 * 60 * 30,
      getNextPageParam: (lastPage, pages) => lastPage.currentPage + 1,
      onSuccess(data) {
        const clonedPages: BuyerGroupedProductListPagedResult[] = [];

        data.pages.forEach((page) => {
          const clonedPageData: GroupedProductModel[] = [];

          page.data.forEach((product) => {
            const newProducts: ProductModel[] = [];
            product.products.forEach(
              (item) =>
                (appState.buyerCurrentSuppliers?.findIndex(
                  (supplier) =>
                    supplier.value === item.sellerCompanyId &&
                    supplier.isSelected &&
                    !supplier.isMarketplace
                ) !== -1 ||
                  appState.buyerCurrentSuppliers?.findIndex((supplier) =>
                    supplier.subSellers?.some(
                      (subSeller) =>
                        subSeller.value === item.sellerCompanyId &&
                        subSeller.isSelected
                    )
                  ) !== -1) &&
                newProducts.push({
                  id: item.id,
                  code: item.code,
                  name: item.name,
                  unit: '',
                  orderStep: 0,
                  manufacturerName: item.manufacturerName ?? '',
                  image: item.image ?? '',
                  isFavorite: false,
                  productClasses: [],
                  moqPrices: item.moqPrices,
                  stockLevels: [],
                  packageSize: 0,
                  sellerCompanyName: item.sellerCompanyName,
                  sellerCompanyId: item.sellerCompanyId,
                  sellerCompanyShortName: item.sellerCompanyShortName,
                })
            );

            const productGeneralInfo: GroupedProductModel = {
              productCode: product.productCode,
              products: newProducts,
            };

            clonedPageData.push(productGeneralInfo);
          });

          clonedPages.push({
            currentPage: 1,
            totalItems: 20,
            totalPages: 1,
            data: clonedPageData,
            parents: [],
          });
        });

        setPages(clonedPages);
      },
    }
  );

  const pricesQuery = useInfiniteQuery(
    [
      `products_stocks_${classId}`,
      currentUser.companyId,
      classId,
      userLocaleId,
      orderBy,
      appState.buyerCurrentSuppliers,
      selectedManufacturers,
    ],
    getProducts,
    {
      enabled: !!currentUser.companyId,
      staleTime: 1000 * 60 * 30,
      getNextPageParam: (lastPage, pages) => lastPage.currentPage + 1,
      onSuccess(data) {
        const clonedPages: BuyerGroupedProductListPagedResult[] = [...pages];

        data.pages.forEach((page) => {
          const pageData = page.data;

          pageData.forEach((fetchedProduct) => {
            const matchedProduct = clonedPages[0].data.find(
              (product) => product.productCode === fetchedProduct.productCode
            );

            if (matchedProduct) {
              matchedProduct.products = fetchedProduct.products;
              matchedProduct.quantity = fetchedProduct.quantity;
            }
          });
        });

        setPages(clonedPages);
      },
    }
  );

  useMergeProductsAndPricesSearchMain(productsQuery, pricesQuery, setPages);

  const BuyerBreadcrumbNode = (
    <ProductBreadcrumb
      items={productBreadCrumbItems}
      productClassesRoute='/buyer/product-classes/'
      productSubClassesRoute='/buyer/product-subclasses/'
    />
  );

  const loadMoreProducts = () => {
    setMoreDealsLoading(true);
    if (
      productsQuery.data &&
      pageNumber < productsQuery.data.pages[0].totalPages
    ) {
      setPageNumber((prevState: number) => prevState + 1);
    }
    productsQuery.fetchNextPage();
    pricesQuery.fetchNextPage();
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  return productsQuery.data && productsQuery.data?.pages?.length < 1 ? (
    <Spinner className='center-spinner' animation='border' />
  ) : (
    <>
      <ProductListGuide />
      {/* {mounted &&
        ReactDOM.createPortal(
          BuyerBreadcrumbNode,
          document &&
            document.getElementsByClassName('fixed-portal-receiver')[0]
        )} */}

      <BuyerProductList
        isLoading={productsQuery.isLoading}
        breadcrumbNode={BuyerBreadcrumbNode}
        // getProducts={getProducts}
        isPricesLoading={pricesQuery.isLoading}
        setPageNumber={setPageNumber}
        setOrderBy={setOrderBy}
        setSelectedManufacturers={setSelectedManufacturers}
        selectedManufacturers={selectedManufacturers}
        items={pages}
        productDetailRoute={'/buyer/product-details'}
      />

      <div className='d-flex justify-content-center mb-7'>
        {(productsQuery.data &&
          pageNumber < productsQuery.data?.pages[0].totalPages) ||
        moreDealsLoading ? (
          <Button
            disabled={moreDealsLoading}
            onClick={loadMoreProducts}
            className='btn btn-eleczap'
          >
            {t('productSearch.btn_LoadMoreProducts')}{' '}
            {moreDealsLoading ? (
              <Spinner animation='border' size='sm' />
            ) : (
              <CaretDownFill />
            )}
          </Button>
        ) : (
          <span>{t('productSearch.inf_AllProductsLoaded')}</span>
        )}
      </div>
    </>
  );
};

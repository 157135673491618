import { ImgHTMLAttributes, useEffect, useState, ChangeEvent } from 'react';
import { Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import './EditableImageWithFallback.css';

type PropsType = ImgHTMLAttributes<any> & {
  maxFileSizekB: number;
  uploadProductImageRequirementsText: string;
  accept: string;
  handleCloseUploadModal: () => void;
  handleImageUpload: (file: File) => Promise<void>;
  show: boolean;
};

export const EditableImageUploadModal = ({
  handleCloseUploadModal,
  maxFileSizekB,
  uploadProductImageRequirementsText,
  accept,
  handleImageUpload,
  show,
}: PropsType) => {
  const { t } = useTranslation('components');
  const [file, setFile] = useState<File>();

  useEffect(() => {
    setFile(undefined);
  }, [show]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      return;
    }

    setFile(files[0]);
  };

  const handleUpload = () => {
    if (file) {
      const url = URL.createObjectURL(file);
      const img = new Image();
      img.onload = () => {
        let isValidImage = true;
        let errorMsg = '';

        /*  Validate max size */
        const maxFileSize = maxFileSizekB * 1024;
        if (file.size > maxFileSize) {
          isValidImage = false;
          errorMsg = t('editableImageWithFallback.err_ImageSizeTooBig', {
            fileSize: file.size,
            maxSize: maxFileSize,
          });
        }

        /*  Validate minimum dimensions */
        if (img.width < 300 || img.height < 300) {
          isValidImage = false;

          errorMsg =
            img.width < 300
              ? t('editableImageWithFallback.err_ImageWidthWrong', {
                  width: img.width,
                  minWidth: 300,
                })
              : t('editableImageWithFallback.err_ImageHeightWrong', {
                  height: img.height,
                  minHeight: 300,
                });
        }

        URL.revokeObjectURL(img.src);

        if (!isValidImage) {
          toast.error(
            errorMsg === ''
              ? t('editableImageWithFallback.err_ImageNotOk')
              : errorMsg
          );
          return;
        }
        handleImageUpload(file).then((x) => {
          handleCloseUploadModal();
        });
      };
      img.src = url;
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseUploadModal}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('editableImageWithFallback.hd_UploadImage')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('editableImageWithFallback.lbl_UploadImageDescription')}</p>
        <p>{uploadProductImageRequirementsText}</p>

        <InputGroup className='mb-3'>
          <label
            htmlFor='imageUpload'
            className='btn btn-primary'
            id='fileSelectBtn'
          >
            {t('editableImageWithFallback.btn_SelectFile')}
          </label>
          <FormControl
            aria-label='Example text with button addon'
            aria-describedby='basic-addon1'
            placeholder={t('editableImageWithFallback.plh_NoFileSelected')}
            value={file?.name}
          />
        </InputGroup>
        <Form.Group className='mb-3'>
          <input
            type='file'
            id='imageUpload'
            name='imageUpload'
            className='inputfile'
            accept={accept}
            onChange={handleFileChange}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleCloseUploadModal}>
          {t('common.btn_Close')}
        </Button>
        <Button
          variant='primary'
          disabled={file === undefined}
          onClick={handleUpload}
        >
          {t('common.btn_Ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/light.css';
import './css/site.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

const cache = queryClient.getQueryCache();

console.log(cache);

// cachedQueries.forEach((query) => {
//   console.log('Query key:', query.queryKey);
//   console.log('Is fetching:', query.isFetching);
//   console.log('Data:', query.state.data);
//   console.log('Error:', query.state.error);
//   console.log('Status:', query.state.status);
//   console.log('...');
// });

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </React.StrictMode>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

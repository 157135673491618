import { Order } from 'pages/buyer/Order/Order';
import { OrderUnsentContainer } from 'pages/buyer/Order/OrderUnsentContainer';
import { Offcanvas } from 'react-bootstrap';

type PropTypes = {
  orderId: number | null;
  show: boolean;
  setShow: (value: boolean) => void;
  getComparison: () => Promise<void>;
};

export const OfferComparisonDrawer = ({
  orderId,
  setShow,
  show,
  getComparison,
}: PropTypes) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Offcanvas
        style={{ zIndex: 4000, overflowY: 'auto' }}
        placement='end'
        show={show}
        onHide={handleClose}
      >
        {orderId ? (
          <Order passedOrderId={orderId} />
        ) : (
          <OrderUnsentContainer getComparison={getComparison} />
        )}
      </Offcanvas>
    </>
  );
};

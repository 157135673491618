import { ApplicationSettingsApi } from 'api/ApplicationSettingsApi';
import { IdentityApi } from 'api/IdentityApi';
import { useFormik } from 'formik';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { UserOnboardingDetailsModel } from 'models/shared/userOnboarding/UserOnboardingDetailsModel';
import { UserOnboardingUpdateDetailsModel } from 'models/shared/userOnboarding/UserOnboardingUpdateDetailsModel';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* Formik init */
export const useFormikHookForOnboardingDetails = (
  setCustomerDetails: (values: UserOnboardingDetailsModel) => void,
  customer: UserOnboardingDetailsModel,
  invitationToken: string,
  nextStep: () => void,
  termsAndConditionsAccepted: boolean
): any => {
  const { t } = useTranslation('components');

  const handleSubmit = async (
    values: UserOnboardingUpdateDetailsModel
  ): Promise<void> => {
    toast.dismiss();
    await IdentityApi.updateUserDetails(invitationToken, values);
    toast.info(t('account.inf_UserSettingsSaved'));
    nextStep();
  };

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t('userSettings.err_FirstNameRequired'))
      .max(50, t('userSettings.err_FirstNameMax')),
    lastName: yup
      .string()
      .required(t('userSettings.err_LastNameRequired'))
      .max(50, t('userSettings.err_LastNameMax')),
    position: yup
      .string()
      .max(50, t('userSettings.err_PositionMax'))
      .nullable(),
    linkedInProfile: yup
      .string()
      .nullable()
      .url(t('userSettings.err_LinkedInInvalid'))
      .max(150, t('userSettings.err_LinkedInMax')),
    phone: yup.string().max(50, t('userSettings.err_PhoneMax')).nullable(),
  });

  const formik = useFormik<UserOnboardingDetailsModel>({
    initialValues: { ...customer },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedCustomer: UserOnboardingUpdateDetailsModel = {
        firstName: values.firstName,
        lastName: values.lastName,
        position: values.position,
        linkedInProfile: values.linkedInProfile,
        phone: values.phone,
        defaultView: values.defaultView,
        termsAndConditionsAccepted,
      };
      await handleSubmit(updatedCustomer);
      const newResponse = await IdentityApi.getUserDetails(invitationToken);

      setCustomerDetails(newResponse);

      nextStep();
      formik.setSubmitting(false);
    },
  });

  return formik;
};

/* Get customer onboarding details */
export const useGetUserOnboardingDetails = (
  setCustomer: (values: UserOnboardingDetailsModel) => void,
  invitationToken: string
) => {
  useEffect(() => {
    const getWelcomeDetails = async () => {
      const response = await IdentityApi.getUserDetails(invitationToken);

      setCustomer(response);
    };

    invitationToken !== '' && getWelcomeDetails();
  }, [invitationToken, setCustomer]);
};

export const useGetLocalizations = (
  setLocalizations: (values: LocalizationModel[]) => void
) => {
  useEffect(() => {
    const getLocalizations = async () => {
      const localizations = await ApplicationSettingsApi.getLocalizations();
      setLocalizations(localizations);
    };
    getLocalizations();
  }, [setLocalizations]);
};

import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { StocksMyModel } from 'models/shared/stocksMy/StocksMyModel';
import { ChangeEvent, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  handleVisibleOrArrivingChange: (
    checked: boolean,
    stock: StocksMyModel,
    field: 'isVisible' | 'showArriving'
  ) => Promise<void>;
  hasRightsToEdit: boolean;
  row: StocksMyModel;
};

export const StocksMyArrivingToggle = ({
  handleVisibleOrArrivingChange,
  hasRightsToEdit,
  row,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const [checkedValue, setCheckedValue] = useState<boolean>(row.showArriving);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = (): void => setShow(false);
  const handleShow = (): void => setShow(true);

  const { t } = useTranslation('components');

  const handleOpenConfirmation = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;

    setCheckedValue(checked);

    if (checked) {
      setIsLoading(true);

      await handleVisibleOrArrivingChange(checked, row, 'showArriving');
      setIsLoading(false);
    } else {
      handleShow();
    }
  };

  const handleConfirmation = async () => {
    setIsLoading(true);
    await handleVisibleOrArrivingChange(checkedValue, row, 'showArriving');
    setIsLoading(false);
    handleClose();
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Form.Check
        onChange={(event) => handleOpenConfirmation(event)}
        type='switch'
        checked={row.showArriving}
        disabled={!hasRightsToEdit}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          {t('stockSettings.hd_DisableArriving', { stockName: row.name })}
        </Modal.Header>
        <Modal.Body>
          {t('stockSettings.inf_YourStocks_DisableArriving', {
            stockName: row.name,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Cancel')}
          </Button>
          <Button variant='btn btn-danger' onClick={handleConfirmation}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

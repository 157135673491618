import { SellerApi } from 'api/SellerApi';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';

import { StocksSharedWithMeModel } from 'models/shared/stocksSharedWithMe/StocksSharedWithMeModel';

export const getAllStocksSharedWithMe = async (
  currentUser: LoginResponseUserModel,
  setStocks: (values: StocksSharedWithMeModel[]) => void
) => {
  const allStocks: StocksSharedWithMeModel[] =
    await SellerApi.getAllStocksSharedWithMe(currentUser.companyId);

  allStocks.forEach((stock) => {
    stock.stock.fullAddress =
      stock.stock.addressLine1 !== null
        ? `${stock.stock.addressLine1} ${stock.stock.addressLine2 || ''}, ${
            stock.stock.postalCode
          }, ${stock.stock.city}, ${stock.stock.state}, ${
            stock.stock.countryName || '-'
          }`
        : '';
  });
  setStocks(allStocks);
};

import { truncateDecimals } from 'utils/truncateDecimals';

export const formatMonetaryValue = (
  currencyCode: string | null,
  value: number | null,
  appState: any,
  decimalPlaces?: number
): string => {
  const cultureName = appState.userProfile?.localization?.cultureName;

  if (value === null) return '-';

  return Intl.NumberFormat(cultureName, {
    style: 'currency',
    currency: currencyCode || 'EUR',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces ?? 2,
  }).format(
    decimalPlaces !== undefined ? truncateDecimals(value, decimalPlaces) : value
  );
};

import { TermsAndConditionsModal } from 'components/shared/TermsAndConditionsModal';
import { useGetLocalizations } from 'hooks/useGetLocalizations';
import { BuyerRegistrationRequestModel } from 'models/buyer/buyerRegistration/BuyerRegistrationModels';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { ChangeEvent, MouseEventHandler, useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { PlayFill } from 'react-bootstrap-icons';
import { Trans, useTranslation } from 'react-i18next';
import ElecZapLogo from './../../../../media/ElecZap_new_logo.png';
import './buyerRegistrationIntro.css';

type PropTypes = {
  nextStep: MouseEventHandler;
  buyerRegistrationData: BuyerRegistrationRequestModel | null;
  setBuyerRegistrationData: (
    value: BuyerRegistrationRequestModel | null
  ) => void;
};

export const BuyerRegistrationIntro = ({
  nextStep,
  setBuyerRegistrationData,
  buyerRegistrationData,
}: PropTypes): JSX.Element => {
  const [localizations, setLocalizations] = useState<LocalizationModel[]>([]);
  const [localizationId, setLocalizationId] = useState<number>(1);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [languageTermsToShow, setLanguageTermsToShow] = useState<string>(
    '/TermsANdConditions_EN.pdf'
  );

  const { t, i18n } = useTranslation('components');

  /* Set UI language */
  useEffect(() => {
    const selectedLng = localizations.find((l) => l.id === localizationId);
    i18n.changeLanguage(selectedLng?.cultureName?.slice(0, 2));
  }, [localizations, localizationId, i18n]);

  useGetLocalizations(setLocalizations);

  useEffect(() => {
    buyerRegistrationData?.localizationId !== undefined &&
      setLocalizationId(buyerRegistrationData?.localizationId);

    if (buyerRegistrationData?.localizationId === 2)
      setLanguageTermsToShow('/TermsANdConditions_ET.pdf');
    else setLanguageTermsToShow('/TermsANdConditions_EN.pdf');
  }, [buyerRegistrationData?.localizationId]);

  const handleLocalizationChange = async (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const localeId = Number.parseInt(event.target.value);
    setLocalizationId(localeId);
    if (buyerRegistrationData !== null) {
      const clonedData: BuyerRegistrationRequestModel | null = {
        ...buyerRegistrationData,
        localizationId: localeId,
      };

      setBuyerRegistrationData(clonedData);
    }
  };

  const handleTermsAndConditionsAccepted = (
    event: ChangeEvent<HTMLInputElement> | boolean
  ) => {
    if (
      buyerRegistrationData !== null &&
      buyerRegistrationData.buyerUserRegistrationModel !== null
    ) {
      const clonedData: BuyerRegistrationRequestModel | null = {
        ...buyerRegistrationData,
        localizationId: localizationId,
        buyerUserRegistrationModel: {
          ...buyerRegistrationData.buyerUserRegistrationModel,
          termsAndConditionsAccepted:
            typeof event === 'boolean' ? event : event.target.checked,
        },
      };

      setBuyerRegistrationData(clonedData);
    }
  };

  const handleAcceptFromModal = () => {
    handleTermsAndConditionsAccepted(true);
    setShowTerms(false);
  };

  return (
    <Card className='onboarding-intro-card'>
      <Card.Body className='text-center'>
        <img className='logo-onboarding' src={ElecZapLogo} alt='eleczap-logo' />
        <h1 className='mt-3'>{t('userOnboarding.hd_WelcomeToElecZap')}</h1>

        <p>{t('userOnboarding.inf_Intro2')}</p>
        <Form.Group>
          <Form.Label column lg={3} className='text-start'>
            {t('userSettings.lbl_Language')}
            <Form.Select
              aria-label='Current active language'
              value={localizationId}
              onChange={handleLocalizationChange}
            >
              {localizations.map((e, key) => {
                return (
                  <option key={key} value={e.id}>
                    {e.cultureLongName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Label>
        </Form.Group>
        <div
          className='text-center d-flex justify-content-center'
          id='terms-and-conditions-checkbox'
        >
          <Form.Check.Input
            onChange={handleTermsAndConditionsAccepted}
            checked={
              buyerRegistrationData?.buyerUserRegistrationModel
                ?.termsAndConditionsAccepted
            }
            type='checkbox'
          />
          <Form.Check.Label className='ms-2'>
            <Trans i18nKey={t('userOnboarding.lbl_AcceptTermsAndConditions')}>
              {[
                ' I agree to the ElecZap ',
                <span
                  onClick={() => setShowTerms(true)}
                  className='icon-ez cursor-pointer'
                >
                  terms and conditions
                </span>,
              ]}
            </Trans>
          </Form.Check.Label>
        </div>
        <Button
          disabled={
            !buyerRegistrationData?.buyerUserRegistrationModel
              ?.termsAndConditionsAccepted
          }
          className='btn btn-eleczap float-end mt-3'
          onClick={nextStep}
        >
          {t('common.btn_Next')}
          <PlayFill />
        </Button>
      </Card.Body>
      <TermsAndConditionsModal
        showTerms={showTerms}
        setShowTerms={setShowTerms}
        languageTermsToShow={languageTermsToShow}
        handleAcceptFromModal={handleAcceptFromModal}
      />
    </Card>
  );
};

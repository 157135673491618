import { CreateNewProjectModal } from 'components/buyer/project/CreateNewProjectModal/CreateNewProjectModal';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FolderPlus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export const CreateNewProjectButton = () => {
  const { t } = useTranslation('components');
  const [openNewProjectModal, setOpenNewProjectModal] = useState(false);

  const handleNewProjectModalShow = () => setOpenNewProjectModal(true);
  const handleNewProjectModalClosed = () => setOpenNewProjectModal(false);

  return (
    <>
      <Button
        onClick={handleNewProjectModalShow}
        className='ms-4 text-center btn btn-eleczap d-flex flex-row align-items-center rounded'
      >
        <FolderPlus size={20} />
        <span className='ms-2'>{t('projectSettings.lbl_NewProject')}</span>
      </Button>
      <CreateNewProjectModal
        open={openNewProjectModal}
        redirectToProject
        handleClosed={handleNewProjectModalClosed}
      />
    </>
  );
};

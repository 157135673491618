import { SellerFooter } from 'layout/SellerFooter';
import { SellerHeader } from 'layout/SellerHeader';
import { Inquiry } from 'pages/seller/Inquiry/Inquiry';
import { Invoice } from 'pages/seller/Invoices/Invoice';
import { Offer } from 'pages/seller/Offer/Offer';
import { Order } from 'pages/seller/Order/Order';
import { FavoriteProducts } from 'pages/seller/Products/FavoriteProducts';
import { ProductClasses } from 'pages/seller/Products/ProductClasses';
import { ProductDetails } from 'pages/seller/Products/ProductDetails';
import { ProductSubclasses } from 'pages/seller/Products/ProductSubclasses';
import { Products } from 'pages/seller/Products/Products';
import { Search } from 'pages/seller/Products/Search';
import { Switch, useRouteMatch } from 'react-router';
import { AnySellerRole } from '../../../hooks/useRBAC';
import Customers from '../../../pages/seller/Customers/Customers';
import { SellerDealsHome } from '../../../pages/seller/SellerDealsHome';
import { SellerSettings } from '../../../pages/seller/SellerSettings';
//Customers
import { SellerSidebar } from 'layout/SellerSidebar';
import { MarketplaceEndSuppliers } from 'pages/seller/MarketplaceEndSuppliers/MarketplaceEndSuppliers';
import { SignalRBroker } from 'utils/SignalRBroker';
import { Acustomer } from './../../../pages/seller/Acustomer';
import { RBACRoute } from './RBACRoute';

/**
 * All routes for Seller view type
 * @returns All routes available for Seller view type
 */
export const SellerRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <div className='wrapper'>
      <SignalRBroker />
      <div className='main-container'>
        <div className='main-sidebar-seller'>
          <SellerSidebar />
        </div>

        <div className='main'>
          <SellerHeader />
          <div className='fixed-portal-receiver fixed-portal-receiver-sticky' />
          <Switch>
            <RBACRoute
              Component={SellerDealsHome}
              path={`${path}/deals`}
              exact
              requiredRoles={AnySellerRole}
            />
            <RBACRoute
              Component={Customers}
              path={`${path}/customers`}
              exact
              requiredRoles={AnySellerRole}
            />
            <RBACRoute
              Component={MarketplaceEndSuppliers}
              path={`${path}/marketplace-end-suppliers`}
              exact
              requiredRoles={AnySellerRole}
            />
            <RBACRoute
              Component={SellerSettings}
              path={`${path}/settings`}
              exact
              requiredRoles={AnySellerRole}
            />

            {/* Products */}
            <RBACRoute
              Component={ProductClasses}
              path={`${path}/product-classes`}
              exact
              requiredRoles={AnySellerRole}
            />
            <RBACRoute
              Component={ProductSubclasses}
              path={`${path}/product-subclasses/:classId`}
              exact
              requiredRoles={AnySellerRole}
            />
            <RBACRoute
              Component={Products}
              path={`${path}/product-classes/:classId/products`}
              requiredRoles={AnySellerRole}
            />
            <RBACRoute
              Component={Search}
              path={`${path}/search`}
              requiredRoles={AnySellerRole}
            />
            <RBACRoute
              Component={ProductDetails}
              path={`${path}/product-details/:productId`}
              exact
              requiredRoles={AnySellerRole}
            />
            <RBACRoute
              Component={FavoriteProducts}
              path={`${path}/favorite-products`}
              requiredRoles={AnySellerRole}
            />

            <RBACRoute
              Component={Acustomer}
              path={`${path}/a-customer`}
              exact
              requiredRoles={AnySellerRole}
            />
            <RBACRoute
              Component={Inquiry}
              path={`${path}/inquiry/:inquiryId`}
              exact
              requiredRoles={AnySellerRole}
            />

            <RBACRoute
              Component={Offer}
              path={`${path}/offer/:offerId`}
              exact
              requiredRoles={AnySellerRole}
            />

            <RBACRoute
              Component={Order}
              path={`${path}/order/:orderId`}
              exact
              requiredRoles={AnySellerRole}
            />
            <RBACRoute
              Component={Invoice}
              path={`${path}/invoice/:invoiceId`}
              exact
              requiredRoles={AnySellerRole}
            />
          </Switch>
          <SellerFooter />
        </div>
      </div>
    </div>
  );
};

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { ImageWithFallback } from 'components/shared/ImageWithFallback';
import NoProductImage from 'media/ElecZap_new_logo_grayscale_cropped.png';
import { SellerProductModelPagedResult } from 'models/seller/product/SellerProductModelPagedResult';
import { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { components, MenuListProps, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import './ProductSearch.css';

export type ProductOption = {
  value: number | 'AllResults';
  label: string;
  image: string;
};

type PropsType = {
  searchRoute: string;
  detailsRoute: string;
  handleSearch: (
    searchString: string
  ) => Promise<SellerProductModelPagedResult>;
};

export const SellerProductSearch = ({
  searchRoute,
  detailsRoute,
  handleSearch,
}: PropsType) => {
  const { t } = useTranslation('components');

  const [searchString, setSearchString] = useState<string>('');
  const [totalItems, setTotalItems] = useState<number>(0);

  const history = useHistory();

  const handleInputChange = (newValue: string) => {
    setSearchString(newValue);
  };

  const findProducts = async (
    searchString: string
  ): Promise<ProductOption[]> => {
    const productsResponse = await handleSearch(searchString);
    const options = productsResponse.data.map<ProductOption>((product) => ({
      value: product.id,
      label: product.name,
      image: product.image,
    }));

    setTotalItems(productsResponse.totalItems);

    return options;
  };

  const formatOptionLabel = (option: ProductOption): JSX.Element =>
    option.value !== 'AllResults' ? (
      <div key={option.value}>
        <ImageWithFallback
          fallback={NoProductImage}
          alt={option.label}
          src={option.image}
          style={{
            height: '24px',
            marginRight: '10px',
            width: '24px',
          }}
        />
        <span className='btn-link'>{option.label}</span>
      </div>
    ) : (
      <div key={option.value} className='text-center'>
        <span className='btn-link-bold'>{option.label}</span>
      </div>
    );

  const handleProductSelect = (option: SingleValue<ProductOption>): void => {
    if (option !== null) {
      const url = `${detailsRoute}/${option?.value}`;

      history.push(url);
      document.getElementsByClassName('select-product-search__input')[0] !==
        undefined &&
        (
          document.getElementsByClassName(
            'select-product-search__input'
          )[0] as HTMLElement
        ).blur();
    }
  };

  const handleAllResults = (): void => {
    const searchParam = new URLSearchParams({
      search: searchString,
    });
    const url = `${searchRoute}?${searchParam}`;

    history.push(url);
    document.getElementsByClassName('select-product-search__input')[0] !==
      undefined &&
      (
        document.getElementsByClassName(
          'select-product-search__input'
        )[0] as HTMLElement
      ).blur();
  };

  const CustomMenuList = (
    props: MenuListProps<ProductOption, false>
  ): JSX.Element => (
    <components.MenuList {...props} className='customReactSelectMenu'>
      {props.children}

      <div
        onClick={handleAllResults}
        key='AllResults'
        className='text-center btn-all-search-results btn-link-bold'
      >{`${t('productSearch.lbl_AllSearchSesults')} (${totalItems})`}</div>
    </components.MenuList>
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAllResults();
    }
  };

  // const Control = ({
  //   children,
  //   ...props
  // }: ControlProps<ProductOption, false>) => (
  //   <components.Control {...props}>
  //     <Search className='ms-2' /> {children}
  //   </components.Control>
  // );

  const handleSearchDebounced = AwesomeDebouncePromise(findProducts, 500);

  return (
    <Nav.Item style={{ maxWidth: '46%', flexGrow: 1 }} className='me-0'>
      <div
        className='input-group input-group-navbar flex-row flex-nowrap'
        id='productSearch'
      >
        <span
          className={
            'search-icon-header' + searchRoute.includes('seller')
              ? 'search-icon-header-seller'
              : ''
          }
        >
          <Search />
        </span>
        <AsyncSelect
          blurInputOnSelect
          onInputChange={handleInputChange}
          className={
            searchRoute.includes('seller')
              ? 'header-search-input d-sm-inline-block header-search-input-seller'
              : 'header-search-input d-sm-inline-block'
          }
          aria-label='Search'
          placeholder={t('productSearch.lbl_SearchProducts')}
          openMenuOnFocus={false}
          openMenuOnClick={false}
          noOptionsMessage={() => t('common.lbl_NoOptions')}
          loadingMessage={() => t('common.lbl_Loading')}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            MenuList: CustomMenuList,
            // Control,
          }}
          formatOptionLabel={formatOptionLabel}
          onKeyDown={handleKeyDown}
          onChange={handleProductSelect}
          loadOptions={handleSearchDebounced}
          controlShouldRenderValue={false}
          classNamePrefix='select-product-search'
        />
      </div>
    </Nav.Item>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { ProductQuickSearchModel } from 'models/buyer/product/ProductQuickSearchModel';
import { BuyerProjectRowInfoGroupedModel } from 'models/buyer/project/GroupedProjectModels';
import {
  ProductOptionType,
  ProjectRowBulkUpdateModel,
  ProjectRowModel,
} from 'models/buyer/project/ProjectModels';
import {
  ProjectOrderModel,
  ProjectOrderRowCreateModel,
  ProjectOrderRowFreehandCreateModel,
  ProjectOrderRowGroupedModel,
  ProjectOrderRowUpdateModel,
} from 'models/buyer/projectOrder/ProjectOrderModels';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { toast } from 'react-toastify';

export const mapQuickProductReponseToOptions = (
  response: ProductQuickSearchModel[],
  sellers: BuyerCurrentSuppliers[]
): ProductOptionType[] => {
  let options: ProductOptionType[] = [];

  /* Find out which seller has most products */
  let sellerWithMostProducts;

  sellerWithMostProducts = response.sort(
    (a, b) => b.products.length - a.products.length
  )[0];

  const mostProducts = sellerWithMostProducts?.products.length;

  /* Render products seller by seller */
  for (let j = 0; j < mostProducts; j++) {
    for (let i = 0; i < response.length; i++) {
      if (
        response[i].products[j] !== undefined &&
        response[i].products[j] !== null
      ) {
        const row = response[i].products[j];

        sellers?.findIndex(
          (supplier) => supplier.value === response[i].sellerId
        ) !== -1 &&
          options.push({
            value: row.id,
            label: row.code,
            name: row.name,
            supplierId: response[i].sellerId,
            sellerName: response[i].sellerName,
          });
      }
    }
  }

  return options;
};

export const addProductsToProjectOrder = async (
  projectRows: ProjectOrderRowCreateModel[],
  currentUser: LoginResponseUserModel,
  projectId: number,
  projectOrderId: number,
  t?: any
) => {
  toast.dismiss();
  await BuyerApi.projectOrder.createProjectOrderRows(
    currentUser.companyId,
    projectId,
    projectOrderId,
    projectRows
  );

  // toast.success(t('projectSettings.inf_ProductAdded'));
};

export const addFreehandProductToProjectOrder = async (
  projectRows: ProjectOrderRowFreehandCreateModel,
  currentUser: LoginResponseUserModel,
  projectId: number,
  projectOrderId: number,
  t: any
) => {
  toast.dismiss();
  await BuyerApi.projectOrder.createProjectOrderFreehandRows(
    currentUser.companyId,
    projectId,
    projectOrderId,
    projectRows
  );

  SuccessToast(t('projectSettings.inf_ProductAdded'));
};

export const updateProjectOrderRows = async (
  projectRows: ProjectOrderRowUpdateModel[],
  currentUser: LoginResponseUserModel,
  projectId: number,
  projectOrderId: number
) => {
  toast.dismiss();
  await BuyerApi.projectOrder.updateProjectOrderRows(
    currentUser.companyId,
    projectId,
    projectOrderId,
    projectRows
  );
};

export const deleteProjectOrderRow = async (
  projectRow: ProjectRowModel[],
  currentUser: LoginResponseUserModel,
  projectId: number,
  projectOrderId: number
) => {
  toast.dismiss();
  await BuyerApi.projectOrder.deleteProjectOrderRows(
    currentUser.companyId,
    projectId,
    projectOrderId,
    projectRow.map((x) => x.id)
  );
};

export const getAllProjectOrderRows = async (
  projectId: number,
  projectOrderId: number,
  suppliers: BuyerCurrentSuppliers[] | null,
  currentUser: LoginResponseUserModel
): Promise<ProjectOrderRowGroupedModel[]> => {
  toast.dismiss();
  const response = await BuyerApi.projectOrder.getProjectOrderRows(
    currentUser.companyId,
    projectOrderId,
    projectId
  );

  const getFlooredFixed = (v: number, d: number): number => {
    return parseFloat(
      (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d)
    );
  };

  const getStandardRounded = (v: number) => {
    return Math.round((v + Number.EPSILON) * 100) / 100;
  };

  const rowRender: BuyerProjectRowInfoGroupedModel[] = [];

  const favouriteSupplier = suppliers?.find((x) => x.isFavourite);

  response.forEach((row) => {
    let item = {
      productCode: row.productCode,
      rows: [...row.rows],
    };

    item.rows.forEach((r) => {
      r.isFavorite = favouriteSupplier?.value === r.sellerId;
      r.supplierName = r.sellerShortName ?? r.sellerName;
      r.supplierId = r.sellerId;

      let isDisabled = false;

      if (suppliers) {
        for (const supplier of suppliers) {
          if (
            !supplier.isSubSeller &&
            supplier.value === r.sellerId &&
            !supplier.isSelected
          ) {
            isDisabled = true;
            break;
          }

          if (supplier.isMarketplace && supplier.subSellers) {
            for (const subSeller of supplier.subSellers) {
              if (subSeller.value === r.sellerId && !subSeller.isSelected) {
                isDisabled = true;
                break;
              }
            }
          }
        }
      }

      r.isDisabled = isDisabled;

      r.moqPrices?.forEach((moq) => {
        moq.price = getFlooredFixed(moq.price, moq.decimalPlaces);
      });

      r.moqPrices?.sort((a, b) => a.moq - b.moq);
      if (r.moqPrices !== undefined) {
        r.totalPrice = getStandardRounded(
          r.supplierQuantity *
            ([...r.moqPrices]
              .reverse()
              .find((moq) => r.supplierQuantity >= moq.moq)?.price ||
              r.moqPrices[0].price)
        );
      }
    });

    rowRender.push(item);
  });

  return [...rowRender];
};

export const getProjectOrderDetails = async (
  projectId: number,
  projectOrderId: number,
  currentUser: LoginResponseUserModel,
  setProjectDetails: (values: ProjectOrderModel) => void
): Promise<void> => {
  const response = await BuyerApi.projectOrder.getProjectOrder(
    currentUser.companyId,
    projectOrderId,
    projectId
  );

  setProjectDetails(response);
};

export const bulkUpdateProjectRows = async (
  projectRows: ProjectRowBulkUpdateModel[],
  currentUser: LoginResponseUserModel,
  projectId: number,
  projectOrderId: number
) => {
  toast.dismiss();
  await BuyerApi.projectOrder.bulkUpdateProjectOrderRows(
    currentUser.companyId,
    projectId,
    projectOrderId,
    projectRows
  );
};

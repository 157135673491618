import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { getAllUsers } from 'components/shared/settings/Users/Users/utilsUsers';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { UserModel } from 'models/shared/user/UserModel';
import { ChangeEvent } from 'react';

type PropTypes = {
  currentUser: LoginResponseUserModel;
  setUsers: (values: UserModel) => void;
  setResetPaginationToggle: (value: boolean) => void;
  setQuery: (value: string) => void;
  t: any;
  viewType: 'Seller' | 'Buyer';
};

export const UserSearch = ({
  currentUser,
  setUsers,
  setResetPaginationToggle,
  setQuery,
  t,
  viewType,
}: PropTypes) => {
  const userType: 'buyer' | 'seller' =
    viewType === 'Buyer' ? 'buyer' : 'seller';

  const handleQueryChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const query: string = event.target.value;

    setQuery(query);

    setResetPaginationToggle(true);
    await getAllUsers(currentUser, setUsers, 1, query);
    setResetPaginationToggle(false);
  };

  const searchAPIDebounced = AwesomeDebouncePromise(handleQueryChange, 500);

  return (
    <input
      style={{ maxWidth: '175px' }}
      onChange={searchAPIDebounced}
      type='search'
      id='SearchText'
      name='SearchText'
      className='form-control d-none d-sm-inline-block'
      placeholder={t('usersSettings.plh_SearchForUsers')}
      aria-label='Search'
    />
  );
};

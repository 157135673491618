import { useEffect, useRef } from 'react';

export const usePrevious = (value: any) => {
  const prevChildrenRef = useRef();

  useEffect(() => {
    prevChildrenRef.current = value;
  }, [value]);

  return prevChildrenRef.current;
};

// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state

import { SellerApi } from 'api/SellerApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StocksMyArrivingToggle } from 'components/seller/settings/stocks/stocksModals/StocksMyArrivingToggle/StocksMyArrivingToggle';
import { StocksMyVisibleToggle } from 'components/seller/settings/stocks/stocksModals/StocksMyVisibleToggle/StocksMyVisibleToggle';
import { getAllMyStocks } from 'components/seller/settings/stocks/StocksMy/utilsStocksMy';
import { getAllStocksSharedToPartners } from 'components/seller/settings/stocks/StocksSharedToPartners/utilsStocksSharedToPartners';
import { AnyAdminRole, useRBAC } from 'hooks/useRBAC';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksMyModel } from 'models/shared/stocksMy/StocksMyModel';
import { StocksMyUpdateModel } from 'models/shared/stocksMy/StocksMyUpdateModel';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AddNewStock } from '../stocksModals/AddNewStock/AddNewStock';
import { EditStock } from '../stocksModals/EditStock/EditStock';
import { ShareStockWithPartner } from '../stocksModals/ShareStockWithPartner/ShareStockWithPartner';
import { useGetAllMyStocks } from './hooksStocksMy';

type PropTypes = {
  setStocksSharedToPartners: (values: StocksSharedToPartnersModel[]) => void;
};

export const StocksMy = ({
  setStocksSharedToPartners,
}: PropTypes): JSX.Element => {
  const [stocks, setStocks] = useState<StocksMyModel[]>([] as StocksMyModel[]);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const hasRightsToEdit = useRBAC(AnyAdminRole);

  useGetAllMyStocks(currentUser, setStocks);

  const { t } = useTranslation('components');

  const handleSubmit = async (
    values: StocksMyUpdateModel,
    stock: StocksMyModel
  ): Promise<void> => {
    toast.dismiss();
    await SellerApi.updateMyStock(currentUser.companyId, values, stock.id);

    toast.info(t('stockSettings.inf_StockSaved'));

    await getAllMyStocks(currentUser, setStocks);
  };

  const handleVisibleOrArrivingChange = async (
    checked: boolean,
    stock: StocksMyModel,
    field: 'isVisible' | 'showArriving'
  ) => {
    const updatedStock: StocksMyUpdateModel = {
      code: stock.code,
      name: stock.name,
      addressLine1: stock.addressLine1,
      addressLine2: stock.addressLine2,
      postalCode: stock.postalCode,
      city: stock.city,
      state: stock.state,
      countryCode: stock.country?.code.toLowerCase(),
      isVisible:
        (field === 'showArriving' && checked) || field === 'isVisible'
          ? checked
          : stock.isVisible,
      showArriving:
        (field === 'isVisible' && !checked) || field === 'showArriving'
          ? checked
          : stock.showArriving,
      additionalInfo: stock.additionalInfo,
    };

    await handleSubmit(updatedStock, stock);
    await getAllMyStocks(currentUser, setStocks);
    await getAllStocksSharedToPartners(currentUser, setStocksSharedToPartners);
  };

  const columns: TableColumn<StocksMyModel>[] = [
    {
      name: t('stockSettings.lbl_StockID'),
      selector: (row) => row.code,
      sortable: true,
      maxWidth: '150px',
      minWidth: '150px',
    },
    {
      name: t('stockSettings.lbl_DisplayedName'),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: t('addressSettings.lbl_Address'),
      selector: (row) => row.fullAddress,
      sortable: true,
      right: false,
      maxWidth: '400px',
      minWidth: '400px',
    },
    {
      name: t('stockSettings.lbl_AdditionalInfo'),
      selector: (row) => row.additionalInfo,
      sortable: true,
      maxWidth: '250px',
      minWidth: '250px',
    },
    {
      name: t('addressSettings.lbl_Visible'),
      cell: (row: StocksMyModel) => (
        <StocksMyVisibleToggle
          handleVisibleOrArrivingChange={handleVisibleOrArrivingChange}
          row={row}
          hasRightsToEdit={hasRightsToEdit}
        />
      ),
      sortable: true,
      center: true,
      // maxWidth: '100px',
      // minWidth: '100px',
    },
    {
      name: t('stockSettings.lbl_ShowArriving'),
      cell: (row: StocksMyModel) => (
        <StocksMyArrivingToggle
          handleVisibleOrArrivingChange={handleVisibleOrArrivingChange}
          row={row}
          hasRightsToEdit={hasRightsToEdit}
        />
      ),
      sortable: true,
      center: true,
      // maxWidth: '130px',
      // minWidth: '130px',
    },
    {
      name: '',
      cell: (row: StocksMyModel) => (
        <EditStock
          stock={row}
          currentUser={currentUser}
          isDisabled={!hasRightsToEdit}
          setStocks={setStocks}
          t={t}
          setStocksSharedToPartners={setStocksSharedToPartners}
        />
      ),
      center: true,
      maxWidth: '40px',
      minWidth: '40px',
    },
    {
      name: '',
      cell: (row: StocksMyModel) => (
        <ShareStockWithPartner
          setStocksSharedToPartners={setStocksSharedToPartners}
          t={t}
          currentUser={currentUser}
          isDisabled={!hasRightsToEdit}
          stock={row}
        />
      ),
      center: true,
      maxWidth: '40px',
      minWidth: '40px',
    },
  ];

  return (
    <Card className='p-3'>
      <Card.Header>
        <Row>
          <Col>
            <h4>{t('stockSettings.hd_YourStocks')}</h4>
            <AddNewStock
              currentUser={currentUser}
              setStocks={setStocks}
              t={t}
            />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <DataTable
          noDataComponent={
            <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
          }
          striped
          highlightOnHover
          responsive
          columns={columns}
          data={stocks}
        />
      </Card.Body>
    </Card>
  );
};

import { BuyerFooter } from 'layout/BuyerFooter';
import { BuyerHeader } from 'layout/BuyerHeader';
import { BuyerSidebar } from 'layout/BuyerSidebar';
import { BuyerSettings } from 'pages/buyer/BuyerSettings';
import { ExternalSupplierInfo } from 'pages/buyer/ExternalSupplierInfo';
import { Inquiry } from 'pages/buyer/Inquiry/Inquiry';
import { InquiryUnsentContainer } from 'pages/buyer/Inquiry/InquiryUnsentContainer';
import { Invoice } from 'pages/buyer/Invoices/Invoice';
import { Offer } from 'pages/buyer/Offer/Offer';
import { OfferComparison } from 'pages/buyer/Offer/OfferComparison';
import { Order } from 'pages/buyer/Order/Order';
import { OrderUnsentContainer } from 'pages/buyer/Order/OrderUnsentContainer';
import { FavoriteProducts } from 'pages/buyer/Products/FavoriteProducts';
import { ProductClasses } from 'pages/buyer/Products/ProductClasses';
import {
  ProductDetails,
  ProjectProductDetails,
} from 'pages/buyer/Products/ProductDetails';
import { ProductSubclasses } from 'pages/buyer/Products/ProductSubclasses';
import { Products } from 'pages/buyer/Products/Products';
import { Search } from 'pages/buyer/Products/Search';
import { SearchSimple } from 'pages/buyer/Products/SearchSimple';
import { Project } from 'pages/buyer/Project/Project';
import { ProjectInquiry } from 'pages/buyer/ProjectInquiry/ProjectInquiry';
import { ProjectOrder } from 'pages/buyer/ProjectOrder/ProjectOrder';
import { SupplierInfo } from 'pages/buyer/SupplierInfo';
import { Suppliers } from 'pages/buyer/Suppliers/Suppliers';
import { BuyerProductDetails } from 'pages/buyer/wireframes/BuyerProductDetails/BuyerProductDetails';
import { useEffect, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router';
import { SignalRBroker } from 'utils/SignalRBroker';
import { getUrlParams } from 'utils/getUrlParams';
import { AnyBuyerRole } from '../../../hooks/useRBAC';
import { BuyerDealsHome } from '../../../pages/buyer/BuyerDealsHome';
import { HiddenFeatureRoute } from './HiddenFeatureRoute';
import { RBACRoute } from './RBACRoute';

/**
 * All routes for Buyer view type
 * @returns All routes available for Buyer view type
 */
export const BuyerRoutes = () => {
  const { path } = useRouteMatch();

  const [isQuickOrder, setIsQuickOrder] = useState<boolean>(false);

  /* Check from urlParams if isQuickOrder */
  useEffect(() => {
    const comingFromQuickOrder = getUrlParams('isQuickOrder');

    comingFromQuickOrder && setIsQuickOrder(comingFromQuickOrder === 'true');
  }, []);

  const [showSelector, setShowSelector] = useState<boolean>(false);

  return (
    <div className='wrapper'>
      <SignalRBroker />
      <div className='main-container'>
        <div className='main-sidebar'>
          <BuyerSidebar setShowSelector={setShowSelector} />
        </div>

        <div
          className={`buyer-supplier-selector-portal-receiver ${
            showSelector
              ? 'buyer-supplier-selector-portal-receiver-opened'
              : 'buyer-supplier-selector-portal-receiver-closed'
          }`}
        ></div>

        <div className={showSelector ? `main-shifted` : `main`}>
          {!isQuickOrder ? (
            <BuyerHeader />
          ) : (
            <div className='d-none'>
              <BuyerHeader />
            </div>
          )}

          {
            <div
              // style={{ maxWidth: '1553px', margin: '0 auto' }}
              className={
                isQuickOrder
                  ? 'fixed-portal-receiver position-static'
                  : 'fixed-portal-receiver fixed-portal-receiver-sticky'
              }
            />
          }

          <Switch>
            <RBACRoute
              Component={BuyerDealsHome}
              path={`${path}/deals`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={BuyerSettings}
              path={`${path}/settings`}
              exact
              requiredRoles={AnyBuyerRole}
            />

            {/* Products */}
            <RBACRoute
              Component={ProductClasses}
              path={`${path}/product-classes`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={ProductSubclasses}
              path={`${path}/product-subclasses/:classId`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={Products}
              path={`${path}/product-classes/:classId/products`}
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={Search}
              path={`${path}/search`}
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={SearchSimple}
              path={`${path}/search-simple`}
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={ProductDetails}
              path={`${path}/product-details/:productCode`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={ProjectProductDetails}
              path={`${path}/project-product-details/:productCode`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={FavoriteProducts}
              path={`${path}/favorite-products`}
              requiredRoles={AnyBuyerRole}
            />

            <RBACRoute
              Component={Suppliers}
              path={`${path}/suppliers`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={SupplierInfo}
              path={`${path}/supplier-info`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={ExternalSupplierInfo}
              path={`${path}/external-supplier-info`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={Project}
              path={`${path}/projects/:projectId`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={ProjectInquiry}
              path={`${path}/project/:projectId/project-inquiry/:projectInquiryId`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={ProjectOrder}
              path={`${path}/project/:projectId/project-order/:projectOrderId`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={InquiryUnsentContainer}
              path={`${path}/inquiry-unsent`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={Inquiry}
              path={`${path}/inquiry/:inquiryId`}
              exact
              requiredRoles={AnyBuyerRole}
            />

            <RBACRoute
              Component={Offer}
              path={`${path}/offer/:offerId`}
              exact
              requiredRoles={AnyBuyerRole}
            />

            <RBACRoute
              Component={OrderUnsentContainer}
              path={`${path}/order-unsent`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={Order}
              path={`${path}/order/:orderId`}
              exact
              requiredRoles={AnyBuyerRole}
            />
            <RBACRoute
              Component={Invoice}
              path={`${path}/invoice/:invoiceId`}
              exact
              requiredRoles={AnyBuyerRole}
            />

            <RBACRoute
              Component={OfferComparison}
              path={`${path}/offer-comparison/:dealGroupId`}
              exact
              requiredRoles={AnyBuyerRole}
            />

            {/* Wireframes: remove after done */}
            <HiddenFeatureRoute
              Component={BuyerProductDetails}
              path={`${path}/product-details-multiple-suppliers`}
              exact
              allowedUserNameDomains={['morek.ee', 'eleczap.com']}
            />
            {/* ***** */}
          </Switch>

          <BuyerFooter showSelector={showSelector} />
        </div>
      </div>
    </div>
  );
};

import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { MouseEvent } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export const ProjectViewGuide = () => {
  const { t } = useTranslation(['components', 'introGuides']);

  const handleStartTour = (event: MouseEvent) => {
    introJs()
      .setOptions({
        scrollToElement: false,
        nextLabel: t('common.btn_Next', { ns: 'components' }),
        prevLabel: t('common.btn_Back', { ns: 'components' }),
        steps: [
          {
            title: t('projectViewGuide.hd_CurrentProject', {
              ns: 'introGuides',
            }),
            intro: t('projectViewGuide.inf_CurrentProject', {
              ns: 'introGuides',
            }),
            tooltipClass: 'introjs-initial-tooltip',
          },
          {
            element: '.project-view-deal.row',
            title: t('projectViewGuide.hd_Quantity', {
              ns: 'introGuides',
            }),
            intro: t('projectViewGuide.inf_Quantity', {
              ns: 'introGuides',
            }),
          },
          {
            element: '.project-view-deal.row',
            title: t('projectViewGuide.hd_Fill', {
              ns: 'introGuides',
            }),
            intro: t('projectViewGuide.inf_Fill', {
              ns: 'introGuides',
            }),
          },
          {
            element: '#project-view-btn-import-products',
            title: t('projectViewGuide.hd_Import', {
              ns: 'introGuides',
            }),
            intro: t('projectViewGuide.inf_Import', {
              ns: 'introGuides',
            }),
          },
          {
            element: '#project-view-add-product-form',
            title: t('projectViewGuide.hd_AddAProduct', {
              ns: 'introGuides',
            }),
            intro: t('projectViewGuide.inf_AddAProduct', {
              ns: 'introGuides',
            }),
          },
          {
            element: '#project-view-attachments-toggle',
            title: t('projectViewGuide.hd_Files', {
              ns: 'introGuides',
            }),
            intro: t('projectViewGuide.inf_Files', {
              ns: 'introGuides',
            }),
          },
        ],
      })
      .start();
  };

  return (
    <div>
      <QuestionCircle
        onClick={handleStartTour}
        size={40}
        className='btn-floating-help me-3'
      />
    </div>
  );
};

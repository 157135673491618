import { ExternalSupplierNew } from 'components/buyer/suppliers/ExternalSupplier/ExternalSupplierNew';
import { SellersCompaniesAndContactsModel } from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  setSuppliers: (value: SellersCompaniesAndContactsModel) => void;
  suppliers: SellersCompaniesAndContactsModel;
  show: boolean;
  setShow: (value: boolean) => void;
};

export const NoSuppliersModal = ({
  setSuppliers,
  suppliers,
  show,
  setShow,
}: PropTypes) => {
  const { t } = useTranslation('components');

  const closeModal = () => {
    setShow(false);
  };

  return (
    <Modal show={show} size='sm' id='no-supplier' onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t('suppliersPage.hd_HaveNoSuppliers')}</Modal.Title>
      </Modal.Header>

      <Modal.Body className='p-4'>
        <p>{t('suppliersPage.inf_HaveNoSuppliersPart1')}</p>

        <p>{t('suppliersPage.inf_HaveNoSuppliersPart2')}</p>

        <p>{t('suppliersPage.inf_HaveNoSuppliersPart3')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={closeModal}>
          {t('common.lbl_No')}
        </Button>
        <ExternalSupplierNew
          suppliers={suppliers}
          setSuppliers={setSuppliers}
          setParentModalShow={setShow}
        />
      </Modal.Footer>
    </Modal>
  );
};

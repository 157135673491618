import { BuyerApi } from 'api/BuyerApi';
import { ExternalSupplierAddContact } from 'components/buyer/supplierInfo/ExternalSupplierAddContact';
import { ExternalSupplierEditContact } from 'components/buyer/supplierInfo/ExternalSupplierEditContact';
import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { ModalRemoveAssignedContact } from 'components/shared/contacts/ModalRemoveAssignedContact';
import { ExternalSellerContactModel } from 'models/buyer/contacts/ExternalSellerContactsModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Col, Row } from 'react-bootstrap';
import { Envelope, Phone } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  supplierId: number;
  supplierName: string;
};

export const ExternalSupplierContacts = ({
  supplierId,
  supplierName,
}: PropTypes) => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const [sellerContacts, setSellerContacts] = useState<
    ExternalSellerContactModel[]
  >([]);

  const getContacts = useCallback(async (): Promise<void> => {
    const response = await BuyerApi.getExternalSellerContacts(
      currentUser.companyId,
      supplierId
    );

    setSellerContacts(response);
  }, [currentUser, supplierId]);

  useEffect(() => {
    supplierId !== undefined && getContacts();
  }, [supplierId, getContacts]);

  const { t } = useTranslation('components');

  const handleRemoveAssignedContact = async (id: number): Promise<void> => {
    toast.dismiss();
    await BuyerApi.deleteExternalSellerContact(
      currentUser.companyId,
      supplierId,
      id
    );

    toast.info(t('externalSupplierContacts.inf_ContactRemoved'));

    await getContacts();
  };

  return (
    <>
      {sellerContacts.length !== 0 && (
        <Card className='p-3'>
          <Card.Header>
            <h4>{t('suppliersPage.hd_YourContacts')}</h4>
          </Card.Header>
          <Card.Body className='d-flex flex-wrap'>
            {sellerContacts.map((contact) => (
              <div
                style={{
                  width: '230px',
                }}
                className='border p-4 m-2'
              >
                <Row>
                  <Col lg={1}>
                    <ExternalSupplierEditContact
                      getContacts={getContacts}
                      supplierId={supplierId}
                      contactId={contact.id}
                    />{' '}
                  </Col>
                  <Col className='d-flex justify-content-center'>
                    <AvatarWithFallback
                      imageUrl={null}
                      initials={contact.initials}
                      size='x-large'
                    />
                  </Col>
                  <Col lg={1}>
                    <ModalRemoveAssignedContact
                      contactName={`${contact.firstName} ${contact.lastName}`}
                      customerName={supplierName}
                      id={contact.id}
                      handleRemoveAssignedContact={handleRemoveAssignedContact}
                    />
                  </Col>
                </Row>
                <Row>
                  <h4 className='text-center mt-4'>{`${contact.firstName} ${contact.lastName}`}</h4>
                </Row>
                <Row>
                  <h4 className='text-center'>
                    <Phone className='icon-ez' /> {`${contact.phone ?? '-'}`}
                  </h4>
                </Row>
                <Row>
                  <h5 className='text-center d-flex aling-items-center justify-content-center'>
                    <div>
                      <Envelope className='icon-ez me-1' />
                    </div>
                    <a href={`mailto:${contact.email}`} className='icon-ez'>
                      {contact.email}
                    </a>
                  </h5>
                </Row>
              </div>
            ))}

            <ExternalSupplierAddContact
              getContacts={getContacts}
              supplierId={supplierId}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

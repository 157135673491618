import { BuyerApi } from 'api/BuyerApi';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { DealTagModel } from 'models/buyer/deals/DealsModels';
import { DealGroupModel } from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';

type PropTypes = {
  currentUser: LoginResponseUserModel;
  dealsDetail: DealGroupModel;
  tag: DealTagModel;
  type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice';
};

export const BuyerDealsTag = ({
  currentUser,
  dealsDetail,
  tag,
  type,
}: PropTypes) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  const { appState } = useContext(AppContext);

  const handleDeleteTag = async (tag: DealTagModel) => {
    setIsLoading(true);
    await BuyerApi.tags.deleteDealGroupTag(
      currentUser.companyId,
      tag.id,
      dealsDetail.id,
      type
    );

    await appState.getProjects();
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}{' '}
      <Badge
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={isLoading ? () => {} : () => handleDeleteTag(tag)}
        pill
        className={
          isHover
            ? 'deals-pill-inverse mx-1 cursor-pointer'
            : 'deals-pill mx-1 cursor-pointer'
        }
      >
        {tag.name}{' '}
        {isHover ? (
          <X className='p-0' style={{ marginTop: '-2.8px' }} />
        ) : (
          <span style={{ height: '1em' }} />
        )}
      </Badge>
    </>
  );
};

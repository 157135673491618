import { BuyerApi } from 'api/BuyerApi';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { ExternalSellerContactModel } from 'models/buyer/contacts/ExternalSellerContactsModel';
import { ProjectInquiryAddRemoveSupplierModel } from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Form, FormCheck, ListGroupItem } from 'react-bootstrap';

type PropTypes = {
  supplier: BuyerCurrentSuppliers;
  isSelected: boolean;
  isDisabled: boolean;
  externalContactId: number | null;
  currentUser: LoginResponseUserModel;
  handleSupplierAdd: (value: ProjectInquiryAddRemoveSupplierModel) => void;
  handleSupplierRemove: (value: ProjectInquiryAddRemoveSupplierModel) => void;
  handleSupplierContactUpdate: (
    value: ProjectInquiryAddRemoveSupplierModel
  ) => void;
};

export const InquiryGroupSupplierSelectorOption = ({
  supplier,
  isSelected,
  isDisabled,
  currentUser,
  externalContactId,
  handleSupplierAdd,
  handleSupplierRemove,
  handleSupplierContactUpdate,
}: PropTypes) => {
  const [selected, setSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [externalContacts, setExternalContacts] = useState<
    ExternalSellerContactModel[]
  >([]);

  const [selectedExternalContact, setSelectedExternalContact] = useState<
    number | null
  >(null);

  const { appState } = useContext(AppContext);

  /* Get external contacts */
  useEffect(() => {
    const getExternalContacts = async () => {
      const response = await BuyerApi.getExternalSellerContacts(
        currentUser.companyId,
        supplier.value
      );

      setExternalContacts(response);
      setSelectedExternalContact(externalContactId ?? response[0].id);
    };

    supplier.isExternalSeller && getExternalContacts();
  }, [
    currentUser.companyId,
    externalContactId,
    supplier.isExternalSeller,
    supplier.value,
  ]);

  const toggleSelect = useCallback(
    async (isChecked: boolean) => {
      setIsLoading(true);

      if (isChecked) {
        handleSupplierAdd({
          sellerCompanyId: supplier.value,
          externalSellerContactId: externalContacts[0]?.id ?? null,
        });
      } else
        handleSupplierRemove({
          sellerCompanyId: supplier.value,
          externalSellerContactId: selectedExternalContact,
        });

      setSelected(isChecked);
      setIsLoading(false);
    },
    [
      externalContacts,
      handleSupplierAdd,
      handleSupplierRemove,
      selectedExternalContact,
      supplier.value,
    ]
  );

  useEffect(() => {
    const checkIfSupplierStillSelected = async () => {
      const supplierStillActive =
        appState?.buyerCurrentSuppliers?.findIndex(
          (currentSupplier) =>
            currentSupplier.value === supplier.value &&
            currentSupplier.isSelected
        ) !== -1;

      if (!supplierStillActive) {
        await toggleSelect(false);
      }
    };

    checkIfSupplierStillSelected();
  }, [appState?.buyerCurrentSuppliers, supplier.value, toggleSelect]);

  const toggleCheckSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    await toggleSelect(isChecked);
  };

  const toggleClickSelect = async (event: any) => {
    if (!isSelected && event.target.tagName !== 'INPUT') {
      await toggleSelect(!isSelected);
      setSelected(isSelected);
    }
  };

  const handleContactChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    const contactId = parseInt(event.target.value);

    setSelectedExternalContact(contactId);

    handleSupplierContactUpdate({
      sellerCompanyId: supplier.value,
      externalSellerContactId: contactId,
    });
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <ListGroupItem
        // onClick={toggleClickSelect}
        disabled={!supplier.isSelected}
        className={
          isSelected
            ? 'project-supplier-option-selected cursor-pointer'
            : 'project-supplier-option cursor-pointer'
        }
      >
        <FormCheck
          className='wrap-ellipsis project-supplier-option-checkbox cursor-pointer'
          type='checkbox'
          disabled={isDisabled}
          label={
            <strong
              className={
                !supplier.isSelected
                  ? 'icon-ez-light-gray'
                  : isSelected
                  ? 'icon-ez-white'
                  : 'icon-ez'
              }
            >
              {supplier.name}
            </strong>
          }
          checked={selected || (isSelected && supplier.isSelected)}
          onChange={toggleCheckSelect}
        />
        {supplier.isExternalSeller && isSelected && (
          <Form.Select
            disabled={isDisabled}
            style={{
              maxWidth: '200px',
            }}
            onChange={handleContactChange}
            className='border rounded p-0 ps-2 m-0 ms-3 icon-ez'
            value={selectedExternalContact ?? undefined}
          >
            {externalContacts.map((contact) => (
              <option value={contact.id} className='icon-ez'>
                {contact.firstName} {contact.lastName}
              </option>
            ))}
          </Form.Select>
        )}
      </ListGroupItem>
    </>
  );
};

import { UserModelById } from 'models/shared/user/UserModelById';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  user: UserModelById;
  hasRightsToEdit: boolean;
  handleDeleteUser: (id: number) => Promise<void>;
};

export const UserDeleteButton = ({
  user,
  hasRightsToEdit,
  handleDeleteUser,
}: PropTypes) => {
  const { t } = useTranslation('components');
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleDeleteConfirmation = async () => {
    handleDeleteUser(user.id);
    handleClose();
  };

  return (
    <>
      <Button
        variant='btn btn-eleczap-noBorder'
        disabled={!hasRightsToEdit}
        onClick={handleShow}
      >
        <Trash className='status-icon-danger' size={20}></Trash>
      </Button>
      <Modal size='sm' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('usersSettings.hd_CancelInvitation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('usersSettings.inf_CancelInvitationDescription', {
            userName: user.username,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='danger' onClick={handleDeleteConfirmation}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

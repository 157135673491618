/* Hooks utilized in StocksMy.tsx (My stocks settings)*/

import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useEffect } from 'react';
import { StocksMyModel } from 'models/shared/stocksMy/StocksMyModel';
import { getAllMyStocks } from './utilsStocksMy';

/* Get all addresses */
export const useGetAllMyStocks = (
  currentUser: LoginResponseUserModel,
  setStocks: (values: StocksMyModel[]) => void
) => {
  useEffect(() => {
    getAllMyStocks(currentUser, setStocks);
  }, [currentUser, currentUser.companyId, currentUser.id, setStocks]);
};

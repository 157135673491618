import { SellerNotifications } from 'components/seller/settings/sellerNotifications/SellerNotifications';
import { useGetCompanyDetails } from 'components/shared/settings/Company/hooksCompany';
import {
  handleCompanyProfileImageDelete,
  handleCompanyProfileImageUpload,
} from 'components/shared/settings/Company/utilsCompany';
import { UserProfileImageUpload } from 'components/shared/settings/UserProfileImageUpload';
import { Users } from 'components/shared/settings/Users/Users/Users';
import { AppContext } from 'contexts/user/AppContext';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { CompanyModel } from 'models/shared/company/CompanyModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Api } from '../../api/Api';
import ProductGroups from '../../components/seller/settings/ProductGroups';
import { Stocks } from '../../components/seller/settings/Stocks';
import { Account } from '../../components/shared/settings/Account';
import { Company } from '../../components/shared/settings/Company/Company';
import './sellerSettings.css';

export const SellerSettings = () => {
  const { t } = useTranslation('components');

  const [user, setUser] = useState<UserModelById>({} as UserModelById);
  const [company, setCompany] = useState<CompanyModel>({} as CompanyModel);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [activeKey, setActiveKey] = useState<string>('SellerUserAccount');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);
  const setUserProfile = useSetUserProfile();

  useGetCompanyDetails(currentUser, setCompany);

  /* User details form initial data */
  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);
      const user = await Api.getUser(
        currentUser.companyId,
        currentUser.id,
        appState.buyerCurrentSupplierId
      );
      setUser(user);
      setIsLoading(false);
    };
    getUser();
  }, [currentUser.companyId, currentUser.id, appState.buyerCurrentSupplierId]);

  const handleProfileImageUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    toast.dismiss();

    if (event.target.files) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        if (selectedFile.size > 500 * 1024) {
          toast.error(t('account.err_ProfileImageSizeTooBig'));
          event.target.value = '';
          return;
        }
        const data = new FormData();
        data.append('file', selectedFile);
        const imageLocation = await Api.uploadProfileImage(
          currentUser.companyId,
          currentUser.id,
          data
        );
        const updatedUser = await setUserProfile(
          appState.buyerCurrentSupplierId || null
        );
        setUser(updatedUser);

        setProfileImage(imageLocation);

        toast.info(t('account.inf_ProfileImageUploaded'));
      }
    }
  };

  const handleProfileImageDelete = async () => {
    toast.dismiss();

    if (profileImage) {
      await Api.deleteProfileImage(currentUser.companyId, currentUser.id);
      setProfileImage('');

      const updatedUser = await setUserProfile(
        appState.buyerCurrentSupplierId || null
      );
      setUser(updatedUser);

      toast.info(t('account.inf_ProfileImageDeleted'));
    }
  };

  const handleCompanyImageUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    await handleCompanyProfileImageUpload(
      currentUser,
      t,
      setProfileImage,
      event
    );
  };

  const handleCompanyImageDelete = async () => {
    await handleCompanyProfileImageDelete(
      currentUser,
      t,
      setProfileImage,
      profileImage
    );
  };

  const isCompanyRelatedPage: boolean = activeKey.includes('Company');

  useEffect(() => {
    isCompanyRelatedPage
      ? setProfileImage(company.logo)
      : setProfileImage(user.profileImage);
  }, [company.logo, isCompanyRelatedPage, user.profileImage]);

  return (
    <div className='container-fluid p-2'>
      <Card className='p-1 settingsPage'>
        <Tab.Container
          activeKey={activeKey}
          id='left-tabs-example'
          defaultActiveKey='SellerUserAccount'
        >
          <div className='row flex-nowrap'>
            <div className='col col-side-menu'>
              {isCompanyRelatedPage ? (
                <Card className='border'>
                  <Card.Body>
                    <Row className='justify-content-center'>
                      <UserProfileImageUpload
                        profileImage={profileImage}
                        initials={company.initials}
                        handleImageUpload={handleCompanyImageUpload}
                        handleImageDelete={handleCompanyImageDelete}
                      />
                    </Row>

                    <Row className='justify-content-center'>
                      {isLoading ? (
                        <Spinner className='my-2' animation='border' />
                      ) : (
                        <h4 className='text-center mt-4'>{company.name}</h4>
                      )}
                    </Row>
                    <Row>
                      <h5 className='text-center'>
                        <a href={`mailto:${company.email}`} className='icon-ez'>
                          {company.email}
                        </a>
                      </h5>
                    </Row>
                  </Card.Body>
                </Card>
              ) : (
                <Card className='border'>
                  <Card.Body>
                    <Row className='justify-content-center'>
                      <UserProfileImageUpload
                        profileImage={user.profileImage}
                        initials={user.initials}
                        handleImageUpload={handleProfileImageUpload}
                        handleImageDelete={handleProfileImageDelete}
                      />
                    </Row>

                    <Row className='justify-content-center'>
                      {isLoading ? (
                        <Spinner className='my-2' animation='border' />
                      ) : (
                        <h4 className='text-center mt-4'>{`${user.firstName} ${user.lastName}`}</h4>
                      )}
                    </Row>
                    <Row>
                      <h5 className='text-center'>
                        <a href={`mailto:${user.email}`} className='icon-ez'>
                          {user.email}
                        </a>
                      </h5>
                    </Row>
                    <Row>
                      <h5
                        onClick={() => setActiveKey('SellerCompany')}
                        className='text-center cursor-pointer icon-ez'
                      >
                        {user.companyName}
                      </h5>
                    </Row>
                    <Row>
                      <span className='text-center'>{user.position}</span>
                    </Row>
                  </Card.Body>
                </Card>
              )}

              {/*User pills*/}
              <Nav className='flex-column p-0 border mb-4 tabContainerSettings'>
                <Nav.Item
                  style={{ cursor: 'default' }}
                  className='border-bottom'
                >
                  <Nav.Link style={{ cursor: 'default' }}>
                    <h4>{t('userSettings.lbl_Mypeferences')}</h4>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  onClick={() => setActiveKey('SellerUserAccount')}
                  className='border-bottom nav-item-hover'
                >
                  <Nav.Link eventKey='SellerUserAccount'>
                    {t('userSettings.lbl_UserInfo')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className='nav-item-hover'
                  onClick={() => setActiveKey('SellerNotifications')}
                >
                  <Nav.Link eventKey='SellerNotifications'>
                    {t('userSettings.lbl_Notifications')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {/*Company pills*/}
              <Nav className='flex-column p-0 border tabContainerSettings'>
                <Nav.Item
                  style={{ cursor: 'default' }}
                  className='border-bottom'
                >
                  <Nav.Link style={{ cursor: 'default' }}>
                    <h4>{t('userSettings.lbl_MyCompany')}</h4>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  onClick={() => setActiveKey('SellerCompany')}
                  className='border-bottom nav-item-hover'
                >
                  <Nav.Link eventKey='SellerCompany'>
                    {t('userSettings.lbl_CompanyInfo')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  onClick={() => setActiveKey('SellerCompanyUsers')}
                  className='border-bottom nav-item-hover'
                >
                  <Nav.Link eventKey='SellerCompanyUsers'>
                    {t('userSettings.lbl_Users')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className='border-bottom nav-item-hover'
                  onClick={() => setActiveKey('SellerCompanyStocks')}
                >
                  <Nav.Link eventKey='SellerCompanyStocks'>
                    {t('userSettings.lbl_Stocks')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className='nav-item-hover'
                  onClick={() => setActiveKey('SellerProductGroups')}
                >
                  <Nav.Link eventKey='SellerProductGroups'>
                    {t('userSettings.lbl_ProductGroups')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className='col col-lg-10'>
              {/*User tab*/}
              <Tab.Content>
                <Tab.Pane eventKey='SellerUserAccount'>
                  <Account user={user} setUser={setUser} viewType='Seller' />
                </Tab.Pane>
                {/*Notifications tab*/}
                <Tab.Pane eventKey='SellerNotifications'>
                  <SellerNotifications />
                </Tab.Pane>

                {/*Company tab*/}
                <Tab.Pane eventKey='SellerCompany'>
                  <Company company={company} />
                </Tab.Pane>
                {/*Seller company users tab*/}
                <Tab.Pane eventKey='SellerCompanyUsers'>
                  <Users company={company} viewType='Seller' />
                </Tab.Pane>
                {/*Stock tab*/}
                <Tab.Pane eventKey='SellerCompanyStocks'>
                  <Stocks />
                </Tab.Pane>
                {/*Product groups tab*/}
                <Tab.Pane eventKey='SellerProductGroups'>
                  <ProductGroups />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </Card>
    </div>
  );
};

import { handleUpdateUserPermissions } from 'components/shared/settings/Users/Users/utilsUsers';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { UserUpdatePermissionsModel } from 'models/shared/user/UserUpdatePermissionsModel';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import './userRoleSwitch.css';

type PropTypes = {
  user: UserModelById;
  currentUser: LoginResponseUserModel;
  getAllUsers: () => Promise<void>;
  hasRightsToEdit: boolean;
  isSameUser: boolean;
};

export const UserRoleSwitch = ({
  user,
  currentUser,
  getAllUsers,
  hasRightsToEdit,
  isSameUser,
}: PropTypes) => {
  const { t } = useTranslation('components');

  const [value, setValue] = useState<string>('');

  const handleChangeUserRole = async (event: FormEvent<HTMLSelectElement>) => {
    const roleName = event.currentTarget.value;

    setValue(roleName);

    const userUpdate: UserUpdatePermissionsModel =
      {} as UserUpdatePermissionsModel;

    if (roleName === 'Admin') {
      userUpdate.isBuyerAdministrator = user.isBuyerRegularUser;
      userUpdate.isSellerAdministrator = user.isSellerRegularUser;
      userUpdate.isBuyerRegularUser = false;
      userUpdate.isSellerRegularUser = false;
      userUpdate.canPlaceOrders = user.canPlaceOrders;
      userUpdate.canSeePrices = user.canSeePrices;
    }

    if (roleName === 'RegularUser') {
      userUpdate.isBuyerAdministrator = false;
      userUpdate.isSellerAdministrator = false;
      userUpdate.isBuyerRegularUser = user.isBuyerAdministrator;
      userUpdate.isSellerRegularUser = user.isSellerAdministrator;
      userUpdate.canPlaceOrders = user.canPlaceOrders;
      userUpdate.canSeePrices = user.canSeePrices;
    }

    toast.dismiss();

    await handleUpdateUserPermissions(currentUser, user, userUpdate);
    await getAllUsers();

    toast.success(t('account.inf_UserSettingsSaved'));
  };

  const newRoleNames = useMemo(() => ['Admin', 'RegularUser'], []);

  const isAdmin = user.isBuyerAdministrator || user.isSellerAdministrator;

  useEffect(() => {
    setValue(isAdmin ? newRoleNames[0] : newRoleNames[1]);
  }, [isAdmin, newRoleNames]);

  return (
    <>
      <Form.Select
        className='me-sm-2 userSettingsRoleSelection'
        id='selectUserRole'
        disabled={!hasRightsToEdit || isSameUser}
        onChange={(event) =>
          handleChangeUserRole(event as FormEvent<HTMLSelectElement>)
        }
        value={value}
      >
        {newRoleNames.map((role) => (
          <option key={role} value={role}>
            {t(`roles.${role}`)}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { ExternalSupplierDeletePricelist } from 'components/buyer/suppliers/ExternalSupplier/ExternalSupplierDeletePricelist';
import { ExternalSupplierImportPriceList } from 'components/buyer/suppliers/ExternalSupplier/ExternalSupplierImportPriceList';
import { ExternalSupplierRemove } from 'components/buyer/suppliers/ExternalSupplier/ExternalSupplierRemove';
import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { PriceListExport } from 'components/shared/controls/PriceListExport';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { useRefreshAllSuppliers } from 'hooks/useRefreshAllSuppliers';
import {
  SellersCompaniesAndContactsDataModel,
  SellersCompaniesAndContactsModel,
} from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { SellersUsersDataModel } from 'models/buyer/suppliers/SellersUsersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Col, Row } from 'react-bootstrap';
import {
  ArrowReturnRight,
  ExclamationSquareFill,
  FileEarmarkArrowDownFill,
  FileEarmarkArrowUp,
  FileEarmarkX,
  Image,
  Link45deg,
  Star,
  StarFill,
} from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import './supplierTable.css';

const customStyles = {
  rows: {
    style: {
      backgroundColor: '#F2F2F2', // override the row height
    },
  },
};

type PropTypes = {
  suppliers: SellersCompaniesAndContactsModel;
  showUsers: boolean;
  handlePageChange: (page: number) => Promise<void>;
  handleRowsPerChange: (rowsAmount: number) => Promise<void>;
  handleOnSort: (selectedColumn: any, sortDirection: any) => Promise<void>;
  handlePriceListExport: (
    companyId: number,
    setFileIsSaving: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  resetPaginationToggle: boolean;
  refreshSuppliers: () => Promise<void>;
};

export const SupplierCompanyUserTable = ({
  suppliers,
  showUsers,
  handlePageChange,
  handleRowsPerChange,
  handleOnSort,
  handlePriceListExport,
  resetPaginationToggle,
  refreshSuppliers,
}: PropTypes): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const refreshAllSuppliers = useRefreshAllSuppliers();

  const handleUpdateFavoriteSeller = async (sellerId: number) => {
    toast.dismiss();
    await BuyerApi.updateFavoriteSeller(currentUser.companyId, currentUser.id, {
      id: sellerId,
    });
    await refreshSuppliers();
    await refreshAllSuppliers();
    SuccessToast(t('suppliersPage.inf_FavoriteSupplierChanged'));
  };

  const columns: TableColumn<SellersCompaniesAndContactsDataModel>[] = [
    {
      name: '',
      selector: (row) => row.isFavoriteSeller,
      cell: (row) =>
        row.isActive ? (
          <div
            onClick={
              row.isFavoriteSeller
                ? () => {}
                : () => handleUpdateFavoriteSeller(row.id)
            }
            className='btn btn-eleczap-noBorder d-flex align-items-center justify-content-center'
          >
            {row.isFavoriteSeller ? <StarFill size={20} /> : <Star size={20} />}
          </div>
        ) : (
          <div>
            <ExclamationSquareFill className='icon-customerInvoice' />
          </div>
        ),
      maxWidth: '4.5rem',
      minWidth: '4.5rem',
      center: true,
    },
    {
      name: <Image />,
      selector: (row) => row.initials,
      cell: (row) => (
        <AvatarWithFallback
          initials={row.initials}
          imageUrl={row.logo}
          size='large'
        />
      ),
      maxWidth: '4.5rem',
      minWidth: '4.5rem',
      center: true,
    },
    {
      name: t('customers.lbl_Name'),
      selector: (row) => row.name,
      cell: (row) => (
        <Link
          className='link-eleczap wrap-ellipsis'
          to={
            !row.isExternalSeller
              ? `supplier-info?id=${row.id}`
              : `external-supplier-info?id=${row.id}`
          }
        >
          {row.shortName ?? row.name}
          {!row.isExternalSeller && <Link45deg />}
        </Link>
      ),
      sortable: true,
      maxWidth: '13rem',
      minWidth: '13rem',
    },
    {
      name: t('customers.lbl_E-mail'),
      selector: (row) => row.email,
      cell: (row) => (
        <a href={`mailto:${row.email}`} className='link-eleczap wrap-ellipsis'>
          {row.email}
        </a>
      ),
    },

    {
      name: '',
      selector: (row) => row.isInvitationCompleted,
      cell: (row) =>
        row.isExternalSeller ? null : !row.isActive ? (
          row.isInvitationCompleted ? (
            ''
          ) : (
            <Button
              disabled={isLoading}
              onClick={() => handleAcceptInvite(row.id)}
              className='btn btn-eleczap'
            >
              {t('common.btn_Accept')}
            </Button>
          )
        ) : (
          ''
          // <span className='text-danger'>
          //   {t('customers.lbl_AccessDisabled')}
          // </span>
        ),
      maxWidth: '7rem',
      minWidth: '7rem',
    },

    {
      name: t('addressSettings.lbl_Country'),
      selector: (row) => row.countryName,
      maxWidth: '7rem',
      minWidth: '7rem',
    },

    // {
    //   name: <FolderCheck className='icon-customerActiveProject' />,
    //   selector: (row) => row.activeDeals,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },

    // {
    //   name: <FileEarmarkMinusFill className='icon-customerOffer' />,
    //   selector: (row) => row.offers,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },
    // {
    //   name: <CartDashFill className='icon-customerOrder' />,
    //   selector: (row) => row.orders,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },
    // {
    //   name: <ExclamationSquareFill className='icon-customerInvoice' />,
    //   selector: (row) => row.overdueInvoices,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },
    {
      name: (
        <span className='price-list'>
          {t('customers.lbl_PriceList')}{' '}
          <FileEarmarkArrowUp className='cursor-pointer icon-ez' size={20} />
        </span>
      ),
      cell: (row) =>
        row.isActive && row.isExternalSeller ? (
          <ExternalSupplierImportPriceList
            refreshSuppliers={refreshSuppliers}
            externalSellerId={row.id}
            currencyCode={row.currencyCode}
          />
        ) : (
          <></>
        ),
      selector: (row) => row.priceListUrl,
      center: true,
      maxWidth: '7rem',
      minWidth: '7rem',
      style: {
        color: 'var(--primary)',
      },
    },

    {
      name: (
        <span className='price-list'>
          {t('customers.lbl_PriceList')}{' '}
          <FileEarmarkArrowDownFill
            className='cursor-pointer icon-ez'
            size={20}
          />
        </span>
      ),
      cell: (row) =>
        row.isActive ? (
          row.isExternalSeller && row.hasExternalPricelist && row.id !== 102 ? (
            <PriceListExport
              handleExport={(setFileIsSaving) =>
                handlePriceListExport(row.id, setFileIsSaving)
              }
            />
          ) : (
            !row.isExternalSeller &&
            row.id !== 102 && (
              <PriceListExport
                handleExport={(setFileIsSaving) =>
                  handlePriceListExport(row.id, setFileIsSaving)
                }
              />
            )
          )
        ) : (
          <></>
        ),
      selector: (row) => row.priceListUrl,
      center: true,
      maxWidth: '7rem',
      minWidth: '7rem',
      style: {
        color: 'var(--primary)',
      },
    },

    {
      name: (
        <span className='price-list'>
          {t('customers.lbl_PriceList')}{' '}
          <FileEarmarkX className='cursor-pointer icon-ez-red' size={20} />
        </span>
      ),
      cell: (row) =>
        row.isActive ? (
          row.hasExternalPricelist && (
            <ExternalSupplierDeletePricelist
              externalSellerName={row.name}
              refreshSuppliers={refreshSuppliers}
              externalSellerId={row.id}
            />
          )
        ) : (
          <></>
        ),
      selector: (row) => row.priceListUrl,
      center: true,
      maxWidth: '7rem',
      minWidth: '7rem',
      style: {
        color: 'var(--primary)',
      },
    },

    {
      name: t('customers.lbl_Remove'),
      cell: (row) =>
        row.isExternalSeller ? (
          <ExternalSupplierRemove
            refreshSuppliers={refreshSuppliers}
            supplierId={row.id}
            supplierName={row.name}
          />
        ) : (
          <></>
        ),
      selector: (row) => 'remove',
      center: true,
      maxWidth: '5rem',
      minWidth: '5rem',
    },
  ];

  const ExpandedComponent = ({ data }: any) => {
    return data.users
      ? data.users.map((user: SellersUsersDataModel) => (
          <Row className='mt-1 mb-1'>
            <Col
              style={{
                maxWidth: '4.5rem',
                minWidth: '4.5rem',
              }}
            ></Col>
            <Col
              className='text-end'
              style={{
                maxWidth: '7rem',
                minWidth: '7rem',
                alignSelf: 'center',
              }}
            >
              <ArrowReturnRight />
            </Col>
            <Col
              style={{
                maxWidth: '3rem',
                minWidth: '3rem',
                alignSelf: 'center',
              }}
            >
              <AvatarWithFallback
                initials={user.initials}
                imageUrl={user.profileImage}
                size='large'
              />
            </Col>
            <Col
              className='wrap-ellipsis'
              style={{
                maxWidth: '12rem',
                minWidth: '12rem',
                alignSelf: 'center',
              }}
            >
              {user.fullName}
            </Col>
            <Col
              className='ps-0 wrap-ellipsis'
              style={{
                // maxWidth: '20rem',
                // minWidth: '20rem',
                alignSelf: 'center',
              }}
            >
              <a href={`mailto:${user.email}`} className='link-eleczap'>
                {user.email}
              </a>
            </Col>

            <Col
              style={{
                alignSelf: 'center',
                marginRight: '30px',
              }}
            >
              {user.position}
            </Col>
            {/* <Col
          style={{
            maxWidth: '3.7rem',
            minWidth: '3.7rem',
            alignSelf: 'center',
            color: 'var(--primary)',
          }}
        >
          {user.activeDeals}
        </Col>
        <Col
          style={{
            maxWidth: '3.7rem',
            minWidth: '3.7rem',
            alignSelf: 'center',
            color: 'var(--primary)',
          }}
        >
          {user.offers}
        </Col>
        <Col
          style={{
            maxWidth: '3.7rem',
            minWidth: '3.7rem',
            alignSelf: 'center',
            color: 'var(--primary)',
          }}
        >
          {user.orders}
        </Col>
        <Col
          style={{
            maxWidth: '3.7rem',
            minWidth: '3.7rem',
            alignSelf: 'center',
            color: 'var(--primary)',
          }}
        >
          {user.overdueInvoices}
        </Col> */}

            <Col
              style={{
                maxWidth: '27rem',
                minWidth: '27rem',
                alignSelf: 'center',
              }}
            ></Col>
            <Col
              style={{
                maxWidth: '5.7rem',
                minWidth: '5.7rem',
                alignSelf: 'center',
              }}
            ></Col>
          </Row>
        ))
      : null;
  };

  const paginationComponentOptions = {
    rowsPerPageText: t('common.lbl_RowsPerPage'),
    rangeSeparatorText: t('common.lbl_Of'),
  };

  const handleAcceptInvite = async (supplierId: number) => {
    setIsLoading(true);
    await BuyerApi.acceptSupplierInvitation(currentUser.companyId, supplierId, {
      acceptInvitation: true,
    });

    toast.info(t('sellerSwitch.inf_RefreshPage'));

    await refreshSuppliers();
    setIsLoading(false);
  };

  const conditionalRowStyles = [
    {
      when: (row: SellersCompaniesAndContactsDataModel) => row.isArchived,
      style: {
        display: 'none',
      },
    },
  ];

  return (
    <DataTable
      noDataComponent={
        <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
      }
      highlightOnHover
      responsive
      columns={columns}
      data={suppliers.data}
      pagination
      expandableRows={showUsers}
      customStyles={customStyles}
      expandableRowsComponent={ExpandedComponent}
      paginationServer
      paginationComponentOptions={paginationComponentOptions}
      paginationTotalRows={suppliers?.totalItems}
      onChangeRowsPerPage={handleRowsPerChange}
      onChangePage={handlePageChange}
      paginationResetDefaultPage={resetPaginationToggle}
      sortServer
      onSort={handleOnSort}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

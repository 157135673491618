import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import {
  ProjectModel,
  ProjectRowModel,
} from 'models/buyer/project/ProjectModels';
import {
  ProjectOrderModel,
  ProjectOrderRowModel,
  ProjectOrderRowUpdateModel,
} from 'models/buyer/projectOrder/ProjectOrderModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { updateProjectOrderRows } from 'pages/buyer/ProjectOrder/utilsProjectOrder';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import {
  FormControl,
  InputGroup,
  Overlay,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  CaretDownFill,
  CaretUpFill,
  SkipForwardFill,
  X,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import './customInputs.css';

type PropTypes = {
  row: ProjectOrderRowModel;
  currentUser: LoginResponseUserModel;
  projectDetails: ProjectOrderModel | ProjectModel;
  getDataSource: () => Promise<void>;
  isRequiredQtyUpdating: boolean;
  currentRequiredQtyFocused: number;
  isHidden: boolean;
  projectId: number;
  setIsExtraTableExpanded: (value: boolean) => void;
  setIsLoading: (value: boolean) => void;
};

export const CustomSupplierQuantityInput = ({
  row,
  currentUser,
  projectDetails,
  projectId,
  getDataSource,
  isRequiredQtyUpdating,
  currentRequiredQtyFocused,
  isHidden,
  setIsExtraTableExpanded,
  setIsLoading,
}: // setIsLoading
PropTypes) => {
  const [internalValue, setInternalValue] = useState<number>(
    !isNaN(row.supplierQuantity) ? row.supplierQuantity : 0
  );
  const [internalRow, setInternalRow] = useState<ProjectRowModel>(
    {} as ProjectRowModel
  );
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isSupplierQtyUpdating, setIsSupplierQtyUpdating] =
    useState<boolean>(false);

  const { t } = useTranslation('components');

  const ref = useRef<HTMLInputElement>(null);

  const { appState } = useContext(AppContext);

  useEffect(() => {
    !isNaN(row.supplierQuantity)
      ? setInternalValue(row.supplierQuantity)
      : setInternalValue(0);

    setInternalRow(row);
  }, [row, row.supplierQuantity]);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value !== '' ? event.target.value : '0';

    const truncatedValue = parseInt(value, 10);

    setInternalValue(+truncatedValue);

    let clonedRow: ProjectRowModel = { ...internalRow };

    clonedRow.supplierQuantity = truncatedValue;

    setInternalRow(clonedRow);
  };

  const handleSaveRow = async () => {
    setIsFocused(false);
    setIsLoading(true);
    let clonedRow = { ...internalRow };
    if (isNaN(internalValue)) {
      setInternalValue(0);
      clonedRow = { ...clonedRow, supplierQuantity: 0 };
    }

    const rowUpdate: ProjectOrderRowUpdateModel = {
      productId: clonedRow.productId,
      requiredQuantity: clonedRow.requiredQuantity,
      supplierQuantity: internalValue,
      productFreeName: clonedRow.productId === null ? clonedRow.name : null,
      productCode: clonedRow.productId === null ? clonedRow.productCode : null,
      updateSupplierQuantity: true,
      updateRequiredQuantity: false,
      id: row.id,
    };

    await updateProjectOrderRows(
      [rowUpdate],
      currentUser,
      projectId,
      projectDetails.id
    );
    await getDataSource();

    setInternalRow(row);

    setIsLoading(false);
  };

  const handleDecrement = async () => {
    if (row.supplierQuantity > Math.max(row.orderStep, row.moqPrices[0].moq)) {
      setIsLoading(true);
      let clonedRow: ProjectRowModel = { ...internalRow };

      const remainder = internalValue % row.orderStep;

      if (remainder === 0) {
        setInternalValue((prevState) => (prevState -= row.orderStep));
        clonedRow.supplierQuantity = internalValue - row.orderStep;
      } else {
        setInternalValue(internalValue - remainder);
        clonedRow.supplierQuantity = internalValue - remainder;
      }
      const rowUpdate: ProjectOrderRowUpdateModel = {
        productId: clonedRow.productId,
        requiredQuantity: clonedRow.requiredQuantity,
        supplierQuantity: clonedRow.supplierQuantity,
        productFreeName: clonedRow.productId === null ? clonedRow.name : null,
        productCode:
          clonedRow.productId === null ? clonedRow.productCode : null,

        updateSupplierQuantity: true,
        updateRequiredQuantity: false,
        id: row.id,
      };

      await updateProjectOrderRows(
        [rowUpdate],
        currentUser,
        projectId,
        projectDetails.id
      );
      await getDataSource();
      setIsLoading(false);
    }
  };

  const handleIncrement = async () => {
    setIsLoading(true);
    let clonedRow: ProjectRowModel = { ...internalRow };

    const remainder = internalValue % row.orderStep;

    if (internalValue < Math.max(row.orderStep, row.moqPrices[0].moq)) {
      setInternalValue(Math.max(row.orderStep, row.moqPrices[0].moq));
      clonedRow.supplierQuantity = Math.max(
        row.orderStep,
        row.moqPrices[0].moq
      );
    } else {
      if (remainder === 0) {
        setInternalValue((prevState) => (prevState += row.orderStep));
        clonedRow.supplierQuantity = internalValue + row.orderStep;
      } else {
        setInternalValue(internalValue + row.orderStep - remainder);
        clonedRow.supplierQuantity = internalValue + row.orderStep - remainder;
      }
    }

    const rowUpdate: ProjectOrderRowUpdateModel = {
      productId: clonedRow.productId,
      requiredQuantity: clonedRow.requiredQuantity,
      supplierQuantity: clonedRow.supplierQuantity,
      productFreeName: clonedRow.productId === null ? clonedRow.name : null,
      productCode: clonedRow.productId === null ? clonedRow.productCode : null,

      updateSupplierQuantity: true,
      updateRequiredQuantity: false,
      id: row.id,
    };

    await updateProjectOrderRows(
      [rowUpdate],
      currentUser,
      projectId,
      projectDetails.id
    );
    await getDataSource();
    setIsLoading(false);
  };

  const handleFillSupplierQty = async (row: ProjectRowModel) => {
    setIsLoading(true);
    setIsSupplierQtyUpdating(true);
    if (!isRequiredQtyUpdating) {
      let clonedRow: ProjectRowModel = { ...internalRow };

      const requiredQtyToUse = isRequiredQtyUpdating
        ? currentRequiredQtyFocused
        : row.requiredQuantity;

      setInternalValue(requiredQtyToUse);

      clonedRow.requiredQuantity = requiredQtyToUse;
      clonedRow.supplierQuantity = requiredQtyToUse;

      const rowUpdate: ProjectOrderRowUpdateModel = {
        productId: clonedRow.productId,
        requiredQuantity: clonedRow.requiredQuantity,
        supplierQuantity: clonedRow.supplierQuantity,
        productFreeName: clonedRow.productId === null ? clonedRow.name : null,
        productCode:
          clonedRow.productId === null ? clonedRow.productCode : null,

        updateSupplierQuantity: true,
        updateRequiredQuantity: false,
        id: row.id,
      };

      await updateProjectOrderRows(
        [rowUpdate],
        currentUser,
        projectId,
        projectDetails.id
      );
      await getDataSource();

      setIsSupplierQtyUpdating(false);
      setIsLoading(false);
    }
  };

  const handleEmptySupplierQty = async (row: ProjectRowModel) => {
    setIsSupplierQtyUpdating(true);
    setIsLoading(true);

    if (!isRequiredQtyUpdating) {
      let clonedRow: ProjectRowModel = { ...row };

      setInternalValue(0);

      clonedRow.supplierQuantity = 0;

      const rowUpdate: ProjectOrderRowUpdateModel = {
        productId: clonedRow.productId,
        requiredQuantity: clonedRow.requiredQuantity,
        supplierQuantity: clonedRow.supplierQuantity,
        productFreeName: clonedRow.productId === null ? clonedRow.name : null,
        productCode:
          clonedRow.productId === null ? clonedRow.productCode : null,

        updateSupplierQuantity: true,
        updateRequiredQuantity: false,
        id: row.id,
      };

      await updateProjectOrderRows(
        [rowUpdate],
        currentUser,
        projectId,
        projectDetails.id
      );
      await getDataSource();
      setIsSupplierQtyUpdating(false);
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      ref.current && ref.current.blur();
    }
  };

  const qtyIsNotAlignedWithOrderStep =
    row.supplierQuantity % row.orderStep !== 0;

  const qtyIsLessThanMOQ =
    row.supplierQuantity < Math.max(row.moqPrices[0].moq, row.orderStep) &&
    row.supplierQuantity !== 0;

  const isThereError = qtyIsNotAlignedWithOrderStep || qtyIsLessThanMOQ;

  const supplierIsSelected =
    appState.buyerCurrentSuppliers
      ?.filter((supplier) => supplier.isSelected)
      .findIndex((supplier) => supplier.value === row.sellerId) !== -1;

  useEffect(() => {
    qtyIsNotAlignedWithOrderStep &&
      supplierIsSelected &&
      setIsExtraTableExpanded(true);
  }, [
    qtyIsNotAlignedWithOrderStep,
    setIsExtraTableExpanded,
    supplierIsSelected,
  ]);

  const target = useRef(null);

  return (
    <>
      {isSupplierQtyUpdating && isRequiredQtyUpdating && <FullScreenLoader />}
      <InputGroup
        ref={target}
        style={
          isHidden
            ? {
                visibility: 'hidden',
              }
            : {}
        }
        className={
          isThereError
            ? 'custom-project-table-input px-2 supplier-qty-error'
            : isFocused
            ? 'custom-project-table-input px-2 supplier-qty-focused'
            : 'custom-project-table-input px-2'
        }
      >
        <div style={{ width: '1rem' }}>
          {internalValue === 0 ? (
            <SkipForwardFill
              style={{ cursor: 'pointer' }}
              onClick={() => handleFillSupplierQty(row)}
              className='icon-ez me-3'
            />
          ) : (
            <X
              style={{ cursor: 'pointer' }}
              onClick={() => handleEmptySupplierQty(row)}
              className='icon-ez-red'
            />
          )}
        </div>
        <FormControl
          disabled={isHidden}
          readOnly={false}
          ref={ref}
          onKeyDown={handleKeyDown}
          className='borderless-input custom-supplier-qty-input text-end'
          defaultValue={row.supplierQuantity}
          value={parseInt(internalValue.toString()).toString()} // this is  quick fix only. Needs revision later.
          min={0}
          type='number'
          onFocus={() => setIsFocused(true)}
          onBlur={handleSaveRow}
          style={{ textAlign: 'right' }}
          onChange={handleChange}
        />

        <Row className='custom-project-table-input-row'>
          <CaretUpFill
            style={{ cursor: 'pointer' }}
            onClick={!false ? handleIncrement : () => {}}
            className='icon-ez'
          />
          {row.moqPrices.length < 2 ? (
            <CaretDownFill
              style={{ cursor: 'pointer' }}
              onClick={!false ? handleDecrement : () => {}}
              className={
                row.supplierQuantity >
                Math.max(row.moqPrices[0].moq, row.orderStep)
                  ? 'icon-ez'
                  : 'icon-ez-gray'
              }
            />
          ) : (
            <CaretDownFill
              style={{ cursor: 'pointer' }}
              onClick={!false ? handleDecrement : () => {}}
              className={
                row.supplierQuantity >
                Math.max(row.moqPrices[0].moq, row.orderStep)
                  ? 'icon-ez'
                  : 'icon-ez-gray'
              }
            />
          )}
        </Row>
      </InputGroup>

      <Overlay
        target={target.current}
        show={isThereError && supplierIsSelected}
        placement='top-start'
      >
        {(props) => (
          <Tooltip className='bg-light' id='overlay-example' {...props}>
            {qtyIsNotAlignedWithOrderStep &&
              t('projectSettings.err_OrderStepError', {
                orderStep: row.orderStep,
              })}

            {qtyIsLessThanMOQ &&
              t('projectSettings.err_MOQError', {
                moq: row.moqPrices[0].moq,
              })}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

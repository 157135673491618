import { GlobalNotifications } from 'components/seller/settings/sellerNotifications/GlobalNotifications';
import { PersonalNotifications } from 'components/seller/settings/sellerNotifications/PersonalNotifications';
import { AppContext } from 'contexts/user/AppContext';
import { useContext } from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const SellerNotifications = () => {
  const { t } = useTranslation('components');

  const { appState } = useContext(AppContext);

  const isAdmin = appState.userProfile?.roleNames.includes(
    'SellerAdministrator'
  );

  return (
    <Card className='p-4'>
      <Card.Header>
        <h4>{t('userSettings.lbl_Notifications')}</h4>
      </Card.Header>
      <Card.Body>
        <Row className='justify-content-between'>
          {/* Global notifications */}
          {isAdmin && <GlobalNotifications />}

          {/* Personal notifications */}
          <PersonalNotifications />
          <Col lg={2} />
        </Row>
      </Card.Body>
    </Card>
  );
};

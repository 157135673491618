import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  cellValue: string | number | null;
  updateRow: (updatedValue: any) => void;
  rowNo?: number;
  colName: string;
  rowsLength?: number;
  isNumberField?: boolean;
  isDocumentUneditable: boolean;
};

export const CustomExternalSellerRowInput = ({
  cellValue,
  updateRow,
  colName,
  rowNo,
  rowsLength,
  isNumberField = false,
  isDocumentUneditable,
}: PropTypes) => {
  const [value, setValue] = useState<any>(null);

  const { t } = useTranslation('components');

  useEffect(() => {
    setValue(cellValue);
  }, [cellValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    setValue(targetValue);

    updateRow(targetValue);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const targetValue = event.target.value;
    setValue(targetValue);
    updateRow(targetValue);
  };

  const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const tempValue = event?.target.value
      .replaceAll(/[A-Za-z!@#$%^&*()]/g, '')
      .replaceAll(/[.,]+/g, '.');

    setValue(tempValue);

    updateRow(tempValue);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      (event.key === 'Tab' || event.key === 'Enter') &&
      rowNo &&
      rowNo !== rowsLength
    ) {
      event.preventDefault();
      document.getElementById(`custom-price-id-${rowNo + 1}`)?.focus();

      (
        document.getElementById(
          `custom-price-id-${rowNo + 1}`
        ) as HTMLInputElement
      )?.select();
    }
  };

  return colName === 'expectedDelivery' ? (
    <Form.Select
      disabled={isDocumentUneditable}
      style={{
        width: '100px',
      }}
      value={cellValue?.toString()}
      onChange={handleSelectChange}
    >
      <option value={'null'}>-</option>
      <option value='In stock'>{t('common.lbl_Instock')}</option>
      {Array.from(Array(30).keys()).map((week, index) => (
        <option value={`${index + 1} ${index === 0 ? 'Week' : 'Weeks'}`}>{`${
          index + 1
        } ${
          index === 0 ? t('documents.lbl_Week') : t('documents.lbl_Weeks')
        }`}</option>
      ))}
    </Form.Select>
  ) : colName === 'price' ? (
    <FormControl
      disabled={isDocumentUneditable}
      max={100}
      maxLength={100}
      onKeyDown={handleKeyDown}
      onChange={handlePriceChange}
      value={value ?? ''}
      style={{ maxWidth: '100px', textAlign: 'right' }}
      type='text'
      id={`custom-price-id-${rowNo ?? null}`}
    />
  ) : (
    <InputGroup className='flex-nowrap'>
      <FormControl
        disabled={isDocumentUneditable}
        className='custom-external-seller-input'
        style={{
          width: colName === 'name' ? '300px' : '60px',
          textAlign: isNumberField ? 'right' : 'left',
        }}
        value={value}
        onChange={handleChange}
        type={isNumberField ? 'number' : 'text'}
      />
    </InputGroup>
  );
};

import { AppContext } from 'contexts/user/AppContext';
import { RowMOQPriceModel } from 'models/buyer/common/RowMOQPriceModel';
import { useContext } from 'react';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';

export const useGetFormattedPrice = () => {
  const { appState } = useContext(AppContext);

  const getFormattedPrice = (moq: RowMOQPriceModel) => {
    return moq
      ? formatMonetaryValue(
          moq.currencyCode,
          moq.price,
          appState,
          moq.decimalPlaces
        )
      : '';
  };

  return getFormattedPrice;
};

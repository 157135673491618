import {
  ChangeEvent,
  ImgHTMLAttributes,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import { Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { Trash, Upload } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import './EditableImageWithFallback.css';

type PropsType = ImgHTMLAttributes<any> & {
  fallback: string;
  maxFileSizekB: number;
  uploadProductImageRequirementsText: string;
  accept: string;
  handleImageUpload?: (file: File) => Promise<void>;
  handleImageDelete?: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
};

/** Renders the image, on error renders the fallback image.
 * Provides image upload and deletion functionality */
export const EditableImageWithFallback = ({
  fallback,
  maxFileSizekB,
  uploadProductImageRequirementsText,
  accept,
  handleImageUpload,
  handleImageDelete,
  src,
  ...props
}: PropsType) => {
  const { t } = useTranslation('components');
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);
  const [file, setFile] = useState<File>();
  const [isFallback, setIsFallback] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const onError = () => {
    setImgSrc(fallback);
    setIsFallback(true);
  };

  const handleShowUploadModal = () => {
    setFile(undefined);
    setShowUploadModal(true);
  };

  const handleCloseUploadModal = () => setShowUploadModal(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      return;
    }

    setFile(files[0]);
  };

  const handleUpload = () => {
    if (file) {
      const url = URL.createObjectURL(file);
      const img = new Image();
      img.onload = () => {
        let isValidImage = true;

        /*  Validate max size */
        if (file.size > maxFileSizekB * 1024) {
          isValidImage = false;
        }

        /*  Validate minimum dimensions */
        if (img.width < 300 || img.height < 300) {
          isValidImage = false;
        }

        URL.revokeObjectURL(img.src);

        if (!isValidImage) {
          toast.error(t('editableImageWithFallback.err_ImageNotOk'));
          return;
        }
        handleImageUpload?.(file);
        handleCloseUploadModal();
      };
      img.src = url;
    }
  };

  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDeleteConfirmation = (event: MouseEvent<HTMLButtonElement>) => {
    handleImageDelete?.(event);
    handleCloseDeleteModal();
  };

  useEffect(() => {
    if (src) {
      setIsFallback(false);
      setImgSrc(src);
    } else {
      setIsFallback(true);
      setImgSrc(fallback);
    }
  }, [src, fallback]);

  return (
    <div className='overlay-image-container'>
      <img src={imgSrc} alt='' onError={onError} {...props} />
      <div className='overlay-content'>
        <Button
          variant='btn btn-eleczap-noBorder'
          onClick={handleShowUploadModal}
        >
          <Upload />
        </Button>
        <Button
          variant='btn btn-eleczap-danger-noBorder'
          onClick={handleShowDeleteModal}
          disabled={isFallback}
        >
          <Trash />
        </Button>
      </div>
      {/* Upload modal */}
      <Modal show={showUploadModal} onHide={handleCloseUploadModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('editableImageWithFallback.hd_UploadImage')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('editableImageWithFallback.lbl_UploadImageDescription')}</p>
          <p>{uploadProductImageRequirementsText}</p>

          <InputGroup className='mb-3'>
            <label htmlFor='imageUpload' className='btn btn-primary'>
              {t('editableImageWithFallback.btn_SelectFile')}
            </label>
            <FormControl
              aria-label='Example text with button addon'
              aria-describedby='basic-addon1'
              placeholder={t('editableImageWithFallback.plh_NoFileSelected')}
              value={file?.name}
            />
          </InputGroup>
          <Form.Group className='mb-3'>
            <input
              type='file'
              id='imageUpload'
              name='imageUpload'
              className='inputfile'
              accept={accept}
              onChange={handleFileChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseUploadModal}>
            {t('common.btn_Close')}
          </Button>
          <Button
            variant='primary'
            disabled={file === undefined}
            onClick={handleUpload}
          >
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Delete modal */}
      <Modal size='sm' show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('editableImageWithFallback.hd_DeleteImage')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('editableImageWithFallback.lbl_DeleteImageDescription')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseDeleteModal}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='danger' onClick={handleDeleteConfirmation}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

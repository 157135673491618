import 'bootstrap/dist/css/bootstrap.min.css';
import { useFormikHookForStocksSharedWithMeUpdate } from 'components/seller/settings/stocks/stocksModals/EditSharedStock/hooksEditSharedStock';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksSharedWithMeModel } from 'models/shared/stocksSharedWithMe/StocksSharedWithMeModel';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

type PropTypes = {
  stock: StocksSharedWithMeModel;
  setStocks: (values: StocksSharedWithMeModel[]) => void;
  currentUser: LoginResponseUserModel;
  isDisabled: boolean;
  t: any;
  isAcceptingInvite?: boolean;
};

export const EditSharedStock = ({
  stock,
  setStocks,
  currentUser,
  isDisabled,
  t,
  isAcceptingInvite,
}: PropTypes) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const formik = useFormikHookForStocksSharedWithMeUpdate(
    currentUser,
    setStocks,
    setShow,
    stock,
    t
  );

  return (
    <>
      {isAcceptingInvite ? (
        <Button
          onClick={handleShow}
          disabled={isDisabled}
          className='btn btn-eleczap'
        >
          {t('common.btn_Accept')}
        </Button>
      ) : (
        <Button
          variant='btn btn-eleczap-noBorder'
          disabled={isDisabled}
          onClick={handleShow}
        >
          <PencilSquare />
        </Button>
      )}

      <Modal
        size='sm'
        show={show}
        onHide={handleClose}
        id='buyerUserDeactivate'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('stockSettings.hd_EditStock')} {stock.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-1' controlId='my.publicName'>
              <Form.Label>{t('stockSettings.lbl_DisplayedName')}</Form.Label>
              <Form.Control
                name='name'
                onChange={formik.handleChange}
                value={formik.values.name}
                type='input'
              />
            </Form.Group>
            <div className='text-muter mb-3'>
              {t('stockSettings.inf_EditStockInfo')}
            </div>
            <Form.Group className='mb-3' controlId='formBasicCheckbox'>
              <Form.Check
                name='displayDetails'
                onChange={formik.handleChange}
                type='switch'
                label={t('stockSettings.lbl_DisplayDetails')}
                checked={formik.values.displayDetails}
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>{t('addressSettings.lbl_AddressLine1')}</Form.Label>
              <Form.Control
                value={formik.values.stock.addressLine1}
                type='input'
                disabled
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>{t('addressSettings.lbl_AddressLine2')}</Form.Label>
              <Form.Control
                value={formik.values.stock.addressLine2}
                type='input'
                disabled
              />
            </Form.Group>
            <Row>
              <Form.Group
                as={Col}
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>{t('addressSettings.lbl_PostalCode')}</Form.Label>
                <Form.Control
                  value={formik.values.stock.postalCode}
                  type='input'
                  disabled
                />
              </Form.Group>
              <Form.Group
                as={Col}
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>{t('addressSettings.lbl_City')}</Form.Label>
                <Form.Control
                  value={formik.values.stock.city}
                  type='input'
                  disabled
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                as={Col}
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>{t('addressSettings.lbl_State')}</Form.Label>
                <Form.Control
                  value={formik.values.stock.state}
                  type='input'
                  disabled
                />
              </Form.Group>
              <Form.Group
                as={Col}
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>{t('addressSettings.lbl_Country')}</Form.Label>
                <Form.Control
                  value={formik.values.stock.countryName}
                  type='input'
                  disabled
                />
              </Form.Group>
            </Row>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>{t('stockSettings.lbl_AdditionalInfo')}</Form.Label>
              <Form.Control
                type='input'
                value={formik.values.stock.additionalInfo}
                disabled
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='btn btn-eleczap' onClick={formik.handleSubmit}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { SellerApi } from 'api/SellerApi';
import { useFormikHookForSellerGlobalNotifications } from 'components/seller/settings/sellerNotifications/hooksSellerNotifications';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { SellerGlobalNotificationsModel } from 'models/seller/notifications/SellerNotificationsModels';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const GlobalNotifications = () => {
  const [globalNotifications, setGlobalNotifications] =
    useState<SellerGlobalNotificationsModel>(
      {} as SellerGlobalNotificationsModel
    );

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const getGlobalNotifications = useCallback(async () => {
    const response = await SellerApi.getSellerGlobalNotifications(
      currentUser.companyId
    );

    setGlobalNotifications(response);
  }, [currentUser.companyId]);

  useEffect(() => {
    currentUser.companyId && getGlobalNotifications();
  }, [currentUser.companyId, getGlobalNotifications]);

  const formik = useFormikHookForSellerGlobalNotifications(globalNotifications);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    toast.dismiss();
    formik.handleChange(event);
    await formik.submitForm();
    toast.info(
      event.target.checked
        ? t('userSettings.inf_NotificationEnabled')
        : t('userSettings.inf_NotificationDisabled')
    );

    await getGlobalNotifications();
  };

  return (
    <Col lg={3}>
      <strong>{t('userSettings.lbl_Global')}</strong>
      <p>{t('userSettings.inf_Global')}</p>

      <Row>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.newInquiryReceived}
            name='newInquiryReceived'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_NewInquiryReceived')}</span>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col style={{ maxWidth: '40px' }}></Col>
        <Col>
          <Form.Group>
            <Form.Control
              onChange={formik.handleChange}
              name='newInquiryEmail'
              value={formik.values.newInquiryEmail}
              type='input'
              onBlur={(event) => formik.submitForm()}
              isInvalid={
                formik.touched.newInquiryEmail &&
                !!formik.errors.newInquiryEmail
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.newInquiryEmail}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            name='newOrderReceived'
            checked={formik.values.newOrderReceived}
            type='switch'
            aria-label='radio 1'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_NewOrderReceived')}</span>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col style={{ maxWidth: '40px' }}></Col>
        <Col>
          <Form.Group>
            <Form.Control
              name='newOrderEmail'
              onChange={formik.handleChange}
              value={formik.values.newOrderEmail}
              type='input'
              onBlur={(event) => formik.submitForm()}
              isInvalid={
                formik.touched.newOrderEmail && !!formik.errors.newOrderEmail
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.newOrderEmail}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Col>
  );
};

import { SellerApi } from 'api/SellerApi';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  ExternalSellerInquiryRowChangeType,
  ExternalSellerInquiryRowExtendedModel,
  ExternalSellerInquiryRowModel,
} from 'models/seller/documents/ExternalSellerInquiryModels';
import {
  ExternalSellerOfferRowExtendedModel,
  ExternalSellerOfferRowModel,
  ExternalSellerOfferUpsertRowModel,
} from 'models/seller/documents/ExternalSellerOfferModels';
import { InquiryModel } from 'models/seller/documents/InquiryModels';

export const getInquiry = async (
  currentUser: LoginResponseUserModel,
  inquiryId: number,
  localizationId: number
): Promise<InquiryModel> => {
  const response = await SellerApi.getInquiry(
    currentUser.companyId,
    inquiryId,
    localizationId
  );

  return response;
};

export const mapExternalSellerInquiryRows = (
  rows: ExternalSellerInquiryRowModel[]
): ExternalSellerInquiryRowExtendedModel[] => {
  let mappedRows: ExternalSellerInquiryRowExtendedModel[] = [];

  for (let row of rows) {
    const updatedRow: ExternalSellerInquiryRowExtendedModel = {
      ...row,
      replacedProductCode: null,
      replacedProductName: null,
      price: null,
      showReplacebleInputs: false,
      isNew: false,
      isReplaced: false,
    };

    mappedRows.push(updatedRow);
  }

  return mappedRows;
};

export const mapExternalSellerOfferRows = (
  rows: ExternalSellerOfferRowModel[]
): ExternalSellerOfferRowExtendedModel[] => {
  let mappedRows: ExternalSellerOfferRowExtendedModel[] = [];

  for (let row of rows) {
    const updatedRow: ExternalSellerOfferRowExtendedModel = {
      ...row,
      replacedProductCode: row.replacementCode,
      replacedProductName: row.replacementName,
    } as ExternalSellerOfferRowExtendedModel;

    mappedRows.push(updatedRow);
  }

  return mappedRows;
};

export const bulkEditExternalSellerOfferRows = (
  rows: ExternalSellerOfferRowExtendedModel[],
  fieldsToChange: {
    fieldName: 'code' | 'name' | 'qty' | 'price' | 'expectedDelivery';
    fieldValue: ExternalSellerInquiryRowChangeType;
  }
): ExternalSellerOfferRowExtendedModel[] => {
  let mappedRows: ExternalSellerOfferRowExtendedModel[] = [];

  const {
    replacedProductCode,
    replacedProductName,
    quantity,
    price,
    expectedDelivery,
  } = fieldsToChange?.fieldValue;

  for (let row of rows) {
    const updatedRow: ExternalSellerOfferRowExtendedModel = {
      ...row,
    } as ExternalSellerOfferRowExtendedModel;

    switch (fieldsToChange?.fieldName) {
      case 'expectedDelivery':
        updatedRow.expectedDelivery = expectedDelivery;
    }

    mappedRows.push(updatedRow);
  }

  return mappedRows;
};

export const bulkEditExternalSellerInquiryRows = (
  rows: ExternalSellerInquiryRowExtendedModel[],
  fieldsToChange: {
    fieldName: 'code' | 'name' | 'qty' | 'price' | 'expectedDelivery';
    fieldValue: ExternalSellerInquiryRowChangeType;
  }
): ExternalSellerInquiryRowExtendedModel[] => {
  let mappedRows: ExternalSellerInquiryRowExtendedModel[] = [];

  const {
    replacedProductCode,
    replacedProductName,
    quantity,
    price,
    expectedDelivery,
  } = fieldsToChange?.fieldValue;

  for (let row of rows) {
    const updatedRow: ExternalSellerInquiryRowExtendedModel = {
      ...row,
    } as ExternalSellerInquiryRowExtendedModel;

    switch (fieldsToChange?.fieldName) {
      case 'expectedDelivery':
        updatedRow.expectedDelivery = expectedDelivery;
    }

    mappedRows.push(updatedRow);
  }

  return mappedRows;
};

export const createExternalSellerOfferRows = (
  rows:
    | ExternalSellerOfferRowExtendedModel[]
    | ExternalSellerInquiryRowExtendedModel[]
): ExternalSellerOfferUpsertRowModel[] => {
  let mappedRows: ExternalSellerOfferUpsertRowModel[] = [];

  for (let row of rows) {
    const isReplaced =
      row.allowReplacement &&
      (row.replacedProductCode !== null || row.replacedProductName !== null);
    const updatedRow: ExternalSellerOfferUpsertRowModel = {
      isReplaced,
      rowNo: row.rowNo,
      productName: isReplaced ? row.replacedProductName : row.name,
      productCode: isReplaced ? row.replacedProductCode : row.productCode,
      price: row.price,
      isNew: row.isNew,
      quantity: row.quantity,
      expectedDelivery:
        row.expectedDelivery === 'null' ? null : row.expectedDelivery,
    };

    mappedRows.push(updatedRow);
  }

  return mappedRows;
};

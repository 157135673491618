/* Functions utilized in BuyerUsers.tsx (Users on buyer side settings)*/

import { ApplicationSettingsApi } from 'api/ApplicationSettingsApi';
import { CompanyApi } from 'api/CompanyApi';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { UserModel } from 'models/shared/user/UserModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { UserUpdateModel } from 'models/shared/user/UserUpdateModel';
import { UserUpdatePermissionsModel } from 'models/shared/user/UserUpdatePermissionsModel';
import { toast } from 'react-toastify';

/* Get all users */
export const getAllUsers = async (
  currentUser: LoginResponseUserModel,
  setUsers: (values: UserModel) => void,
  page?: number | undefined,
  searchString?: string | undefined,
  rowsPerPage?: number | undefined,
  sortColumn?: string | undefined
) => {
  const allUsers: UserModel = await CompanyApi.getUsers(
    currentUser.companyId,
    page,
    searchString,
    rowsPerPage,
    sortColumn
  );

  allUsers.data.forEach((user) => {
    user.fullName = `${user.firstName} ${user.lastName}`;
  });

  setUsers(allUsers);
};

export const handleUpdateUser = async (
  currentUser: LoginResponseUserModel,
  user: UserModelById,
  userUpdate: UserUpdateModel
) => {
  toast.dismiss();
  await CompanyApi.updateUser(currentUser.companyId, user, userUpdate);
};

export const handleUpdateUserPermissions = async (
  currentUser: LoginResponseUserModel,
  user: UserModelById,
  userUpdate: UserUpdatePermissionsModel
) => {
  toast.dismiss();
  await CompanyApi.updateUserPermissions(
    currentUser.companyId,
    user,
    userUpdate
  );
};

export const deleteUser = async (companyId: number, id: number) => {
  await CompanyApi.deleteUser(companyId, id);
};

export const getSellerRoles = async (): Promise<string[]> => {
  const roles: string[] = await ApplicationSettingsApi.getSellerRoles();
  return roles;
};

export const getBuyerRoles = async (): Promise<string[]> => {
  const roles: string[] = await ApplicationSettingsApi.getBuyerRoles();
  return roles;
};

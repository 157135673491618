import { IdentityApi } from 'api/IdentityApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { useFormik } from 'formik';
import { PasswordResetModel } from 'models/identity/PasswordResetModel';
import { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import '../../css/light.css';
import '../../css/site.css';
import './login.css';

export const ResetPassword = () => {
  const [resetToken, setResetToken] = useState<string>('');

  /* Get reset token from url */
  useEffect(() => {
    const queryString = window && window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('resetToken');
    token !== null && setResetToken(token);
  }, []);

  const { t } = useTranslation('components');

  const validationSchema = yup.object({
    username: yup
      .string()
      .required(t('userSettings.err_EmailRequired'))
      .email(t('userSettings.err_EmailInvalid'))
      .max(50, t('userSettings.err_EmailMax')),

    newPassword: yup
      .string()
      .required(t('changePassword.err_NewPasswordRequired'))
      .min(8, t('changePassword.err_NewPasswordLength')),

    confirmNewPassword: yup
      .string()
      .required(t('changePassword.err_ConfirmNewPasswordRequired'))
      .oneOf(
        [yup.ref('newPassword'), null],
        t('changePassword.err_PasswordsMustMatch')
      ),
  });

  const initialValues: PasswordResetModel = {
    username: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const formik = useFormik<PasswordResetModel>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values: PasswordResetModel) => {
      const passwordReset: PasswordResetModel = {
        username: values.username,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
      };
      await handleSubmit(passwordReset);
      window.location.replace(window && window.location.origin);
      formik.setSubmitting(false);
    },
  });

  const handleSubmit = async (
    passwordReset: PasswordResetModel
  ): Promise<void> => {
    toast.dismiss();
    await IdentityApi.resetPassword(resetToken, passwordReset);

    SuccessToast(t('forgotPassword.inf_PasswordChangeSuccess'));
  };

  return (
    <div className='wrapper'>
      <div className='main d-flex justify-content-center w-100'>
        <div className='content d-flex p-0'>
          <div className='container d-flex flex-column'>
            <div className='row h-100'>
              <div className='col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100'>
                <div className='d-table-cell align-middle'>
                  <div className='text-center mb-4 mt-4'>
                    <h1>{t('forgotPassword.btn_ResetPassword')}</h1>
                  </div>
                  <Card>
                    <Card.Body>
                      <Form onSubmit={formik.handleSubmit}>
                        <Form.Group
                          className='mb-3 form-control-lg'
                          controlId='formPassword'
                        >
                          <Form.Label>{t('userSettings.lbl_Email')}</Form.Label>
                          <Form.Control
                            name='username'
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            type='email'
                            placeholder='Enter your email'
                            isInvalid={
                              formik.touched.username &&
                              !!formik.errors.username
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {formik.errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className='mb-3 form-control-lg'
                          controlId='formPassword'
                        >
                          <Form.Label>
                            {t('changePassword.lbl_NewPassword')}
                          </Form.Label>
                          <Form.Control
                            name='newPassword'
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            type='password'
                            placeholder={t(
                              'changePassword.plh_EnterNewPassword'
                            )}
                            isInvalid={
                              formik.touched.newPassword &&
                              !!formik.errors.newPassword
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {formik.errors.newPassword}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className='mb-3 form-control-lg'
                          controlId='formPassword'
                        >
                          <Form.Label>
                            {t('changePassword.lbl_ConfirmPassword')}
                          </Form.Label>
                          <Form.Control
                            name='confirmNewPassword'
                            value={formik.values.confirmNewPassword}
                            onChange={formik.handleChange}
                            type='password'
                            placeholder={t(
                              'changePassword.plh_ConfirmNewPassword'
                            )}
                            isInvalid={
                              formik.touched.confirmNewPassword &&
                              !!formik.errors.confirmNewPassword
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {formik.errors.confirmNewPassword}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <div className='text-center'>
                          <Button
                            className='btn btn-primary btn-lg'
                            type='submit'
                            disabled={formik.isSubmitting}
                          >
                            {t('forgotPassword.btn_ResetPassword')}
                          </Button>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

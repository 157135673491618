import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import { Trash } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';

import { useState } from 'react';
import {
  AddressModel,
  AddressModelById,
} from 'models/shared/address/AddressModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { BuyerApi } from 'api/BuyerApi';
import { getAllAddresses } from './utilsDeliveryAddresses';

type PropTypes = {
  address: AddressModelById;
  setAddresses: (values: AddressModel) => void;
  currentUser: LoginResponseUserModel;
  t: any;
};

export const DeliveryAddressesDelete = ({
  address,
  setAddresses,
  currentUser,
  t,
}: PropTypes) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = async () => {
    await BuyerApi.deleteDeliveryAddresses(
      currentUser.companyId,
      currentUser.id,
      address.id
    );

    await getAllAddresses(currentUser, setAddresses);
  };

  return (
    <>
      <Button variant='btn btn-eleczap-danger-noBorder' onClick={handleShow}>
        <Trash />
      </Button>

      {/*          Delete a delivery address modal*/}
      <Modal size='sm' show={show} onHide={handleClose} id='deleteAddress'>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('addressSettings.hd_DeleteAddressShort')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('addressSettings.inf_DeleteAddressBody_part1')}{' '}
          <strong>{address.name}</strong> ?<br />
          {t('addressSettings.inf_DeleteAddressBody_part2')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='danger' onClick={handleDelete}>
            {t('common.btn_Delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

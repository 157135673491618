import { IdentityApi } from 'api/IdentityApi';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { getUrlParams } from 'utils/getUrlParams';

export const BuyerRegistrationConfirmation = () => {
  const [registrationToken, setRegistrationToken] = useState<string>('');
  const [isRegistrationSuccessfull, setIsRegistrationSuccessfull] =
    useState<boolean>(false);

  const [counter, setCounter] = useState<number>(5);

  const navigate = useHistory();

  const { t } = useTranslation('components');

  /* Get invitation token from url */
  useEffect(() => {
    const token = getUrlParams('registrationToken');
    token !== null && setRegistrationToken(token);
  }, []);

  useEffect(() => {
    const registerBuyer = async () => {
      if (registrationToken !== null && registrationToken !== '') {
        await IdentityApi.completeBuyerRegistration(registrationToken);

        setIsRegistrationSuccessfull(true);
      }
    };
    registerBuyer();
  }, [registrationToken]);

  useEffect(() => {
    if (isRegistrationSuccessfull)
      counter > 0
        ? setTimeout(() => setCounter(counter - 1), 1000)
        : navigate.push('/');
  }, [counter, isRegistrationSuccessfull, navigate]);

  return isRegistrationSuccessfull ? (
    <div
      style={{ height: '100vh' }}
      className='d-flex flex-column justify-content-center align-items-center'
    >
      {' '}
      <h1>{t('buyerRegistration.inf_RegistrationSuccessfull')}</h1>{' '}
      <span className='my-1'>
        {t('buyerRegistration.inf_RedirectingInSeconds', { counter })}
      </span>
      <h3>
        <Link className='btn-link-bold mt-2' to={'/'}>
          {t('buyerRegistration.lbl_GoToLogin')} <ArrowRight />
        </Link>
      </h3>{' '}
    </div>
  ) : (
    <div
      style={{ height: '100vh' }}
      className='d-flex justify-content-center align-items-center'
    >
      <h2>
        {t('buyerRegistration.inf_CheckingRegistrationDetails')}{' '}
        <Spinner animation='border' />
      </h2>
    </div>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import {
  ProductBreadcrumb,
  ProductBreadcrumbItem,
} from 'components/shared/products/ProductBreadcrumb';
import 'components/shared/products/ProductDetails/ProductDetails.css';
import { ProductDetailsBody } from 'components/shared/products/ProductDetails/ProductDetailsBody';
import { ProductDetailsHeader } from 'components/shared/products/ProductDetails/ProductDetailsHeader';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductDetailsModel } from 'models/shared/product/ProductDetailsModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useParams } from 'react-router';

export const ProjectProductDetails = (): JSX.Element => {
  return <ProductDetailsComponent showBreadCrumb={false} />;
};

export const ProductDetails = (): JSX.Element => {
  return <ProductDetailsComponent showBreadCrumb={true} />;
};

type UrlParamsType = {
  productCode: string;
};

type ProductDetailsParamsType = {
  showBreadCrumb: boolean;
};

const ProductDetailsComponent = (
  props: ProductDetailsParamsType
): JSX.Element => {
  let { productCode } = useParams<UrlParamsType>();

  const [products, setProducts] = useState<ProductDetailsModel[] | null>(null);
  const [filteredProducts, setFilteredProducts] = useState<
    ProductDetailsModel[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [productBreadCrumbItems, setproductBreadCrumbItems] = useState<
    ProductBreadcrumbItem[]
  >([]);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  useEffect(() => {
    setIsLoading(true);
    const getProducts = async () => {
      try {
        const productDetails = await BuyerApi.getProductsByCode(
          currentUser.companyId,
          productCode,
          userLocaleId
        );
        productDetails.forEach((element) => {
          element.sellerCompanyId = element.company.id;
          element.sellerCompanyName = element.company.name;
        });
        setProducts(productDetails);

        const currentSupplierIdSelectedFromTree =
          appState.buyerCurrentSupplierId;

        const productTreeToDisplayBreadcrumbsFrom = productDetails.find(
          (product) => product.company.id === currentSupplierIdSelectedFromTree
        );

        if (
          props.showBreadCrumb &&
          productTreeToDisplayBreadcrumbsFrom !== undefined
        ) {
          const breadCrumbItems =
            productTreeToDisplayBreadcrumbsFrom.productClasses[0].parents.map<ProductBreadcrumbItem>(
              (c) => ({
                name: c.name,
                classId: c.id,
                classLevel: c.level,
                linkType: c.linkType,
                code: c.code,
                sellerId: productTreeToDisplayBreadcrumbsFrom.sellerCompanyId,
              })
            );

          breadCrumbItems.push({
            name: productTreeToDisplayBreadcrumbsFrom.productClasses[0].name,
            classId: productTreeToDisplayBreadcrumbsFrom.productClasses[0].id,
            classLevel:
              productTreeToDisplayBreadcrumbsFrom.productClasses[0].level,
            linkType:
              productTreeToDisplayBreadcrumbsFrom.productClasses[0].linkType,
            code: productTreeToDisplayBreadcrumbsFrom.productClasses[0].code,
          });

          breadCrumbItems.push({
            name: productDetails[0].name,
            classId: 0,
            classLevel: 0,
            linkType: 'ThisClassProducts',
          });

          setproductBreadCrumbItems(breadCrumbItems);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    getProducts();
  }, [
    appState.buyerCurrentSupplierId,
    currentUser,
    productCode,
    props.showBreadCrumb,
    userLocaleId,
  ]);

  useEffect(() => {
    const filterFn = (data: ProductDetailsModel[]) =>
      data.filter((x) => {
        if (appState.buyerCurrentSuppliers)
          return (
            appState.buyerCurrentSuppliers.findIndex(
              (v) =>
                v.isActive &&
                v.isSelected &&
                x.company.id === v.value &&
                !v.isMarketplace
            ) > -1 ||
            appState.buyerCurrentSuppliers.findIndex(
              (v) =>
                v.isActive &&
                v.isSelected &&
                v.subSellers?.some(
                  (subSeller) =>
                    x.company.id === subSeller.value && subSeller.isSelected
                )
            ) > -1
          );
      });

    products && setFilteredProducts(filterFn(products));
  }, [appState.buyerCurrentSuppliers, products]);

  return isLoading ? (
    <Spinner className='center-spinner' animation='border' />
  ) : (
    <>
      <ProductBreadcrumb
        items={productBreadCrumbItems}
        productClassesRoute='/buyer/product-classes/'
        productSubClassesRoute='/buyer/product-subclasses/'
      />
      {filteredProducts && filteredProducts.length > 0 ? (
        <div className='container-fluid p-4'>
          <Card className='py-4 px-0 product-details-card-container'>
            <ProductDetailsHeader
              products={filteredProducts}
              viewType='Buyer'
              isAddToCartVisible
            />
            <ProductDetailsBody products={filteredProducts} />
          </Card>
        </div>
      ) : null}
    </>
  );
};

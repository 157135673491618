import {
  getAllRowsWithSameCode,
  getExternalContacts,
} from 'components/buyer/documents/utilsOfferComparison';
import { AppContext } from 'contexts/user/AppContext';
import {
  OfferComparisonModel,
  OfferComparisonOfferExtendModel,
  OfferComparisonOfferRowExtendedModel,
} from 'models/buyer/documents/OfferModels';
import {
  OrderCreateForSessionModel,
  OrderRowModel,
} from 'models/buyer/documents/OrderModels';
import { ProjectModel } from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useHistory } from 'react-router';

export const useHandleOrderFromOffer = () => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);

  const history = useHistory();

  const handleOrderFromOffer = async (
    projectId: number,
    projectName: string,
    offer: OfferComparisonOfferExtendModel,
    dealGroupId: number,
    setShowDrawer: (value: boolean) => void
  ): Promise<void> => {
    let order: OrderCreateForSessionModel = {} as OrderCreateForSessionModel;

    order.comment = '';
    order.deliveryAddressId = 0;
    order.project = {
      id: projectId,
      name: projectName,
    } as ProjectModel;
    order.requestedDeliveryTime = offer.deliveryDate;
    order.paymentTerm = offer.paymentTerm;
    order.deliveryTerm = offer.deliveryTerm;

    /* Map project rows to order rows */
    const data = offer.rows;

    let orderRows: OrderRowModel[] = [];

    data.forEach((row) => {
      const orderRow: OrderRowModel = {
        id: row.id,
        productId: row.productId,
        rowNo: row.rowNo,
        productCode: row.isReplaced ? row.replacementCode : row.productCode,
        name: row.isReplaced ? row.replacementName : row.productName,
        // quantity: row.quantity, TODO: Martti qty is missing
        quantity: row.quantity,
        unit: row.unit,
        price: row.price,
        priceWithDiscount: row.priceWithDiscount,
        discount: 0,
        total: row.total,
        requestedDeliveryTime: '',
        deliveryDate: '',
        deliveredCount: 0,
        invoicedCount: 0,
        decimalPlaces: row.decimalPlaces,
        isReplaced: row.isReplaced,
        initialCode: row.isReplaced ? row.productCode : null,
        initialName: row.isReplaced ? row.productName : null,
      };
      orderRows.push(orderRow);
    });

    order.totalSum = offer.total;
    order.currencyCode = offer.currencyCode;

    order.isExternalSeller =
      appState.buyerCurrentSuppliers?.find(
        (seller) => seller.value === offer.sellerCompanyId
      )?.isExternalSeller ?? false;

    order.sentId = null;

    order.rows = orderRows;

    order.sellerCompanyId = offer.sellerCompanyId;
    order.sellerCompanyName = offer.sellerCompanyName;

    /* If external seller, set contact id as well */

    if (order.isExternalSeller) {
      const externalSellerContacts = await getExternalContacts(
        order.sellerCompanyId,
        currentUser
      );

      const externalContactId = externalSellerContacts.find(
        (contact) =>
          `${contact.firstName} ${contact.lastName}` === offer.sentByContactName
      )?.id;

      order.externalSellerContactId = externalContactId ?? null;
    } else order.externalSellerContactId = null;

    sessionStorage.removeItem('orders_unsent');

    sessionStorage.setItem('orders_unsent', JSON.stringify([order]));

    // TODO: Martti offer needs to have its id returned as well in comparison response. offer.id

    const offerId = offer.id ?? 0;

    history.replace(
      `/buyer/offer-comparison/${dealGroupId}?offerId=${offerId}&offerCode=${offer.code}&dealId=${offer.dealId}`
    );

    setShowDrawer(true);
  };

  return handleOrderFromOffer;
};

export const useHandleOrderSelectedPricesFromOffer = () => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);

  const history = useHistory();

  const handleOrderSelectedPricesFromOffer = async (
    projectId: number,
    projectName: string,
    offer: OfferComparisonOfferExtendModel,
    dealGroupId: number,
    setShowDrawer: (value: boolean) => void
  ): Promise<void> => {
    let order: OrderCreateForSessionModel = {} as OrderCreateForSessionModel;

    order.comment = offer.comment;
    order.deliveryAddressId = 0;
    order.project = {
      id: projectId,
      name: projectName,
    } as ProjectModel;
    order.requestedDeliveryTime = offer.deliveryDate;
    order.paymentTerm = offer.paymentTerm;
    order.deliveryTerm = offer.deliveryTerm;

    /* Map project rows to order rows */
    const data = offer.rows;

    let orderRows: OrderRowModel[] = [];

    data.forEach((row) => {
      if (row.isSelected || row.isNew) {
        const orderRow: OrderRowModel = {
          id: row.id,
          productId: row.productId,
          rowNo: row.rowNo,
          productCode: row.isReplaced ? row.replacementCode : row.productCode,
          name: row.isReplaced ? row.replacementName : row.productName,
          // quantity: row.quantity, TODO: Martti qty is missing
          quantity: row.quantity,
          unit: row.unit,
          price: row.price,
          priceWithDiscount: row.priceWithDiscount,
          discount: 0,
          total: row.total,
          requestedDeliveryTime: '',
          deliveryDate: '',
          deliveredCount: 0,
          invoicedCount: 0,
          decimalPlaces: row.decimalPlaces,
          isReplaced: row.isReplaced,
          initialCode: row.isReplaced ? row.productCode : null,
          initialName: row.isReplaced ? row.productName : null,
        };
        orderRows.push(orderRow);
      }
    });

    order.totalSum = offer.total;
    order.currencyCode = offer.currencyCode;

    order.isExternalSeller =
      appState.buyerCurrentSuppliers?.find(
        (seller) => seller.value === offer.sellerCompanyId
      )?.isExternalSeller ?? false;

    order.sentId = null;

    order.rows = orderRows;

    order.sellerCompanyId = offer.sellerCompanyId;
    order.sellerCompanyName = offer.sellerCompanyName;

    /* If external seller, set contact id as well */

    if (order.isExternalSeller) {
      const externalSellerContacts = await getExternalContacts(
        order.sellerCompanyId,
        currentUser
      );

      const externalContactId = externalSellerContacts.find(
        (contact) =>
          `${contact.firstName} ${contact.lastName}` === offer.sentByContactName
      )?.id;

      order.externalSellerContactId = externalContactId ?? null;
    } else order.externalSellerContactId = null;

    sessionStorage.removeItem('orders_unsent');

    sessionStorage.setItem('orders_unsent', JSON.stringify([order]));

    // TODO: Martti offer needs to have its id returned as well in comparison response. offer.id

    const offerId = offer.id ?? 0;

    history.replace(
      `/buyer/offer-comparison/${dealGroupId}?offerId=${offerId}&offerCode=${offer.code}&dealId=${offer.dealId}&cherryPicked=true`
    );

    setShowDrawer(true);
  };

  return handleOrderSelectedPricesFromOffer;
};

export const useGetBestPrices = () => {
  const { appState } = useContext(AppContext);

  const getBestPrices = (
    row: OfferComparisonOfferRowExtendedModel,
    offerComparison: OfferComparisonModel
  ) => {
    const allPricesWithSameCode = getAllRowsWithSameCode(
      row,
      offerComparison,
      appState
    );

    const sortedPrices = allPricesWithSameCode.sort((a, b) => {
      const aValue = a.priceWithDiscount ?? 0;
      const bValue = b.priceWithDiscount ?? 0;

      return aValue - bValue;
    });

    const minPriceIndex = sortedPrices.findIndex(
      (row) =>
        row !== null &&
        row.priceWithDiscount !== null &&
        row.priceWithDiscount !== 0
    );

    const bestPrice = sortedPrices[minPriceIndex];
    const secondBestPrice = sortedPrices[minPriceIndex + 1];

    return [bestPrice, secondBestPrice];
  };

  return getBestPrices;
};

export const useGetBestDeliveryDates = () => {
  const { appState } = useContext(AppContext);

  const getBestPrices = (
    row: OfferComparisonOfferRowExtendedModel,
    offerComparison: OfferComparisonModel
  ) => {
    const allPricesWithSameCode = getAllRowsWithSameCode(
      row,
      offerComparison,
      appState
    );

    const allDeliveries = allPricesWithSameCode.map(
      (row) => row.expectedDelivery
    );

    const sortedPrices = allDeliveries.sort();

    let minPriceIndex = sortedPrices.findIndex((row) => row === 'In stock');
    let secondBestDate;

    if (minPriceIndex === -1) {
      minPriceIndex = sortedPrices.findIndex((row) => row !== null);
      secondBestDate = sortedPrices[minPriceIndex + 1];
    } else {
      secondBestDate =
        sortedPrices[
          sortedPrices.findIndex((row) => row !== null && row !== 'In stock')
        ];
    }

    const bestDate = sortedPrices[minPriceIndex];

    return [bestDate, secondBestDate];
  };

  return getBestPrices;
};

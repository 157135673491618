import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext } from 'react';
import { useAuthUser } from 'react-auth-kit';

export const useRefreshAllSuppliers = () => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState, appContextDispatch } = useContext(AppContext);

  const updateAllSuppliers = async (suppliers: BuyerCurrentSuppliers[]) => {
    appContextDispatch({
      type: 'BUYER_SET_CURRENT_SUPPLIERS',
      suppliers: suppliers || null,
    });
    localStorage.removeItem(`_buyerCurrentSuppliers_${currentUser.id}`);
    localStorage.setItem(
      `_buyerCurrentSuppliers_${currentUser.id}`,
      JSON.stringify(suppliers)
    );

    appContextDispatch({
      type: 'BUYER_SET_ACTIVE_SELLER_COUNT',
      activeSupplierCount: suppliers.filter((x) => x.isActive).length || null,
    });
  };

  const refreshAllSuppliers = async () => {
    const freshAllSupplierForAppState =
      await BuyerApi.getSellersCompaniesAndContacts(currentUser.companyId);

    updateAllSuppliers(
      freshAllSupplierForAppState.data.map((y) => {
        return {
          isActive: y.isActive,
          name: y.shortName ?? y.name,
          value: y.id,
          isExternalSeller: y.isExternalSeller,
          isArchived: y.isArchived,
          isFavourite: y.isFavoriteSeller,
          isSelected:
            appState?.buyerCurrentSuppliers?.find(
              (appStateSupplier) => appStateSupplier.value === y.id
            )?.isSelected ?? true,
          logo: y.logo,
          currencyCode: y?.currencyCode,
          isMarketplace: y?.isMarketplace,
          isSubSeller: y?.isSubSeller,
          subSellers: y?.subSellers
            ? y?.subSellers.map((subSeller) => {
                return {
                  isActive: subSeller.isActive,
                  name: subSeller.shortName,
                  value: subSeller.id,
                  isExternalSeller: subSeller.isExternalSeller,
                  isArchived: subSeller.isArchived,
                  isFavourite: subSeller.isFavoriteSeller,
                  isSelected:
                    appState?.buyerCurrentSuppliers
                      ?.find(
                        (appStateSupplier) => appStateSupplier.value === y.id
                      )
                      ?.subSellers?.find((subSeller) =>
                        y.subSellers?.find(
                          (ySubSeller) => ySubSeller.id === subSeller.value
                        )
                      )?.isSelected ?? true,
                  logo: subSeller.logo,
                  currencyCode: subSeller?.currencyCode,
                  isMarketplace: subSeller?.isMarketplace,
                  subSellers: null,
                  isSubSeller: subSeller?.isSubSeller,
                };
              })
            : null,
        };
      })
    );
  };

  return refreshAllSuppliers;
};

export const truncateDecimals = (number: number, digits: number): number => {
  if (number === undefined) {
    return number;
  }
  if (digits === 0) {
    return parseFloat(
      (
        Math.floor(number * Math.pow(10, digits)) / Math.pow(10, digits)
      ).toFixed(digits)
    );
  }

  const decimals = digits ? digits : 2;
  const asString = number.toString(); //Tomas problem
  const pos =
    asString.indexOf('.') !== -1
      ? asString.indexOf('.') + decimals + 1
      : asString.length;
  return parseFloat(number.toString().substring(0, pos));
};

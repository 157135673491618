import { SellerApi } from 'api/SellerApi';
import { useFormikHookForSellerPersonalNotifications } from 'components/seller/settings/sellerNotifications/hooksSellerNotifications';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { SellerPeronalNotificationsModel } from 'models/seller/notifications/SellerNotificationsModels';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const PersonalNotifications = () => {
  const [personalNotifications, setPersonalNotifications] =
    useState<SellerPeronalNotificationsModel>(
      {} as SellerPeronalNotificationsModel
    );

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const getPersonalNotifications = useCallback(async () => {
    const response = await SellerApi.getSellerPersonalNotifications(
      currentUser.companyId,
      currentUser.id
    );

    setPersonalNotifications(response);
  }, [currentUser.companyId, currentUser.id]);

  useEffect(() => {
    currentUser.companyId && currentUser.id && getPersonalNotifications();
  }, [currentUser.companyId, currentUser.id, getPersonalNotifications]);

  const formik = useFormikHookForSellerPersonalNotifications(
    personalNotifications
  );

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    toast.dismiss();
    formik.handleChange(event);
    await formik.submitForm();
    toast.info(
      event.target.checked
        ? t('userSettings.inf_NotificationEnabled')
        : t('userSettings.inf_NotificationDisabled')
    );

    await getPersonalNotifications();
  };

  return (
    <Col lg={3}>
      <strong>{t('userSettings.lbl_Personal')}</strong>
      <p>{t('userSettings.inf_Personal')}</p>

      <strong>{t('userSettings.lbl_Inquires')}</strong>
      <Row className='my-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.inquiryReceived}
            name='inquiryReceived'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_NewInquiryReceived')}</span>
        </Col>
      </Row>

      <strong>{t('userSettings.lbl_Offers')}</strong>
      {/* <Row className='mt-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.offerDeclined}
            name='offerDeclined'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_OfferDeclined')}</span>
        </Col>
      </Row> */}

      <Row className='my-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.offerAccepted}
            name='offerAccepted'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_OfferAccepted')}</span>
        </Col>
      </Row>

      <strong>{t('userSettings.lbl_Orders')}</strong>
      <Row className='mt-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.orderReceived}
            name='orderReceived'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_NewOrderReceived')}</span>
        </Col>
      </Row>
    </Col>
  );
};

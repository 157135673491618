import { useQueryClient } from '@tanstack/react-query';
import { BuyerApi } from 'api/BuyerApi';
import { InquiryDisabled } from 'assets/icons/StatusIcons';
import { DeliveryAddressesNew } from 'components/buyer/settings/deliveryAddresses/DeliveryAddressNew/DeliveryAddressesNew';
import {
  getAddressById,
  getAllAddresses,
} from 'components/buyer/settings/deliveryAddresses/utilsDeliveryAddresses';
import { SupplierProfileCard } from 'components/buyer/supplierInfo/SupplierProfileCard';
import { useGetSupplierCompanyInfo } from 'components/buyer/supplierInfo/hooksSupplierInfo';
import { getSupplierCreditInfo } from 'components/buyer/supplierInfo/utilsSupplierInfo';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { ExternalSellerContactModel } from 'models/buyer/contacts/ExternalSellerContactsModel';
import {
  InquiryCreateForSessionModel,
  InquiryCreateModel,
  InquiryRowsModel,
  InquiryUpdateModel,
} from 'models/buyer/documents/InquiryModels';
import { SellerCreditInfoModel } from 'models/buyer/suppliers/SellerCreditInfoModel';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  AddressModel,
  AddressModelById,
} from 'models/shared/address/AddressModel';
import {
  createInquiry,
  getInquiry,
  updateInquiry,
  updateInquiryRow,
} from 'pages/buyer/Inquiry/utilsInquiry';
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Popover,
  Row,
} from 'react-bootstrap';
import { Clipboard, Trash, X } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import './Inquiry.css';

dayjs.extend(utc);

type PropTypes = {
  inquiryProp: InquiryCreateForSessionModel;
  handleDiscardInquiry: (sellerId: number) => void;
  setIsInquirying: (value: boolean) => void;
  updateInquirySentId: (inquiryProp: InquiryCreateForSessionModel) => void;
};

export const InquiryUnsent = ({
  inquiryProp,
  handleDiscardInquiry,
  setIsInquirying,
  updateInquirySentId,
}: PropTypes) => {
  const [inquiryDetails, setInquiryDetails] =
    useState<InquiryCreateForSessionModel>({} as InquiryCreateForSessionModel);
  const [inquiryNumber, setInquiryNumber] = useState<string>('');
  const [requestedDate, setRequestedDate] = useState<string | null>('');
  const [deliveryAddressId, setDeliveryAddressId] = useState<number>(0);
  const [comment, setComment] = useState<string>('');
  const [addresses, setAddresses] = useState<AddressModel>({} as AddressModel);
  const [filteredAddresses, setFilteredAddresses] = useState<
    AddressModelById[]
  >([]);
  const [asOptionShow, setAsOptionShow] = useState<boolean>(false);
  const [isoDate, setIsoDate] = useState<string | null>(null);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [creditInfo, setCreditInfo] = useState<SellerCreditInfoModel>(
    {} as SellerCreditInfoModel
  );

  const [company, setCompany] = useState<SupplierCompanyModel>(
    {} as SupplierCompanyModel
  );

  const [externalContacts, setExternalContacts] = useState<
    ExternalSellerContactModel[]
  >([]);

  const [selectedExternalContact, setSelectedExternalContact] = useState<
    number | null
  >(null);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const { t } = useTranslation('components');
  const validateUnitExists = useValidateUnitExists();

  const queryClient = useQueryClient();

  const sellerCompanyId = inquiryProp.sellerCompanyId;

  /* Get external contacts */
  useEffect(() => {
    const getExternalContacts = async () => {
      const response = await BuyerApi.getExternalSellerContacts(
        currentUser.companyId,
        sellerCompanyId
      );

      setExternalContacts(response);
      setSelectedExternalContact(response[0].id);
    };

    inquiryDetails.isExternalSeller && getExternalContacts();
  }, [currentUser.companyId, inquiryDetails.isExternalSeller, sellerCompanyId]);

  /* Get all addresses */
  useEffect(() => {
    const getAddresses = async () => {
      await getAllAddresses(
        currentUser,
        setAddresses,
        undefined,
        undefined,
        undefined,
        'isDefault_desc'
      );
    };

    getAddresses();
  }, [currentUser]);

  useEffect(() => {
    /* filter out not visible address */

    let clonedAddress = { ...addresses };

    if (clonedAddress.data !== undefined) {
      const filtered = clonedAddress.data.filter(
        (address) => address.isVisible
      );

      setFilteredAddresses(filtered);
    }
  }, [addresses]);

  useEffect(() => {
    addresses.data !== undefined &&
      addresses.data[0] !== undefined &&
      setDeliveryAddressId(addresses.data[0].id);
  }, [addresses.data]);

  /* Set inquiry details */
  useEffect(() => {
    setInquiryDetails(inquiryProp);

    setDeliveryAddressId(inquiryProp.deliveryAddressId);
    setComment(inquiryProp.comment);
  }, [inquiryProp]);

  const handleTargetPriceChange = (
    event: ChangeEvent<HTMLInputElement>,
    row: InquiryRowsModel
  ) => {
    let clonedData = { ...inquiryDetails };

    clonedData.rows.forEach((item) => {
      if (item.id === row.id) {
        item.targetPrice = parseFloat(event.target.value);
      }
    });

    setInquiryDetails(clonedData);
  };

  const handleAllowReplacementChange = (
    event: ChangeEvent<HTMLInputElement>,
    row: InquiryRowsModel
  ) => {
    let clonedData = { ...inquiryDetails };

    clonedData.rows.forEach((item) => {
      if (item.id === row.id) {
        item.allowReplacement = event.target.checked;
      }
    });

    setInquiryDetails(clonedData);
  };

  const columns: TableColumn<InquiryRowsModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      sortable: true,
      width: '60px',
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode,
      cell: (row) =>
        row.productId === null ? (
          <span>{row.productCode}</span>
        ) : (
          <Link
            to={`/buyer/product-details/${row.productCode}`}
            className='btn-link-bold'
          >
            {row.productCode}
          </Link>
        ),
      sortable: true,
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name,
      sortable: true,
      grow: 6,
    },
    {
      name: t('documents.lbl_Qty'),
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          <span>
            {row.quantity} <small>{validateUnitExists(row.unit)}</small>
          </span>
        </>
      ),
      sortable: true,
      right: true,
    },
    {
      name: t('documents.lbl_ListPrice'),
      selector: (row) => row.price ?? 'price',
      cell: (row) =>
        formatMonetaryValue(
          inquiryDetails.currencyCode,
          row.price,
          appState,
          row.decimalPlaces
        ),
      sortable: true,
      right: true,
    },
    {
      name: t('documents.lbl_TargetPrice'),
      selector: (row) =>
        row.targetPrice !== null ? row.targetPrice?.toString() : 'targetPrice',
      cell: (row) => (
        <FormControl
          max={100}
          maxLength={100}
          className='inquiry-unsent-price-cell'
          placeholder={t('documents.plh_EnterPrice')}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleTargetPriceChange(event, row)
          }
          style={{ maxWidth: '100px' }}
          type='number'
        />
      ),
      sortable: true,
      center: true,
    },
    {
      name: t('documents.lbl_AllowReplacements'),
      selector: (row) => row.allowReplacement,
      cell: (row) => (
        <Form.Check
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleAllowReplacementChange(event, row)
          }
          type='switch'
          checked={row.allowReplacement}
          aria-label='radio 1'
        />
      ),
      sortable: true,
      center: true,
    },
  ];

  const handleDateChange = async (day: Date) => {
    const pickedDate = dayjs(day).format('DD-MM-YYYY');

    setRequestedDate(pickedDate);
    setIsoDate(dayjs(day).toISOString());
  };

  const handleDeliveryChange = async (event: FormEvent<HTMLSelectElement>) => {
    if (event.currentTarget.value === 'addNewAddress') {
      setAsOptionShow(true);
    } else {
      setDeliveryAddressId(parseInt(event.currentTarget.value));
    }
  };

  const setNewDeliverAddressAsSelected = async (value: number) => {
    const response = await getAddressById(currentUser, value);

    const clonedAddresses: AddressModel = { ...addresses };

    clonedAddresses.data.unshift(response);

    setAddresses(clonedAddresses);

    setDeliveryAddressId(value);
  };

  const handleInquire = async () => {
    setIsInquirying(true);
    setIsloading(true);

    try {
      let createInquiryData: InquiryCreateModel = {} as InquiryCreateModel;
      let updateCreatedInquiry: InquiryUpdateModel = {} as InquiryUpdateModel;

      createInquiryData.projectId = inquiryDetails.project.id;
      createInquiryData.sellerCompanyId = sellerCompanyId || 0;
      createInquiryData.dealId = null;
      createInquiryData.attachmentIds = inquiryDetails.attachmentIds;
      createInquiryData.externalSellerContactId = selectedExternalContact;
      createInquiryData.offerDeadlineDate = isoDate;

      /* Create an inquiry */
      const createResponse = await createInquiry(
        currentUser,
        createInquiryData
      );

      /* Update inquiry */
      updateCreatedInquiry.comment = comment;
      updateCreatedInquiry.deliveryAddressId =
        deliveryAddressId !== 0 && deliveryAddressId !== -1
          ? deliveryAddressId
          : null;

      updateCreatedInquiry.customerInquiryCode = inquiryNumber;

      await updateInquiry(currentUser, updateCreatedInquiry, createResponse);

      /* Fetch that inquiry */
      const getResponse = await getInquiry(
        currentUser,
        createResponse,
        userLocaleId
      );

      /* Update inquiry rows. ONLY FOR MVP1 */
      await Promise.all(
        inquiryDetails.rows.map((row, index) =>
          updateInquiryRow(
            currentUser,
            getResponse.rows[index].id,
            {
              targetPrice: row.targetPrice,
              allowReplacement: row.allowReplacement,
            },
            createResponse
          )
        )
      );

      /* Send inquiry to ERP */
      await BuyerApi.sendInquiry(currentUser.companyId, {
        sellerCompanyId: sellerCompanyId || 0,
        inquiryId: createResponse,
        documentUrlTemplate: `${
          window && window.location.origin
        }/external-seller-inquiry?accessToken={accessToken}`,
      });

      SuccessToast(t('documents.inf_InquiryMade'));

      queryClient.invalidateQueries({ queryKey: ['deals'] });

      const updateSentIdInCacheInqiury: InquiryCreateForSessionModel = {
        ...inquiryDetails,
        sentId: createResponse,
      };

      updateInquirySentId(updateSentIdInCacheInqiury);

      setIsloading(false);
    } catch (err) {
      setIsloading(false);
    }
  };

  const disabledDays = {
    from: new Date(
      new Date().getFullYear() - 1,
      dayjs().startOf('month').month(),
      1
    ),
    to: new Date(
      dayjs().startOf('month').year(),
      dayjs().startOf('month').month(),
      new Date().getDate() - 1
    ),
  };

  const handleDiscard = () => {
    handleDiscardInquiry(inquiryDetails.sellerCompanyId);
  };

  const handleDateDelete = async () => {
    setRequestedDate('');
    setIsoDate(null);
  };

  useGetSupplierCompanyInfo(
    setCompany,
    currentUser,
    inquiryDetails.sellerCompanyId
  );

  useEffect(() => {
    const getCreditInfo = async (): Promise<void> => {
      inquiryDetails.sellerCompanyId !== null &&
        inquiryDetails.sellerCompanyId !== undefined &&
        (await getSupplierCreditInfo(
          setCreditInfo,
          currentUser,
          inquiryDetails.sellerCompanyId
        ));
    };

    getCreditInfo();
  }, [currentUser, inquiryDetails.sellerCompanyId]);

  const popover = (
    <Popover className='p-0' id='popover-basic'>
      <div className='col-side-menu-supplier-customer'>
        <SupplierProfileCard company={company} creditInfo={creditInfo} />
      </div>
    </Popover>
  );

  return (
    <div className='container-fluid p-2'>
      <Card>
        <Card.Header>
          <Row>
            <Col lg={4}>
              <h2 className='btn-link-bold'>
                {inquiryDetails.sellerCompanyName}
              </h2>
              {inquiryDetails.isExternalSeller && (
                <Form.Select
                  style={{
                    maxWidth: '200px',
                    color: '#1b9ad2',
                    fontWeight: '600',
                  }}
                  onChange={(event) =>
                    setSelectedExternalContact(parseInt(event.target.value))
                  }
                  className='border-0 borderless p-0 m-0 icon-ez'
                >
                  {externalContacts.map((contact) => (
                    <option value={contact.id} className='icon-ez'>
                      {contact.firstName} {contact.lastName}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Col>
            <Col className='text-center' lg={4}>
              <h2>{t('documents.hd_Inquiry')}</h2>
            </Col>
            <Col className='text-end' lg={4}>
              <Row>
                <Col></Col>
                <Col className='text-center' lg={5}>
                  <h3>
                    {t('documents.lbl_Unsent')}{' '}
                    <InquiryDisabled className='icon-ez-gray' />
                  </h3>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col lg={4}>
              <Row className='mb-3'>
                <Col lg={2}>{t('documents.lbl_Date')}:</Col>
                <Col>
                  <strong>{dayjs().format('DD.MM.YYYY')}</strong>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col lg={2}>{t('documents.lbl_SentBy')}:</Col>
                <Col>
                  <strong>
                    {appState.userProfile?.firstName}{' '}
                    {appState.userProfile?.lastName}
                  </strong>
                </Col>
              </Row>
            </Col>
            <Col className='text-center' lg={3} />
            <Col className='text-end' lg={5}>
              <Row className='mb-0'>
                <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                <Col className='text-start' lg={5}>
                  <Link
                    className='btn-link-bold'
                    to={`buyer/projects/${inquiryDetails.project?.id}`}
                  >
                    {inquiryDetails.project?.name}
                  </Link>
                </Col>
              </Row>
              <Row className='mb-0'>
                <Col>{t('documents.lbl_MyInquiryNumber')}:</Col>
                <Col lg={5}>
                  <FormControl
                    max={100}
                    maxLength={100}
                    onChange={(event) => setInquiryNumber(event.target.value)}
                    placeholder={t('documents.plh_MyInquiryNumber')}
                  />
                </Col>
              </Row>
              <Row className='mb-0'>
                <Col>{t('documents.lbl_OfferDeadline')}:</Col>
                <Col lg={5}>
                  <DayPickerInput
                    format='DD-MM-YYYY'
                    dayPickerProps={{
                      fromMonth: new Date(),
                      disabledDays: disabledDays,
                    }}
                    style={{ width: '100%' }}
                    component={(props: any) => (
                      <InputGroup>
                        <FormControl {...props} />
                        <InputGroup.Text
                          onClick={handleDateDelete}
                          style={{ cursor: 'pointer' }}
                        >
                          <X className='icon-ez-red' size={21} />
                        </InputGroup.Text>
                      </InputGroup>
                    )}
                    value={requestedDate || t('common.lbl_DDMMYYYY')}
                    onDayChange={handleDateChange}
                  />
                </Col>
              </Row>
              <Row className='mb-0'>
                <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                <Col lg={5}>
                  <Form.Select
                    value={deliveryAddressId !== 0 ? deliveryAddressId : -1}
                    style={{ width: '100%' }}
                    onChange={handleDeliveryChange}
                  >
                    <option value={-1}>
                      {t('addressSettings.lbl_SelectDeliveryAddress')}
                    </option>
                    {filteredAddresses !== undefined &&
                      filteredAddresses.map((address, key) => (
                        <option value={address.id}>
                          {address.name} ({address.fullAddress})
                        </option>
                      ))}
                    <DeliveryAddressesNew
                      asOption
                      asOptionShow={asOptionShow}
                      setAsOptionShow={setAsOptionShow}
                      setAddresses={setAddresses}
                      setNewDeliverAddressAsSelected={
                        setNewDeliverAddressAsSelected
                      }
                      t={t}
                    />
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className='my-5'>
            <DataTable
              noDataComponent={
                <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
              }
              columns={columns}
              data={inquiryDetails.rows}
            />
          </Row>

          <Row className='justify-content-between'>
            <Col lg={5}>
              <Form.Group className='mb-3' controlId='comments'>
                <Form.Label>{t('common.lbl_Comments')}</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  placeholder={t('common.plh_EnterComments')}
                  onChange={(event) => setComment(event.target.value)}
                  value={comment}
                />
              </Form.Group>
            </Col>

            <Col lg={3}>
              <Row className='mt-3'>
                <Col>
                  <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                </Col>
                <Col>
                  <h3 className='text-end'>
                    {useFormatMonetaryValue(
                      inquiryDetails.currencyCode,
                      inquiryDetails.totalSum
                    )}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col className='text-end'>
                  {t('common.inf_TotalPriceSubtext')}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button onClick={handleDiscard} className='btn-outline-danger '>
                <Trash className='icon-ez-red' /> {t('common.btn_Discard')}
              </Button>
            </Col>
            <Col>
              <Button
                disabled={isLoading}
                onClick={handleInquire}
                className='btn btn-eleczap float-end'
              >
                <Clipboard /> {t('documents.btn_Inquire')}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

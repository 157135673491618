import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { createdAt, fileBlob, responseBody } from './ApiClient';
import {
  apiResponseInterceptor,
  jwtRequestInterceptor,
} from './ApiInterceptors';

const client = axios.create({ baseURL: '/api/buyers' });
client.interceptors.response.use(undefined, apiResponseInterceptor);
client.interceptors.request.use(jwtRequestInterceptor);

/** API client for using REST API only for Buyer level */
export const BuyerApiClient = {
  get: (url: string, sourceToken?: CancelToken) =>
    client.get(url, { cancelToken: sourceToken }).then(responseBody),
  getFile: (url: string) =>
    client
      .get(url, {
        responseType: 'blob',
      } as AxiosRequestConfig)
      .then(fileBlob),
  post: (url: string, body: {}) => client.post(url, body).then(responseBody),
  postCreate: (url: string, body: {}) => client.post(url, body).then(createdAt),
  put: (url: string, body: {}) => client.put(url, body).then(responseBody),
  delete: (url: string) => client.delete(url).then(responseBody),
};

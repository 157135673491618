import { ProductClassResultLinkType } from 'models/shared/product/ProductClassResultLinkType';
import { Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type ProductBreadcrumbItem = {
  classId: number;
  classLevel: number;
  name: string;
  linkType: ProductClassResultLinkType;
  code?: string;
};

type PropsType = {
  items?: ProductBreadcrumbItem[];
  isStatic?: boolean;
  productClassesRoute: string;
  productSubClassesRoute: string;
};

export const ProductBreadcrumb = ({
  items = [],
  isStatic = false,
  productClassesRoute,
  productSubClassesRoute,
}: PropsType) => {
  const { t } = useTranslation('components');
  const allCategories = t('common.lbl_AllCategories');

  const lastItem = items.length - 1;

  const getUrl = (item: ProductBreadcrumbItem) => {
    if (item.linkType === 'Subclasses') {
      return item.classLevel === 1
        ? `${productClassesRoute}#${item.code}`
        : `${productSubClassesRoute}${item.classId}`;
    } else if (item.linkType === 'Products') {
      return `${productClassesRoute}${item.classId}/products`;
    }
    return '';
  };

  return items.length > 0 || isStatic ? (
    <Breadcrumb
      className={`p-2 pt-0 breadcrumb mb-0 d-print-none ${
        productClassesRoute.includes('seller') ? 'seller-breadcrumb' : ''
      }`}
    >
      <Breadcrumb.Item
        className='mt-2'
        key={0}
        active={lastItem < 0}
        linkAs={Link}
        linkProps={{ to: productClassesRoute }}
      >
        {allCategories}
      </Breadcrumb.Item>
      {items.map((item, index) => (
        <Breadcrumb.Item
          className='mt-2'
          key={item.classId.toString()}
          active={lastItem === index}
          linkAs={Link}
          linkProps={{ to: getUrl(item) }}
        >
          {item.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  ) : null;
};

import { HTMLAttributes, useState } from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = HTMLAttributes<any> & {
  handleSortASC: () => Promise<void>;
  handleSortDESC: () => Promise<void>;
  currentSort: string;
  sortProperty: string;
  sortTitle: string;
};

export const ProductListHeaderSorter = ({
  handleSortASC,
  handleSortDESC,
  currentSort,
  sortProperty,
  sortTitle,
  ...props
}: PropTypes) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Col
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className='d-flex align-items-center wrap-ellipsis'
      {...props}
    >
      {sortProperty === 'order_step' ? (
        <h5 className='text-end wrap-ellipsis'>
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip>
                {t('productListHeader.lbl_OrderStep')} /{' '}
                {t('productListHeader.lbl_Package')}
              </Tooltip>
            }
          >
            <div>
              <span className='wrap-ellipsis'>
                {t('productListHeader.lbl_OrderStep')}
              </span>{' '}
              /{' '}
              <span className='wrap-ellipsis'>
                {t('productListHeader.lbl_Package')}
              </span>
            </div>
          </OverlayTrigger>
        </h5>
      ) : (
        <h5 className='wrap-ellipsis'>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip>{sortTitle}</Tooltip>}
          >
            <div>{sortTitle}</div>
          </OverlayTrigger>
        </h5>
      )}
      <div style={{ marginBottom: '9px' }} className='d-flex flex-column ms-1'>
        {currentSort === sortProperty ? (
          <CaretUpFill
            onClick={() => {
              setIsHovered(false);
              handleSortDESC();
            }}
            className='icon-ez'
            style={{ cursor: 'pointer' }}
          />
        ) : null}
        {currentSort === `${sortProperty}_desc` ? (
          <CaretDownFill
            onClick={() => {
              setIsHovered(false);
              handleSortASC();
            }}
            className='icon-ez'
            style={{ cursor: 'pointer' }}
          />
        ) : null}

        {isHovered &&
          currentSort !== sortProperty &&
          currentSort !== `${sortProperty}_desc` && (
            <CaretUpFill
              onClick={() => {
                setIsHovered(false);
                handleSortASC();
              }}
              className='icon-ez-gray'
              style={{ cursor: 'pointer' }}
            />
          )}
      </div>
    </Col>
  );
};

import { AppContext } from 'contexts/user/AppContext';
import { ProductMoqPriceModel } from 'models/shared/product/ProductMoqPriceModel';
import { useContext } from 'react';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';

type PropsType = {
  moq: ProductMoqPriceModel;
  unit: string;
};

export const ProductMoqPrice = ({ moq, unit }: PropsType) => {
  const { appState } = useContext(AppContext);
  return (
    <h5>
      {formatMonetaryValue(
        moq.currencyCode,
        moq.price,
        appState,
        moq.decimalPlaces
      )}
      <small> {unit}</small>
    </h5>
  );
};

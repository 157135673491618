import { AppContext } from 'contexts/user/AppContext';
import { useCallback, useContext } from 'react';

export const useResetQtyForProductList = () => {
  const { appState, appContextDispatch } = useContext(AppContext);

  const items = appState.currentProducts;

  const resetQtyForProductList = useCallback(() => {
    if (items) {
      items.forEach((item) => (item.quantity = 0));

      appContextDispatch({
        type: 'BUYER_SET_CURRENT_PRODUCT_LIST',
        currentProducts: items,
      });

      appContextDispatch({
        type: 'BUYER_SET_CURRENT_PRODUCT_LIST_WITH_QTYS',
        currentProductsWithQtys: [],
      });
    }
  }, [appContextDispatch, items]);

  return resetQtyForProductList;
};

import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback } from 'react';
import { useAuthUser } from 'react-auth-kit';

export const useUpdateBuyerCurrentProductSupplierInLocalStorage = () => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const updateBuyerCurrentProductSupplierInLocalStorage = useCallback(
    (currentSupplier: BuyerCurrentSuppliers | undefined) => {
      if (currentSupplier !== undefined) {
        localStorage.removeItem(
          `_buyerCurrentProductsSupplier_${currentUser.id}`
        );

        localStorage.setItem(
          `_buyerCurrentProductsSupplier_${currentUser.id}`,
          JSON.stringify(currentSupplier)
        );
      }
    },
    [currentUser.id]
  );

  return updateBuyerCurrentProductSupplierInLocalStorage;
};

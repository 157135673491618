import { BuyerApi } from 'api/BuyerApi';
import { LightTooltip } from 'components/shared/LightTooltip';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { useSetCurrentProject } from 'hooks/useSetCurrentProject';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Nav } from 'react-bootstrap';
import { ClipboardPlusFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

type PropTypes = {
  windowIsNarrow?: boolean;
};

export const CreateNewProjectInquiryButton = ({
  windowIsNarrow,
}: PropTypes) => {
  const { t } = useTranslation('components');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigator = useHistory();

  const { appState } = useContext(AppContext);

  const setCurrentProject = useSetCurrentProject();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleNewProjectModalShow = async () => {
    if (appState.userProfile?.currentProject?.id) {
      setIsLoading(true);
      const response = await BuyerApi.projectInquiry.createProjectInquiry(
        currentUser.companyId,
        appState.userProfile?.currentProject.id
      );

      setCurrentProject(appState.userProfile?.currentProject.id);

      navigator.push(
        `/buyer/project/${appState.userProfile?.currentProject?.id}/project-inquiry/${response}`
      );
    } else {
      const response = await BuyerApi.projects.createProjectAndProjectInquiry(
        currentUser.companyId
      );

      setCurrentProject(response.projectId);

      navigator.push(
        `/buyer/project/${response.projectId}/project-inquiry/${response.inquiryProjectId}`
      );
    }

    setIsLoading(false);
  };

  return !windowIsNarrow ? (
    <>
      {isLoading && <FullScreenLoader />}

      <Nav.Item
        data-tip
        data-for={`/buyer/project/new/project-inquiry/new`}
        style={{ cursor: 'pointer' }}
        onClick={handleNewProjectModalShow}
        className='ms-4 text-center float-end'
      >
        <span className='buyer-nav-item d-flex flex-column align-items-center'>
          <ClipboardPlusFill className='buyer-nav-item-icon-white' size={34} />
        </span>
      </Nav.Item>

      <LightTooltip
        place='bottom'
        id={`/buyer/project/new/project-inquiry/new`}
      >
        <span className='buyer-nav-item-label'>
          {t('projectSettings.lbl_NewProjectInquiry')}
        </span>
      </LightTooltip>
    </>
  ) : (
    <>
      <Nav.Item
        style={{ cursor: 'pointer' }}
        onClick={handleNewProjectModalShow}
        className='ms-4 text-center'
      >
        <span className='buyer-nav-item buyer-nav-item-icon-container'>
          {t('projectSettings.lbl_NewProjectInquiry')}
        </span>
      </Nav.Item>
    </>
  );
};

import 'bootstrap/dist/css/bootstrap.min.css';
import { SellerDeals } from 'components/seller/dealshome/SellerDeals';

import Card from 'react-bootstrap/Card';

import './../../css/light.css';
import './../../css/site.css';
import './sellerDealsHome.css';

export const SellerDealsHome = () => {
  return (
    <div className='container-fluid p-2'>
      <Card>
        <SellerDeals />
      </Card>
      <div className='p-4' />
    </div>
  );
};

import 'bootstrap/dist/css/bootstrap.min.css';
import GoogleMapReact from 'google-map-react';
import { useGetAvailableCountries } from 'hooks/useGetAvailabeCountries';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { AddressModel } from 'models/shared/address/AddressModel';
import { CountryModel } from 'models/shared/country/CountryModel';
import { useRef, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { GeoAltFill, Search } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { useFormikHookForAddress } from './hooksDeliveryAddressNew';

type PropTypes = {
  setAddresses: (values: AddressModel) => void;
  t: any;
  asOption?: boolean | undefined;
  asOptionShow?: boolean | undefined;
  setAsOptionShow?: (value: boolean) => void | undefined;
  setNewDeliverAddressAsSelected?: (value: number) => Promise<void> | undefined;
};

type AddressTypes = {
  long_name: string;
  short_name: string;
  types: string[];
};

type GeoType = { lat: number; lng: number };

const defaultProps = {
  center: {
    lat: 59.436962,
    lng: 24.753574,
  },
  zoom: 11,
};

export const DeliveryAddressesNew = ({
  setAddresses,
  t,
  asOption,
  asOptionShow,
  setAsOptionShow,
  setNewDeliverAddressAsSelected,
}: PropTypes) => {
  const [show, setShow] = useState(asOption ? asOptionShow : false);
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [coords, setCoords] = useState<GeoType>(defaultProps.center as GeoType);
  const [zoom, setZoom] = useState<number>(11);

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const handleClose = () => {
    formik.resetForm();
    setValue('');
    setShow(false);
    setAsOptionShow !== undefined && setAsOptionShow(false);
  };
  const handleShow = () => {
    setShow(true);

    setTimeout(() => {
      if (searchInputRef !== null) {
        searchInputRef.current?.focus();
      }
    }, 500);
  };

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const formik = useFormikHookForAddress(
    currentUser,
    setAddresses,
    setShow,
    t,
    setNewDeliverAddressAsSelected,
    setAsOptionShow
  );

  useGetAvailableCountries(setCountries);

  const handleSubmitForm = async () => {
    await formik.handleSubmit();
  };

  /* Google apis code */

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const handleInput = (e: any) => {
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description, place_id }: any) =>
    async () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"

      formik.resetForm();
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          setCoords({ lat, lng });
          setZoom(15);
        })
        .catch((error) => {
          toast.error('Error: ', error);
        });

      // Get place details
      try {
        const request = { placeId: place_id };

        const results: any = await getDetails(request);
        const addressComponents: [] = results.address_components;
        const addressName = results.name;

        const addressLine1StreetNumber: AddressTypes = addressComponents.filter(
          (address: AddressTypes) => address.types.includes('street_number')
        )[0];

        const addressLine1StreetName: AddressTypes = addressComponents.filter(
          (address: AddressTypes) => address.types.includes('route')
        )[0];

        const state: AddressTypes = addressComponents.filter(
          (address: AddressTypes) =>
            address.types.includes('administrative_area_level_1')
        )[0];

        const city: AddressTypes = addressComponents.filter(
          (address: AddressTypes) => address.types.includes('locality')
        )[0];

        const country: AddressTypes = addressComponents.filter(
          (address: AddressTypes) => address.types.includes('country')
        )[0];

        const postalCode: AddressTypes = addressComponents.filter(
          (address: AddressTypes) => address.types.includes('postal_code')
        )[0];

        formik.setFieldValue('name', `${addressName || ''}`);

        formik.setFieldValue(
          'addressLine1',
          addressLine1StreetName?.long_name !== undefined &&
            addressLine1StreetNumber?.long_name !== undefined
            ? `${addressLine1StreetName?.long_name || ''} ${
                addressLine1StreetNumber?.long_name || ''
              }`
            : ''
        );
        formik.setFieldValue('city', city?.long_name || '');
        formik.setFieldValue('state', state?.long_name || '');
        formik.setFieldValue('country', {
          code: country.short_name,
          shortName: country.long_name,
        });
        formik.setFieldValue('postalCode', postalCode?.long_name || '');

        setValue(
          `${addressLine1StreetName?.long_name || ''} ${
            addressLine1StreetNumber?.long_name || ''
          }`,
          false
        );
      } catch (err) {
        toast.error('Something happened while getting address details.');
      }
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          style={{ cursor: 'pointer' }}
          key={id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const Marker = ({ show, place }: any) => {
    return (
      <>
        <GeoAltFill fontSize={20} />
      </>
    );
  };

  return (
    <>
      {asOption ? (
        <option value='addNewAddress' onClick={handleShow}>
          {t('addressSettings.hd_AddNewAddressShort')}
        </option>
      ) : (
        <Button
          variant='btn btn-eleczap m-auto mx-2'
          id='addAddress'
          onClick={handleShow}
        >
          {t('addressSettings.hd_AddNewAddressShort')}
        </Button>
      )}

      {/*          Add a new delivery address modal*/}
      <Modal
        size='sm'
        show={asOption ? asOptionShow : show}
        onHide={handleClose}
        id='addAddress'
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('addressSettings.hd_AddNewAddress')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <div style={{ height: '20vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_API_KEY || '',
                libraries: ['places', 'geometry'],
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              center={coords}
              zoom={zoom}
            >
              {zoom !== 11 && (
                <Marker lat={coords.lat} lng={coords.lng} text='My Marker' />
              )}
            </GoogleMapReact>
          </div>

          <Form noValidate>
            <InputGroup
              style={{ marginLeft: '-10px', width: '376px' }}
              className='my-3 border rounded'
            >
              <InputGroup.Text className='btn' id='search-addon'>
                <Search />
              </InputGroup.Text>
              <FormControl
                ref={searchInputRef}
                id='google-address-search-field'
                as='input'
                value={value}
                onChange={handleInput}
                className='border-0'
                placeholder={t('addressSettings.plh_SearchForAddress')}
                aria-label='SearchForAddress'
                aria-describedby='search-addon'
              />
            </InputGroup>

            {status === 'OK' && <ul>{renderSuggestions()}</ul>}

            <hr />

            <Form.Group
              className='mb-2'
              as={Row}
              md='12'
              controlId='validationName'
            >
              <Form.Label className='required'>
                {t('addressSettings.lbl_Name')}
              </Form.Label>
              <Form.Control
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                required
                placeholder={t('addressSettings.plh_Name')}
                type='input'
                isInvalid={formik.touched.name && !!formik.errors.name}
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className='mb-2'
              as={Row}
              md='12'
              controlId='validationAddressLine1'
            >
              <Form.Label className='required'>
                {t('addressSettings.lbl_AddressLine1')}
              </Form.Label>
              <Form.Control
                name='addressLine1'
                value={formik.values.addressLine1}
                onChange={formik.handleChange}
                required
                type='text'
                isInvalid={
                  formik.touched.addressLine1 && !!formik.errors.addressLine1
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.addressLine1}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className='mb-2'
              as={Row}
              md='12'
              controlId='validationAddressLine2'
            >
              <Form.Label>{t('addressSettings.lbl_AddressLine2')}</Form.Label>
              <Form.Control
                name='addressLine2'
                value={formik.values.addressLine2}
                onChange={formik.handleChange}
                type='text'
                isInvalid={
                  formik.touched.addressLine2 && !!formik.errors.addressLine2
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.addressLine2}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className='mb-2'
              as={Row}
              md='12'
              controlId='validationCity'
            >
              <Form.Label className='required'>
                {t('addressSettings.lbl_City')}
              </Form.Label>
              <Form.Control
                name='city'
                value={formik.values.city}
                onChange={formik.handleChange}
                type='text'
                required
                isInvalid={formik.touched.city && !!formik.errors.city}
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.city}
              </Form.Control.Feedback>
            </Form.Group>

            <Row className=' mt-3'>
              <Form.Group
                className='mb-2'
                as={Col}
                md='8'
                controlId='validationState'
              >
                <Form.Label className='required'>
                  {t('addressSettings.lbl_State')}
                </Form.Label>
                <Form.Control
                  name='state'
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  type='text'
                  required
                  isInvalid={formik.touched.state && !!formik.errors.state}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.state}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className='mb-2'
                as={Col}
                md='4'
                controlId='validationPostalCode'
              >
                <Form.Label className='required'>
                  {t('addressSettings.lbl_PostalCode')}
                </Form.Label>
                <Form.Control
                  name='postalCode'
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  type='text'
                  required
                  isInvalid={
                    formik.touched.postalCode && !!formik.errors.postalCode
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.postalCode}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group
              className='mb-2'
              as={Row}
              md='12'
              controlId='validationCountryCode'
            >
              <Form.Label className='required'>
                {t('addressSettings.lbl_Country')}
              </Form.Label>
              <Form.Select
                name='country.code'
                required
                value={
                  formik.values.country !== undefined
                    ? formik.values.country.code
                    : 'AD'
                }
                onChange={formik.handleChange}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.code}>
                    {country.shortName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <>
              <Form.Check
                name='isDefault'
                checked={formik.values.isDefault}
                onChange={formik.handleChange}
                aria-label={t('addressSettings.lbl_MakeDefaut')}
                label={t('addressSettings.lbl_MakeDefaut')}
                className='mt-3'
              />
            </>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='btn btn-eleczap' onClick={handleSubmitForm}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { SellerApi } from 'api/SellerApi';
import { SellerProductList } from 'components/seller/products/Product/SellerProductList';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import {
  ProductBreadcrumb,
  ProductBreadcrumbItem,
} from 'components/shared/products/ProductBreadcrumb';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductModel } from 'models/shared/product/ProductModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Spinner } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import './SellerProducts.css';

type UrlParamsType = {
  classId: string;
};

export const Products = () => {
  let { classId } = useParams<UrlParamsType>();

  const [products, setproducts] = useState<ProductModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  const [productBreadCrumbItems, setproductBreadCrumbItems] = useState<
    ProductBreadcrumbItem[]
  >([]);

  const [mounted, setMounted] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');

  const searchString = '';
  const pageNumber = 1;
  const pageSize = 500; // No paging in 1st version

  const selectedCustomerId = appState.sellerCurrentCustomerId;
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const getProducts = useCallback(
    async (didCancel: boolean, orderBy?: string) => {
      setIsLoading(true);

      const productsResponse = await SellerApi.getProducts(
        currentUser.companyId,
        selectedCustomerId,
        classId,
        searchString,
        pageNumber,
        orderBy || '',
        pageSize,
        userLocaleId
      );
      if (!didCancel) {
        setproducts(productsResponse.data);

        const breadCrumbItems =
          productsResponse.parents.map<ProductBreadcrumbItem>((c) => ({
            name: c.name,
            classId: c.id,
            classLevel: c.level,
            linkType: c.linkType,
            code: c.code,
          }));

        setproductBreadCrumbItems(breadCrumbItems);
      }
      setIsLoading(false);
      setIsInitialLoading(false);
    },
    [classId, currentUser.companyId, selectedCustomerId, userLocaleId]
  );

  useEffect(() => {
    let didCancel = false;

    getProducts(didCancel);

    return () => {
      didCancel = true;
    };
  }, [
    currentUser.companyId,
    selectedCustomerId,
    classId,
    userLocaleId,
    getProducts,
  ]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const BuyerBreadcrumbNode = (
    <ProductBreadcrumb
      items={productBreadCrumbItems}
      productClassesRoute='/seller/product-classes/'
      productSubClassesRoute='/seller/product-subclasses/'
    />
  );

  return isInitialLoading ? (
    <Spinner className='center-spinner' animation='border' />
  ) : products.length > 0 ? (
    <>
      {isLoading && <FullScreenLoader />}
      {mounted &&
        ReactDOM.createPortal(
          BuyerBreadcrumbNode,
          document &&
            document.getElementsByClassName('fixed-portal-receiver')[0]
        )}
      <SellerProductList
        isLoading={isLoading}
        getProducts={getProducts}
        items={products}
        productDetailRoute={'/seller/product-details'}
      />
    </>
  ) : (
    <div className='text-center' style={{ marginTop: '150px' }}>
      <h3>{t('productListTile.inf_NoProductResults1')}</h3>
      <h3>{t('productListTile.inf_NoProductResults2')}</h3>
    </div>
  );
};

import {
  BuyerRegistrationRequestModel,
  BuyerRegistrationResponseModel,
} from 'models/buyer/buyerRegistration/BuyerRegistrationModels';
import { LoginModel } from 'models/identity/LoginModel';
import { LoginResponseModel } from 'models/identity/LoginResponseModel';
import { PasswordResetModel } from 'models/identity/PasswordResetModel';
import { RequestPasswordResetModel } from 'models/identity/RequestPasswordResetModel';
import { ExternalSellerBuyerContactModel } from 'models/seller/contacts/ExternalSellerBuyerContactModel';
import { CustomerCompanyModel } from 'models/seller/customers/CustomerCompanyModel';
import {
  ExternalSellerInquiryDeclineModel,
  ExternalSellerInquiryModel,
} from 'models/seller/documents/ExternalSellerInquiryModels';
import {
  ExternalSellerOfferModel,
  ExternalSellerOfferUpsertModel,
} from 'models/seller/documents/ExternalSellerOfferModels';
import {
  ExternalSellerOrderCancelModel,
  ExternalSellerOrderConfirmModel,
  ExternalSellerOrderModel,
} from 'models/seller/documents/ExternalSellerOrderModels';
import { ContactOnboardingLocalizationUpdateModel } from 'models/shared/customerOnboarding/ContactOnboardingLocalizationUpdateModel';
import { CustomerOnboardingContactDetailsModel } from 'models/shared/customerOnboarding/customerOnboardingContactDetailsModel';
import { CustomerOnboardingDetailsModel } from 'models/shared/customerOnboarding/customerOnboardingDetailsModel';
import { CustomerOnboardingPasswordModel } from 'models/shared/customerOnboarding/customerOnboardingPasswordModel';
import { CustomerOnboardingUpdateContactDetailsModel } from 'models/shared/customerOnboarding/customerOnboardingUpdateContactDetailsModel';
import { CustomerOnboardingUpdateDetailsModel } from 'models/shared/customerOnboarding/customerOnboardingUpdateDetailsModel';
import { CustomerOnboardingWelcomeModel } from 'models/shared/customerOnboarding/customerOnboardingWelcomeModel';
import { UserOnboardingDetailsModel } from 'models/shared/userOnboarding/UserOnboardingDetailsModel';
import { UserOnboardingLocalizationUpdateModel } from 'models/shared/userOnboarding/UserOnboardingLocalizationUpdateModel';
import { UserOnboardingPasswordModel } from 'models/shared/userOnboarding/UserOnboardingPasswordModel';
import { UserOnboardingUpdateDetailsModel } from 'models/shared/userOnboarding/UserOnboardingUpdateDetailsModel';
import { UserOnboardingWelcomeModel } from 'models/shared/userOnboarding/UserOnboardingWelcomeModel';
import { NoAuthApiClient } from './NoAuthApiClient';

/* Api for making various identity and authentication related calls (login, reset password) etc. */
export const IdentityApi = {
  login: (post: LoginModel): Promise<LoginResponseModel> =>
    NoAuthApiClient.post('login', post),

  /* Forgot password */
  requestPasswordReset: (
    requestResetPassword: RequestPasswordResetModel
  ): Promise<void> =>
    NoAuthApiClient.post(
      'ForgotPassword/RequestPasswordReset',
      requestResetPassword
    ),

  resetPassword: (
    resetToken: string,
    resetPassword: PasswordResetModel
  ): Promise<void> =>
    NoAuthApiClient.post(
      `ForgotPassword/PasswordReset?resetToken=${resetToken}`,
      resetPassword
    ),

  /* User onboarding */
  getWelcomeDetails: (
    invitationToken: string
  ): Promise<UserOnboardingWelcomeModel> =>
    NoAuthApiClient.get(
      `UserOnboarding/Welcome?invitationToken=${invitationToken}`
    ),

  getUserDetails: (
    invitationToken: string
  ): Promise<UserOnboardingDetailsModel> =>
    NoAuthApiClient.get(
      `UserOnboarding/Details?invitationToken=${invitationToken}`
    ),

  updateUserLocalization: (
    invitationToken: string,
    userLocalization: UserOnboardingLocalizationUpdateModel
  ): Promise<void> =>
    NoAuthApiClient.put(
      `UserOnboarding/Localization?invitationToken=${invitationToken}`,
      userLocalization
    ),

  updateUserDetails: (
    invitationToken: string,
    customer: UserOnboardingUpdateDetailsModel
  ): Promise<void> =>
    NoAuthApiClient.put(
      `UserOnboarding/Details?invitationToken=${invitationToken}`,
      customer
    ),

  completeUserOnboarding: (
    invitationToken: string,
    password: UserOnboardingPasswordModel
  ): Promise<void> =>
    NoAuthApiClient.post(
      `UserOnboarding/Complete?invitationToken=${invitationToken}`,
      password
    ),

  updateProfileImage: (invitationToken: string, image: any): Promise<string> =>
    NoAuthApiClient.postCreate(
      `UserOnboarding/ProfileImage?invitationToken=${invitationToken}`,
      image
    ),

  /* Customer onboarding */
  getCustomerWelcomeDetails: (
    invitationToken: string
  ): Promise<CustomerOnboardingWelcomeModel> =>
    NoAuthApiClient.get(
      `CustomerOnboarding/Welcome?invitationToken=${invitationToken}`
    ),

  getCustomerDetails: (
    invitationToken: string
  ): Promise<CustomerOnboardingDetailsModel> =>
    NoAuthApiClient.get(
      `CustomerOnboarding/CustomerDetails?invitationToken=${invitationToken}`
    ),

  updateCustomerDetails: (
    invitationToken: string,
    customer: CustomerOnboardingUpdateDetailsModel
  ): Promise<void> =>
    NoAuthApiClient.put(
      `CustomerOnboarding/CustomerDetails?invitationToken=${invitationToken}`,
      customer
    ),

  getCustomerContactDetails: (
    invitationToken: string
  ): Promise<CustomerOnboardingContactDetailsModel> =>
    NoAuthApiClient.get(
      `CustomerOnboarding/ContactDetails?invitationToken=${invitationToken}`
    ),

  updateCustomerContactLocalization: (
    invitationToken: string,
    userLocalization: ContactOnboardingLocalizationUpdateModel
  ): Promise<void> =>
    NoAuthApiClient.put(
      `CustomerOnboarding/Localization?invitationToken=${invitationToken}`,
      userLocalization
    ),

  updateCustomerContactDetails: (
    invitationToken: string,
    customer: CustomerOnboardingUpdateContactDetailsModel
  ): Promise<void> =>
    NoAuthApiClient.put(
      `CustomerOnboarding/ContactDetails?invitationToken=${invitationToken}`,
      customer
    ),

  completeCustomerOnboarding: (
    invitationToken: string,
    password: CustomerOnboardingPasswordModel
  ): Promise<void> =>
    NoAuthApiClient.post(
      `CustomerOnboarding/Complete?invitationToken=${invitationToken}`,
      password
    ),

  updateCustomerProfileImage: (
    invitationToken: string,
    image: any
  ): Promise<string> =>
    NoAuthApiClient.postCreate(
      `CustomerOnboarding/ProfileImage?invitationToken=${invitationToken}`,
      image
    ),

  updateCustomerLogo: (invitationToken: string, image: any): Promise<string> =>
    NoAuthApiClient.postCreate(
      `CustomerOnboarding/Logo?invitationToken=${invitationToken}`,
      image
    ),

  /* Buyer registration */
  checkCanCompanyBeCreated: (
    registryNumber: string,
    countryCode: string
  ): Promise<boolean> =>
    NoAuthApiClient.get(
      `BuyerRegistration/CanCompanyBeCreated?registryNumber=${registryNumber}&countryCode=${countryCode}`
    ),

  checkIsUsernameAvailable: (username: string): Promise<boolean> =>
    NoAuthApiClient.get(
      `BuyerRegistration/IsUsernameAvailable?username=${username}`
    ),

  createBuyerRegistration: (
    buyerRegistrationData: BuyerRegistrationRequestModel
  ): Promise<BuyerRegistrationResponseModel> =>
    NoAuthApiClient.postCreate(`BuyerRegistration`, buyerRegistrationData),

  uploadBuyerRegistrationLogo: (image: FormData): Promise<string> =>
    NoAuthApiClient.post(`BuyerRegistration/Logo`, image),

  uploadBuyerRegistrationUserProfileImage: (
    userId: number,
    image: FormData
  ): Promise<string> =>
    NoAuthApiClient.post(`BuyerRegistration/${userId}/ProfileImage`, image),

  completeBuyerRegistration: (registrationToken: string): Promise<string> =>
    NoAuthApiClient.postCreate(
      `BuyerRegistration/Complete?registrationToken=${registrationToken}`,
      {}
    ),

  /* External seller documents */
  getExternalSellerInquiry: (
    accessToken: string
  ): Promise<ExternalSellerInquiryModel> =>
    NoAuthApiClient.get(
      `ExternalSellers/ExternalSellerInquiries?accessToken=${accessToken}`
    ),

  declineExternalSellerInquiry: (
    accessToken: string,
    decline: ExternalSellerInquiryDeclineModel
  ): Promise<void> =>
    NoAuthApiClient.put(
      `ExternalSellers/ExternalSellerInquiries/Decline?accessToken=${accessToken}`,
      decline
    ),

  recallExternalSellerOffer: (accessToken: string): Promise<void> =>
    NoAuthApiClient.put(
      `ExternalSellers/ExternalSellerOffers/Recall?accessToken=${accessToken}`,
      {}
    ),

  createExternalSellerOffer: (
    accessToken: string,
    offer: ExternalSellerOfferUpsertModel
  ): Promise<void> =>
    NoAuthApiClient.postCreate(
      `ExternalSellers/ExternalSellerOffers?accessToken=${accessToken}`,
      offer
    ),

  updateExternalSellerOffer: (
    accessToken: string,
    offer: ExternalSellerOfferUpsertModel
  ): Promise<void> =>
    NoAuthApiClient.put(
      `ExternalSellers/ExternalSellerOffers?accessToken=${accessToken}`,
      offer
    ),

  getExternalSellerOffer: (
    accessToken: string
  ): Promise<ExternalSellerOfferModel> =>
    NoAuthApiClient.get(
      `ExternalSellers/ExternalSellerOffers?accessToken=${accessToken}`
    ),

  recallInquiryUpdated: (accessToken: string): Promise<void> =>
    NoAuthApiClient.put(
      `ExternalSellers/ExternalSellerOffers/RecallInquiryUpdated?accessToken=${accessToken}`,
      {}
    ),

  getExternalSellerOrder: (
    accessToken: string
  ): Promise<ExternalSellerOrderModel> =>
    NoAuthApiClient.get(
      `ExternalSellers/ExternalSellerOrders?accessToken=${accessToken}`
    ),

  confirmExternalSellerOrder: (
    accessToken: string,
    order: ExternalSellerOrderConfirmModel
  ): Promise<void> =>
    NoAuthApiClient.put(
      `ExternalSellers/ExternalSellerOrders/Confirm?accessToken=${accessToken}`,
      order
    ),

  cancelExternalSellerOrder: (
    accessToken: string,
    order: ExternalSellerOrderCancelModel
  ): Promise<void> =>
    NoAuthApiClient.put(
      `ExternalSellers/ExternalSellerOrders/Cancel?accessToken=${accessToken}`,
      order
    ),

  /* External seller customer info */
  getExternalSellerCustomerProfile: (
    accessToken: string,
    documentType: string
  ): Promise<CustomerCompanyModel> =>
    NoAuthApiClient.get(
      `ExternalSellers/ExternalSellerBuyers?accessToken=${accessToken}&documentType=${documentType}`
    ),

  getExternalSellerCustomerContactProfile: (
    accessToken: string,
    documentType: string
  ): Promise<ExternalSellerBuyerContactModel> =>
    NoAuthApiClient.get(
      `ExternalSellers/ExternalSellerBuyerContacts?accessToken=${accessToken}&documentType=${documentType}`
    ),

  /* Attachments */
  addExternalSellerOfferAttachment: (
    accessToken: string,
    file: FormData
  ): Promise<number> =>
    NoAuthApiClient.post(
      `ExternalSellers/ExternalSellerAttachments?accessToken=${accessToken}`,
      file
    ),

  deleteExternalSellerOfferAttachment: (
    accessToken: string,
    id: number,
    documentType: 'Offer' | 'Order'
  ): Promise<void> =>
    NoAuthApiClient.delete(
      `ExternalSellers/ExternalSellerAttachments/${id}?documentType=${documentType}&accessToken=${accessToken}`
    ),
};

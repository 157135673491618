import { useFormikHookForExternalSeller } from 'components/buyer/supplierInfo/hooksExternalSupplierInfo';
import { useGetSupplierCompanyInfo } from 'components/buyer/supplierInfo/hooksSupplierInfo';
import { AppContext } from 'contexts/user/AppContext';
import { useGetAvailableCountries } from 'hooks/useGetAvailabeCountries';
import { AnyAdminRole, useRBAC } from 'hooks/useRBAC';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { CountryModel } from 'models/shared/country/CountryModel';
import { useContext, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import { Save } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { getUrlParams } from 'utils/getUrlParams';

type PropTypes = {
  setCompany: (value: SupplierCompanyModel) => void;
  company: SupplierCompanyModel;
};

export const ExternalSupplierCompanyInfo = ({
  setCompany,
  company,
}: PropTypes): JSX.Element => {
  const [countries, setCountries] = useState<CountryModel[]>([]);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');

  const supplierId = getUrlParams('id');

  const formik = useFormikHookForExternalSeller(
    company,
    setCompany,
    supplierId ? parseInt(supplierId) : null
  );

  const hasRightsToEdit = useRBAC(AnyAdminRole);

  useGetSupplierCompanyInfo(
    setCompany,
    currentUser,
    parseInt(supplierId || '0')
  );

  useGetAvailableCountries(setCountries);

  return (
    <Row>
      <Col style={{ maxWidth: '1056px' }}>
        <Card className='p-3'>
          <Card.Header>
            <h4>{t('companySettings.hd_CompanyInformation')}</h4>
          </Card.Header>
          <Form className='p-3'>
            <Row>
              <Col className='col col-lg-10'>
                <Row>
                  <Col className='mx-3'>
                    <Form.Group as={Row} controlId='validationCompnayName'>
                      <Form.Label>
                        {t('companySettings.lbl_CompanyName')}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='name'
                        placeholder={t('companySettings.plh_CompanyName')}
                        value={formik.values.name}
                        disabled={!hasRightsToEdit}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.name && !!formik.errors.name}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationRegistryNumber'>
                      <Form.Label>
                        {t('companySettings.lbl_RegisterNumber')}
                      </Form.Label>
                      <Form.Control
                        name='registryNumber'
                        value={formik.values.registryNumber}
                        type='text'
                        onChange={formik.handleChange}
                        placeholder={t('companySettings.plh_RegistryNumber')}
                        disabled={!hasRightsToEdit}
                        isInvalid={
                          formik.touched.registryNumber &&
                          !!formik.errors.registryNumber
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.registryNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationAddress'>
                      <Form.Label>
                        {t('addressSettings.lbl_AddressLine1')}
                      </Form.Label>
                      <Form.Control
                        value={formik.values.addressLine1}
                        type='text'
                        placeholder={t('addressSettings.lbl_AddressLine1')}
                        disabled={!hasRightsToEdit}
                        onChange={formik.handleChange}
                        name='addressLine1'
                        isInvalid={
                          formik.touched.addressLine1 &&
                          !!formik.errors.addressLine1
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.addressLine1}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row className='mb-3'>
                      <Form.Group
                        as={Col}
                        className='p-0 me-2'
                        controlId='validationZip'
                      >
                        <Form.Label>
                          {t('addressSettings.lbl_PostalCode')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          value={formik.values.postalCode}
                          placeholder={t('addressSettings.plh_PostalCode')}
                          name='postalCode'
                          disabled={!hasRightsToEdit}
                          onChange={formik.handleChange}
                          isInvalid={
                            formik.touched.postalCode &&
                            !!formik.errors.postalCode
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          {formik.errors.postalCode}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className='p-0'
                        controlId='validationCity'
                      >
                        <Form.Label>{t('addressSettings.lbl_City')}</Form.Label>
                        <Form.Control
                          type='text'
                          value={formik.values.city}
                          placeholder={t('addressSettings.plh_City')}
                          name='city'
                          disabled={!hasRightsToEdit}
                          onChange={formik.handleChange}
                          isInvalid={
                            formik.touched.city && !!formik.errors.city
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          {formik.errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Form.Group as={Row} controlId='validationPhoneNumber'>
                      <Form.Label>{t('userSettings.lbl_Phone')}</Form.Label>
                      <Form.Control
                        name='phone'
                        type='text'
                        value={formik.values.phone}
                        placeholder={t('userSettings.plh_Phone')}
                        disabled={!hasRightsToEdit}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.phone && !!formik.errors.phone
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationWebsite'>
                      <Form.Label>
                        {t('companySettings.lbl_Website')}
                      </Form.Label>
                      <Form.Control
                        name='webSite'
                        type='text'
                        placeholder={t('companySettings.plh_Website')}
                        value={formik.values.webSite}
                        disabled={!hasRightsToEdit}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.webSite && !!formik.errors.webSite
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.webSite}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className='mx-3'>
                    <Form.Group as={Row} controlId='validationShortCompanyName'>
                      <Form.Label>
                        {t('companySettings.lbl_ShortName')}
                      </Form.Label>
                      <Form.Control
                        name='shortName'
                        type='text'
                        placeholder={t('companySettings.plh_ShortName')}
                        value={formik.values.shortName}
                        disabled={!hasRightsToEdit}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.shortName && !!formik.errors.shortName
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.shortName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationVATNumber'>
                      <Form.Label>
                        {t('companySettings.lbl_VatNumber')}
                      </Form.Label>
                      <Form.Control
                        name='vatNumber'
                        type='text'
                        value={formik.values.vatNumber}
                        placeholder={t('companySettings.plh_VatNumber')}
                        disabled={!hasRightsToEdit}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.vatNumber && !!formik.errors.vatNumber
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.vatNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationAddress2'>
                      <Form.Label>
                        {t('addressSettings.lbl_AddressLine2')}
                      </Form.Label>
                      <Form.Control
                        value={formik.values.addressLine2}
                        name='addressLine2'
                        type='text'
                        placeholder={t('addressSettings.plh_AddressLine2')}
                        disabled={!hasRightsToEdit}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.addressLine2 &&
                          !!formik.errors.addressLine2
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.addressLine2}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row className='mb-3'>
                      <Form.Group
                        as={Col}
                        className='p-0 me-2'
                        controlId='validationState'
                      >
                        <Form.Label>
                          {t('addressSettings.lbl_State')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder={t('addressSettings.plh_State')}
                          name='state'
                          value={formik.values.state}
                          disabled={!hasRightsToEdit}
                          onChange={formik.handleChange}
                          isInvalid={
                            formik.touched.state && !!formik.errors.state
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          {formik.errors.state}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        className='p-0'
                        controlId='validationCountry'
                      >
                        <Form.Label>
                          {t('addressSettings.lbl_Country')}
                        </Form.Label>
                        <Form.Select
                          name='country.code'
                          placeholder={t('addressSettings.plh_Country')}
                          disabled={!hasRightsToEdit}
                          value={
                            formik.values.country !== undefined
                              ? formik.values.country.code
                              : 'AD'
                          }
                          onChange={formik.handleChange}
                        >
                          {countries.map((country, index) => (
                            <option key={index} value={country.code}>
                              {country.shortName}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <Form.Group as={Row} controlId='validationEmail'>
                      <Form.Label>{t('userSettings.lbl_Email')}</Form.Label>
                      <Form.Control
                        name='email'
                        type='text'
                        placeholder={t('userSettings.plh_Email')}
                        value={formik.values.email}
                        disabled={!hasRightsToEdit}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.email && !!formik.errors.email
                        }
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {appState.userProfile?.isMarketplace && (
                      <Form.Group as={Row} controlId='validationMargin'>
                        <Form.Label>
                          {t('externalSupplier.lbl_Margin')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            name='margin'
                            type='number'
                            placeholder={t('externalSupplier.lbl_Margin')}
                            value={formik.values.margin}
                            disabled={!hasRightsToEdit}
                            onChange={formik.handleChange}
                            isInvalid={
                              formik.touched.margin && !!formik.errors.margin
                            }
                          />
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>

                        <Form.Control.Feedback type='invalid'>
                          {formik.errors.margin}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}

                    {appState.userProfile?.isMarketplace && (
                      <Form.Group
                        as={Row}
                        controlId='validationShowAsSubseller'
                      >
                        <Form.Label>
                          {t('externalSupplier.lbl_ShowAsSubSeller')}
                        </Form.Label>

                        <Form.Check
                          name='isSubSeller'
                          type='switch'
                          placeholder={t(
                            'externalSupplier.lbl_ShowAsSubSeller'
                          )}
                          checked={formik.values.isSubSeller}
                          disabled={!hasRightsToEdit}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>

                {hasRightsToEdit && (
                  <Button
                    variant='btn btn-eleczap mt-3'
                    className='float-end'
                    disabled={formik.isSubmitting}
                    onClick={formik.handleSubmit}
                  >
                    <Save className='mb-1 me-1' />
                    {t('common.btn_Save')}{' '}
                    {formik.isSubmitting && (
                      <Spinner animation='border' size='sm' />
                    )}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { OrderCreateForSessionModel } from 'models/buyer/documents/OrderModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { Order } from 'pages/buyer/Order/Order';
import { OrderUnsent } from 'pages/buyer/Order/OrderUnsent';
import { OrderUnsentClosedCard } from 'pages/buyer/Order/OrderUnsentClosedCard';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

type PropTypes = {
  getComparison?: () => Promise<void>;
};

export const OrderUnsentContainer = ({
  getComparison,
}: PropTypes): JSX.Element => {
  const [orders, setOrders] = useState<OrderCreateForSessionModel[]>([]);

  const [isOrdering, setIsOrdering] = useState<boolean>(false);
  const [currentSellerOrderOpened, setCurrentSellerOrderOpened] =
    useState<number>(1);

  const { t } = useTranslation('components');

  const setUserProfile = useSetUserProfile();

  const history = useHistory();
  const { appState } = useContext(AppContext);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const buyerCurrentProject = appState.userProfile?.currentProject;

  /* Get inquiries */
  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem('orders_unsent');

    if (sessionStorageData !== null) {
      const parsedData: OrderCreateForSessionModel[] = JSON.parse(
        sessionStorageData?.toString()
      );
      setOrders(parsedData);
      setCurrentSellerOrderOpened(parsedData[0]?.sellerCompanyId);
    }
  }, []);

  const closePojectOrder = async (
    clonedOrders: OrderCreateForSessionModel[]
  ) => {
    const filteredOrders = clonedOrders.filter(
      (order) => order.sentId !== null
    );

    filteredOrders.length === clonedOrders.length &&
      clonedOrders[0].project.id &&
      clonedOrders[0].parentProjectId &&
      (await BuyerApi.projectOrder.closeProjectOrder(
        currentUser.companyId,
        clonedOrders[0].parentProjectId,
        clonedOrders[0].project.id
      ));

    await setUserProfile(null);
  };

  const handleDiscardOrder = (sellerId: number) => {
    let clonedOrders = [...orders];

    const indexOfCurrentIteration = clonedOrders.findIndex(
      (order) => order.sellerCompanyId === sellerId
    );

    const filteredOrders = clonedOrders.filter(
      (inquiry) => inquiry.sellerCompanyId !== sellerId
    );

    const nextOrPrevInquiry =
      filteredOrders[indexOfCurrentIteration] !== undefined
        ? filteredOrders[indexOfCurrentIteration]
        : filteredOrders[indexOfCurrentIteration - 1];

    setOrders(filteredOrders);

    closePojectOrder(filteredOrders);

    sessionStorage.removeItem('orders_unsent');

    sessionStorage.setItem('orders_unsent', JSON.stringify(filteredOrders));

    filteredOrders.length === 0
      ? history.goBack()
      : handleOpenOrder(nextOrPrevInquiry.sellerCompanyId);
  };

  // useBeforeUnload(!isOrdering, t('common.inf_SureToLeave'));

  const handleOpenOrder = (sellerCompanyId: number) => {
    setCurrentSellerOrderOpened(sellerCompanyId);
  };

  const updateOrderSentId = async (orderProp: OrderCreateForSessionModel) => {
    const targetInquiryToUpdateIndex = orders.findIndex(
      (order) => order.sellerCompanyId === orderProp.sellerCompanyId
    );

    const clonedOrders = [...orders];

    clonedOrders[targetInquiryToUpdateIndex].sentId = orderProp.sentId;

    setOrders(clonedOrders);

    closePojectOrder(clonedOrders);

    sessionStorage.removeItem('orders_unsent');

    sessionStorage.setItem('orders_unsent', JSON.stringify(clonedOrders));

    getComparison && (await getComparison());
  };

  return (
    <div className='document-unsent-container d-flex justify-content-center'>
      <div className='d-flex flex-column'>
        {orders.map(
          (order, index) =>
            order.sellerCompanyId !== currentSellerOrderOpened && (
              <div
                className={`document-unsent ${
                  order.sellerCompanyId === currentSellerOrderOpened
                    ? 'document-unsent-open'
                    : 'document-unsent-closed'
                }`}
              >
                {order.sellerCompanyId !== currentSellerOrderOpened && (
                  <OrderUnsentClosedCard
                    orders={orders}
                    order={order}
                    handleOpenOrder={handleOpenOrder}
                    index={index}
                    currentSellerOrderOpened={currentSellerOrderOpened}
                  />
                )}
              </div>
            )
        )}
      </div>

      {/* <Prompt when={!isOrdering} message={t('common.inf_SureToLeave')} /> */}
      {orders.map((order, index: number) => (
        <div
          className={`document-unsent ${
            order.sellerCompanyId === currentSellerOrderOpened
              ? 'document-unsent-open'
              : 'document-unsent-closed'
          }`}
        >
          {order.sentId === null ? (
            <div
              className={
                order.sellerCompanyId !== currentSellerOrderOpened
                  ? 'd-none'
                  : ''
              }
            >
              <OrderUnsent
                fromOffer={getComparison !== undefined}
                setIsOrdering={setIsOrdering}
                handleDiscardOrder={handleDiscardOrder}
                orderProp={order}
                updateOrderSentId={updateOrderSentId}
              />
            </div>
          ) : (
            order.sellerCompanyId === currentSellerOrderOpened && (
              <Order passedOrderId={order.sentId} />
            )
          )}
        </div>
      ))}
    </div>
  );
};

import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { ProductDetailsModel } from 'models/shared/product/ProductDetailsModel';
import { Card } from 'react-bootstrap';
import { ProductDetailsEtim } from './ProductDetailsEtim';
import { ProductDetailsGeneralInfo } from './ProductDetailsGeneralInfo';
import { ProductDetailsPrice } from './ProductDetailsPrice';

type PropsType = {
  products: ProductDetailsModel[];
};

export const ProductDetailsBody = ({ products }: PropsType) => {
  const canSeePrices = useUserCanSeePrices();

  return (
    <Card.Body>
      {canSeePrices(<ProductDetailsPrice products={products} />)}
      <ProductDetailsGeneralInfo products={products} />
      {products[0].features && products[0].features.length > 0 ? (
        <ProductDetailsEtim items={products} />
      ) : null}

      {/*  Product history - Not in MVP1 */}
      {/* <Card>
        <Card.Header
          className='header-aProduct'
          onClick={() => setOpen(!open)}
          aria-controls='productHistory'
          aria-expanded={open}
        >
          {open ? <ArrowDownCircleFill /> : <ArrowRightCircleFill />} Product
          history
        </Card.Header>
        <Collapse in={open}>
          <Card.Body id='productHistory'>
            <Row>Dynamicaly generated data</Row>
          </Card.Body>
        </Collapse>
      </Card> */}
    </Card.Body>
  );
};

import { Api } from 'api/Api';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { UserCurrentProjectModel } from 'models/shared/user/UserCurrentProjectModel';
import { useCallback, useContext } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useParams } from 'react-router';

type UrlParamsType = {
  projectId: string;
};

/** Updates user current project in AppContext based on the projectId URL parameter */
export const useSetCurrentProjectFromUrl = (
  setProject?: (value: UserCurrentProjectModel | null) => void
) => {
  const { projectId } = useParams<UrlParamsType>();
  const { appState } = useContext(AppContext);
  const { appContextDispatch } = useContext(AppContext);
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const setUserCurrentProjectCallback = useCallback(async () => {
    const setUserCurrentProject = async (
      id: number,
      sellerCompanyId: number
    ) => {
        await Api.setCurrentProject(
        currentUser.companyId,
        currentUser.id,
        {
          id: id
        }
      );
      const user = await Api.getUser(
        currentUser.companyId,
        currentUser.id,
        sellerCompanyId
      );
      appContextDispatch({
        type: 'USER_SET_PROFILE',
        userProfile: user,
      });
      setProject && setProject(user.currentProject);
    };
    const currentProject = appState.userProfile?.currentProject;
    if (projectId) {
      const projectIdNumber = Number(projectId);

      if (!currentProject || projectIdNumber !== currentProject.id) {
        setUserCurrentProject(
          projectIdNumber,
          appState.buyerCurrentSupplierId ?? 0
        );
      } else {
        setProject && setProject(currentProject);
      }
    }
    setProject && setProject(currentProject || null);
  }, [
    appContextDispatch,
    appState.buyerCurrentSupplierId,
    appState.userProfile?.currentProject,
    currentUser.companyId,
    currentUser.id,
    projectId,
    setProject,
  ]);

  return setUserCurrentProjectCallback;
};

import { SellerApi } from 'api/SellerApi';
import {
  ProductBreadcrumb,
  ProductBreadcrumbItem
} from 'components/shared/products/ProductBreadcrumb';
import 'components/shared/products/ProductDetails/ProductDetails.css';
import { ProductDetailsBody } from 'components/shared/products/ProductDetails/ProductDetailsBody';
import { ProductDetailsHeader } from 'components/shared/products/ProductDetails/ProductDetailsHeader';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductDetailsModel } from 'models/shared/product/ProductDetailsModel';
import { MouseEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

type UrlParamsType = {
  productId: string;
};

export const ProductDetails = (): JSX.Element => {
  let { productId } = useParams<UrlParamsType>();

  const [product, setProduct] = useState<ProductDetailsModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [productBreadCrumbItems, setproductBreadCrumbItems] = useState<
    ProductBreadcrumbItem[]
  >([]);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const selectedCustomerId = appState.sellerCurrentCustomerId;
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const { t } = useTranslation('components');

  useEffect(() => {
    setIsLoading(true);

    const getProduct = async () => {
      const productDetail = await SellerApi.getProduct(
        currentUser.companyId,
        selectedCustomerId,
        productId,
        userLocaleId
      );
      setProduct(productDetail);

      const breadCrumbItems =
        productDetail.productClasses[0].parents.map<ProductBreadcrumbItem>(
          (c) => ({
            name: c.name,
            classId: c.id,
            classLevel: c.level,
            linkType: c.linkType,
            code: c.code,
          })
        );

      breadCrumbItems.push({
        name: productDetail.productClasses[0].name,
        classId: productDetail.productClasses[0].id,
        classLevel: productDetail.productClasses[0].level,
        linkType: productDetail.productClasses[0].linkType,
        code: productDetail.productClasses[0].code,
      });

      breadCrumbItems.push({
        name: productDetail.name,
        classId: 0,
        classLevel: 0,
        linkType: 'ThisClassProducts',
      });

      setproductBreadCrumbItems(breadCrumbItems);
      setIsLoading(false);
    };

    getProduct();
  }, [currentUser, selectedCustomerId, productId, userLocaleId]);

  const handleProductImageUpload = async (file: File) => {
    toast.dismiss();

    if (file) {
      const data = new FormData();
      data.append('file', file);
      const imageLocation = await SellerApi.uploadProductImage(
        currentUser.companyId,
        Number.parseInt(productId),
        data
      );

      setProduct({ ...product, image: imageLocation } as ProductDetailsModel);
      toast.info(t('common.inf_ImageUploaded'));
    }
  };

  const handleProductImageDelete = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    toast.dismiss();

    if (product?.image) {
      await SellerApi.deleteProductImage(
        currentUser.companyId,
        Number.parseInt(productId)
      );

      setProduct({ ...product, image: '' } as ProductDetailsModel);
      toast.info(t('common.inf_ImageDeleted'));
    }
  };

  return isLoading ? (
    <Spinner className='center-spinner' animation='border' />
  ) : (
    <>
      <ProductBreadcrumb
        items={productBreadCrumbItems}
        productClassesRoute='/seller/product-classes/'
        productSubClassesRoute='/seller/product-subclasses/'
      />
      {product ? (
        <div className='container-fluid p-4'>
          <Card className='p-4 position-relative'>
            <ProductDetailsHeader
              products={[product]}
              viewType='Seller'
              handleImageUpload={handleProductImageUpload}
              handleImageDelete={handleProductImageDelete}
            />
            <ProductDetailsBody products={[product]} />
          </Card>
        </div>
      ) : null}
    </>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { useFormik } from 'formik';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import {
  ProjectModel,
  ProjectUpdateModel,
} from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { getProjectDetails } from 'pages/buyer/Project/utilsProject';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';

type UrlParamsType = {
  projectId: string;
};

export const useGetProjectDetails = (
  currentUser: LoginResponseUserModel,
  setProjectDetails: (values: ProjectModel) => void,
  setIsLoading: (value: boolean) => void
) => {
  let { projectId } = useParams<UrlParamsType>();

  useEffect(() => {
    const getProject = async (): Promise<void> => {
      projectId !== null &&
        projectId !== undefined &&
        (await getProjectDetails(
          parseInt(projectId),
          currentUser,
          setProjectDetails
        ));

      setIsLoading(false);
    };

    getProject();
  }, [
    currentUser,
    currentUser.companyId,
    projectId,
    setIsLoading,
    setProjectDetails,
  ]);
};

/* Formik init */
export const useFormikHookForProject = (
  currentUser: LoginResponseUserModel,
  projectDetails: ProjectModel,
  setProjectDetails: (values: ProjectModel) => void,
  t: any
): any => {
  const [currentProjectName, setCurrentProjectName] = useState('');
  const setUserProfile = useSetUserProfile();

  const { appState } = useContext(AppContext);

  const handleSubmit = async (
    currentUser: LoginResponseUserModel,
    values: ProjectUpdateModel
  ): Promise<void> => {
    toast.dismiss();
    await BuyerApi.updateProject(
      currentUser.companyId,
      projectDetails.id,
      values
    );

    // If project name was changed, reload user profile with updated project name
    // into AppContext so that current project name in header is updated also
    if (currentProjectName !== values.name) {
      await setUserProfile(appState.buyerCurrentSupplierId);
      setCurrentProjectName(values.name);
    }

    await getProjectDetails(projectDetails.id, currentUser, setProjectDetails);
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),
  });

  const formik = useFormik<ProjectModel>({
    initialValues: { ...projectDetails },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedProject: ProjectUpdateModel = {
        name: values.name,
        requestedDeliveryDate: null,
        deliveryAddressId: null,
        comment: null,
      };
      await handleSubmit(currentUser, updatedProject);

      formik.setSubmitting(false);
    },
  });

  return formik;
};

import { BuyerApi } from 'api/BuyerApi';
import { useFormikHookForBuyerGlobalNotifications } from 'components/buyer/settings/buyerNotifications/hooksBuyerNotifications';
import { BuyerGlobalNotificationsModel } from 'models/buyer/notifications/BuyerNotificationsModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const GlobalNotifications = () => {
  const [globalNotifications, setGlobalNotifications] =
    useState<BuyerGlobalNotificationsModel>(
      {} as BuyerGlobalNotificationsModel
    );

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const getGlobalNotifications = useCallback(async () => {
    const response = await BuyerApi.getBuyerGlobalNotifications(
      currentUser.companyId
    );

    setGlobalNotifications(response);
  }, [currentUser.companyId]);

  useEffect(() => {
    currentUser.companyId && getGlobalNotifications();
  }, [currentUser.companyId, getGlobalNotifications]);

  const formik = useFormikHookForBuyerGlobalNotifications(globalNotifications);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    toast.dismiss();
    formik.handleChange(event);
    await formik.submitForm();
    toast.info(
      event.target.checked
        ? t('userSettings.inf_NotificationEnabled')
        : t('userSettings.inf_NotificationDisabled')
    );

    await getGlobalNotifications();
  };

  return (
    <Col lg={3}>
      <strong>{t('userSettings.lbl_Global')}</strong>
      <p>{t('userSettings.inf_Global')}</p>

      <Row>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.newOfferReceived}
            name='newOfferReceived'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_NewOfferReceived')}</span>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col style={{ maxWidth: '40px' }}></Col>
        <Col>
          <Form.Group>
            <Form.Control
              onChange={formik.handleChange}
              name='newOfferEmail'
              value={formik.values.newOfferEmail}
              type='input'
              onBlur={(event) => formik.submitForm()}
              isInvalid={
                formik.touched.newOfferEmail && !!formik.errors.newOfferEmail
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.newOfferEmail}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            name='buyerNewOrderReceived'
            checked={formik.values.buyerNewOrderReceived}
            type='switch'
            aria-label='radio 1'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_NewOrderCreatedBySupplier')}</span>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col style={{ maxWidth: '40px' }}></Col>
        <Col>
          <Form.Group>
            <Form.Control
              name='buyerNewOrderEmail'
              onChange={formik.handleChange}
              value={formik.values.buyerNewOrderEmail}
              type='input'
              onBlur={(event) => formik.submitForm()}
              isInvalid={
                formik.touched.buyerNewOrderEmail &&
                !!formik.errors.buyerNewOrderEmail
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.buyerNewOrderEmail}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            name='newInvoiceReceived'
            checked={formik.values.newInvoiceReceived}
            type='switch'
            aria-label='new-invoice-received'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_NewInvoiceReceived')}</span>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col style={{ maxWidth: '40px' }}></Col>
        <Col>
          <Form.Group>
            <Form.Control
              name='newInvoiceEmail'
              onChange={formik.handleChange}
              value={formik.values.newInvoiceEmail}
              type='input'
              onBlur={(event) => formik.submitForm()}
              isInvalid={
                formik.touched.newInvoiceEmail &&
                !!formik.errors.newInvoiceEmail
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.newInvoiceEmail}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Col>
  );
};

/* Hooks utilized in User management settings*/

import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { UserModel } from 'models/shared/user/UserModel';
import { useContext, useEffect } from 'react';
import { getAllUsers, getBuyerRoles, getSellerRoles } from './utilsUsers';

/* Get all users */
export const useGetAllUsers = (
  currentUser: LoginResponseUserModel,
  setUsers: (values: UserModel) => void,
  rowsPerPage?: number
) => {
  useEffect(() => {
    getAllUsers(currentUser, setUsers, undefined, undefined, rowsPerPage);
  }, [
    currentUser,
    currentUser.companyId,
    currentUser.id,
    rowsPerPage,
    setUsers,
  ]);
};

/* Get current default view */
export const useGetDefaultViewRoles = (
  setChangedView: (value: string) => void,
  setRoleNames?: (value: string[]) => void
) => {
  const { appState } = useContext(AppContext);

  useEffect(() => {
    let view: string = '';

    const userStoredInSessionStorage: string | null =
      sessionStorage.getItem('_elecZapUserView');

    const getRoles = async (view: string): Promise<void> => {
      const roles =
        view === 'Seller' ? await getSellerRoles() : await getBuyerRoles();

      setRoleNames !== undefined && setRoleNames(roles);
    };

    if (userStoredInSessionStorage !== null) {
      const userStoredInSessionStorageJSON =
        userStoredInSessionStorage.toString();

      view = userStoredInSessionStorageJSON;
    } else if (appState.userProfile !== null) {
      view = appState.userProfile.defaultView;
    }
    setChangedView(view);
    setRoleNames !== undefined && getRoles(view);
  }, [setChangedView, setRoleNames, appState.userProfile]);
};

import { AppContext } from 'contexts/user/AppContext';
import { GroupedProductModel } from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Button, FormControl, InputGroup, Row } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

type PropTypes = {
  product: GroupedProductModel;
};

type ChildPropTypes = {
  product: GroupedProductModel;
};

const CustomProductClassListQtyInputEnabled = ({ product }: ChildPropTypes) => {
  const { appState, appContextDispatch } = useContext(AppContext);

  const [internalValue, setInternalValue] = useState<number>(0);

  const [isValid, setIsValid] = useState<boolean>(true);
  const [isMinimumReached, setIsMinimumReached] = useState<boolean>(true);

  const handleItemQtyChange = (quantity: number | null) => {
    if (appState.currentProducts) {
      const clonedProducts = [...appState.currentProducts];

      let clonedProductsWithQtys: GroupedProductModel[] = [];

      if (appState.currentProductsWithQtys)
        clonedProductsWithQtys = [...appState.currentProductsWithQtys];

      const itemToChange = clonedProducts.find(
        (clonedProduct) => clonedProduct.productCode === product?.productCode
      );

      if (itemToChange === undefined) return;

      itemToChange.quantity = quantity;

      /* Check if item already added */
      const productWithQtyIndex = clonedProductsWithQtys.findIndex(
        (productWithQty) => productWithQty.productCode === product.productCode
      );

      if (productWithQtyIndex === -1) clonedProductsWithQtys.push(itemToChange);
      else {
        clonedProductsWithQtys[productWithQtyIndex].quantity = quantity;
      }

      appContextDispatch({
        type: 'BUYER_SET_CURRENT_PRODUCT_LIST_WITH_QTYS',
        currentProductsWithQtys: clonedProductsWithQtys,
      });
    }
  };

  useEffect(() => {
    if (appState.currentProductsWithQtys?.length === 0) {
      setInternalValue(0);
    }
  }, [appState.currentProductsWithQtys.length]);

  const setNewValue = (newValue: number) => {
    if (isMinimumValue(newValue)) {
      setIsMinimumReached(true);
    } else {
      setIsMinimumReached(false);
    }

    if (!isValidValue(newValue)) {
      setIsValid(false);
      return;
    }
    setInternalValue(newValue);
    setIsValid(true);
  };

  const isValidValue = (value: number): boolean => {
    return value > -1;
  };

  const isMinimumValue = (value: number): boolean => {
    if (product === null) {
      return internalValue === 0;
    }

    return value === 0;
  };

  const handleIncrement = () => {
    let newValue: number = 0;

    newValue = internalValue + 1;

    if (!isValidValue(newValue)) {
      newValue = 0;
    }

    setNewValue(newValue);

    handleItemQtyChange(newValue);
  };

  const handleDecrement = () => {
    if (isMinimumReached) {
      return;
    }

    let newValue: number = 0;

    newValue = internalValue - 1;

    setNewValue(newValue);
    handleItemQtyChange(newValue);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(parseInt(event.target.value));
    handleItemQtyChange(parseInt(event.target.value));
  };

  // const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
  //   handleItemQtyChange(internalValue);
  // };

  return (
    <>
      <InputGroup
        style={{ maxWidth: '100px' }}
        className={`${
          isValid ? 'custom-project-table-input' : 'input-group-is-invalid'
        }`}
      >
        <FormControl
          className='borderless-input product-list-qty-input'
          value={internalValue?.toString()}
          type='number'
          style={{ textAlign: 'right' }}
          onChange={handleChange}
          // onBlur={handleBlur}
        />
        <Row
          style={{ width: '34px' }}
          className='custom-project-table-input-row me-1'
        >
          <Button
            onClick={() => handleIncrement()}
            className='p-0 m-0'
            variant='light'
            style={{
              height: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CaretUpFill
              style={{ cursor: 'pointer' }}
              className='icon-ez p-0 m-0'
            />
          </Button>
          <Button
            onClick={() => handleDecrement()}
            className='p-0 m-0'
            variant='light'
            style={{
              height: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CaretDownFill
              style={{ cursor: 'pointer' }}
              className={`${!isMinimumReached ? 'icon-ez p-0 m-0' : 'p-0 m-0'}`}
            />
          </Button>
        </Row>
      </InputGroup>
    </>
  );
};

export const CustomProductListQtyInput = (props: PropTypes) => {
  return <CustomProductClassListQtyInputEnabled product={props.product} />;
};

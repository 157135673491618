import { SellerApi } from 'api/SellerApi';
import { ModalAssignContacts } from 'components/seller/customers/ModalAssignContacts/ModalAssignContacts';
import { getSpecificCustomersUsers } from 'components/seller/customers/utilsCustomer';
import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { ModalRemoveAssignedContact } from 'components/shared/contacts/ModalRemoveAssignedContact';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { BuyerContactsModel } from 'models/seller/contacts/BuyerContactsModel';
import { BuyersUsersModel } from 'models/seller/customers/BuyersUsersModel';
import { useCallback, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Col, Row } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  customerId: number;
  customerName: string;
};

export const AcustomerContacts = ({ customerId, customerName }: PropTypes) => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const [buyerContacts, setBuyerContacts] = useState<BuyerContactsModel[]>([]);
  const [show, setShow] = useState(false);
  const [customersUsers, setCustomersUsers] = useState<BuyersUsersModel>(
    {} as BuyersUsersModel
  );

  const getContacts = useCallback(async (): Promise<void> => {
    const response = await SellerApi.getBuyerContacts(
      currentUser.companyId,
      customerId
    );

    setBuyerContacts(response);
  }, [currentUser.companyId, customerId]);

  useEffect(() => {
    customerId !== undefined && getContacts();
  }, [customerId, getContacts]);

  const { t } = useTranslation('components');

  const handleShow = () => {
    setShow(true);
  };

  const handleRemoveAssignedContact = async (id: number): Promise<void> => {
    toast.dismiss();
    await SellerApi.updateBuyerContacts(currentUser.companyId, customerId, {
      userIds: [],
      contactIds: [id],
    });

    toast.info(t('customers.inf_AssignedContactsUpdated'));

    await getContacts();
  };

  useEffect(() => {
    customerId &&
      getSpecificCustomersUsers(currentUser, setCustomersUsers, customerId);
  }, [currentUser, customerId]);

  const activeBuyerContacts = buyerContacts.filter(
    (contact) => contact.isContact
  );

  return (
    <>
      <Card className='p-3'>
        <Card.Header>
          <h4>{t('customers.hd_AssignedContacts')}</h4>
        </Card.Header>
        <Card.Body className='d-flex flex-wrap'>
          {activeBuyerContacts.map((contact) => (
            <div
              style={{
                width: '230px',
              }}
              className='border p-4 m-2'
            >
              <Row>
                <Col lg={1} />
                <Col className='d-flex justify-content-center'>
                  <AvatarWithFallback
                    imageUrl={contact.profileImage}
                    initials={contact.initials}
                    size='x-large'
                  />
                </Col>
                <Col lg={1}>
                  {activeBuyerContacts.length > 1 && (
                    <ModalRemoveAssignedContact
                      contactName={`${contact.firstName} ${contact.lastName}`}
                      customerName={customerName}
                      id={contact.id}
                      handleRemoveAssignedContact={handleRemoveAssignedContact}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <h4 className='text-center mt-4'>{`${contact.firstName} ${contact.lastName}`}</h4>
              </Row>

              <Row>
                <h5 className='text-center'>
                  <a href={`mailto:${contact.email}`} className='icon-ez'>
                    {contact.email}
                  </a>
                </h5>
              </Row>
              <Row>
                <h5 className='text-center'>{contact.phone}</h5>
              </Row>
              <Row>
                <span className='text-center'>{contact.position}</span>
              </Row>
            </div>
          ))}
          <div
            onClick={handleShow}
            style={{
              width: '230px',
            }}
            className='border p-4 m-2 cursor-pointer'
          >
            <Row>
              <PlusCircle className='icon-ez' size={112} />
            </Row>
            <Row>
              <h4 className='text-center mt-4 icon-ez'>
                {t('customers.lbl_AssignContacts')}
              </h4>
            </Row>
            <Row>
              <br />
            </Row>
            <Row>
              <br />
            </Row>
          </div>
          <ModalAssignContacts
            postAssignTaskFunction={getContacts}
            fromPage='customerContacts'
            customerId={customerId}
            show={show}
            setShow={setShow}
          />
        </Card.Body>
      </Card>

      <Card className='p-3'>
        <Card.Header>
          <h4>{t('customers.hd_CustomerContacts')}</h4>
        </Card.Header>
        <Card.Body className='d-flex flex-wrap'>
          {customersUsers.data !== undefined &&
            customersUsers.data.map((contact) => (
              <div
                style={{
                  width: '230px',
                }}
                className='border p-4 m-2'
              >
                <Row className='justify-content-center'>
                  <AvatarWithFallback
                    imageUrl={contact.profileImage}
                    initials={contact.initials}
                    size='x-large'
                  />
                </Row>
                <Row>
                  <h4 className='text-center mt-4'>{contact.fullName}</h4>
                </Row>

                <Row>
                  <h5 className='text-center'>
                    <a href={`mailto:${contact.email}`} className='icon-ez'>
                      {contact.email}
                    </a>
                  </h5>
                </Row>
                <Row>
                  <h5 className='text-center'>{contact.phone}</h5>
                </Row>
                <Row>
                  <span className='text-center'>{contact.position}</span>
                </Row>
              </div>
            ))}
        </Card.Body>
      </Card>
    </>
  );
};

import {
  ProjectInquiryRowModel,
  ProjectInquiryUpdateRowModel,
} from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { updateProjectInquiryRows } from 'pages/buyer/ProjectInquiry/utilsProjectInquiry';
import { ChangeEvent, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

type PropTypes = {
  row: ProjectInquiryRowModel;
  currentUser: LoginResponseUserModel;
  projectId: number;
  projectInquiryId: number;
  getDataSource: () => Promise<void>;
  setIsRequiredQtyUpdating: (value: boolean) => void;
  isRequiredQtyUpdating: boolean;
};

export const CustomSingleRequiredQuantityInput = ({
  row,
  currentUser,
  projectId,
  projectInquiryId,
  getDataSource,
  setIsRequiredQtyUpdating,
  isRequiredQtyUpdating,
}: PropTypes) => {
  const [internalValue, setInternalValue] = useState<number>(
    !isNaN(row.requiredQuantity) ? row.requiredQuantity : 0
  );
  const [internalRow, setInternalRow] = useState<ProjectInquiryRowModel>(row);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(parseInt(event.target.value));

    let clonedRow: ProjectInquiryRowModel = { ...internalRow };

    clonedRow.requiredQuantity = parseInt(event.target.value);

    setInternalRow(clonedRow);
  };

  const handleSaveRow = async () => {
    setIsRequiredQtyUpdating(true);

    setIsFocused(false);

    let clonedRow = { ...internalRow };
    if (isNaN(internalValue)) {
      setInternalValue(0);
      clonedRow = { ...clonedRow, requiredQuantity: 0 };
    }

    if (row !== undefined) {
      const rowUpdate: ProjectInquiryUpdateRowModel = {
        productCode: row.productCode,
        productName: row.productName,
        requiredQuantity: clonedRow.requiredQuantity,
        targetPrice: row.targetPrice,
        allowReplacement: row.allowReplacement,
      };
      await updateProjectInquiryRows(
        rowUpdate,
        currentUser.companyId,
        projectId,
        projectInquiryId,
        row.id
      );
      await getDataSource();

      setInternalRow(row);
      setIsRequiredQtyUpdating(false);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <InputGroup
      style={{ maxWidth: '110px' }}
      className={
        isFocused
          ? 'custom-project-table-input px-2 required-qty-focused'
          : 'custom-project-table-input px-2'
      }
    >
      <FormControl
        className='borderless-input custom-required-qty-input'
        defaultValue={row.requiredQuantity}
        value={internalValue}
        min={0}
        type='number'
        onFocus={handleFocus}
        onBlur={handleSaveRow}
        style={{ textAlign: 'right' }}
        onChange={handleChange}
      />
      <span className='me-2 icon-ez-gray'>
        {/* / {row.supplierQuantity} */}
      </span>
    </InputGroup>
  );
};

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  apiResponseInterceptor,
  jwtRequestInterceptor,
} from './ApiInterceptors';

const client = axios.create({ baseURL: '/api' });
client.interceptors.response.use(undefined, apiResponseInterceptor);
client.interceptors.request.use(jwtRequestInterceptor);

export const responseBody = (response: AxiosResponse) => response.data;
export const createdAt = (response: AxiosResponse) =>
  response.headers['location'];

export type FileBlob = {
  name: string;
  data: Blob;
};

export const fileBlob = (response: AxiosResponse): FileBlob => {
  let fileName = 'unknown';
  const contentDisposition = response.headers['content-disposition'];
  if (contentDisposition) {
    var filenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
    var matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = decodeURI(matches[1].replace(/['"]/g, ''));
    }
  }

  return { data: response.data, name: fileName };
};

export const ApiClient = {
  get: (url: string) => client.get(url).then(responseBody),

  getWithAuthHeader: (url: string, token: string) =>
    client
      .get(url, {
        headers: { Authorization: 'Bearer ' + token },
      } as AxiosRequestConfig)
      .then(responseBody),

  post: (url: string, body: {}) => client.post(url, body).then(responseBody),
  postCreate: (url: string, body: {}) => client.post(url, body).then(createdAt),
  put: (url: string, body: {}) => client.put(url, body).then(responseBody),
  delete: (url: string) => client.delete(url).then(responseBody),
};

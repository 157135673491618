import {
  getSupplierCompanyInfo,
  getSupplierCreditInfo,
} from 'components/buyer/supplierInfo/utilsSupplierInfo';
import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { AppContext } from 'contexts/user/AppContext';
import { useGetFormattedPrice } from 'hooks/useGetFormattedPrice';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { OfferRowModel } from 'models/buyer/documents/OfferModels';
import { SellerCreditInfoModel } from 'models/buyer/suppliers/SellerCreditInfoModel';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { ChevronDoubleLeft, ChevronDoubleRight } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { getMOQColumns } from 'utils/getMOQColumns';
import { getStockLevelColumns } from 'utils/getStockLevelColumns';

type PropTypes = {
  showCanvas: boolean;
  handleShowCanvas: () => void;
  supplierName: string;
  supplierId: number;
  offerRows: OfferRowModel[];
  isExternalSeller: boolean;
};

export const SupplierMoreInfoPane = ({
  showCanvas,
  handleShowCanvas,
  supplierName,
  supplierId,
  offerRows,
  isExternalSeller,
}: PropTypes) => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const [company, setCompany] = useState<SupplierCompanyModel>(
    {} as SupplierCompanyModel
  );

  const [creditInfo, setCreditInfo] = useState<SellerCreditInfoModel>(
    {} as SellerCreditInfoModel
  );

  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');
  const getFormattedPrice = useGetFormattedPrice();
  const canSeePrices = useUserCanSeePrices();

  /* Get buyer's credit information */
  useEffect(() => {
    const getCreditInfo = async (): Promise<void> => {
      supplierId !== undefined &&
        (await getSupplierCreditInfo(setCreditInfo, currentUser, supplierId));
    };

    const getCompanyInfo = async (): Promise<void> => {
      supplierId !== undefined &&
        (await getSupplierCompanyInfo(setCompany, currentUser, supplierId));
    };

    getCreditInfo();
    getCompanyInfo();
  }, [currentUser, supplierId]);

  const moqColumns = getMOQColumns(getFormattedPrice, t);

  const stockLevelColumns = getStockLevelColumns(t);

  const columns: TableColumn<OfferRowModel>[] = [
    {
      name: t('common.lbl_Instock'),
      selector: (row) => row.stockLevels.toString(),
      cell: (row) => {
        let totalStockLevel = 0;

        row.stockLevels.forEach((stockLevel) => {
          totalStockLevel += stockLevel.inStock;
        });

        const popover = (
          <Popover style={{ minWidth: '500px' }} id='popover-stockLevels'>
            <Popover.Header as='h3'>
              {t('projectSettings.lbl_Stocks')}
            </Popover.Header>
            <Popover.Body>
              <DataTable columns={stockLevelColumns} data={row.stockLevels} />
            </Popover.Body>
          </Popover>
        );
        return isExternalSeller ? (
          '-'
        ) : row.stockLevels.length > 1 ? (
          <OverlayTrigger trigger='hover' placement='auto' overlay={popover}>
            <span>
              <strong className='btn-link-bold'>
                {totalStockLevel ?? '-'}
              </strong>
            </span>
          </OverlayTrigger>
        ) : (
          totalStockLevel
        );
      },
      right: true,
    },

    {
      name: t('documents.lbl_TargetPrice'),
      selector: (row) => row.targetPrice,
      cell: (row) => row.targetPrice ?? '-',
      right: true,
    },

    {
      name: t('documents.lbl_ListPrice'),
      selector: (row) => row.moqPrices.toString(),
      cell: (row) => {
        const reverseClone = [...row.moqPrices].reverse();
        const popover = (
          <Popover id='popover-moqPrices'>
            <Popover.Body>
              <DataTable columns={moqColumns} data={row.moqPrices} />
            </Popover.Body>
          </Popover>
        );
        return isExternalSeller
          ? '-'
          : canSeePrices(
              row.moqPrices.length > 1 ? (
                <OverlayTrigger
                  trigger='hover'
                  placement='auto'
                  overlay={popover}
                >
                  <span>
                    <strong className='btn-link-bold'>
                      {getFormattedPrice(
                        reverseClone.find((moq) => row.quantity >= moq.moq) ||
                          row.moqPrices[0]
                      )}
                    </strong>
                  </span>
                </OverlayTrigger>
              ) : (
                getFormattedPrice(row.moqPrices[0])
              )
            );
      },
      right: true,
    },
  ];

  return (
    <Card
      className='ms-1'
      style={{
        transitionDuration: '0.2s',
        width: showCanvas ? '40vh' : '84px',
      }}
    >
      <div className='supplier-side-pane-info mb-3'>
        {!showCanvas && (
          <ChevronDoubleLeft
            className='icon-ez float-end me-4 mt-3 cursor-pointer'
            size={32}
            onClick={handleShowCanvas}
          />
        )}
        <div className='d-flex justify-content-between mt-3 mx-4'>
          <div style={{ width: '48px' }} />
          {showCanvas && (
            <AvatarWithFallback
              imageUrl={company.logo}
              initials={company.initials}
              size='x-large'
            />
          )}

          {showCanvas && (
            <ChevronDoubleRight
              className='icon-ez ms-3 float-end cursor-pointer'
              size={32}
              onClick={handleShowCanvas}
            />
          )}
        </div>
        <div
          style={{ visibility: showCanvas ? 'visible' : 'hidden' }}
          className='my-2 mx-3 d-flex flex-column align-items-center'
        >
          <h3 className='text-center'>{supplierName}</h3>
          <div className={showCanvas ? 'mt-3 w-100' : 'mt-3'}>
            {!company.isExternalSeller && (
              <>
                <Row>
                  <Col>{t('creditInfo.lbl_TotalCredit')}:</Col>
                  <Col className='text-end'>
                    {formatMonetaryValue(
                      creditInfo.currencyCode,
                      creditInfo.limit,
                      appState
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>{t('creditInfo.lbl_RemainingCredit')}:</Col>
                  <Col className='text-end'>
                    {formatMonetaryValue(
                      creditInfo.currencyCode,
                      creditInfo.remainingCredit,
                      appState
                    )}
                  </Col>
                </Row>
              </>
            )}
            {/* <Row>
              <Col>{t('creditInfo.lbl_UnpaidInvoices')}:</Col>
              <Col className='text-end'>
                {useFormatMonetaryValue(
                  creditInfo.currencyCode,
                  creditInfo.unpaidInvoices
                )}
              </Col>
            </Row> */}
            {/* <Row>
              <Col>{t('creditInfo.lbl_OverdueInvoices')}:</Col>
              <Col className='text-end'>
                {useFormatMonetaryValue(
                  creditInfo.currencyCode,
                  creditInfo.overdueInvoices
                )}
              </Col>
            </Row> */}
          </div>
        </div>
      </div>
      {showCanvas && (
        <div className='mt-5'>
          <DataTable
            noDataComponent={
              <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
            }
            columns={columns}
            data={offerRows}
          />
        </div>
      )}
    </Card>
  );
};

import { ProjectAttachmentsModel } from 'models/shared/attachments/AttachmentModel';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import {
  FileEaselFill,
  FileImage,
  FilePdfFill,
  FilePptFill,
  FileSpreadsheetFill,
  FileTextFill,
  TrashFill,
} from 'react-bootstrap-icons';

type PropTypes = {
  attachment: ProjectAttachmentsModel;
  index: number;
  dealId: number;
  type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice';
  handleDeleteAttachment: (
    id: number,
    dealId: number,
    type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice'
  ) => Promise<void>;
};

export const ProjectDealsAttachmentItem = ({
  attachment,
  index,
  handleDeleteAttachment,
  dealId,
  type,
}: PropTypes) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  var fileExtension = attachment.name.split('.').pop();

  let fileIcon;

  switch (fileExtension) {
    case 'pdf':
      fileIcon = <FilePdfFill className='icon-ez' />;
      break;

    case 'xls':
    case 'xlsx':
      fileIcon = <FileSpreadsheetFill className='icon-ez' />;
      break;
    case 'ppt':
    case 'pptx':
      fileIcon = <FilePptFill className='icon-ez' />;
      break;
    case 'svg':
    case 'step':
      fileIcon = <FileEaselFill className='icon-ez' />;
      break;

    case 'doc':
    case 'docx':
    case 'rtf':
    case 'txt':
      fileIcon = <FileTextFill className='icon-ez' />;
      break;

    case 'jpg':
    case 'jpeg':
    case 'png':
      fileIcon = <FileImage className='icon-ez' />;
      break;
    default:
      break;
  }

  return (
    <Dropdown.Item
      as='span'
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      eventKey={index}
    >
      <div className='d-flex align-items-center' style={{ minWidth: '200px' }}>
        <span className='me-1'>{fileIcon}</span>

        <a className='flex-grow-1' href={attachment.uri}>
          <span>{decodeURIComponent(attachment.name)}</span>
        </a>

        {handleDeleteAttachment !== undefined && isHovering ? (
          <div style={{ cursor: 'pointer', width: '30px' }} className=' ms-3'>
            {attachment.byBuyer && (
              <TrashFill
                onClick={() =>
                  handleDeleteAttachment !== undefined &&
                  handleDeleteAttachment(attachment.id, dealId, type)
                }
                className='icon-ez-red float-end'
              />
            )}
          </div>
        ) : (
          <div style={{ width: '30px' }} className=' ms-3' />
        )}
      </div>
    </Dropdown.Item>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import {
  InquiryCreateForSessionModel,
  InquiryModel,
} from 'models/buyer/documents/InquiryModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card } from 'react-bootstrap';
import { ChevronDoubleLeft, ChevronDoubleRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  inquiries: InquiryCreateForSessionModel[];
  inquiry: InquiryCreateForSessionModel;
  handleOpenInquiry: (sellerCompanyId: number) => void;
  index: number;
  currentSellerInquiryOpened: number;
};

export const InquiryUnsentClosedCard = ({
  inquiries,
  inquiry,
  handleOpenInquiry,
  index,
  currentSellerInquiryOpened,
}: PropTypes) => {
  const { t } = useTranslation('components');

  const [fetchedInquiry, setFetchedInquiry] = useState<InquiryModel>(
    {} as InquiryModel
  );

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  useEffect(() => {
    const getFetchedInquiry = async (): Promise<void> => {
      if (inquiry.sentId !== null) {
        const response = await BuyerApi.getInquiry(
          currentUser.companyId,
          inquiry.sentId,
          userLocaleId
        );

        setFetchedInquiry(response);
      }
    };
    getFetchedInquiry();
  }, [currentUser.companyId, inquiry.sentId, userLocaleId]);

  return (
    <Card
      className={
        index >
        inquiries.findIndex(
          (inquiry) => inquiry.sellerCompanyId === currentSellerInquiryOpened
        )
          ? 'py-3 px-1 me-2 mt-2 ms-3'
          : 'py-3 px-1 ms-2 mt-2 me-3'
      }
    >
      <div
        onClick={() => handleOpenInquiry(inquiry.sellerCompanyId)}
        className='cursor-pointer'
      >
        <h3 className='d-flex flex-row align-items-center'>
          {index >
          inquiries.findIndex(
            (inquiry) => inquiry.sellerCompanyId === currentSellerInquiryOpened
          ) ? (
            <ChevronDoubleLeft size={30} className='icon-ez mb-3' />
          ) : (
            <ChevronDoubleRight size={30} className='icon-ez mb-3' />
          )}
          <span className='btn-link-bold my-2'>
            {t('documents.hd_Inquiry')}
          </span>{' '}
          / <span className='my-2'>{inquiry.sellerCompanyName}</span> /{' '}
          {inquiry.sentId === null ? (
            <span className='icon-ez-gray my-2'>
              {t('documents.lbl_Unsent')}
            </span>
          ) : (
            <>
              {fetchedInquiry.status === 'Sending' && (
                <span className='icon-ez-gray my-2'>
                  {t('documents.lbl_Sending')}
                </span>
              )}

              {fetchedInquiry.status === 'NoReply' && (
                <span className='icon-ez-yellow my-2'>
                  {t('documents.lbl_NoReply')}
                </span>
              )}
              {fetchedInquiry.status === 'OfferMade' && (
                <span className='icon-ez my-2'>
                  {t('documents.lbl_OfferMade')}
                </span>
              )}
            </>
          )}
        </h3>
      </div>
    </Card>
  );
};

import { useGetSellerStocks } from 'components/buyer/supplierInfo/hooksSupplierInfo';
import {
  getSellerStocks,
  updateSellerIsFavoriteStock,
  updateSellerStock,
} from 'components/buyer/supplierInfo/utilsSupplierInfo';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import {
  SellerStocksModel,
  SellerUpdateIsFavoriteStockModel,
  SellerUpdateStocksModel,
} from 'models/buyer/suppliers/SellerStocksModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import React, { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Form } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getUrlParams } from 'utils/getUrlParams';

export const SupplierStocks = (): JSX.Element => {
  const [sellerStocks, setSellerStocks] = useState<SellerStocksModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');

  const supplierId = getUrlParams('id');

  useGetSellerStocks(setSellerStocks, currentUser, supplierId);

  const handleStockUpdate = async (
    field: 'isFavorite' | 'isVisible',
    check: boolean,
    id: number
  ) => {
    setIsLoading(true);

    if (field === 'isVisible') {
      const stockUpdate: SellerUpdateStocksModel = {
        isVisible: check,
      };

      supplierId !== null &&
        (await updateSellerStock(
          currentUser,
          parseInt(supplierId),
          stockUpdate,
          id
        ));

      supplierId !== null &&
        (await getSellerStocks(
          setSellerStocks,
          currentUser,
          parseInt(supplierId)
        ));
    }

    if (field === 'isFavorite') {
      const stockUpdate: SellerUpdateIsFavoriteStockModel = {
        isFavorite: check,
      };

      supplierId !== null &&
        (await updateSellerIsFavoriteStock(
          currentUser,
          parseInt(supplierId),
          stockUpdate,
          id
        ));

      supplierId !== null &&
        (await getSellerStocks(
          setSellerStocks,
          currentUser,
          parseInt(supplierId)
        ));
    }

    setIsLoading(false);

    toast.dismiss();
    toast.success(t('stockSettings.inf_StockSaved'));
  };

  const sellerStockColumns: TableColumn<SellerStocksModel>[] = [
    {
      name: t('stockSettings.lbl_DisplayedName'),
      selector: (row) => row.name,
      sortable: true,
      maxWidth: '15rem',
      minWidth: '15rem',
    },
    {
      name: t('addressSettings.lbl_Address'),
      selector: (row) => row.address,
      sortable: true,
    },

    {
      name: t('stockSettings.lbl_AdditionalInfo'),
      selector: (row) => row.additionalInfo,
      sortable: true,
      maxWidth: '250px',
      minWidth: '250px',
    },
    {
      name: t('common.lbl_Favourite'),
      selector: (row) => row.isFavorite,
      cell: (row) => (
        <Form.Check
          disabled={!row.isVisible}
          checked={row.isFavorite}
          onClick={(event: React.MouseEvent<HTMLInputElement>) =>
            handleStockUpdate('isFavorite', event.currentTarget.checked, row.id)
          }
          type='radio'
          id='isFavourite'
        />
      ),
      sortable: true,
      center: true,
      maxWidth: '100px',
      minWidth: '100px',
    },
    {
      name: t('addressSettings.lbl_Visible'),
      selector: (row) => row.isVisible,
      cell: (row) => (
        <Form.Check
          disabled={row.isFavorite}
          checked={row.isVisible}
          onChange={(event) =>
            handleStockUpdate('isVisible', event.target.checked, row.id)
          }
          type='switch'
          id='defaultAadress'
        />
      ),
      sortable: true,
      center: true,
      maxWidth: '100px',
      minWidth: '100px',
    },
  ];

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Card className='p-3'>
        <Card.Header>
          <h4>{t('stockSettings.hd_Stocks')}</h4>
        </Card.Header>
        <DataTable
          noDataComponent={
            <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
          }
          striped
          highlightOnHover
          responsive
          columns={sellerStockColumns}
          data={sellerStocks}
        />
      </Card>
    </>
  );
};

import { SellerApi } from 'api/SellerApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { EditYourSharedStocks } from 'components/seller/settings/stocks/stocksModals/EditYourSharedStocks/EditYourSharedStocks';
import { RemoveSharedStock } from 'components/seller/settings/stocks/stocksModals/RemoveSharedStock';
import { SharedStocksArrivingToggle } from 'components/seller/settings/stocks/stocksModals/SharedStocksArrivingToggle';
import { SharedStocksVisibleToggle } from 'components/seller/settings/stocks/stocksModals/SharedStocksVisibleToggle';
import { useGetAllStocksSharedToPartners } from 'components/seller/settings/stocks/StocksSharedToPartners/hooksStocksSharedToPartners';
import { getAllStocksSharedToPartners } from 'components/seller/settings/stocks/StocksSharedToPartners/utilsStocksSharedToPartners';
import { AnyAdminRole, useRBAC } from 'hooks/useRBAC';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';
import { StocksSharedToPartnersUpdateModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersUpdateModel';
import { useAuthUser } from 'react-auth-kit';
import Card from 'react-bootstrap/Card';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  stocks: StocksSharedToPartnersModel[];
  setStocks: (values: StocksSharedToPartnersModel[]) => void;
};

export const StocksSharedToPartners = ({
  stocks,
  setStocks,
}: PropTypes): JSX.Element => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const hasRightsToEdit = useRBAC(AnyAdminRole);

  useGetAllStocksSharedToPartners(currentUser, setStocks);

  const { t } = useTranslation('components');

  const handleSubmit = async (
    values: StocksSharedToPartnersUpdateModel,
    stock: StocksSharedToPartnersModel
  ): Promise<void> => {
    toast.dismiss();
    await SellerApi.updateStockSharedToPartner(
      currentUser.companyId,
      values,
      stock.id
    );
    toast.info(t('stockSettings.inf_StockSaved'));
  };

  const handleVisibleOrArrivingChange = async (
    checked: boolean,
    stock: StocksSharedToPartnersModel,
    field: 'isActive' | 'shareArriving'
  ) => {
    const updatedStock: StocksSharedToPartnersUpdateModel = {
      isActive:
        (field === 'shareArriving' && checked) || field === 'isActive'
          ? checked
          : stock.isActive,
      shareArriving:
        (field === 'isActive' && !checked) || field === 'shareArriving'
          ? checked
          : stock.shareArriving,
      priceformulaCode: stock.priceformulaCode,
    };

    await handleSubmit(updatedStock, stock);
    await getAllStocksSharedToPartners(currentUser, setStocks);
  };

  const columns = [
    {
      name: t('stockSettings.lbl_StockID'),
      selector: (row: StocksSharedToPartnersModel) => row.stockCode,
      sortable: true,
      maxWidth: '150px',
      minWidth: '150px',
    },
    {
      name: t('stockSettings.lbl_Partner'),
      selector: (row: StocksSharedToPartnersModel) => row.partnerCompanyName,
      sortable: true,
      maxWidth: '150px',
      minWidth: '150px',
    },
    {
      name: t('stockSettings.lbl_StockName'),
      selector: (row: StocksSharedToPartnersModel) => row.stockName,
      sortable: true,
      maxWidth: '150px',
      minWidth: '150px',
    },
    {
      name: t('stockSettings.lbl_PriceListName'),
      selector: (row: StocksSharedToPartnersModel) => row.priceformulaName,
    },
    {
      name: t('stockSettings.lbl_Shared'),
      cell: (row: StocksSharedToPartnersModel) => (
        <SharedStocksVisibleToggle
          handleVisibleOrArrivingChange={handleVisibleOrArrivingChange}
          row={row}
          hasRightsToEdit={hasRightsToEdit}
        />
      ),
      sortable: true,
      center: true,
      // maxWidth: '100px',
      // minWidth: '100px',
    },
    {
      name: t('stockSettings.lbl_ShareArriving'),
      cell: (row: StocksSharedToPartnersModel) => (
        <SharedStocksArrivingToggle
          handleVisibleOrArrivingChange={handleVisibleOrArrivingChange}
          row={row}
          hasRightsToEdit={hasRightsToEdit}
        />
      ),
      sortable: true,
      center: true,
      // maxWidth: '130px',
      // minWidth: '130px',
    },
    {
      name: '',
      cell: (row: StocksSharedToPartnersModel) => (
        <EditYourSharedStocks
          setStocks={setStocks}
          currentUser={currentUser}
          isDisabled={!hasRightsToEdit}
          stock={row}
          t={t}
        />
      ),
      center: true,
      maxWidth: '40px',
      minWidth: '40px',
    },

    {
      name: '',
      cell: (row: StocksSharedToPartnersModel) => (
        <RemoveSharedStock
          setStocks={setStocks}
          currentUser={currentUser}
          isDisabled={!hasRightsToEdit}
          stock={row}
        />
      ),
      center: true,
      maxWidth: '40px',
      minWidth: '40px',
    },
  ];

  return (
    <Card className='p-3'>
      <Card.Header>
        <h4>{t('stockSettings.hd_YourSharedStocks')}</h4>
      </Card.Header>
      <Card.Body>
        <DataTable
          noDataComponent={
            <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
          }
          striped
          highlightOnHover
          responsive
          columns={columns}
          data={stocks}
        />
      </Card.Body>
    </Card>
  );
};

export default StocksSharedToPartners;

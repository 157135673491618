import { SellerApi } from 'api/SellerApi';
import { useFormik } from 'formik';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksMyModel } from 'models/shared/stocksMy/StocksMyModel';
import { StocksMyUpdateModel } from 'models/shared/stocksMy/StocksMyUpdateModel';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { getAllMyStocks } from '../../StocksMy/utilsStocksMy';

/* Formik init */
export const useFormikHookForStocksMy = (
  currentUser: LoginResponseUserModel,
  setStocks: (values: StocksMyModel[]) => void,
  setShow: (values: boolean) => void,
  t: any
): any => {
  const handleSubmit = async (values: StocksMyUpdateModel): Promise<void> => {
    toast.dismiss();
    await SellerApi.createMyStock(currentUser.companyId, values);
    toast.info(t('stockSettings.inf_StockSaved'));
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    addressLine1: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    addressLine2: yup.string().max(50, t('common.err_ValueMax50')),

    postalCode: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    city: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    state: yup.string().nullable().max(50, t('common.err_ValueMax50')),

    countryCode: yup.string(),
  });

  const initialValues: StocksMyModel = {
    id: 0,
    name: '',
    code: '',
    fullAddress: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
    state: '',
    country: {
      code: 'AD',
      shortName: 'Andorra',
    },
    isVisible: true,
    showArriving: false,
    additionalInfo: '',
  };

  const formik = useFormik<StocksMyModel>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedAddress: StocksMyUpdateModel = {
        code: values.code,
        name: values.name,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        postalCode: values.postalCode,
        city: values.city,
        state: values.state,
        countryCode: values.country.code,
        isVisible: values.isVisible,
        showArriving: values.showArriving,
        additionalInfo: values.additionalInfo,
      };
      await handleSubmit(updatedAddress);
      await getAllMyStocks(currentUser, setStocks);
      setShow(false);
      formik.resetForm();
      formik.setSubmitting(false);
    },
  });

  return formik;
};

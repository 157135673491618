import { CompanyApi } from 'api/CompanyApi';
import {
  DealActive,
  DealFinshed,
  DealLost,
  InquiryDeclined,
  InquiryDisabled,
  InquiryNoReplyBuyer,
  InquiryOfferSent,
  InquirySending,
  OfferExpired,
  OfferNoReplyBuyer,
  OfferOrderMade,
  OrderCancelled,
  OrderConfirmed,
  OrderDisabled,
  OrderDispatched,
  OrderSending,
  OrderUnconfirmed,
} from 'assets/icons/StatusIcons';
import { BuyerDealsInvoiceCard } from 'components/buyer/dealshome/BuyerDealsInvoiceCard';
import { BuyerDealsTagInput } from 'components/buyer/dealshome/BuyerDealsTagInput';
import { DealAttachement } from 'components/shared/deals/DealAttachement';
import { DealsNameTooltip } from 'components/shared/deals/DealsNameTooltip/DealsNameTooltip';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import {
  AllProjectsNewDataModel,
  DealGroupModel,
} from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useRef } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { ArrowCounterclockwise, BriefcaseFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const dealStatusMap: {
  [Draft: string]: JSX.Element;
  Lost: JSX.Element;
  Active: JSX.Element;
  Finished: JSX.Element;
} = {
  Draft: <BriefcaseFill size={15} className='icon-ez-gray mx-1' />,
  Lost: <DealLost size={15} className='icon-ez-black mx-1' />,
  Active: <DealActive size={15} className='icon-ez-green mx-1' />,
  Finished: <DealFinshed size={15} className='icon-ez-gray mx-1' />,
};

const inquiryStatusMap: {
  [Unsent: string]: JSX.Element;
  NoReply: JSX.Element;
  OfferMade: JSX.Element;
  Sending: JSX.Element;
  Declined: JSX.Element;
  Expired: JSX.Element;
} = {
  Unsent: <InquiryDisabled size={15} className='icon-ez-gray mx-1' />,
  NoReply: <InquiryNoReplyBuyer size={15} className='icon-ez-yellow mx-1' />,
  OfferMade: <InquiryOfferSent size={15} className='icon-ez mx-1' />,
  Sending: <InquirySending size={15} className='icon-ez-gray mx-1' />,
  Declined: <InquiryDeclined className='icon-ez-red mx-1' />,
  Expired: <OfferExpired size={15} className='icon-ez-red mx-1' />,
};

const offerStatusMap: {
  [Expired: string]: JSX.Element;
  NoReply: JSX.Element;
  OrderMade: JSX.Element;
} = {
  Expired: <OfferExpired size={15} className='icon-ez-red mx-1' />,
  NoReply: <OfferNoReplyBuyer size={15} className='icon-ez-yellow mx-1' />,
  OrderMade: <OfferOrderMade size={15} className='icon-ez mx-1' />,
};

const orderStatusMap: {
  [Unsent: string]: JSX.Element;
  Unconfirmed: JSX.Element;
  Confirmed: JSX.Element;
  Dispatched: JSX.Element;
  Cancelled: JSX.Element;
} = {
  Unsent: <OrderDisabled size={15} className='icon-ez-gray mx-1' />,
  Unconfirmed: <OrderUnconfirmed size={15} className='icon-ez-yellow mx-1' />,
  Confirmed: <OrderConfirmed size={15} className='icon-ez-green mx-1' />,
  Dispatched: <OrderDispatched size={15} className='icon-ez mx-1' />,
  Sending: <OrderSending size={15} className='icon-ez-gray mx-1' />,
  Cancelled: <OrderCancelled className='icon-ez-red mx-1' />,
};

type PropTypes = {
  dealsDetail: DealGroupModel;
  index: number;
  currentUser: LoginResponseUserModel;
  project: AllProjectsNewDataModel;
};

export const BuyerDealsCard = ({
  dealsDetail,
  index,
  project,
  currentUser,
}: PropTypes) => {
  const { t } = useTranslation('components');

  const canSeePrices = useUserCanSeePrices();
  const history = useHistory();

  const { appState } = useContext(AppContext);

  const inputFile = useRef<HTMLInputElement>(null);

  const handleOpenFilePicker = () => {
    inputFile.current !== null && inputFile.current.click();
  };

  const handleDeleteAttachment = async (
    id: number,
    dealId: number,
    type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice'
  ) => {
    let timeout: any = 0;

    toast.success(t('projectSettings.inf_DeletingFile'), {
      className: 'delete-attachment-toast',
      autoClose: 5000,
      pauseOnHover: true,
      closeButton: (
        <Button
          className='btn-undo-delete-attachment'
          onClick={() => clearTimeout(timeout)}
        >
          <ArrowCounterclockwise size={20} />
        </Button>
      ),
    });

    await CompanyApi.deleteDocumentAttachment(
      currentUser.companyId,
      project.id,
      id,
      dealId,
      type
    );

    await appState.getProjects();
  };

  const handleAddNewFile =
    (type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice', dealId: number) =>
    async (file: File) => {
      const reader = new FileReader();
      const formData = new FormData();

      formData.append('file', file);
      formData.append('dealId', dealId.toString());
      formData.append('documentType', type);

      const input: HTMLInputElement | null =
        document.querySelector('input[type=file]');

      if (input !== null) {
        input.value = '';
      }

      reader.readAsDataURL(file);
      reader.onload = async function () {
        reader.result !== undefined &&
          (await CompanyApi.addNewDocumentAttachment(
            currentUser.companyId,
            project.id,
            formData
          ));

        await appState.getProjects();
        // await getProjectInquiry(
        //   projectId,
        //   projectDetails.id,
        //   currentUser.companyId,
        //   setProjectDetails
        // );
      };
    };

  return (
    <Row className='px-3 mb-2 justify-content-between project-view-deal'>
      {/* Inquiries */}

      <Col>
        {dealsDetail.deals !== null &&
        dealsDetail.deals.filter((deal) => deal.inquiry !== null).length !==
          0 ? (
          <Card
            style={{ height: '100%' }}
            className='card-deals-secondary bg-light border m-0 mb-2 p-1'
          >
            <Card.Body className='bg-light p-1'>
              <Row className='mb-2'>
                <Col md={7}>
                  <BuyerDealsTagInput
                    currentUser={currentUser}
                    dealsDetail={dealsDetail}
                    type={'Inquiry'}
                    t={t}
                  />
                </Col>
                <Col className='text-end'>{t('documents.hd_Inquiry')}</Col>
              </Row>
              {dealsDetail.deals.map((deal) => (
                <Row>
                  <Col className='wrap-ellipsis' md={6}>
                    {deal.sellerCompanyShortName ?? deal.sellerCompanyName}
                  </Col>
                  <Col className='text-end wrap-ellipsis'>
                    <Link
                      className='btn-link-bold wrap-ellipsis'
                      to={`/buyer/inquiry/${deal.inquiry?.id}`}
                    >
                      {deal.inquiry?.code ??
                        t(`documents.lbl_${deal.inquiry?.status}`)}
                    </Link>
                  </Col>
                </Row>
              ))}
            </Card.Body>
            <Card.Footer className='p-1 m-0 mt-2 bg-light'>
              <Row className='align-items-end'>
                <Col md={5}>
                  {/* {t('documents.lbl_SentOn')}{' '} */}
                  {dayjs(dealsDetail.deals[0]?.dealDate).format('DD-MM-YYYY')}
                </Col>

                <Col className='d-flex align-items-end justify-content-end'>
                  <DealAttachement
                    dealsDetail={dealsDetail}
                    dealId={dealsDetail.deals[0].id}
                    type={'Inquiry'}
                    project={project}
                    listOfAttachments={dealsDetail.inquiryGroupAttachments}
                  />{' '}
                  <DealsNameTooltip
                    owners={[dealsDetail.inquiryGroupOwner]}
                    dealId={dealsDetail.id}
                  />
                </Col>
              </Row>

              <Row className='mt-3 justify-content-center'>
                <Link
                  className=' btn-link-bold text-center'
                  to={`/buyer/offer-comparison/${dealsDetail.id}`}
                >
                  {t('documents.lbl_ManageInquiries')}
                </Link>
              </Row>
            </Card.Footer>
          </Card>
        ) : (
          <div
            className='d-flex align-items-center mx-0 px-0 justify-content-center'
            style={{
              minHeight: '100%',
              paddingBottom: '0.5rem',
              marginTop: index === 0 ? '-2px' : '4px',
            }}
          >
            {/* {(dealsDetail.order !== null ||
            (dealsDetail.invoices !== null &&
              dealsDetail.invoices.length > 0)) && (
            <>
              <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
              <ChevronRight className='icon-ez-light-gray' />
              <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
            </>
          )} */}
          </div>
        )}
      </Col>

      {/* <Col
        className='d-flex align-items-center mx-0 px-0'
        style={{ maxWidth: '24px' }}
      >
        {(dealsDetail.offer !== null ||
          dealsDetail.order !== null ||
          (dealsDetail.invoices !== null &&
            dealsDetail.invoices.length > 0)) && (
          <hr className='icon-ez-light-gray' style={{ width: '24px' }} />
        )}
      </Col> */}

      {/* Offers */}

      <Col>
        {dealsDetail.deals !== null &&
        dealsDetail.deals.filter((deal) => deal.offer !== null).length !== 0 ? (
          <Card
            style={{ height: '100%' }}
            className='card-deals-secondary bg-light border m-0 p-1'
          >
            <Card.Body className='bg-light p-1'>
              <Row className='mb-2'>
                <Col md={7}>
                  <BuyerDealsTagInput
                    currentUser={currentUser}
                    dealsDetail={dealsDetail}
                    type={'Offer'}
                    t={t}
                  />
                </Col>
                <Col className='text-end'>{t('documents.hd_Offer')}</Col>
              </Row>
              {dealsDetail.deals.map((deal) =>
                deal.offer ? (
                  <Row>
                    <Col className='wrap-ellipsis' md={6}>
                      {deal.sellerCompanyShortName ?? deal.sellerCompanyName}
                    </Col>
                    <Col
                      className='text-end d-flex flex-nowrap justify-content-end'
                      md={6}
                    >
                      {deal.attachments.filter(
                        (attachment) => attachment.documentType === 'Offer'
                      ).length > 0 && (
                        <DealAttachement
                          listOfAttachments={deal.attachments.filter(
                            (attachment) => attachment.documentType === 'Offer'
                          )}
                          dealsDetail={dealsDetail}
                          dealId={deal.id}
                          type={'Offer'}
                          project={project}
                        />
                      )}
                      <Link
                        className='btn-link-bold wrap-ellipsis'
                        to={`/buyer/offer/${deal.offer?.id}`}
                      >
                        {deal.offer?.code ??
                          t(`documents.lbl_${deal.offer?.status}`)}
                      </Link>{' '}
                    </Col>
                  </Row>
                ) : (
                  <br />
                )
              )}
            </Card.Body>
            <Card.Footer className='p-1 m-0 mt-2 bg-light text-center'>
              {dealsDetail.deals.filter((deal) => deal.offer !== null).length >
                0 &&
                dealsDetail.deals.filter((deal) => deal.inquiry !== null)
                  .length !== 0 && (
                  <Link
                    className=' btn-link-bold'
                    to={`/buyer/offer-comparison/${dealsDetail.id}`}
                  >
                    {t('documents.lbl_CompareOffers')}
                  </Link>
                )}
            </Card.Footer>
          </Card>
        ) : (
          <div
            className='d-flex align-items-center mx-0 px-0 justify-content-center'
            style={{
              minHeight: '100%',
              paddingBottom: '0.5rem',
              marginTop: index === 0 ? '-2px' : '4px',
            }}
          >
            {/* {(dealsDetail.order !== null ||
              (dealsDetail.invoices !== null &&
                dealsDetail.invoices.length > 0)) && (
              <>
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
                <ChevronRight className='icon-ez-light-gray' />
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
              </>
            )} */}
          </div>
        )}
      </Col>

      {/* <Col
        className='d-flex align-items-center mx-0 px-0'
        style={{ maxWidth: '24px' }}
      >
        {(dealsDetail.order !== null ||
          (dealsDetail.invoices !== null &&
            dealsDetail.invoices.length > 0)) && (
          <hr className='icon-ez-light-gray' style={{ width: '24px' }} />
        )}
      </Col> */}

      {/* Orders */}

      <Col>
        {dealsDetail.deals !== null &&
        dealsDetail.deals?.length !== 0 &&
        dealsDetail.deals.findIndex((deal) => deal.order !== null) !== -1 ? (
          <Card
            style={{ height: '100%' }}
            className='card-deals-secondary bg-light border m-0 p-1'
          >
            <Card.Body className='bg-light p-1'>
              <Row className='mb-2'>
                <Col md={7}>
                  <BuyerDealsTagInput
                    currentUser={currentUser}
                    dealsDetail={dealsDetail}
                    type={'Order'}
                    t={t}
                  />
                </Col>
                <Col className='text-end'>{t('documents.hd_Order')}</Col>
              </Row>
              {dealsDetail.deals.map((deal) =>
                deal.order ? (
                  <Row>
                    <Col className='wrap-ellipsis' md={6}>
                      {deal.sellerCompanyShortName ?? deal.sellerCompanyName}
                    </Col>
                    <Col className='text-end d-flex flex-nowrap' md={6}>
                      <DealAttachement
                        listOfAttachments={deal.attachments.filter(
                          (attachment) => attachment.documentType === 'Order'
                        )}
                        dealsDetail={dealsDetail}
                        dealId={deal.id}
                        type={'Order'}
                        project={project}
                      />
                      <Link
                        className='btn-link-bold wrap-ellipsis'
                        to={`/buyer/order/${deal.order?.id}`}
                      >
                        {deal.order?.code ??
                          t(`documents.lbl_${deal.order?.status}`)}
                      </Link>
                    </Col>
                  </Row>
                ) : (
                  <br />
                )
              )}
            </Card.Body>
            <Card.Footer className='p-1 m-0 mt-2 bg-light'>
              <Row className='align-items-end'>
                <Col md={7}>
                  {t('documents.lbl_SentOn')}{' '}
                  {dayjs(dealsDetail.deals[0]?.dealDate).format('DD-MM-YYYY')}
                </Col>
                <Col>
                  <DealsNameTooltip
                    owners={[dealsDetail.orderGroupOwner]}
                    dealId={dealsDetail.id}
                  />
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        ) : (
          <div
            className='d-flex align-items-center mx-0 px-0 justify-content-center'
            style={{
              minHeight: '100%',
              paddingBottom: '0.5rem',
              marginTop: index === 0 ? '-2px' : '4px',
            }}
          >
            {/* {(dealsDetail.order !== null ||
              (dealsDetail.invoices !== null &&
                dealsDetail.invoices.length > 0)) && (
              <>
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
                <ChevronRight className='icon-ez-light-gray' />
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
              </>
            )} */}
          </div>
        )}
      </Col>

      {/* <Col
        className='d-flex align-items-center mx-0 px-0'
        style={{ maxWidth: '24px' }}
      >
        {dealsDetail.invoices !== null && dealsDetail.invoices.length > 0 && (
          <hr className='icon-ez-light-gray' style={{ width: '24px' }} />
        )}
      </Col> */}

      {/* Invoices */}

      <Col>
        {dealsDetail.deals !== null &&
        dealsDetail.deals[0]?.invoices.length !== 0 ? (
          dealsDetail.deals.map((deal, index) =>
            deal.invoices.map((invoice) => (
              <BuyerDealsInvoiceCard
                sellerName={
                  deal.sellerCompanyShortName ?? deal.sellerCompanyName
                }
                invoice={invoice}
                currentUser={currentUser}
                dealsDetail={dealsDetail}
              />
            ))
          )
        ) : (
          <div
            className='d-flex align-items-center mx-0 px-0 justify-content-center'
            style={{
              minHeight: '100%',
              paddingBottom: '0.5rem',
              marginTop: index === 0 ? '-2px' : '4px',
            }}
          ></div>
        )}
      </Col>
    </Row>
  );
};

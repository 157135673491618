import { LogoLoader } from 'components/shared/Loaders/LogoLoader';

export const FullScreenLoader = (): JSX.Element => {
  return (
    <div className='full-screen-loader'>
      {/* <Spinner animation='border' /> */}
      <LogoLoader />
    </div>
  );
};

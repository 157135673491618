import { ApplicationSettingsApi } from 'api/ApplicationSettingsApi';
import { ManufacturerImagesModel } from 'models/buyer/common/ManufacturerImages';
import { useEffect } from 'react';

export const useGetAmpwiseManufacturers = (
  setAmpwiseManufacturers: (values: ManufacturerImagesModel[]) => void
) => {
  useEffect(() => {
    const getAmpwiseManufacturers = async () => {
      const localizations =
        await ApplicationSettingsApi.getAmpwiseManufacturerImages();
      setAmpwiseManufacturers(localizations);
    };
    getAmpwiseManufacturers();
  }, [setAmpwiseManufacturers]);
};

import { useFormikHookForBuyerRegistrationUserDetails } from 'components/shared/onboarding/BuyerRegistration/BuyerRegistrationContactDetails/hooksBuyerRegistrationContactDetails';
import {
  BuyerRegistrationRequestModel,
  BuyerRegistrationUpdateContactDetailsModel,
} from 'models/buyer/buyerRegistration/BuyerRegistrationModels';
import { ChangeEvent, MouseEventHandler, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { PlayFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import '../buyerRegistrationIntro.css';

type PropTypes = {
  nextStep: () => void;
  prevStep: MouseEventHandler;
  buyerRegistrationData: BuyerRegistrationRequestModel | null;
  setBuyerRegistrationData: (value: BuyerRegistrationRequestModel) => void;
  setProfileImageFile: (value: File | null) => void;
};

export const BuyerRegistrationContactDetails = ({
  nextStep,
  prevStep,
  buyerRegistrationData,
  setBuyerRegistrationData,
  setProfileImageFile,
}: PropTypes): JSX.Element => {
  const [contactDetails, setContactDetails] =
    useState<BuyerRegistrationUpdateContactDetailsModel>(
      {} as BuyerRegistrationUpdateContactDetailsModel
    );

  const [profileImage, setProfileImage] = useState<string | null>(null);

  const { t } = useTranslation('components');

  const formik = useFormikHookForBuyerRegistrationUserDetails(
    contactDetails,
    setContactDetails,
    nextStep,
    buyerRegistrationData,
    setBuyerRegistrationData
  );

  useEffect(() => {
    buyerRegistrationData?.buyerUserRegistrationModel !== undefined &&
      setContactDetails(buyerRegistrationData?.buyerUserRegistrationModel);
  }, [buyerRegistrationData?.buyerUserRegistrationModel]);

  const handleProfileImageUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    toast.dismiss();

    if (event.target.files) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        if (selectedFile.size > 500 * 1024) {
          toast.error(t('account.err_ProfileImageSizeTooBig'));
          event.target.value = '';
          return;
        }
        const srcUrl = URL.createObjectURL(selectedFile);
        // const data = new FormData();
        // data.append('file', selectedFile);
        // const imageLocation = await IdentityApi.updateCustomerLogo(
        //   invitationToken,
        //   data
        // );

        setProfileImage(srcUrl);
        setProfileImageFile(selectedFile);

        // toast.info(t('account.inf_ProfileImageUploaded'));
      }
    }
  };

  return (
    <Card className='onboarding-intro-card'>
      <Card.Body>
        <h4 className='mb-3'>{t('userSettings.lbl_Header')}</h4>
        <Form>
          <Row>
            {/* <Col lg={2} className='text-lg-center text-sm-center'>
              <div className='mt-3'>
                <BuyerRegistrationProfileImageUpload
                  profileImage={profileImage}
                  setProfileImage={setProfileImage}
                  initials={`${
                    formik.values.firstName !== null &&
                    formik.values.firstName !== undefined
                      ? formik.values.firstName.charAt(0).toUpperCase()
                      : ''
                  }${
                    formik.values.lastName !== null &&
                    formik.values.lastName !== undefined
                      ? formik.values.lastName.charAt(0).toUpperCase()
                      : ''
                  }`}
                  handleImageUpload={handleProfileImageUpload}
                />
              </div>
            </Col> */}
            <Col className='col col-lg-10'>
              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='formFirstName'>
                    <Form.Label className='required'>
                      {t('userSettings.lbl_FirstName')}
                    </Form.Label>
                    <Form.Control
                      value={formik.values.firstName || ''}
                      type='input'
                      name='firstName'
                      placeholder={t('userSettings.plh_FirstName')}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.firstName && !!formik.errors.firstName
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className='mb-3' controlId='formUsername'>
                    <Form.Label className='required'>
                      {t('userSettings.lbl_Email')}
                    </Form.Label>
                    <Form.Control
                      value={formik.values.username}
                      type='email'
                      name='username'
                      placeholder={t('userSettings.plh_Email')}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.username && !!formik.errors.username
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.username}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='formLinkedInProfile'>
                    <Form.Label>{t('userSettings.lbl_LinkedIn')}</Form.Label>
                    <Form.Control
                      type='input'
                      name='linkedInProfile'
                      value={formik.values.linkedInProfile || ''}
                      placeholder={t('userSettings.plh_LinkedIn')}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.linkedInProfile &&
                        !!formik.errors.linkedInProfile
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.linkedInProfile}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3' controlId='formLastName'>
                    <Form.Label className='required'>
                      {t('userSettings.lbl_LastName')}
                    </Form.Label>
                    <Form.Control
                      name='lastName'
                      value={formik.values.lastName || ''}
                      type='input'
                      placeholder={t('userSettings.plh_LastName')}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.lastName && !!formik.errors.lastName
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='formPosition'>
                    <Form.Label>{t('userSettings.lbl_Position')}</Form.Label>
                    <Form.Control
                      name='position'
                      value={formik.values.position || ''}
                      type='input'
                      placeholder={t('userSettings.plh_Position')}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.position && !!formik.errors.position
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.position}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='formPhoneNumber'>
                    <Form.Label>{t('userSettings.lbl_Phone')}</Form.Label>
                    <Form.Control
                      name='phone'
                      value={formik.values.phone || ''}
                      type='input'
                      placeholder={t('userSettings.plh_Phone')}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.phone && !!formik.errors.phone}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <Button className='btn btn-eleczap float-start ms-3' onClick={prevStep}>
          <PlayFill className='iconPlayBack' />
          {t('common.btn_Back')}
        </Button>
        <Button
          className='btn btn-eleczap float-end mt-3'
          onClick={formik.handleSubmit}
        >
          {t('common.btn_Next')}{' '}
          {formik.isSubmitting ? (
            <Spinner animation='border' size='sm' />
          ) : (
            <PlayFill />
          )}
        </Button>
      </Card.Body>
    </Card>
  );
};

import { ChangePasswordModel } from 'models/shared/user/ChangePasswordModel';
import { CurrentProjectUpdateModel } from 'models/shared/user/CurrentProjectUpdateModel';
import { DealsByMeUpdateModel } from 'models/shared/user/DealsByMeUpdateModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { UserUpdateModel } from 'models/shared/user/UserUpdateModel';
import { ApiClient } from './ApiClient';

export const Api = {
  /*  Users */
  getUser: (
    companyId: number,
    id: number,
    sellerCompanyId?: number | null
  ): Promise<UserModelById> =>
    ApiClient.get(
      `/companies/${companyId}/users/${id}?sellerCompanyId=${
        sellerCompanyId !== null && sellerCompanyId !== undefined
          ? sellerCompanyId
          : ''
      }`
    ),

  /** Get user by passing the passing the JWT token as method parameter
   * Normally the Autorization header with current JWT token is added
   * automatically by interceptor.
   */
  getUserWithAuthHeader: (
    companyId: number,
    id: number,
    accessToken: string
  ): Promise<UserModelById> =>
    ApiClient.getWithAuthHeader(
      `/companies/${companyId}/users/${id}`,
      accessToken
    ),

  updateUser: (
    companyId: number,
    id: number,
    user: UserUpdateModel
  ): Promise<void> =>
    ApiClient.put(`/companies/${companyId}/users/${id}`, user),

  getProfileImage: (companyId: number, userId: number): Promise<string> =>
    ApiClient.get(`/companies/${companyId}/users/${userId}/profileimages`),

  uploadProfileImage: (
    companyId: number,
    userId: number,
    file: FormData
  ): Promise<string> =>
    ApiClient.postCreate(
      `/companies/${companyId}/users/${userId}/profileimages`,
      file
    ),

  deleteProfileImage: (companyId: number, userId: number): Promise<string> =>
    ApiClient.delete(`/companies/${companyId}/users/${userId}/profileimages`),

  changePassword: (
    companyId: number,
    id: number,
    passwordInfo: ChangePasswordModel
  ): Promise<void> =>
    ApiClient.put(
      `/companies/${companyId}/users/${id}/changepassword`,
      passwordInfo
    ),

  setCurrentProject: (
    companyId: number,
    id: number | null,
    currentProject: CurrentProjectUpdateModel
  ): Promise<void> =>
    ApiClient.put(
      `/companies/${companyId}/users/${id}/setcurrentproject`,
      currentProject
    ),

  updateDealsByMe: (
    companyId: number,
    id: number,
    dealsByMe: DealsByMeUpdateModel
  ): Promise<void> =>
    ApiClient.put(
      `/companies/${companyId}/users/${id}/ChangeDealsByMe`,
      dealsByMe
    ),
};

import {
  getAllCustomers,
  getAllCustomersUsers,
  getAllMarketplaceEndSuppliersAndContacts,
} from 'components/seller/customers/utilsCustomer';
import { SellersCompaniesAndContactsModel } from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { BuyersCompaniesAndUsersModel } from 'models/seller/customers/BuyersCompaniesAndUsersModel';
import { BuyersUsersModel } from 'models/seller/customers/BuyersUsersModel';
import { useEffect } from 'react';

export const useGetAllCustomers = (
  currentUser: LoginResponseUserModel,
  setCustomers: (values: BuyersCompaniesAndUsersModel) => void
) => {
  useEffect(() => {
    getAllCustomers(currentUser, setCustomers);
  }, [currentUser, setCustomers]);
};

export const useGetAllCustomersUsers = (
  currentUser: LoginResponseUserModel,
  setCustomersUsers: (values: BuyersUsersModel) => void
) => {
  useEffect(() => {
    getAllCustomersUsers(currentUser, setCustomersUsers);
  }, [currentUser, setCustomersUsers]);
};

export const useGetAllMarketplaceEndSuppliersAndContacts = (
  currentUser: LoginResponseUserModel,
  setSuppliersUsers: (values: SellersCompaniesAndContactsModel) => void,
  page?: number | undefined,
  searchString?: string | undefined,
  rowsPerPage?: number | undefined,
  sortColumn?: string | undefined
) => {
  useEffect(() => {
    getAllMarketplaceEndSuppliersAndContacts(
      currentUser,
      setSuppliersUsers,
      page,
      searchString,
      rowsPerPage,
      sortColumn
    );
  }, [currentUser, setSuppliersUsers]);
};

import { useAuthUser } from 'react-auth-kit';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';

/**
 * Seller role types
 */
export type SellerRole = 'SellerAdministrator' | 'SellerRegularUser';

/**
 * Buyer role types
 */
export type BuyerRole = 'BuyerAdministrator' | 'BuyerRegularUser';

/**
 * Admin role types
 */
export type AdminRole = 'SellerAdministrator' | 'BuyerAdministrator';

/**
 * All seller role names
 */
export const AnySellerRole: SellerRole[] = [
  'SellerAdministrator',
  'SellerRegularUser',
];

/**
 * All buyer role names
 */
export const AnyBuyerRole: BuyerRole[] = [
  'BuyerAdministrator',
  'BuyerRegularUser',
];

/**
 * All buyer role names
 */
export const AnyAdminRole: AdminRole[] = [
  'SellerAdministrator',
  'BuyerAdministrator',
];

/**
 * Role Based Acccess Control - validates if the currently logged in user has any of the required roles
 * @param requiredRoles list of role names to check against
 * @returns true if user has any of the required roles
 */
export const useRBAC = (
  requiredRoles: SellerRole[] | BuyerRole[] | AdminRole[]
): boolean => {
  const authUser = useAuthUser();
  const user = authUser() as LoginResponseUserModel;

  let isMatch: boolean = false;
  if (user) {
    for (let role of requiredRoles) {
      isMatch = user.roleNames.indexOf(role) !== -1;
      if (isMatch) {
        break;
      }
    }
  }

  return isMatch;
};

import { ApplicationSettingsApi } from 'api/ApplicationSettingsApi';
import { CompanyApi } from 'api/CompanyApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { UserSearch } from 'components/shared/settings/Users/UserSearch';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import dayjs from 'dayjs';
import { useGetUserActiveRole } from 'hooks/useGetUserActiveRole';
import { AnyAdminRole, useRBAC } from 'hooks/useRBAC';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { CompanyModel } from 'models/shared/company/CompanyModel';
import { UserModel } from 'models/shared/user/UserModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { UserUpdateModel } from 'models/shared/user/UserUpdateModel';
import { UserUpdatePermissionsModel } from 'models/shared/user/UserUpdatePermissionsModel';
import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserDeleteButton } from '../UserDeleteButton';
import { UserNew } from '../UserNew';
import { UserRoleSwitch } from '../UserRoleSwitch';
import { UserStates } from '../UserStates';
import { useGetAllUsers, useGetDefaultViewRoles } from './hooksUsers';
import {
  deleteUser,
  getAllUsers,
  handleUpdateUser,
  handleUpdateUserPermissions,
} from './utilsUsers';

type PropTypes = {
  viewType: 'Seller' | 'Buyer';
  company: CompanyModel;
};

export const Users = ({ viewType, company }: PropTypes): JSX.Element => {
  const [users, setUsers] = useState<UserModel>({} as UserModel);
  const [onlyActiveUsers, setOnlyActiveUsers] = useState<UserModel>(
    {} as UserModel
  );
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [hideDeactivated, setHideDeactivated] = useState<boolean>(false);
  const [rolesNames, setRoleNames] = useState<string[]>([]);
  const [changedView, setChangedView] = useState<string>('');
  const hasRightsToEdit = useRBAC(AnyAdminRole);
  const getUserActiveRole = useGetUserActiveRole();
  const [localizations, setLocalizations] = useState<LocalizationModel[]>([]);
  const [defaultLocalizationId, setDefaultLocalizationId] = useState<number>(1);
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');

  const dateFormat = 'YYYY-MM-DD, HH:mm';

  const userType: 'buyer' | 'seller' =
    viewType === 'Buyer' ? 'buyer' : 'seller';

  useGetAllUsers(currentUser, setUsers);

  useEffect(() => {
    const getLocalizations = async () => {
      const localizations = await ApplicationSettingsApi.getLocalizations();
      setLocalizations(localizations);
    };
    getLocalizations();
  }, []);

  useEffect(() => {
    const defaultLocale = localizations.find(
      (l) => l.twoLetterISORegionName === company.country?.code
    );
    setDefaultLocalizationId(defaultLocale?.id || 1);
  }, [localizations, company.country?.code]);

  const isSameUser = (user: UserModelById): boolean => {
    return user.id === currentUser.id;
  };

  const canChangePermissions = (user: UserModelById) => {
    return hasRightsToEdit;
  };

  useGetDefaultViewRoles(setChangedView, setRoleNames);

  const handleStatusChange = async (
    value: boolean,
    user: UserModelById,
    field: string
  ) => {
    toast.dismiss();

    const activeRoleName = getUserActiveRole(user, viewType);

    const userUpdate: UserUpdateModel = {
      firstName: user.firstName,
      lastName: user.lastName,
      position: user.position,
      linkedInProfile: user.linkedInProfile,
      localizationId: user.localization.id,
      phone: user.phone,
      defaultView: user.defaultView,
      canPlaceOrders: field === 'canPlaceOrders' ? value : user.canPlaceOrders,
      canSeePrices: field === 'canSeePrices' ? value : user.canSeePrices,
      isActive: field === 'status' ? value : user.isActive,
      roleName: activeRoleName,
      cancelInvitation: user.isInvitationCompleted,
      email: user.email,
    };

    await handleUpdateUser(currentUser, user, userUpdate);
    await getAllUsers(
      currentUser,
      setUsers,
      pageNumber,
      query,
      rowsPerPage,
      sortColumn
    );

    SuccessToast(t('account.inf_UserSettingsSaved'));
  };

  const handlePermissionsChange = async (
    value: boolean,
    user: UserModelById,
    field: 'canPlaceOrders' | 'canSeePrices' | 'isSeller' | 'isBuyer'
  ) => {
    toast.dismiss();

    const isAdmin = user.isBuyerAdministrator || user.isSellerAdministrator;

    const userUpdate: UserUpdatePermissionsModel = {
      canPlaceOrders: field === 'canPlaceOrders' ? value : user.canPlaceOrders,
      canSeePrices: field === 'canSeePrices' ? value : user.canSeePrices,
      isSellerAdministrator:
        field === 'isSeller' && isAdmin ? value : user.isSellerAdministrator,
      isBuyerAdministrator:
        field === 'isBuyer' && isAdmin ? value : user.isBuyerAdministrator,
      isSellerRegularUser:
        field === 'isSeller' && !isAdmin ? value : user.isSellerRegularUser,
      isBuyerRegularUser:
        field === 'isBuyer' && !isAdmin ? value : user.isBuyerRegularUser,
    };

    await handleUpdateUserPermissions(currentUser, user, userUpdate);
    await getAllUsers(
      currentUser,
      setUsers,
      pageNumber,
      query,
      rowsPerPage,
      sortColumn
    );

    SuccessToast(t('account.inf_UserSettingsSaved'));
  };

  const handlePageChange = async (page: number) => {
    setPageNumber(page);
    await getAllUsers(
      currentUser,
      setUsers,
      page,
      query,
      rowsPerPage,
      sortColumn
    );
  };

  const handleRowsPerChange = async (rowsAmount: number) => {
    setRowsPerPage(rowsAmount);
    await getAllUsers(
      currentUser,
      setUsers,
      pageNumber,
      query,
      rowsAmount,
      sortColumn
    );
  };
  const handleOnSort = async (selectedColumn: any, sortDirection: any) => {
    const sortingColumn = `${selectedColumn.sortField}_${sortDirection}`;
    setSortColumn(sortingColumn);
    await getAllUsers(
      currentUser,
      setUsers,
      pageNumber,
      query,
      rowsPerPage,
      sortingColumn
    );
  };

  const handleHideDeactivated = () => {
    setHideDeactivated(!hideDeactivated);
  };

  const handleDeleteUser = async (id: number) => {
    await deleteUser(currentUser.companyId, id);
    await getAllUsers(
      currentUser,
      setUsers,
      pageNumber,
      query,
      rowsPerPage,
      sortColumn
    );
    SuccessToast(t('usersSettings.inf_InvitationCancelled'));
  };

  /* After new fetch of users, set only active users as well */
  useEffect(() => {
    if (users.data !== undefined) {
      let clonedDataSource = { ...users };
      let deactivatedUsers = 0;

      let activeOnlyUsers = clonedDataSource.data.filter((user) => {
        if (!user.isActive) deactivatedUsers += 1;
        return user.isActive;
      });

      clonedDataSource.totalItems -= deactivatedUsers;
      clonedDataSource.data = activeOnlyUsers;

      setOnlyActiveUsers(clonedDataSource);
    }
  }, [users]);

  const handleReinviteUser = async (row: UserModelById) => {
    const invitation = {
      username: row.email,
      activationUrlTemplate: `${
        window && window.location.origin
      }/user-onboarding?invitationToken={invitationToken}`,
    };

    await CompanyApi.resendUserInvitation(currentUser.companyId, invitation);

    SuccessToast(t('userSettings.inf_InvitationSent'));
  };

  const columns = [
    {
      name: '',
      cell: (row: UserModelById) => (
        <AvatarWithFallback
          imageUrl={row.profileImage}
          initials={row.initials}
          size={'medium'}
        />
      ),
      maxWidth: '65px',
      minWidth: '65px',
    },
    {
      name: t('usersSettings.lbl_Name'),
      selector: (row: UserModelById) => row.fullName,
      cell: (row: UserModelById) => (
        <div>
          {row.fullName}
          <br />
          <div className='text-muted' style={{ fontSize: 11 }}>
            {row.username}
          </div>
        </div>
      ),
      sortField: 'name',
      sortable: true,
    },

    {
      name: t('usersSettings.lbl_LastLogin'),
      selector: (row: UserModelById) => row.lastLogin.toString(),
      // If status is pending show resend invitation btn.
      cell: (row: UserModelById) =>
        row.isInvitationCompleted ? (
          row.lastLogin !== null ? (
            dayjs(row.lastLogin).format(dateFormat)
          ) : (
            '-'
          )
        ) : (
          <Button
            onClick={() => handleReinviteUser(row)}
            variant='btn btn-eleczap'
            disabled={!hasRightsToEdit}
          >
            {t('usersSettings.btn_ResendUserInvite')}
          </Button>
        ),
      sortable: true,
      sortField: 'lastlogin',
      maxWidth: '140px',
      minWidth: '140px',
    },
    {
      name: t('usersSettings.lbl_Role'),
      selector: (row: UserModelById) => row.roleNames.toString(),
      cell: (row: UserModelById) => (
        <UserRoleSwitch
          currentUser={currentUser}
          hasRightsToEdit={hasRightsToEdit}
          isSameUser={isSameUser(row)}
          getAllUsers={() =>
            getAllUsers(
              currentUser,
              setUsers,
              pageNumber,
              query,
              rowsPerPage,
              sortColumn
            )
          }
          user={row}
        />
      ),
      left: true,
      maxWidth: '170px',
      minWidth: '170px',
    },
    company.isBuyer && company.isSeller
      ? {
          name: t('userSettings.lbl_Buyer'),
          selector: (row: UserModelById) => row.isBuyerAdministrator,
          cell: (row: UserModelById) => (
            <Form.Check
              onChange={(event) =>
                handlePermissionsChange(event.target.checked, row, 'isBuyer')
              }
              type='switch'
              checked={row.isBuyerAdministrator || row.isBuyerRegularUser}
              disabled={!canChangePermissions(row) || isSameUser(row)}
            />
          ),

          center: true,
          maxWidth: '80px',
          minWidth: '80px',
        }
      : { maxWidth: '0px', minWidth: '0px' },
    company.isBuyer && company.isSeller
      ? {
          name: t('userSettings.lbl_Seller'),
          selector: (row: UserModelById) => row.isSellerAdministrator,
          cell: (row: UserModelById) => (
            <Form.Check
              onChange={(event) =>
                handlePermissionsChange(event.target.checked, row, 'isSeller')
              }
              type='switch'
              checked={row.isSellerAdministrator || row.isSellerRegularUser}
              disabled={!canChangePermissions(row) || isSameUser(row)}
            />
          ),

          center: true,
          maxWidth: '80px',
          minWidth: '80px',
        }
      : { maxWidth: '0px', minWidth: '0px' },
    viewType === 'Buyer'
      ? {
          name: t('usersSettings.lbl_Ordering'),
          selector: (row: UserModelById) => row.canPlaceOrders,
          cell: (row: UserModelById) => (
            <Form.Check
              onChange={(event) =>
                handlePermissionsChange(
                  event.target.checked,
                  row,
                  'canPlaceOrders'
                )
              }
              type='switch'
              checked={row.canPlaceOrders}
              disabled={!canChangePermissions(row)}
            />
          ),
          sortable: true,
          sortField: 'canorder',
          right: true,
          maxWidth: '100px',
          minWidth: '100px',
        }
      : { maxWidth: '0px', minWidth: '0px' },
    viewType === 'Buyer'
      ? {
          name: t('usersSettings.lbl_Prices'),
          selector: (row: UserModelById) => row.canSeePrices,
          cell: (row: UserModelById) => (
            <Form.Check
              onChange={(event) =>
                handlePermissionsChange(
                  event.target.checked,
                  row,
                  'canSeePrices'
                )
              }
              type='switch'
              checked={row.canSeePrices}
              disabled={!canChangePermissions(row)}
            />
          ),
          sortable: true,
          sortField: 'canseeprices',
          right: true,
          maxWidth: '80px',
          minWidth: '80px',
        }
      : { maxWidth: '0px', minWidth: '0px' },
    {
      name: t('usersSettings.lbl_Status'),
      selector: (row: UserModelById) => row.isActive,
      cell: (row: UserModelById) =>
        row.isInvitationCompleted ? (
          <UserStates
            currentUser={currentUser}
            user={row}
            isDisabled={!hasRightsToEdit || isSameUser(row)}
            handleStatusChange={handleStatusChange}
            t={t}
          />
        ) : (
          <UserDeleteButton
            user={row}
            hasRightsToEdit={hasRightsToEdit}
            handleDeleteUser={handleDeleteUser}
          />
        ),
      center: true,
      maxWidth: '96px',
      minWidth: '96px',
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: t('common.lbl_RowsPerPage'),
    rangeSeparatorText: t('common.lbl_Of'),
  };

  return (
    <Card className='p-4'>
      <Card.Header>
        <h4>{t('usersSettings.hd_Users')}</h4>
        <Row>
          <Col>
            <div className='input-group'>
              <UserNew
                currentUser={currentUser}
                viewType={viewType}
                localizations={localizations}
                defaultLocalizationId={defaultLocalizationId}
              />
              <UserSearch
                currentUser={currentUser}
                setUsers={setUsers}
                setResetPaginationToggle={setResetPaginationToggle}
                setQuery={setQuery}
                t={t}
                viewType={viewType}
              />
            </div>
          </Col>
          <Col>
            <Button
              onClick={handleHideDeactivated}
              variant='btn btn-eleczap float-end'
            >
              {!hideDeactivated
                ? t('usersSettings.btn_HideDeactivated')
                : t('usersSettings.btn_ShowDeactivated')}
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <DataTable
          noDataComponent={
            <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
          }
          striped
          highlightOnHover
          responsive
          columns={columns}
          data={hideDeactivated ? onlyActiveUsers.data : users.data}
          pagination
          paginationServer
          paginationComponentOptions={paginationComponentOptions}
          paginationTotalRows={
            hideDeactivated ? onlyActiveUsers.totalItems : users.totalItems
          }
          onChangeRowsPerPage={handleRowsPerChange}
          onChangePage={handlePageChange}
          paginationResetDefaultPage={resetPaginationToggle}
          sortServer
          onSort={handleOnSort}
          className='rdt_TableHead'
        />
      </Card.Body>
    </Card>
  );
};

import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { MouseEvent } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export const ProjectsPageGuide = () => {
  const { t } = useTranslation(['components', 'introGuides']);

  const handleStartTour = (event: MouseEvent) => {
    introJs()
      .setOptions({
        scrollToElement: false,
        nextLabel: t('common.btn_Next', { ns: 'components' }),
        prevLabel: t('common.btn_Back', { ns: 'components' }),
        steps: [
          {
            element: '.project-view-card:first-of-type',
            title: t('projectsPageGuide.hd_Project', {
              ns: 'introGuides',
            }),
            intro: t('projectsPageGuide.inf_Project', {
              ns: 'introGuides',
            }),
          },
          {
            element: '#manage-project',
            title: t('projectsPageGuide.hd_ManageProject', {
              ns: 'introGuides',
            }),
            intro: t('projectsPageGuide.inf_ManageProject', {
              ns: 'introGuides',
            }),
          },
          {
            element: '.project-view-deal.row',
            title: t('projectsPageGuide.hd_Deal', {
              ns: 'introGuides',
            }),
            intro: t('projectsPageGuide.inf_Deal', {
              ns: 'introGuides',
            }),
          },
          {
            element: '.project-view-deal.row',
            title: t('projectsPageGuide.hd_Documents', {
              ns: 'introGuides',
            }),
            intro: t('projectsPageGuide.inf_Documents', {
              ns: 'introGuides',
            }),
          },
          {
            element: '.projects-filters',
            title: t('projectsPageGuide.hd_Filters', {
              ns: 'introGuides',
            }),
            intro: t('projectsPageGuide.inf_Filters', {
              ns: 'introGuides',
            }),
          },
          {
            element: '.tags-toggle:first-of-type',
            title: t('projectsPageGuide.hd_Tags', {
              ns: 'introGuides',
            }),
            intro: t('projectsPageGuide.inf_Tags', {
              ns: 'introGuides',
            }),
          },
          {
            element: '.attachments:first-of-type',
            title: t('projectsPageGuide.hd_Attachments', {
              ns: 'introGuides',
            }),
            intro: t('projectsPageGuide.inf_Attachments', {
              ns: 'introGuides',
            }),
          },
        ],
      })
      .start();
  };

  return (
    <div>
      <QuestionCircle
        onClick={handleStartTour}
        size={40}
        className='btn-floating-help me-3'
      />
    </div>
  );
};

import { IdentityApi } from 'api/IdentityApi';
import { useFormik } from 'formik';
import {
  BuyerRegistrationPasswordModel,
  BuyerRegistrationRequestModel,
} from 'models/buyer/buyerRegistration/BuyerRegistrationModels';
import { UserOnboardingPasswordModel } from 'models/shared/userOnboarding/UserOnboardingPasswordModel';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* Formik init */
export const useFormikHookForBuyerRegistrationPassword = (
  buyerRegistrationData: BuyerRegistrationRequestModel | null,
  setBuyerRegistrationData: (value: BuyerRegistrationRequestModel) => void,
  logo: File | null,
  profileImage: File | null
): any => {
  const { t } = useTranslation('components');

  const navigate = useHistory();

  const buyerRegistrationStorageKey = '_eleczapBuyerRegistration';

  const handleSubmit = async (
    values: UserOnboardingPasswordModel
  ): Promise<void> => {
    toast.dismiss();

    if (buyerRegistrationData !== null) {
      setBuyerRegistrationData({
        ...buyerRegistrationData,
        passwordCreateModel: values,
        registrationUrlTemplate: `${
          window && window.location.origin
        }/buyer-registration-confirmation?registrationToken={registrationToken}`,
      });

      const createdBuyerResponse = await IdentityApi.createBuyerRegistration({
        ...buyerRegistrationData,
        passwordCreateModel: values,
        registrationUrlTemplate: `${
          window && window.location.origin
        }/buyer-registration-confirmation?registrationToken={registrationToken}`,
      });

      navigate.push('/buyer-registration-email-sent');
      localStorage.removeItem(buyerRegistrationStorageKey);
    }
  };

  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .required(t('changePassword.err_PasswordRequired'))
      .max(50, t('common.err_ValueMax50')),

    confirmNewPassword: yup
      .string()
      .required(t('changePassword.err_ConfirmPasswordRequired'))
      .max(50, t('common.err_ValueMax50')),
  });

  const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
  };

  const formik = useFormik<BuyerRegistrationPasswordModel>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedPassword: BuyerRegistrationPasswordModel = {
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
      };
      await handleSubmit(updatedPassword);
      // window.location.replace(window && window.location.origin);
      // navigate.push('/buyer-registration-email-sent');
      formik.setSubmitting(false);
    },
  });

  return formik;
};

import { useInfiniteQuery } from '@tanstack/react-query';
import { BuyerApi } from 'api/BuyerApi';
import { BuyerProductListSimple } from 'components/buyer/products/Product/BuyerProductListSimple';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { useMergeProductsAndPricesSearchSimple } from 'hooks/useMergeProductsAndPrices';
import { usePrevious } from 'hooks/usePrevious';
import {
  BuyerGroupedProductListPagedResult,
  GroupedProductModel,
} from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductModel } from 'models/shared/product/ProductModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Spinner } from 'react-bootstrap';
import { CaretDownFill, Grid3x3GapFill, List } from 'react-bootstrap-icons';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { getUrlParams } from 'utils/getUrlParams';

export const SearchSimple = () => {
  const { t } = useTranslation('components');

  const [search, setSearch] = useState<string>('');

  const [pages, setPages] = useState<BuyerGroupedProductListPagedResult[]>([]);

  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(false);

  const [moreDealsLoading, setMoreDealsLoading] = useState<boolean>(false);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [totalProducts, setTotalProducts] = useState<number>(0);

  const [mounted, setMounted] = useState<boolean>(false);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<string>('');

  const [selectedManufacturers, setSelectedManufacturers] = useState<string[]>(
    []
  );

  const pageSize = 20;

  const prevValue = usePrevious(orderBy);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState, appContextDispatch } = useContext(AppContext);

  const searchString = getUrlParams('search') || '';
  const byCode = getUrlParams('ByCode') ?? false;

  useEffect(() => {
    setSearch(searchString);
  }, [searchString]);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const handleSearchByCode = useCallback(
    async ({ pageParam = 1 }) => {
      const productsResponse = await BuyerApi.getQuickProductsGroupedPaginated(
        currentUser.companyId,
        searchString,
        prevValue !== orderBy ? 1 : pageParam,
        orderBy,
        userLocaleId,
        selectedManufacturers
      );
      return productsResponse;
    },
    [
      currentUser.companyId,
      orderBy,
      prevValue,
      searchString,
      selectedManufacturers,
      userLocaleId,
    ]
  );

  /* TODO: Dennis - display for all sellers and not just first */
  const findProducts = useCallback(
    async ({ pageParam = 1 }) => {
      setPageNumber(pageParam);
      let sellerIds =
        appState.buyerCurrentSuppliers === null
          ? null
          : appState.buyerCurrentSuppliers
              .filter(
                (supplier) => supplier.isSelected && !supplier.isMarketplace
              )
              .map((filteredSupplier) => filteredSupplier.value);

      appState.buyerCurrentSuppliers
        ?.filter((supplier) => supplier.isSelected && supplier.isMarketplace)
        .forEach((filteredSubSeller) =>
          filteredSubSeller.subSellers?.forEach((subSeller) =>
            sellerIds?.push(subSeller.value)
          )
        );

      const productsResponse = byCode
        ? await BuyerApi.findProductsGroupedByCode(
            currentUser.companyId,
            sellerIds,
            searchString,
            prevValue !== orderBy ? 1 : pageParam,
            orderBy,
            pageSize,
            userLocaleId,
            false,
            selectedManufacturers
          )
        : await BuyerApi.findProductsGrouped(
            currentUser.companyId,
            sellerIds,
            searchString,
            prevValue !== orderBy ? 1 : pageParam,
            orderBy,
            pageSize,
            userLocaleId,
            false,
            selectedManufacturers
          );
      setTotalPages(productsResponse.totalPages);
      setTotalProducts(productsResponse.totalItems);

      setMoreDealsLoading(false);
      setIsInitialLoading(false);

      return productsResponse;
    },
    [
      appState.buyerCurrentSuppliers,
      byCode,
      currentUser.companyId,
      orderBy,
      prevValue,
      searchString,
      selectedManufacturers,
      userLocaleId,
    ]
  );

  const productsQuery = useInfiniteQuery(
    [
      `search_products_${searchString}`,
      currentUser.companyId,
      userLocaleId,
      orderBy,
      appState.buyerCurrentSuppliers,
      selectedManufacturers,
    ],
    handleSearchByCode,
    {
      enabled: !!currentUser.companyId,
      staleTime: 100 * 60 * 30,
      getNextPageParam: (lastPage, pages) => lastPage.currentPage + 1,
      onSuccess(data) {
        const clonedPages: BuyerGroupedProductListPagedResult[] = [];

        data.pages.forEach((page) => {
          const clonedPageData: GroupedProductModel[] = [];

          page.data.forEach((product) => {
            const newProducts: ProductModel[] = [];
            product.products.forEach(
              (item) =>
                (appState.buyerCurrentSuppliers?.findIndex(
                  (supplier) =>
                    supplier.value === item.sellerId &&
                    supplier.isSelected &&
                    !supplier.isMarketplace
                ) !== -1 ||
                  appState.buyerCurrentSuppliers?.findIndex((supplier) =>
                    supplier.subSellers?.some(
                      (subSeller) =>
                        subSeller.value === item.sellerId &&
                        subSeller.isSelected
                    )
                  ) !== -1) &&
                newProducts.push({
                  id: item.id,
                  code: item.code,
                  name: item.name,
                  unit: '',
                  orderStep: 0,
                  manufacturerName: item.manufacturerName ?? '',
                  image: '',
                  isFavorite: false,
                  productClasses: [],
                  moqPrices: item.moqPrices ?? [],
                  stockLevels: [],
                  packageSize: 0,
                  sellerCompanyName: item.sellerName,
                  sellerCompanyId: item.sellerId,
                  sellerCompanyShortName: item.sellerShortName,
                })
            );

            const productGeneralInfo: GroupedProductModel = {
              productCode: product.productCode,
              products: newProducts,
            };

            clonedPageData.push(productGeneralInfo);
          });

          clonedPages.push({
            currentPage: 1,
            totalItems: 20,
            totalPages: 1,
            data: clonedPageData,
            parents: [],
          });
        });

        setPages(clonedPages);
      },
    }
  );

  const pricesQuery = useInfiniteQuery(
    [
      `search_prices_${searchString}`,
      currentUser.companyId,
      userLocaleId,
      orderBy,
      appState.buyerCurrentSuppliers,
      selectedManufacturers,
    ],
    findProducts,
    {
      enabled: !!currentUser.companyId,
      staleTime: 100 * 60 * 30,
      getNextPageParam: (lastPage, pages) => lastPage.currentPage + 1,
      onSuccess(data) {
        const clonedPages: BuyerGroupedProductListPagedResult[] = [...pages];

        data.pages.forEach((page) => {
          const pageData = page.data;

          pageData.forEach((fetchedProduct) => {
            const matchedProduct = clonedPages[0].data.find(
              (product) => product.productCode === fetchedProduct.productCode
            );

            if (matchedProduct) {
              matchedProduct.products = fetchedProduct.products;
              matchedProduct.quantity = fetchedProduct.quantity;
            }
          });
        });

        setPages(clonedPages);
      },
    }
  );

  useMergeProductsAndPricesSearchSimple(productsQuery, pricesQuery, setPages);

  const loadMoreProducts = () => {
    setMoreDealsLoading(true);
    if (pageNumber < totalPages) {
      setPageNumber((prevState: number) => prevState + 1);
    }
    pricesQuery.fetchNextPage();
    productsQuery.fetchNextPage();
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  const searchSummary = `${t('common.lbl_YouSearchedFor')} ${searchString}: ${
    productsQuery.data?.pages[0].data.length
  } ${t('common.lbl_Products')}`;

  const BuyerBreadcrumbNode = (
    <div className='d-flex justify-content-between align-items-center'>
      <h5 className='mt-2 ms-2 p-2 mb-0 pb-0 product-search-sticky-header'>
        {searchSummary}
      </h5>

      <div className='d-flex me-3 align-items-center'>
        <Grid3x3GapFill
          onClick={() => {
            appContextDispatch({
              type: 'SET_PRODUCTS_VIEW',
              productsView: 'GRID',
            });
          }}
          size={20}
          className={
            appState.productsView === 'GRID'
              ? 'icon-ez cursor-pointer'
              : 'icon-ez-gray cursor-pointer'
          }
        />{' '}
        <List
          onClick={() => {
            appContextDispatch({
              type: 'SET_PRODUCTS_VIEW',
              productsView: 'LIST',
            });
          }}
          size={24}
          className={
            appState.productsView === 'LIST'
              ? 'icon-ez cursor-pointer'
              : 'icon-ez-gray cursor-pointer'
          }
        />
      </div>
    </div>
  );

  return isInitialLoading ? (
    <FullScreenLoader />
  ) : (
    <>
      <>
        {mounted &&
          ReactDOM.createPortal(
            BuyerBreadcrumbNode,
            document &&
              document.getElementsByClassName('fixed-portal-receiver')[0]
          )}

        <BuyerProductListSimple
          isLoading={productsQuery.isLoading}
          isPricesLoading={pricesQuery.isLoading}
          // getProducts={findProducts}
          setPageNumber={setPageNumber}
          setOrderBy={setOrderBy}
          selectedManufacturers={selectedManufacturers}
          setSelectedManufacturers={setSelectedManufacturers}
          items={pages}
          productDetailRoute={'/buyer/product-details'}
        />
      </>

      <div className='d-flex justify-content-center mb-7'>
        {productsQuery.data &&
        (pageNumber < productsQuery.data?.pages[0].totalPages ||
          moreDealsLoading) ? (
          <Button
            disabled={moreDealsLoading}
            onClick={loadMoreProducts}
            className='btn btn-eleczap'
          >
            {t('productSearch.btn_LoadMoreProducts')}{' '}
            {moreDealsLoading ? (
              <Spinner animation='border' size='sm' />
            ) : (
              <CaretDownFill />
            )}
          </Button>
        ) : (
          !isInitialLoading && (
            <span>{t('productSearch.inf_AllProductsLoaded')}</span>
          )
        )}
      </div>
    </>
  );
};

import { SellerApi } from 'api/SellerApi';
import { getAllStocksSharedToPartners } from 'components/seller/settings/stocks/StocksSharedToPartners/utilsStocksSharedToPartners';
import { useFormik } from 'formik';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksMyModel } from 'models/shared/stocksMy/StocksMyModel';
import { StocksMyUpdateModel } from 'models/shared/stocksMy/StocksMyUpdateModel';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { getAllMyStocks } from '../../StocksMy/utilsStocksMy';

/* Formik init */
export const useFormikHookForStocksMyUpdate = (
  currentUser: LoginResponseUserModel,
  setStocks: (values: StocksMyModel[]) => void,
  setShow: (values: boolean) => void,
  stock: StocksMyModel,
  t: any,
  setStocksSharedToPartners: (values: StocksSharedToPartnersModel[]) => void
): any => {
  const handleSubmit = async (values: StocksMyUpdateModel): Promise<void> => {
    toast.dismiss();
    await SellerApi.updateMyStock(currentUser.companyId, values, stock.id);
    toast.success(t('stockSettings.inf_StockSaved'));
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    addressLine1: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    addressLine2: yup.string().nullable().max(50, t('common.err_ValueMax50')),

    postalCode: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    city: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    state: yup.string().nullable().max(50, t('common.err_ValueMax50')),

    countryCode: yup.string(),
  });

  const formik = useFormik<StocksMyModel>({
    initialValues: { ...stock },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedStock: StocksMyUpdateModel = {
        code: values?.code,
        name: values.name,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        postalCode: values.postalCode,
        city: values.city,
        state: values.state,
        countryCode: values.country?.code,
        isVisible: values.isVisible,
        showArriving: values.showArriving,
        additionalInfo: values.additionalInfo,
      };
      await handleSubmit(updatedStock);
      await Promise.all([
        getAllMyStocks(currentUser, setStocks),
        getAllStocksSharedToPartners(currentUser, setStocksSharedToPartners),
      ]);

      setShow(false);
      formik.resetForm();
      formik.setSubmitting(false);
    },
  });

  return formik;
};

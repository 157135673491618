import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

type DataRow = {
  email: string;
  isSeller: boolean;
  isBuyer: boolean;
};

type PropTypes = {
  setEmailsMap: (value: DataRow[]) => void;
  emailsMap: DataRow[];
};

export const UserNewSetRole = ({
  setEmailsMap,
  emailsMap,
}: PropTypes): JSX.Element => {
  // const [emailsMap, setEmailsMap] = useState<DataRow[]>([]);

  const { t } = useTranslation('components');

  const handleRoleChange = (
    row: DataRow,
    checked: boolean,
    column: 'buyer' | 'seller'
  ) => {
    let clonedData = [...emailsMap];

    const targetIndex = clonedData.findIndex(
      (email) => email.email === row.email
    );

    if (targetIndex === null || targetIndex === undefined) return;

    if (column === 'buyer') clonedData[targetIndex].isBuyer = checked;
    if (column === 'seller') clonedData[targetIndex].isSeller = checked;

    setEmailsMap(clonedData);
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: t('userSettings.lbl_Email'),
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: t('userSettings.lbl_Seller'),
      // selector: (row) => row.roleName,
      cell: (row) => (
        <>
          <Form.Check
            type='checkbox'
            aria-label='seller'
            checked={row.isSeller}
            onChange={(event) =>
              handleRoleChange(row, event.target.checked, 'seller')
            }
          />
        </>
      ),
      maxWidth: '100px',
      minWidth: '100px',
      center: true,
    },
    {
      name: t('userSettings.lbl_Buyer'),
      // selector: (row) => row.roleName,
      cell: (row) => (
        <>
          <Form.Check
            type='checkbox'
            aria-label='buyer'
            checked={row.isBuyer}
            onChange={(event) =>
              handleRoleChange(row, event.target.checked, 'buyer')
            }
          />
        </>
      ),
      maxWidth: '120px',
      minWidth: '120px',
      center: true,
    },
  ];

  return (
    <Modal.Body className='p-4'>
      You entered {emailsMap.length} e-mail addresses, please confirm the roles!
      <br />
      <br />
      <DataTable
        striped
        highlightOnHover
        responsive
        columns={columns}
        data={emailsMap}
      />
    </Modal.Body>
  );
};

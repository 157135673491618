import { BuyerApi } from 'api/BuyerApi';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';
import { BuyerProductSearchByCode } from 'layout/BuyerProductSearchByCode';
import { ContinueProjectInquiryButton } from 'layout/ContinueProjectInquiryButton';
import { ContinueProjectOrderButton } from 'layout/ContinueProjectOrderButton';
import { CreateNewProjectInquiryButton } from 'layout/CreateNewProjectInquiryButton';
import { CreateNewProjectOrderButton } from 'layout/CreateNewProjectOrderButton';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, Nav, Navbar, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../contexts/user/AppContext';
import { BuyerCurrentProjectButton } from './BuyerCurrentProjectButton';
import { BuyerProductSearch } from './BuyerProductSearch';
import { BuyerSettingsDropdownButton } from './BuyerSettingsDropdownButton';
import './buyerHeader.css';

export const BuyerHeader = () => {
  const [width, setWidth] = useState<number>(window && window.innerWidth);
  const { appState, appContextDispatch } = useContext(AppContext);
  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const pageNumber = 1;
  const orderBy = ''; // No ordering in 1st version
  const maxResults = 5;
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const handleSearch = async (
    searchString: string,
    sellerIds: number[] | null
  ) => {
    // if (appState.buyerProductsMainSearchSource) {
    //   appState.buyerProductsMainSearchSource.cancel(
    //     'Operation canceled due to new request.'
    //   );
    // }

    const source = axios.CancelToken.source();

    appContextDispatch({
      type: 'BUYER_SET_NEW_SOURCE_SEARCH_PRODUCTS',
      buyerProductsMainSearchSource: source,
    });

    const productsResponse = await BuyerApi.findProductsInstant(
      currentUser.companyId,
      sellerIds,
      searchString,

      userLocaleId
    );
    return productsResponse;
  };

  const handleSearchByCode = async (
    searchString: string,
    sellerIds: number[] | null
  ) => {
    const productsResponse = await BuyerApi.getQuickProductsGrouped(
      currentUser.companyId,
      searchString,
      userLocaleId
    );
    return productsResponse.data;
  };

  const handleSearchDebounced = AwesomeDebouncePromise(handleSearch, 800);
  const handleSearchDebouncedByCode = AwesomeDebouncePromise(
    handleSearchByCode,
    800
  );

  const navigate = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Navbar className='sticky-top navbar-light navbar-buyer navbar-bg d-print-none py-0 m-2 mt-0 rounded'>
      <div className='container-xxl buyer-header-container-xxl align-items-center'>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <>
            <Nav className='align-items-center nav-container'>
              <Row className='align-items-center nav-container me-2'>
                <Col
                  md={1}
                  className='d-flex align-items-start justify-content-start nav-container'
                >
                  <div className='me-2'>
                    <BuyerProductSearchByCode
                      handleSearch={handleSearchDebouncedByCode}
                      searchRoute='/buyer/search-simple'
                      detailsRoute='/buyer/product-details'
                    />
                  </div>
                  <div>
                    <BuyerProductSearch
                      handleSearch={handleSearchDebounced}
                      searchRoute='/buyer/search'
                      detailsRoute='/buyer/product-details'
                    />
                  </div>
                </Col>

                <Col className='ms-4 d-flex align-items-end nav-container buyer-current-project-order-inquiry justify-content-end'>
                  {/* <CreateNewProjectButton /> */}
                  <BuyerCurrentProjectButton
                    currentProject={
                      appState.userProfile?.currentProject || null
                    }
                  />

                  {appState.userProfile?.currentProject?.inquiryProjectId ? (
                    <ContinueProjectInquiryButton />
                  ) : (
                    <CreateNewProjectInquiryButton />
                  )}

                  {appState.userProfile?.currentProject?.orderProjectId ? (
                    <ContinueProjectOrderButton />
                  ) : (
                    <CreateNewProjectOrderButton />
                  )}
                </Col>
              </Row>
            </Nav>
            <div className='float-end'>
              <BuyerSettingsDropdownButton
                initials={appState.userProfile?.initials}
                profileImage={appState.userProfile?.profileImage}
              />
            </div>
          </>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

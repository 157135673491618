import { BuyerApi } from 'api/BuyerApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LightTooltip } from 'components/shared/LightTooltip';
import { AppContext } from 'contexts/user/AppContext';
import { useSetCurrentProject } from 'hooks/useSetCurrentProject';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  projectId: number;
  currentName: string;
  currentUser: LoginResponseUserModel;
};

export const EditDealNameModal = ({
  projectId,
  currentName,
  currentUser,
}: PropTypes) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState<string>(currentName);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');

  const setUserProfile = useSetUserProfile();

  const setCurrentProject = useSetCurrentProject();

  const handleNameChange = async () => {
    setIsLoading(true);
    await BuyerApi.projects.updateProjectName(
      currentUser.companyId,
      projectId,
      {
        name,
      }
    );

    await appState.getProjects();

    await setCurrentProject(projectId);

    // If project name was changed, reload user profile with updated project name
    // into AppContext so that current project name in header is updated also
    await setUserProfile(null);

    setIsLoading(false);

    handleClose();
  };

  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await handleNameChange();
    }
  };

  return (
    <>
      <PencilSquare
        data-tip
        data-for={`deal-row-edit-name_${projectId}`}
        className='icon-ez cursor-pointer'
        onClick={handleShow}
      />

      <LightTooltip place='bottom' id={`deal-row-edit-name_${projectId}`}>
        <span className='buyer-nav-item-label'>
          {t('deals.hd_EditProjectName')}
        </span>
      </LightTooltip>

      <Modal
        size='sm'
        show={show}
        onHide={handleClose}
        id='buyerUserDeactivate'
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('deals.hd_EditProjectName')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            onKeyUp={handleKeyUp}
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant='secondary'
            onClick={handleClose}
          >
            {t('common.btn_Cancel')}
          </Button>
          <Button
            disabled={isLoading}
            variant='btn btn-eleczap'
            onClick={handleNameChange}
          >
            {t('common.btn_Ok')}{' '}
            {isLoading && <Spinner animation='border' size='sm' />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

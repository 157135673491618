import { BuyerApi } from 'api/BuyerApi';
import {
  InquiryDisabled,
  InquiryNoReplyBuyer,
  InquiryOfferSent,
  InquirySending,
  OfferExpired,
} from 'assets/icons/StatusIcons';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useAddProductsToProjectInquiry } from 'hooks/useAddProductsToProjectInquiry';
import { useAddProductsToProjectOrder } from 'hooks/useAddProductsToProjectOrder';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import {
  InquiryModel,
  InquiryRowsModel,
} from 'models/buyer/documents/InquiryModels';
import {
  ProjectImportProductsErrorModel,
  ProjectImportProductsModel,
} from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { CartPlusFill, Clipboard2PlusFill } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { SiMicrosoftexcel } from 'react-icons/si';
import { Link, useParams } from 'react-router-dom';
import { handleDownloadInquiryExcel } from 'utils/downloadExcelSheet';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { formatMonetaryValueWithoutDecimals } from 'utils/formatMonetaryValueWithoutDecimals';

import { ProjectImportProductsErrorModal } from 'components/buyer/project/ProjectImportProductsErrorModal/ProjectImportProductsErrorModal';
import './Inquiry.css';

type UrlParamsType = {
  inquiryId: string;
};

type PropTypes = {
  passedInquiryId?: number;
};

export const Inquiry = ({ passedInquiryId }: PropTypes) => {
  const [inquiry, setInquiry] = useState<InquiryModel>({} as InquiryModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [errors, setErrors] = useState<ProjectImportProductsErrorModel[]>([]);
  const [onlyQuantitiesErrorExists, setOnlyQuantitiesErrorExists] =
    useState<boolean>(true);
  const [unfoundCodesExists, setUnfoundCodesExists] = useState<boolean>(false);
  const [products, setProducts] = useState<string>('');

  const [importTo, setImportTo] = useState<'Inquiry' | 'Order' | null>(null);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);
  const canSeePrices = useUserCanSeePrices();
  const validateUnitExists = useValidateUnitExists();

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const { t } = useTranslation('components');

  let { inquiryId } = useParams<UrlParamsType>();

  const addProductsToInquiry = useAddProductsToProjectInquiry();
  const addProductsToOrder = useAddProductsToProjectOrder();

  /* This peice of code is ProjectInquiryImportProductsModal.tsx as well. unify it in seperate file */
  const checkIfOnlyErrorsAreInQuantities = useCallback((): void => {
    setOnlyQuantitiesErrorExists(true);
    errors.forEach((error) => {
      if (error.errorCode !== 'QuantityMissing')
        setOnlyQuantitiesErrorExists(false);
    });
  }, [errors]);

  const checkIfUnfoundCodeExists = useCallback((): void => {
    errors.forEach((error) => {
      if (!unfoundCodesExists && error.errorCode === 'ProductNotFound')
        setUnfoundCodesExists(true);
    });
  }, [errors, unfoundCodesExists]);

  useEffect(() => {
    checkIfOnlyErrorsAreInQuantities();
    checkIfUnfoundCodeExists();
  }, [checkIfOnlyErrorsAreInQuantities, checkIfUnfoundCodeExists]);

  const downloadExcel = () => {
    handleDownloadInquiryExcel(
      inquiry.rows,
      inquiry.sentToCompanyName,
      `${inquiry.sentToCompanyName}_INQUIRY_${inquiry.code}`
    );
  };

  useEffect(() => {
    setIsLoading(true);
    const inquiryIdInUse = passedInquiryId ?? parseInt(inquiryId);
    const getInquiry = async (): Promise<void> => {
      const response = await BuyerApi.getInquiry(
        currentUser.companyId,
        inquiryIdInUse,
        userLocaleId
      );

      setInquiry(response);
      setIsLoading(false);
    };
    getInquiry();
  }, [currentUser.companyId, inquiryId, passedInquiryId, userLocaleId]);

  const columns: TableColumn<InquiryRowsModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      sortable: true,
      width: '60px',
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode,
      cell: (row) =>
        row.productId === null ? (
          <span>{row.productCode}</span>
        ) : (
          <Link
            to={`/buyer/product-details/${row.productCode}`}
            className='btn-link-bold'
          >
            {row.productCode}
          </Link>
        ),
      sortable: true,
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name,
      sortable: true,
      grow: 6,
    },
    {
      name: t('documents.lbl_Qty'),
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          <span>
            {row.quantity} <small>{validateUnitExists(row.unit)}</small>
          </span>
        </>
      ),
      sortable: true,
      right: true,
    },
    {
      name: t('documents.lbl_ListPrice'),
      selector: (row) => row.priceWithDiscount ?? 'priceWithDiscount',
      cell: (row) =>
        row.productId === null
          ? '-'
          : canSeePrices(
              formatMonetaryValue(
                inquiry.currencyCode,
                row.priceWithDiscount,
                appState,
                row.decimalPlaces
              )
            ),
      sortable: true,
      right: true,
    },
    {
      name: t('documents.lbl_TargetPrice'),
      selector: (row) =>
        row.targetPrice !== null ? row.targetPrice?.toString() : 'targetPrice',
      cell: (row) =>
        canSeePrices(
          formatMonetaryValueWithoutDecimals(
            inquiry.currencyCode,
            row.targetPrice,
            appState
          )
        ),
      sortable: true,
      center: true,
    },
    {
      name: t('documents.lbl_AllowReplacements'),
      selector: (row) => row.allowReplacement,
      cell: (row) => (
        <Form.Check
          disabled
          type='switch'
          checked={row.allowReplacement}
          aria-label='radio 1'
        />
      ),
      sortable: true,
      center: true,
    },
  ];

  const handleProjectInquireProducts = async () => {
    setIsLoading(true);
    const cleanedProducts: any[] = [];

    inquiry.rows.forEach((row) =>
      cleanedProducts.push(
        row.productId !== null
          ? [row.productCode ?? '*EMPTY_CODE*', row.quantity]
          : [row.productCode ?? '*EMPTY_CODE*', row.quantity, row.name]
      )
    );

    const cleanedProductsText: ProjectImportProductsModel = {
      productsText: cleanedProducts.join('\n'),
      saveUnknownProducts: true,
    };

    setProducts(cleanedProducts.join('\n'));

    setImportTo('Inquiry');

    const response = await addProductsToInquiry('Document', {
      groupedProducts: null,
      importProductsText: cleanedProductsText,
    });

    response !== null && setErrors(response);

    response && response?.length > 0 && setShowErrors(true);

    setIsLoading(false);
  };

  const handleProjectOrderProducts = async () => {
    setIsLoading(true);
    const cleanedProducts: any[] = [];

    inquiry.rows.forEach((row) =>
      cleanedProducts.push(
        row.productId !== null
          ? [row.productCode ?? '*EMPTY_CODE*', row.quantity]
          : [row.productCode ?? '*EMPTY_CODE*', row.quantity, row.name]
      )
    );

    const cleanedProductsText: ProjectImportProductsModel = {
      productsText: cleanedProducts.join('\n'),
      saveUnknownProducts: true,
    };

    setProducts(cleanedProducts.join('\n'));

    setImportTo('Order');

    const response = await addProductsToOrder('Document', {
      groupedProducts: null,
      importProductsText: cleanedProductsText,
    });

    setIsLoading(false);
  };

  const handleImportFromDocument = async (
    cleanedProducts: string,
    importTo: 'Inquiry' | 'Order'
  ) => {
    setIsLoading(true);
    const cleanedProductsText: ProjectImportProductsModel = {
      productsText: cleanedProducts,
      saveUnknownProducts: true,
    };

    if (importTo === 'Inquiry') {
      await addProductsToInquiry('Document', {
        groupedProducts: null,
        importProductsText: cleanedProductsText,
      });
    }

    if (importTo === 'Order') {
      await addProductsToOrder('Document', {
        groupedProducts: null,
        importProductsText: cleanedProductsText,
      });
    }

    setShowErrors(false);
    setIsLoading(false);
  };

  const isToExternalSeller = appState.buyerCurrentSuppliers?.filter(
    (supplier) => supplier.value === inquiry.sellerCompanyId
  )[0]?.isExternalSeller;

  return (
    <>
      {isLoading && <FullScreenLoader />}

      {showErrors && (
        <ProjectImportProductsErrorModal
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          errors={errors}
          handleImportFromDocument={handleImportFromDocument}
          products={products}
          setProducts={setProducts}
          isUnknownProductsActive={true}
          unfoundCodesExists={true}
          onlyQuantitiesErrorExists={onlyQuantitiesErrorExists}
          importTo={importTo}
        />
      )}

      <div className='container-fluid p-2'>
        <Card>
          <Card.Header>
            <Row>
              <Col lg={4}>
                <h3>
                  <Link
                    to={`/buyer/${
                      isToExternalSeller
                        ? 'external-supplier-info'
                        : 'supplier-info'
                    }?id=${inquiry.sellerCompanyId}`}
                    className='btn-link-bold'
                  >
                    {inquiry.sentToCompanyShortName ??
                      inquiry.sentToCompanyName}
                  </Link>
                </h3>
                <h4>{inquiry.sentToContactName}</h4>
              </Col>
              <Col className='text-center' lg={4}>
                <h2>
                  {t('documents.hd_Inquiry')} {inquiry.code}
                </h2>
              </Col>
              <Col
                className='text-center d-flex justify-content-lg-end justify-content-sm-center'
                lg={4}
              >
                <h3>
                  {inquiry.status === 'Sending' && (
                    <>
                      {t('documents.lbl_Sending')}{' '}
                      <InquirySending className='icon-ez-gray' />
                    </>
                  )}
                  {inquiry.status === 'Unsent' && (
                    <>
                      {t('documents.lbl_Unsent')}{' '}
                      <InquiryDisabled className='icon-ez-gray' />
                    </>
                  )}
                  {inquiry.status === 'NoReply' && (
                    <>
                      {t('documents.lbl_NoReply')}{' '}
                      <InquiryNoReplyBuyer className='icon-ez-yellow' />
                    </>
                  )}
                  {inquiry.status === 'OfferMade' && (
                    <>
                      {t('documents.lbl_OfferMade')}{' '}
                      <InquiryOfferSent className='icon-ez' />
                    </>
                  )}

                  {inquiry.status === 'Expired' && (
                    <>
                      {t('documents.lbl_Expired')}{' '}
                      <OfferExpired className='icon-ez-gray' />
                    </>
                  )}
                </h3>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_Date')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(inquiry.documentDate).format('DD.MM.YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentBy')}:
                  </Col>
                  <Col>
                    <strong>{inquiry.sentByContactName}</strong>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row className='flex-nowrap'>
                  <Col className='text-end pe-0 text-nowrap'>
                    <Row className='mb-0'>
                      <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                    </Row>
                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_MyInquiryNumber')}:</Col>
                    </Row>
                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_OfferDeadline')}:</Col>
                    </Row>
                    <Row className='mb-0'>
                      <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                    </Row>
                  </Col>

                  <Col
                    className='pe-3 d-flex flex-column'
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <span className='text-start document-header-right-col'>
                      <strong>{inquiry.projectName}</strong>
                    </span>

                    <span className='text-start document-header-right-col'>
                      {inquiry.customerInquiryCode !== null &&
                      inquiry.customerInquiryCode !== '' ? (
                        <strong>{inquiry.customerInquiryCode}</strong>
                      ) : (
                        <br />
                      )}
                    </span>

                    <span className='text-start document-header-right-col'>
                      {inquiry.offerDeadlineDate !== null ? (
                        <strong>
                          {dayjs(inquiry.offerDeadlineDate).format(
                            'DD.MM.YYYY'
                          )}
                        </strong>
                      ) : (
                        <br />
                      )}
                    </span>

                    <span className='text-start document-header-right-col d-flex flex-column text-nowrap'>
                      <strong>{inquiry.deliveryAddressLine1}</strong>
                      <strong>{inquiry.deliveryAddressLine2}</strong>
                      <strong>{inquiry.deliveryAddressLine3}</strong>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='my-2'>
              <Col></Col>
              <Col>
                <ButtonGroup className='d-flex float-end'>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>{t('documents.btn_ExportToExcel')}</Tooltip>
                    }
                  >
                    <Button
                      type={'button'}
                      onClick={downloadExcel}
                      variant='btn btn-eleczap'
                    >
                      <SiMicrosoftexcel size={20} />
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        {t('documents.btn_AddProductsToProjectInquiry')}
                      </Tooltip>
                    }
                  >
                    <Button
                      disabled={isLoading}
                      onClick={handleProjectInquireProducts}
                      className='btn btn-eleczap d-flex align-items-center  px-1'
                    >
                      <Clipboard2PlusFill size={20} className='me-1' />{' '}
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        {t('documents.btn_AddProductsToProjectOrder')}
                      </Tooltip>
                    }
                  >
                    <Button
                      disabled={isLoading}
                      onClick={handleProjectOrderProducts}
                      className='btn btn-eleczap d-flex align-items-center px-1'
                    >
                      <CartPlusFill size={20} className='me-1' />{' '}
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </Col>
            </Row>

            <Row className='my-5'>
              <DataTable
                noDataComponent={
                  <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
                }
                columns={columns}
                data={inquiry.rows}
              />
            </Row>

            <Row className='justify-content-between'>
              <Col lg={6}>
                <Form.Group className='mb-3' controlId='comments'>
                  <Form.Label>{t('common.lbl_Comments')}</Form.Label>
                  <Form.Control
                    readOnly
                    as='textarea'
                    rows={4}
                    value={inquiry.comment}
                  />
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Row className='mt-3'>
                  <Col>
                    <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                  </Col>
                  <Col>
                    <h3 className='text-end'>
                      {canSeePrices(
                        useFormatMonetaryValue(
                          inquiry.currencyCode,
                          inquiry.total
                        )
                      )}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-end'>
                    {t('common.inf_TotalPriceSubtext')}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

import {
  InvoiceOverdue,
  InvoicePaid,
  InvoiceUnpaidSeller,
} from 'assets/icons/StatusIcons';
import { LightTooltip } from 'components/shared/LightTooltip';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { DealInvoiceModel } from 'models/seller/deals/DealsModels';
import { useContext, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { ClockHistory } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';

const invoiceStatusMap: {
  [Overdue: string]: JSX.Element;
  Unpaid: JSX.Element;
  Paid: JSX.Element;
} = {
  Overdue: <InvoiceOverdue size={15} className='mx-1 icon-ez-red' />,
  Unpaid: <InvoiceUnpaidSeller className='icon-ez-yellow mx-1' />,
  Paid: <InvoicePaid size={15} className='icon-ez mx-1' />,
};

type PropTypes = {
  invoice: DealInvoiceModel;
  singular?: boolean;
};

export const SellerDealsInvoiceCard = ({ invoice, singular }: PropTypes) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { appState } = useContext(AppContext);
  const history = useHistory();

  const { t } = useTranslation('components');

  return !singular ? (
    <Card
      onClick={() => setIsOpen(!isOpen)}
      style={{
        height: isOpen ? '80.4px' : '35px',
        overflow: 'hidden',
      }}
      className='card-deals-secondary card-deals-invoice-card bg-light border m-0 p-1'
    >
      <Card.Header className='bg-light p-1'>
        <Row className='mb-3'>
          <Col>
            <h5 className='link-toDocument'>
              <Link
                to={`/seller/invoice/${invoice.id}`}
                className='link-eleczap'
              >
                {invoice.code}
              </Link>
            </h5>
          </Col>
          <Col>
            <span
              data-tip
              data-for={`invoice-tooltip-${invoice?.id}`}
              className='float-end'
            >
              {invoiceStatusMap[invoice.status]}
            </span>

            <LightTooltip place='left' id={`invoice-tooltip-${invoice?.id}`}>
              <span>
                {invoice.status === 'Overdue' && (
                  <>{t('documents.lbl_Overdue')}</>
                )}
                {invoice.status === 'Unpaid' && (
                  <>{t('documents.lbl_Unpaid')}</>
                )}
                {invoice.status === 'Paid' && <>{t('documents.lbl_Paid')}</>}
              </span>
            </LightTooltip>
          </Col>
        </Row>
        {isOpen && (
          <Row>
            <Col>
              <span data-tip data-for={`sent-on-invoice-tooltip${invoice.id}`}>
                <ClockHistory size={15} className='icon-ez-gray' />{' '}
                {dayjs(invoice.documentDate).format('DD.MM.YYYY')}
              </span>

              <LightTooltip
                place='bottom'
                id={`sent-on-invoice-tooltip${invoice.id}`}
              >
                <span>{t('documents.lbl_InvoiceDue')}</span>
              </LightTooltip>
            </Col>
            <Col className='d-flex justify-content-end align-items-end'>
              <span className='float-end'>
                {formatMonetaryValue(
                  invoice.currencyCode,
                  invoice.total,
                  appState
                )}
              </span>
            </Col>
          </Row>
        )}
      </Card.Header>
    </Card>
  ) : (
    <Card
      onClick={() => history.push(`/seller/invoice/${invoice.id}`)}
      className='card-deals-secondary card-deals-invoice-card bg-light border m-0 p-1 cursor-pointer'
    >
      <Card.Header className='bg-light p-1'>
        <Row className='mb-3'>
          <Col>
            <h5 className='link-toDocument'>
              <Link
                to={`/seller/invoice/${invoice.id}`}
                className='link-eleczap'
              >
                {invoice.code}
              </Link>
            </h5>
          </Col>
          <Col>
            <span
              data-tip
              data-for={`invoice-tooltip-${invoice?.id}`}
              className='float-end'
            >
              {invoiceStatusMap[invoice.status]}
            </span>

            <LightTooltip id={`invoice-tooltip-${invoice?.id}`}>
              <span>
                {invoice.status === 'Overdue' && (
                  <>{t('documents.lbl_Overdue')}</>
                )}
                {invoice.status === 'Unpaid' && (
                  <>{t('documents.lbl_Unpaid')}</>
                )}
                {invoice.status === 'Paid' && <>{t('documents.lbl_Paid')}</>}
              </span>
            </LightTooltip>
          </Col>
        </Row>

        <Row>
          <Col>
            <span data-tip data-for={`sent-on-invoice-tooltip${invoice.id}`}>
              <ClockHistory size={15} className='icon-ez-gray' />{' '}
              {dayjs(invoice.documentDate).format('DD.MM.YYYY')}
            </span>

            <LightTooltip
              place='bottom'
              id={`sent-on-invoice-tooltip${invoice.id}`}
            >
              <span>{t('documents.lbl_InvoiceDue')}</span>
            </LightTooltip>
          </Col>
          <Col className='d-flex justify-content-end align-items-end'>
            <span className='float-end'>
              {formatMonetaryValue(
                invoice.currencyCode,
                invoice.total,
                appState
              )}
            </span>
          </Col>
        </Row>
      </Card.Header>
    </Card>
  );
};

import { SellerApi } from 'api/SellerApi';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksMyModel } from 'models/shared/stocksMy/StocksMyModel';
import { StocksMyUpdateModel } from 'models/shared/stocksMy/StocksMyUpdateModel';
import { toast } from 'react-toastify';

export const getAllMyStocks = async (
  currentUser: LoginResponseUserModel,
  setStocks: (values: StocksMyModel[]) => void
) => {
  const allStocks: StocksMyModel[] = await SellerApi.getAllMyStocks(
    currentUser.companyId
  );

  allStocks.forEach((stock) => {
    stock.fullAddress =
      stock.addressLine1 !== null
        ? `${stock.addressLine1} ${stock.addressLine2 || ''}, ${
            stock.postalCode
          }, ${stock.city}, ${stock.state}, ${stock.country.shortName || '-'}`
        : '';
  });
  setStocks(allStocks);
};

export const handleSubmit = async (
  currentUser: LoginResponseUserModel,
  values: StocksMyUpdateModel,
  stocks: StocksMyModel,
  t: any
): Promise<void> => {
  toast.dismiss();
  await SellerApi.updateMyStock(currentUser.companyId, values, stocks.id);
  toast.info(t('stockSettings.inf_StockSaved'));
};

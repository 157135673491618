import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { ProductDetailsModel } from 'models/shared/product/ProductDetailsModel';
import { useState } from 'react';
import { Card, Col, Collapse, Row } from 'react-bootstrap';
import {
  ArrowDownCircleFill,
  ArrowRightCircleFill,
  StarFill,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { ProductMoqPrice } from './ProductDetailMoq';

type PropsType = {
  products: ProductDetailsModel[];
  isAddToCartVisible?: boolean;
};

export const ProductDetailsPrice = ({
  products,
  isAddToCartVisible = false,
}: PropsType) => {
  const { t } = useTranslation('components');
  const validateUnitExists = useValidateUnitExists();
  const [open, setOpen] = useState(true);

  const renderStockRows = () => {
    /* Get maximum number of stock rows */
    const stockRows = products.map((product) => product.stockLevels.length);
    const maxStockRows = Math.max(...stockRows);

    const renderList = [];
    for (let i = 0; i < maxStockRows; i++) {
      renderList.push(
        <Row className='width-max-content'>
          <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
            {i === 0 ? t('productDetailsPrice.lbl_IndvidualStock') : null}
          </Col>

          {products &&
            products.map((product) => {
              const favStock = product.stockLevels.filter(
                (stock) => stock.isFavoriteStock
              );
              const itemHasFavoriteStock = favStock.length > 0;

              const stock = product.stockLevels[i];

              return (
                <Col className='ms-1 pt-1 pb-1 product-stock-level-column multiple-seller-card-product-max-width'>
                  {/* {product.stockLevels.map((stock, index) => ( */}
                  <Row>
                    <Col className='favorite-stock-column product-details-tranparent-background'>
                      {i === 0 && itemHasFavoriteStock && <StarFill />}
                    </Col>
                    <Col className='pt-1 pb-1 text-center product-details-tranparent-background wrap-ellipsis'>
                      {stock !== undefined ? stock.stockName : '-'}
                    </Col>
                    <Col className='pt-1 pb-1 text-center product-details-tranparent-background'>
                      {stock !== undefined && stock.inStock !== null ? (
                        <>
                          {`${stock.inStock}`}
                          <small> {validateUnitExists(product.unit)}</small>
                        </>
                      ) : null}
                    </Col>
                    {
                      <Col className='pt-1 pb-1 text-center product-details-tranparent-background'>
                        {stock !== undefined && stock.arriving !== null ? (
                          <>
                            {`${stock.arriving}`}
                            <small> {validateUnitExists(product.unit)}</small>
                          </>
                        ) : null}
                      </Col>
                    }
                  </Row>
                  {/* ))} */}
                </Col>
              );
            })}
        </Row>
      );
    }

    return renderList;
  };

  const getFormattedMoqText = (quantity: number) => {
    return `${t('common.lbl_Price')} (${t('common.lbl_Moq')} ${quantity}):`;
  };

  const returnMoqRows = () => {
    const allMoqs: number[][] = [];

    products.forEach((product) => {
      allMoqs.push(product.moqPrices.map((moq) => moq.moq));
    });

    const flatAllMoqs = allMoqs.flat();

    const uniqueAllMoqs = Array.from(new Set(flatAllMoqs)).sort(
      (a, b) => a - b
    );

    const rowNodes = [];

    for (const uniqueMoq of uniqueAllMoqs) {
      rowNodes.push(
        <Row className='width-max-content'>
          <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
            {getFormattedMoqText(uniqueMoq)}
          </Col>
          {products &&
            products.map((product) => (
              <ProductMoqPrice uniqueMoq={uniqueMoq} moqs={product.moqPrices} />
            ))}
        </Row>
      );
    }

    return rowNodes;
  };

  return (
    <Card className='d-print-none width-max-content'>
      <Card.Header
        style={{ left: '0px' }}
        className='header-aProduct p-2 position-sticky'
        onClick={() => setOpen(!open)}
        aria-controls='priceAndAvailability'
        aria-expanded={open}
      >
        {open ? <ArrowDownCircleFill /> : <ArrowRightCircleFill />}
        <span className='p-1 multiple-seller-card-header-max-width-colored'>
          {t('productDetailsPrice.lbl_PriceAvailability')}
        </span>
      </Card.Header>
      <Collapse in={open}>
        <Card.Body id='priceAndAvailability'>
          {returnMoqRows()}
          <Row className='width-max-content'>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsPrice.lbl_OrderStep')}:
            </Col>
            {products &&
              products.map((product) => (
                <Col className='ms-1 pt-1 pb-1 text-center multiple-seller-card-product-max-width'>
                  {product.orderStep}
                </Col>
              ))}
          </Row>

          <Row className='width-max-content'>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'></Col>

            {products &&
              products.map((product) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  <Row>
                    <Col className='favorite-stock-column product-details-tranparent-background' />
                    <Col className='ms-1 pt-1 pb-1 text-center product-details-tranparent-background'>
                      {t('customers.lbl_Name')}
                    </Col>
                    <Col className='ms-1 pt-1 pb-1 text-center product-details-tranparent-background'>
                      {t('common.lbl_Instock')}
                    </Col>
                    {
                      <Col className='ms-1 pt-1 pb-1 text-center product-details-tranparent-background'>
                        {t('common.lbl_Arriving')}
                      </Col>
                    }
                  </Row>
                </Col>
              ))}
          </Row>

          <Row className='width-max-content'>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsPrice.lbl_InStockAll')}:
            </Col>

            {products &&
              products.map((product) => {
                let totalStockLevel = 0;
                let totalArriving = 0;

                product.stockLevels.forEach((stockLevel) => {
                  totalStockLevel += stockLevel.inStock;
                  totalArriving += stockLevel.arriving;
                });

                const allStockIsNull =
                  product.stockLevels.filter(
                    (stockLevel) => stockLevel.inStock === null
                  ).length === product.stockLevels.length;
                const allArrivingIsNull =
                  product.stockLevels.filter(
                    (stockLevel) => stockLevel.arriving === null
                  ).length === product.stockLevels.length;
                return (
                  <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                    <Row>
                      <Col className='favorite-stock-column product-details-tranparent-background' />
                      <Col className='ms-1 pt-1 pb-1 text-center product-details-tranparent-background'>
                        {t('common.lbl_Total')}
                      </Col>
                      <Col className='ms-1 pt-1 pb-1 text-center product-details-tranparent-background'>
                        {allStockIsNull ? (
                          '-'
                        ) : (
                          <strong>
                            {totalStockLevel}{' '}
                            <small> {validateUnitExists(product.unit)}</small>
                          </strong>
                        )}
                      </Col>
                      {
                        <Col className='ms-1 pt-1 pb-1 text-center product-details-tranparent-background'>
                          {allArrivingIsNull ? (
                            '-'
                          ) : (
                            <>
                              {totalArriving}{' '}
                              <small> {validateUnitExists(product.unit)}</small>
                            </>
                          )}
                        </Col>
                      }
                    </Row>
                  </Col>
                );
              })}
          </Row>

          {renderStockRows()}

          {/*  Not in MVP1 scope */}
          {/*  <Row>
            <Col className='pt-1 pb-1'>
              {t('productDetailsPrice.lbl_InStockFavorite')}:
            </Col>
            <Col className='ms-1 pt-1 pb-1'>TODO: 240 pcs</Col>
          </Row> */}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

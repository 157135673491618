import 'bootstrap/dist/css/bootstrap.min.css';
import { BuyerDealsFilter } from 'components/buyer/dealshome/BuyerDealsFilter';
import { BuyerDealsRow } from 'components/buyer/dealshome/BuyerDealsRow';
import { AppContext } from 'contexts/user/AppContext';
import { ProjectsPageGuide } from 'introGuides/ProjectsPageGuide';
import { FilterStateModel } from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Card, Spinner } from 'react-bootstrap';
import { CaretDownFill } from 'react-bootstrap-icons';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { LogoLoader } from 'components/shared/Loaders/LogoLoader';
import '../dealshome/deals.css';

export const BuyerDeals = () => {
  const [filterState, setFilterState] = useState<FilterStateModel>({
    ownerId: 'all',
    dealStatus: null,
    inquiryStatus: null,
    offerStatus: null,
    orderStatus: null,
    invoiceStatus: null,
    searchString: '',
    pageNumber: 1,
    tagIds: [],
  } as FilterStateModel);

  const [firstFetchDone, setFirstFetchDone] = useState<boolean>(false);

  const [mounted, setMounted] = useState<boolean>(false);

  const [moreDealsLoading, setMoreDealsLoading] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');

  useEffect(() => {
    setMounted(true);
  }, []);

  // const { data, isLoading, isError } = useQuery(
  //   [
  //     'deals',
  //     currentUser.companyId,
  //     appState.buyerDealsFilter,
  //     appState.buyerCurrentSuppliers,
  //   ],
  //   appState.getProjects,
  //   {
  //     enabled: !!currentUser.companyId,
  //     staleTime: 100 * 60 * 30,
  //     keepPreviousData: true,
  //   }
  // );

  const BuyerDealsFilterNode = appState.buyerDeals && (
    <Card className='pt-2 mx-2 mb-0'>
      <BuyerDealsFilter
        setFilterState={setFilterState}
        filterState={filterState}
        currentUser={currentUser}
        getProjects={appState.getProjects}
        totalNumberOfPages={
          appState.buyerDeals.data ? appState.buyerDeals.data.totalPages : 1
        }
      />
    </Card>
  );

  const loadMoreDeals = () => {
    setMoreDealsLoading(true);
    if (
      appState.buyerDeals &&
      appState.buyerDeals.data &&
      appState.buyerDealsFilter.pageNumber < appState.buyerDeals.data.totalPages
    ) {
      setFilterState((prevState: FilterStateModel) => ({
        ...prevState,
        pageNumber: prevState.pageNumber + 1,
      }));
    }
  };

  return (
    <>
      <ProjectsPageGuide />

      {/* <NewElecZapVideoGuides /> */}

      {mounted &&
        ReactDOM.createPortal(
          BuyerDealsFilterNode,
          document &&
            document.getElementsByClassName('fixed-portal-receiver')[0]
        )}

      {appState.buyerDeals.isLoading ? (
        <div>
          <LogoLoader />
        </div>
      ) : (
        <div className='pt-3 mt-0'>
          {appState.buyerDeals?.data?.data?.length !== 0 ? (
            <>
              {appState.buyerDeals.data?.data?.map((project, index) => (
                <BuyerDealsRow
                  filterState={filterState}
                  currentUser={currentUser}
                  project={project}
                  index={index}
                />
              ))}

              <div className='d-flex justify-content-center mb-4'>
                {(appState.buyerDeals.data?.data &&
                  appState.buyerDealsFilter?.pageNumber <
                    appState.buyerDeals.data?.totalPages) ||
                moreDealsLoading ? (
                  <Button
                    disabled={moreDealsLoading}
                    onClick={loadMoreDeals}
                    className='btn btn-eleczap'
                  >
                    {t('deals.btn_LoadMoreDeals')}{' '}
                    {moreDealsLoading ? (
                      <Spinner animation='border' size='sm' />
                    ) : (
                      <CaretDownFill />
                    )}
                  </Button>
                ) : (
                  <span>{t('deals.inf_AllDealsLoaded')}</span>
                )}
              </div>
            </>
          ) : (
            <div className='text-center' style={{ marginTop: '150px' }}>
              <h3>{t('deals.inf_NoFilterResults1')}</h3>
              <h3>{t('deals.inf_NoFilterResults2')}</h3>
            </div>
          )}
        </div>
      )}
    </>
  );
};

import 'bootstrap/dist/css/bootstrap.min.css';
import { useGetAvailableCountries } from 'hooks/useGetAvailabeCountries';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  AddressModel,
  AddressModelById,
} from 'models/shared/address/AddressModel';
import { AddressUpdateModel } from 'models/shared/address/AddressUpdateModel';
import { CountryModel } from 'models/shared/country/CountryModel';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useFormikHookForAddress } from './hooksDeliveryAddressEdit';

type PropTypes = {
  currentUser: LoginResponseUserModel;
  handleSubmit: (
    currentUser: LoginResponseUserModel,
    values: AddressUpdateModel,
    address: AddressModelById,
    t: any
  ) => Promise<void>;
  address: AddressModelById;
  setAddresses: (values: AddressModel) => void;
  t: any;
};

export const DeliveryAddressesEdit = ({
  currentUser,
  handleSubmit,
  address,
  setAddresses,
  t,
}: PropTypes) => {
  const [show, setShow] = useState(false);
  const [countries, setCountries] = useState<CountryModel[]>([]);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const formik = useFormikHookForAddress(
    currentUser,
    address,
    setAddresses,
    handleSubmit,
    setShow,
    t
  );

  useGetAvailableCountries(setCountries);

  const handleSubmitForm = async () => {
    await formik.handleSubmit();
  };

  return (
    <>
      <Button variant='btn btn-eleczap-noBorder' onClick={handleShow}>
        <PencilSquare />
      </Button>

      <Modal size='sm' show={show} onHide={handleClose} id='addAddress'>
        <Modal.Header closeButton>
          <Modal.Title>{t('addressSettings.hd_EditAddress')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <Form noValidate>
            <Form.Group
              className='mb-2'
              as={Row}
              md='12'
              controlId='validationName'
            >
              <Form.Label className='required'>
                {t('addressSettings.lbl_Name')}
              </Form.Label>
              <Form.Control
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                required
                placeholder={t('addressSettings.plh_Name')}
                type='input'
                isInvalid={formik.touched.name && !!formik.errors.name}
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className='mb-2'
              as={Row}
              md='12'
              controlId='validationAddressLine1'
            >
              <Form.Label className='required'>
                {t('addressSettings.lbl_AddressLine1')}
              </Form.Label>
              <Form.Control
                name='addressLine1'
                value={formik.values.addressLine1}
                onChange={formik.handleChange}
                type='text'
                isInvalid={
                  formik.touched.addressLine1 && !!formik.errors.addressLine1
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.addressLine1}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className='mb-2'
              as={Row}
              md='12'
              controlId='validationAddressLine2'
            >
              <Form.Label>{t('addressSettings.lbl_AddressLine2')}</Form.Label>
              <Form.Control
                name='addressLine2'
                value={formik.values.addressLine2}
                onChange={formik.handleChange}
                type='text'
                isInvalid={
                  formik.touched.addressLine2 && !!formik.errors.addressLine2
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.addressLine2}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className='mb-2'
              as={Row}
              md='12'
              controlId='validationCity'
            >
              <Form.Label className='required'>
                {t('addressSettings.lbl_City')}
              </Form.Label>
              <Form.Control
                name='city'
                value={formik.values.city}
                onChange={formik.handleChange}
                type='text'
                required
                isInvalid={formik.touched.city && !!formik.errors.city}
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.city}
              </Form.Control.Feedback>
            </Form.Group>

            <Row className='mt-3'>
              <Form.Group
                className='mb-2'
                as={Col}
                md='8'
                controlId='validationState'
              >
                <Form.Label className='required'>
                  {t('addressSettings.lbl_State')}
                </Form.Label>
                <Form.Control
                  name='state'
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  type='text'
                  required
                  isInvalid={formik.touched.state && !!formik.errors.state}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.state}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className='mb-2'
                as={Col}
                md='4'
                controlId='validationPostalCode'
              >
                <Form.Label className='required'>
                  {t('addressSettings.lbl_PostalCode')}
                </Form.Label>
                <Form.Control
                  name='postalCode'
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  type='text'
                  required
                  isInvalid={
                    formik.touched.postalCode && !!formik.errors.postalCode
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.postalCode}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group
              className='mb-2'
              as={Row}
              md='12'
              controlId='validationCountryCode'
            >
              <Form.Label className='required'>
                {t('addressSettings.lbl_Country')}
              </Form.Label>
              <Form.Select
                name='country.code'
                required
                value={
                  formik.values.country !== undefined
                    ? formik.values.country.code
                    : 'AD'
                }
                onChange={formik.handleChange}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.code}>
                    {country.shortName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <>
              <Form.Check
                name='isDefault'
                checked={formik.values.isDefault}
                onChange={formik.handleChange}
                aria-label={t('addressSettings.lbl_MakeDefaut')}
                label={t('addressSettings.lbl_MakeDefaut')}
                className='mt-3'
              />
            </>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='btn btn-eleczap' onClick={handleSubmitForm}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {} from 'components/buyer/suppliers/hooksSuppliers';
import { NoSuppliersModal } from 'components/buyer/suppliers/NoSuppliersModal/NoSuppliersModal';
import { SupplierCompanyUserTable } from 'components/buyer/suppliers/SupplierTables/SupplierCompanyUserTable';
import { SupplierUserTable } from 'components/buyer/suppliers/SupplierTables/SupplierUserTable';
import {
  getAllSuppliersUsers,
  getSupplierPricelistExport,
} from 'components/buyer/suppliers/utilsSuplliers';
import { useGetAllMarketplaceEndSuppliersAndContacts } from 'components/seller/customers/hooksCustomer';
import { getAllMarketplaceEndSuppliersAndContacts } from 'components/seller/customers/utilsCustomer';
import { AppContext } from 'contexts/user/AppContext';
import { useSaveFileAs } from 'hooks/useSaveFileAs';
import { SellersCompaniesAndContactsModel } from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { SellersUsersModel } from 'models/buyer/suppliers/SellersUsersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const MarketplaceEndSuppliersContainer = (): JSX.Element => {
  const [suppliers, setSuppliers] = useState<SellersCompaniesAndContactsModel>(
    {} as SellersCompaniesAndContactsModel
  );
  const [suppliersUsers, setSuppliersUsers] = useState<SellersUsersModel>(
    {} as SellersUsersModel
  );
  const [supplierFilter, setSupplierFilter] = useState<string>('');

  const [showNoSuppliersModal, setShowNoSuppliersModal] =
    useState<boolean>(false);

  /* Search suppliers and users states */
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('name');
  const saveFile = useSaveFileAs();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const { t } = useTranslation('components');

  useGetAllMarketplaceEndSuppliersAndContacts(
    currentUser,
    setSuppliers,
    pageNumber,
    query,
    rowsPerPage,
    sortColumn
  );

  const handleChangeSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const inputQuery = event.target.value;
    setQuery(query);
    setResetPaginationToggle(false);
    supplierFilter === 'users'
      ? await getAllSuppliersUsers(
          currentUser,
          setSuppliersUsers,
          pageNumber,
          inputQuery,
          rowsPerPage,
          sortColumn
        )
      : await getAllMarketplaceEndSuppliersAndContacts(
          currentUser,
          setSuppliers,
          pageNumber,
          inputQuery,
          rowsPerPage,
          sortColumn
        );

    setResetPaginationToggle(true);
  };

  const searchAPIDebounced = AwesomeDebouncePromise(handleChangeSearch, 500);

  const handlePageChange = async (page: number) => {
    setPageNumber(page);
    supplierFilter === 'users'
      ? await getAllSuppliersUsers(
          currentUser,
          setSuppliersUsers,
          page,
          query,
          rowsPerPage,
          sortColumn
        )
      : await getAllMarketplaceEndSuppliersAndContacts(
          currentUser,
          setSuppliers,
          page,
          query,
          rowsPerPage,
          sortColumn
        );
  };

  const handleRowsPerChange = async (rowsAmount: number) => {
    setRowsPerPage(rowsAmount);
    supplierFilter === 'users'
      ? await getAllSuppliersUsers(
          currentUser,
          setSuppliersUsers,
          pageNumber,
          query,
          rowsAmount,
          sortColumn
        )
      : await getAllMarketplaceEndSuppliersAndContacts(
          currentUser,
          setSuppliers,
          pageNumber,
          query,
          rowsAmount,
          sortColumn
        );
  };
  const handleOnSort = async (selectedColumn: any, sortDirection: any) => {
    const sortingColumn =
      sortDirection === 'desc'
        ? `${selectedColumn.selector}_${sortDirection}`
        : selectedColumn.selector;
    setSortColumn(sortingColumn);
    supplierFilter === 'users'
      ? await getAllSuppliersUsers(
          currentUser,
          setSuppliersUsers,
          pageNumber,
          query,
          rowsPerPage,
          sortDirection
        )
      : await getAllMarketplaceEndSuppliersAndContacts(
          currentUser,
          setSuppliers,
          pageNumber,
          query,
          rowsPerPage,
          sortDirection
        );
  };

  const handlePriceListExport = async (
    compnanyId: number,
    setFileIsSaving: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setFileIsSaving(true);

    const fileBlob = await getSupplierPricelistExport(
      currentUser,
      compnanyId,
      userLocaleId
    );
    saveFile(fileBlob);

    setFileIsSaving(false);
  };

  const tableToShow = () => {
    switch (supplierFilter) {
      case 'companiesAndUsers':
        return (
          <SupplierCompanyUserTable
            refreshSuppliers={async () =>
              getAllMarketplaceEndSuppliersAndContacts(
                currentUser,
                setSuppliers,
                pageNumber,
                query,
                rowsPerPage,
                sortColumn
              )
            }
            handlePageChange={handlePageChange}
            handleRowsPerChange={handleRowsPerChange}
            handleOnSort={handleOnSort}
            handlePriceListExport={handlePriceListExport}
            resetPaginationToggle={resetPaginationToggle}
            showUsers
            suppliers={suppliers}
          />
        );

      case 'users':
        return (
          <SupplierUserTable
            handlePageChange={handlePageChange}
            handleRowsPerChange={handleRowsPerChange}
            handleOnSort={handleOnSort}
            resetPaginationToggle={resetPaginationToggle}
            suppliersUsers={suppliersUsers}
          />
        );

      case 'companies':
        return (
          <SupplierCompanyUserTable
            refreshSuppliers={async () =>
              getAllMarketplaceEndSuppliersAndContacts(
                currentUser,
                setSuppliers,
                pageNumber,
                query,
                rowsPerPage,
                sortColumn
              )
            }
            handlePageChange={handlePageChange}
            handleRowsPerChange={handleRowsPerChange}
            handleOnSort={handleOnSort}
            handlePriceListExport={handlePriceListExport}
            resetPaginationToggle={resetPaginationToggle}
            showUsers={false}
            suppliers={suppliers}
          />
        );

      default:
        return (
          <SupplierCompanyUserTable
            refreshSuppliers={async () =>
              getAllMarketplaceEndSuppliersAndContacts(
                currentUser,
                setSuppliers,
                pageNumber,
                query,
                rowsPerPage,
                sortColumn
              )
            }
            handlePageChange={handlePageChange}
            handleRowsPerChange={handleRowsPerChange}
            handleOnSort={handleOnSort}
            handlePriceListExport={handlePriceListExport}
            resetPaginationToggle={resetPaginationToggle}
            showUsers
            suppliers={suppliers}
          />
        );
    }
  };

  /* Check if buyer even has any suppliers */
  useEffect(() => {
    const buyerHasAtleastOneSupplier = suppliers?.data?.length !== 0;

    /* If is false, show the noSuppliers modal and do not load the supplier selector. */
    if (!buyerHasAtleastOneSupplier) {
      setShowNoSuppliersModal(true);
    }
  }, [suppliers?.data?.length]);

  return (
    <>
      <div className='container-fluid p-2'>
        <Card className='mt-1'>
          <Card.Header>
            <h1>{t('suppliersPage.hd_YourSuppliers')}</h1>
            <div className='d-flex align-items-center'>
              <div className='flex-grow-1'>
                <div>
                  {/* <SupplierFilter setSupplierFilter={setSupplierFilter} /> */}

                  <input
                    style={{ maxWidth: '175px' }}
                    onChange={searchAPIDebounced}
                    type='text'
                    id='SearchText'
                    name='SearchText'
                    className='form-control d-none d-sm-inline-block ms-2'
                    placeholder={t('suppliersPage.plh_SearchForSuppliers')}
                    aria-label='Search'
                  />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body id='list-of-suppliers'>{tableToShow()}</Card.Body>
        </Card>

        <NoSuppliersModal
          show={showNoSuppliersModal}
          setShow={setShowNoSuppliersModal}
          suppliers={suppliers}
          setSuppliers={setSuppliers}
        />
      </div>
    </>
  );
};

import { BuyerFavoriteProductSwitch } from 'components/buyer/products/BuyerFavoriteProductSwitch';
import { SellerFavoriteProductSwitch } from 'components/seller/products/SellerFavoriteProductSwitch';
import { EditableImageWithFallback } from 'components/shared/controls/EditableImageWithFallback/EditableImageWithFallback';
import { CustomProductListQtyInput } from 'components/shared/CustomInputs/CustomProductListQtyInput';

import { ImageWithFallback } from 'components/shared/ImageWithFallback';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { AppContext } from 'contexts/user/AppContext';
import { useAddProductsToProjectInquiry } from 'hooks/useAddProductsToProjectInquiry';
import { useAddProductsToProjectOrder } from 'hooks/useAddProductsToProjectOrder';
import { useResetQtyForProductList } from 'hooks/useResetQtyForProductList';
import NoProductImage from 'media/ElecZap_new_logo_grayscale_cropped.png';
import logov2 from 'media/ElecZap_new_logo_text.png';
import { GroupedProductModel } from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductDetailsModel } from 'models/shared/product/ProductDetailsModel';
import { MouseEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, ButtonGroup, Card, Col, Row } from 'react-bootstrap';
import { CartFill, Clipboard2Fill, FilePdf } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropsType = {
  products: ProductDetailsModel[];
  isAddToCartVisible?: boolean;
  viewType: 'Seller' | 'Buyer';
  handleImageUpload?: (file: File) => Promise<void>;
  handleImageDelete?: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
};

export const ProductDetailsHeader = ({
  products,
  viewType,
  isAddToCartVisible = false,
  handleImageUpload,
  handleImageDelete,
}: PropsType) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [groupedProduct, setGroupedProduct] = useState<GroupedProductModel>(
    {} as GroupedProductModel
  );
  const [productListWithQtys, setProductListWithQtys] = useState<
    GroupedProductModel[]
  >([]);

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState, appContextDispatch } = useContext(AppContext);

  const addProductsToInquiry = useAddProductsToProjectInquiry();
  const addProductsToOrder = useAddProductsToProjectOrder();
  const resetQtyForProductList = useResetQtyForProductList();

  useEffect(() => {
    if (viewType === 'Buyer') {
      const product: GroupedProductModel = {
        productCode: products[0].code,
        products: products,
      };
      appContextDispatch({
        type: 'BUYER_SET_CURRENT_PRODUCT_LIST',
        currentProducts: [product],
      });

      setGroupedProduct(product);
    }
  }, [appContextDispatch, products, viewType]);

  const isSellerSelectedForGivenProduct = (
    product: ProductDetailsModel
  ): boolean => {
    return (
      appState.buyerCurrentSuppliers?.findIndex(
        (supplier) =>
          supplier.value === product.company.id && supplier.isSelected
      ) !== -1 ||
      appState.buyerCurrentSuppliers.findIndex(
        (v) =>
          v.isActive &&
          v.isSelected &&
          v.subSellers?.some(
            (subSeller) =>
              product.company.id === subSeller.value && subSeller.isSelected
          )
      ) !== -1
    );
  };

  useEffect(() => {
    const productsWithQtys = appState.currentProductsWithQtys.filter(
      (prod) => prod.quantity !== 0
    );

    setProductListWithQtys(productsWithQtys);
  }, [appState.currentProductsWithQtys]);

  const handleInquireProducts = async () => {
    setIsLoading(true);

    if (productListWithQtys) {
      await addProductsToInquiry('ProductList', {
        groupedProducts: productListWithQtys,
        importProductsText: null,
      });

      resetQtyForProductList();

      setIsLoading(false);

      SuccessToast(t('projectSettings.inf_ProductAdded'));
    }

    setIsLoading(false);
  };

  const handleOrderProducts = async () => {
    setIsLoading(true);

    if (productListWithQtys) {
      await addProductsToOrder('ProductList', {
        groupedProducts: productListWithQtys,
        importProductsText: null,
      });

      resetQtyForProductList();

      setIsLoading(false);

      SuccessToast(t('projectSettings.inf_ProductAdded'));
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Card.Header className='p-1'>
        <Row className='flex-nowrap width-max-content'>
          <Col className='multiple-seller-card-header-max-width'>
            <Row>
              <Col lg={2} className='d-print-none'></Col>
              <Col>
                <h2 className='ms-0 mb-0'>{products[0].code}</h2>

                <small>{products[0].manufacturerName}</small>
              </Col>
            </Row>
          </Col>
          {products.map(
            (product) =>
              (viewType === 'Seller' ||
                isSellerSelectedForGivenProduct(product)) && (
                <Col className='d-flex justify-content-between ms-3 multiple-seller-card-product-max-width'>
                  <span className='d-flex'>
                    <h4 className='ms-4'>
                      {product.company.shortName ?? product.company.name}
                    </h4>
                  </span>
                  <img
                    src={logov2}
                    alt='ElecZap'
                    className='logo-product-card-header float-end'
                  ></img>
                  <Button
                    className='d-print-none'
                    variant='btn btn-eleczap'
                    onClick={() => window.print()}
                  >
                    <FilePdf />
                  </Button>
                </Col>
              )
          )}
        </Row>
        <Row className='flex-nowrap width-max-content '>
          {isAddToCartVisible ? (
            <Col className='multiple-seller-card-header-max-width d-print-none'>
              <ButtonGroup className='ms-4 me-1 mt-3'>
                <Button
                  disabled={productListWithQtys.length < 1}
                  onClick={handleInquireProducts}
                  className='btn btn-eleczap me-1 d-flex align-items-center'
                >
                  <Clipboard2Fill className='me-1' />{' '}
                  {t('documents.btn_Inquire')}
                </Button>
                <Button
                  disabled={productListWithQtys.length < 1}
                  onClick={handleOrderProducts}
                  className='btn btn-eleczap d-flex align-items-center'
                >
                  {t('documents.btn_Order')} <CartFill className='ms-1' />
                </Button>
              </ButtonGroup>

              <div
                style={{ maxWidth: '160px' }}
                className='ms-4 me-1 mt-1 d-flex justify-content-center'
              >
                <CustomProductListQtyInput product={groupedProduct} />
              </div>
            </Col>
          ) : (
            <Col className='multiple-seller-card-header-max-width'></Col>
          )}
          {products &&
            products.map(
              (product) =>
                (viewType === 'Seller' ||
                  isSellerSelectedForGivenProduct(product)) && (
                  <Col className='text-center ms-3 multiple-seller-card-product-max-width'>
                    {viewType === 'Buyer' ? (
                      <ImageWithFallback
                        alt={product.code}
                        className='img-Aporduct'
                        src={product.image}
                        fallback={NoProductImage}
                      />
                    ) : (
                      <EditableImageWithFallback
                        alt={product.code}
                        className='img-Aporduct overlay-image'
                        src={product.image}
                        fallback={NoProductImage}
                        maxFileSizekB={500}
                        accept={'.png, .jpg, .jpeg, .gif, .bmp, .tif, .tiff'}
                        uploadProductImageRequirementsText={t(
                          'editableImageWithFallback.lbl_UploadProductImageRequirements',
                          { maxFileSizeKb: 500 }
                        )}
                        handleImageUpload={handleImageUpload}
                        handleImageDelete={handleImageDelete}
                      />
                    )}
                  </Col>
                )
            )}
        </Row>
        <Row className='p-3 flex-nowrap width-max-content'>
          <Col className='multiple-seller-card-header-max-width text-end h5'>
            {t('common.lbl_ProductName')}
          </Col>
          {products &&
            products.map(
              (product) =>
                (viewType === 'Seller' ||
                  isSellerSelectedForGivenProduct(product)) && (
                  <Col className='text-start h5 ms-1 multiple-seller-card-product-max-width d-flex align-items-center'>
                    {viewType === 'Buyer' ? (
                      <BuyerFavoriteProductSwitch
                        isFavorite={product.isFavorite}
                        companyId={currentUser.companyId}
                        userId={currentUser.id}
                        productId={product.id}
                        sellerId={product.company.id}
                      />
                    ) : (
                      <SellerFavoriteProductSwitch
                        isFavorite={product.isFavorite}
                        companyId={currentUser.companyId}
                        userId={currentUser.id}
                        productId={product.id}
                      />
                    )}{' '}
                    <span className='ms-1'>{product.name}</span>
                  </Col>
                )
            )}
        </Row>
      </Card.Header>
    </>
  );
};

import { OfferComparisonRowModel } from 'models/buyer/documents/OfferModels';
import { ProjectInquiryRowModel } from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  handleTargetPriceChange?: (
    value: string,
    row: ProjectInquiryRowModel
  ) => Promise<void>;
  handleOfferComparisonTargetPriceChange?: (
    value: string,
    row: OfferComparisonRowModel
  ) => Promise<void>;
  row?: ProjectInquiryRowModel;
  rowsLength: number;
  offerComparisonRow?: OfferComparisonRowModel;
};

export const CustomTargetPriceInput = ({
  row,
  offerComparisonRow,
  rowsLength,
  handleTargetPriceChange,
  handleOfferComparisonTargetPriceChange,
}: PropTypes) => {
  const [value, setValue] = useState<string>(
    offerComparisonRow
      ? offerComparisonRow?.targetPrice?.toString() ?? ''
      : row?.targetPrice?.toString() ?? ''
  );

  useEffect(() => {
    if (offerComparisonRow?.targetPrice)
      setValue(offerComparisonRow?.targetPrice?.toString());
  }, [offerComparisonRow?.targetPrice]);

  const { t } = useTranslation('components');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(
      event.target.value
        .replaceAll(/[A-Za-z!@#$%^&*()\s]/g, '')
        .replaceAll(/[.,]+/g, '.')
    );
  };

  const handleBlur = async () => {
    if (row && handleTargetPriceChange) {
      handleTargetPriceChange(value ?? '', row);
    }

    if (offerComparisonRow && handleOfferComparisonTargetPriceChange) {
      handleOfferComparisonTargetPriceChange(value, offerComparisonRow);
    }
  };

  const handleKeyDown = async (event: any) => {
    if (
      (event.key === 'Enter' || event.key === 'Tab') &&
      row?.rowNo !== rowsLength
    ) {
      event.preventDefault();

      if (row && handleTargetPriceChange) {
        document
          .getElementById(
            `custom-${
              row ? 'project-inquiry-target' : 'offer-comparison'
            }-price-id-${row.rowNo + 1}`
          )
          ?.focus();

        (
          document.getElementById(
            `custom-${
              row ? 'project-inquiry-target' : 'offer-comparison'
            }-price-id-${row.rowNo + 1}`
          ) as HTMLInputElement
        )?.select();
      }

      if (offerComparisonRow && handleOfferComparisonTargetPriceChange) {
        document
          .getElementById(
            `custom-${
              row ? 'project-inquiry-target' : 'offer-comparison'
            }-price-id-${offerComparisonRow.rowNo + 1}`
          )
          ?.focus();

        (
          document.getElementById(
            `custom-${
              row ? 'project-inquiry-target' : 'offer-comparison'
            }-price-id-${offerComparisonRow.rowNo + 1}`
          ) as HTMLInputElement
        )?.select();
      }

      // document.activeElement && (document.activeElement as HTMLElement).blur();
    }
  };

  return (
    <FormControl
      max={100}
      maxLength={100}
      onChange={handleChange}
      className='inquiry-unsent-price-cell'
      onKeyDown={handleKeyDown}
      placeholder={t('documents.plh_EnterPrice')}
      value={value}
      onBlur={handleBlur}
      style={{ maxWidth: '100px', textAlign: 'right' }}
      type='text'
      id={`custom-${
        row ? 'project-inquiry-target' : 'offer-comparison'
      }-price-id-${row ? row.rowNo : offerComparisonRow?.rowNo}`}
    />
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { SellerCreditInfoModel } from 'models/buyer/suppliers/SellerCreditInfoModel';
import {
  SellerStocksModel,
  SellerUpdateIsFavoriteStockModel,
  SellerUpdateStocksModel,
} from 'models/buyer/suppliers/SellerStocksModel';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';

export const getSupplierCompanyInfo = async (
  setCompany: (value: SupplierCompanyModel) => void,
  currentUser: LoginResponseUserModel,
  supplierId: number
): Promise<void> => {
  const response = await BuyerApi.getSupplierCompanyInfo(
    currentUser.companyId,
    supplierId
  );

  setCompany(response);
};

export const getSupplierCreditInfo = async (
  setCreditInfo: (value: SellerCreditInfoModel) => void,
  currentUser: LoginResponseUserModel,
  supplierId: number
): Promise<void> => {
  const response = await BuyerApi.getSupplierCreditInfo(
    currentUser.companyId,
    supplierId
  );

  setCreditInfo(response);
};

export const getSellerStocks = async (
  setSellerStocks: (value: SellerStocksModel[]) => void,
  currentUser: LoginResponseUserModel,
  supplierId: number
): Promise<void> => {
  const response = await BuyerApi.getSellerStocks(
    currentUser.companyId,
    supplierId
  );

  setSellerStocks(response);
};

export const updateSellerStock = async (
  currentUser: LoginResponseUserModel,
  supplierId: number,
  stock: SellerUpdateStocksModel,
  stockId: number
): Promise<void> => {
  await BuyerApi.updateSellerStocks(
    currentUser.companyId,
    supplierId,
    stock,
    stockId
  );
};

export const updateSellerIsFavoriteStock = async (
  currentUser: LoginResponseUserModel,
  supplierId: number,
  stock: SellerUpdateIsFavoriteStockModel,
  stockId: number
): Promise<void> => {
  await BuyerApi.updateSellerIsFavoriteStock(
    currentUser.companyId,
    supplierId,
    stock,
    stockId
  );
};

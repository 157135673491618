import { IdentityApi } from 'api/IdentityApi';
import { TermsAndConditionsModal } from 'components/shared/TermsAndConditionsModal';
import { AppContext } from 'contexts/user/AppContext';
import { useGetLocalizations } from 'hooks/useGetLocalizations';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import {
  ChangeEvent,
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { PlayFill } from 'react-bootstrap-icons';
import { Trans, useTranslation } from 'react-i18next';
import 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ElecZapLogo from './../../../../media/ElecZap_new_logo.png';

import './userOnboardingIntro.css';

type PropTypes = {
  nextStep: MouseEventHandler;
  invitationToken: string;
  isTermsAccepted: boolean;
  setIsTermsAccepted: (value: boolean) => void;
};

export const UserOnboardingIntro = ({
  nextStep,
  invitationToken,
  isTermsAccepted,
  setIsTermsAccepted,
}: PropTypes): JSX.Element => {
  const [companyName, setCompanyName] = useState<string>('');
  const [localizations, setLocalizations] = useState<LocalizationModel[]>([]);
  const [localizationId, setLocalizationId] = useState<number>(1);

  const [showTerms, setShowTerms] = useState<boolean>(false);

  const [languageTermsToShow, setLanguageTermsToShow] = useState<string>(
    '/TermsANdConditions_EN.pdf'
  );

  const { appState } = useContext(AppContext);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const { t, i18n } = useTranslation('components');

  useEffect(() => {
    if (userLocaleId === 2)
      setLanguageTermsToShow('/TermsANdConditions_ET.pdf');
    else setLanguageTermsToShow('/TermsANdConditions_EN.pdf');
  }, [userLocaleId]);

  /* Get welcome details */
  useEffect(() => {
    const getWelcomeDetails = async () => {
      const response = await IdentityApi.getWelcomeDetails(invitationToken);

      setCompanyName(response.companyName);
      setLocalizationId(response.localizationId);
    };

    invitationToken !== '' && getWelcomeDetails();
  }, [invitationToken, setCompanyName]);

  /* Set UI language */
  useEffect(() => {
    const selectedLng = localizations.find((l) => l.id === localizationId);
    i18n.changeLanguage(selectedLng?.cultureName?.slice(0, 2));
  }, [localizations, localizationId, i18n]);

  useGetLocalizations(setLocalizations);

  const handleLocalizationChange = async (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const localeId = Number.parseInt(event.target.value);
    await IdentityApi.updateUserLocalization(invitationToken, {
      localizationId: localeId,
    });
    setLocalizationId(localeId);
  };

  const handleAcceptFromModal = () => {
    setIsTermsAccepted(true);
    setShowTerms(false);
  };

  return (
    <Card className='onboarding-intro-card'>
      <Card.Body className='text-center'>
        <img className='logo-onboarding' src={ElecZapLogo} alt='eleczap-logo' />
        <h1 className='mt-3'>{t('userOnboarding.hd_WelcomeToElecZap')}</h1>
        <p>
          {t('userOnboarding.lbl_InvitationExplanation', {
            companyName: companyName,
          })}
          <br />
          {t('userOnboarding.inf_Intro2')}
        </p>
        <Form.Group>
          <Form.Label column lg={3} className='text-start'>
            {t('userSettings.lbl_Language')}
            <Form.Select
              aria-label='Current active language'
              value={localizationId}
              onChange={handleLocalizationChange}
            >
              {localizations.map((e, key) => {
                return (
                  <option key={key} value={e.id}>
                    {e.cultureLongName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Label>
        </Form.Group>
        <div
          className='text-center d-flex justify-content-center'
          id='terms-and-conditions-checkbox'
        >
          <Form.Check.Input
            onChange={(event) => setIsTermsAccepted(event.target.checked)}
            checked={isTermsAccepted}
            type='checkbox'
          />
          <Form.Check.Label className='ms-2'>
            <Trans i18nKey={t('userOnboarding.lbl_AcceptTermsAndConditions')}>
              {[
                ' I agree to the ElecZap ',
                <span
                  onClick={() => setShowTerms(true)}
                  className='icon-ez cursor-pointer'
                >
                  terms and conditions
                </span>,
              ]}
            </Trans>
          </Form.Check.Label>
        </div>
        <Button
          disabled={!isTermsAccepted}
          className='btn btn-eleczap float-end mt-3'
          onClick={nextStep}
        >
          {t('common.btn_Next')}
          <PlayFill />
        </Button>
      </Card.Body>
      <TermsAndConditionsModal
        showTerms={showTerms}
        setShowTerms={setShowTerms}
        languageTermsToShow={languageTermsToShow}
        handleAcceptFromModal={handleAcceptFromModal}
      />
    </Card>
  );
};

import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import {
  BuyersUsersDataModel,
  BuyersUsersModel,
} from 'models/seller/customers/BuyersUsersModel';
import {
  CartDashFill,
  ExclamationSquareFill,
  FileEarmarkMinusFill,
  FolderCheck,
  Image,
} from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './customerTable.css';

const customStyles = {
  rows: {
    style: {
      backgroundColor: '#F2F2F2', // override the row height
    },
  },
};

type PropTypes = {
  customersContacts: BuyersUsersModel;
  handlePageChange: (page: number) => Promise<void>;
  handleRowsPerChange: (rowsAmount: number) => Promise<void>;
  handleOnSort: (selectedColumn: any, sortDirection: any) => Promise<void>;
  resetPaginationToggle: boolean;
};

export const UsersTable = ({
  customersContacts,
  handlePageChange,
  handleRowsPerChange,
  handleOnSort,
  resetPaginationToggle,
}: PropTypes): JSX.Element => {
  const { t } = useTranslation('components');

  const columns: TableColumn<BuyersUsersDataModel>[] = [
    {
      name: <Image />,
      selector: (row) => row.initials,
      cell: (row) => (
        <AvatarWithFallback
          initials={row.initials}
          imageUrl={row.profileImage}
          size='large'
        />
      ),

      maxWidth: '4.5rem',
      minWidth: '4.5rem',
      center: true,
    },
    {
      name: 'Name',
      selector: (row) => row.fullName,
      cell: (row) => (
        <Link className='link-eleczap' to='a-customer'>
          {row.fullName}
        </Link>
      ),
      sortable: true,
      maxWidth: '15rem',
      minWidth: '15rem',
    },
    {
      name: 'E-mail',
      selector: (row) => row.email,
      cell: (row) => <a className='link-eleczap'>{row.email}</a>,
    },

    {
      name: 'Phone',
      selector: (row) => row.phone,
    },
    {
      name: 'Position',
      selector: (row) => row.position,
    },
    {
      name: <FolderCheck className='icon-customerActiveProject' />,
      selector: (row) => row.activeDeals,
      sortable: true,
      center: true,
      maxWidth: '3.7rem',
      minWidth: '3.7rem',
      style: {
        color: 'var(--primary)',
      },
    },

    {
      name: <FileEarmarkMinusFill className='icon-customerOffer' />,
      selector: (row) => row.offers,
      sortable: true,
      center: true,
      maxWidth: '3.7rem',
      minWidth: '3.7rem',
      style: {
        color: 'var(--primary)',
      },
    },
    {
      name: <CartDashFill className='icon-customerOrder' />,
      selector: (row) => row.orders,
      sortable: true,
      center: true,
      maxWidth: '3.7rem',
      minWidth: '3.7rem',
      style: {
        color: 'var(--primary)',
      },
    },
    {
      name: <ExclamationSquareFill className='icon-ez-red' />,
      selector: (row) => row.overdueInvoices,
      sortable: true,
      center: true,
      maxWidth: '3.7rem',
      minWidth: '3.7rem',
      style: {
        color: 'var(--primary)',
      },
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: t('common.lbl_RowsPerPage'),
    rangeSeparatorText: t('common.lbl_Of'),
  };

  return (
    <DataTable
      noDataComponent={
        <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
      }
      highlightOnHover
      responsive
      columns={columns}
      data={customersContacts.data}
      pagination
      customStyles={customStyles}
      paginationServer
      paginationComponentOptions={paginationComponentOptions}
      paginationTotalRows={customersContacts.totalItems}
      onChangeRowsPerPage={handleRowsPerChange}
      onChangePage={handlePageChange}
      paginationResetDefaultPage={resetPaginationToggle}
      sortServer
      onSort={handleOnSort}
    />
  );
};

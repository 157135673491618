import axios from 'axios';
import { createdAt, responseBody } from './ApiClient';
import {
  apiResponseInterceptor,
  jwtRequestInterceptor,
} from './ApiInterceptors';

const client = axios.create({ baseURL: '/api/Companies' });
client.interceptors.response.use(undefined, apiResponseInterceptor);
client.interceptors.request.use(jwtRequestInterceptor);

/** API client for using REST API only for Company level */
export const CompanyApiClient = {
  get: (url: string) => client.get(url).then(responseBody),
  post: (url: string, body: {}) => client.post(url, body).then(responseBody),
  postCreate: (url: string, body: {}) => client.post(url, body).then(createdAt),
  put: (url: string, body: {}) => client.put(url, body).then(responseBody),
  delete: (url: string) => client.delete(url).then(responseBody),
};

import { BuyerApi } from 'api/BuyerApi';
import { ProjectInquiryAddProductForm } from 'components/buyer/projectInquiry/ProjectInquiryAddProductForm/ProjectInquiryAddProductForm';
import { CustomSingleEditableProductCode } from 'components/shared/CustomInputs/CustomeSingleEditableProductCode';
import { CustomSingleEditableText } from 'components/shared/CustomInputs/CustomSingleEditableText';
import { CustomSingleRequiredQuantityInput } from 'components/shared/CustomInputs/CustomSingleRequiredQtyInput';
import { CustomTargetPriceInput } from 'components/shared/CustomInputs/CustomTargetPriceInput';
import { CustomToggle } from 'components/shared/CustomInputs/CustomToggle';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { useListenElementResize } from 'hooks/useListenElementResize';
import {
  ProjectInquiryBulkUpdateRowModel,
  ProjectInquiryModel,
  ProjectInquiryRowModel,
  ProjectInquiryUpdateRowModel,
} from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  updateBulkProjectInquiryRows,
  updateProjectInquiryRows,
} from 'pages/buyer/ProjectInquiry/utilsProjectInquiry';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  projectDetails: ProjectInquiryModel;
  projectId: number;
  getDataSource: () => Promise<void>;
  dataSource: ProjectInquiryRowModel[];
  setDataSource: (value: ProjectInquiryRowModel[]) => void;
};

export const ProjectInquiryProductsGridGrouped = ({
  projectDetails,
  projectId,
  getDataSource,
  setDataSource,
  dataSource,
}: PropTypes) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);
  const [widthOfGridContainer, setWidthOfGridContainer] = useState<number>(0);

  const [isRequiredQtyUpdating, setIsRequiredQtyUpdating] =
    useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');

  useEffect(() => {
    const getData = async () => {
      await getDataSource();
      setIsLoading(false);
    };

    appState.userProfile?.currentProject?.inquiryProjectId !== null &&
      getData();
  }, [
    appState.userProfile?.currentProject?.inquiryProjectId,
    currentUser,
    getDataSource,
  ]);

  const handleDeleteRow = async (row: ProjectInquiryRowModel) => {
    setIsLoading(true);
    toast.dismiss();
    projectId &&
      (await BuyerApi.projectInquiry.deleteProjectInquiryRows(
        currentUser.companyId,
        projectId,
        projectDetails.id,

        row.id
      ));
    // toast.success(t('projectSettings.inf_RowDeleted'));
    await getDataSource();
    setIsLoading(false);
  };

  useListenElementResize('.project-product-grid-container', {
    setWidthOfElement: setWidthOfGridContainer,
  });

  const handleTargetPriceChange = async (
    value: string,
    row: ProjectInquiryRowModel
  ) => {
    const updatedRow: ProjectInquiryUpdateRowModel = {
      productCode: row.productCode,
      productName: row.productName,
      requiredQuantity: row.requiredQuantity,
      targetPrice: isNaN(parseFloat(value)) ? null : parseFloat(value),
      allowReplacement: row.allowReplacement,
    };

    // document
    //   .getElementById(`custom-target-price-id-${row?.rowNo + 1}`)
    //   ?.focus();

    projectId &&
      (await updateProjectInquiryRows(
        updatedRow,
        currentUser.companyId,
        projectId,
        projectDetails.id,
        row.id
      ));

    await getDataSource();
  };

  const handleAllowReplacementChange = async (
    checked: boolean,
    row: ProjectInquiryRowModel
  ) => {
    setIsLoading(true);
    const updatedRow: ProjectInquiryUpdateRowModel = {
      productCode: row.productCode,
      productName: row.productName,
      requiredQuantity: row.requiredQuantity,
      targetPrice: row.targetPrice,
      allowReplacement: checked,
    };

    projectId &&
      (await updateProjectInquiryRows(
        updatedRow,
        currentUser.companyId,
        projectId,
        projectDetails.id,
        row.id
      ));

    await getDataSource();
    setIsLoading(false);
  };

  const handleBulkAllowReplacementChange = async (checked: boolean) => {
    setIsLoading(true);

    const updatedRow: ProjectInquiryBulkUpdateRowModel = {
      allowReplacement: checked,
    };

    projectId &&
      (await updateBulkProjectInquiryRows(
        updatedRow,
        currentUser.companyId,
        projectId,
        projectDetails.id
      ));

    await getDataSource();

    setIsLoading(false);
  };

  const namePopover = (name: string) => (
    <Popover>
      <Popover.Body>{name}</Popover.Body>
    </Popover>
  );

  const columns: TableColumn<ProjectInquiryRowModel>[] = [
    {
      name: '#',
      cell: (row, index) => <span>{row.rowNo}</span>,
      selector: (row) => row.rowNo,
      sortable: false,
      width: '30px',
      right: true,
      style: {
        // backgroundColor: '#f7f9fc !important',
      },
    },
    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('productDetailsGeneralInfo.lbl_ProductCode')}
        </span>
      ),
      selector: (row) => row.productCode ?? 'productCode',
      format: (row) => {},
      cell: (row) => (
        <Row className='wrap-ellipsis'>
          {!row.productExists ? (
            <CustomSingleEditableProductCode
              currentUser={currentUser}
              row={row}
              projectId={projectId}
              projectInquiryId={projectDetails.id}
              getDataSource={getDataSource}
              inputString={row.productCode ?? ''}
              isHovered={row.rowNo === hoveredRowId}
            />
          ) : (
            <a
              className='btn-link-bold'
              href={`/buyer/project-product-details/${encodeURIComponent(
                row.productCode ?? ''
              )}`}
            >
              {row.productCode}
            </a>
          )}
        </Row>
      ),
      maxWidth: '130px',
      sortable: false,
    },
    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('projectSettings.lbl_ProductName')}
        </span>
      ),
      selector: (row) => row.productName ?? 'productName',
      sortable: false,
      cell: (row) => (
        <Row className='wrap-ellipsis position-relative'>
          {[row].map((product) => (
            <div className=' d-flex align-items-center'>
              {!product.productExists ? (
                <OverlayTrigger
                  trigger='hover'
                  placement='auto'
                  overlay={namePopover(product.productName ?? '')}
                >
                  <CustomSingleEditableText
                    currentUser={currentUser}
                    row={product}
                    projectId={projectId}
                    projectInquiryId={projectDetails.id}
                    getDataSource={getDataSource}
                    inputString={product.productName ?? ''}
                    isHovered={row.rowNo === hoveredRowId}
                  />
                </OverlayTrigger>
              ) : (
                <span className='wrap-ellipsis'>
                  <OverlayTrigger
                    trigger='hover'
                    placement='auto'
                    overlay={namePopover(product.productName ?? '')}
                  >
                    <span>{product.productName}</span>
                  </OverlayTrigger>
                </span>
              )}
            </div>
          ))}
        </Row>
      ),
      grow: 2,
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
    },

    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('projectSettings.lbl_Quantity')}
        </span>
      ),
      selector: (row) => row.requiredQuantity,
      cell: (row) => (
        <Row className='justify-content-center'>
          <Row className='align-items-center'>
            <CustomSingleRequiredQuantityInput
              currentUser={currentUser}
              row={row}
              projectId={projectId}
              projectInquiryId={projectDetails.id}
              getDataSource={getDataSource}
              setIsRequiredQtyUpdating={setIsRequiredQtyUpdating}
              isRequiredQtyUpdating={isRequiredQtyUpdating}
            />
          </Row>
        </Row>
      ),
      width: '120px',
      sortable: false,
      center: true,
    },

    {
      name: (
        <span style={{ fontSize: 'larger' }} className='text-center'>
          {t('documents.lbl_TargetPrice')}
        </span>
      ),
      selector: (row) => 'targetPrice',
      cell: (row) => (
        <CustomTargetPriceInput
          handleTargetPriceChange={handleTargetPriceChange}
          row={row}
          rowsLength={dataSource.length}
        />
      ),
      sortable: false,
      center: true,
    },
    {
      name: (
        <span style={{ fontSize: 'larger' }} className='text-center'>
          {t('documents.lbl_AllowReplacements')}
          <CustomToggle
            onBulkChange={handleBulkAllowReplacementChange}
            checked={
              dataSource.filter((row) => !row.allowReplacement).length === 0
            }
          />
        </span>
      ),
      selector: (row) => 'row.allowReplacement',
      cell: (row) => (
        <CustomToggle
          row={row}
          onChange={handleAllowReplacementChange}
          checked={row.allowReplacement}
        />
      ),
      sortable: false,
      center: true,
    },

    {
      name: <Trash />,
      sortable: false,
      cell: (row) => (
        <Row className='align-items-center'>
          {row.productCode !== undefined && row.rowNo === hoveredRowId && (
            <Trash
              style={{ cursor: 'pointer' }}
              onClick={() => handleDeleteRow(row)}
              className='icon-ez-red'
            />
          )}
        </Row>
      ),
      width: '40px',
      center: true,
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
      //   maxWidth: '65px',
      //   minWidth: '65px',
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    table: {
      style: {
        backgroundColor: '#f7f9fc !important',
        borderRight: '0 !important',
      },
    },
    headRow: {
      style: {
        // backgroundColor: '#f7f9fc !important',
        borderBottom: '0 !important',
      },
    },
    rows: {
      style: {
        // maxWidth: widthOfGridContainer,
        borderBottom: '0 !important',
        // marginBottom: '5px !important',
        minHeight: '40px !important',
      },
    },
    expanderRow: {
      style: {
        maxWidth: widthOfGridContainer * 0.8 - 120,
        borderBottom: '0 !important',
        marginTop: '-5px !important',
        marginBottom: '5px !important',
        borderTopWidth: '1px',
        borderTopColor: 'lightgray',
        borderTopStyle: 'solid',
        padding: '5px',
        marginLeft: '49px',
      },
    },
    cells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    expanderButton: {
      style: {
        backgroundColor: '#f7f9fc !important',
      },
    },
  };

  const handleRowMouseEnter = (row: ProjectInquiryRowModel) => {
    setHoveredRowId(row.rowNo);
  };
  const handleRowMouseLeave = (row: ProjectInquiryRowModel) => {
    setHoveredRowId(null);
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}

      <DataTable
        className='project-inquiry-grid-table'
        onRowMouseEnter={handleRowMouseEnter}
        onRowMouseLeave={handleRowMouseLeave}
        columns={columns}
        noDataComponent={
          <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
        }
        data={dataSource}
        customStyles={customStyles}
      />

      <ProjectInquiryAddProductForm
        getDataSource={getDataSource}
        projectInquiryId={projectDetails.id}
        projectId={projectId}
      />
    </>
  );
};

import { ImgHTMLAttributes, useEffect, useState } from 'react';

type PropsType = ImgHTMLAttributes<any> & {
  fallback: string;
};

/** Renders the image, on error renders the fallback image */
export const ImageWithFallback = ({ fallback, src, ...props }: PropsType) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);
  const onError = () => setImgSrc(fallback);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <img src={imgSrc ? imgSrc : fallback} alt='' onError={onError} {...props} />
  );
};

import { BuyerApi } from 'api/BuyerApi';
import {
  useGetBestDeliveryDates,
  useGetBestPrices,
  useHandleOrderFromOffer,
  useHandleOrderSelectedPricesFromOffer,
} from 'components/buyer/documents/hooksOfferComparison';
import { OfferComparisonDrawer } from 'components/buyer/documents/OfferComparisonDrawer';
import { OfferOnBehalfDrawer } from 'components/buyer/documents/OfferOnBehalfDrawer';
import {
  getBaseForPercent,
  handleBulkSelectPriceChange,
  handleSelectPriceChange,
} from 'components/buyer/documents/utilsOfferComparison';
import { useGetSupplierCompanyInfo } from 'components/buyer/supplierInfo/hooksSupplierInfo';
import { SupplierProfileCard } from 'components/buyer/supplierInfo/SupplierProfileCard';
import { getSupplierCreditInfo } from 'components/buyer/supplierInfo/utilsSupplierInfo';
import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import {
  OfferComparisonModel,
  OfferComparisonOfferExtendModel,
  OfferComparisonOfferRowExtendedModel,
  OfferComparisonRowModel,
} from 'models/buyer/documents/OfferModels';
import { OrderRowModel } from 'models/buyer/documents/OrderModels';
import { SellerCreditInfoModel } from 'models/buyer/suppliers/SellerCreditInfoModel';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  ArrowDownCircle,
  ArrowRepeat,
  ArrowsCollapse,
  ArrowsExpand,
  ArrowUpCircle,
  BookmarkStar,
  CartFill,
  ChatDots,
  CheckCircleFill,
  ExclamationCircle,
  FileEarmarkXFill,
  HourglassSplit,
  Link45deg,
  PlusCircle,
  XCircleFill,
} from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { GiCherry } from 'react-icons/gi';
import { SiMicrosoftexcel } from 'react-icons/si';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import { handleDownloadOfferExcel } from 'utils/downloadExcelSheet';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { truncateDecimals } from 'utils/truncateDecimals';

type UrlParamsType = {
  dealGroupId: string;
};

type PropTypes = {
  offer: OfferComparisonOfferExtendModel;
  projectId: number;
  projectName: string;
  inquiryRows: OfferComparisonRowModel[];
  columnsFilter: {
    date: boolean;
    price: boolean;
    total: boolean;
    percent: boolean;
  };
  getComparison: () => Promise<void>;
  setOfferComparisonAndUpdateLocalStorage: (
    value: OfferComparisonModel
  ) => void;
  offerComparison: OfferComparisonModel;
  baseForPercentageIsTargetPrice: boolean;
};

export const OfferComparisonSuppliers = ({
  offer,
  columnsFilter,
  projectId,
  projectName,
  inquiryRows,
  getComparison,
  setOfferComparisonAndUpdateLocalStorage,
  offerComparison,
  baseForPercentageIsTargetPrice,
}: PropTypes) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [extendedRows, setExtendedRows] = useState<
    OfferComparisonOfferRowExtendedModel[]
  >([]);
  const [orderRows, setOrderRows] = useState<OrderRowModel[]>([]);
  const [orderedTotal, setOrderedTotal] = useState<number | null>(null);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const canSeePrices = useUserCanSeePrices();
  const validateUnitExists = useValidateUnitExists();
  const getBestPrices = useGetBestPrices();
  const getBestDeliveryDates = useGetBestDeliveryDates();

  const [creditInfo, setCreditInfo] = useState<SellerCreditInfoModel>(
    {} as SellerCreditInfoModel
  );

  const [company, setCompany] = useState<SupplierCompanyModel>(
    {} as SupplierCompanyModel
  );

  const [isExpand, setIsExpand] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const history = useHistory();

  let { dealGroupId } = useParams<UrlParamsType>();

  const { appState } = useContext(AppContext);

  const downloadExcel = () => {
    handleDownloadOfferExcel(
      offer.rows,
      offer.sellerCompanyName,
      `${offer.sellerCompanyName}_OFFER_${offer.code}`
    );
  };

  const handleOrderFromOffer = useHandleOrderFromOffer();
  const handleOrderSelectedPricesFromOffer =
    useHandleOrderSelectedPricesFromOffer();

  useGetSupplierCompanyInfo(setCompany, currentUser, offer.sellerCompanyId);

  useEffect(() => {
    const getOrderRows = async () => {
      if (
        offer.orderId &&
        offer.status !== 'Recalled' &&
        appState.userProfile?.localization?.id
      ) {
        const response = await BuyerApi.getOrder(
          currentUser.companyId,
          offer.orderId,
          appState.userProfile?.localization?.id
        );

        setOrderRows(response.rows);

        let sum = 0;

        response.rows.forEach((row) => {
          sum += row.total ?? 0;
        });

        setOrderedTotal(sum);
      }
    };

    getOrderRows();
  }, [
    appState.userProfile?.localization?.id,
    currentUser.companyId,
    offer.orderId,
    offer.status,
  ]);

  useEffect(() => {
    const getCreditInfo = async (): Promise<void> => {
      offer.sellerCompanyId !== null &&
        offer.sellerCompanyId !== 0 &&
        (await getSupplierCreditInfo(
          setCreditInfo,
          currentUser,
          offer.sellerCompanyId
        ));
    };

    getCreditInfo();
  }, [currentUser, offer.sellerCompanyId]);

  const handleOrder = async (): Promise<void> => {
    await handleOrderFromOffer(
      projectId,
      projectName,
      offer,
      parseInt(dealGroupId),
      setShowDrawer
    );
  };

  const handleOrderSelectedPrices = async (): Promise<void> => {
    if (offer.allowSplitting) {
      await handleOrderSelectedPricesFromOffer(
        projectId,
        projectName,
        offer,
        parseInt(dealGroupId),
        setShowDrawer
      );
    } else {
      await handleOrder();
    }
  };

  const handleDeleteExternalOffer = async () => {
    if (window.confirm(t('documents.btn_DeleteOfferOnBehalf'))) {
      await BuyerApi.deleteOfferOnBehalf(currentUser.companyId, offer.id);
      await getComparison();
    }
  };

  const columns: TableColumn<OfferComparisonOfferRowExtendedModel>[] = [
    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('productDetailsGeneralInfo.lbl_ProductCode')}
        </span>
      ),
      selector: (row) => row.productCode,
      format: (row) => {},
      cell: (row) => (
        <div className='wrap-ellipsis'>
          {row.productId === null || row.isReplaced ? (
            <span className='btn-link-bold-no-link'>
              {row.isReplaced ? row.replacementCode : row.productCode}
            </span>
          ) : (
            <a
              className='btn-link-bold'
              href={`/buyer/project-product-details/${encodeURIComponent(
                row.productCode
              )}`}
            >
              {row.isReplaced ? row.replacementCode : row.productCode}
            </a>
          )}
        </div>
      ),
      maxWidth: '130px',
      omit: !isExpand,
    },
    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('projectSettings.lbl_ProductName')}
        </span>
      ),
      selector: (row) => row.productName,
      sortable: false,
      cell: (row) => (
        <div className='wrap-ellipsis'>
          {row.isReplaced ? row.replacementName : row.productName}
        </div>
      ),
      grow: 1,
      omit: !isExpand,
    },

    {
      name: (
        <div className='d-flex justify-content-between align-items-center'>
          {offer.orderId === null &&
            offer.status !== 'Recalled' &&
            offer.allowSplitting && (
              <Form.Check
                onChange={handleBulkSelectPriceChange(
                  offer,
                  offerComparison,
                  setOfferComparisonAndUpdateLocalStorage
                )}
                checked={
                  offer.rows.filter(
                    (row) =>
                      row.priceWithDiscount && !row.isSelected && !row.isNew
                  ).length === 0
                }
                type='checkbox'
              />
            )}
          <CartFill className='icon-ez-gray ms-1' />
        </div>
      ),
      selector: (row) => row.isReplaced,
      cell: (row) => {
        return (
          <>
            {row.priceWithDiscount &&
            offer.orderId === null &&
            offer.status !== 'Recalled' &&
            offer.allowSplitting ? (
              <Form.Check
                type='checkbox'
                onChange={handleSelectPriceChange(
                  row,
                  offer,
                  offerComparison,
                  setOfferComparisonAndUpdateLocalStorage
                )}
                checked={row.isSelected}
              />
            ) : null}

            {orderRows?.filter((orderRow) => {
              const code = orderRow.isReplaced
                ? orderRow.initialCode
                : orderRow.productCode;
              const name = orderRow.isReplaced
                ? orderRow.initialName
                : orderRow.name;

              return name === row.productName && code === row.productCode;
            }).length > 0 ? (
              <CheckCircleFill className='icon-ez-green' />
            ) : null}
          </>
        );
      },
      width: '50px',
    },

    {
      name: <ExclamationCircle className='icon-ez-gray' />,
      selector: (row) => row.isReplaced,
      cell: (row) => (
        <>
          {row.isReplaced && <ArrowRepeat className='icon-ez-yellow' />}{' '}
          {row.isNew && <PlusCircle className='icon-ez-yellow' />}
        </>
      ),
      width: '30px',

      right: true,
    },

    {
      name: (
        <span className='wrap-elipsis' style={{ fontSize: 'larger' }}>
          {t('documents.lbl_ExpectedDelivery')}
        </span>
      ),
      selector: (row) => row.expectedDelivery ?? 'expectedDelivery',
      cell: (row) => {
        const [bestDate, secondBestDate] = getBestDeliveryDates(
          row,
          offerComparison
        );

        const doesNeedComparing =
          !row.isReplaced &&
          inquiryRows.findIndex(
            (inquiryRow) => inquiryRow.productCode === row.productCode
          ) !== -1;

        const firstChars = row.expectedDelivery?.split(' ')[0];

        return (
          <span
            className={
              doesNeedComparing
                ? row.expectedDelivery === null
                  ? ''
                  : row.expectedDelivery === bestDate
                  ? 'icon-ez-green'
                  : row.expectedDelivery === secondBestDate
                  ? 'icon-ez-yellow'
                  : ''
                : ''
            }
          >
            {row.expectedDelivery && firstChars
              ? isNaN(parseInt(firstChars))
                ? t('common.lbl_Instock')
                : `${parseInt(firstChars)} ${
                    parseInt(firstChars) === 1
                      ? t('documents.lbl_Week')
                      : t('documents.lbl_Weeks')
                  }`
              : '-'}
          </span>
        );
      },
      maxWidth: '150px',
      minWidth: '150px',
      center: true,
      omit: !columnsFilter.date,
    },
    {
      name: (
        <span className='d-flex' style={{ fontSize: 'larger' }}>
          {t('documents.lbl_PriceUnit')}{' '}
        </span>
      ),
      selector: (row) => row.priceWithDiscount,

      cell: (row) => {
        const [bestPrice, secondBestPrice] = getBestPrices(
          row,
          offerComparison
        );

        const baseForPercent = getBaseForPercent(
          row,
          offerComparison,
          baseForPercentageIsTargetPrice,
          bestPrice
        );

        const doesNeedComparing =
          !row.isReplaced &&
          inquiryRows.findIndex(
            (inquiryRow) => inquiryRow.productCode === row.productCode
          ) !== -1;

        const incrementPercent = bestPrice
          ? (
              ((row.priceWithDiscount - baseForPercent) / baseForPercent) *
              100
            ).toFixed(1)
          : null;

        return (
          <div className='d-flex flex-column align-items-end'>
            <strong
              className={
                doesNeedComparing
                  ? row.priceWithDiscount === 0 ||
                    row.priceWithDiscount === null
                    ? ''
                    : row.priceWithDiscount === bestPrice?.priceWithDiscount
                    ? 'icon-ez-green'
                    : row.priceWithDiscount ===
                      secondBestPrice?.priceWithDiscount
                    ? 'icon-ez-yellow'
                    : ''
                  : ''
              }
            >
              <span className='d-flex align-items-center'>
                <span className='me-1'>
                  {canSeePrices(
                    formatMonetaryValue(
                      offer.currencyCode,
                      row.priceWithDiscount,
                      appState,
                      row.decimalPlaces
                    )
                  )}
                </span>
                {row.unit && ' / '}
                <small>{validateUnitExists(row.unit)}</small>
              </span>
            </strong>
            {doesNeedComparing &&
              columnsFilter.percent &&
              row.priceWithDiscount &&
              incrementPercent !== 'Infinity' &&
              incrementPercent !== 'NaN' &&
              incrementPercent && (
                <span>
                  {incrementPercent !== '0.0' ? (
                    <span>
                      {`${incrementPercent}%`}
                      {parseFloat(incrementPercent) < 0 ? (
                        <ArrowDownCircle className='ms-1' />
                      ) : (
                        <ArrowUpCircle className='ms-1' />
                      )}
                    </span>
                  ) : incrementPercent === '0.0' &&
                    !baseForPercentageIsTargetPrice ? (
                    <span>
                      {t('common.lbl_BestPrice')}{' '}
                      <BookmarkStar className='icon-ez-green' />
                    </span>
                  ) : null}
                </span>
              )}
          </div>
        );
      },

      maxWidth: '130px',
      right: true,
      omit: !columnsFilter.price,
    },

    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('projectSettings.lbl_TotalPrice')}
        </span>
      ),
      selector: (row) => row.total,
      cell: (row) => {
        const [bestPrice, secondBestPrice] = getBestPrices(
          row,
          offerComparison
        );

        const doesNeedComparing =
          !row.isReplaced &&
          inquiryRows.findIndex(
            (inquiryRow) => inquiryRow.productCode === row.productCode
          ) !== -1;

        return (
          <div className='d-flex flex-column align-items-end'>
            <strong
              className={
                doesNeedComparing
                  ? row.total === 0 || row.total === null
                    ? ''
                    : row.total === bestPrice?.total
                    ? 'icon-ez-green'
                    : row.total === secondBestPrice?.total
                    ? 'icon-ez-yellow'
                    : ''
                  : ''
              }
            >
              {row.total
                ? canSeePrices(
                    formatMonetaryValue(
                      offer.currencyCode,
                      truncateDecimals(row.total, row.decimalPlaces),
                      appState
                    )
                  )
                : '-'}
            </strong>
          </div>
        );
      },
      maxWidth: '100px',
      omit: !columnsFilter.total,
      right: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    table: {
      style: {
        backgroundColor: '#f7f9fc !important',
        borderRight: '0 !important',
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f7f9fc !important',
        borderBottom: '0 !important',
        paddingRight: '10px',
        paddingLeft: '10px',
      },
    },
    rows: {
      style: {
        // maxWidth: widthOfGridContainer,
        borderBottom: '0 !important',
        marginBottom: '5px !important',
        minHeight: '54.67px !important',
        padding: '10px',
      },
    },
    cells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    expanderButton: {
      style: {
        backgroundColor: '#f7f9fc !important',
      },
    },
  };

  const popover = (
    <Popover className='p-0' id='popover-basic'>
      <div className='col-side-menu-supplier-customer'>
        <SupplierProfileCard company={company} creditInfo={creditInfo} />
      </div>
    </Popover>
  );

  const isToExternalSeller = appState.buyerCurrentSuppliers?.filter(
    (supplier) => supplier.value === offer.sellerCompanyId
  )[0]?.isExternalSeller;

  let cherryPickedTotal = 0;

  offer.rows.forEach((row) => {
    if (row.isSelected) {
      cherryPickedTotal += row.total;
    }
  });

  const allRowsAreSelected =
    offer.rows.filter((row) => row.isSelected || row.isNew).length ===
    offer.rows.length;

  const only2ViewsSelected =
    (Object.values(columnsFilter).filter((value, index) => value).length < 3 &&
      columnsFilter.percent) ||
    Object.values(columnsFilter).filter((value, index) => value).length === 1;

  return (
    <>
      <div
        style={{
          maxWidth: only2ViewsSelected ? (isExpand ? '770px' : '270px') : '',
          minWidth: only2ViewsSelected ? (isExpand ? '770px' : '270px') : '',
        }}
        className={`offer-comparison-supplier-container p-3 ps-0 ${
          isExpand
            ? 'offer-comparison-supplier-table-container-expanded'
            : 'offer-comparison-supplier-table-container'
        } `}
      >
        <Card className='p-0 bg-body'>
          {only2ViewsSelected ? (
            <Card.Header className='py-0'>
              <Row className='align-items-center'>
                {/* <Col md={1}>
                <ArrowsExpand
                  style={{ transform: 'rotate(90deg)' }}
                  className='icon-ez'
                />
              </Col> */}
                <Col
                  className='d-flex align-items-center justify-content-start mx-0 px-0'
                  style={{ maxWidth: '32px' }}
                >
                  {isExpand ? (
                    <ArrowsCollapse
                      onClick={() => setIsExpand(false)}
                      size={25}
                      className='icon-ez me-2 cursor-pointer'
                      style={{ transform: 'rotate(90deg)' }}
                    />
                  ) : (
                    <ArrowsExpand
                      onClick={() => setIsExpand(true)}
                      size={25}
                      className='icon-ez me-2 cursor-pointer'
                      style={{ transform: 'rotate(90deg)' }}
                    />
                  )}
                </Col>
                <Col className='d-flex align-items-center wrap-ellipsis'>
                  <AvatarWithFallback
                    imageUrl={
                      appState?.buyerCurrentSuppliers?.find(
                        (supplier) => supplier.value === offer.sellerCompanyId
                      )?.logo ?? null
                    }
                    initials={
                      offer.sellerCompanyName
                        ? offer.sellerCompanyName[0].toUpperCase()
                        : ''
                    }
                    size={'small'}
                  />{' '}
                </Col>
              </Row>
              <Row>
                <Col className='wrap-ellipsis'>
                  <OverlayTrigger
                    trigger='hover'
                    overlay={popover}
                    placement='right'
                  >
                    <Link
                      className='btn-link-bold wrap-ellipsis'
                      to={`/buyer/${
                        isToExternalSeller
                          ? 'external-supplier-info'
                          : 'supplier-info'
                      }?id=${offer.sellerCompanyId}`}
                      style={{ fontSize: 'larger' }}
                    >
                      {offer.sellerCompanyName}
                    </Link>
                  </OverlayTrigger>
                </Col>
              </Row>

              <Row></Row>
            </Card.Header>
          ) : (
            <Card.Header className='py-0'>
              <Row className='align-items-center flex-nowrap'>
                {/* <Col md={1}>
                <ArrowsExpand
                  style={{ transform: 'rotate(90deg)' }}
                  className='icon-ez'
                />
              </Col> */}
                <Col
                  className='d-flex align-items-center justify-content-between px-0 mx-0 '
                  style={{ maxWidth: '64px' }}
                >
                  {isExpand ? (
                    <ArrowsCollapse
                      onClick={() => setIsExpand(false)}
                      size={25}
                      className='icon-ez me-2 cursor-pointer'
                      style={{ transform: 'rotate(90deg)' }}
                    />
                  ) : (
                    <ArrowsExpand
                      onClick={() => setIsExpand(true)}
                      size={25}
                      className='icon-ez me-2 cursor-pointer'
                      style={{ transform: 'rotate(90deg)' }}
                    />
                  )}
                  <AvatarWithFallback
                    imageUrl={
                      appState?.buyerCurrentSuppliers?.find(
                        (supplier) => supplier.value === offer.sellerCompanyId
                      )?.logo ?? null
                    }
                    initials={
                      offer.sellerCompanyName
                        ? offer.sellerCompanyName[0].toUpperCase()
                        : ''
                    }
                    size={'small'}
                  />
                </Col>
                <Col>
                  <Row>
                    <Col className='wrap-ellipsis' md={8}>
                      <OverlayTrigger
                        trigger='hover'
                        overlay={popover}
                        placement='right'
                      >
                        <Link
                          className='btn-link-bold wrap-ellipsis'
                          to={`/buyer/${
                            isToExternalSeller
                              ? 'external-supplier-info'
                              : 'supplier-info'
                          }?id=${offer.sellerCompanyId}`}
                          style={{ fontSize: 'larger' }}
                        >
                          {offer.sellerCompanyName}
                        </Link>
                      </OverlayTrigger>
                    </Col>
                    <Col className='text-end btn-link-bold wrap-ellipsis'>
                      <Link
                        className='btn-link-bold'
                        to={`/buyer/offer/${offer.id}`}
                      >
                        {offer.code}
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='wrap-ellipsis'>
                      {offer.sentByContactName ?? '-'}
                    </Col>
                    <Col className='text-end wrap-ellipsis'>
                      <HourglassSplit
                        className={
                          offer.status === 'Expired'
                            ? 'icon-ez-red me-1'
                            : 'icon-ez me-1'
                        }
                      />
                      <span
                        className={
                          offer.status === 'Expired'
                            ? 'icon-ez-red'
                            : 'icon-ez-gray'
                        }
                      >
                        {offer.validUntil
                          ? dayjs(offer.validUntil).format('DD-MM-YYYY')
                          : '-'}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Header>
          )}

          <hr className='icon-ez-light-gray p-0 my-0' />

          {only2ViewsSelected ? (
            <Card.Footer className='wrap-ellipsis d-flex flex-column align-items-center justify-content-between py-0'>
              <div className='d-flex flex-column align-items-center'>
                <strong style={{ fontSize: 'larger' }}>
                  {formatMonetaryValue(
                    offer.currencyCode,
                    offer.allowSplitting
                      ? orderedTotal ?? cherryPickedTotal
                      : offer.total,
                    appState
                  )}
                </strong>
                <small>
                  {allRowsAreSelected
                    ? t('common.lbl_Total')
                    : t('documents.lbl_SelectedTotal')}
                </small>
              </div>
              <div className='d-flex justify-content-between'>
                <ButtonGroup>
                  {offer.status === 'NoReply' && !offer.orderId && (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip>{t('projectSettings.lbl_Order')}</Tooltip>
                      }
                    >
                      <Button
                        disabled={
                          offer.rows.filter((row) => row.isSelected).length ===
                            0 && !offer.allowSplitting
                        }
                        onClick={handleOrderSelectedPrices}
                        className='btn btn-eleczap  d-flex align-items-center justify-content-start'
                      >
                        <CartFill size={20} />
                      </Button>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>{t('documents.btn_ExportToExcel')}</Tooltip>
                    }
                  >
                    <Button
                      type={'button'}
                      onClick={downloadExcel}
                      variant='btn btn-eleczap'
                    >
                      <SiMicrosoftexcel size={20} />
                    </Button>
                  </OverlayTrigger>

                  {offer.isExternal && (
                    <OfferOnBehalfDrawer
                      rows={offerComparison.rows}
                      projectName={offerComparison.projectName}
                      dealGroupId={parseInt(dealGroupId)}
                      offers={offerComparison.offers}
                      getComparison={getComparison}
                      offerId={offer.id}
                      hideLabel
                    />
                  )}

                  {offer.isExternal && (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip>
                          {t('documents.btn_DeleteOfferOnBehalf')}
                        </Tooltip>
                      }
                    >
                      <Button
                        type={'button'}
                        className=''
                        onClick={handleDeleteExternalOffer}
                        variant='btn btn-danger'
                      >
                        <FileEarmarkXFill size={20} />
                      </Button>
                    </OverlayTrigger>
                  )}
                </ButtonGroup>

                {!offer.allowSplitting && (
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        {t('documents.lbl_CherryPickingNotAllowed')}
                      </Tooltip>
                    }
                  >
                    <div className='d-flex justify-content-center'>
                      <Switch
                        checked={offer.allowSplitting}
                        onChange={(checked) => null}
                        onColor='#1b9ad2'
                        className='react-switch'
                        id='icon-switch'
                        checkedHandleIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 15,
                              paddingRight: 2,
                            }}
                          >
                            <GiCherry className='icon-ez' />
                          </div>
                        }
                        uncheckedHandleIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 15,
                              paddingRight: 2,
                            }}
                          >
                            <GiCherry className='icon-ez-red' />
                          </div>
                        }
                      />
                    </div>
                  </OverlayTrigger>
                )}
              </div>

              {offer.status === 'OrderMade' ? (
                <h3>
                  <span
                    className='btn-link-bold cursor-pointer'
                    onClick={() => setShowDrawer(true)}
                  >
                    {t('documents.lbl_CheckOrder')} <Link45deg />
                  </span>
                </h3>
              ) : (
                <div style={{ height: '32px' }} />
              )}

              {offer.status === 'Recalled' ? (
                <h3 className='icon-ez-yellow'>
                  {t('documents.lbl_OfferRecalled')}
                </h3>
              ) : (
                <div />
              )}

              <OfferComparisonDrawer
                getComparison={getComparison}
                show={showDrawer}
                setShow={setShowDrawer}
                orderId={offer.orderId}
              />
            </Card.Footer>
          ) : (
            <Card.Footer className='wrap-ellipsis d-flex align-items-center justify-content-between py-0'>
              <ButtonGroup>
                {offer.status === 'NoReply' &&
                  !offer.orderId &&
                  !(!offer.isExternalSeller && offer.isExternal) && (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip>{t('projectSettings.lbl_Order')}</Tooltip>
                      }
                    >
                      <Button
                        disabled={
                          offer.rows.filter((row) => row.isSelected).length ===
                            0 || !offer.allowSplitting
                        }
                        onClick={handleOrderSelectedPrices}
                        className='btn btn-eleczap  d-flex align-items-center justify-content-start'
                      >
                        <CartFill size={20} />
                      </Button>
                    </OverlayTrigger>
                  )}

                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip>{t('documents.btn_ExportToExcel')}</Tooltip>
                  }
                >
                  <Button
                    type={'button'}
                    className=''
                    onClick={downloadExcel}
                    variant='btn btn-eleczap'
                  >
                    <SiMicrosoftexcel size={20} />
                  </Button>
                </OverlayTrigger>

                {offer.isExternal && (
                  <OfferOnBehalfDrawer
                    rows={offerComparison.rows}
                    projectName={offerComparison.projectName}
                    dealGroupId={parseInt(dealGroupId)}
                    offers={offerComparison.offers}
                    getComparison={getComparison}
                    offerId={offer.id}
                    hideLabel
                  />
                )}

                {offer.isExternal && (
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        {t('documents.btn_DeleteOfferOnBehalf')}
                      </Tooltip>
                    }
                  >
                    <Button
                      type={'button'}
                      className=''
                      onClick={handleDeleteExternalOffer}
                      variant='btn btn-danger'
                    >
                      <FileEarmarkXFill size={20} />
                    </Button>
                  </OverlayTrigger>
                )}
              </ButtonGroup>

              {!offer.allowSplitting && (
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip>
                      {t('documents.lbl_CherryPickingNotAllowed')}
                    </Tooltip>
                  }
                >
                  <div className='d-flex justify-content-center'>
                    <Switch
                      checked={offer.allowSplitting}
                      onChange={(checked) => null}
                      onColor='#1b9ad2'
                      className='react-switch'
                      id='icon-switch'
                      checkedHandleIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        >
                          <GiCherry className='icon-ez' />
                        </div>
                      }
                      uncheckedHandleIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        >
                          <GiCherry className='icon-ez-gray' />
                        </div>
                      }
                    />
                  </div>
                </OverlayTrigger>
              )}

              {offer.status === 'OrderMade' ? (
                <div className='d-flex align-items-start'>
                  <CheckCircleFill
                    className='icon-ez-green me-2 mt-1'
                    size={20}
                  />
                  <h3>
                    <span
                      className='btn-link-bold cursor-pointer'
                      onClick={() => setShowDrawer(true)}
                    >
                      {t('documents.lbl_CheckOrder')} <Link45deg />
                    </span>
                  </h3>
                </div>
              ) : null}

              {offer.status === 'Recalled' ? (
                <div className='d-flex align-items-start'>
                  <XCircleFill className='icon-ez-yellow me-2 mt-1' size={20} />
                  <h3 className='icon-ez-yellow'>
                    {t('documents.lbl_OfferRecalled')}
                  </h3>
                </div>
              ) : (
                <div />
              )}

              <div></div>

              <OfferComparisonDrawer
                getComparison={getComparison}
                show={showDrawer}
                setShow={setShowDrawer}
                orderId={offer.orderId}
              />

              <div className='d-flex flex-column'>
                <div className='d-flex flex-column align-items-end'>
                  <strong style={{ fontSize: 'larger' }}>
                    {formatMonetaryValue(
                      offer.currencyCode,
                      offer.allowSplitting
                        ? orderedTotal ?? cherryPickedTotal
                        : offer.total,
                      appState
                    )}
                  </strong>
                  <small>
                    {' '}
                    {allRowsAreSelected
                      ? t('common.lbl_Total')
                      : t('documents.lbl_SelectedTotal')}
                  </small>
                </div>
              </div>
            </Card.Footer>
          )}

          <Card.Body className='bg-body px-0 pb-0'>
            <DataTable
              data={offer.rows}
              columns={columns}
              customStyles={customStyles}
            />
          </Card.Body>

          <Card.Footer className='mt-3'>
            <div className='d-flex'>
              <ChatDots className='icon-ez-gray me-2 mt-1' />{' '}
              <p>{offer.comment}</p>
            </div>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
};

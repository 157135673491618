import { SellerApi } from 'api/SellerApi';
import { EditableImageUploadModal } from 'components/shared/controls/EditableImageWithFallback/EditableImageUploadModal';
import {
  ProductBreadcrumb,
  ProductBreadcrumbItem,
} from 'components/shared/products/ProductBreadcrumb';
import 'components/shared/products/ProductClasses/ProductClasses.css';
import { ProductClassesList } from 'components/shared/products/ProductClasses/ProductClassesList';
import { ProductClassesQuickMenu } from 'components/shared/products/ProductClasses/ProductClassesQuickMenu';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { SellerProductClassWithSubclassesModel } from 'models/seller/product/SellerProductClassWithSubclassesModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

type UrlParamsType = {
  classId: string;
};

export const ProductSubclasses = () => {
  let { classId } = useParams<UrlParamsType>();
  const { t } = useTranslation('components');

  const [productClasses, setproductClasses] = useState<
    SellerProductClassWithSubclassesModel[]
  >([]);
  const [quickMenuItems, setquickMenuItems] = useState<
    SellerProductClassWithSubclassesModel[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [productBreadCrumbItems, setproductBreadCrumbItems] = useState<
    ProductBreadcrumbItem[]
  >([]);

  const [imageProductClassId, setImageProductClassId] = useState<number>(-1);
  const [showImageUploadModal, setShowImageUploadModal] =
    useState<boolean>(false);
  const [refreshClasses, setRefreshClasses] = useState<boolean>(false);

  const [mounted, setMounted] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const handleProductClassImageUpload = (productClassId: number) => {
    setImageProductClassId(productClassId);
    setShowImageUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setShowImageUploadModal(false);
  };

  const handleImageUpload = (file: File) => {
    const data = new FormData();
    data.append('file', file);
    return SellerApi.uploadProductClassImage(
      currentUser.companyId,
      imageProductClassId,
      data
    ).then((x) => {
      setRefreshClasses(!refreshClasses);
    });
  };

  const selectedCustomerId = appState.sellerCurrentCustomerId;
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  useEffect(() => {
    let didCancel = false;

    const getQuickMenuItems = async () => {
      const level = 1;
      const productClassInfo = await SellerApi.getAllProductClasses(
        currentUser.companyId,
        selectedCustomerId,
        level,
        null,
        userLocaleId
      );
      if (!didCancel) {
        if (level === 1) {
          const firstLevelClasses = productClassInfo.productClasses.filter(
            (pc) => pc.level === 1
          );
          setquickMenuItems(firstLevelClasses);
        }
      }
      setIsLoading(false);
    };

    getQuickMenuItems();

    return () => {
      didCancel = true;
    };
  }, [currentUser.companyId, selectedCustomerId, userLocaleId, refreshClasses]);

  useEffect(() => {
    const loadOtherLevels = async () => {
      if (classId !== null) {
        const productClassInfo = await SellerApi.getAllProductClasses(
          currentUser.companyId,
          selectedCustomerId,
          null,
          Number(classId),
          userLocaleId
        );
        setproductClasses(productClassInfo.productClasses);

        const breadCrumbItems =
          productClassInfo.parents.map<ProductBreadcrumbItem>((c) => ({
            name: c.name,
            classId: c.id,
            classLevel: c.level,
            linkType: c.linkType,
            code: c.code,
          }));

        setproductBreadCrumbItems(breadCrumbItems);
      }
      setIsLoading(false);
    };

    loadOtherLevels();
  }, [
    classId,
    currentUser.companyId,
    selectedCustomerId,
    userLocaleId,
    refreshClasses,
  ]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const BuyerBreadcrumbNode = (
    <>
      <ProductBreadcrumb
        items={productBreadCrumbItems}
        productClassesRoute='/seller/product-classes/'
        productSubClassesRoute='/seller/product-subclasses/'
      />
      <Card.Header className='p-2 mt-2'>
        <ProductClassesQuickMenu
          handleProductClassImageUpload={handleProductClassImageUpload}
          productBreadCrumbItems={productBreadCrumbItems}
          defaultCollapse
          items={quickMenuItems}
          productSubClassesRoute='/seller/product-classes'
          favoriteProductsRoute='/seller/favorite-products'
          viewType='Seller'
        />
      </Card.Header>
    </>
  );

  return !isLoading ? (
    <>
      {mounted &&
        ReactDOM.createPortal(
          BuyerBreadcrumbNode,
          document &&
            document.getElementsByClassName('fixed-portal-receiver')[0]
        )}

      <div className='container-fluid px-2 pb-2'>
        <EditableImageUploadModal
          show={showImageUploadModal}
          handleCloseUploadModal={handleCloseUploadModal}
          uploadProductImageRequirementsText={t(
            'editableImageWithFallback.lbl_UploadProductImageRequirements',
            { maxFileSizeKb: 5000 }
          )}
          maxFileSizekB={5000}
          accept={'.png, .jpg, .jpeg, .gif, .bmp, .tif, .tiff'}
          handleImageUpload={handleImageUpload}
        ></EditableImageUploadModal>
        <Card className='card-wrapper'>
          {/* Add static quick menu */}

          <ProductClassesList
            handleProductClassImageUpload={handleProductClassImageUpload}
            imageReadOnly={false}
            items={productClasses}
            productListRoute={'/seller/product-classes'}
            productSubClassesRoute='/seller/product-subclasses'
          />
        </Card>
      </div>
    </>
  ) : null;
};

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { StocksMyModel } from 'models/shared/stocksMy/StocksMyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { CountryModel } from 'models/shared/country/CountryModel';
import { useGetAvailableCountries } from 'hooks/useGetAvailabeCountries';
import { useFormikHookForStocksMyUpdate } from './hooksEditStocks';
import { PencilSquare } from 'react-bootstrap-icons';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';

type PropTypes = {
  setStocks: (values: StocksMyModel[]) => void;
  currentUser: LoginResponseUserModel;
  isDisabled: boolean;
  stock: StocksMyModel;
  setStocksSharedToPartners: (values: StocksSharedToPartnersModel[]) => void;
  t: any;
};

export const EditStock = ({
  setStocks,
  currentUser,
  isDisabled,
  stock,
  t,
  setStocksSharedToPartners,
}: PropTypes) => {
  const [show, setShow] = useState(false);
  const [countries, setCountries] = useState<CountryModel[]>([]);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useGetAvailableCountries(setCountries);

  const formik = useFormikHookForStocksMyUpdate(
    currentUser,
    setStocks,
    setShow,
    stock,
    t,
    setStocksSharedToPartners
  );

  return (
    <>
      <Button variant='btn btn-eleczap-noBorder' disabled={isDisabled} onClick={handleShow}>
        <PencilSquare />
      </Button>

      <Modal size='sm' show={show} onHide={handleClose} id='sellerSetupStock'>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('stockSettings.hd_EditStock')} {stock.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-1' controlId='exampleForm.ControlInput1'>
              <Form.Label>{t('stockSettings.lbl_ChooseStockId')}</Form.Label>
              <Form.Control
                required
                onChange={formik.handleChange}
                value={formik.values.code}
                name='code'
                type='input'
                isInvalid={formik.touched.code && !!formik.errors.code}
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.code}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>{t('stockSettings.lbl_DisplayedName')}</Form.Label>
              <Form.Control
                required
                onChange={formik.handleChange}
                value={formik.values.name}
                name='name'
                type='input'
                isInvalid={formik.touched.name && !!formik.errors.name}
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>{t('addressSettings.lbl_AddressLine1')}</Form.Label>
              <Form.Control
                name='addressLine1'
                value={formik.values.addressLine1}
                onChange={formik.handleChange}
                type='text'
                isInvalid={
                  formik.touched.addressLine1 && !!formik.errors.addressLine1
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.addressLine1}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>{t('addressSettings.lbl_AddressLine2')}</Form.Label>
              <Form.Control
                name='addressLine2'
                value={formik.values.addressLine2}
                onChange={formik.handleChange}
                type='text'
                isInvalid={
                  formik.touched.addressLine2 && !!formik.errors.addressLine2
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.addressLine2}
              </Form.Control.Feedback>
            </Form.Group>
            <Row>
              <Form.Group
                as={Col}
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>{t('addressSettings.lbl_PostalCode')}</Form.Label>
                <Form.Control
                  name='postalCode'
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  type='text'
                  required
                  isInvalid={
                    formik.touched.postalCode && !!formik.errors.postalCode
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.postalCode}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>{t('addressSettings.lbl_City')}</Form.Label>
                <Form.Control
                  name='city'
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  type='text'
                  required
                  isInvalid={formik.touched.city && !!formik.errors.city}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.city}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                as={Col}
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>{t('addressSettings.lbl_State')}</Form.Label>
                <Form.Control
                  name='state'
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  type='text'
                  required
                  isInvalid={formik.touched.state && !!formik.errors.state}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.state}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>{t('addressSettings.lbl_Country')}</Form.Label>
                <Form.Select
                  name='country.code'
                  required
                  value={
                    formik.values.country !== null
                      ? formik.values.country.code
                      : 'AD'
                  }
                  onChange={formik.handleChange}
                >
                  {countries.map((country, index) => (
                    <option key={index} value={country.code}>
                      {country.shortName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>{t('stockSettings.lbl_AdditionalInfo')}</Form.Label>
              <Form.Control
                name='additionalInfo'
                value={formik.values.additionalInfo}
                onChange={formik.handleChange}
                type='text'
                required
                isInvalid={
                  formik.touched.additionalInfo &&
                  !!formik.errors.additionalInfo
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.additionalInfo}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Check
              name='showArriving'
              type='switch'
              onChange={formik.handleChange}
              label='Show arriving'
              defaultChecked={formik.values.showArriving}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='btn btn-eleczap' onClick={formik.handleSubmit}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const formatMonetaryValueWithoutDecimals = (
  currencyCode: string | null,
  value: number | null,
  appState: any
): string => {
  const cultureName = appState.userProfile?.localization?.cultureName;

  if (value === null) return '-';

  return Intl.NumberFormat(cultureName, {
    style: 'currency',
    currency: currencyCode || 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 5,
  }).format(value);
};

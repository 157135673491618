import { ProjectRowWithSupplier } from 'models/buyer/project/ProjectModels';
import { ProjectOrderRowUpdateModel } from 'models/buyer/projectOrder/ProjectOrderModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { updateProjectOrderRows } from 'pages/buyer/ProjectOrder/utilsProjectOrder';
import { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';

type PropTypes = {
  inputString: string;
  currentUser: LoginResponseUserModel;
  row: ProjectRowWithSupplier;
  projectId: number;
  projectOrderId: number;
  getDataSource: () => Promise<void>;
  isHovered: boolean;
};

export const CustomEditableText = ({
  inputString,
  currentUser,
  row,
  projectId,
  projectOrderId,
  getDataSource,
  isHovered,
}: PropTypes) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(inputString);
  }, [inputString]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSaveName = async () => {
    setIsEditing(false);

    let clonedRow = { ...row };

    const rowUpdate: ProjectOrderRowUpdateModel = {
      productId: clonedRow.productId,
      requiredQuantity: clonedRow.requiredQuantity,
      supplierQuantity: clonedRow.supplierQuantity,
      productFreeName: value,
      productCode: clonedRow.productCode,
      updateRequiredQuantity: false,
      updateSupplierQuantity: false,
      id: clonedRow.id,
    };

    await updateProjectOrderRows(
      [rowUpdate],
      currentUser,
      projectId,
      projectOrderId
    );
    await getDataSource();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSaveName();
    }
  };

  return isEditing ? (
    <Form.Control
      onKeyDown={handleKeyDown}
      onBlur={handleSaveName}
      autoFocus
      onChange={handleInputChange}
      value={value}
      className='borderless border-0 wrap-ellipsis'
    />
  ) : (
    <span
      className={
        row.isDisabled ? 'icon-ez-light-gray wrap-ellipsis' : 'wrap-ellipsis'
      }
      onClick={() => setIsEditing(true)}
    >
      {value}
      {isHovered && (
        <PencilSquare
          className='icon-ez ms-2 cursor-pointer'
          onClick={() => setIsEditing(true)}
        />
      )}
    </span>
  );
};

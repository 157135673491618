import { BuyerApi } from 'api/BuyerApi';
import { BuyerDealsTag } from 'components/buyer/dealshome/BuyerDealsTag';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { DealTagModel } from 'models/buyer/deals/DealsModels';
import { DealGroupModel } from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useState } from 'react';
import { Badge, Card, FormControl, InputGroup } from 'react-bootstrap';
import { TagFill } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

type PropTypes = {
  currentUser: LoginResponseUserModel;
  dealsDetail: DealGroupModel;
  type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice';
  t: any;
};

export const BuyerDealsTagInput = ({
  currentUser,
  dealsDetail,
  type,
  t,
}: PropTypes) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastTags, setLastTags] = useState<DealTagModel[]>([]);
  const [tagString, setTagString] = useState<string>('');

  const { appState } = useContext(AppContext);

  const handleClick = async (): Promise<void> => {
    setIsEditing(true);

    const response = await BuyerApi.getLastTags(currentUser.companyId);

    setLastTags(response);
  };

  const handleBlur = (): void => {
    setTimeout(() => setIsEditing(false), 400);
  };

  const handleKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      if (tagString.length <= 25) {
        setIsLoading(true);
        await BuyerApi.tags.createDealGroupTag(
          currentUser.companyId,
          dealsDetail.id,
          {
            name: tagString,
            type,
          }
        );

        await appState.getProjects();
        setIsLoading(false);
      } else toast.error(t('common.err_ValueMax25'));

      setIsEditing(false);
    }
  };

  const handleUsedClick = async (tag: DealTagModel) => {
    setIsLoading(true);
    await BuyerApi.tags.updateDealGroupTag(
      currentUser.companyId,
      dealsDetail.id,
      tag.id,
      type
    );

    await appState.getProjects();
    setIsLoading(false);
    setIsEditing(false);
  };

  const tagType =
    type === 'Inquiry'
      ? 'inquiryGroupTags'
      : type === 'Order'
      ? 'orderGroupTags'
      : type === 'Offer'
      ? 'offerGroupTags'
      : 'invoiceGroupTags';

  return dealsDetail.id !== 0 ? (
    <>
      {isLoading && <FullScreenLoader />}
      <TagFill
        onClick={handleClick}
        size={15}
        id={`recent-tags-toggle${dealsDetail.id}`}
        style={{ cursor: 'pointer' }}
        className='icon-ez tags-toggle'
      />

      {dealsDetail[tagType] !== null &&
        dealsDetail[tagType].map((tag: DealTagModel) => (
          <BuyerDealsTag
            currentUser={currentUser}
            dealsDetail={dealsDetail}
            tag={tag}
            type={type}
          />
        ))}
      {isEditing ? (
        <div
          id={`recent-tags-container${dealsDetail.id}`}
          className='recent-tags-container '
        >
          <Card className='recent-tags'>
            <Card.Header>
              <InputGroup className='border-1'>
                <FormControl
                  className='mb-2 borderless border-none'
                  value={tagString}
                  onChange={(event) => setTagString(event.currentTarget.value)}
                  onKeyPress={handleKeyPress}
                  autoFocus
                  onBlur={handleBlur}
                />
                {/* <InputGroup.Text className='btn' id='search-addon'>
                  <PlusCircleFill className='icon-ez' />
                </InputGroup.Text> */}
              </InputGroup>
            </Card.Header>
            <Card.Body>
              <div className='text-center recent-tags-header-text'>
                {t('common.hd_RecentlyUsed')}
              </div>

              {lastTags !== null &&
                lastTags.map((tag) => (
                  <Badge
                    onClick={isLoading ? () => {} : () => handleUsedClick(tag)}
                    pill
                    className={
                      tag.dealIds.includes(dealsDetail.id)
                        ? 'deals-pill-inverse m-1 cursor-pointer'
                        : 'deals-pill m-1 cursor-pointer'
                    }
                  >
                    {tag.name}
                  </Badge>
                ))}
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div />
      )}
    </>
  ) : null;
};

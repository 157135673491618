import { BuyerApi } from 'api/BuyerApi';
import { ProjectImportProductsErrorModal } from 'components/buyer/project/ProjectImportProductsErrorModal/ProjectImportProductsErrorModal';
import { AppContext } from 'contexts/user/AppContext';
import {
  ProjectImportProductsErrorModel,
  ProjectImportProductsModel,
  ProjectModel,
} from 'models/buyer/project/ProjectModels';
import { ProjectOrderModel } from 'models/buyer/projectOrder/ProjectOrderModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { FileEarmark } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  projectDetails: ProjectModel | ProjectOrderModel;
  getDataSource: () => Promise<void>;
  projectId: number;
};

export const ProjectInquiryImportProductsModal = ({
  projectDetails,
  getDataSource,
  projectId,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [products, setProducts] = useState<string>('');
  const [errors, setErrors] = useState<ProjectImportProductsErrorModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUnknownProductsActive, setIsUnknownProductsActive] =
    useState<boolean>(false);
  const [unfoundCodesExists, setUnfoundCodesExists] = useState<boolean>(false);
  const [onlyQuantitiesErrorExists, setOnlyQuantitiesErrorExists] =
    useState<boolean>(true);

  const { appState } = useContext(AppContext);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  /* This peice of code is Inquiry.tsx as well. unify it in seperate file */
  const checkIfOnlyErrorsAreInQuantities = useCallback((): void => {
    setOnlyQuantitiesErrorExists(true);
    errors.forEach((error) => {
      if (error.errorCode !== 'QuantityMissing')
        setOnlyQuantitiesErrorExists(false);
    });
  }, [errors]);

  const checkIfUnfoundCodeExists = useCallback((): void => {
    errors.forEach((error) => {
      if (!unfoundCodesExists && error.errorCode === 'ProductNotFound')
        setUnfoundCodesExists(true);
    });
  }, [errors, unfoundCodesExists]);

  useEffect(() => {
    checkIfOnlyErrorsAreInQuantities();
    checkIfUnfoundCodeExists();
  }, [checkIfOnlyErrorsAreInQuantities, checkIfUnfoundCodeExists]);

  // useEffect(() => {
  //   getAllSuppliers(currentUser, handleSetSuppliers);
  // }, []);

  const { t } = useTranslation(['components', 'server']);

  const handleImport = async (
    cleanedProducts?: string,
    latestIsUnknownActive?: boolean
  ) => {
    try {
      setIsLoading(true);
      const importProducts: ProjectImportProductsModel =
        {} as ProjectImportProductsModel;

      importProducts.productsText = cleanedProducts ?? products;

      if (importProducts.productsText.length > 0) {
        importProducts.saveUnknownProducts =
          latestIsUnknownActive !== undefined
            ? latestIsUnknownActive
            : isUnknownProductsActive;

        const response = await BuyerApi.projectInquiry.importProductFromText(
          currentUser.companyId,
          projectId,
          projectDetails.id,
          importProducts
        );

        if (response !== null) setErrors(response);

        await getDataSource();
        if (response.length < 1) {
          handleClose();
          setShowErrors(false);
          setIsLoading(false);
          setProducts('');
          toast.success(t('projectSettings.inf_ProductAdded'));
        } else {
          setShowErrors(true);
          setIsLoading(false);
        }
      } else {
        setShowErrors(false);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  // const handleSellerChange = (event: FormEvent<HTMLSelectElement>) => {
  //   setSellerCompanyId(Number(event.currentTarget.value));
  // };

  return (
    <>
      <Button
        id='project-view-btn-import-products'
        onClick={handleShow}
        className='btn btn-eleczap'
      >
        <FileEarmark /> {t('projectImport.hd_ImportRowsByText')}
      </Button>

      {!showErrors ? (
        <Modal show={show} onHide={handleClose} id='sellerSetupStock'>
          <Modal.Header closeButton={!isLoading}>
            <Modal.Title>{t('projectSettings.hd_ImportProducts')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {t('projectSettings.inf_ImportProductsPart1')}
              <br />
              <br />
              {t('projectSettings.inf_ImportProductsPart2')}
              <br />
              <br />
              {t('projectSettings.inf_ImportProductsPart3')}
              <br />
              <br />
              {t('projectSettings.inf_ImportProductsPart4')}
            </p>
            <Form>
              <strong>
                {t('projectSettings.inf_Code')} <small>|</small>{' '}
                {t('projectSettings.plh_Qty')} <small>|</small>{' '}
                {t('projectSettings.inf_Name')}{' '}
                <small>({t('projectSettings.inf_ProductNameImport')})</small>
              </strong>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Control
                  required
                  onChange={(event) => setProducts(event?.target.value)}
                  value={products}
                  placeholder={t('projectSettings.plh_ImportProducts')}
                  name='products'
                  as='textarea'
                  rows={4}
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type='switch'
                  label={t('projectSettings.lbl_SaveUnknownCodes')}
                  checked={isUnknownProductsActive}
                  onChange={(event) =>
                    setIsUnknownProductsActive(event.target.checked)
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='secondary'
              onClick={handleClose}
            >
              {t('common.btn_Close')}
            </Button>
            <Button
              disabled={isLoading}
              variant='btn btn-eleczap'
              onClick={() => handleImport()}
            >
              {t('common.btn_Ok')}
              {isLoading && (
                <Spinner className='ms-1' size='sm' animation='border' />
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <ProjectImportProductsErrorModal
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          errors={errors}
          handleImport={handleImport}
          products={products}
          setProducts={setProducts}
          isUnknownProductsActive={isUnknownProductsActive}
          setIsUnknownProductsActive={setIsUnknownProductsActive}
          unfoundCodesExists={unfoundCodesExists}
          onlyQuantitiesErrorExists={onlyQuantitiesErrorExists}
        />
      )}
    </>
  );
};

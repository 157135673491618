import { CompanyModel } from 'models/shared/company/CompanyModel';
import { CompanyUpdateModel } from 'models/shared/company/CompanyUpdateModel';
import { CreateUserInvitationModel } from 'models/shared/user/CreateUserInvitationModel';
import { UserModel } from 'models/shared/user/UserModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { UserResendInvitationModel } from 'models/shared/user/UserResendInvitationModel';
import { UserUpdateModel } from 'models/shared/user/UserUpdateModel';
import { UserUpdatePermissionsModel } from 'models/shared/user/UserUpdatePermissionsModel';
import { CompanyApiClient } from './CompanyApiClient';

export const CompanyApi = {
  /* Companies */
  getCompanyProfileImage: (companyId: number): Promise<string> =>
    CompanyApiClient.get(`/${companyId}Logo`),

  uploadCompanyProfileImage: (
    companyId: number,
    file: FormData
  ): Promise<string> => CompanyApiClient.postCreate(`/${companyId}/Logo`, file),

  deleteCompanyProfileImage: (companyId: number): Promise<string> =>
    CompanyApiClient.delete(`/${companyId}/Logo`),

  getCompanies: (companyId: number): Promise<CompanyModel> =>
    CompanyApiClient.get(`${companyId}`),

  updateCompanies: (
    companyId: number,
    company: CompanyUpdateModel
  ): Promise<void> => CompanyApiClient.put(`${companyId}`, company),

  /* Company users */
  getUsers: (
    companyId: number,
    page?: number | undefined,
    searchString?: string | undefined,
    rowsPerPage?: number | undefined,
    sortColumn?: string | undefined
  ): Promise<UserModel> =>
    CompanyApiClient.get(
      `${companyId}/Users?SearchString=${
        searchString !== undefined ? searchString : ''
      }&PageSize=${rowsPerPage !== undefined ? rowsPerPage : 10}&PageNumber=${
        page !== undefined ? page : 1
      }&OrderBy=${sortColumn !== undefined ? sortColumn : 'name'}`
    ),

  updateUser: (
    companyId: number,
    user: UserModelById,
    userUpdate: UserUpdateModel
  ): Promise<void> =>
    CompanyApiClient.put(`${companyId}/Users/${user.id}`, userUpdate),

  updateUserPermissions: (
    companyId: number,
    user: UserModelById,
    userUpdate: UserUpdatePermissionsModel
  ): Promise<void> =>
    CompanyApiClient.put(
      `${companyId}/Users/${user.id}/UpdatePermissions`,
      userUpdate
    ),

  createUserInvitation: (
    companyId: number,
    invitation: CreateUserInvitationModel
  ): Promise<void> => CompanyApiClient.post(`${companyId}/Users`, invitation),

  resendUserInvitation: (
    companyId: number,
    invitation: UserResendInvitationModel
  ): Promise<void> =>
    CompanyApiClient.post(`${companyId}/Users/ResendInvitation`, invitation),

  deleteUser: (companyId: number, id: number): Promise<void> =>
    CompanyApiClient.delete(`${companyId}/Users/${id}`),

  /* Attachments */
  addNewAttachment: (
    companyId: number,
    projectId: number,
    file: FormData
  ): Promise<number> =>
    CompanyApiClient.post(
      `${companyId}/projects/${projectId}/Attachments`,
      file
    ),

  deleteAttachment: (
    companyId: number,
    projectId: number,
    attachementId: number
  ): Promise<number> =>
    CompanyApiClient.delete(
      `${companyId}/projects/${projectId}/Attachments/${attachementId}`
    ),

  addNewInquiryAttachment: (
    companyId: number,
    projectId: number,
    file: FormData
  ): Promise<number> =>
    CompanyApiClient.post(
      `${companyId}/projects/${projectId}/Attachments/PostInquiryProjectAttachment`,
      file
    ),

  deleteInquiryAttachment: (
    companyId: number,
    projectId: number,
    projectInquiryId: number,
    attachementId: number
  ): Promise<number> =>
    CompanyApiClient.delete(
      `${companyId}/projects/${projectId}/Attachments/${attachementId}/InquiryProject/${projectInquiryId}`
    ),

  addNewOrderAttachment: (
    companyId: number,
    projectId: number,
    file: FormData
  ): Promise<number> =>
    CompanyApiClient.post(
      `${companyId}/projects/${projectId}/Attachments/PostOrderProjectAttachment`,
      file
    ),

  deleteOrderAttachment: (
    companyId: number,
    projectId: number,
    projectOrderId: number,
    attachementId: number
  ): Promise<number> =>
    CompanyApiClient.delete(
      `${companyId}/projects/${projectId}/Attachments/${attachementId}/OrderProject/${projectOrderId}`
    ),

  addNewDocumentAttachment: (
    companyId: number,
    projectId: number,
    file: FormData
  ): Promise<number> =>
    CompanyApiClient.post(
      `${companyId}/projects/${projectId}/Attachments/PostDocumentAttachment`,
      file
    ),

  deleteDocumentAttachment: (
    companyId: number,
    projectId: number,
    attachmentId: number,
    dealId: number,
    type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice'
  ): Promise<number> =>
    CompanyApiClient.delete(
      `${companyId}/projects/${projectId}/Attachments/${attachmentId}/Deal/${dealId}/${type}`
    ),
};

import { BuyerApi } from 'api/BuyerApi';
import { BuyerRecentCurrentProjects } from 'layout/BuyerRecentCurrentProjects';
import { CurrentProjects } from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { UserCurrentProjectModel } from 'models/shared/user/UserCurrentProjectModel';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Nav } from 'react-bootstrap';
import { CaretDownFill, FolderFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type PropsType = {
  currentProject: UserCurrentProjectModel | null;
  windowIsNarrow?: boolean;
};

export const BuyerCurrentProjectButton = ({
  currentProject,
  windowIsNarrow,
}: PropsType) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [recentProjects, setRecentProjects] = useState<CurrentProjects[]>([]);

  const { t } = useTranslation('components');

  const navigate = useHistory();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleProjectsClick = async (): Promise<void> => {
    setIsEditing(!isEditing);

    const getRecentProjects = async () => {
      const response = await BuyerApi.getRecentCurrentProjects(
        currentUser.companyId,
        currentUser.id
      );

      setRecentProjects(response);
    };

    if (!isEditing) {
      await getRecentProjects();
    }
  };

  return !windowIsNarrow ? (
    <>
      <Nav.Item
        className={
          windowIsNarrow
            ? 'ms-4 text-end position-relative'
            : 'ms-4 text-center position-relative'
        }
      >
        <span className='buyer-nav-item d-flex flex-column align-items-center '>
          <span className='buyer-nav-item-icon-with-text d-flex flex-row align-items-end'>
            <div className='current-project-name-icon pe-1 '>
              <h3 className='wrap-ellipsis'>
                <FolderFill className='icon-ez' />{' '}
                {currentProject ? currentProject.name : t('common.lbl_AddNew')}
              </h3>
            </div>
            <div
              style={{ minHeight: '35px', maxHeight: '35px' }}
              onClick={handleProjectsClick}
              className='d-flex align-items-center cursor-pointer current-project-dropdown-icon'
            >
              <CaretDownFill size={18} className='ps-1' />
            </div>
            {isEditing ? (
              <BuyerRecentCurrentProjects
                setIsEditing={setIsEditing}
                recentProjects={recentProjects}
                setRecentProjects={setRecentProjects}
              />
            ) : (
              <div />
            )}
          </span>
          {/* <span className='buyer-nav-item-label'>
            {t('projectSettings.lbl_CurrentProject')}
          </span> */}
        </span>
      </Nav.Item>
    </>
  ) : (
    <>
      <Nav.Item
        style={{ cursor: 'pointer', marginTop: '18px' }}
        className={windowIsNarrow ? 'ms-4 text-end' : 'ms-4 text-center'}
      >
        <span className='buyer-nav-item'>
          <span>{currentProject ? currentProject.name : '-'}</span>
        </span>
      </Nav.Item>
    </>
  );
};

import { BuyerFavoriteProductSwitch } from 'components/buyer/products/BuyerFavoriteProductSwitch';
import { CustomProductListQtyInput } from 'components/shared/CustomInputs/CustomProductListQtyInput';
import { AppContext } from 'contexts/user/AppContext';
import { useGetFormattedPrice } from 'hooks/useGetFormattedPrice';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import NoProductImage from 'media/EZ_new_notext_border_grey.png';
import { RowMOQPriceModel } from 'models/buyer/common/RowMOQPriceModel';
import { RowStockLevelModel } from 'models/buyer/common/RowStockLevelModel';
import { GroupedProductModel } from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductModel } from 'models/shared/product/ProductModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getMOQColumns } from 'utils/getMOQColumns';
import { getStockLevelColumns } from 'utils/getStockLevelColumns';

import { ImageWithFallback } from 'components/shared/ImageWithFallback';
import './styles.css';

type PropsType = {
  item: GroupedProductModel;
  productDetailRoute: string;
  favoriteStockExists: boolean;
  favouriteSellerId: number;
  isPricesLoading?: boolean | false;
};

export const BuyerProductListBoxGrouped = ({
  item,
  productDetailRoute,
  favoriteStockExists,
  favouriteSellerId,
  isPricesLoading,
}: PropsType) => {
  const { t } = useTranslation('components');
  const [expandCard, setExpandCard] = useState<boolean>(false);
  const canSeePrices = useUserCanSeePrices();
  const validateUnitExists = useValidateUnitExists();
  const getFormattedPrice = useGetFormattedPrice();
  const [checkedProduct, setCheckedProduct] = useState<ProductModel | null>(
    null
  );

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState, appContextDispatch } = useContext(AppContext);

  useEffect(() => {
    if (item !== null && item.products.length === 1) {
      setCheckedProduct(item.products[0]);
    }
  }, [item]);

  let productListClasses = ['card-wrapper', 'product-class-list'];

  const heightOfOnePriceStep = 5;

  /* Check if item actually has any favourite stock. Necessary for search results */
  const favStock = item.products[0]?.stockLevels.filter(
    (stock) => stock.isFavoriteStock
  );
  const itemHasFavoriteStock = favStock?.length > 0;

  const moqColumns = getMOQColumns(getFormattedPrice, t);

  const stockLevelColumns = getStockLevelColumns(
    t,
    favoriteStockExists,
    itemHasFavoriteStock
  );

  const stockPopover = (stockLevels: RowStockLevelModel[]) => (
    <Popover style={{ minWidth: '500px' }} id='popover-stockLevels'>
      <Popover.Header as='h3'>{t('projectSettings.lbl_Stocks')}</Popover.Header>
      <Popover.Body>
        <DataTable columns={stockLevelColumns} data={stockLevels} />
      </Popover.Body>
    </Popover>
  );

  const moqPopover = (moqPrices: RowMOQPriceModel[]) => (
    <Popover id='popover-moqPrices'>
      <Popover.Body>
        <DataTable columns={moqColumns} data={moqPrices} />
      </Popover.Body>
    </Popover>
  );

  const getTotalStockLevel = (row: RowStockLevelModel[]) => {
    let totalStockLevel = 0;
    row.forEach((x) => {
      totalStockLevel += x.inStock;
    });
    return totalStockLevel;
  };

  const getTotalArriving = (row: RowStockLevelModel[]) => {
    let totalArriving = 0;
    row.forEach((x) => {
      totalArriving += x.arriving;
    });
    return totalArriving;
  };

  const namePopover = (name: string) => (
    <Popover>
      <Popover.Body>{name}</Popover.Body>
    </Popover>
  );

  return item.products.filter(
    (x) =>
      appState.buyerCurrentSuppliers &&
      appState.buyerCurrentSuppliers?.findIndex(
        (y) => y.isSelected && y.value === x.sellerCompanyId && !y.isMarketplace
      ) > -1
  ).length > 0 ||
    item.products.filter(
      (x) =>
        appState.buyerCurrentSuppliers &&
        appState.buyerCurrentSuppliers?.findIndex((supplier) =>
          supplier.subSellers?.some(
            (subSeller) =>
              subSeller.value === x.sellerCompanyId && subSeller.isSelected
          )
        ) > -1
    ).length > 0 ? (
    <Card
      className='p-3 mx-2 my-2 position-relative product-grid-item'
      style={{}}
      onMouseEnter={() => setExpandCard(true)}
      onMouseLeave={() => setExpandCard(false)}
    >
      {/* <Card.Img
        style={{ maxHeight: '15rem', maxWidth: '15rem', margin: '0 auto' }}
        className='p-3'
        src={
          isPricesLoading
            ? NoProductImage
            : checkedProduct
            ? checkedProduct.image
            : NoProductImage
        }
      ></Card.Img> */}

      <div style={{ height: '15rem', maxWidth: '15rem', margin: '0 auto' }}>
        <ImageWithFallback
          alt='Product class image'
          className='product-class-grid-image p-3'
          src={checkedProduct ? checkedProduct.image : item.products[0]?.image}
          fallback={NoProductImage}
        />
      </div>
      <Card.Title
        style={{ left: 0, top: 0, width: '100%' }}
        className='px-3 pb-0 pt-2 position-absolute d-flex justify-content-start'
      >
        <div className='flex-grow-1'>
          <h3 className='text-start mb-0 '>
            <Link
              style={{ cursor: 'pointer' }}
              to={`${productDetailRoute}/${encodeURIComponent(
                item.products[0]?.code
              )}`}
              className='btn-link-bold'
            >
              {item.products[0]?.code}
            </Link>
          </h3>
          <small>{item.products[0].manufacturerName}</small>
        </div>

        <div
          style={{ maxWidth: '105px', minWidth: '105px' }}
          className='align-self-start d-flex justify-content-end product-list-col-add-to-project mt-0'
        >
          <CustomProductListQtyInput product={item} />
        </div>
      </Card.Title>

      <Card.Body className='p-0'>
        <div className='d-flex flex-row justify-content-between align-items-center'>
          <div></div>
          <div className='d-flex flex-row'>
            <div className='ms-4'>
              <small>{t('common.lbl_Price')}</small>
            </div>

            {expandCard && (
              <div
                style={{
                  transitionDuration: '0.2s',
                  width: expandCard ? '60px' : 0,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                className={expandCard ? 'ms-4 text-end' : ''}
              >
                <small>{t('common.lbl_Instock')}</small>
              </div>
            )}
          </div>
        </div>

        {item.products.map((product, index) => {
          return (
            (appState.buyerCurrentSuppliers?.findIndex(
              (supplier) =>
                supplier.value === product.sellerCompanyId &&
                supplier.isSelected
            ) !== -1 ||
              appState.buyerCurrentSuppliers?.findIndex((supplier) =>
                supplier.subSellers?.some(
                  (subSeller) =>
                    subSeller.value === product.sellerCompanyId &&
                    subSeller.isSelected
                )
              ) !== -1) && (
              <>
                <div className='d-flex flex-row justify-content-between align-items-center pb-0'>
                  <div className='d-flex align-items-center flex-row wrap-ellipsis'>
                    <div className='pb-0 mb-0'>
                      <BuyerFavoriteProductSwitch
                        isFavorite={product.isFavorite}
                        companyId={currentUser.companyId}
                        userId={currentUser.id}
                        productId={product.id}
                        sellerId={product.sellerCompanyId}
                      />
                    </div>
                    {/* <div className='p-2 ps-1 ms-1 me-0 pb-0 mb-0 align-self-center'>
                      <ImageWithZoom
                        src={product.image}
                        width={40}
                        height={40}
                        alt='ProductImage'
                        zoomedMaxHeight={300}
                        zoomedMaxWidth={300}
                      ></ImageWithZoom>
                    </div> */}
                    <div className='p-2 pb-0 mb-0 align-self-center wrap-ellipsis'>
                      <OverlayTrigger
                        trigger='hover'
                        placement='auto'
                        overlay={namePopover(product.name)}
                      >
                        <strong className=''>{product.name}</strong>
                      </OverlayTrigger>

                      <br />
                      <small>
                        {product.sellerCompanyShortName ??
                          product.sellerCompanyName}
                      </small>
                    </div>
                  </div>
                  <div className='d-flex flex-row justify-content-end'>
                    <div className='text-end'>
                      {canSeePrices(
                        product.moqPrices.length > 1 ? (
                          <OverlayTrigger
                            trigger='hover'
                            placement='auto'
                            overlay={moqPopover(product.moqPrices)}
                          >
                            <strong className='btn-link-bold'>
                              {getFormattedPrice(product.moqPrices[0])}
                            </strong>
                          </OverlayTrigger>
                        ) : (
                          <strong>
                            {product.moqPrices[0]
                              ? getFormattedPrice(product.moqPrices[0])
                              : '-'}
                          </strong>
                        )
                      )}
                    </div>

                    <div
                      style={{
                        transitionDuration: '0.2s',
                        width: expandCard ? '60px' : 0,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                      className={expandCard ? 'ms-4 text-end' : ''}
                    >
                      {isPricesLoading ? (
                        <Spinner animation='border' size='sm' />
                      ) : (
                        <>
                          {product.stockLevels?.length > 1 ? (
                            <OverlayTrigger
                              trigger='hover'
                              placement='auto'
                              overlay={stockPopover(product.stockLevels)}
                            >
                              <strong className='btn-link-bold'>
                                {favoriteStockExists
                                  ? product.stockLevels[0].inStock
                                  : getTotalStockLevel(product.stockLevels)}
                                <small>
                                  {' '}
                                  {validateUnitExists(product.unit)}
                                </small>
                              </strong>
                            </OverlayTrigger>
                          ) : product.stockLevels[0]?.inStock ? (
                            <strong>
                              {getTotalStockLevel(product.stockLevels)}
                              <small> {validateUnitExists(product.unit)}</small>
                            </strong>
                          ) : (
                            <strong>-</strong>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <hr className='ms-5 mt-0 mb-1' />
              </>
            )
          );
        })}
      </Card.Body>
    </Card>
  ) : (
    <></>
  );
};

import { SellerApi } from 'api/SellerApi';
import { DealModel, DealTagModel } from 'models/buyer/deals/DealsModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useState } from 'react';
import { Badge } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';

type PropTypes = {
  currentUser: LoginResponseUserModel;
  getProjects: (loadFromStart?: boolean) => Promise<void>;
  dealsDetail: DealModel;
  tag: DealTagModel;
};

export const SellerDealsTag = ({
  currentUser,
  dealsDetail,
  getProjects,
  tag,
}: PropTypes) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  const handleDeleteTag = async (tag: DealTagModel) => {
    setIsLoading(true);
    await SellerApi.deleteTag(currentUser.companyId, tag.id, dealsDetail.id);

    await getProjects(true);
    setIsLoading(false);
  };

  return (
    <Badge
      onClick={isLoading ? () => {} : () => handleDeleteTag(tag)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      pill
      className={
        isHover
          ? 'deals-pill-inverse mx-1 cursor-pointer'
          : 'deals-pill mx-1 cursor-pointer'
      }
    >
      {tag.name}{' '}
      {isHover ? (
        <X className='p-0' style={{ marginTop: '-2.8px' }} />
      ) : (
        <span style={{ height: '1em' }} />
      )}
    </Badge>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { ExternalSellerImportSuppliersResponseModel } from 'models/buyer/suppliers/ExternalSupplierModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import { FileEarmarkSpreadsheet } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  refreshData: () => Promise<void>;
  projectId: number;
  projectOrderId: number;
};

export const ProjectOrderImportExcel = ({
  refreshData,
  projectId,
  projectOrderId,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [file, setFile] = useState<File | null>(null);

  const [importComplete, setImportComplete] = useState<boolean>(false);

  const [importedDataResponse, setImportedDataResponse] =
    useState<ExternalSellerImportSuppliersResponseModel>(
      {} as ExternalSellerImportSuppliersResponseModel
    );

  const [showErrors, setShowErrors] = useState<boolean>(false);

  const [dragging, setDragging] = useState(false);

  const { t } = useTranslation(['components', 'server']);
  const { appState } = useContext(AppContext);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const resetModal = () => {
    setIsLoading(false);
    setFile(null);

    setImportComplete(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    resetModal();
  };

  const handleImportPricelist = async () => {
    setIsLoading(true);

    const data = new FormData();

    if (file === null || appState?.userProfile?.localization?.id === undefined)
      return null;

    data.append('File', file);

    try {
      const response = await BuyerApi.projectOrder.importProductFromExcel(
        currentUser.companyId,
        projectId,
        projectOrderId,
        data
      );

      // if (response.errors === null) {
      //   setImportComplete(true);
      //   await refreshSuppliers();
      //   setIsLoading(false);
      // } else {
      //   toast.error(t('projectImport.err_ImportAborted'));
      //   setShowErrors(true);
      //   setIsLoading(false);
      // }

      // setImportedDataResponse(response);

      await refreshData();
      setFile(null);
      setIsLoading(false);
      setShow(false);
    } catch (e) {
      setIsLoading(false);
      setFile(null);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        setFile(selectedFile);
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    file && setFile(file);
  };

  const errorsNode = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col>
            <h5>#</h5>
          </Col>
          {/* <Col>
            <h5>{t('companySettings.lbl_CompanyName')}</h5>
          </Col> */}
          <Col>
            <h5>{t('common.lbl_Error')}</h5>
          </Col>
        </Row>
        {importedDataResponse.errors?.map((error, index) => (
          <Row className='border' key={index}>
            <Col>{error.rowNo}</Col>
            {/* <Col>{error.companyName}</Col> */}
            <Col>{t(`error.${error.errorCode}`, { ns: 'server' })}</Col>
          </Row>
        ))}
      </>
    );
  };

  const handleErrorsClose = () => {
    setShowErrors(false);
  };

  return (
    <>
      <Button
        variant='btn btn-eleczap'
        className='cursor-pointer'
        onClick={handleShow}
      >
        <FileEarmarkSpreadsheet /> {t('projectImport.hd_ImportRowsFromExcel')}
      </Button>

      <Modal show={showErrors} onHide={handleErrorsClose}>
        <Modal.Header>
          <Modal.Title>{t('projectImport.err_ImportAborted')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorsNode()}</Modal.Body>
        <Button variant='btn btn-eleczap' onClick={handleErrorsClose}>
          {t('common.btn_Close')}
        </Button>
      </Modal>

      <Modal
        show={show}
        size='sm'
        id='add-external-supplier-pricelist'
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {importComplete
              ? t('projectImport.hd_ImportComplete')
              : t('projectImport.hd_ImportRowsFromExcel')}
          </Modal.Title>
        </Modal.Header>

        {importComplete ? (
          <Modal.Body className='p-4'>
            <p>
              {t('projectImport.inf_ImportComplete', {
                countNew: importedDataResponse.newCompaniesCount,
              })}
            </p>
          </Modal.Body>
        ) : (
          <Modal.Body
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className='p-4'
          >
            <p>{t('projectImport.inf_ImportRowsPart1')}</p>

            <p>{t('projectImport.inf_ImportRowsPart2')}</p>

            <a href='/templates/ElecZap_OrderProjectImport_EN.xlsx'>
              <Button className='mb-3' variant='btn btn-eleczap'>
                {t('externalSuppliersImport.btn_DownloadTemplate')}
              </Button>
            </a>

            <Form noValidate>
              <FormGroup className='mb-2' controlId='validationFile'>
                <Form.Label className='required'>
                  {t('projectImport.lbl_SelectRowsFile')}
                </Form.Label>
                <Form.Control
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  accept='.xlsx'
                  type='file'
                  name='file'
                  id='file'
                  ref={fileInputRef}
                />
                <br />
                <div className='d-flex align-items-center'>
                  <Button
                    variant='btn btn-eleczap'
                    onClick={() => fileInputRef.current?.click()}
                  >
                    {t('common.btn_ChooseFile')}
                  </Button>
                  <span className='ms-2'>{file?.name}</span>
                </div>
              </FormGroup>
            </Form>
          </Modal.Body>
        )}

        {importComplete ? (
          <Modal.Footer>
            <Button variant='btn btn-eleczap' onClick={handleClose}>
              {t('common.btn_Close')}
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='secondary'
              onClick={handleClose}
            >
              {t('common.btn_Cancel')}
            </Button>
            <Button
              disabled={file === null || isLoading}
              variant='btn btn-eleczap'
              onClick={handleImportPricelist}
            >
              {t('common.btn_Ok')}{' '}
              {isLoading && <Spinner animation='border' size='sm' />}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

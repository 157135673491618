import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { XCircle } from "react-bootstrap-icons";
import { useState } from "react";
import { BuyersCompaniesAndUsersDataModel } from "models/seller/customers/BuyersCompaniesAndUsersModel";
import { useTranslation } from "react-i18next";
import { SellerApi } from "api/SellerApi";
import { useAuthUser } from "react-auth-kit";
import { LoginResponseUserModel } from "models/identity/LoginResponseUserModel";

type PropsType = {
  company: BuyersCompaniesAndUsersDataModel;
  statusChanged: (id: number, isActive: boolean) => Promise<void>;
};

export const SellerBuyerActivate = ({ company, statusChanged }: PropsType) => {
  const { t } = useTranslation("components");

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const enableBuyer = async () => {
    let result = await SellerApi.setBuyerActive(
      currentUser.companyId,
      company.id,
      true
    );
    if (result !== undefined) {
      setShow(false);
      statusChanged(company.id, true);
    }
  };

  //Modal

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="btn btn-eleczap-noBorder" onClick={handleShow}>
        <XCircle className="status-icon-danger" size={20}></XCircle>
      </Button>
      <Modal size="sm" show={show} onHide={handleClose} id="buyerUserActivate">
        <Modal.Header closeButton>
          <Modal.Title>{t("customers.lbl_ActivateBuyer")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("customers.inf_activate_confirm1", { company: company.name })}
          <br />
          {t("customers.inf_activate_confirm2")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("common.btn_Close")}
          </Button>
          <Button variant="btn btn-eleczap" onClick={enableBuyer}>
            {t("common.btn_Ok")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

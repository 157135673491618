import { ProjectImportProductsErrorModel } from 'models/buyer/project/ProjectModels';
import { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
  errors: ProjectImportProductsErrorModel[];
  handleImport?: (
    cleanedProducts?: string,
    latestIsUnknownActive?: boolean
  ) => Promise<void>;
  handleImportFromDocument?: (
    cleanedProducts: string,
    importTo: 'Inquiry' | 'Order'
  ) => Promise<void>;
  products: string;
  setProducts: (value: string) => void;
  isUnknownProductsActive: boolean;
  setIsUnknownProductsActive?: (value: boolean) => void;
  onlyQuantitiesErrorExists: boolean;
  unfoundCodesExists: boolean;
  importTo?: 'Inquiry' | 'Order' | null;
};

export const ProjectImportProductsErrorModal = ({
  showErrors,
  setShowErrors,
  errors,
  handleImport,
  handleImportFromDocument,
  products,
  setProducts,
  isUnknownProductsActive,
  setIsUnknownProductsActive,
  onlyQuantitiesErrorExists,
  unfoundCodesExists,
  importTo,
}: PropTypes) => {
  const handleClose = () => {
    setShowErrors(false);
  };

  const { t } = useTranslation(['components', 'server']);

  const [
    isLoadingForProceedWithUnfoundCodes,
    setIsLoadingForProceedWithUnfoundCodes,
  ] = useState<boolean>(false);
  const [isLoadingForSkip, setIsLoadingForSkip] = useState<boolean>(false);

  let prepareTextArray: string[] = [];

  errors.forEach((error) => {
    prepareTextArray.push(
      `${t(`error.${error.errorCode}`, { ns: 'server' })} - ${
        error.productCode
      }`
    );
  });

  const handleRemoveErrorsAndImport = async (
    latestIsUnknownActive?: boolean
  ) => {
    latestIsUnknownActive !== undefined
      ? setIsLoadingForProceedWithUnfoundCodes(true)
      : setIsLoadingForSkip(true);
    let copyOfProducts = products.slice().toUpperCase();
    let copyOfProductsWithoutUpperCase = products.slice();

    let copyOfProductsArray = copyOfProducts.split('\n');
    let copyOfProductsWithoutUpperCaseArray =
      copyOfProductsWithoutUpperCase.split('\n');

    const unknownIsActiveInLocalScope =
      latestIsUnknownActive !== undefined
        ? latestIsUnknownActive
        : isUnknownProductsActive;

    errors.forEach((code) => {
      let indexFromReverse = copyOfProductsArray.length;

      while (indexFromReverse--) {
        if (unknownIsActiveInLocalScope) {
          if (
            code.errorCode !== 'ProductNotFound' &&
            copyOfProductsArray[indexFromReverse]
              .toUpperCase()
              .includes(code.productCode)
          ) {
            copyOfProductsArray.splice(indexFromReverse, 1);
            copyOfProductsWithoutUpperCaseArray.splice(indexFromReverse, 1);
          }
        } else {
          if (
            copyOfProductsArray[indexFromReverse]
              .toUpperCase()
              .includes(code.productCode)
          ) {
            copyOfProductsArray.splice(indexFromReverse, 1);
            copyOfProductsWithoutUpperCaseArray.splice(indexFromReverse, 1);
          }
        }
      }
    });

    setProducts(copyOfProductsWithoutUpperCaseArray.join('\n'));

    latestIsUnknownActive
      ? handleImport &&
        (await handleImport(
          copyOfProductsWithoutUpperCaseArray.join('\n'),
          latestIsUnknownActive
        ))
      : handleImport &&
        (await handleImport(copyOfProductsWithoutUpperCaseArray.join('\n')));

    handleImportFromDocument &&
      importTo &&
      handleImportFromDocument(
        copyOfProductsWithoutUpperCaseArray.join('\n'),
        importTo
      );

    setIsLoadingForProceedWithUnfoundCodes(false);
    setIsLoadingForSkip(false);
  };

  const handleProceedWithUnfoundCodes = async () => {
    await handleRemoveErrorsAndImport(true);
    setIsUnknownProductsActive && setIsUnknownProductsActive(true);
  };

  return (
    <Modal show={showErrors} onHide={handleClose} id='sellerSetupStockError'>
      <Modal.Header
        closeButton={!(isLoadingForProceedWithUnfoundCodes || isLoadingForSkip)}
      >
        <Modal.Title>{t('projectSettings.hd_ImportProducts')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('projectSettings.inf_ImportProductsError', {
            rows: errors.length,
          })}
        </p>

        <Form>
          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
            <Form.Control
              required
              readOnly
              value={prepareTextArray.join('\n')}
              placeholder={t('projectSettings.plh_ImportProducts')}
              name='products'
              as='textarea'
              rows={4}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isLoadingForProceedWithUnfoundCodes || isLoadingForSkip}
          variant='secondary'
          onClick={handleClose}
        >
          {onlyQuantitiesErrorExists
            ? t('projectSettings.btn_AddQuantities')
            : t('common.btn_Back')}
        </Button>
        <Button
          disabled={isLoadingForProceedWithUnfoundCodes || isLoadingForSkip}
          variant='btn btn-eleczap'
          onClick={() => handleRemoveErrorsAndImport()}
        >
          {t('common.btn_Skip')}
          {isLoadingForSkip && (
            <Spinner className='ms-1' size='sm' animation='border' />
          )}
        </Button>
        {unfoundCodesExists && !isUnknownProductsActive && (
          <Button
            disabled={isLoadingForProceedWithUnfoundCodes || isLoadingForSkip}
            variant='info'
            onClick={handleProceedWithUnfoundCodes}
          >
            {t('projectSettings.btn_ProceedWithUnfoundCodes')}
            {isLoadingForProceedWithUnfoundCodes && (
              <Spinner className='ms-1' size='sm' animation='border' />
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

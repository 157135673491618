import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { SellerStocksModel } from 'models/buyer/suppliers/SellerStocksModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback, useContext } from 'react';
import { useAuthUser } from 'react-auth-kit';

export const useCheckIfFavoriteStockExists = () => {
  const authUser = useAuthUser();
  const user = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const checkIfFavoriteStockExists = useCallback(async () => {
    if (appState.buyerCurrentSupplierId) {
      const stocks: SellerStocksModel[] = await BuyerApi.getSellerStocks(
        user.companyId,
        appState.buyerCurrentSupplierId
      );
      const favStock = stocks.filter((stock) => stock.isFavorite);

      return favStock.length > 0;
    }

    return false;
  }, [appState.buyerCurrentSupplierId, user.companyId]);

  return checkIfFavoriteStockExists;
};

import { EditableImageWithFallbackButtons } from 'components/shared/controls/EditableImageWithFallback/EditableImageWithFallbackButtons';
import NoProductImage from 'media/ElecZap_new_logo_grayscale_cropped.png';
import { BuyerProductClassModel } from 'models/buyer/product/BuyerProductClassModel';
import { SellerProductClassModel } from 'models/seller/product/SellerProductClassModel';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type PropsType = {
  item: SellerProductClassModel | BuyerProductClassModel;
  productListRoute: string;
  handleProductClassImageUpload: (productClassId: number) => void;
  imageReadonly: boolean;
};

export const ProductClassWithProductsItem = ({
  item,
  productListRoute,
  handleProductClassImageUpload ,
  imageReadonly
}: PropsType) => {
  const { t } = useTranslation('components');
  const productsUrl = `${productListRoute}/${item.id}/products`;

  return (
    <>
      <Link to={productsUrl}>
      <EditableImageWithFallbackButtons
              readonly={imageReadonly}
              noDelete={true}
              alt={item.code}
              className='product-class-image p-3 mb-2'
              src={item.image}
              fallback={NoProductImage}
              handleImageUpload={() => handleProductClassImageUpload(item.id)}
            />
      </Link>
      <Link className='product-class-link' to={productsUrl}>
        {item.name}
      </Link>
      <Link className='product-class-qty' to={productsUrl}>
        {`${item.productCount} ${t('common.lbl_Products')}`}
      </Link>
    </>
  );
};

import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { MouseEvent } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export const ProductsPageGuide = () => {
  const { t } = useTranslation(['components', 'introGuides']);

  const handleStartTour = (event: MouseEvent) => {
    introJs()
      .setOptions({
        scrollToElement: false,
        nextLabel: t('common.btn_Next', { ns: 'components' }),
        prevLabel: t('common.btn_Back', { ns: 'components' }),
        steps: [
          {
            title: t('productsPageGuide.hd_WelcomeToEleczap', {
              ns: 'introGuides',
            }),
            intro: t('productsPageGuide.hd_WelcomeToEleczap', {
              ns: 'introGuides',
            }),
            tooltipClass: 'introjs-initial-tooltip',
          },
          {
            element: '#productsClasses',
            position: 'bottom',
            title: t('productsPageGuide.hd_ProductsClasses', {
              ns: 'introGuides',
            }),
            intro: t('productsPageGuide.inf_ProductsClasses', {
              ns: 'introGuides',
            }),
          },
          {
            element: '#productSearch',
            title: t('productsPageGuide.hd_Search', {
              ns: 'introGuides',
            }),
            intro: t('productsPageGuide.inf_Search', {
              ns: 'introGuides',
            }),
          },
          {
            element: '.buyer-current-project-order-inquiry',
            title: t('productsPageGuide.hd_CurrentProjectInquiryOrder', {
              ns: 'introGuides',
            }),
            intro: t('productsPageGuide.inf_CurrentProjectInquiryOrder', {
              ns: 'introGuides',
            }),
          },
          {
            element: '#projectsPage',
            title: t('productsPageGuide.hd_Projects', {
              ns: 'introGuides',
            }),
            intro: t('productsPageGuide.inf_Projects', {
              ns: 'introGuides',
            }),
          },

          {
            element: '#suppliers-filter',
            title: t('productsPageGuide.hd_SupplierFilter', {
              ns: 'introGuides',
            }),
            intro: t('productsPageGuide.inf_SupplierFilter', {
              ns: 'introGuides',
            }),
          },

          {
            element: '#suppliersPage',
            title: t('productsPageGuide.hd_Suppliers', {
              ns: 'introGuides',
            }),
            intro: t('productsPageGuide.inf_Suppliers', {
              ns: 'introGuides',
            }),
          },

          {
            element: '#buyerSettings',
            title: t('productsPageGuide.hd_Settings', {
              ns: 'introGuides',
            }),
            intro: t('productsPageGuide.inf_Settings', {
              ns: 'introGuides',
            }),
          },
        ],
      })
      .start();
  };

  return (
    <div>
      <QuestionCircle
        onClick={handleStartTour}
        size={40}
        className='btn-floating-help me-3'
      />
    </div>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { useFormik } from 'formik';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  AddressModel,
  AddressModelById,
} from 'models/shared/address/AddressModel';
import { AddressUpdateModel } from 'models/shared/address/AddressUpdateModel';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { getAllAddresses } from '../utilsDeliveryAddresses';

/* Formik init */
export const useFormikHookForAddress = (
  currentUser: LoginResponseUserModel,
  setAddresses: (values: AddressModel) => void,
  setShow: (values: boolean) => void,
  t: any,
  setNewDeliverAddressAsSelected?: (value: number) => Promise<void> | undefined,
  setAsOptionShow?: (value: boolean) => void | undefined
): any => {
  const handleSubmit = async (values: AddressUpdateModel): Promise<void> => {
    toast.dismiss();
    const location = await BuyerApi.createDeliveryAddresses(
      currentUser.companyId,
      currentUser.id,
      values
    );
    toast.info(t('addressSettings.inf_DeliveryAddressSaved'));

    const locationStringArr = location.split('/');
    const locationId = locationStringArr[locationStringArr.length - 1];

    setNewDeliverAddressAsSelected !== undefined &&
      setNewDeliverAddressAsSelected(parseInt(locationId));
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    addressLine1: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    addressLine2: yup.string().nullable().max(50, t('common.err_ValueMax50')),

    postalCode: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    city: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    state: yup.string().nullable().max(50, t('common.err_ValueMax50')),

    countryCode: yup.string(),
  });

  const initialValues: AddressModelById = {
    id: 0,
    name: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
    state: '',
    country: {
      code: 'AD',
      shortName: 'Andorra',
    },
    isVisible: true,
    isDefault: false,
    fullAddress: '',
  };

  const formik = useFormik<AddressModelById>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedAddress: AddressUpdateModel = {
        name: values.name,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        postalCode: values.postalCode,
        city: values.city,
        state: values.state,
        countryCode: values.country.code,
        isVisible: values.isVisible,
        isDefault: values.isDefault,
      };
      const newLocationUrl = await handleSubmit(updatedAddress);
      setNewDeliverAddressAsSelected === undefined &&
        (await getAllAddresses(currentUser, setAddresses));
      setShow(false);
      setAsOptionShow !== undefined && setAsOptionShow(false);
      formik.resetForm();
      formik.setSubmitting(false);

      return newLocationUrl;
    },
  });

  return formik;
};

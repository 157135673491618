import { BuyerNotifications } from 'components/buyer/settings/buyerNotifications/BuyerNotifications';
import { useGetCompanyDetails } from 'components/shared/settings/Company/hooksCompany';
import {
  handleCompanyProfileImageDelete,
  handleCompanyProfileImageUpload,
} from 'components/shared/settings/Company/utilsCompany';
import { UserProfileImageUpload } from 'components/shared/settings/UserProfileImageUpload';
import { Users } from 'components/shared/settings/Users/Users/Users';
import { AppContext } from 'contexts/user/AppContext';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { CompanyModel } from 'models/shared/company/CompanyModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Row, Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Api } from '../../api/Api';
import DeliveryAddresses from '../../components/buyer/settings/deliveryAddresses/DeliveryAddresses/DeliveryAddresses';
import { Account } from '../../components/shared/settings/Account';
import { Company } from '../../components/shared/settings/Company/Company';

import './BuyerSettings.css';

export const BuyerSettings = () => {
  const { t } = useTranslation('components');

  const [user, setUser] = useState<UserModelById>({} as UserModelById);
  const [company, setCompany] = useState<CompanyModel>({} as CompanyModel);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [activeKey, setActiveKey] = useState<string>('BuyerUserAccount');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);
  const setUserProfile = useSetUserProfile();

  useGetCompanyDetails(currentUser, setCompany);

  /* User details form initial data */
  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);
      const user = await Api.getUser(
        currentUser.companyId,
        currentUser.id,
        appState.buyerCurrentSupplierId
      );
      setUser(user);
      setIsLoading(false);
    };
    getUser();
  }, [currentUser.companyId, currentUser.id, appState.buyerCurrentSupplierId]);

  const handleProfileImageUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    toast.dismiss();

    if (event.target.files) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        if (selectedFile.size > 500 * 1024) {
          toast.error(t('account.err_ProfileImageSizeTooBig'));
          event.target.value = '';
          return;
        }
        const data = new FormData();
        data.append('file', selectedFile);
        const imageLocation = await Api.uploadProfileImage(
          currentUser.companyId,
          currentUser.id,
          data
        );
        const updatedUser = await setUserProfile(
          appState.buyerCurrentSupplierId || null
        );
        setUser(updatedUser);

        setProfileImage(imageLocation);

        toast.info(t('account.inf_ProfileImageUploaded'));
      }
    }
  };

  const handleProfileImageDelete = async () => {
    toast.dismiss();

    if (profileImage) {
      await Api.deleteProfileImage(currentUser.companyId, currentUser.id);
      setProfileImage('');

      const updatedUser = await setUserProfile(
        appState.buyerCurrentSupplierId || null
      );
      setUser(updatedUser);

      toast.info(t('account.inf_ProfileImageDeleted'));
    }
  };

  const handleCompanyImageUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    await handleCompanyProfileImageUpload(
      currentUser,
      t,
      setProfileImage,
      event
    );
  };

  const handleCompanyImageDelete = async () => {
    await handleCompanyProfileImageDelete(
      currentUser,
      t,
      setProfileImage,
      profileImage
    );
  };

  const isCompanyRelatedPage: boolean = activeKey.includes('Company');

  useEffect(() => {
    isCompanyRelatedPage
      ? setProfileImage(company.logo)
      : setProfileImage(user.profileImage);
  }, [company.logo, isCompanyRelatedPage, user.profileImage]);

  return (
    <>
      <div className='container-fluid p-2'>
        <Card className='p-1 settingsPage'>
          <Tab.Container
            activeKey={activeKey}
            id='left-tabs-example'
            defaultActiveKey='BuyerUserAccount'
          >
            <div className='row flex-nowrap'>
              <div className='col col-side-menu'>
                {isCompanyRelatedPage ? (
                  <Card className='border'>
                    <Card.Body>
                      <Row className='justify-content-center'>
                        <UserProfileImageUpload
                          profileImage={profileImage}
                          initials={company.initials}
                          handleImageUpload={handleCompanyImageUpload}
                          handleImageDelete={handleCompanyImageDelete}
                        />
                      </Row>

                      <Row className='justify-content-center'>
                        {isLoading ? (
                          <Spinner className='my-2' animation='border' />
                        ) : (
                          <h4 className='text-center mt-4'>{company.name}</h4>
                        )}
                      </Row>
                      <Row>
                        <h5 className='text-center'>
                          <a
                            href={`mailto:${company.email}`}
                            className='icon-ez'
                          >
                            {company.email}
                          </a>
                        </h5>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  <Card className='border'>
                    <Card.Body>
                      <Row className='justify-content-center'>
                        <UserProfileImageUpload
                          profileImage={user.profileImage}
                          initials={user.initials}
                          handleImageUpload={handleProfileImageUpload}
                          handleImageDelete={handleProfileImageDelete}
                        />
                      </Row>

                      <Row className='justify-content-center'>
                        {isLoading ? (
                          <Spinner className='my-2' animation='border' />
                        ) : (
                          <h4 className='text-center mt-4'>{`${user.firstName} ${user.lastName}`}</h4>
                        )}
                      </Row>
                      <Row>
                        <h5 className='text-center'>
                          <a href={`mailto:${user.email}`} className='icon-ez'>
                            {user.email}
                          </a>
                        </h5>
                      </Row>
                      <Row>
                        <h5
                          onClick={() => setActiveKey('BuyerCompany')}
                          className='text-center cursor-pointer icon-ez'
                        >
                          {user.companyName}
                        </h5>
                      </Row>
                      <Row>
                        <span className='text-center'>{user.position}</span>
                      </Row>
                    </Card.Body>
                  </Card>
                )}

                {/*User pills*/}
                <Nav className='flex-column border p-0 mb-4 tabContainerSettings'>
                  <Nav.Item
                    style={{ cursor: 'default' }}
                    className='border-bottom'
                  >
                    <Nav.Link style={{ cursor: 'default' }}>
                      <h4>{t('userSettings.lbl_Mypeferences')}</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => setActiveKey('BuyerUserAccount')}
                    className='border-bottom nav-item-hover'
                  >
                    <Nav.Link eventKey='BuyerUserAccount'>
                      {t('userSettings.lbl_UserInfo')}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    className='border-bottom nav-item-hover'
                    onClick={() => setActiveKey('BuyerNotifications')}
                  >
                    <Nav.Link eventKey='BuyerNotifications'>
                      {t('userSettings.lbl_Notifications')}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    className='nav-item-hover'
                    onClick={() => setActiveKey('BuyerDeliveryAddresses')}
                  >
                    <Nav.Link eventKey='BuyerDeliveryAddresses'>
                      {t('userSettings.lbl_DeliveryAddresses')}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                {/*Company pills*/}
                <Nav className='flex-column border p-0 tabContainerSettings'>
                  <Nav.Item
                    style={{ cursor: 'default' }}
                    className='border-bottom'
                  >
                    <Nav.Link style={{ cursor: 'default' }}>
                      <h4>{t('userSettings.lbl_MyCompany')}</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => setActiveKey('BuyerCompany')}
                    className='border-bottom nav-item-hover'
                  >
                    <Nav.Link eventKey='BuyerCompany'>
                      {t('userSettings.lbl_CompanyInfo')}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    className='nav-item-hover'
                    onClick={() => setActiveKey('BuyerCompanyUsers')}
                  >
                    <Nav.Link eventKey='BuyerCompanyUsers'>
                      {t('userSettings.lbl_Users')}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className='col'>
                {/*User tab*/}
                <Tab.Content>
                  <Tab.Pane eventKey='BuyerUserAccount'>
                    <Account user={user} setUser={setUser} viewType='Buyer' />
                  </Tab.Pane>
                  {/*Notifications tab*/}
                  <Tab.Pane eventKey='BuyerNotifications'>
                    <BuyerNotifications />
                  </Tab.Pane>
                  {/*Company tab*/}
                  <Tab.Pane eventKey='BuyerCompany'>
                    <Company company={company} />
                  </Tab.Pane>
                  {/*Buyer company users tabs*/}
                  <Tab.Pane eventKey='BuyerCompanyUsers'>
                    <Users company={company} viewType='Buyer' />
                  </Tab.Pane>
                  {/*Delivery addresses tab*/}
                  <Tab.Pane eventKey='BuyerDeliveryAddresses'>
                    <DeliveryAddresses />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </Card>
      </div>
    </>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { LightTooltip } from 'components/shared/LightTooltip';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Nav, Navbar } from 'react-bootstrap';
import { BriefcaseFill, PeopleFill, PlugFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../contexts/user/AppContext';
import logo from '../media/ElecZap_new_logo.png';
import './sellerHeader.css';

export const SellerSidebar = () => {
  const [width, setWidth] = useState<number>(window && window.innerWidth);
  const { appState } = useContext(AppContext);
  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const pageNumber = 1;
  const orderBy = ''; // No ordering in 1st version
  const maxResults = 5;
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const handleSearch = async (
    searchString: string,
    sellerIds: number[] | null
  ) => {
    const productsResponse = await BuyerApi.findProductsGrouped(
      currentUser.companyId,
      sellerIds,
      searchString,
      pageNumber,
      orderBy,
      maxResults,
      userLocaleId,
      true
    );
    return productsResponse;
  };

  const handleSearchDebounced = AwesomeDebouncePromise(handleSearch, 800);

  const navigate = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const narrowMenu: JSX.Element = (
    <>
      <Nav className='ms-4 align-items-end nav-container'>
        <Nav.Item
          style={{ cursor: 'pointer' }}
          onClick={() => navigate.push('/seller/product-classes')}
          className='ms-4 text-center'
        >
          <span className='seller-nav-item'>
            {t('sellerHeader.lbl_Products')}
          </span>
        </Nav.Item>
        <Nav.Item
          style={{ cursor: 'pointer' }}
          onClick={() => navigate.push('/seller/deals')}
          className='ms-4 text-center'
        >
          <span className='seller-nav-item'>
            {t('sellerHeader.lbl_Projects')}
          </span>
        </Nav.Item>
      </Nav>
    </>
  );

  return (
    <div className='container-xxl seller-header-container-xxl d-print-none align-items-center flex-column'>
      <Navbar.Brand>
        <Link className='bg-logo ' to='/seller/deals'>
          <img
            style={{ marginLeft: '6px' }}
            className='seller-logo-header'
            alt='ElecZap'
            src={logo}
          />
        </Link>
      </Navbar.Brand>

      <Nav className='nav-container flex-column align-items-start'>
        <Nav.Item
          data-tip
          data-for={`/seller/product-classes`}
          style={{ cursor: 'pointer', marginTop: '18px' }}
          onClick={() => navigate.push('/seller/product-classes')}
          className={`text-center nav-item-rounded-white ${
            navigate.location.pathname.includes('seller/product-classes')
              ? 'nav-item-rounded-active'
              : ''
          }`}
        >
          <span className='seller-nav-item-white d-flex flex-column align-items-center'>
            <PlugFill
              className={`seller-nav-item-icon ${
                navigate.location.pathname.includes('seller/product-classes')
                  ? 'icon-ez-white'
                  : 'icon-ez'
              } `}
              size={38}
            />
          </span>
        </Nav.Item>
        <LightTooltip id={`/seller/product-classes`}>
          <h3 className='seller-nav-item-label'>
            {t('sellerHeader.lbl_Products')}
          </h3>
        </LightTooltip>

        <Nav.Item
          data-tip
          data-for={`/seller/deals`}
          style={{ cursor: 'pointer', marginTop: '18px' }}
          onClick={() => navigate.push('/seller/deals')}
          className={`text-center nav-item-rounded-white ${
            navigate.location.pathname.includes('seller/deals')
              ? 'nav-item-rounded-active'
              : ''
          }`}
          id='projectsPage'
        >
          <span className='seller-nav-item-white d-flex flex-column align-items-center'>
            <BriefcaseFill
              className={`seller-nav-item-icon ${
                navigate.location.pathname.includes('seller/deals')
                  ? 'icon-ez-white'
                  : 'icon-ez'
              } `}
              size={38}
            />
          </span>
        </Nav.Item>
        <LightTooltip id={`/seller/deals`}>
          <h3 className='seller-nav-item-label'>
            {t('sellerHeader.lbl_Projects')}
          </h3>
        </LightTooltip>

        <Nav.Item
          data-tip
          data-for={`/seller/customers`}
          style={{ cursor: 'pointer', marginTop: '18px' }}
          onClick={() => navigate.push('/seller/customers')}
          className={`text-center nav-item-rounded-white ${
            navigate.location.pathname.includes('seller/customers')
              ? 'nav-item-rounded-active'
              : ''
          }`}
          id='suppliersPage'
        >
          <span className='seller-nav-item-white d-flex flex-column align-items-center'>
            <PeopleFill
              className={`seller-nav-item-icon ${
                navigate.location.pathname.includes('seller/customers')
                  ? 'icon-ez-white'
                  : 'icon-ez'
              } `}
              size={38}
            />
          </span>
        </Nav.Item>
        <LightTooltip id={`/seller/suppliers`}>
          <h3 className='seller-nav-item-label'>
            {t('sellerHeader.lbl_Customers')}
          </h3>
        </LightTooltip>

        {/* <Nav.Item
          data-tip
          data-for={`/seller/marketplace-end-suppliers`}
          style={{ cursor: 'pointer', marginTop: '18px' }}
          onClick={() => navigate.push('/seller/marketplace-end-suppliers')}
          className={`text-center nav-item-rounded-white ${
            navigate.location.pathname.includes(
              '/seller/marketplace-end-suppliers'
            )
              ? 'nav-item-rounded-active'
              : ''
          }`}
          id='marketplaceEndSuppliersPage'
        >
          <span className='seller-nav-item-white d-flex flex-column align-items-center'>
            <IoIosPeople
              className={`seller-nav-item-icon ${
                navigate.location.pathname.includes(
                  '/seller/marketplace-end-suppliers'
                )
                  ? 'icon-ez-white'
                  : 'icon-ez'
              } `}
              size={38}
            />
          </span>
        </Nav.Item> */}
        <LightTooltip id={`/seller/marketplace-end-suppliers`}>
          <h3 className='seller-nav-item-label'>
            {t('buyerHeader.lbl_Suppliers')}
          </h3>
        </LightTooltip>
      </Nav>
    </div>
  );
};

import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'appInsights';
import { useClearCache } from 'clearCache';
import { ExternalSellerInquiry } from 'pages/seller/Inquiry/ExternalSellerInquiry';
import { ExternalSellerOffer } from 'pages/seller/Offer/ExternalSellerOffer';
import { ExternalSellerOrder } from 'pages/seller/Order/ExternalSellerOrder';
import { BuyerRegistration } from 'pages/shared/onboarding/BuyerRegistration';
import { BuyerRegistrationConfirmation } from 'pages/shared/onboarding/BuyerRegistrationConfirmation';
import { BuyerRegistrationEmailSent } from 'pages/shared/onboarding/BuyerRegistrationEmailSent';
import { CustomerOnboarding } from 'pages/shared/onboarding/CustomerOnboarding';
import { UserOnboarding } from 'pages/shared/onboarding/UserOnboarding';
import { AuthProvider, PrivateRoute } from 'react-auth-kit';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { BuyerRoutes } from './components/shared/routes/BuyerRoutes';
import { SellerRoutes } from './components/shared/routes/SellerRoutes';
import { AppContextProvider } from './contexts/user/AppContextProvider';
import './i18n';
import { ErrorFallback } from './pages/ErrorFallback';
import { ForgotPassword } from './pages/identity/ForgotPassword';
import { Login } from './pages/identity/Login';
import { ResetPassword } from './pages/identity/ResetPassword';

function App() {
  useClearCache();
  return (
    <AppInsightsErrorBoundary onError={ErrorFallback} appInsights={reactPlugin}>
      <>
        <Helmet>
          <script
            src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`}
            type='text/javascript'
          />
        </Helmet>
        <ToastContainer
          theme='colored'
          position='top-right'
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          icon={false}
        />

        <AuthProvider authType={'localstorage'} authName={'_auth'}>
          <AppContextProvider>
            <Router>
              <Switch>
                <Route path='/login'>
                  <Login />
                </Route>
                <Route path='/forgot-password'>
                  <ForgotPassword />
                </Route>
                <Route path='/reset-password'>
                  <ResetPassword />
                </Route>
                <Route path='/user-onboarding'>
                  <UserOnboarding />
                </Route>
                <Route path='/customer-onboarding'>
                  <CustomerOnboarding />
                </Route>
                <Route path='/buyer-registration'>
                  <BuyerRegistration />
                </Route>
                <Route path='/buyer-registration-confirmation'>
                  <BuyerRegistrationConfirmation />
                </Route>
                <Route path='/buyer-registration-email-sent'>
                  <BuyerRegistrationEmailSent />
                </Route>
                <Route path='/external-seller-inquiry'>
                  <ExternalSellerInquiry />
                </Route>
                <Route path='/external-seller-offer'>
                  <ExternalSellerOffer />
                </Route>
                <Route path='/external-seller-order'>
                  <ExternalSellerOrder />
                </Route>
                <PrivateRoute
                  component={SellerRoutes}
                  path={'/seller'}
                  loginPath={'/login'}
                />
                <PrivateRoute
                  component={BuyerRoutes}
                  path={'/buyer'}
                  loginPath={'/login'}
                />
                <Route path='/'>
                  <Login />
                </Route>
              </Switch>
            </Router>
          </AppContextProvider>
        </AuthProvider>
      </>
    </AppInsightsErrorBoundary>
  );
}

export default App;

/* Functions utilized in Company.tsx (Company settings)*/

import { CompanyApi } from 'api/CompanyApi';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ChangeEvent } from 'react';
import { toast } from 'react-toastify';

export const handleCompanyProfileImageUpload = async (
  currentUser: LoginResponseUserModel,
  t: any,
  setProfileImage: (value: string) => void,
  event: ChangeEvent<HTMLInputElement>
) => {
  toast.dismiss();

  if (event.target.files) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 500 * 1024) {
        toast.error(t('account.err_ProfileImageSizeTooBig'));
        event.target.value = '';
        return;
      }
      const data = new FormData();
      data.append('file', selectedFile);
      const imageLocation = await CompanyApi.uploadCompanyProfileImage(
        currentUser.companyId,
        data
      );
      setProfileImage(imageLocation);

      toast.info(t('account.inf_ProfileImageUploaded'));
    }
  }
};

export const handleCompanyProfileImageDelete = async (
  currentUser: LoginResponseUserModel,
  t: any,
  setProfileImage: (value: string | null) => void,
  profileImage: string | null
) => {
  toast.dismiss();

  if (profileImage) {
    await CompanyApi.deleteCompanyProfileImage(currentUser.companyId);
    setProfileImage(null);

    toast.info(t('account.inf_ProfileImageDeleted'));
  }
};

/* Hooks utilized in StocksSharedToPartners.tsx (StocksSharedToPartners settings)*/

import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useEffect } from 'react';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';
import { getAllStocksSharedToPartners } from 'components/seller/settings/stocks/StocksSharedToPartners/utilsStocksSharedToPartners';

/* Get all stock */
export const useGetAllStocksSharedToPartners = (
  currentUser: LoginResponseUserModel,
  setStocks: (values: StocksSharedToPartnersModel[]) => void
) => {
  useEffect(() => {
    getAllStocksSharedToPartners(currentUser, setStocks);
  }, [currentUser, currentUser.companyId, currentUser.id, setStocks]);
};

import { useQueryClient } from '@tanstack/react-query';
import { BuyerApi } from 'api/BuyerApi';
import { InquiryGroupSupplierSelector } from 'components/buyer/documents/InquiryGroupSupplierSelector/InquiryGroupSupplierSelector';
import { OfferComparisonSuppliers } from 'components/buyer/documents/OfferComparisonSuppliers';
import { OfferOnBehalfDrawer } from 'components/buyer/documents/OfferOnBehalfDrawer';
import {
  useGetBestDeliveryDates,
  useGetBestPrices,
} from 'components/buyer/documents/hooksOfferComparison';
import { getAllRowsWithSameCode } from 'components/buyer/documents/utilsOfferComparison';
import { CustomTargetPriceInput } from 'components/shared/CustomInputs/CustomTargetPriceInput';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { InquiryUpdateTargetPriceModel } from 'models/buyer/documents/InquiryModels';
import {
  OfferComparisonModel,
  OfferComparisonRowModel,
} from 'models/buyer/documents/OfferModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  BookmarkStar,
  EnvelopeCheck,
  EyeFill,
  FolderFill,
  Percent,
} from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useTranslation } from 'react-i18next';
import { BiTargetLock } from 'react-icons/bi';
import { SiMicrosoftexcel } from 'react-icons/si';
import { useParams } from 'react-router';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { handleDownloadOfferExcel } from 'utils/downloadExcelSheet';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';

type UrlParamsType = {
  dealGroupId: string;
};

const offerComparisonLocalKey = '_elecZapApp_offer_comparison';

export const OfferComparison = () => {
  const [offerComparison, setOfferComparison] = useState<OfferComparisonModel>(
    {} as OfferComparisonModel
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isoDate, setIsoDate] = useState<string | null>(null);
  const [offerDeadlineDate, seOfferDeadlineDate] = useState<string | null>(
    offerComparison.offerDeadlineDate
  );
  const [showPercentage, setShowPercentage] = useState<boolean>(true);
  const [baseForPercentageIsTargetPrice, setBaseForPercentageIsTargetPrice] =
    useState<boolean>(false);

  const [columnFilter, setColumnsFilter] = useState<{
    date: boolean;
    price: boolean;
    total: boolean;
    percent: boolean;
  }>({ date: true, price: true, total: true, percent: true });

  const [show, setShow] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const queryClient = useQueryClient();

  const canSeePrices = useUserCanSeePrices();
  const validateUnitExists = useValidateUnitExists();
  const getBestPrices = useGetBestPrices();
  const getBestDeliveryDates = useGetBestDeliveryDates();

  const { t } = useTranslation('components');

  let { dealGroupId } = useParams<UrlParamsType>();

  const { appState } = useContext(AppContext);

  const downloadExcel = () => {
    const sheetData: { sheetName: string; sheetData: any[] }[] | undefined = [];

    offerComparison.offers.forEach((offer) => {
      sheetData.push({
        sheetName: offer.sellerCompanyName,
        sheetData: offer.rows,
      });
    });
    handleDownloadOfferExcel(
      offerComparison.offers,
      offerComparison.projectName,
      `${offerComparison.projectName}_OFFER_COMPARISON_${offerComparison.projectId}`,
      sheetData
    );
  };

  const setOfferComparisonAndUpdateLocalStorage = (
    offerComparisonArg: OfferComparisonModel
  ) => {
    setOfferComparison(offerComparisonArg);

    sessionStorage.setItem(
      `${offerComparisonLocalKey}_${dealGroupId}`,
      JSON.stringify(offerComparisonArg)
    );
  };

  useEffect(() => {
    if (offerComparison.offerDeadlineDate) {
      const pickedDate = dayjs(offerComparison.offerDeadlineDate).format(
        'DD-MM-YYYY'
      );

      seOfferDeadlineDate(pickedDate);
      setIsoDate(dayjs(offerComparison.offerDeadlineDate).toISOString());
    }
  }, [offerComparison.offerDeadlineDate]);

  const handleDateChange = async (day: Date) => {
    const pickedDate = dayjs(day).format('DD-MM-YYYY');

    seOfferDeadlineDate(pickedDate);
    setIsoDate(dayjs(day).toISOString());

    await BuyerApi.buyerDealsGroup.updateOfferDeadlineDate(
      currentUser.companyId,
      parseInt(dealGroupId),
      dayjs(day).toISOString()
    );

    await getComparison();
  };

  const only2ViewsSelected =
    (Object.values(columnFilter).filter((value, index) => value).length < 3 &&
      columnFilter.percent) ||
    Object.values(columnFilter).filter((value, index) => value).length === 1;

  const getComparison = useCallback(async () => {
    const sessionStorageRawData = sessionStorage.getItem(
      `${offerComparisonLocalKey}_${dealGroupId}`
    );

    const response = await BuyerApi.getOfferComparison(
      currentUser.companyId,
      parseInt(dealGroupId)
    );

    let clonedOffers = [...response.offers];

    if (sessionStorageRawData !== null) {
      const data = JSON.parse(sessionStorageRawData);

      clonedOffers.forEach((offer, index) => {
        offer.rows = data.offers[index].rows;
      });

      setOfferComparison({ ...response, offers: clonedOffers });
    } else setOfferComparison(response);

    setIsLoading(false);
  }, [currentUser.companyId, dealGroupId]);

  useEffect(() => {
    setIsLoading(true);

    getComparison();
  }, [currentUser.companyId, dealGroupId, getComparison]);

  window.onbeforeunload = () => {
    sessionStorage.removeItem(`${offerComparisonLocalKey}_${dealGroupId}`);
  };

  const handleSelectBestPrices = () => {
    const clonedOfferComparison = { ...offerComparison };

    const inquiryRows = offerComparison.rows;

    clonedOfferComparison.offers.forEach((offer) => {
      if (
        offer.allowSplitting &&
        offer.status !== 'Recalled' &&
        offer.orderId === null
      ) {
        offer.rows.forEach((row) => {
          const allRowsWithSameCode = getAllRowsWithSameCode(
            row,
            offerComparison,
            appState
          );

          const otherOfferWithSamePrice = allRowsWithSameCode.filter(
            (rowWithSameCode) =>
              rowWithSameCode.priceWithDiscount === row.priceWithDiscount
          );

          const otherOfferWithSameDeliveryDateAndPrice =
            allRowsWithSameCode.filter(
              (rowWithSameCode) =>
                rowWithSameCode.expectedDelivery === row.expectedDelivery &&
                rowWithSameCode.priceWithDiscount === row.priceWithDiscount
            );

          const otherOffersHaveSamePrice = otherOfferWithSamePrice.length > 1;
          const otherOffersHaveSameDeliveryDateAndPrice =
            otherOfferWithSameDeliveryDateAndPrice.length > 1;

          const [bestPrice, secondBestPrice] = getBestPrices(
            row,
            offerComparison
          );

          const [bestDate, secondBestDate] = getBestDeliveryDates(
            row,
            offerComparison
          );

          const doesNeedComparing =
            !row.isReplaced &&
            inquiryRows.findIndex(
              (inquiryRow) => inquiryRow.productCode === row.productCode
            ) !== -1;

          if (
            doesNeedComparing &&
            row.priceWithDiscount !== null &&
            row.priceWithDiscount !== 0 &&
            row.priceWithDiscount === bestPrice.priceWithDiscount
          ) {
            if (otherOffersHaveSameDeliveryDateAndPrice) {
              if (row.id === otherOfferWithSameDeliveryDateAndPrice[0].id)
                row.isSelected = true;
            } else {
              if (otherOffersHaveSamePrice) {
                if (row.expectedDelivery === bestDate) row.isSelected = true;
              } else row.isSelected = true;
            }
          } else row.isSelected = false;
        });
      }
    });

    setOfferComparisonAndUpdateLocalStorage(clonedOfferComparison);
  };

  const handleUpdateTargetPrices = async () => {
    const selectedPrices: InquiryUpdateTargetPriceModel[] = [];

    setIsLoading(true);

    offerComparison.offers.forEach((offer) => {
      offer.rows.forEach((row) => {
        if (row.isSelected) {
          selectedPrices.push({
            rowNo: row.rowNo,
            targetPrice: row.priceWithDiscount,
          });
        }
      });
    });

    if (selectedPrices.length > 0) {
      await BuyerApi.buyerDealsGroup.updateInquiryRowTargetPrice(
        currentUser.companyId,
        parseInt(dealGroupId),
        selectedPrices
      );

      await getComparison();
    } else {
      toast.error(t('documents.err_PleaseSelectSomePrices'));
    }

    setIsLoading(false);
  };

  const handleTargetPriceChange = async (
    value: string,
    row: OfferComparisonRowModel
  ) => {
    const selectedPrices: InquiryUpdateTargetPriceModel[] = [];

    selectedPrices.push({
      rowNo: row.rowNo,
      targetPrice: isNaN(parseFloat(value)) ? null : parseFloat(value),
    });

    await BuyerApi.buyerDealsGroup.updateInquiryRowTargetPrice(
      currentUser.companyId,
      parseInt(dealGroupId),
      selectedPrices
    );

    await getComparison();
  };

  const suppliers = appState.buyerCurrentSuppliers?.filter(
    (supplier) =>
      supplier.isSelected &&
      offerComparison.suppliers
        ?.map((supplier) => supplier.sellerCompanyId)
        ?.includes(supplier.value)
  );

  const handleSendEmails = async () => {
    const filteredSupplierIds = suppliers?.map((supplier) => supplier.value);
    if (filteredSupplierIds) {
      await BuyerApi.buyerDealsGroup.sendInquiryUpdateEmails(
        currentUser.companyId,
        parseInt(dealGroupId),
        filteredSupplierIds
      );

      queryClient.invalidateQueries({ queryKey: ['deals'] });

      SuccessToast(t('documents.inf_NotificationSentToSellers'));
    }

    setShow(false);
  };

  const noRowsAreSelected =
    offerComparison.offers?.filter(
      (offer) =>
        (offer.orderId === null || offer.status === 'Recalled') &&
        offer.rows.filter((row) => row.isSelected).length > 0
    )?.length < 1;

  const columns: TableColumn<OfferComparisonRowModel>[] = [
    {
      name: '#',
      cell: (row) => <span>{row.rowNo}</span>,
      selector: (row) => row.rowNo,
      sortable: false,
      width: '30px',
      right: true,
    },
    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('productDetailsGeneralInfo.lbl_ProductCode')}
        </span>
      ),
      selector: (row) => row.productCode,
      format: (row) => {},
      cell: (row) => (
        <div className='wrap-ellipsis'>
          {!row.productExists ? (
            <span className='btn-link-bold-no-link'>{row.productCode}</span>
          ) : (
            <a
              className='btn-link-bold'
              href={`/buyer/project-product-details/${encodeURIComponent(
                row.productCode
              )}`}
            >
              {row.productCode}
            </a>
          )}
        </div>
      ),
      maxWidth: '130px',
      sortable: false,
    },
    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('projectSettings.lbl_ProductName')}
        </span>
      ),
      selector: (row) => row.productName,
      sortable: false,
      cell: (row) => <div className='wrap-ellipsis'>{row.productName}</div>,

      maxWidth: '250px',
      minWidth: '150px',
    },

    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('projectSettings.lbl_Quantity')}
        </span>
      ),
      selector: (row) => row.quantity,
      cell: (row) => (
        <span>
          {row.quantity} <small>{validateUnitExists(row.unit)}</small>
        </span>
      ),
      width: '120px',
      sortable: false,
      center: true,
    },

    {
      name: (
        <span
          className='d-flex align-items-center justify-content-center'
          style={{ fontSize: 'larger' }}
        >
          <span className='text-end me-2'>
            {t('documents.lbl_TargetPrice')}
          </span>

          <OverlayTrigger
            placement='bottom'
            trigger={'hover'}
            overlay={
              <Tooltip>
                {noRowsAreSelected
                  ? t('documents.err_PleaseSelectSomePrices')
                  : t('documents.btn_UpdateTargetPriceWithSelectedPrices')}
              </Tooltip>
            }
          >
            <div>
              <Button
                onClick={handleUpdateTargetPrices}
                variant='btn btn-eleczap'
                className='float-end p-1'
                disabled={noRowsAreSelected}
              >
                <BiTargetLock
                  className={noRowsAreSelected ? 'icon-ez-white' : ''}
                  size={25}
                />
              </Button>
            </div>
          </OverlayTrigger>
        </span>
      ),
      selector: (row) => row.targetPrice,
      cell: (row) =>
        !allOrdersAreMade || noOfferAreMade ? (
          <CustomTargetPriceInput
            handleOfferComparisonTargetPriceChange={handleTargetPriceChange}
            offerComparisonRow={row}
            rowsLength={offerComparison?.rows?.length}
          />
        ) : (
          canSeePrices(
            formatMonetaryValue(
              offerComparison.currencyCode,
              row.targetPrice,
              appState
            )
          ) ?? '-'
        ),
      sortable: false,
      right: true,
      maxWidth: '150px',
      minWidth: '150px',
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    table: {
      style: {
        backgroundColor: '#f7f9fc !important',
        borderRight: '0 !important',
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f7f9fc !important',
        borderBottom: '0 !important',
        paddingRight: '10px',
        paddingLeft: '10px',
      },
    },
    rows: {
      style: {
        // maxWidth: widthOfGridContainer,
        borderBottom: '0 !important',
        marginBottom: '5px !important',
        minHeight: '54.67px !important',
        padding: '10px',
      },
    },
    cells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    expanderButton: {
      style: {
        backgroundColor: '#f7f9fc !important',
      },
    },
  };

  const disabledDays = {
    from: new Date(
      new Date().getFullYear() - 1,
      dayjs().startOf('month').month(),
      1
    ),
    to: new Date(
      dayjs().startOf('month').year(),
      dayjs().startOf('month').month(),
      new Date().getDate() - 1
    ),
  };

  const allOrdersAreMade =
    offerComparison?.offers?.filter(
      (offer) => offer.orderId === null && offer.status !== 'Recalled'
    ).length < 1;

  const noOfferAreMade = offerComparison?.offers?.length === 0;

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('documents.lbl_ConfirmSendingInquiryEmail')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('documents.inf_EmailWillBeSentTo')}
          <ul>
            {suppliers?.map((supplier) => (
              <li>{supplier.name}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShow(false)}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='btn btn-eleczap' onClick={handleSendEmails}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>

      {isLoading && <FullScreenLoader />}
      <div
        style={{ overflowX: 'auto' }}
        className='d-flex flex-row offer-comparison-container '
      >
        <div
          className={`p-0 mt-3 me-0 pe-3 ${
            !isLoading ? 'offer-comparison-main-table-container bg-body' : ''
          }`}
        >
          <Card className='p-0 bg-body'>
            <Card.Header
              // className='py-0'
              style={{
                paddingTop: '10px',
                height: only2ViewsSelected ? '55px' : '49px',
              }}
            >
              <div className='d-flex justify-content-between align-items-center'>
                <h2 className='btn-link-bold-no-link mb-0 wrap-ellipsis'>
                  <FolderFill className='icon-ez' />{' '}
                  {offerComparison.projectName}{' '}
                  {offerComparison.customerInquiryCode &&
                    `/ ${offerComparison.customerInquiryCode}`}
                </h2>

                <div className='d-flex align-items-center justify-content-end'>
                  <DropdownButton
                    className='me-2'
                    autoClose='outside'
                    variant='btn btn-eleczap'
                    title={
                      <span className=''>
                        <EyeFill
                          size={20}
                          style={{ marginTop: '-3px' }}
                          className='me-1'
                        />{' '}
                        {`${t('documents.btn_CompareColumns')} ${
                          Object.values(columnFilter).filter((column) => column)
                            .length
                        }/4`}
                      </span>
                    }
                  >
                    <Dropdown.Item
                      onClick={() =>
                        setColumnsFilter({
                          ...columnFilter,
                          date: !columnFilter.date,
                        })
                      }
                      as={'div'}
                    >
                      <Form.Check
                        type='checkbox'
                        label={t('documents.lbl_ExpectedDelivery')}
                        onChange={(event) =>
                          setColumnsFilter({
                            ...columnFilter,
                            date: event.target.checked,
                          })
                        }
                        checked={columnFilter.date}
                      />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setColumnsFilter({
                          ...columnFilter,
                          price: !columnFilter.price,
                        })
                      }
                      as={'div'}
                    >
                      <Form.Check
                        type='checkbox'
                        label={t('documents.lbl_PriceUnit')}
                        onChange={(event) =>
                          setColumnsFilter({
                            ...columnFilter,
                            price: event.target.checked,
                          })
                        }
                        checked={columnFilter.price}
                      />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setColumnsFilter({
                          ...columnFilter,
                          total: !columnFilter.total,
                        })
                      }
                      as={'div'}
                    >
                      <Form.Check
                        type='checkbox'
                        label={t('projectSettings.lbl_TotalPrice')}
                        onChange={(event) =>
                          setColumnsFilter({
                            ...columnFilter,
                            total: event.target.checked,
                          })
                        }
                        checked={columnFilter.total}
                      />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setColumnsFilter({
                          ...columnFilter,
                          percent: !columnFilter.percent,
                        })
                      }
                      as={'div'}
                    >
                      <Form.Check
                        type='checkbox'
                        label={t('documents.lbl_ShowPercent')}
                        onChange={(event) =>
                          setColumnsFilter({
                            ...columnFilter,
                            percent: event.target.checked,
                          })
                        }
                        checked={columnFilter.percent}
                      />
                    </Dropdown.Item>
                  </DropdownButton>

                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>{t('documents.lbl_BaseForPercentage')}</Tooltip>
                    }
                  >
                    <div className='d-flex justify-content-center'>
                      <Switch
                        checked={baseForPercentageIsTargetPrice}
                        onChange={(checked) => {
                          setColumnsFilter({
                            ...columnFilter,
                            percent: true,
                          });
                          setBaseForPercentageIsTargetPrice(checked);
                        }}
                        checkedHandleIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 15,
                              paddingRight: 2,
                            }}
                          >
                            <Percent className='icon-ez' />
                          </div>
                        }
                        uncheckedHandleIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 15,
                              paddingRight: 2,
                            }}
                          >
                            <Percent className='icon-ez' />
                          </div>
                        }
                        uncheckedIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 15,
                              paddingRight: 2,
                            }}
                          >
                            <BookmarkStar className='icon-ez-white' />
                          </div>
                        }
                        onColor='#1b9ad2'
                        offColor='#1b9ad2'
                        checkedIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 15,
                              paddingRight: 2,
                            }}
                          >
                            <BiTargetLock className='icon-ez-white' />
                          </div>
                        }
                        className='react-switch'
                        id='icon-switch'
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </Card.Header>

            <hr className='icon-ez-light-gray p-0 my-0' />

            <Card.Footer
              style={{
                height: only2ViewsSelected ? '106px' : '41px',
                paddingTop: '5px',
                marginBottom: '1px',
              }}
            >
              {!allOrdersAreMade || noOfferAreMade ? (
                <Row>
                  <Col md={4} className='d-flex flex-column'>
                    <DayPickerInput
                      classNames={{
                        container: '',
                        overlayWrapper: '',
                        overlay:
                          'offer-comparison-day-picker DayPickerInput-Overlay',
                      }}
                      format='DD-MM-YYYY'
                      dayPickerProps={{
                        fromMonth: new Date(),
                        disabledDays: disabledDays,
                      }}
                      style={{ width: '100px' }}
                      component={(props: any) => (
                        <InputGroup>
                          <FormControl
                            className='shorter-height-fields'
                            {...props}
                          />
                        </InputGroup>
                      )}
                      value={
                        offerDeadlineDate === 'Invalid Date'
                          ? t('common.lbl_DDMMYYYY')
                          : offerDeadlineDate ?? t('common.lbl_DDMMYYYY')
                      }
                      onDayChange={handleDateChange}
                    />
                    <small>{t('documents.lbl_OfferDeadline')}</small>
                  </Col>

                  <Col className='d-flex justify-content-end  align-items-start'>
                    <ButtonGroup>
                      <InquiryGroupSupplierSelector
                        suppliers={offerComparison.suppliers}
                        projectId={offerComparison.projectId}
                        dealGroupId={offerComparison.dealGroupId}
                        refreshData={getComparison}
                      />

                      <OverlayTrigger
                        placement='bottom'
                        trigger={'hover'}
                        overlay={
                          <Tooltip>{t('documents.btn_ExportToExcel')}</Tooltip>
                        }
                      >
                        <Button
                          type={'button'}
                          onClick={downloadExcel}
                          variant='btn btn-eleczap'
                        >
                          <SiMicrosoftexcel size={20} />
                        </Button>
                      </OverlayTrigger>

                      <OfferOnBehalfDrawer
                        rows={offerComparison.rows}
                        projectName={offerComparison.projectName}
                        dealGroupId={parseInt(dealGroupId)}
                        offers={offerComparison.offers}
                        supplierIds={offerComparison.suppliers?.map(
                          (supplier) => supplier.sellerCompanyId
                        )}
                        getComparison={getComparison}
                      />

                      <OverlayTrigger
                        placement='bottom'
                        trigger={'hover'}
                        overlay={
                          <Tooltip>{t('common.lbl_SelectBestPrices')}</Tooltip>
                        }
                      >
                        <Button
                          onClick={handleSelectBestPrices}
                          variant='btn btn-eleczap'
                          className='float-end'
                        >
                          <BookmarkStar size={20} />
                        </Button>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement='bottom'
                        trigger={'hover'}
                        overlay={
                          <Tooltip>
                            {t(
                              'documents.btn_SendEmailNotificationToSelectedSuppliers'
                            )}
                          </Tooltip>
                        }
                      >
                        <Button
                          onClick={() => setShow(true)}
                          variant='btn btn-eleczap'
                          className='float-end'
                        >
                          <EnvelopeCheck size={20} />
                        </Button>
                      </OverlayTrigger>
                    </ButtonGroup>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={4} className='d-flex flex-column'>
                    <strong>
                      {offerDeadlineDate === 'Invalid Date'
                        ? t('common.lbl_DDMMYYYY')
                        : offerDeadlineDate ?? t('common.lbl_DDMMYYYY')}
                    </strong>
                    <small>{t('documents.lbl_OfferDeadline')}</small>
                  </Col>

                  <Col></Col>

                  <Col
                    md={4}
                    className='d-flex flex-column align-items-end'
                  ></Col>
                </Row>
              )}
            </Card.Footer>

            <Card.Body className='bg-body px-0 pb-0'>
              <DataTable
                data={offerComparison.rows}
                columns={columns}
                customStyles={customStyles}
              />
            </Card.Body>
          </Card>
        </div>

        <div className='d-flex flex-row offer-comparison-supplier-table-main'>
          {offerComparison.offers?.map(
            (offer) =>
              appState.buyerCurrentSuppliers?.findIndex(
                (supplier) =>
                  supplier.value === offer.sellerCompanyId &&
                  supplier.isSelected
              ) !== -1 && (
                <OfferComparisonSuppliers
                  baseForPercentageIsTargetPrice={
                    baseForPercentageIsTargetPrice
                  }
                  setOfferComparisonAndUpdateLocalStorage={
                    setOfferComparisonAndUpdateLocalStorage
                  }
                  offerComparison={offerComparison}
                  getComparison={getComparison}
                  columnsFilter={columnFilter}
                  offer={offer}
                  projectId={offerComparison.projectId}
                  projectName={offerComparison.projectName}
                  inquiryRows={offerComparison.rows}
                />
              )
          )}
        </div>
      </div>
    </>
  );
};

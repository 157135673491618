/* Hooks utilized in DeliveryAddresses.tsx (Delivery Address settings)*/

import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { AddressModel } from 'models/shared/address/AddressModel';
import { useEffect } from 'react';
import { getAllAddresses } from 'components/buyer/settings/deliveryAddresses/utilsDeliveryAddresses';

/* Get all addresses */
export const useGetAllAddresses = (
  currentUser: LoginResponseUserModel,
  setAddresses: (values: AddressModel) => void
) => {
  useEffect(() => {
    getAllAddresses(currentUser, setAddresses);
  }, [currentUser, currentUser.companyId, currentUser.id, setAddresses]);
};

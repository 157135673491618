import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';

type PropTypes = {
  showTerms: boolean;
  setShowTerms: (value: boolean) => void;
  languageTermsToShow: string;
  handleAcceptFromModal?: () => void;
};

export const TermsAndConditionsModal = ({
  showTerms,
  setShowTerms,
  languageTermsToShow,
  handleAcceptFromModal,
}: PropTypes) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const { t } = useTranslation('components');

  return (
    <Modal
      className='terms-and-conditions-modal'
      show={showTerms}
      onHide={() => setShowTerms(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('userOnboarding.hd_TermsAndConditions')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='terms-and-conditions-modal-body'>
          <Document
            file={languageTermsToShow}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setShowTerms(false)}>
          {t('common.btn_Close')}
        </Button>
        {handleAcceptFromModal !== undefined && (
          <Button variant='btn btn-eleczap' onClick={handleAcceptFromModal}>
            {t('userOnboarding.btn_IAgree')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

import 'bootstrap/dist/css/bootstrap.min.css';
import { CheckCircle } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { useState } from 'react';
import { toast } from 'react-toastify';

type ProptTypes = {
  user: UserModelById;
  currentUser: LoginResponseUserModel;
  isDisabled: boolean;
  t: any;
  handleStatusChange: (
    value: boolean,
    user: UserModelById,
    field: string
  ) => Promise<void>;
};

export const UserDeactivate = ({
  user,
  currentUser,
  isDisabled,
  handleStatusChange,
  t,
}: ProptTypes) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeactivateUser = async () => {
    if (
      user.roleNames.includes('SellerAdministrator') ||
      user.roleNames.includes('BuyerAdministrator')
    ) {
      toast.dismiss();
      toast.error('You cannot delete an admin');
      handleClose();

      return;
    }

    if (user.id === currentUser.id) {
      toast.dismiss();
      toast.error('You cannot deactivate yourself');
      handleClose();

      return;
    }

    await handleStatusChange(false, user, 'status');
    handleClose();
  };

  return (
    <>
      <Button
        variant='btn btn-eleczap-noBorder'
        disabled={isDisabled}
        onClick={handleShow}
      >
        <CheckCircle className='status-icon-success' size={20}></CheckCircle>
      </Button>
      <Modal
        size='sm'
        show={show}
        onHide={handleClose}
        id='buyerUserDeactivate'
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('usersSettings.hd_DeactivateUser')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('usersSettings.inf_SureToDeactivateUserPart1')} {user.firstName}{' '}
          {user.lastName} <br />
          {t('usersSettings.inf_SureToDeactivateUserPart2')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='danger' onClick={handleDeactivateUser}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { useFormikHookForBuyerPersonalNotifications } from 'components/buyer/settings/buyerNotifications/hooksBuyerNotifications';
import { BuyerPeronalNotificationsModel } from 'models/buyer/notifications/BuyerNotificationsModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const PersonalNotifications = () => {
  const [personalNotifications, setPersonalNotifications] =
    useState<BuyerPeronalNotificationsModel>(
      {} as BuyerPeronalNotificationsModel
    );

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const getPersonalNotifications = useCallback(async () => {
    const response = await BuyerApi.getBuyerPersonalNotifications(
      currentUser.companyId,
      currentUser.id
    );

    setPersonalNotifications(response);
  }, [currentUser.companyId, currentUser.id]);

  useEffect(() => {
    currentUser.companyId && currentUser.id && getPersonalNotifications();
  }, [currentUser.companyId, currentUser.id, getPersonalNotifications]);

  const formik = useFormikHookForBuyerPersonalNotifications(
    personalNotifications
  );

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    toast.dismiss();
    formik.handleChange(event);
    await formik.submitForm();
    toast.info(
      event.target.checked
        ? t('userSettings.inf_NotificationEnabled')
        : t('userSettings.inf_NotificationDisabled')
    );

    await getPersonalNotifications();
  };

  return (
    <Col lg={3}>
      <strong>{t('userSettings.lbl_Personal')}</strong>
      <p>{t('userSettings.inf_Personal')}</p>

      {/* Commented out until we have inquiry declined functionality */}
      {/* <strong>{t('userSettings.lbl_Inquires')}</strong>
      <Row className='my-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.inquiryDeclined}
            name='inquiryDeclined'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_InquiryDeclined')}</span>
        </Col>
      </Row> */}

      <strong>{t('userSettings.lbl_Offers')}</strong>
      <Row className='mt-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.offerReceived}
            name='offerReceived'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_NewOfferReceived')}</span>
        </Col>
      </Row>

      <Row className='my-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.offerUpdated}
            name='offerUpdated'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_OfferUpdated')}</span>
        </Col>
      </Row>

      <strong>{t('userSettings.lbl_Orders')}</strong>
      <Row className='mt-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.orderSent}
            name='orderSent'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_OrderSentToSupplier')}</span>
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.orderUpdated}
            name='orderUpdated'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_OrderUpdated')}</span>
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.orderConfirmed}
            name='orderConfirmed'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_OrderConfirmed')}</span>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.orderDispatched}
            name='orderDispatched'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_OrderDispatched')}</span>
        </Col>
      </Row>
      <Row className='my-2'></Row>

      <strong>{t('userSettings.lbl_Invoices')}</strong>
      <Row className='my-2'>
        <Col style={{ maxWidth: '40px' }}>
          <Form.Check
            onChange={handleChange}
            checked={formik.values.invoiceReceived}
            name='invoiceReceived'
            type='switch'
          />
        </Col>
        <Col>
          <span>{t('userSettings.lbl_NewInvoiceReceived')}</span>
        </Col>
      </Row>
    </Col>
  );
};

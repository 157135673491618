import { FileBlob } from 'api/ApiClient';
import { SellerApi } from 'api/SellerApi';
import { SellersCompaniesAndContactsModel } from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { AvailableCustomersForOnboardingModel } from 'models/seller/customers/AvailableCustomersForOnboardingModel';
import {
  BuyersCompaniesAndUsersDataModel,
  BuyersCompaniesAndUsersModel,
} from 'models/seller/customers/BuyersCompaniesAndUsersModel';
import { BuyersUsersModel } from 'models/seller/customers/BuyersUsersModel';
import { ResendCustomerInvitationModel } from 'models/seller/customers/ResendCustomerInvitationModel';
import { SellerCustomerContactModel } from 'models/seller/customers/SellerCustomerContactModel';

export const getAllCustomers = async (
  currentUser: LoginResponseUserModel,
  setCustomers: (values: BuyersCompaniesAndUsersModel) => void,
  page?: number | undefined,
  searchString?: string | undefined,
  rowsPerPage?: number | undefined,
  sortColumn?: string | undefined
): Promise<void> => {
  const response = await SellerApi.getBuyersCompaniesAndUsers(
    currentUser.companyId,
    page,
    searchString,
    rowsPerPage,
    sortColumn
  );

  setCustomers(response);
};

export const getAllCustomersUsers = async (
  currentUser: LoginResponseUserModel,
  setCustomersUsers: (values: BuyersUsersModel) => void,
  page?: number | undefined,
  searchString?: string | undefined,
  rowsPerPage?: number | undefined,
  sortColumn?: string | undefined
): Promise<void> => {
  const response = await SellerApi.getBuyersUsers(
    currentUser.companyId,
    page,
    searchString,
    rowsPerPage,
    sortColumn
  );

  setCustomersUsers(response);
};

export const getAllMarketplaceEndSuppliersAndContacts = async (
  currentUser: LoginResponseUserModel,
  setSuppliers: (values: SellersCompaniesAndContactsModel) => void,
  page?: number | undefined,
  searchString?: string | undefined,
  rowsPerPage?: number | undefined,
  sortColumn?: string | undefined
): Promise<void> => {
  const response =
    await SellerApi.getMarketplaceEndSuppliersCompaniesAndContacts(
      currentUser.companyId,
      page,
      searchString,
      rowsPerPage,
      sortColumn
    );

  setSuppliers(response);
};

export const getSpecificCustomersUsers = async (
  currentUser: LoginResponseUserModel,
  setCustomersUsers: (values: BuyersUsersModel) => void,
  customerId: number
): Promise<void> => {
  const response = await SellerApi.getBuyersUsers(currentUser.companyId);

  let cloneData = [...response.data];

  cloneData = cloneData.filter(
    (user) => user.companyId === customerId && user.initials !== ''
  );

  setCustomersUsers({ ...response, data: cloneData });
};

export const getAvailableCustomersForOnboarding = async (
  currentUser: LoginResponseUserModel,
  name: string | null,
  registryNumber?: string
): Promise<AvailableCustomersForOnboardingModel[]> => {
  const response = await SellerApi.getAllCustomersForOnboarding(
    currentUser.companyId,
    name,
    registryNumber
  );

  return response;
};

export const getSellerCustomerContacts = async (
  currentUser: LoginResponseUserModel,
  customerId: number
): Promise<SellerCustomerContactModel[]> => {
  const response: SellerCustomerContactModel[] =
    await SellerApi.getSellerCustomerContacts(
      currentUser.companyId,
      customerId
    );

  return response;
};

export const getCustomerPricelistExport = async (
  currentUser: LoginResponseUserModel,
  customerId: number,
  localizationId: number
): Promise<FileBlob> => {
  const response = await SellerApi.getCustomerPricelistExport(
    currentUser.companyId,
    customerId,
    localizationId
  );
  return response;
};

export const reinviteCustomer = async (
  currentUser: LoginResponseUserModel,
  customer: BuyersCompaniesAndUsersDataModel
) => {
  const invitation: ResendCustomerInvitationModel = {
    companyId: customer.id,
    activationUrlTemplate: `${
      window && window.location.origin
    }/customer-onboarding?invitationToken={invitationToken}`,
    connectionAcceptUrl: `${window && window.location.origin}/buyer/suppliers`,
  };

  await SellerApi.resendCustomerInvitation(currentUser.companyId, invitation);
};

export const deleteCustomer = async (
  currentUser: LoginResponseUserModel,
  customerId: number
) => {
  await SellerApi.deleteCustomer(currentUser.companyId, customerId);
};

export const mapCustomerReponseToOptions = (
  response: AvailableCustomersForOnboardingModel[]
): {
  value: number;
  label: string;
  registryNumber: string;
  vatNo: string;
  countryName: string;
  countryCode: string;
}[] => {
  let options: {
    value: number;
    label: string;
    registryNumber: string;
    vatNo: string;
    countryName: string;
    countryCode: string;
  }[] = [];

  response.forEach((item) => {
    options.push({
      value: item.id,
      label: item.name,
      registryNumber: item.registryNumber,
      vatNo: item.vatNo,
      countryName: item.countryName,
      countryCode: item.countryCode,
    });
  });

  return options;
};

export const mapCustomerContactsToOptions = (
  response: SellerCustomerContactModel[]
): { value: number; label: string; email: string }[] => {
  let options: { value: number; label: string; email: string }[] = [];

  response.forEach((item) => {
    options.push({
      value: item.id,
      label: item.name,
      email: item.email,
    });
  });

  return options;
};

import { CustomerDeleteButton } from 'components/seller/customers/CustomerTables/CustomerDeleteButton';
import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { PriceListExport } from 'components/shared/controls/PriceListExport';
import dayjs from 'dayjs';
import {
  BuyersCompaniesAndUsersDataModel,
  BuyersCompaniesAndUsersModel,
} from 'models/seller/customers/BuyersCompaniesAndUsersModel';
import { BuyersUsersDataModel } from 'models/seller/customers/BuyersUsersModel';
import { Button, Col, Row } from 'react-bootstrap';
import { ArrowReturnRight, Image } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './customerTable.css';
import { SellerBuyerActivate } from './SellerBuyerActivate';
import { SellerBuyerDeactivate } from './SellerBuyerDeactivate';

const customStyles = {
  rows: {
    style: {
      backgroundColor: '#F2F2F2', // override the row height
    },
  },
};

type PropTypes = {
  customers: BuyersCompaniesAndUsersModel;
  showUsers: boolean;
  handlePageChange: (page: number) => Promise<void>;
  handleRowsPerChange: (rowsAmount: number) => Promise<void>;
  handleOnSort: (selectedColumn: any, sortDirection: any) => Promise<void>;
  handlePriceListExport: (
    companyId: number,
    setFileIsSaving: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  handleDeleteCustomer: (id: number) => Promise<void>;
  handleReinviteCustomer: (
    customer: BuyersCompaniesAndUsersDataModel
  ) => Promise<void>;
  resetPaginationToggle: boolean;
  handleCustomerStatusChanged: (id: number, isActive: boolean) => Promise<void>;
};

export const CustomerUserTable = ({
  customers,
  showUsers,
  handlePageChange,
  handleRowsPerChange,
  handleOnSort,
  handlePriceListExport,
  handleDeleteCustomer,
  handleReinviteCustomer,
  resetPaginationToggle,
  handleCustomerStatusChanged,
}: PropTypes): JSX.Element => {
  const { t } = useTranslation('components');
  const dateFormat = 'YYYY-MM-DD, HH:mm';

  const handleStatusChanged = () => {};

  const columns: TableColumn<BuyersCompaniesAndUsersDataModel>[] = [
    {
      name: <Image />,
      selector: (row) => row.initials,
      cell: (row) => (
        <AvatarWithFallback
          initials={row.initials}
          imageUrl={row.logo}
          size='large'
        />
      ),
      maxWidth: '4.5rem',
      minWidth: '4.5rem',
      center: true,
    },
    {
      name: t('customers.lbl_Name'),
      selector: (row) => row.name,
      cell: (row) =>
        row.isActive ? (
          <Link className='link-eleczap' to={`a-customer?id=${row.id}`}>
            {row.name}
          </Link>
        ) : (
          <span>{row.name}</span>
        ),
      sortable: true,
      maxWidth: '11rem',
      minWidth: '11rem',
    },
    {
      name: t('customers.lbl_E-mail'),
      selector: (row) => row.email,
      cell: (row) => (
        <a href={`mailto:${row.email}`} className='link-eleczap'>
          {row.email}
        </a>
      ),
    },

    {
      name: t('customers.lbl_Phone'),
      selector: (row) => row.phone,
    },
    showUsers
      ? {
          name: t('customers.lbl_Position'),
        }
      : {},
    // {
    //   name: <FolderCheck className='icon-customerActiveProject' />,
    //   selector: (row) => row.activeDeals,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },

    // {
    //   name: <FileEarmarkMinusFill className='icon-customerOffer' />,
    //   selector: (row) => row.offers,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },
    // {
    //   name: <CartDashFill className='icon-customerOrder' />,
    //   selector: (row) => row.orders,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },
    // {
    //   name: <ExclamationSquareFill className='icon-customerInvoice' />,
    //   selector: (row) => row.overdueInvoices,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },
    {
      name: t('customers.lbl_PriceList'),
      cell: (row) =>
        row.isInvitationCompleted && row.isActive ? (
          <PriceListExport
            handleExport={(setFileIsSaving) =>
              handlePriceListExport(row.id, setFileIsSaving)
            }
          />
        ) : null,
      selector: (row) => row.priceListUrl,
      center: true,
      maxWidth: '7rem',
      minWidth: '7rem',
      style: {
        color: 'var(--primary)',
      },
    },
    {
      name: t('customers.lbl_LastLogin'),
      cell: (row) =>
        row.isInvitationCompleted ? null : (
          <Button
            onClick={() => handleReinviteCustomer(row)}
            variant='btn btn-eleczap'
          >
            {t('customers.btn_ResendCustomerInvite')}
          </Button>
        ),
      maxWidth: '140px',
      minWidth: '140px',
    },
    {
      name: 'Allowed',
      selector: (row) => row.isActive,
      cell: (row) =>
        row.isInvitationCompleted ? null : (
          <CustomerDeleteButton
            company={row}
            handleDelete={handleDeleteCustomer}
          />
        ),
      sortable: true,
      center: true,
      maxWidth: '5.7rem',
      minWidth: '5.7rem',
    },
    {
      name: t('customers.lbl_Status'),
      cell: (row) =>
        row.isInvitationCompleted ? (
          row.isActive ? (
            <SellerBuyerDeactivate
              company={row}
              statusChanged={handleCustomerStatusChanged}
            />
          ) : (
            <SellerBuyerActivate
              company={row}
              statusChanged={handleCustomerStatusChanged}
            />
          )
        ) : null,
    },
  ];

  const ExpandedComponent = ({ data }: any) => {
    return data.users.map((user: BuyersUsersDataModel) => (
      <Row className='mt-1 mb-1'>
        <Col
          className='text-end'
          style={{
            maxWidth: '7rem',
            minWidth: '7rem',
            alignSelf: 'center',
          }}
        >
          <ArrowReturnRight />
        </Col>
        <Col
          style={{
            maxWidth: '3rem',
            minWidth: '3rem',
            alignSelf: 'center',
          }}
        >
          <AvatarWithFallback
            initials={user.initials}
            imageUrl={user.profileImage}
            size='large'
          />
        </Col>
        <Col
          style={{
            maxWidth: '10rem',
            minWidth: '10rem',
            alignSelf: 'center',
          }}
        >
          {user.fullName}
        </Col>
        <Col
          className='ps-0 wrap-ellipsis'
          style={{
            // maxWidth: '10rem',
            // minWidth: '10rem',
            alignSelf: 'center',
          }}
        >
          <a href={`mailto:${user.email}`} className='link-eleczap'>
            {user.email}
          </a>
        </Col>
        <Col
          style={{
            // maxWidth: '15rem',
            // minWidth: '15rem',
            alignSelf: 'center',
          }}
        >
          {user.phone}
        </Col>
        <Col
          style={{
            // maxWidth: '15rem',
            // minWidth: '15rem',
            alignSelf: 'center',
          }}
        >
          {user.position}
        </Col>
        {/* <Col
          style={{
            maxWidth: '3.7rem',
            minWidth: '3.7rem',
            alignSelf: 'center',
            color: 'var(--primary)',
          }}
        >
          {user.activeDeals}
        </Col>
        <Col
          style={{
            maxWidth: '3.7rem',
            minWidth: '3.7rem',
            alignSelf: 'center',
            color: 'var(--primary)',
          }}
        >
          {user.offers}
        </Col>
        <Col
          style={{
            maxWidth: '3.7rem',
            minWidth: '3.7rem',
            alignSelf: 'center',
            color: 'var(--primary)',
          }}
        >
          {user.orders}
        </Col>
        <Col
          style={{
            maxWidth: '3.7rem',
            minWidth: '3.7rem',
            alignSelf: 'center',
            color: 'var(--primary)',
          }}
        >
          {user.overdueInvoices}
        </Col> */}

        <Col style={{ maxWidth: '5rem', minWidth: '5rem' }} />

        <Col
          style={{
            maxWidth: '250px',
            minWidth: '250px',
            alignSelf: 'center',
          }}
        >
          {user.lastLogin !== null
            ? dayjs(user.lastLogin).format(dateFormat)
            : '-'}
        </Col>
        <Col
          style={{
            alignSelf: 'center',
          }}
        ></Col>
      </Row>
    ));
  };

  const paginationComponentOptions = {
    rowsPerPageText: t('common.lbl_RowsPerPage'),
    rangeSeparatorText: t('common.lbl_Of'),
  };

  return (
    <DataTable
      noDataComponent={
        <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
      }
      highlightOnHover
      responsive
      columns={columns}
      data={customers.data}
      pagination
      expandableRows={showUsers}
      customStyles={customStyles}
      expandableRowsComponent={ExpandedComponent}
      paginationServer
      paginationComponentOptions={paginationComponentOptions}
      paginationTotalRows={customers.totalItems}
      onChangeRowsPerPage={handleRowsPerChange}
      onChangePage={handlePageChange}
      paginationResetDefaultPage={resetPaginationToggle}
      sortServer
      onSort={handleOnSort}
    />
  );
};

import { BuyerApi } from 'api/BuyerApi';
import {
  InquiryCreateModel,
  InquiryModel,
  InquiryRowUpdateModel,
  InquiryUpdateModel,
} from 'models/buyer/documents/InquiryModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';

export const getInquiry = async (
  currentUser: LoginResponseUserModel,
  inquiryId: number,
  localizationId: number
): Promise<InquiryModel> => {
  const response = await BuyerApi.getInquiry(
    currentUser.companyId,
    inquiryId,
    localizationId
  );

  return response;
};

export const updateInquiry = async (
  currentUser: LoginResponseUserModel,
  inquiry: InquiryUpdateModel,
  inquiryId: number
): Promise<void> => {
  await BuyerApi.updateInquiry(currentUser.companyId, inquiryId, inquiry);
};

export const createInquiry = async (
  currentUser: LoginResponseUserModel,
  inquiry: InquiryCreateModel
): Promise<number> => {
  const response = await BuyerApi.createInquiry(currentUser.companyId, inquiry);

  return response;
};

export const updateInquiryRow = async (
  currentUser: LoginResponseUserModel,
  inquiryRowId: number,
  inquiryRow: InquiryRowUpdateModel,
  inquiryId: number
): Promise<void> => {
  await BuyerApi.updateInquiryRow(
    currentUser.companyId,
    inquiryRowId,
    inquiryRow,
    inquiryId
  );
};

import { SellerApi } from 'api/SellerApi';
import { SellerDealsTag } from 'components/seller/dealshome/SellerDealsTag';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { DealModel, DealTagModel } from 'models/seller/deals/DealsModels';
import { useState } from 'react';
import { Badge, Card, FormControl } from 'react-bootstrap';
import { TagFill } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

type PropTypes = {
  currentUser: LoginResponseUserModel;
  getProjects: (loadFromStart?: boolean) => Promise<void>;
  dealsDetail: DealModel;
  t: any;
};

export const SellerDealsTagInput = ({
  currentUser,
  getProjects,
  dealsDetail,
  t,
}: PropTypes) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastTags, setLastTags] = useState<DealTagModel[]>([]);
  const [tagString, setTagString] = useState<string>('');

  const handleClick = async (): Promise<void> => {
    setIsEditing(true);

    const response = await SellerApi.getLastTags(currentUser.companyId);

    setLastTags(response);
  };

  const handleBlur = (): void => {
    setTimeout(() => setIsEditing(false), 400);
  };

  const handleKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      if (tagString.length <= 25) {
        await SellerApi.createTag(currentUser.companyId, dealsDetail.id, {
          name: tagString,
        });

        await getProjects(true);
      } else toast.error(t('common.err_ValueMax25'));

      setIsEditing(false);
    }
  };

  const handleUsedClick = async (tag: DealTagModel) => {
    setIsLoading(true);
    await SellerApi.updateTag(currentUser.companyId, tag.id, dealsDetail.id);

    await getProjects(true);
    setIsLoading(false);
    setIsEditing(false);
  };

  return (
    <>
      <TagFill
        onClick={handleClick}
        size={15}
        id={`recent-tags-toggle${dealsDetail.id}`}
        className='icon-ez tags-toggle'
      />
      {dealsDetail.tags !== null &&
        dealsDetail.tags.map((tag: DealTagModel) => (
          <SellerDealsTag
            currentUser={currentUser}
            getProjects={getProjects}
            dealsDetail={dealsDetail}
            tag={tag}
          />
        ))}
      {isEditing ? (
        <div
          id={`recent-tags-container${dealsDetail.id}`}
          className='recent-tags-container'
        >
          <Card className='recent-tags'>
            <Card.Header>
              <div className='text-center recent-tags-header-text'>
                {t('common.hd_RecentlyUsed')}
              </div>
            </Card.Header>
            <Card.Body>
              <FormControl
                className='tags-input mb-2'
                value={tagString}
                onChange={(event) => setTagString(event.currentTarget.value)}
                onKeyPress={handleKeyPress}
                autoFocus
                onBlur={handleBlur}
              />
              {lastTags !== null &&
                lastTags.map((tag) => (
                  <Badge
                    onClick={isLoading ? () => {} : () => handleUsedClick(tag)}
                    pill
                    className={
                      tag.dealIds.includes(dealsDetail.id)
                        ? 'deals-pill-inverse m-1 cursor-pointer'
                        : 'deals-pill m-1 cursor-pointer'
                    }
                  >
                    {tag.name}
                  </Badge>
                ))}
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

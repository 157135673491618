import FilePicker from '@mavedev/react-file-picker';
import { IdentityApi } from 'api/IdentityApi';
import {
  InquiryDisabled,
  InquiryNoReplySeller,
  InquiryOfferSent,
  InquirySending,
  OfferExpired,
} from 'assets/icons/StatusIcons';
import { ExternalSellerContactProfileModal } from 'components/seller/externalSeller/ExternalSellerContactProfileModal';
import { ExternalSellerCustomerProfileModal } from 'components/seller/externalSeller/ExternalSellerCustomerProfileModal';
import { ExternalSellerRecallModal } from 'components/seller/externalSeller/ExternalSellerRecallModal';
import { ExternalSellerUpdateOfferModal } from 'components/seller/externalSeller/ExternalSellerUpdateOfferModal';
import { CustomExternalSellerRowInput } from 'components/shared/CustomInputs/CustomExternalSellerRowInput';
import { ExternalSellerAttachmentItem } from 'components/shared/deals/ProjectAttachmentItem/ExternalSellerAttachmentItem';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { SellerFooter } from 'layout/SellerFooter';
import { SellerHeader } from 'layout/SellerHeader';
import { ExternalSellerBuyerContactModel } from 'models/seller/contacts/ExternalSellerBuyerContactModel';
import { CustomerCompanyModel } from 'models/seller/customers/CustomerCompanyModel';
import { ExternalSellerInquiryRowChangeType } from 'models/seller/documents/ExternalSellerInquiryModels';
import {
  ExternalSellerOfferModel,
  ExternalSellerOfferRowExtendedModel,
  ExternalSellerOfferUpsertModel,
} from 'models/seller/documents/ExternalSellerOfferModels';
import {
  bulkEditExternalSellerOfferRows,
  createExternalSellerOfferRows,
  mapExternalSellerOfferRows,
} from 'pages/seller/Inquiry/utilsInquiry';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  ArrowCounterclockwise,
  ArrowRepeat,
  Paperclip,
  PlusCircleFill,
  Trash,
} from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useTranslation } from 'react-i18next';
import { GiCherry } from 'react-icons/gi';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { formatMonetaryValueWithoutDecimals } from 'utils/formatMonetaryValueWithoutDecimals';
import { getUrlParams } from 'utils/getUrlParams';
import { truncateDecimals } from 'utils/truncateDecimals';

export const ExternalSellerOffer = () => {
  const [offer, setOffer] = useState<ExternalSellerOfferModel>(
    {} as ExternalSellerOfferModel
  );
  const [offerUpdate, setOfferUpdate] =
    useState<ExternalSellerOfferUpsertModel>(
      {} as ExternalSellerOfferUpsertModel
    );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [allowCherryPicking, setAllowCherryPicking] = useState<boolean>(true);

  const [paymentTerms, setPaymentTerms] = useState<string>('');
  const [deliveryTerms, setDeliveryTerms] = useState<string>('');
  const [offerValidUntil, setOfferValidUntil] = useState<string | null>('');
  const [isoDate, setIsoDate] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [yourComment, setYourComment] = useState<string>('');
  const [extendedRows, setExtendedRows] = useState<
    ExternalSellerOfferRowExtendedModel[]
  >([]);

  const [customer, setCustomer] = useState<CustomerCompanyModel>(
    {} as CustomerCompanyModel
  );
  const [contact, setContact] = useState<ExternalSellerBuyerContactModel>(
    {} as ExternalSellerBuyerContactModel
  );

  const [documentTotal, setDocumentTotal] = useState<number>(0);

  const [headerExpectedDelivery, setHeaderExpectedDelivery] = useState<
    string | null
  >(null);

  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');
  const validateUnitExists = useValidateUnitExists();

  const isDocumentUneditable: boolean = offer.isReadonly;

  let accessToken = getUrlParams('accessToken');

  const recallInquiryUpdated = useCallback(
    async (offer: ExternalSellerOfferModel) => {
      if (accessToken && offer.inquiryUpdated) {
        await IdentityApi.recallInquiryUpdated(accessToken);
        setShowAlert(false);
      }
    },
    [accessToken]
  );

  const getOffer = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    if (accessToken !== null) {
      const response = await IdentityApi.getExternalSellerOffer(accessToken);

      setPaymentTerms(response.paymentTerm);
      setDeliveryTerms(response.deliveryTerm);
      setOfferValidUntil(
        response.validUntil
          ? dayjs(response.validUntil).format('DD-MM-YYYY')
          : t('common.lbl_DDMMYYYY')
      );
      setHeaderExpectedDelivery(response.expectedDelivery);

      setIsoDate(dayjs(response.validUntil).toISOString());
      setAllowCherryPicking(response.allowSplitting);
      setYourComment(response.comment);

      setShowAlert(response.inquiryUpdated);

      setOffer(response);
      setIsLoading(false);

      setTimeout(() => recallInquiryUpdated(response), 5000);
    }
  }, [accessToken, recallInquiryUpdated]);

  useEffect(() => {
    accessToken && getOffer();
  }, [accessToken, getOffer]);

  /* Get customer contact */
  useEffect(() => {
    const getContact = async () => {
      if (accessToken) {
        const response =
          await IdentityApi.getExternalSellerCustomerContactProfile(
            accessToken,
            'inquiry'
          );

        setContact(response);
      }
    };

    getContact();
  }, [accessToken]);

  /* Get customer profile */
  useEffect(() => {
    const getCustomer = async () => {
      if (accessToken) {
        const response = await IdentityApi.getExternalSellerCustomerProfile(
          accessToken,
          'inquiry'
        );

        setCustomer(response);
      }
    };

    getCustomer();
  }, [accessToken]);

  /* Get mapped rows */
  useEffect(() => {
    if (offer !== undefined && offer.rows !== undefined) {
      const mappedRows = mapExternalSellerOfferRows(offer.rows);

      setExtendedRows(mappedRows);
    }
  }, [offer, offer.rows]);

  /* Calculate total of document */
  useEffect(() => {
    let sum = 0;

    for (const row of extendedRows) {
      sum += (row.price ?? 0) * (row.quantity ?? 0);
    }

    setDocumentTotal(sum);
  }, [extendedRows]);

  const handleRowChange = (
    rowChangeValues: ExternalSellerInquiryRowChangeType,
    rowNo: number,
    column: 'code' | 'name' | 'qty' | 'price' | 'expectedDelivery'
  ) => {
    const clonedExtendedRows = [...extendedRows];
    const {
      replacedProductCode,
      replacedProductName,
      quantity,
      price,
      expectedDelivery,
    } = rowChangeValues;

    const index = clonedExtendedRows.findIndex((row) => row.rowNo === rowNo);

    switch (column) {
      case 'code':
        if (clonedExtendedRows[index].isReplaced)
          clonedExtendedRows[index].replacedProductCode = replacedProductCode;
        if (clonedExtendedRows[index].isNew)
          clonedExtendedRows[index].productCode = replacedProductCode;
        break;

      case 'name':
        if (clonedExtendedRows[index].isReplaced)
          clonedExtendedRows[index].replacedProductName = replacedProductName;
        if (clonedExtendedRows[index].isNew)
          clonedExtendedRows[index].name = replacedProductName;
        break;

      case 'qty':
        clonedExtendedRows[index].quantity = quantity;
        break;

      case 'price':
        clonedExtendedRows[index].price =
          price && price !== '' ? parseFloat(price) : null;
        break;
      case 'expectedDelivery':
        clonedExtendedRows[index].expectedDelivery = expectedDelivery;
        break;
      default:
        break;
    }

    setExtendedRows(clonedExtendedRows);
  };

  const toggleShowReplacementInputs = (rowNo: number) => {
    const clonedExtendedRows = [...extendedRows];
    const index = clonedExtendedRows.findIndex((row) => row.rowNo === rowNo);

    clonedExtendedRows[index].showReplacebleInputs =
      !clonedExtendedRows[index].showReplacebleInputs;

    clonedExtendedRows[index].isReplaced =
      !clonedExtendedRows[index].isReplaced;

    if (!clonedExtendedRows[index].showReplacebleInputs) {
      clonedExtendedRows[index].replacedProductCode = null;
      clonedExtendedRows[index].replacedProductName = null;
    }

    setExtendedRows(clonedExtendedRows);
  };

  const handleDateChange = async (day: Date) => {
    const pickedDate = dayjs(day).format('DD-MM-YYYY');

    setOfferValidUntil(pickedDate);
    setIsoDate(dayjs(day).toISOString());
  };

  const disabledDays = {
    from: new Date(
      dayjs().startOf('month').year(),
      dayjs().startOf('month').month(),
      1
    ),
    to: new Date(),
  };

  const updateLocalOffer = useCallback(() => {
    if (accessToken !== null) {
      const finalRows = createExternalSellerOfferRows(extendedRows);

      const updateData: ExternalSellerOfferUpsertModel = {
        deliveryTerm: deliveryTerms,
        paymentTerm: paymentTerms,
        validUntil: isoDate,
        comment: yourComment,
        expectedDelivery: headerExpectedDelivery,
        rows: finalRows,
        allowSplitting: allowCherryPicking,
      };

      setOfferUpdate(updateData);
    }
  }, [
    accessToken,
    extendedRows,
    deliveryTerms,
    paymentTerms,
    isoDate,
    yourComment,
    headerExpectedDelivery,
    allowCherryPicking,
  ]);

  useEffect(() => {
    updateLocalOffer();
  }, [updateLocalOffer]);

  const handleAddEmptyRow = () => {
    const clonedExtendedRows = [...extendedRows];

    const newRowNo =
      Math.max(...clonedExtendedRows.map((row) => row.rowNo)) + 1;

    clonedExtendedRows.push({
      rowNo: newRowNo,
      targetPrice: null,
      isNew: true,
    } as unknown as ExternalSellerOfferRowExtendedModel);

    setExtendedRows(clonedExtendedRows);
  };

  const handleDeleteRow = (rowNo: number) => {
    const clonedExtendedRows = [...extendedRows];
    const filtered = clonedExtendedRows.filter((row) => row.rowNo !== rowNo);

    setExtendedRows(filtered);
  };

  const handleHeaderWeeksChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    const changedRows = bulkEditExternalSellerOfferRows(extendedRows, {
      fieldName: 'expectedDelivery',
      fieldValue: {
        expectedDelivery: value,
      } as ExternalSellerInquiryRowChangeType,
    });

    setHeaderExpectedDelivery(value);

    setExtendedRows(changedRows);
  };

  const columns: TableColumn<ExternalSellerOfferRowExtendedModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      sortable: false,
      width: '60px',
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode ?? 'productCode',
      cell: (row) => (
        <div className='d-flex align-items-start flex-column justify-content-start'>
          {row.showReplacebleInputs || row.isReplaced ? (
            <CustomExternalSellerRowInput
              isDocumentUneditable={isDocumentUneditable}
              cellValue={row.replacedProductCode}
              colName='code'
              updateRow={(updatedValue: string | null) =>
                handleRowChange(
                  {
                    replacedProductCode: updatedValue,
                  } as ExternalSellerInquiryRowChangeType,
                  row.rowNo,
                  'code'
                )
              }
            />
          ) : row.showReplacebleInputs || row.isNew ? (
            <CustomExternalSellerRowInput
              isDocumentUneditable={isDocumentUneditable}
              cellValue={row.productCode}
              colName='code'
              updateRow={(updatedValue: string | null) =>
                handleRowChange(
                  {
                    replacedProductCode: updatedValue,
                  } as ExternalSellerInquiryRowChangeType,
                  row.rowNo,
                  'code'
                )
              }
            />
          ) : (
            <span>{row.productCode}</span>
          )}
          {(row.showReplacebleInputs || row.isReplaced) && (
            <small>{row.productCode}</small>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name ?? 'name',
      cell: (row) => (
        <div className='d-flex align-items-start flex-column justify-content-start'>
          {row.showReplacebleInputs || row.isReplaced ? (
            <CustomExternalSellerRowInput
              cellValue={row.replacedProductName}
              isDocumentUneditable={isDocumentUneditable}
              colName='name'
              updateRow={(updatedValue: string | null) =>
                handleRowChange(
                  {
                    replacedProductName: updatedValue,
                  } as ExternalSellerInquiryRowChangeType,
                  row.rowNo,
                  'name'
                )
              }
            />
          ) : row.showReplacebleInputs || row.isNew ? (
            <CustomExternalSellerRowInput
              isDocumentUneditable={isDocumentUneditable}
              cellValue={row.name}
              colName='name'
              updateRow={(updatedValue: string | null) =>
                handleRowChange(
                  {
                    replacedProductName: updatedValue,
                  } as ExternalSellerInquiryRowChangeType,
                  row.rowNo,
                  'name'
                )
              }
            />
          ) : (
            <span>{row.name}</span>
          )}
          {(row.showReplacebleInputs || row.isReplaced) && (
            <small>{row.name}</small>
          )}
        </div>
      ),
      sortable: false,
      grow: 4,
    },
    {
      name: t('documents.lbl_ExpectedDelivery'),
      selector: (row) => row.expectedDelivery ?? 'expectedDelivery',
      cell: (row) => (
        <>
          <div className='d-flex align-items-end flex-column justify-content-start'>
            {isDocumentUneditable ? (
              row.expectedDelivery
            ) : (
              <CustomExternalSellerRowInput
                isDocumentUneditable={isDocumentUneditable}
                cellValue={row.expectedDelivery}
                colName='expectedDelivery'
                updateRow={(updatedValue: string | null) =>
                  handleRowChange(
                    {
                      expectedDelivery: updatedValue,
                    } as unknown as ExternalSellerInquiryRowChangeType,
                    row.rowNo,
                    'expectedDelivery'
                  )
                }
              />
            )}
          </div>
        </>
      ),
      sortable: false,
      center: true,
    },
    {
      name: t('documents.lbl_Qty'),
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          <div className='d-flex align-items-end flex-column justify-content-end'>
            {row.isNew && (
              <CustomExternalSellerRowInput
                isDocumentUneditable={isDocumentUneditable}
                cellValue={row.quantity}
                isNumberField
                colName='qty'
                updateRow={(updatedValue: number | null) =>
                  handleRowChange(
                    {
                      quantity: updatedValue,
                    } as ExternalSellerInquiryRowChangeType,
                    row.rowNo,
                    'qty'
                  )
                }
              />
            )}
            {!row.isNew && (
              <span>
                {row.quantity} <small>{validateUnitExists(row.unit)}</small>
              </span>
            )}
          </div>
        </>
      ),
      maxWidth: '70px',
      sortable: false,
      right: true,
    },
    {
      name: <span className='text-end'>{t('documents.lbl_TargetPrice')}</span>,
      selector: (row) => row.targetPrice,
      cell: (row) =>
        `${formatMonetaryValueWithoutDecimals(
          offer.currencyCode,
          row.targetPrice,
          appState
        )}`,
      sortable: false,
      right: true,
    },
    {
      name: t('documents.lbl_PriceUnit'),
      selector: (row) => row.price ?? 'price',
      cell: (row) =>
        isDocumentUneditable ? (
          row.price ?? '-'
        ) : (
          <CustomExternalSellerRowInput
            isDocumentUneditable={isDocumentUneditable}
            cellValue={row.price}
            rowsLength={extendedRows.length}
            colName='price'
            rowNo={row.rowNo}
            updateRow={(updatedValue: number | null) =>
              handleRowChange(
                { price: updatedValue } as ExternalSellerInquiryRowChangeType,
                row.rowNo,
                'price'
              )
            }
          />
        ),
      sortable: false,
      center: true,
    },
    {
      name: t('documents.lbl_Replace'),
      selector: (row) => row.allowReplacement,
      cell: (row) =>
        row.isNew ? (
          <Trash
            className='icon-ez-red cursor-pointer'
            onClick={() => handleDeleteRow(row.rowNo)}
          />
        ) : row.allowReplacement ? (
          row.showReplacebleInputs || row.isReplaced ? (
            <ArrowRepeat
              onClick={() => toggleShowReplacementInputs(row.rowNo)}
              className='icon-ez-red cursor-pointer'
            />
          ) : (
            <ArrowRepeat
              onClick={() => toggleShowReplacementInputs(row.rowNo)}
              className='icon-ez cursor-pointer'
            />
          )
        ) : (
          <ArrowRepeat className='icon-ez-gray' />
        ),
      sortable: false,
      center: true,
      omit: isDocumentUneditable,
    },
    {
      name: t('common.lbl_Total'),
      selector: (row) => 'total',
      cell: (row) =>
        row.price && row.quantity
          ? formatMonetaryValue(
              offer.currencyCode,
              row.price * row.quantity,
              appState
            )
          : '-',
      right: true,
    },
  ];

  const inputFile = useRef<HTMLInputElement>(null);

  const handleOpenFilePicker = () => {
    inputFile.current !== null && inputFile.current.click();
  };

  const handleDeleteAttachment = async (id: number) => {
    let timeout: any = 0;

    toast.success(t('projectSettings.inf_DeletingFile'), {
      className: 'delete-attachment-toast',
      autoClose: 5000,
      pauseOnHover: true,
      closeButton: (
        <Button
          className='btn-undo-delete-attachment'
          onClick={() => clearTimeout(timeout)}
        >
          <ArrowCounterclockwise size={20} />
        </Button>
      ),
    });

    timeout = setTimeout(async () => {
      accessToken &&
        (await IdentityApi.deleteExternalSellerOfferAttachment(
          accessToken,
          id,
          'Offer'
        ));

      await getOffer();
    }, 5000);
  };

  const handleAddNewFile = async (file: File) => {
    const reader = new FileReader();
    const formData = new FormData();

    formData.append('file', file);
    formData.append('documentType', 'Offer');

    const input: HTMLInputElement | null =
      document.querySelector('input[type=file]');

    if (input !== null) {
      input.value = '';
    }

    reader.readAsDataURL(file);
    reader.onload = async function () {
      reader.result !== undefined &&
        accessToken &&
        (await IdentityApi.addExternalSellerOfferAttachment(
          accessToken,
          formData
        ));

      await getOffer();
    };
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <SellerHeader requiresNoAuth localizationId={offer.localizationId ?? 1} />
      <div className='container-fluid p-2'>
        {showAlert && (
          <Alert
            style={{
              opacity: showAlert ? '1' : '0',
              transitionDuration: '0.2s',
              zIndex: 3000,
              right: 0,
              top: '10px',
            }}
            variant='info'
            className='p-2 position-absolute'
          >
            <h3 className='text-center p-2 pb-0'>
              {t('documents.inf_InquiryHasUpdated')}
            </h3>
          </Alert>
        )}
        <Card>
          <Card.Header>
            <Row>
              <Col lg={4}>
                <ExternalSellerCustomerProfileModal company={customer} />
                <ExternalSellerContactProfileModal
                  companyName={customer.name}
                  contact={contact}
                />

                <div className='d-flex align-items-center'>
                  <Dropdown className='me-4' as={ButtonGroup}>
                    <Dropdown.Toggle
                      id='project-view-attachments-toggle'
                      className='btn btn-eleczap dropdown-toggle ms-3'
                    >
                      <Paperclip /> {t('common.btn_AllFiles')}(
                      {offer.attachments?.length})
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='super-colors'>
                      {offer.attachments?.map((file, index: number) => (
                        <ExternalSellerAttachmentItem
                          handleDeleteAttachment={handleDeleteAttachment}
                          attachment={file}
                          index={index}
                          accessToken={''}
                          type={'Offer'}
                        />
                      ))}

                      <Dropdown.Divider />
                      <FilePicker
                        maxSize={10}
                        sizeUnit='MB'
                        extensions={[
                          '.jpg',
                          '.png',
                          '.jpeg',
                          '.pdf',
                          '.txt',
                          '.doc',
                          '.docx',
                          '.xls',
                          '.xlsx',
                          '.ppt',
                          '.pptx',
                          '.dwg',
                          '.dxf',
                        ]}
                        onFilePicked={(file) => {
                          handleAddNewFile(file);
                        }}
                        onError={(code) => {
                          toast.error(code);
                        }}
                      >
                        <Dropdown.Item
                          onClick={handleOpenFilePicker}
                          eventKey='4'
                        >
                          {t('projectSettings.lbl_AddNewAttachment')}
                        </Dropdown.Item>
                      </FilePicker>
                    </Dropdown.Menu>
                  </Dropdown>

                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>{t('documents.lbl_AllowCherryPicking')}</Tooltip>
                    }
                  >
                    <div className='d-flex justify-content-center'>
                      <Switch
                        checked={allowCherryPicking}
                        onChange={(checked) => setAllowCherryPicking(checked)}
                        onColor='#1b9ad2'
                        className='react-switch'
                        id='icon-switch'
                        checkedHandleIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 15,
                              paddingRight: 2,
                            }}
                          >
                            <GiCherry className='icon-ez' />
                          </div>
                        }
                        uncheckedHandleIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 15,
                              paddingRight: 2,
                            }}
                          >
                            <GiCherry className='icon-ez-gray' />
                          </div>
                        }
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </Col>
              <Col className='text-center' lg={4}>
                <h2>
                  {t('documents.hd_Offer')} {offer.code}
                </h2>
              </Col>
              <Col
                className='text-center d-flex justify-content-lg-end justify-content-sm-center'
                lg={4}
              >
                <h3>
                  {offer.status === 'Sending' && (
                    <>
                      {t('documents.lbl_Sending')}{' '}
                      <InquirySending className='icon-ez-gray' />
                    </>
                  )}
                  {offer.status === 'Unsent' && (
                    <>
                      {t('documents.lbl_Unsent')}{' '}
                      <InquiryDisabled className='icon-ez-gray' />
                    </>
                  )}
                  {offer.status === 'NoReply' && (
                    <>
                      {t('documents.lbl_NoReply')}{' '}
                      <InquiryNoReplySeller className='icon-ez-yellow' />
                    </>
                  )}
                  {offer.status === 'OrderMade' && (
                    <>
                      {t('documents.lbl_OrderMade')}{' '}
                      <InquiryOfferSent className='icon-ez' />
                    </>
                  )}

                  {offer.status === 'Expired' && (
                    <>
                      {t('documents.lbl_Expired')}{' '}
                      <OfferExpired className='icon-ez-gray' />
                    </>
                  )}
                </h3>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <Row className='mb-0'>
                  <Col className='document-header-left-col text-nowrap'>
                    {t('documents.lbl_InquiryReceived')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(offer.inquiryDate).format('DD.MM.YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_OfferDeadline')}:
                  </Col>
                  <Col>
                    <strong
                      className={
                        dayjs().isAfter(offer.offerDeadlineDate)
                          ? 'icon-ez-red'
                          : ''
                      }
                    >
                      {offer.offerDeadlineDate === null
                        ? '-'
                        : dayjs(offer.offerDeadlineDate).format('DD.MM.YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_OfferSent')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(offer.documentDate).format('DD.MM.YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('projectSettings.lbl_RequestedDeliveryDate')}:
                  </Col>
                  <Col>
                    <strong>
                      {offer.requestedDeliveryDate
                        ? dayjs(offer.requestedDeliveryDate).format(
                            'DD.MM.YYYY'
                          )
                        : '-'}
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_InquiryTo')}:
                  </Col>
                  <Col>
                    <strong>{offer.sentToContactName}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'></Col>
                  <Col>
                    <strong>{offer.sentToCompanyName}</strong>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row className='flex-nowrap'>
                  <Col className='text-end pe-0 text-nowrap'>
                    <Row className='mb-0'>
                      <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                    </Row>
                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_CustomerInquiryNumber')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                    </Row>

                    {offer.deliveryAddressLine2 !== null &&
                      offer.deliveryAddressLine2 !== '' && <br />}

                    {offer.deliveryAddressLine3 !== null &&
                      offer.deliveryAddressLine3 !== '' && <br />}

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_DeliveryTerms')}:</Col>
                    </Row>
                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_PaymentTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_OfferValidUntil')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_ExpectedDelivery')}:</Col>
                    </Row>
                  </Col>

                  <Col
                    className='pe-3 d-flex flex-column'
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        <span className='btn-link-bold-no-link'>
                          {offer.projectName}
                        </span>
                      </Col>
                    </Row>
                    <Row className='mb-0'>
                      <span className='text-start document-header-right-col'>
                        {offer.code !== null && offer.code !== '' ? (
                          <strong>{offer.code}</strong>
                        ) : (
                          <br />
                        )}
                      </span>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='d-flex flex-column flex-nowrap text-nowrap'>
                        {offer.deliveryAddressLine1 !== null ? (
                          <strong>{offer.deliveryAddressLine1}</strong>
                        ) : (
                          <br />
                        )}
                        <strong>
                          {offer.deliveryAddressLine2 !== null ||
                          offer.deliveryAddressLine2 !== '' ? (
                            offer.deliveryAddressLine2
                          ) : (
                            <br />
                          )}
                        </strong>
                        <strong>
                          {offer.deliveryAddressLine3 !== null ||
                          offer.deliveryAddressLine3 !== '' ? (
                            offer.deliveryAddressLine3
                          ) : (
                            <br />
                          )}
                        </strong>
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>
                        {!isDocumentUneditable ? (
                          <FormControl
                            className='shorter-height-fields'
                            value={deliveryTerms}
                            onChange={(event) =>
                              setDeliveryTerms(event.target.value)
                            }
                          />
                        ) : (
                          <span>{deliveryTerms ?? <br />}</span>
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>
                        {!isDocumentUneditable ? (
                          <FormControl
                            className='shorter-height-fields'
                            value={paymentTerms}
                            disabled={isDocumentUneditable}
                            onChange={(event) =>
                              setPaymentTerms(event.target.value)
                            }
                          />
                        ) : (
                          <span>{paymentTerms ?? <br />}</span>
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>
                        {isDocumentUneditable ? (
                          <strong>{offerValidUntil}</strong>
                        ) : (
                          <DayPickerInput
                            dayPickerProps={{
                              fromMonth: new Date(),
                              disabledDays: disabledDays,
                            }}
                            style={{ width: '100%' }}
                            component={(props: any) => (
                              <InputGroup>
                                <FormControl
                                  readOnly
                                  className='shorter-height-fields'
                                  {...props}
                                />
                              </InputGroup>
                            )}
                            value={
                              offerValidUntil === 'Invalid Date'
                                ? t('common.lbl_DDMMYYYY')
                                : offerValidUntil ?? t('common.lbl_DDMMYYYY')
                            }
                            onDayChange={handleDateChange}
                          />
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Select
                          className='shorter-height-fields'
                          value={headerExpectedDelivery ?? 'null'}
                          onChange={handleHeaderWeeksChange}
                        >
                          <option value={'null'}>-</option>
                          <option value='In stock'>
                            {t('common.lbl_Instock')}
                          </option>
                          {Array.from(Array(30).keys()).map((week, index) => (
                            <option
                              value={`${index + 1} ${
                                index === 0 ? 'Week' : 'Weeks'
                              }`}
                            >{`${index + 1} ${
                              index === 0
                                ? t('documents.lbl_Week')
                                : t('documents.lbl_Weeks')
                            }`}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='my-5'>
              <DataTable
                noDataComponent={
                  <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
                }
                columns={columns}
                data={extendedRows}
              />
              {!isDocumentUneditable && (
                <span
                  onClick={handleAddEmptyRow}
                  className='icon-ez ms-3 cursor-pointer'
                >
                  <PlusCircleFill className='icon-ez me-3' />{' '}
                  {t('documents.lbl_AddNewRow')}
                </span>
              )}
            </Row>

            <Row className='mb-3'>
              <Col lg={6}>
                <span>{t('documents.lbl_CustomerComments')} : </span>{' '}
                {offer.inquiryComment}
              </Col>
            </Row>

            <Row className='justify-content-between'>
              <Col lg={6}>
                <Form.Group className='mb-3' controlId='comments'>
                  <Form.Label>{t('documents.lbl_YourComments')}</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={4}
                    value={yourComment}
                    onChange={(event) => setYourComment(event.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Row className='mt-3'>
                  <Col>
                    <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                  </Col>
                  <Col>
                    <h3 className='text-end'>
                      {useFormatMonetaryValue(
                        offer.currencyCode,
                        truncateDecimals(documentTotal, 2)
                      )}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-end'>
                    {t('common.inf_TotalPriceSubtext')}
                  </Col>
                </Row>
              </Col>
            </Row>
            {!isDocumentUneditable && (
              <Row>
                <Col>
                  <ExternalSellerRecallModal
                    accessToken={accessToken}
                    offerNumber={offer.code}
                    getOffer={getOffer}
                  />
                </Col>
                <Col>
                  <span className='float-end'>
                    <ExternalSellerUpdateOfferModal
                      accessToken={accessToken}
                      count={
                        extendedRows.filter((row) => row.price !== null).length
                      }
                      offerNumber={offer.code}
                      offer={offerUpdate}
                      getOffer={getOffer}
                    />
                  </span>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </div>
      <SellerFooter />
    </>
  );
};

import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  contactName: string;
  customerName: string;
  id: number;
  handleRemoveAssignedContact: (id: number) => Promise<void>;
};

export const ModalRemoveAssignedContact = ({
  contactName,
  customerName,
  handleRemoveAssignedContact,
  id,
}: PropTypes) => {
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const handleShow = (): void => {
    setShowRemoveModal(true);
  };

  const handleClose = (): void => {
    setShowRemoveModal(false);
  };

  const handleOkRemove = async (): Promise<void> => {
    await handleRemoveAssignedContact(id);
    handleClose();
  };

  return (
    <>
      <Trash
        onClick={handleShow}
        size={18}
        className='icon-ez-red cursor-pointer'
      />
      <Modal show={showRemoveModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('customers.hd_RemoveAContact')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {t('customers.inf_RemoveAContact', { contactName, customerName })}
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='danger' onClick={handleOkRemove}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

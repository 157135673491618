import { BuyerProjectRowInfoGroupedModel } from 'models/buyer/project/GroupedProjectModels';
import { ProjectOrderRowUpdateModel } from 'models/buyer/projectOrder/ProjectOrderModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { updateProjectOrderRows } from 'pages/buyer/ProjectOrder/utilsProjectOrder';
import { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';

type PropTypes = {
  inputString: string;
  currentUser: LoginResponseUserModel;
  row: BuyerProjectRowInfoGroupedModel;
  projectId: number;
  projectOrderId: number;
  getDataSource: () => Promise<void>;
  isHovered: boolean;
};

export const CustomEditableProductCode = ({
  inputString,
  currentUser,
  row,
  projectId,
  projectOrderId,
  getDataSource,
  isHovered,
}: PropTypes) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(inputString);
  }, [inputString]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSaveName = async () => {
    setIsEditing(false);

    let clonedRows = { ...row };

    const updatedRows: ProjectOrderRowUpdateModel[] = [];

    clonedRows.rows.forEach((clonedRow) => {
      const rowUpdate: ProjectOrderRowUpdateModel = {
        productId: clonedRow.productId,
        requiredQuantity: clonedRow.requiredQuantity,
        supplierQuantity: clonedRow.supplierQuantity,
        productFreeName: clonedRow.name,
        productCode: value,
        id: clonedRow.id,
        updateRequiredQuantity: false,
        updateSupplierQuantity: false,
      };

      updatedRows.push(rowUpdate);
    });

    await updateProjectOrderRows(
      updatedRows,
      currentUser,
      projectId,
      projectOrderId
    );
    await getDataSource();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSaveName();
    }
  };

  return isEditing ? (
    <Form.Control
      onKeyDown={handleKeyDown}
      onBlur={handleSaveName}
      autoFocus
      onChange={handleInputChange}
      value={value}
      className='borderless border-0 wrap-ellipsis'
    />
  ) : (
    <span
      className={
        row.rows[0].isDisabled
          ? 'icon-ez-light-gray wrap-ellipsis'
          : 'wrap-ellipsis'
      }
      onClick={() => setIsEditing(true)}
    >
      {value}
      {isHovered && (
        <PencilSquare
          className='icon-ez ms-2 cursor-pointer'
          onClick={() => setIsEditing(true)}
        />
      )}
    </span>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { useFormik } from 'formik';
import {
  BuyerGlobalNotificationsModel,
  BuyerGlobalNotificationsUpdateModel,
  BuyerPeronalNotificationsModel,
  BuyerPeronalNotificationsUpdateModel,
} from 'models/buyer/notifications/BuyerNotificationsModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useFormikHookForBuyerGlobalNotifications = (
  globalNotifications: BuyerGlobalNotificationsModel
) => {
  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleSubmit = async (
    updatedGlobalSettings: BuyerGlobalNotificationsUpdateModel
  ) => {
    await BuyerApi.updateBuyerGlobalNotifications(
      currentUser.companyId,
      updatedGlobalSettings
    );
  };

  const validationSchema = yup.object({
    newOfferEmail: yup
      .string()
      .nullable()
      .email(t('common.err_EmailInvalid'))
      .max(50, t('common.err_ValueMax50')),

    buyerNewOrderEmail: yup
      .string()
      .nullable()
      .email(t('common.err_EmailInvalid'))
      .max(50, t('common.err_ValueMax50')),

    newInvoiceEmail: yup
      .string()
      .nullable()
      .email(t('common.err_EmailInvalid'))
      .max(50, t('common.err_ValueMax50')),
  });

  const formik = useFormik<BuyerGlobalNotificationsModel>({
    initialValues: { ...globalNotifications },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      const updatedGlobalSettings: BuyerGlobalNotificationsUpdateModel = {
        newOfferReceived: values.newOfferReceived,
        newOfferEmail: values.newOfferEmail,
        buyerNewOrderReceived: values.buyerNewOrderReceived,
        buyerNewOrderEmail: values.buyerNewOrderEmail,
        newInvoiceReceived: values.newInvoiceReceived,
        newInvoiceEmail: values.newInvoiceEmail,
      };

      await handleSubmit(updatedGlobalSettings);
    },
  });

  return formik;
};

export const useFormikHookForBuyerPersonalNotifications = (
  peronalNotifications: BuyerPeronalNotificationsModel
) => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleSubmit = async (
    updatedPersonalSettings: BuyerPeronalNotificationsUpdateModel
  ) => {
    await BuyerApi.updateBuyerPersonalNotifications(
      currentUser.companyId,
      currentUser.id,
      updatedPersonalSettings
    );
  };

  const formik = useFormik<BuyerPeronalNotificationsModel>({
    initialValues: { ...peronalNotifications },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const updatedPersonalSettings: BuyerPeronalNotificationsUpdateModel = {
        inquiryDeclined: values.inquiryDeclined,
        offerReceived: values.offerReceived,
        offerUpdated: values.offerUpdated,
        orderUpdated: values.orderUpdated,
        orderConfirmed: values.orderConfirmed,
        orderDispatched: values.orderDispatched,
        invoiceReceived: values.invoiceReceived,
        orderSent: values.orderSent,
      };

      await handleSubmit(updatedPersonalSettings);
      formik.setSubmitting(false);
    },
  });

  return formik;
};

import { ApplicationSettingsApi } from 'api/ApplicationSettingsApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppContext } from 'contexts/user/AppContext';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { ChangePasswordModel } from 'models/shared/user/ChangePasswordModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { UserUpdateModel } from 'models/shared/user/UserUpdateModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Api } from '../../../api/Api';
import { ChangePassword } from './ChangePassword';
import { UserSettings } from './UserSettings';

type AccountProps = {
  user: UserModelById;
  setUser: (user: UserModelById) => void;
  viewType: 'Seller' | 'Buyer';
};

export const Account = ({ user, setUser, viewType }: AccountProps) => {
  const [localizations, setLocalizations] = useState<LocalizationModel[]>([]);

  const { t, i18n } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const setUserProfile = useSetUserProfile();

  /* Localization dropdown data */
  useEffect(() => {
    const getLocalizations = async () => {
      const localizations = await ApplicationSettingsApi.getLocalizations();
      setLocalizations(localizations);
    };
    getLocalizations();
  }, []);

  const handleUserSettingsSubmit = async (user: UserUpdateModel) => {
    toast.dismiss();
    await Api.updateUser(currentUser.companyId, currentUser.id, user);

    const updatedUser = await setUserProfile(
      appState.buyerCurrentSupplierId || null
    );
    setUser(updatedUser);

    const selectedLng = localizations.find(
      (l) => l.id === Number(user.localizationId)
    );
    i18n.changeLanguage(selectedLng?.cultureName?.slice(0, 2));

    toast.info(t('account.inf_UserSettingsSaved'));
  };

  const handleChangePasswordSubmit = async (
    passwordInfo: ChangePasswordModel
  ) => {
    toast.dismiss();
    await Api.changePassword(
      currentUser.companyId,
      currentUser.id,
      passwordInfo
    );
    toast.info(t('account.inf_PasswordUpdated'));
  };

  return (
    <Row className='flex-md-column flex-lg-row'>
      <Col>
        <UserSettings
          localizations={localizations}
          user={user}
          viewType={viewType}
          handleSubmit={handleUserSettingsSubmit}
        />
      </Col>
      <Col lg={4}>
        <ChangePassword handleSubmit={handleChangePasswordSubmit} />
      </Col>
    </Row>
  );
};

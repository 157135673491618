import { BuyerApi } from 'api/BuyerApi';
import { getAllSuppliersAndContacts } from 'components/buyer/suppliers/utilsSuplliers';
import {
  SellersCompaniesAndContactsDataModel,
  SellersCompaniesAndContactsModel,
} from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useAuthUser } from 'react-auth-kit';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  isSupplierArchived: () => {
    isActive: boolean;
    externalSupplier: SellersCompaniesAndContactsDataModel;
  } | null;
  setRestoreShow: (value: boolean) => void;
  restoreShow: boolean;
  setSuppliers: (value: SellersCompaniesAndContactsModel) => void;
  closeParentModal: () => void;
};

export const ExternalSupplierRestore = ({
  isSupplierArchived,
  setRestoreShow,
  restoreShow,
  setSuppliers,
  closeParentModal,
}: PropTypes) => {
  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const targetSupplier = isSupplierArchived();

  const targetSupplierExists =
    targetSupplier && targetSupplier.externalSupplier !== undefined;

  const handleRestoreSupplier = async () => {
    if (targetSupplierExists) {
      toast.dismiss();
      await BuyerApi.restoreExternalSeller(
        currentUser.companyId,
        targetSupplier?.externalSupplier?.id
      );

      await getAllSuppliersAndContacts(currentUser, setSuppliers);

      toast.info(t('externalSupplier.inf_ExternalSupplierRestoredSuccesfully'));
      closeParentModal();
    }

    setRestoreShow(false);
  };

  return (
    <Modal
      show={restoreShow}
      size='sm'
      id='restore-external-supplier'
      onHide={() => setRestoreShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('externalSupplier.hd_RestoreSupplier')}</Modal.Title>
      </Modal.Header>

      <Modal.Body className='p-4'>
        {targetSupplierExists && (
          <>
            <p>
              {t('externalSupplier.inf_RestoreSupplierPart1', {
                supplierName: targetSupplier.externalSupplier.name,
              })}
            </p>
            <p>{t('externalSupplier.inf_RestoreSupplierPart2')}</p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={() => setRestoreShow(false)}>
          {t('common.lbl_No')}
        </Button>
        <Button variant='btn btn-eleczap' onClick={handleRestoreSupplier}>
          {t('common.lbl_Yes')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

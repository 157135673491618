import { BuyerApi } from 'api/BuyerApi';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { useFormik } from 'formik';
import {
  ExternalSellerContactByIdModel,
  ExternalSellerContactUpsertModel,
} from 'models/buyer/contacts/ExternalSellerContactsModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

export const useFormikForExternalSupplierContactCreate = (
  externalSellerId: number,
  getContacts: () => Promise<void>,
  setShow: (value: boolean) => void
) => {
  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleSubmit = async (values: ExternalSellerContactUpsertModel) => {
    toast.dismiss();

    await BuyerApi.createExternalSellerContacts(
      currentUser.companyId,
      externalSellerId,
      values
    );

    SuccessToast(t('externalSupplierContacts.inf_ContactAdded'));

    await getContacts();
  };

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    lastName: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    email: yup
      .string()
      .email(t('common.err_EmailInvalid'))
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),
  });

  const initialValues: ExternalSellerContactUpsertModel = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    localizationId: 1,
  };

  const formik = useFormik<ExternalSellerContactUpsertModel>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema,

    onSubmit: async (values) => {
      const createSupplier: ExternalSellerContactUpsertModel = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        localizationId: values.localizationId,
      };

      await handleSubmit(createSupplier);
      setShow(false);
      formik.resetForm();
      formik.setSubmitting(false);
    },
  });

  return formik;
};

export const useFormikForExternalSupplierContactUpdate = (
  externalSellerId: number,
  getContacts: () => Promise<void>,
  setShow: (value: boolean) => void,
  contact: ExternalSellerContactByIdModel,
  contactId: number
) => {
  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleSubmit = async (values: ExternalSellerContactUpsertModel) => {
    toast.dismiss();

    await BuyerApi.updateExternalSellerContacts(
      currentUser.companyId,
      externalSellerId,
      contactId,
      values
    );

    SuccessToast(t('externalSupplierContacts.inf_ContactUpdated'));

    await getContacts();
  };

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    lastName: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    email: yup
      .string()
      .email(t('common.err_EmailInvalid'))
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),
  });

  const initialValues: ExternalSellerContactUpsertModel = {
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.email,
    phone: contact.phone,
    localizationId: contact.localizationId,
  };

  const formik = useFormik<ExternalSellerContactUpsertModel>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema,

    onSubmit: async (values) => {
      const createSupplier: ExternalSellerContactUpsertModel = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        localizationId: values.localizationId,
      };

      await handleSubmit(createSupplier);
      setShow(false);
      formik.resetForm();
      formik.setSubmitting(false);
    },
  });

  return formik;
};

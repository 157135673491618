import { IdentityApi } from 'api/IdentityApi';
import { ExternalSellerOfferUpsertModel } from 'models/seller/documents/ExternalSellerOfferModels';
import { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { FileEarmarkFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

type PropTypes = {
  accessToken: string | null;
  offer: ExternalSellerOfferUpsertModel;
  customerName: string;
  count: number;
  totalRows: number;
  offerValidUntil: string | null;
};

export const ExternalSellerSendOfferModal = ({
  accessToken,
  offer,
  customerName,
  count,
  totalRows,
  offerValidUntil,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const navigate = useHistory();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleSendOffer = async () => {
    setIsLoading(true);
    if (accessToken) {
      await IdentityApi.createExternalSellerOffer(accessToken, offer);
      navigate.push(`/external-seller-offer?accessToken=${accessToken}`);
    }

    toast.success(t('documents.inf_OfferSent'));

    handleClose();

    setIsLoading(false);
  };

  return (
    <>
      <Button
        disabled={isLoading || count === 0}
        onClick={handleShow}
        className='btn btn-eleczap float-end d-flex align-items-center'
      >
        <FileEarmarkFill className='me-1' /> {t('documents.btn_SendOffer')}
      </Button>

      <Modal size='sm' show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{t('documents.hd_SendOffer')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {count !== totalRows && (
            <p>
              {t('documents.inf_SendOfferPart0', { count: totalRows - count })}
            </p>
          )}

          {offerValidUntil === '' || offerValidUntil === null ? (
            <p>{t('documents.inf_OfferDeadlinRequired')}</p>
          ) : (
            <p>{t('documents.inf_SendOfferPart1', { customerName })}</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={isLoading}
            onClick={handleClose}
            variant='secondary'
          >
            {t('common.btn_Cancel')}
          </Button>
          <Button
            disabled={
              isLoading || offerValidUntil === '' || offerValidUntil === null
            }
            onClick={handleSendOffer}
            variant='btn btn-eleczap'
          >
            {t('common.btn_Ok')}{' '}
            {isLoading && <Spinner animation='border' size='sm' />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

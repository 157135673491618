import { AcustomerContacts } from 'components/seller/aCustomer/AcustomerContacts';
import { useGetCustomerCompanyInfo } from 'components/seller/aCustomer/hooksACustomer';
import { getCustomerCreditInfo } from 'components/seller/aCustomer/utilsACustomer';
import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { BuyerCreditInfoModel } from 'models/seller/customers/BuyerCreditInfoModel';
import { CustomerCompanyModel } from 'models/seller/customers/CustomerCompanyModel';
import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Nav, Row, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getUrlParams } from 'utils/getUrlParams';
import { AcustomerCompanyInfo } from '../../components/seller/aCustomer/AcustomerCompanyInfo';
// Settings pages
import { AcustomerStock } from '../../components/seller/aCustomer/AcustomerStock';

export const Acustomer = () => {
  const [creditInfo, setCreditInfo] = useState<BuyerCreditInfoModel>(
    {} as BuyerCreditInfoModel
  );
  const [company, setCompany] = useState<CustomerCompanyModel>(
    {} as CustomerCompanyModel
  );

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');

  const customerId = parseInt(getUrlParams('id') || '0');

  useGetCustomerCompanyInfo(setCompany, currentUser, customerId);

  /* Get buyer's credit information */
  useEffect(() => {
    const getCreditInfo = async (): Promise<void> => {
      customerId !== null &&
        (await getCustomerCreditInfo(setCreditInfo, currentUser, customerId));
    };

    getCreditInfo();
  }, [currentUser, customerId]);

  return (
    <div className='container-fluid p-2'>
      <Card className='p-1 settingsPage'>
        <Tab.Container
          id='customerCompanyInfo'
          defaultActiveKey='customerCompanyInfo'
        >
          <div className='row flex-nowrap'>
            <div className='col col-side-menu-supplier-customer'>
              <Card className='border'>
                <Card.Body>
                  <Row className='justify-content-center'>
                    <AvatarWithFallback
                      imageUrl={company.logo}
                      initials={company.initials}
                      size='x-large'
                    />
                  </Row>
                  <Row>
                    <h4 className='text-center mt-4'>{company.name}</h4>
                  </Row>
                  <Row>
                    <h5 className='text-center'>
                      <a href={`mailto:${company.email}`} className='icon-ez'>
                        {company.email}
                      </a>
                    </h5>
                  </Row>

                  <Row>
                    <Row>
                      <strong className='icon-ez-gray'>
                        <small>{t('creditInfo.lbl_PaymentTerms')}</small>
                      </strong>
                    </Row>
                    <Row>
                      <strong>{creditInfo.paymentTerm ?? '-'}</strong>
                    </Row>
                  </Row>

                  <Row className='mt-2 '>
                    <Row>
                      <strong className='icon-ez-gray'>
                        <small>{t('creditInfo.lbl_DeliveryTerms')}</small>
                      </strong>
                    </Row>
                    <Row>
                      <strong>{creditInfo.deliveryTerm ?? '-'}</strong>
                    </Row>
                  </Row>

                  <Row className='mt-2'>
                    <Row className=''>
                      <strong className='icon-ez-gray'>
                        <small>{t('creditInfo.lbl_TotalCredit')}</small>
                      </strong>
                    </Row>
                    <Row>
                      <strong>
                        {useFormatMonetaryValue(
                          creditInfo.currencyCode,
                          creditInfo.limit
                        )}
                      </strong>
                    </Row>
                  </Row>

                  <Row className='mt-2'>
                    <Row>
                      <strong className='icon-ez-gray'>
                        <small>{t('creditInfo.lbl_RemainingCredit')}</small>
                      </strong>
                    </Row>
                    <Row>
                      <strong>
                        {useFormatMonetaryValue(
                          creditInfo.currencyCode,
                          creditInfo.remainingCredit
                        )}
                      </strong>
                    </Row>
                  </Row>
                </Card.Body>
              </Card>

              <Nav className='flex-column border p-0 mb-4 tabContainerSettings'>
                <Nav.Item className='border-bottom nav-item-hover'>
                  <Nav.Link eventKey='customerCompanyInfo'>
                    {t('companySettings.hd_CompanyInformation')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className='border-bottom nav-item-hover'>
                  <Nav.Link eventKey='customerStocks'>
                    {t('stockSettings.hd_Stocks')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className='nav-item-hover'>
                  <Nav.Link eventKey='customerContacts'>
                    {t('common.hd_Contacts')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className='col col-lg-10 '>
              <Tab.Content>
                <Tab.Pane eventKey='customerCompanyInfo'>
                  <AcustomerCompanyInfo company={company} />
                </Tab.Pane>
                <Tab.Pane eventKey='customerStocks'>
                  <AcustomerStock />
                </Tab.Pane>
                <Tab.Pane eventKey='customerContacts'>
                  <AcustomerContacts
                    customerName={company.name}
                    customerId={customerId}
                  />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </Card>
    </div>
  );
};

import { ExternalSellerBuyerContactModel } from 'models/seller/contacts/ExternalSellerBuyerContactModel';
import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  contact: ExternalSellerBuyerContactModel;
  companyName: string;
};

export const ExternalSellerContactProfileModal = ({
  contact,
  companyName,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <h4 onClick={handleShow} className='btn-link-bold cursor-pointer'>
        {contact.firstName} {contact.lastName}
      </h4>
      <Modal show={show} onHide={handleHide}>
        <Modal.Header>
          <Modal.Title>
            <h4>{t('customers.hd_ContactInformation')}</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form className='p-3'>
            <Row>
              <Col className='col col-lg-10'>
                <Row>
                  <Col className='mx-3'>
                    <Form.Group as={Row} controlId='validationFirstName'>
                      <Form.Label>{t('userSettings.lbl_FirstName')}</Form.Label>
                      <Form.Control
                        value={contact.firstName || ''}
                        type='input'
                        name='firstName'
                        placeholder={t('userSettings.plh_FirstName')}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationCompany'>
                      <Form.Label>{t('userSettings.lbl_Company')}</Form.Label>
                      <Form.Control
                        type='input'
                        name='companyName'
                        value={companyName}
                        aria-describedby='inputGroupPrepend'
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationUserEmail'>
                      <Form.Label>{t('userSettings.lbl_Email')}</Form.Label>
                      <Form.Control
                        type='input'
                        name='email'
                        value={contact.email || ''}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className='mx-3'>
                    <Form.Group as={Row} controlId='validationLastName'>
                      <Form.Label>{t('userSettings.lbl_LastName')}</Form.Label>
                      <Form.Control
                        name='lastName'
                        value={contact.lastName || ''}
                        type='input'
                        placeholder={t('userSettings.plh_LastName')}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationUserPosition'>
                      <Form.Label>{t('userSettings.lbl_Position')}</Form.Label>
                      <Form.Control
                        name='position'
                        value={contact.position || ''}
                        type='input'
                        placeholder={t('userSettings.plh_Position')}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationUserPhoneNumber'>
                      <Form.Label>{t('userSettings.lbl_Phone')}</Form.Label>
                      <Form.Control
                        name='phone'
                        value={contact.phone || ''}
                        type='input'
                        placeholder={t('userSettings.plh_Phone')}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleHide} variant='secondary'>
            {t('common.btn_Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import './../../pages/seller/sellerDealsHome.css';
import { ReactComponent as DealActiveIcon } from './new status icons/Deal Active.svg';
import { ReactComponent as DealDisabledIcon } from './new status icons/Deal Disabled.svg';
import { ReactComponent as DealFinishedIcon } from './new status icons/Deal Finished.svg';
import { ReactComponent as DealLostIcon } from './new status icons/Deal Lost.svg';

import { ReactComponent as InquiryDisabledIcon } from './new status icons/Inquiry Disabled.svg';
import { ReactComponent as InquiryNoReplyBuyerIcon } from './new status icons/Inquiry No Reply Buyer.svg';
import { ReactComponent as InquiryNoReplySellerIcon } from './new status icons/Inquiry No Reply Seller.svg';
import { ReactComponent as InquiryOfferSentIcon } from './new status icons/Inquiry Offer Made.svg';
import { ReactComponent as InquirySendingIcon } from './new status icons/Inquiry Sending.svg';

import { ReactComponent as InvoiceDisabledIcon } from './new status icons/Invoice Disabled.svg';
import { ReactComponent as InvoiceOverdueIcon } from './new status icons/Invoice Overdue.svg';
import { ReactComponent as InvoicePaidIcon } from './new status icons/Invoice Paid.svg';
import { ReactComponent as InvoiceUnpaidBuyerIcon } from './new status icons/Invoice Unpaid Buyer.svg';
import { ReactComponent as InvoiceUnpaidSellerIcon } from './new status icons/Invoice Unpaid Seller.svg';

import { ReactComponent as OfferDisabledIcon } from './new status icons/Offer Disabled.svg';
import { ReactComponent as OfferExpiredIcon } from './new status icons/Offer Expired.svg';
import { ReactComponent as OfferNoReplyBuyerIcon } from './new status icons/Offer No Reply Buyer.svg';
import { ReactComponent as OfferNoReplySellerIcon } from './new status icons/Offer No Reply Seller.svg';
import { ReactComponent as OfferOrderMadeIcon } from './new status icons/Offer Order Made.svg';

import { CartXFill, ClipboardXFill } from 'react-bootstrap-icons';
import { ReactComponent as OrderConfirmedIcon } from './new status icons/Order Confirmed.svg';
import { ReactComponent as OrderDisabledIcon } from './new status icons/Order Disabled.svg';
import { ReactComponent as OrderDispatchedIcon } from './new status icons/Order Dispatched.svg';
import { ReactComponent as OrderSendingIcon } from './new status icons/Order Sending.svg';
import { ReactComponent as OrderUnconfirmedIcon } from './new status icons/Order Unconfirmed.svg';

export const DealActive = ({ ...props }): JSX.Element => (
  <DealActiveIcon {...props} />
);

export const DealDisabled = ({ ...props }): JSX.Element => (
  <DealDisabledIcon {...props} />
);
export const DealFinshed = ({ ...props }): JSX.Element => (
  <DealFinishedIcon {...props} />
);
export const DealLost = ({ ...props }): JSX.Element => (
  <DealLostIcon {...props} />
);

export const InquiryDisabled = ({ ...props }): JSX.Element => (
  <InquiryDisabledIcon {...props} />
);

export const InquiryNoReplyBuyer = ({ ...props }): JSX.Element => (
  <InquiryNoReplyBuyerIcon {...props} />
);

export const InquiryNoReplySeller = ({ ...props }): JSX.Element => (
  <InquiryNoReplySellerIcon {...props} />
);
export const InquiryDeclined = ({ ...props }): JSX.Element => (
  <ClipboardXFill {...props} />
);

export const InquiryOfferSent = ({ ...props }): JSX.Element => (
  <InquiryOfferSentIcon {...props} />
);
export const InquirySending = ({ ...props }): JSX.Element => (
  <InquirySendingIcon {...props} />
);

export const InvoiceDisabled = ({ ...props }): JSX.Element => (
  <InvoiceDisabledIcon {...props} />
);
export const InvoiceOverdue = ({ ...props }): JSX.Element => (
  <InvoiceOverdueIcon {...props} />
);
export const InvoicePaid = ({ ...props }): JSX.Element => (
  <InvoicePaidIcon {...props} />
);

export const InvoiceUnpaidBuyer = ({ ...props }): JSX.Element => (
  <InvoiceUnpaidBuyerIcon {...props} />
);

export const InvoiceUnpaidSeller = ({ ...props }): JSX.Element => (
  <InvoiceUnpaidSellerIcon {...props} />
);

export const OfferDisabled = ({ ...props }): JSX.Element => (
  <OfferDisabledIcon {...props} />
);
export const OfferExpired = ({ ...props }): JSX.Element => (
  <OfferExpiredIcon {...props} />
);
export const OfferNoReplyBuyer = ({ ...props }): JSX.Element => (
  <OfferNoReplyBuyerIcon {...props} />
);

export const OfferNoReplySeller = ({ ...props }): JSX.Element => (
  <OfferNoReplySellerIcon {...props} />
);

export const OfferOrderMade = ({ ...props }): JSX.Element => (
  <OfferOrderMadeIcon {...props} />
);

export const OrderDisabled = ({ ...props }): JSX.Element => (
  <OrderDisabledIcon {...props} />
);
export const OrderCancelled = ({ ...props }): JSX.Element => (
  <CartXFill {...props} />
);
export const OrderDispatched = ({ ...props }): JSX.Element => (
  <OrderDispatchedIcon {...props} />
);
export const OrderSending = ({ ...props }): JSX.Element => (
  <OrderSendingIcon {...props} />
);

export const OrderUnconfirmed = ({ ...props }): JSX.Element => (
  <OrderUnconfirmedIcon {...props} />
);
export const OrderConfirmed = ({ ...props }): JSX.Element => (
  <OrderConfirmedIcon {...props} />
);

import { useFormik } from 'formik';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  AddressModel,
  AddressModelById,
} from 'models/shared/address/AddressModel';
import { AddressUpdateModel } from 'models/shared/address/AddressUpdateModel';
import * as yup from 'yup';
import { getAllAddresses } from '../utilsDeliveryAddresses';

/* Formik init */
export const useFormikHookForAddress = (
  currentUser: LoginResponseUserModel,
  address: AddressModelById,
  setAddresses: (values: AddressModel) => void,
  handleSubmit: (
    currentUser: LoginResponseUserModel,
    values: AddressUpdateModel,
    address: AddressModelById,

    t: any
  ) => Promise<void>,
  setShow: (values: boolean) => void,

  t: any
): any => {
  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    addressLine1: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    addressLine2: yup.string().nullable().max(50, t('common.err_ValueMax50')),

    postalCode: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    city: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    state: yup.string().nullable().max(50, t('common.err_ValueMax50')),
  });

  const formik = useFormik<AddressModelById>({
    initialValues: { ...address },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedAddress: AddressUpdateModel = {
        name: values.name,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        postalCode: values.postalCode,
        city: values.city,
        state: values.state,
        countryCode: values.country.code,
        isVisible: values.isVisible,
        isDefault: values.isDefault,
      };
      await handleSubmit(currentUser, updatedAddress, address, t);
      await getAllAddresses(currentUser, setAddresses);
      setShow(false);
      formik.setSubmitting(false);
    },
  });

  return formik;
};

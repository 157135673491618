import { BuyerApi } from 'api/BuyerApi';
import { useFormikForExternalSupplierContactUpdate } from 'components/buyer/supplierInfo/hookExternalSupplierContact';
import { useGetLocalizations } from 'hooks/useGetLocalizations';
import { ExternalSellerContactByIdModel } from 'models/buyer/contacts/ExternalSellerContactsModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Form, FormGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  getContacts: () => Promise<void>;
  supplierId: number;
  contactId: number;
};

export const ExternalSupplierEditContact = ({
  getContacts,
  supplierId,
  contactId,
}: PropTypes) => {
  const [show, setShow] = useState(false);

  const [localizations, setLocalizations] = useState<LocalizationModel[]>([]);

  const [contact, setContact] = useState<ExternalSellerContactByIdModel>(
    {} as ExternalSellerContactByIdModel
  );

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  useEffect(() => {
    const getContact = async () => {
      const response = await BuyerApi.getExternalSellerContactById(
        currentUser.companyId,
        supplierId,
        contactId
      );

      setContact(response);
    };

    show && getContact();
  }, [contactId, currentUser.companyId, show, supplierId]);

  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  const formik = useFormikForExternalSupplierContactUpdate(
    supplierId,
    getContacts,
    setShow,
    contact,
    contactId
  );

  useGetLocalizations(setLocalizations);

  const handleSubmitForm = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <PencilSquare
        onClick={handleShow}
        className='icon-ez cursor-pointer'
        size={18}
      />

      <Modal size='sm' show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {t('externalSupplierContacts.hd_AddAContact')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate>
            <FormGroup className='mb-2' controlId='validationFirstName'>
              <Form.Label className='required'>
                {t('externalSupplier.lbl_ContactFirstName')}
              </Form.Label>
              <Form.Control
                name='firstName'
                value={formik.values.firstName}
                onChange={formik.handleChange}
                placeholder={t('userSettings.plh_FirstName')}
                isInvalid={
                  formik.touched.firstName && !!formik.errors.firstName
                }
              />{' '}
              <Form.Control.Feedback type='invalid'>
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup className='mb-2' controlId='validationLastName'>
              <Form.Label className='required'>
                {t('externalSupplier.lbl_ContactLastName')}
              </Form.Label>
              <Form.Control
                name='lastName'
                value={formik.values.lastName}
                onChange={formik.handleChange}
                placeholder={t('userSettings.plh_LastName')}
                isInvalid={formik.touched.lastName && !!formik.errors.lastName}
              />{' '}
              <Form.Control.Feedback type='invalid'>
                {formik.errors.lastName}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup className='mb-2' controlId='validationEmail'>
              <Form.Label className='required'>
                {t('externalSupplier.lbl_ContactEmail')}
              </Form.Label>
              <Form.Control
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                type='email'
                placeholder={t('userSettings.plh_Email')}
                isInvalid={formik.touched.email && !!formik.errors.email}
              />{' '}
              <Form.Control.Feedback type='invalid'>
                {formik.errors.email}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup className='mb-2' controlId='validationPhone'>
              <Form.Label>{t('externalSupplier.lbl_ContactPhone')}</Form.Label>
              <Form.Control
                name='phone'
                value={formik.values.phone ?? ''}
                onChange={formik.handleChange}
                type='text'
                placeholder={t('userSettings.plh_Phone')}
                isInvalid={formik.touched.phone && !!formik.errors.phone}
              />{' '}
              <Form.Control.Feedback type='invalid'>
                {formik.errors.phone}
              </Form.Control.Feedback>
            </FormGroup>

            <Form.Group as={Row}>
              <Form.Label className='text-start'>
                {t('userSettings.lbl_Language')}
                <Form.Select
                  name='localizationId'
                  aria-label='Current active language'
                  value={formik.values.localizationId}
                  onChange={formik.handleChange}
                >
                  {localizations.map((e, key) => {
                    return (
                      <option key={key} value={e.id}>
                        {e.cultureLongName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button
            variant='btn btn-eleczap'
            disabled={formik.isSubmitting}
            onClick={handleSubmitForm}
          >
            {t('common.btn_Ok')}{' '}
            {formik.isSubmitting && <Spinner animation='border' size='sm' />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { ProjectInquirySupplierSelectorOption } from 'components/buyer/projectInquiry/ProjectInquirySupplierSelector/ProjectInquirySupplierSelectorOption';
import { AppContext } from 'contexts/user/AppContext';
import { useListenElementResize } from 'hooks/useListenElementResize';
import {
  ProjectInquiryAddRemoveSupplierModel,
  ProjectInquiryModel,
} from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ChangeEvent, useContext, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { FormControl, InputGroup, ListGroup } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  suppliers: ProjectInquiryAddRemoveSupplierModel[];
  projectId: number;
  projectInquiryId: number | null;
  setProjectDetails: (value: ProjectInquiryModel) => void;
};

export const ProjectInquirySupplierSelector = ({
  suppliers,
  projectId,
  projectInquiryId,
  setProjectDetails,
}: PropTypes) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [filterQuery, setFilterQuery] = useState<string>('');

  const [heightOfGridContainer, setHeightOfGridContainer] = useState<number>(0);

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);

  const filterSuppliers = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(event.target.value);
  };

  useListenElementResize('.project-inquiry-grid-table', {
    setHeightOfElement: setHeightOfGridContainer,
  });

  return (
    <div className='project-suppliers-selector-container rounded  position-relative'>
      <div className='px-3 py-3'>
        <h4 className='text-center'>
          {t('projectSettings.lbl_SendInquiryTo')}
        </h4>
      </div>

      <InputGroup
        style={{ width: '200px', margin: '0 auto' }}
        className={`border rounded py-0 my-0 mb-2 ${
          isFocused ? 'custom-focused' : ''
        }`}
      >
        <InputGroup.Text className='btn' id='search-addon'>
          <Search />
        </InputGroup.Text>
        <FormControl
          onFocus={() => setIsFocused(true)}
          className='projects-input my-0 py-0 px-3 border-0'
          onChange={filterSuppliers}
          onBlur={() => setIsFocused(false)}
          // onBlur={handleBlur}
        />
      </InputGroup>

      <ListGroup
        style={{ minHeight: '200px', maxHeight: heightOfGridContainer - 30 }}
        variant='flush'
        className='project-suppliers-selector'
      >
        {appState?.buyerCurrentSuppliers
          ?.filter((supplier) =>
            filterQuery !== ''
              ? supplier.name
                  .toLowerCase()
                  .includes(filterQuery.toLowerCase()) && supplier.isActive
              : supplier.isActive
          )
          .sort((a, b) =>
            a.isSelected === b.isSelected ? 0 : a.isSelected ? -1 : 1
          )
          .map((supplier) => (
            <ProjectInquirySupplierSelectorOption
              projectId={projectId}
              currentUser={currentUser}
              externalContactId={
                suppliers?.find((sup) => sup.sellerCompanyId === supplier.value)
                  ?.externalSellerContactId ?? null
              }
              isSelected={
                suppliers?.findIndex(
                  (sup) => sup.sellerCompanyId === supplier.value
                ) !== -1
              }
              supplier={supplier}
              projectInquiryId={projectInquiryId}
              setProjectDetails={setProjectDetails}
            />
          ))}
      </ListGroup>
    </div>
  );
};

import { ReactNode } from 'react';
import ReactTooltip, { Place } from 'react-tooltip';

type PropTypes = {
  id: string;
  children: ReactNode;
  place?: Place;
  clickable?: boolean;
};

export const LightTooltip = ({
  id,
  children,
  place = 'left',
  clickable = false,
}: PropTypes) => (
  <ReactTooltip
    delayHide={clickable ? 500 : 200}
    effect='solid'
    place={place}
    type='light'
    clickable
    border
    borderColor='#dee6ed'
    arrowColor='#ffffff'
    id={id}
  >
    <span>{children}</span>
  </ReactTooltip>
);

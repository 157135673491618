import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { LightTooltip } from 'components/shared/LightTooltip';
import { DealOwner } from 'models/buyer/deals/DealsModels';

type PropTypes = {
  owners: DealOwner[];
  dealId: number;
};

export const DealsNameTooltip = ({ owners, dealId }: PropTypes) => {
  return (
    <div className='d-flex flex-row jutify-center'>
      {owners &&
        owners.map((owner, index: number) => (
          <div style={{ marginLeft: index !== 0 ? '-10px' : 'auto' }}>
            <span
              data-tip
              data-for={`creator-name-tooltip-deal-${owner?.fullName}-dealid-${dealId}`}
            >
              <AvatarWithFallback
                imageUrl={owner?.profileImage}
                initials={owner?.initials}
                size='small'
              />
            </span>
            <LightTooltip
              id={`creator-name-tooltip-deal-${owner?.fullName}-dealid-${dealId}`}
            >
              <span>{owner?.fullName}</span>
            </LightTooltip>
          </div>
        ))}
    </div>
  );
};

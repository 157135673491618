import { useShowDataOrHide } from 'hooks/useShowDataOrHide';
import { FeatureDetailType } from 'models/shared/product/FeatureDetailType';
import { ProductDetailsModel } from 'models/shared/product/ProductDetailsModel';
import { ProductFeatureModel } from 'models/shared/product/ProductFeatureModel';
import { useState } from 'react';
import {
  Card,
  Col,
  Collapse,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  ArrowDownCircleFill,
  ArrowRightCircleFill,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropsType = {
  items: ProductDetailsModel[];
};

export const ProductDetailsEtim = ({ items }: PropsType) => {
  const { t } = useTranslation('components');
  const [open, setOpen] = useState(true);
  const showDataOrHide = useShowDataOrHide();

  const getValueString = (item: ProductFeatureModel) => {
    if (
      item.value === FeatureDetailType.NotApplicable ||
      item.value === FeatureDetailType.MissingValue ||
      item.value === FeatureDetailType.Unknown
    ) {
      return t(`featureDetailType.${item.value}`);
    }

    switch (item.type) {
      case 'Numeric':
      case 'Alphanumeric': {
        return `${item.value} ${item.unit}`;
      }
      case 'NumericRange': {
        return `${item.value} - ${item.value2} ${item.unit}`;
      }
      case 'Logical': {
        return item.value === 'true' ? t('common.lbl_Yes') : t('common.lbl_No');
      }
    }
  };

  return (
    <Card className='width-max-content'>
      <Card.Header
        className='header-aProduct p-2 position-sticky'
        onClick={() => setOpen(!open)}
        aria-controls='ETIMProductClassParameter'
        aria-expanded={open}
      >
        {open ? (
          <ArrowDownCircleFill className='d-print-none' />
        ) : (
          <ArrowRightCircleFill className='d-print-none' />
        )}
        <span className='p-1 multiple-seller-card-header-max-width-colored'>
          {t('productDetailsEtim.lbl_EtimInfo')}
        </span>
      </Card.Header>
      <Collapse in={open}>
        <Card.Body id='ETIMProductClassParameter'>
          {items[0].features.map((feature, index) => (
            <Row>
              <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width wrap-ellipsis'>
                <OverlayTrigger
                  placement='bottom'
                  overlay={<Tooltip>{feature.name}</Tooltip>}
                >
                  <span>{feature.name}</span>
                </OverlayTrigger>
              </Col>
              {items &&
                items.map((item) => (
                  <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                    {item.features &&
                      item.features.length > index &&
                      getValueString(item.features[index])}
                  </Col>
                ))}
            </Row>
          ))}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

import { useTranslation } from 'react-i18next';

export const useValidateUnitExists = () => {
  const { t, i18n } = useTranslation('components');

  const validateUnitExists = (unit: string): string => {
    return i18n.exists(`components:common.lbl_${unit}`)
      ? t(`common.lbl_${unit}`)
      : unit;
  };

  return validateUnitExists;
};

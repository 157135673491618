import { BuyerApi } from 'api/BuyerApi';
import { getSpecificSuppliersUsers } from 'components/buyer/suppliers/utilsSuplliers';
import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { SellerContactsModel } from 'models/buyer/contacts/SellerContactsModel';
import { SellersUsersModel } from 'models/buyer/suppliers/SellersUsersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  supplierId: number;
};

export const SupplierContacts = ({ supplierId }: PropTypes) => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const [sellerContacts, setSellerContacts] = useState<SellerContactsModel[]>(
    []
  );
  const [suppliersUsers, setSuppliersUsers] = useState<SellersUsersModel>(
    {} as SellersUsersModel
  );

  const getContacts = useCallback(async (): Promise<void> => {
    const response = await BuyerApi.getSellerContacts(
      currentUser.companyId,
      supplierId
    );

    if (response.length === 0) {
      await getSpecificSuppliersUsers(
        currentUser,
        setSuppliersUsers,
        supplierId
      );
    }

    setSellerContacts(response);
  }, [currentUser, supplierId]);

  useEffect(() => {
    supplierId !== undefined && getContacts();
  }, [supplierId, getContacts]);

  const { t } = useTranslation('components');

  return (
    <>
      {sellerContacts.length !== 0 && (
        <Card className='p-3'>
          <Card.Header>
            <h4>{t('suppliersPage.hd_YourContacts')}</h4>
          </Card.Header>
          <Card.Body className='d-flex flex-wrap'>
            {sellerContacts.map((contact) => (
              <div
                style={{
                  width: '230px',
                }}
                className='border p-4 m-2'
              >
                <Row>
                  <Col lg={1} />
                  <Col className='d-flex justify-content-center'>
                    <AvatarWithFallback
                      imageUrl={contact.profileImage}
                      initials={contact.initials}
                      size='x-large'
                    />
                  </Col>
                  <Col lg={1}></Col>
                </Row>
                <Row>
                  <h4 className='text-center mt-4'>{`${contact.firstName} ${contact.lastName}`}</h4>
                </Row>
                <Row>
                  <h5 className='text-center'>
                    <a href={`mailto:${contact.email}`} className='icon-ez'>
                      {contact.email}
                    </a>
                  </h5>
                </Row>
                <Row>
                  <h5 className='text-center'>{contact.phone}</h5>
                </Row>
                <Row>
                  <span className='text-center'>{contact.position}</span>
                </Row>
              </div>
            ))}
          </Card.Body>
        </Card>
      )}

      {sellerContacts.length === 0 && (
        <Card className='p-3'>
          <Card.Header>
            <h4>{t('suppliersPage.hd_AllContacts')}</h4>
          </Card.Header>
          <Card.Body className='d-flex flex-wrap'>
            {suppliersUsers.data !== undefined &&
              suppliersUsers.data.map((user) => (
                <div
                  style={{
                    width: '230px',
                  }}
                  className='border p-4 m-2'
                >
                  <Row className='justify-content-center'>
                    <AvatarWithFallback
                      imageUrl={user.profileImage}
                      initials={user.initials}
                      size='x-large'
                    />
                  </Row>
                  <Row>
                    <h4 className='text-center mt-4'>{user.fullName}</h4>
                  </Row>

                  <Row>
                    <h5 className='text-center'>
                      <a href={`mailto:${user.email}`} className='icon-ez'>
                        {user.email}
                      </a>
                    </h5>
                  </Row>
                  <Row>
                    <h5 className='text-center'>{user.phone}</h5>
                  </Row>
                  <Row>
                    <span className='text-center'>{user.position}</span>
                  </Row>
                </div>
              ))}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

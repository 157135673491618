import FilePicker from '@mavedev/react-file-picker';
import { IdentityApi } from 'api/IdentityApi';
import {
  OrderCancelled,
  OrderConfirmed,
  OrderDispatched,
  OrderSending,
  OrderUnconfirmed,
} from 'assets/icons/StatusIcons';
import { ExternalSellerContactProfileModal } from 'components/seller/externalSeller/ExternalSellerContactProfileModal';
import { ExternalSellerCustomerProfileModal } from 'components/seller/externalSeller/ExternalSellerCustomerProfileModal';
import { ExternalSellerOrderCancelModal } from 'components/seller/externalSeller/ExternalSellerOrderCancelModal';
import { ExternalSellerOverlayDatePicker } from 'components/seller/externalSeller/ExternalSellerOverlayDatePicker';
import { ExternalSellerAttachmentItem } from 'components/shared/deals/ProjectAttachmentItem/ExternalSellerAttachmentItem';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { SellerFooter } from 'layout/SellerFooter';
import { SellerHeader } from 'layout/SellerHeader';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ExternalSellerBuyerContactModel } from 'models/seller/contacts/ExternalSellerBuyerContactModel';
import { CustomerCompanyModel } from 'models/seller/customers/CustomerCompanyModel';
import {
  ExternalSellerOrderConfirmModel,
  ExternalSellerOrderModel,
  ExternalSellerOrderRowModel,
} from 'models/seller/documents/ExternalSellerOrderModels';
import { createExternalSellerOrderConfirmRows } from 'pages/seller/Order/utilsOrder';
import {
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  ArrowCounterclockwise,
  CalendarMinusFill,
  CalendarPlusFill,
  CheckCircleFill,
  Paperclip,
  Save,
  XSquare,
} from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useTranslation } from 'react-i18next';
import { TbTruckDelivery } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { formatMonetaryValueWithoutDecimals } from 'utils/formatMonetaryValueWithoutDecimals';
import { getUrlParams } from 'utils/getUrlParams';
import { truncateDecimals } from 'utils/truncateDecimals';

export const ExternalSellerOrder = () => {
  const [order, setOrder] = useState<ExternalSellerOrderModel>(
    {} as ExternalSellerOrderModel
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [yourComment, setYourComment] = useState<string>('');

  const [isoDate, setIsoDate] = useState<string | null>(null);
  const [date, setDate] = useState<string | null>(null);

  const [customer, setCustomer] = useState<CustomerCompanyModel>(
    {} as CustomerCompanyModel
  );

  const [rows, setRows] = useState<ExternalSellerOrderRowModel[]>([]);

  const [contact, setContact] = useState<ExternalSellerBuyerContactModel>(
    {} as ExternalSellerBuyerContactModel
  );

  const [confirmOrder, setConfirmOrder] =
    useState<ExternalSellerOrderConfirmModel>(
      {} as ExternalSellerOrderConfirmModel
    );

  const { appState } = useContext(AppContext);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');
  const validateUnitExists = useValidateUnitExists();

  const accessToken = getUrlParams('accessToken');

  const isDocumentUneditable = order.status === 'Cancelled';

  const getOrder = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    if (accessToken !== null) {
      const response = await IdentityApi.getExternalSellerOrder(accessToken);

      setOrder(response);

      const modifiedRows: ExternalSellerOrderRowModel[] = [...response.rows];

      modifiedRows.forEach((row) => {
        if (row.deliveries.length === 0) {
          row.deliveries.push({
            confirmedCount: row.quantity,
            deliveryDate: null,
          });
        }
      });

      setRows(modifiedRows);
    }

    setIsLoading(false);
  }, [accessToken]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  /* Get customer contact */
  useEffect(() => {
    const getContact = async () => {
      if (accessToken) {
        const response =
          await IdentityApi.getExternalSellerCustomerContactProfile(
            accessToken,
            'order'
          );

        setContact(response);
      }
    };

    getContact();
  }, [accessToken]);

  /* Get customer profile */
  useEffect(() => {
    const getCustomer = async () => {
      if (accessToken) {
        const response = await IdentityApi.getExternalSellerCustomerProfile(
          accessToken,
          'order'
        );

        setCustomer(response);
      }
    };

    getCustomer();
  }, [accessToken]);

  const updateLocalOrder = useCallback(() => {
    if (accessToken !== null) {
      const finalRows = createExternalSellerOrderConfirmRows(rows);

      const updateData: ExternalSellerOrderConfirmModel = {
        comment: yourComment,
        rows: finalRows,
      };

      // await IdentityApi.createExternalSellerOffer(accessToken, updateData);

      setConfirmOrder(updateData);
    }
  }, [accessToken, rows, yourComment]);

  useEffect(() => {
    updateLocalOrder();
  }, [updateLocalOrder]);

  const disabledDays = {
    from: new Date(
      dayjs().startOf('month').year(),
      dayjs().startOf('month').month(),
      1
    ),
    to: new Date(),
  };

  const handleRowQtyChange =
    (rowNo: number, deliveryIndex: number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const clonedExtendedRows = [...rows];

      const qty = parseInt(event.target.value);

      const target = clonedExtendedRows.find((row) => row.rowNo === rowNo);

      const targetDelivery = target?.deliveries.find(
        (delivery, index) => index === deliveryIndex
      );

      let sum = 0;

      target?.deliveries.forEach(
        (delivery) => (sum += delivery.confirmedCount ?? 0)
      );

      if (targetDelivery) {
        targetDelivery.confirmedCount = qty;
      }

      setRows(clonedExtendedRows);
    };

  const handleRowQtyBlur =
    (rowNo: number, deliveryIndex: number) =>
    (event: FocusEvent<HTMLInputElement>) => {
      const clonedExtendedRows = [...rows];

      const qty = parseInt(event.target.value);

      const target = clonedExtendedRows.find((row) => row.rowNo === rowNo);

      const targetDelivery = target?.deliveries.find(
        (delivery, index) => index === deliveryIndex
      );

      let sum = 0;

      target?.deliveries.forEach(
        (delivery) => (sum += delivery.confirmedCount ?? 0)
      );

      if (targetDelivery) {
        targetDelivery.confirmedCount = qty;

        if (target?.quantity && sum > target?.quantity) {
          let sumOfOthers = 0;

          target?.deliveries.forEach((delivery, index) => {
            if (deliveryIndex !== index)
              sumOfOthers += delivery.confirmedCount ?? 0;
          });

          targetDelivery.confirmedCount = target?.quantity - sumOfOthers;
        }

        if (deliveryIndex !== 0 && qty < 1) {
          targetDelivery.confirmedCount = 1;
        }
      }

      setRows(clonedExtendedRows);
    };

  const handleRowDispatchedChange =
    (rowNo: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const clonedExtendedRows = [...rows];

      const qty = parseInt(event.target.value);

      const target = clonedExtendedRows.find((row) => row.rowNo === rowNo);

      if (target) {
        target.deliveredCount = qty;
      }

      setRows(clonedExtendedRows);
    };

  const handleRowDispatchedBlur =
    (rowNo: number) => (event: FocusEvent<HTMLInputElement>) => {
      const clonedExtendedRows = [...rows];

      const qty = parseInt(event.target.value);

      const target = clonedExtendedRows.find((row) => row.rowNo === rowNo);

      if (target && qty > target.quantity) {
        target.deliveredCount = target.quantity;
      }

      setRows(clonedExtendedRows);
    };

  const handleAddDeliveryRow =
    (rowNo: number, deliveryIndex: number) =>
    (event: MouseEvent<HTMLOrSVGElement>) => {
      let sum = 0;

      const clonedExtendedRows = [...rows];

      const target = clonedExtendedRows.find((row) => row.rowNo === rowNo);

      target?.deliveries.forEach((delivery) => {
        sum += delivery.confirmedCount ?? 0;
      });

      target?.deliveries.push({
        confirmedCount: target.quantity - sum,
        deliveryDate: null,
      });

      setRows(clonedExtendedRows);
    };

  const handleRemoveDeliveryRow =
    (rowNo: number, deliveryIndex: number) =>
    (event: MouseEvent<HTMLOrSVGElement>) => {
      const clonedExtendedRows = [...rows];

      const target = clonedExtendedRows.find((row) => row.rowNo === rowNo);

      target?.deliveries.splice(deliveryIndex, 1);

      setRows(clonedExtendedRows);

      target && updateMainDeliveryDateAndQty(target);
    };

  const updateMainDeliveryDateAndQty = (
    target: ExternalSellerOrderRowModel,
    day?: Date | null
  ) => {
    const clonedExtendedRows = [...rows];

    const date = day ?? null;

    let sum = 0;

    target?.deliveries.forEach(
      (delivery) => (sum += delivery.confirmedCount ?? 0)
    );

    target.confirmedCount = sum;

    if (target?.quantity && target?.quantity === sum) {
      target.deliveryDate = dayjs(date).toISOString();
      target.confirmedCount = sum;
    } else {
      target.deliveryDate = null;
      target.confirmedCount = null;
    }

    setRows(clonedExtendedRows);
  };

  const handleRowDateChange = (
    rowNo: number,
    day: Date | null,
    deliveryIndex: number
  ) => {
    const clonedExtendedRows = [...rows];

    const date = day;

    const target = clonedExtendedRows.find((row) => row.rowNo === rowNo);

    const targetDelivery = target?.deliveries.find(
      (delivery, index) => index === deliveryIndex
    );

    if (targetDelivery) {
      targetDelivery.deliveryDate = date ? dayjs(date).toISOString() : null;
    }

    setRows(clonedExtendedRows);

    target && updateMainDeliveryDateAndQty(target, day);
  };

  const handleSaveOrder = async () => {
    if (accessToken) {
      await IdentityApi.confirmExternalSellerOrder(accessToken, confirmOrder);

      await getOrder();

      SuccessToast(t('documents.inf_OrderSaved'));
    }
  };

  const handleDateChange = async (day: Date) => {
    const pickedDate = dayjs(day).format('DD-MM-YYYY');

    const clonedRows = [...rows];

    clonedRows.forEach((row) => {
      row.deliveries.forEach((delivery) => {
        if (delivery.deliveryDate === null) {
          delivery.deliveryDate = dayjs(day).toISOString();
        }
      });
      row.deliveryDate = dayjs(day).toISOString();
    });

    setRows(clonedRows);

    setDate(pickedDate);
    setIsoDate(dayjs(day).toISOString());
  };

  const handleDateRemove = async () => {
    setDate(t('common.lbl_DDMMYYYY'));
    setIsoDate(null);
  };

  const columns: TableColumn<ExternalSellerOrderRowModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      sortable: false,
      minWidth: '40px',
      maxWidth: '40px',
      style: { marginTop: '5px', marginLeft: 0, marginRight: 0 },
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode,
      cell: (row) => <span>{row.productCode}</span>,
      style: { marginTop: '5px' },
      minWidth: '110px',
      maxWidth: '110px',
      sortable: false,
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name,
      sortable: false,
      grow: 2,
      style: { marginTop: '5px' },
    },

    {
      name: (
        <span className='text-end'>{t('documents.lbl_OrderQuantity')}</span>
      ),
      selector: (row) => row.quantity,
      cell: (row) => {
        return (
          <>
            <span>
              {row.quantity} <small>{validateUnitExists(row.unit)}</small>
            </span>
          </>
        );
      },
      sortable: false,
      right: true,
      style: { marginTop: '5px' },
      minWidth: '100px',
      maxWidth: '100px',
    },

    {
      name: (
        <span className='text-center'>
          {t('documents.lbl_ConfirmedQuantity')}
        </span>
      ),
      selector: (row) => row.confirmedCount ?? 'confirmedCount',
      cell: (row) => {
        let sum = 0;

        row.deliveries.forEach(
          (delivery) => (sum += delivery.confirmedCount ?? 0)
        );

        return (
          <>
            {!isDocumentUneditable ? (
              row.deliveries.length > 0 && (
                <div>
                  {row.deliveries.map((delivery, index) => (
                    <Form.Control
                      type='number'
                      className='text-end'
                      min={index === 0 ? 0 : 1}
                      value={delivery.confirmedCount ?? 0}
                      onChange={handleRowQtyChange(row.rowNo, index)}
                      onBlur={handleRowQtyBlur(row.rowNo, index)}
                    />
                  ))}
                </div>
              )
            ) : (
              <span>
                {row.confirmedCount}{' '}
                <small>{validateUnitExists(row.unit)}</small>
              </span>
            )}
          </>
        );
      },
      sortable: false,
      center: true,
      minWidth: '110px',
      maxWidth: '110px',
    },
    {
      name: (
        <span className='text-start'>{t('documents.lbl_ConfirmedDate')}</span>
      ),
      selector: (row) => row.deliveryDate ?? 'deliveryDate',
      cell: (row) =>
        isDocumentUneditable ? (
          <span>{dayjs(row.deliveryDate).format('DD-MM-YYYY') ?? '-'}</span>
        ) : row.deliveries.length > 0 ? (
          <div>
            {row.deliveries.map((delivery, index) => {
              const target = { ...row };

              let sum = 0;

              target?.deliveries.forEach(
                (delivery) => (sum += delivery.confirmedCount ?? 0)
              );

              return (
                <div className='d-flex align-items-center'>
                  <ExternalSellerOverlayDatePicker
                    disabledDays={disabledDays}
                    row={row}
                    delivery={delivery}
                    handleRowDateChange={handleRowDateChange}
                    deliveryIndex={index}
                  />

                  {index === row.deliveries.length - 1 &&
                  target?.quantity &&
                  sum < target?.quantity &&
                  delivery.confirmedCount !== 0 ? (
                    <CalendarPlusFill
                      size={24}
                      onClick={handleAddDeliveryRow(row.rowNo, index)}
                      className='icon-ez cursor-pointer ms-1'
                    />
                  ) : (
                    <div className='ms-1' style={{ width: '22px' }} />
                  )}
                  {row.deliveries.length > 1 ? (
                    <CalendarMinusFill
                      onClick={handleRemoveDeliveryRow(row.rowNo, index)}
                      size={22}
                      className='icon-ez-red cursor-pointer ms-1'
                    />
                  ) : (
                    <div className='ms-1' style={{ width: '22px' }} />
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className='d-flex align-items-center'>
            <CalendarPlusFill
              size={20}
              onClick={handleAddDeliveryRow(row.rowNo, 0)}
              className='icon-ez cursor-pointer ms-1 mt-1'
            />

            <div className='ms-1' style={{ width: '22px' }} />
          </div>
        ),
      sortable: false,
      center: true,
      maxWidth: '210px',
      minWidth: '210px',
    },

    {
      name: t('documents.lbl_Dispatched'),
      selector: (row) => row.confirmedCount ?? 'confirmedCount',
      cell: (row) => {
        let sum = 0;

        row.deliveries.forEach(
          (delivery) => (sum += delivery.confirmedCount ?? 0)
        );

        return (
          <>
            {!isDocumentUneditable ? (
              <Form.Control
                type='number'
                className='text-end'
                min={0}
                value={row.deliveredCount ?? 0}
                onChange={handleRowDispatchedChange(row.rowNo)}
                onBlur={handleRowDispatchedBlur(row.rowNo)}
              />
            ) : (
              <span>
                {row.confirmedCount}{' '}
                <small>{validateUnitExists(row.unit)}</small>
              </span>
            )}
          </>
        );
      },
      sortable: false,
      center: true,
      maxWidth: '110px',
      minWidth: '110px',
    },

    {
      name: '',
      selector: (row) => row.quantity,
      cell: (row) => {
        return row.quantity === row.deliveredCount ? (
          <span>
            <CheckCircleFill className='icon-ez-green' />{' '}
            <TbTruckDelivery size={20} />
          </span>
        ) : (
          <span>
            <span className='icon-ez-yellow'>
              {t('documents.inf_QtysRemaining', {
                quantities: isNaN(row.quantity - (row.deliveredCount ?? 0))
                  ? row.quantity
                  : row.quantity - (row.deliveredCount ?? 0),
              })}
            </span>{' '}
            <TbTruckDelivery size={20} />{' '}
          </span>
        );
      },
      sortable: false,
      right: true,
      style: { marginTop: '5px' },
      minWidth: '160px',
      maxWidth: '160px',
    },

    {
      name: t('documents.lbl_PriceUnit'),
      selector: (row) => row.price,
      cell: (row) =>
        formatMonetaryValueWithoutDecimals(
          order.currencyCode,
          row.price,
          appState
        ),
      sortable: false,
      right: true,
      style: { marginTop: '5px' },
      minWidth: '90px',
      maxWidth: '90px',
    },

    {
      name: t('projectSettings.lbl_TotalPrice'),
      selector: (row) => row.total,
      cell: (row) =>
        row.total
          ? formatMonetaryValue(
              order.currencyCode,
              truncateDecimals(row.total, 2),
              appState
            )
          : '-',
      sortable: false,
      right: true,
      style: { marginTop: '5px' },
      minWidth: '90px',
      maxWidth: '90px',
    },
  ];

  const customStyles = {
    rows: {
      style: {
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'start',
      },
    },
  };

  const inputFile = useRef<HTMLInputElement>(null);

  const handleOpenFilePicker = () => {
    inputFile.current !== null && inputFile.current.click();
  };

  const handleDeleteAttachment = async (id: number) => {
    let timeout: any = 0;

    toast.success(t('projectSettings.inf_DeletingFile'), {
      className: 'delete-attachment-toast',
      autoClose: 5000,
      pauseOnHover: true,
      closeButton: (
        <Button
          className='btn-undo-delete-attachment'
          onClick={() => clearTimeout(timeout)}
        >
          <ArrowCounterclockwise size={20} />
        </Button>
      ),
    });

    timeout = setTimeout(async () => {
      accessToken &&
        (await IdentityApi.deleteExternalSellerOfferAttachment(
          accessToken,
          id,
          'Order'
        ));

      await getOrder();
    }, 5000);
  };

  const handleAddNewFile = async (file: File) => {
    const reader = new FileReader();
    const formData = new FormData();

    formData.append('file', file);
    formData.append('documentType', 'Order');

    const input: HTMLInputElement | null =
      document.querySelector('input[type=file]');

    if (input !== null) {
      input.value = '';
    }

    reader.readAsDataURL(file);
    reader.onload = async function () {
      reader.result !== undefined &&
        accessToken &&
        (await IdentityApi.addExternalSellerOfferAttachment(
          accessToken,
          formData
        ));

      await getOrder();
    };
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <SellerHeader requiresNoAuth localizationId={order.localizationId ?? 1} />
      <div className='container-fluid p-2'>
        <Card>
          <Card.Header>
            <Row>
              <Col lg={4}>
                <ExternalSellerCustomerProfileModal company={customer} />
                <ExternalSellerContactProfileModal
                  companyName={customer.name}
                  contact={contact}
                />

                <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle
                    id='project-view-attachments-toggle'
                    className='btn btn-eleczap dropdown-toggle ms-3'
                  >
                    <Paperclip /> {t('common.btn_AllFiles')}(
                    {order.attachments?.length})
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='super-colors'>
                    {order.attachments?.map((file, index: number) => (
                      <ExternalSellerAttachmentItem
                        handleDeleteAttachment={handleDeleteAttachment}
                        attachment={file}
                        index={index}
                        accessToken={''}
                        type={'Order'}
                      />
                    ))}

                    <Dropdown.Divider />
                    <FilePicker
                      maxSize={10}
                      sizeUnit='MB'
                      extensions={[
                        '.jpg',
                        '.png',
                        '.jpeg',
                        '.pdf',
                        '.txt',
                        '.doc',
                        '.docx',
                        '.xls',
                        '.xlsx',
                        '.ppt',
                        '.pptx',
                        '.dwg',
                        '.dxf',
                      ]}
                      onFilePicked={(file) => {
                        handleAddNewFile(file);
                      }}
                      onError={(code) => {
                        toast.error(code);
                      }}
                    >
                      <Dropdown.Item
                        onClick={handleOpenFilePicker}
                        eventKey='4'
                      >
                        {t('projectSettings.lbl_AddNewAttachment')}
                      </Dropdown.Item>
                    </FilePicker>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col className='text-center' lg={4}>
                <h2>
                  {t('documents.hd_Order')} {order.code}
                </h2>
              </Col>
              <Col
                className='text-center d-flex justify-content-lg-end justify-content-sm-center'
                lg={4}
              >
                <h3 className='d-flex align-items-center'>
                  {order.status === 'Unconfirmed' && (
                    <>
                      {t('documents.lbl_Unconfirmed')}{' '}
                      <OrderUnconfirmed className='icon-ez-yellow ms-1' />
                    </>
                  )}
                  {order.status === 'Confirmed' && (
                    <>
                      {t('documents.lbl_Confirmed')}{' '}
                      <OrderConfirmed className='icon-ez-green ms-1' />
                    </>
                  )}
                  {order.status === 'Dispatched' && (
                    <>
                      {t('documents.lbl_Dispatched')}{' '}
                      <OrderDispatched className='icon-ez ms-1' />
                    </>
                  )}
                  {order.status === 'Sending' && (
                    <>
                      {t('documents.lbl_Sending')}{' '}
                      <OrderSending className='icon-ez ms-1' />
                    </>
                  )}

                  {order.status === 'Cancelled' && (
                    <>
                      {t('documents.lbl_Cancelled')}{' '}
                      <OrderCancelled className='icon-ez-red ms-1' />
                    </>
                  )}
                </h3>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_Date')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(order.documentDate).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_UpdatedOn')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(order.lastUpdated).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>

                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentTo')}:
                  </Col>
                  <Col>
                    <strong>{order.sentToCompanyName}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col' />
                  <Col>
                    <strong>{order.sentToContactName}</strong>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row className='flex-nowrap'>
                  <Col className='text-end pe-0 text-nowrap'>
                    <Row className='mb-0'>
                      <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_LinkedOffer')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_MyOrderNumber')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>
                        {t('projectSettings.lbl_RequestedDeliveryDate')}:
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                    </Row>

                    {order.deliveryAddressLine2 !== null && <br />}

                    {order.deliveryAddressLine3 !== null && <br />}

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_PaymentTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_DeliveryTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_ConfirmedDate')}:</Col>
                    </Row>
                  </Col>

                  <Col
                    className='pe-3 d-flex flex-column'
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        <span className='btn-link-bold-no-link'>
                          {order.projectName}
                        </span>
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.offerCode !== null && order.offerCode !== '' ? (
                          <Link
                            to={`/external-seller-offer?accessToken=${order.offerAccessToken}`}
                            className='btn-link-bold'
                          >
                            {order.offerCode}
                          </Link>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.code !== null && order.code !== '' ? (
                          <strong>{order.code}</strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.requestedDeliveryDate !== null ? (
                          <strong>
                            {dayjs(order.requestedDeliveryDate).format(
                              'DD.MM.YYYY'
                            )}
                          </strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='d-flex flex-column text-nowrap'>
                        {order.deliveryAddressLine1 !== null ? (
                          <strong>{order.deliveryAddressLine1}</strong>
                        ) : (
                          <br />
                        )}
                        <strong>{order.deliveryAddressLine2 ?? <br />}</strong>
                        <strong>
                          {order.deliveryAddressLine3 !== '' ? (
                            order.deliveryAddressLine3 ?? <br />
                          ) : (
                            <br />
                          )}
                        </strong>
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.paymentTerm !== null ? (
                          <strong>{order.paymentTerm}</strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.deliveryTerm !== null ? (
                          <strong>{order.deliveryTerm}</strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>
                        {isDocumentUneditable ? (
                          <strong>{date ?? '-'}</strong>
                        ) : (
                          <DayPickerInput
                            dayPickerProps={{
                              fromMonth: new Date(),
                              disabledDays: disabledDays,
                            }}
                            style={{ width: '100%' }}
                            component={(props: any) => (
                              <InputGroup>
                                <FormControl
                                  readOnly
                                  className='shorter-height-fields'
                                  {...props}
                                />
                                <InputGroup.Text
                                  onClick={handleDateRemove}
                                  className='bg-light cursor-pointer'
                                  id='basic-addon1'
                                >
                                  <XSquare className='icon-ez-gray' />
                                </InputGroup.Text>
                              </InputGroup>
                            )}
                            value={date || t('common.lbl_DDMMYYYY')}
                            onDayChange={handleDateChange}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='my-5'>
              <DataTable
                noDataComponent={
                  <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
                }
                columns={columns}
                data={order.rows}
                customStyles={customStyles}
              />
            </Row>

            <Row className='mb-3'>
              <Col lg={6}>
                <span>{t('documents.lbl_CustomerComments')} : </span>{' '}
                {order.comment}
              </Col>
            </Row>

            <Row className='justify-content-between'>
              <Col lg={6}>
                <Form.Group className='mb-3' controlId='comments'>
                  <Form.Label>{t('documents.lbl_YourComments')}</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={4}
                    disabled={isDocumentUneditable}
                    value={yourComment}
                    onChange={(event) => setYourComment(event.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Row className='mt-3'>
                  <Col>
                    <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                  </Col>
                  <Col>
                    <h3 className='text-end'>
                      {useFormatMonetaryValue(order.currencyCode, order.total)}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-end'>
                    {t('common.inf_TotalPriceSubtext')}
                  </Col>
                </Row>
              </Col>
            </Row>

            {!isDocumentUneditable && (
              <Row>
                <Col>
                  <ExternalSellerOrderCancelModal
                    getOrder={getOrder}
                    accessToken={accessToken}
                  />
                </Col>
                <Col>
                  <span className='float-end'>
                    <Button
                      onClick={handleSaveOrder}
                      variant='btn btn-eleczap d-flex align-items-center'
                    >
                      <Save className='me-1' />
                      {t('common.btn_Save')}
                    </Button>
                  </span>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </div>
      <SellerFooter />
    </>
  );
};

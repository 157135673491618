import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';
import { ChangeEvent, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  handleVisibleOrArrivingChange: (
    checked: boolean,
    stock: StocksSharedToPartnersModel,
    field: 'isActive' | 'shareArriving'
  ) => Promise<void>;
  hasRightsToEdit: boolean;
  row: StocksSharedToPartnersModel;
};

export const SharedStocksArrivingToggle = ({
  handleVisibleOrArrivingChange,
  hasRightsToEdit,
  row,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const [checkedValue, setCheckedValue] = useState<boolean>(row.isActive);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = (): void => setShow(false);
  const handleShow = (): void => setShow(true);

  const { t } = useTranslation('components');

  const handleOpenConfirmation = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;

    setCheckedValue(checked);

    if (checked) {
      setIsLoading(true);

      await handleVisibleOrArrivingChange(checked, row, 'shareArriving');
      setIsLoading(false);
    } else {
      handleShow();
    }
  };

  const handleConfirmation = async () => {
    setIsLoading(true);
    await handleVisibleOrArrivingChange(checkedValue, row, 'shareArriving');
    setIsLoading(false);

    handleClose();
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Form.Check
        onChange={(event) => handleOpenConfirmation(event)}
        type='switch'
        checked={row.shareArriving}
        disabled={!hasRightsToEdit}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          {t('stockSettings.hd_DisableArriving', { stockName: row.stockName })}
        </Modal.Header>
        <Modal.Body>
          {t('stockSettings.inf_YourSharedStocks_DisableArriving', {
            stockName: row.stockName,
            partnerName: row.partnerCompanyName,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Cancel')}
          </Button>
          <Button variant='btn btn-danger' onClick={handleConfirmation}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, ProgressBar, Spinner } from "react-bootstrap";
import { SellerApi } from "api/SellerApi";
import { useAuthUser } from "react-auth-kit";
import { LoginResponseUserModel } from "models/identity/LoginResponseUserModel";
import { BulkImageValidateModel } from "models/seller/bulkimageupload/BulkImageUploadValidateModel";
import { useTranslation } from "react-i18next";

type PropTypes = {
  visible: boolean;
  handleClose: () => void;
};

type OpenModalProps = {
  handleClose: () => void;
  setImageCheckErrors: (errors: string[]) => void;
  showUploadModal: (files: File[]) => void;
};

const OpenModal = (props: OpenModalProps) => {
  const { t } = useTranslation("components");

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileCheckFinished, setFileCheckFinished] = useState<boolean>(false);

  const handleFilesSelected = async (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (ev.target.files === null) {
      return;
    }

    if (ev.target.files.length > 500) {
      props.setImageCheckErrors([t("sellerBulkImage.errorMax500")]);
      return;
    }

    let filesCount = ev.target.files.length;

    let rx = /^.*(\.png|\.jpg|\.gif|\.bmp|\.tif)$/i;
    let promises: Promise<string>[] = [];

    for (let i = 0; i < filesCount; ++i) {
      let file = ev.target.files[i];

      const promise = new Promise<string>((resolve, reject) => {
        let filename = file.name;
        if (file.size > 5242800) {
          resolve(t("sellerBulkImage.errorImageTooBig", { filename }));
          return;
        }

        if (!rx.test(file.name)) {
          resolve(t("sellerBulkImage.errorImageWrongType", { filename }));
          return;
        }

        let fileReader = new FileReader();

        fileReader.onload = (l) => {
          const image = new Image();

          image.onload = (ie) => {
            if (image.width < 300 || image.height < 300) {
              resolve(t("sellerBulkImage.errorImageWrongSize", { filename }));
              return;
            }
            resolve("");
          };
          image.src = (l.target?.result as string) ?? "";
        };

        fileReader.readAsDataURL(file);
      });
      promises.push(promise);
    }

    Promise.all(promises).then((x) => {
      let errors = x.filter((y) => {
        return y != null && y.length > 0;
      });
      if (errors.length > 0) {
        props.setImageCheckErrors(errors);
        setFileCheckFinished(false);
      }

      setFileCheckFinished(true);
    });
  };

  return (
    <Modal show={true}>
      <Modal.Header closeButton={true} onHide={props.handleClose}>
        <Modal.Title>{t("sellerBulkImage.importTitle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("sellerBulkImage.lbl_Info1")}</p>
        <p>{t("sellerBulkImage.lbl_Info2")}</p>
        <p>{t("sellerBulkImage.lbl_Info3")}</p>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>{t("sellerBulkImage.selectFiles")}</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            multiple={true}
            onChange={handleFilesSelected}
            ref={fileInputRef}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          {t("common.btn_Cancel")}
        </Button>
        <Button
          variant="primary"
          disabled={!fileCheckFinished}
          onClick={(x) => {
            props.showUploadModal(Array.from(fileInputRef.current!.files!));
          }}
        >
          {t("common.btn_Ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const UploadModal = (props: { handleClose: () => void; files: File[] }) => {
  const [showProgressBar, setShowProgressbar] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadComplete, setuploadComplete] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [validationMsg, setValidationMsg] =
    useState<BulkImageValidateModel | null>(null);
  const [uploadCompleteMsg, setUploadCompleteMsg] = useState<string | null>(
    null
  );
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { t } = useTranslation("components");

  const uploadImages = async () => {
    setUploadProgress(0);
    setShowProgressbar(true);
    setUploading(true);
    let filesList = props.files.filter((x) => {
      return validationMsg === null
        ? false
        : validationMsg.matchingFiles.indexOf(x.name) > -1;
    });

    for (let i = 0; i < filesList.length; ++i) {
      const data = new FormData();
      data.append("file", filesList[i]);
      await SellerApi.bulkUpload(currentUser.companyId, data);
      setUploadProgress(Math.round(((i + 1) / filesList.length) * 100));
    }

    let matchingProductsCount = validationMsg?.matchingProductsCount ?? 0;
    let matchingProductClassesCount =
      validationMsg?.matchingProductClassesCount ?? 0;

    setUploadCompleteMsg(
      t("sellerBulkImage.uploadCompleteMsg", {
        matchingProductsCount,
        matchingProductClassesCount,
      })
    );
    setUploading(false);
    setuploadComplete(true);
  };

  useEffect(() => {
    setShowProgressbar(false);
    setUploadProgress(0);
    setValidationMsg(null);
    setUploadCompleteMsg(null);
    let filenamesArray = new Array<string>();
    for (let i = 0; i < props.files.length; ++i) {
      filenamesArray.push(props.files[i].name);
    }

    SellerApi.bulkUploadValidate(currentUser.companyId, filenamesArray).then(
      (x) => {
        setValidationMsg(x);
      }
    );
  }, [props.files, currentUser.companyId]);

  const validationMsgContainer = ({
    imagesCount,
    matchingProductsCount,
    matchingProductClassesCount,
    overwrittenProductClassesCount,
    overwrittenProductsCount,
  }: BulkImageValidateModel) => (
    <div>
      <p>
        {t("sellerBulkImage.lbl_Warning1", {
          imagesCount,
          matchingProductsCount,
          matchingProductClassesCount,
        })}
      </p>
      <p>
        {t("sellerBulkImage.lbl_Warning2", {
          overwrittenProductsCount,
          matchingProductsCount,
        })}
      </p>
      <p>
        {t("sellerBulkImage.lbl_Warning3", {
          overwrittenProductClassesCount,
          matchingProductClassesCount,
        })}
      </p>
      <p>{t("sellerBulkImage.lbl_Warning4")}</p>
      <p>{t("sellerBulkImage.lbl_Warning5")}</p>
    </div>
  );

  const imageUploadCompleteContainer = (message: string) => (
    <div>{message}</div>
  );

  const handleOkClick = async () => {
    if (!uploadComplete) {
      await uploadImages();
    } else {
      props.handleClose();
    }
  };

  const handleHideModal = () => {
    if (!uploading) {
      props.handleClose();
    }
  };

  return (
    <Modal show={true}>
      <Modal.Header closeButton={true} onHide={handleHideModal}>
        <Modal.Title>{t("sellerBulkImage.confirmImport")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {validationMsg == null ? (
          <h3 className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">...</span>
            </Spinner>
          </h3>
        ) : uploadCompleteMsg !== null ? (
          imageUploadCompleteContainer(uploadCompleteMsg)
        ) : (
          validationMsgContainer(validationMsg)
        )}

        {!showProgressBar ? (
          <></>
        ) : (
          <ProgressBar
            now={uploadProgress}
            label={`${uploadProgress}%`}
            striped={true}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={props.handleClose}
          disabled={uploading}
        >
          {t("common.btn_Cancel")}
        </Button>
        <Button
          variant="primary"
          disabled={
            validationMsg == null || validationMsg.imagesCount < 1 || uploading
          }
          onClick={handleOkClick}
        >
          {t("common.btn_Ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ErrorsModal = (props: {
  errorMessages: string[];
  handleClose: () => void;
}) => {
  return (
    <Modal show={true}>
      <Modal.Header closeButton={true} onHide={props.handleClose}>
        <Modal.Title>Import error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.errorMessages.map((x) => {
          return <p>{x}</p>;
        })}
      </Modal.Body>
    </Modal>
  );
};

export const BulkImgUploadModal = (props: PropTypes) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);

  return !props.visible ? (
    <></>
  ) : errors.length > 0 ? (
    <ErrorsModal
      errorMessages={errors}
      handleClose={() => {
        setErrors([]);
      }}
    />
  ) : uploadFiles.length > 0 ? (
    <UploadModal
      files={uploadFiles}
      handleClose={() => {
        setUploadFiles([]);
        props.handleClose();
      }}
    />
  ) : (
    <OpenModal
      handleClose={() => {
        setUploadFiles([]);
        props.handleClose();
      }}
      setImageCheckErrors={setErrors}
      showUploadModal={setUploadFiles}
    />
  );
};

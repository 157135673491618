import FilePicker from '@mavedev/react-file-picker';
import { CompanyApi } from 'api/CompanyApi';
import { ProjectDealsAttachmentItem } from 'components/shared/deals/ProjectAttachmentItem/ProjectDealsAttachmentItem';
import { AppContext } from 'contexts/user/AppContext';
import {
  AllProjectsNewDataModel,
  DealGroupModel,
} from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProjectAttachmentsModel } from 'models/shared/attachments/AttachmentModel';
import { useContext, useRef } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Dropdown } from 'react-bootstrap';
import { ArrowCounterclockwise, Paperclip } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  dealsDetail: DealGroupModel;
  dealId: number;
  type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice';
  project: AllProjectsNewDataModel;
  listOfAttachments: ProjectAttachmentsModel[];
};

export const DealAttachement = ({
  dealsDetail,
  dealId,
  type,
  project,
  listOfAttachments,
}: PropTypes) => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');

  const { appState } = useContext(AppContext);

  const inputFile = useRef<HTMLInputElement>(null);

  const handleOpenFilePicker = () => {
    inputFile.current !== null && inputFile.current.click();
  };

  const handleAddNewFile =
    (type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice', dealId: number) =>
    async (file: File) => {
      const reader = new FileReader();
      const formData = new FormData();

      formData.append('file', file);
      formData.append('dealId', dealId.toString());
      formData.append('documentType', type);

      const input: HTMLInputElement | null =
        document.querySelector('input[type=file]');

      if (input !== null) {
        input.value = '';
      }

      reader.readAsDataURL(file);
      reader.onload = async function () {
        reader.result !== undefined &&
          (await CompanyApi.addNewDocumentAttachment(
            currentUser.companyId,
            project.id,
            formData
          ));

        await appState.getProjects();
        // await getProjectInquiry(
        //   projectId,
        //   projectDetails.id,
        //   currentUser.companyId,
        //   setProjectDetails
        // );
      };
    };

  const handleDeleteAttachment = async (
    id: number,
    dealId: number,
    type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice'
  ) => {
    let timeout: any = 0;

    toast.success(t('projectSettings.inf_DeletingFile'), {
      className: 'delete-attachment-toast',
      autoClose: 5000,
      pauseOnHover: true,
      closeButton: (
        <Button
          className='btn-undo-delete-attachment'
          onClick={() => clearTimeout(timeout)}
        >
          <ArrowCounterclockwise size={20} />
        </Button>
      ),
    });

    await CompanyApi.deleteDocumentAttachment(
      currentUser.companyId,
      project.id,
      id,
      dealId,
      type
    );

    await appState.getProjects();
  };

  return (
    <Dropdown drop='down' className='me-2 attachments' as={'span'}>
      <Dropdown.Toggle
        as={'span'}
        id='project-view-attachments-toggle'
        className='ms-3 posotion-relative'
      >
        {/* <Badge
          pill
          className='position-absolute'
          style={{ right: '27px', top: '0px' }}
          bg='info'
        >
          {listOfAttachments?.length}
        </Badge> */}
        <Paperclip
          size={18}
          style={{ transform: 'rotate(45deg)' }}
          className='icon-ez'
        />{' '}
        ({listOfAttachments?.length})
      </Dropdown.Toggle>
      <Dropdown.Menu className='super-colors'>
        {listOfAttachments?.map(
          (attachment: ProjectAttachmentsModel, index: number) => (
            <ProjectDealsAttachmentItem
              handleDeleteAttachment={handleDeleteAttachment}
              attachment={attachment}
              index={index}
              dealId={dealId}
              type={type}
            />
          )
        )}

        <Dropdown.Divider />
        <FilePicker
          maxSize={10}
          sizeUnit='MB'
          extensions={[
            '.jpg',
            '.png',
            '.jpeg',
            '.pdf',
            '.txt',
            '.doc',
            '.docx',
            '.xls',
            '.xlsx',
            '.ppt',
            '.pptx',
            '.dwg',
            '.dxf',
          ]}
          onFilePicked={handleAddNewFile(type, dealId)}
          onError={(code) => {
            toast.error(code);
          }}
        >
          {type !== 'Offer' ? (
            <Dropdown.Item onClick={handleOpenFilePicker} eventKey='4'>
              {t('projectSettings.lbl_AddNewAttachment')}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item disabled eventKey='4'></Dropdown.Item>
          )}
        </FilePicker>
      </Dropdown.Menu>
    </Dropdown>
  );
};

import { SellerApi } from 'api/SellerApi';
import { useFormik } from 'formik';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksSharedWithMeModel } from 'models/shared/stocksSharedWithMe/StocksSharedWithMeModel';
import { StocksSharedWithMeUpdateModel } from 'models/shared/stocksSharedWithMe/StocksSharedWithMeUpdateModel';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { getAllStocksSharedWithMe } from '../../StocksSharedWithMe/utilsStocksSharedWithMe';

/* Formik init */
export const useFormikHookForStocksSharedWithMeUpdate = (
  currentUser: LoginResponseUserModel,
  setStocks: (values: StocksSharedWithMeModel[]) => void,
  setShow: (values: boolean) => void,
  stock: StocksSharedWithMeModel,
  t: any
): any => {
  const handleSubmit = async (
    values: StocksSharedWithMeUpdateModel
  ): Promise<void> => {
    toast.dismiss();
    await SellerApi.updateStockSharedWithMe(
      currentUser.companyId,
      values,
      stock.id
    );
    toast.info(t('stockSettings.inf_StockSaved'));

    await getAllStocksSharedWithMe(currentUser, setStocks);
    setShow(false);
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),
  });

  const formik = useFormik<StocksSharedWithMeModel>({
    initialValues: { ...stock },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedStock: StocksSharedWithMeUpdateModel = {
        name: values.name,
        isVisible: values.isVisible,
        showArriving: values.showArriving,
        isSharingAccepted: values.isSharingAccepted,
        displayDetails: values.displayDetails,
      };
      await handleSubmit(updatedStock);

      formik.resetForm();
      formik.setSubmitting(false);
    },
  });

  return formik;
};

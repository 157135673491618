import { BuyerFavoriteProductSwitch } from 'components/buyer/products/BuyerFavoriteProductSwitch';
import { CustomProductListQtyInput } from 'components/shared/CustomInputs/CustomProductListQtyInput';
import { ImageWithFallback } from 'components/shared/ImageWithFallback';
import { AppContext } from 'contexts/user/AppContext';
import { useGetFormattedPrice } from 'hooks/useGetFormattedPrice';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import NoProductImage from 'media/EZ_new_notext_border_grey.png';
import { RowMOQPriceModel } from 'models/buyer/common/RowMOQPriceModel';
import { RowStockLevelModel } from 'models/buyer/common/RowStockLevelModel';
import { GroupedProductModel } from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductModel } from 'models/shared/product/ProductModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import { StarFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getMOQColumns } from 'utils/getMOQColumns';
import { getStockLevelColumns } from 'utils/getStockLevelColumns';

type PropsType = {
  item: GroupedProductModel;
  productDetailRoute: string;
  favoriteStockExists: boolean;
  favouriteSellerId: number;
  isPricesLoading?: boolean | false;
};

export const BuyerProductListTileGrouped = ({
  item,
  productDetailRoute,
  favoriteStockExists,
  favouriteSellerId,
  isPricesLoading,
}: PropsType) => {
  const { t } = useTranslation('components');
  const [expandRow, setExpandRow] = useState<boolean>(true);
  const canSeePrices = useUserCanSeePrices();
  const validateUnitExists = useValidateUnitExists();
  const getFormattedPrice = useGetFormattedPrice();
  const [checkedProduct, setCheckedProduct] = useState<ProductModel | null>(
    null
  );

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState, appContextDispatch } = useContext(AppContext);

  useEffect(() => {
    if (item !== null && item.products.length === 1) {
      setCheckedProduct(item.products[0]);
    }
  }, [item]);

  let productListClasses = ['card-wrapper', 'product-class-list'];

  const heightOfOnePriceStep = 5;

  /* Check if item actually has any favourite stock. Necessary for search results */
  const favStock = item.products[0]?.stockLevels.filter(
    (stock) => stock.isFavoriteStock
  );
  const itemHasFavoriteStock = favStock?.length > 0;

  const moqColumns = getMOQColumns(getFormattedPrice, t);

  const stockLevelColumns = getStockLevelColumns(
    t,
    favoriteStockExists,
    itemHasFavoriteStock
  );

  const stockPopover = (stockLevels: RowStockLevelModel[]) => (
    <Popover style={{ minWidth: '500px' }} id='popover-stockLevels'>
      <Popover.Header as='h3'>{t('projectSettings.lbl_Stocks')}</Popover.Header>
      <Popover.Body>
        <DataTable columns={stockLevelColumns} data={stockLevels} />
      </Popover.Body>
    </Popover>
  );

  const moqPopover = (moqPrices: RowMOQPriceModel[]) => (
    <Popover id='popover-moqPrices'>
      <Popover.Body>
        <DataTable columns={moqColumns} data={moqPrices} />
      </Popover.Body>
    </Popover>
  );

  const getTotalStockLevel = (row: RowStockLevelModel[]) => {
    let totalStockLevel = 0;
    row.forEach((x) => {
      totalStockLevel += x.inStock;
    });
    return totalStockLevel;
  };

  const getTotalArriving = (row: RowStockLevelModel[]) => {
    let totalArriving = 0;
    row.forEach((x) => {
      totalArriving += x.arriving;
    });
    return totalArriving;
  };

  const getRowHeight = (item: GroupedProductModel) => {
    const fProducts = item.products.filter(
      (x) =>
        appState.buyerCurrentSuppliers &&
        appState.buyerCurrentSuppliers.findIndex(
          (y) => y.isSelected && y.value === x.sellerCompanyId
        ) > -1
    );
    const rh =
      fProducts.length > 0
        ? fProducts
            .map((x) => {
              const result = heightOfOnePriceStep * (x.moqPrices.length - 3);
              return result < 0 ? 0 : result;
            })
            .reduce((x, y) => x + y) +
          63 * fProducts.length
        : 0;
    return rh < 100 ? 100 : rh;
  };

  return item.products.filter(
    (x) =>
      appState.buyerCurrentSuppliers &&
      appState.buyerCurrentSuppliers?.findIndex(
        (y) => y.isSelected && y.value === x.sellerCompanyId && !y.isMarketplace
      ) > -1
  ).length > 0 ||
    item.products.filter(
      (x) =>
        appState.buyerCurrentSuppliers &&
        appState.buyerCurrentSuppliers?.findIndex((supplier) =>
          supplier.subSellers?.some(
            (subSeller) =>
              subSeller.value === x.sellerCompanyId && subSeller.isSelected
          )
        ) > -1
    ).length > 0 ? (
    <Card
      style={{
        // height: expandRow ? `${getRowHeight(item)}px` : '135px',

        overflowY: 'hidden',
        marginBottom: '.5rem',
      }}
      className={productListClasses.join(' ')}
    >
      <Card.Body className='p-1 mt-2'>
        <Row className='align-items-start'>
          <Col
            style={{ maxWidth: '120px' }}
            className='d-flex align-items-center flex-row'
          >
            <ImageWithFallback
              alt='Product class image'
              className='product-class-list-image ms-0'
              src={
                checkedProduct ? checkedProduct.image : item.products[0]?.image
              }
              fallback={NoProductImage}
            />
          </Col>
          <Col>
            <Row>
              <Col style={{ maxWidth: '130px' }}>
                <Link
                  style={{ cursor: 'pointer' }}
                  to={`${productDetailRoute}/${encodeURIComponent(
                    item.products[0]?.code
                  )}`}
                  className='btn-link-bold wrap-ellipsis'
                >
                  {item.products[0]?.code}
                </Link>
                <br />
                <small className='wrap-ellipsis'>
                  {item.products[0]?.manufacturerName}
                </small>
              </Col>
              {/* <Col
                  className='ms-0 ps-0 wrap-ellipsis'
                  style={{
                    maxWidth: '150px',
                  }}
                >
                  <span>{item.products[0].manufacturerName}</span>
                </Col> */}
              <Col className='ms-0 ps-0'>
                {/* <span>{item.products[0].productClasses[0]?.name}</span> */}
              </Col>
            </Row>

            {item.products.map((product, index) => {
              return (
                (appState.buyerCurrentSuppliers?.findIndex(
                  (supplier) =>
                    supplier.value === product.sellerCompanyId &&
                    supplier.isSelected
                ) !== -1 ||
                  appState.buyerCurrentSuppliers?.findIndex((supplier) =>
                    supplier.subSellers?.some(
                      (subSeller) =>
                        subSeller.value === product.sellerCompanyId &&
                        subSeller.isSelected
                    )
                  ) !== -1) && (
                  <Row className='justify-content-between'>
                    <Col>
                      <div className='d-flex align-items-center flex-row '>
                        <div className='ms-4 p-2'>
                          <BuyerFavoriteProductSwitch
                            isFavorite={product.isFavorite}
                            companyId={currentUser.companyId}
                            userId={currentUser.id}
                            productId={product.id}
                            sellerId={product.sellerCompanyId}
                          />
                        </div>
                        {/* <div className='p-2 ps-1 ms-1 me-0 pb-0 mb-0 align-self-center'>
                          <ImageWithZoom
                            src={product.image}
                            width={30}
                            height={30}
                            alt='ProductImage'
                            zoomedMaxHeight={300}
                            zoomedMaxWidth={300}
                          ></ImageWithZoom>
                        </div> */}
                        <div className='p-2'>
                          <h5 className='mb-0'>{product.name}</h5>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      {
                        <Row
                          className='float-end justify-content-end pb-0 mb-0'
                          style={{ width: '554px' }}
                        >
                          <Col
                            style={{ maxWidth: '130px' }}
                            className='text-start d-flex align-items-center justify-content-start position-relative p-2 wrap-ellipsis'
                          >
                            <div className='position-relative ms-2 wrap-ellipsis'>
                              <div className='position-relative wrap-ellipsis'>
                                {favouriteSellerId ===
                                product.sellerCompanyId ? (
                                  <StarFill
                                    style={{ marginTop: '-5px' }}
                                    className='icon-ez-gray me-1'
                                  />
                                ) : (
                                  <span
                                    style={{ width: '10px' }}
                                    className='icon-ez-gray me-1 d-inline-block'
                                  />
                                )}
                                <OverlayTrigger
                                  placement='top'
                                  delay={{ show: 150, hide: 200 }}
                                  overlay={
                                    <Tooltip>
                                      {product.sellerCompanyShortName ??
                                        product.sellerCompanyName}
                                    </Tooltip>
                                  }
                                >
                                  <span className='wrap-ellipsis'>
                                    {product.sellerCompanyShortName ??
                                      product.sellerCompanyName}
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </Col>

                          <Col
                            className='text-end product-list-col-in-stock p-2'
                            style={{
                              maxWidth: '80px',
                            }}
                          >
                            {isPricesLoading ? (
                              <Spinner animation='border' size='sm' />
                            ) : (
                              <>
                                {product.stockLevels?.length > 1 ? (
                                  <OverlayTrigger
                                    trigger='hover'
                                    placement='auto'
                                    overlay={stockPopover(product.stockLevels)}
                                  >
                                    <span>
                                      <h5>
                                        <strong className='btn-link-bold'>
                                          {favoriteStockExists
                                            ? product.stockLevels[0].inStock
                                            : getTotalStockLevel(
                                                product.stockLevels
                                              )}
                                          <small>
                                            {' '}
                                            {validateUnitExists(product.unit)}
                                          </small>
                                        </strong>
                                      </h5>
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  <h5>
                                    {getTotalStockLevel(product.stockLevels)}
                                    <small>
                                      {' '}
                                      {validateUnitExists(product.unit)}
                                    </small>
                                  </h5>
                                )}
                              </>
                            )}
                          </Col>

                          <Col
                            className='text-end p-2'
                            style={{
                              maxWidth: '85px',
                            }}
                          >
                            {isPricesLoading ? (
                              <Spinner animation='border' size='sm' />
                            ) : (
                              <h5>
                                {favoriteStockExists
                                  ? product.stockLevels[0]?.arriving
                                  : getTotalArriving(product.stockLevels)}
                                <small>
                                  {' '}
                                  {validateUnitExists(product.unit)}
                                </small>
                              </h5>
                            )}
                          </Col>
                          <Col
                            className='text-end p-2'
                            style={{
                              maxWidth: '160px',
                            }}
                          >
                            {isPricesLoading ? (
                              <Spinner animation='border' size='sm' />
                            ) : (
                              <h5>
                                {product.orderStep}
                                <small> / {product.packageSize}</small>{' '}
                                <small>
                                  {' '}
                                  {validateUnitExists(product.unit)}
                                </small>
                              </h5>
                            )}
                          </Col>

                          <Col
                            style={{
                              maxWidth: '85px',
                              height: expandRow
                                ? `${
                                    heightOfOnePriceStep *
                                      product.moqPrices.length +
                                    5
                                  }px`
                                : `${heightOfOnePriceStep * 3 + 5}px`,
                              // overflowY: expandRow ? 'visible' : 'hidden',
                            }}
                            className='text-end product-list-col-prices p-2'
                          >
                            {canSeePrices(
                              product.moqPrices.length > 1 ? (
                                <OverlayTrigger
                                  trigger='hover'
                                  placement='auto'
                                  overlay={moqPopover(product.moqPrices)}
                                >
                                  <span>
                                    <h5 className='btn-link-bold'>
                                      {getFormattedPrice(product.moqPrices[0])}
                                    </h5>
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                <h5>
                                  {getFormattedPrice(product.moqPrices[0])}
                                </h5>
                              )
                            )}
                          </Col>
                        </Row>
                      }
                    </Col>
                  </Row>
                )
              );
            })}
          </Col>

          <Col
            style={{ maxWidth: '175px', minWidth: '175px' }}
            className='align-self-center d-flex justify-content-center product-list-col-add-to-project'
          >
            <CustomProductListQtyInput product={item} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  ) : (
    <></>
  );
};

import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { BulkImgUploadModal } from "./productImageUpload/BulkImageUploadModal";

const ProductGroups = () => {
  const { t } = useTranslation("components");
  const [showFileImport, setShowFileImport] = useState<boolean>(false);
  const handleCloseFileImport = () => {
    setShowFileImport(false);
  };

  return (
    <Card>
      <Card.Header>
        <h4>{t("productGroups.lbl_Title")}</h4>
      </Card.Header>
      <Card.Body>
        <BulkImgUploadModal
          visible={showFileImport}
          handleClose={() => {
            setShowFileImport(false);
          }}
        />
        <Form.Group controlId="formFile" className="mb-3">
          <Button
            onClick={() => {
              setShowFileImport(true);
            }}
          >
            {t("productGroups.importImages")}
          </Button>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default ProductGroups;

import { SellerApi } from 'api/SellerApi';
import { getAllStocksSharedToPartners } from 'components/seller/settings/stocks/StocksSharedToPartners/utilsStocksSharedToPartners';
import { useFormik } from 'formik';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';
import { StocksSharedToPartnersUpdateModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersUpdateModel';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* Formik init */
export const useFormikHookForEditYourSharedStocks = (
  currentUser: LoginResponseUserModel,
  stock: StocksSharedToPartnersModel,
  setStocks: (values: StocksSharedToPartnersModel[]) => void,
  setShow: (values: boolean) => void,
  t: any
): any => {
  const handleSubmit = async (
    values: StocksSharedToPartnersUpdateModel
  ): Promise<void> => {
    toast.dismiss();
    await SellerApi.updateStockSharedToPartner(
      currentUser.companyId,
      values,
      stock.id
    );
    toast.info(t('stockSettings.inf_StockSaved'));
  };

  const validationSchema = yup.object({
    priceformulaCode: yup.string().required(t('common.err_ValueRequired')),
  });

  const initialValues: StocksSharedToPartnersUpdateModel = {
    shareArriving: stock.shareArriving,
    isActive: stock.isActive,
    priceformulaCode: stock.priceformulaCode,
  };

  const formik = useFormik<StocksSharedToPartnersUpdateModel>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values: StocksSharedToPartnersUpdateModel) => {
      await handleSubmit(values);
      await getAllStocksSharedToPartners(currentUser, setStocks);
      setShow(false);
      formik.resetForm();
      formik.setSubmitting(false);
    },
  });

  return formik;
};

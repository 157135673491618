import { useEffect } from 'react';
import './loader.css';
export const LogoLoader = () => {
  useEffect(() => {
    Array.from(document.getElementsByClassName('path')).forEach(
      (pathElement: any) => {
        const svgPathElement = pathElement as SVGPathElement;
        const length = svgPathElement.getTotalLength();
        svgPathElement.style.strokeDasharray = `${length}`;
        svgPathElement.style.strokeDashoffset = `${length}`;
      }
    );
  }, []);
  return (
    <div
      style={{
        width: 500,
        height: 500,
        zIndex: 4000,
        position: 'fixed',
        left: '45%',
        top: '37%',
      }}
    >
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='-10 -10 532 532'
        className='rotate-logo-svg'
        xmlSpace='preserve'
      >
        <g
          transform='translate(0.000000,298.000000) scale(0.100000,-0.100000)'
          fill='#1b9ad2'
          stroke='##1b9ad2'
        >
          <path
            className='path pathUp'
            d='M224 2880 c-71 -36 -119 -95 -134 -166 -24 -110 37 -232 139 -277 19
-9 228 -14 716 -18 670 -5 692 -6 774 -27 229 -61 433 -206 556 -397 54 -84
101 -182 111 -231 l6 -29 -469 0 c-521 0 -516 1 -589 -67 -123 -113 -92 -324
59 -397 41 -20 58 -21 786 -23 l744 -3 -6 215 c-4 159 -10 238 -25 305 -62
286 -185 515 -382 716 -224 228 -466 352 -795 409 -100 17 -169 19 -771 20
l-660 0 -60 -30z m1506 -17 c19 -6 69 -18 110 -28 82 -18 280 -93 280 -105 0
-4 11 -11 25 -14 44 -11 229 -143 311 -223 103 -100 181 -198 243 -306 135
-234 181 -407 188 -702 2 -99 0 -186 -5 -193 -11 -16 -1387 -20 -1448 -3 -46
12 -109 70 -130 119 -18 43 -18 123 0 165 7 18 28 47 47 65 69 68 46 66 561
67 428 1 469 3 487 19 16 14 17 23 9 59 -5 23 -22 71 -38 106 -103 220 -304
409 -526 491 -171 64 -154 63 -888 65 -628 1 -673 2 -709 20 -83 39 -129 109
-130 194 -1 96 60 179 150 208 50 16 1410 12 1463 -4z'
          />
          <path
            className='path pathDown'
            d='M280 1731 c-73 -15 -135 -63 -173 -136 -19 -35 -21 -55 -20 -148 7
-408 207 -804 540 -1069 150 -120 356 -220 540 -263 178 -41 234 -44 898 -44
352 1 654 5 671 10 18 5 53 24 80 41 142 96 134 319 -15 406 l-46 27 -690 6
-690 5 -96 26 c-407 110 -673 437 -704 865 -8 112 -23 154 -68 201 -58 61
-146 89 -227 73z m151 -52 c40 -20 81 -66 97 -110 6 -14 13 -68 17 -119 4 -52
8 -97 10 -100 2 -3 6 -21 9 -40 16 -102 82 -262 148 -358 48 -70 164 -187 235
-238 67 -48 193 -110 278 -137 113 -36 171 -39 810 -39 401 0 650 -4 685 -11
45 -9 63 -19 101 -58 110 -110 83 -276 -56 -341 l-50 -23 -615 -1 c-338 -1
-659 2 -713 6 -236 20 -472 104 -675 242 -113 76 -285 253 -367 377 -72 109
-139 251 -173 366 -47 157 -68 361 -47 447 34 137 180 202 306 137z'
          />
        </g>
      </svg>
    </div>
  );
};

import * as signalR from '@microsoft/signalr';
import { BuyerApi } from 'api/BuyerApi';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';

import { AppContext } from 'contexts/user/AppContext';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { useContext, useEffect } from 'react';
import { useAuthUser } from 'react-auth-kit';

const authStr = () => ` ${localStorage.getItem('_auth') ?? ''}`;

const options: signalR.IHttpConnectionOptions = {
  accessTokenFactory: authStr,
  withCredentials: true,
};

const connection = new signalR.HubConnectionBuilder()
  .withUrl('/hubs/mainhub', options)
  .build();

export const SignalRBroker = () => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appContextDispatch } = useContext(AppContext);

  const updateSuppliers = (suppliers: BuyerCurrentSuppliers[]) => {
    appContextDispatch({
      type: 'BUYER_SET_CURRENT_SUPPLIERS',
      suppliers: suppliers || null,
    });
    localStorage.removeItem(`_buyerCurrentSuppliers_${currentUser.id}`);
    localStorage.setItem(
      `_buyerCurrentSuppliers_${currentUser.id}`,
      JSON.stringify(suppliers)
    );

    appContextDispatch({
      type: 'BUYER_SET_ACTIVE_SELLER_COUNT',
      activeSupplierCount: suppliers.filter((x) => x.isActive).length || null,
    });
  };

  useEffect(() => {
    if (connection.state === signalR.HubConnectionState.Disconnected) {
      connection.on('permissionsChanged', (companyId: number) => {
        BuyerApi.getSellersCompaniesAndContacts(currentUser.companyId).then(
          (x) => {
            updateSuppliers(
              x.data.map((y) => {
                return {
                  isActive: y.isActive,
                  name: y.name,
                  value: y.id,
                  isExternalSeller: y.isExternalSeller,
                } as BuyerCurrentSuppliers;
              })
            );
          }
        );
      });

      connection
        .start()
        .then((x) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  });

  return <></>;
};

import { BuyerApi } from 'api/BuyerApi';
import {
  ProductBreadcrumb,
  ProductBreadcrumbItem,
} from 'components/shared/products/ProductBreadcrumb';
import 'components/shared/products/ProductClasses/ProductClasses.css';
import { ProductClassesList } from 'components/shared/products/ProductClasses/ProductClassesList';
import { ProductClassesQuickMenu } from 'components/shared/products/ProductClasses/ProductClassesQuickMenu';
import { AppContext } from 'contexts/user/AppContext';
import { BuyerProductClassWithSubclassesModel } from 'models/buyer/product/BuyerProductClassWithSubclassesModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router';

type UrlParamsType = {
  classId: string;
};

export const ProductSubclasses = () => {
  let { classId } = useParams<UrlParamsType>();

  const [productClasses, setproductClasses] = useState<
    BuyerProductClassWithSubclassesModel[]
  >([]);
  const [quickMenuItems, setquickMenuItems] = useState<
    BuyerProductClassWithSubclassesModel[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [productBreadCrumbItems, setproductBreadCrumbItems] = useState<
    ProductBreadcrumbItem[]
  >([]);

  const [mounted, setMounted] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const selectedBuyerSupplierId = appState.buyerCurrentSupplierId;

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const handleProductClassImageUpload = (productClassId: number) => {};

  useEffect(() => {
    let didCancel = false;

    const getQuickMenuItems = async () => {
      const level = 1;
      const productClassInfo = await BuyerApi.getAllProductClasses(
        currentUser.companyId,
        selectedBuyerSupplierId, // TODO: replace with selected Seller. At the moment set in server conf
        level,
        null,
        userLocaleId
      );
      if (!didCancel) {
        if (level === 1) {
          const firstLevelClasses = productClassInfo.productClasses.filter(
            (pc) => pc.level === 1
          );
          setquickMenuItems(firstLevelClasses);
        }
      }
      setIsLoading(false);
    };

    getQuickMenuItems();

    return () => {
      didCancel = true;
    };
  }, [currentUser.companyId, selectedBuyerSupplierId, userLocaleId]);

  useEffect(() => {
    const loadOtherLevels = async () => {
      if (classId !== null) {
        const productClassInfo = await BuyerApi.getAllProductClasses(
          currentUser.companyId,
          selectedBuyerSupplierId,
          null,
          Number(classId),
          userLocaleId
        );
        setproductClasses(productClassInfo.productClasses);

        const breadCrumbItems =
          productClassInfo.parents.map<ProductBreadcrumbItem>((c) => ({
            name: c.name,
            classId: c.id,
            classLevel: c.level,
            linkType: c.linkType,
            code: c.code,
          }));

        setproductBreadCrumbItems(breadCrumbItems);
      }
      setIsLoading(false);
    };

    loadOtherLevels();
  }, [classId, currentUser.companyId, selectedBuyerSupplierId, userLocaleId]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const BuyerBreadcrumbNode = (
    <div>
      <ProductBreadcrumb
        items={productBreadCrumbItems}
        productClassesRoute='/buyer/product-classes/'
        productSubClassesRoute='/buyer/product-subclasses/'
      />

      <ProductClassesQuickMenu
        handleProductClassImageUpload={handleProductClassImageUpload}
        productBreadCrumbItems={productBreadCrumbItems}
        defaultCollapse
        items={quickMenuItems}
        productSubClassesRoute='/buyer/product-classes'
        favoriteProductsRoute='/buyer/favorite-products'
        isLoading={isLoading}
        viewType='Buyer'
      />
    </div>
  );
  return !isLoading ? (
    <>
      <div className='container-fluid px-2 pb-2'>
        {mounted &&
          ReactDOM.createPortal(
            BuyerBreadcrumbNode,
            document &&
              document.getElementsByClassName('fixed-portal-receiver')[0]
          )}
        <Card className='card-wrapper '>
          <ProductClassesList
            handleProductClassImageUpload={handleProductClassImageUpload}
            imageReadOnly={true}
            items={productClasses}
            productListRoute='/buyer/product-classes'
            productSubClassesRoute='/buyer/product-subclasses'
          />
        </Card>
      </div>
    </>
  ) : null;
};

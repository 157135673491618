import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FileEarmarkArrowDownFill } from 'react-bootstrap-icons';

type PropTypes = {
  handleExport: (
    setFileIsSaving: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
};

export const PriceListExport = ({
  handleExport: handlePriceListExport,
}: PropTypes) => {
  const [fileIsSaving, setFileIsSaving] = useState<boolean>(false);

  return fileIsSaving ? (
    <Spinner animation='border' size='sm' variant='info' />
  ) : (
    <FileEarmarkArrowDownFill
      className='cursor-pointer'
      size={20}
      onClick={() => handlePriceListExport(setFileIsSaving)}
    />
  );
};

import { FileBlob } from 'api/ApiClient';

export const useSaveFileAs = () => {
  /** Causes Web Browser to save the passed in file blob in 'Save As' way */
  const saveFile = async (response: FileBlob) => {
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', response.name);
    document.body.appendChild(link);
    link.click();
  };

  return saveFile;
};

import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { AppContext } from 'contexts/user/AppContext';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { CaretDownFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropsType = {
  options: BuyerCurrentSuppliers[] | null;
  selectedOption: BuyerCurrentSuppliers;
  setSelectedSupplierFromProductTree: (value: BuyerCurrentSuppliers) => void;
  setIsLoading?: (value: boolean) => void;
  fromProject?: boolean;
  disabled?: boolean;
};

export const ProductTreeSelect = ({
  options,
  selectedOption,
  setSelectedSupplierFromProductTree,
  setIsLoading,
  fromProject,
  disabled,
}: PropsType) => {
  const [show, setShow] = useState(false);

  const { appContextDispatch } = useContext(AppContext);

  const { t } = useTranslation('components');
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleChangeChip =
    (id: number) => (event: MouseEvent<HTMLDivElement>) => {
      setIsLoading && setIsLoading(true);

      let selected = options?.find((option) => option.value === id);

      if (selected === undefined) {
        selected = options?.find((option) =>
          option.subSellers?.some((subSeller) => subSeller.value === id)
        );

        selected = selected?.subSellers?.find(
          (subSeller) => subSeller.value === id
        );
      }

      selected && setSelectedSupplierFromProductTree(selected);

      localStorage.removeItem(
        `_buyerCurrentProductsSupplier_${currentUser.id}`
      );
      localStorage.setItem(
        `_buyerCurrentProductsSupplier_${currentUser.id}`,
        JSON.stringify(selected)
      );

      appContextDispatch({
        type: 'SELLER_SET_SUPPLIER',
        supplierId: selected?.value || null,
      });
    };

  /* Detect click outside the tags-search div */
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !(event.target as Element).classList?.contains('projects-input')
      ) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const popover = (
    <div ref={ref} className='supplier-choice-chip-container'>
      {options?.map((option) =>
        option.isMarketplace ? (
          option.subSellers?.map(
            (subSeller) =>
              !subSeller.isArchived &&
              subSeller.isSelected && (
                <div
                  onClick={handleChangeChip(subSeller?.value)}
                  className={`supplier-choice-chip my-1`}
                >
                  <div className='supplier-choice-chip-avatar me-1'>
                    <AvatarWithFallback
                      imageUrl={subSeller?.logo}
                      initials={subSeller?.name[0]}
                      size={'small'}
                    />
                  </div>

                  <div className='supplier-choice-chip-name wrap-ellipsis'>
                    {`${option?.name} / ${subSeller.name}`}
                  </div>
                </div>
              )
          )
        ) : selectedOption?.value === option?.value ? (
          !option.isArchived && (
            <div className={`supplier-choice-chip my-1 active`}>
              <div className='supplier-choice-chip-avatar me-1'>
                <AvatarWithFallback
                  imageUrl={option?.logo}
                  initials={option?.name[0]}
                  size={'small'}
                />
              </div>

              <div className='supplier-choice-chip-name wrap-ellipsis'>
                {option?.name}
              </div>
            </div>
          )
        ) : (
          <div
            onClick={handleChangeChip(option?.value)}
            className={`supplier-choice-chip my-1`}
          >
            <div className='supplier-choice-chip-avatar me-1'>
              <AvatarWithFallback
                imageUrl={option?.logo}
                initials={option?.name[0]}
                size={'small'}
              />
            </div>

            <div className='supplier-choice-chip-name wrap-ellipsis'>
              {option?.name}
            </div>
          </div>
        )
      )}
    </div>
  );

  return (
    <div className='d-flex flex-column'>
      <strong className='icon-ez-gray ms-3'>
        <small>{t('common.lbl_ProductsFrom')}</small>
      </strong>
      <div
        style={{ width: 'fit-content' }}
        onClick={() => setShow(!show)}
        className='m-0 p-0 d-flex flex-row align-items-center cursor-pointer'
      >
        <h2 className='icon-ez ms-3'>{selectedOption?.name}</h2>
        <div
          style={{ minHeight: '35px', maxHeight: '35px' }}
          className='d-flex align-items-center cursor-pointer current-project-dropdown-icon position-relative'
        >
          <CaretDownFill size={25} className='ps-2 icon-ez' />

          {show ? popover : <div />}
        </div>
      </div>
    </div>
  );
};

import { AppContext } from 'contexts/user/AppContext';
import { InquiryCreateForSessionModel } from 'models/buyer/documents/InquiryModels';
import { Inquiry } from 'pages/buyer/Inquiry/Inquiry';
import { InquiryUnsent } from 'pages/buyer/Inquiry/InquiryUnsent';
import { InquiryUnsentClosedCard } from 'pages/buyer/Inquiry/InquiryUnsentClosedCard';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

export const InquiryUnsentContainer = (): JSX.Element => {
  const [inquiries, setInquiries] = useState<InquiryCreateForSessionModel[]>(
    []
  );
  const [isDiscarding, setIsDiscarding] = useState<boolean>(false);
  const [isInquirying, setIsInquirying] = useState<boolean>(false);
  const [currentSellerInquiryOpened, setCurrentSellerInquiryOpened] =
    useState<number>(1);

  const { t } = useTranslation('components');

  const history = useHistory();
  const { appState } = useContext(AppContext);

  const buyerCurrentProject = appState.userProfile?.currentProject;

  /* Get inquiries */
  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem('inquiries_unsent');

    if (sessionStorageData !== null) {
      const parsedData: InquiryCreateForSessionModel[] = JSON.parse(
        sessionStorageData?.toString()
      );
      setInquiries(parsedData);
      setCurrentSellerInquiryOpened(parsedData[0].sellerCompanyId);
    }
  }, []);

  const handleDiscardInquiry = (sellerId: number) => {
    let clonedInquiries = [...inquiries];

    const indexOfCurrentIteration = clonedInquiries.findIndex(
      (inquiry) => inquiry.sellerCompanyId === sellerId
    );

    const filteredInquires = clonedInquiries.filter(
      (inquiry) => inquiry.sellerCompanyId !== sellerId
    );

    const nextOrPrevInquiry =
      filteredInquires[indexOfCurrentIteration] !== undefined
        ? filteredInquires[indexOfCurrentIteration]
        : filteredInquires[indexOfCurrentIteration - 1];

    setInquiries(filteredInquires);

    sessionStorage.removeItem('inquiries_unsent');

    sessionStorage.setItem(
      'inquiries_unsent',
      JSON.stringify(filteredInquires)
    );

    filteredInquires.length === 0
      ? history.goBack()
      : handleOpenInquiry(nextOrPrevInquiry.sellerCompanyId);
  };

  // useBeforeUnload(!isInquirying, t('common.inf_SureToLeave'));

  const handleOpenInquiry = (sellerCompanyId: number) => {
    setCurrentSellerInquiryOpened(sellerCompanyId);
  };

  const updateInquirySentId = (inquiryProp: InquiryCreateForSessionModel) => {
    const targetInquiryToUpdateIndex = inquiries.findIndex(
      (inquiry) => inquiry.sellerCompanyId === inquiryProp.sellerCompanyId
    );

    const clonedInquiries = [...inquiries];

    clonedInquiries[targetInquiryToUpdateIndex].sentId = inquiryProp.sentId;

    setInquiries(clonedInquiries);

    sessionStorage.removeItem('inquiries_unsent');

    sessionStorage.setItem('inquiries_unsent', JSON.stringify(clonedInquiries));
  };

  return (
    <div className='document-unsent-container d-flex justify-content-center'>
      {/* <Prompt when={!isInquirying} message={t('common.inf_SureToLeave')} /> */}
      {inquiries.map(
        (inquiry, index: number) =>
          inquiry.project.id === buyerCurrentProject?.id && (
            <div
              className={`document-unsent ${
                inquiry.sellerCompanyId === currentSellerInquiryOpened
                  ? 'document-unsent-open'
                  : 'document-unsent-closed'
              }`}
            >
              {inquiry.sentId === null ? (
                <div
                  className={
                    inquiry.sellerCompanyId !== currentSellerInquiryOpened
                      ? 'd-none'
                      : ''
                  }
                >
                  <InquiryUnsent
                    setIsInquirying={setIsInquirying}
                    handleDiscardInquiry={handleDiscardInquiry}
                    inquiryProp={inquiry}
                    updateInquirySentId={updateInquirySentId}
                  />
                </div>
              ) : (
                inquiry.sellerCompanyId === currentSellerInquiryOpened && (
                  <Inquiry passedInquiryId={inquiry.sentId} />
                )
              )}
              {inquiry.sellerCompanyId !== currentSellerInquiryOpened && (
                <InquiryUnsentClosedCard
                  inquiries={inquiries}
                  inquiry={inquiry}
                  handleOpenInquiry={handleOpenInquiry}
                  index={index}
                  currentSellerInquiryOpened={currentSellerInquiryOpened}
                />
              )}
            </div>
          )
      )}
    </div>
  );
};

import { SellerApi } from 'api/SellerApi';
import { useFormik } from 'formik';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  SellerGlobalNotificationsModel,
  SellerGlobalNotificationsUpdateModel,
  SellerPeronalNotificationsModel,
  SellerPeronalNotificationsUpdateModel,
} from 'models/seller/notifications/SellerNotificationsModels';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useFormikHookForSellerGlobalNotifications = (
  globalNotifications: SellerGlobalNotificationsModel
) => {
  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleSubmit = async (
    updatedGlobalSettings: SellerGlobalNotificationsUpdateModel
  ) => {
    await SellerApi.updateSellerGlobalNotifications(
      currentUser.companyId,
      updatedGlobalSettings
    );
  };

  const validationSchema = yup.object({
    newOrderEmail: yup
      .string()
      .nullable()
      .email(t('common.err_EmailInvalid'))
      .max(50, t('common.err_ValueMax50')),

    newInquiryEmail: yup
      .string()
      .nullable()
      .email(t('common.err_EmailInvalid'))
      .max(50, t('common.err_ValueMax50')),
  });

  const formik = useFormik<SellerGlobalNotificationsModel>({
    initialValues: { ...globalNotifications },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      const updatedGlobalSettings: SellerGlobalNotificationsUpdateModel = {
        newOrderReceived: values.newOrderReceived,
        newOrderEmail: values.newOrderEmail,
        newInquiryReceived: values.newInquiryReceived,
        newInquiryEmail: values.newInquiryEmail,
      };

      await handleSubmit(updatedGlobalSettings);
    },
  });

  return formik;
};

export const useFormikHookForSellerPersonalNotifications = (
  peronalNotifications: SellerPeronalNotificationsModel
) => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleSubmit = async (
    updatedPersonalSettings: SellerPeronalNotificationsUpdateModel
  ) => {
    await SellerApi.updateSellerPersonalNotifications(
      currentUser.companyId,
      currentUser.id,
      updatedPersonalSettings
    );
  };

  const formik = useFormik<SellerPeronalNotificationsModel>({
    initialValues: { ...peronalNotifications },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const updatedPersonalSettings: SellerPeronalNotificationsUpdateModel = {
        inquiryReceived: values.inquiryReceived,
        offerDeclined: values.offerDeclined,
        offerAccepted: values.offerAccepted,
        orderReceived: values.orderReceived,
      };

      await handleSubmit(updatedPersonalSettings);
      formik.setSubmitting(false);
    },
  });

  return formik;
};

import { UserModelById } from 'models/shared/user/UserModelById';
import { AnyBuyerRole, AnySellerRole } from './useRBAC';

type ViewType = 'Seller' | 'Buyer';

export const useGetUserActiveRole = () => {
  /** Returns active user role based on view type */
  const getUserActiveRole = (user: UserModelById, currentView: ViewType) => {
    let currentRoleName = 'Unknown';
    if (user?.roleNames) {
      if (currentView === 'Seller') {
        currentRoleName =
          AnySellerRole.find((r) => user.roleNames.includes(r)) || '';
      } else if (currentView === 'Buyer') {
        currentRoleName =
          AnyBuyerRole.find((r) => user.roleNames.includes(r)) || '';
      }
    }
    return currentRoleName;
  };
  return getUserActiveRole;
};

import { CustomerCompanyModel } from 'models/seller/customers/CustomerCompanyModel';
import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  company: CustomerCompanyModel;
};

export const ExternalSellerCustomerProfileModal = ({ company }: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <h3 onClick={handleShow} className='btn-link-bold cursor-pointer'>
        {company.name}
      </h3>
      <Modal show={show} onHide={handleHide}>
        <Modal.Header>
          <Modal.Title>
            <h4>{t('companySettings.hd_CompanyInformation')}</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form className='p-3'>
            <Row>
              <Col className='col col-lg-10'>
                <Row>
                  <Col className='mx-3'>
                    <Form.Group as={Row} controlId='validationCompnayName'>
                      <Form.Label>
                        {t('companySettings.lbl_CompanyName')}
                      </Form.Label>
                      <Form.Control
                        required
                        type='text'
                        placeholder={t('companySettings.plh_CompanyName')}
                        value={company.name}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationRegistryNumber'>
                      <Form.Label>
                        {t('companySettings.lbl_RegisterNumber')}
                      </Form.Label>
                      <Form.Control
                        required
                        value={company.registryNumber}
                        type='text'
                        placeholder={t('companySettings.plh_RegistryNumber')}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationAddress'>
                      <Form.Label>
                        {t('addressSettings.lbl_AddressLine1')}
                      </Form.Label>
                      <Form.Control
                        required
                        value={company.addressLine1}
                        type='text'
                        placeholder={t('addressSettings.lbl_AddressLine1')}
                        disabled
                      />
                    </Form.Group>
                    <Row className='mb-3'>
                      <Form.Group
                        as={Col}
                        className='p-0 me-2'
                        controlId='validationZip'
                      >
                        <Form.Label>
                          {t('addressSettings.lbl_PostalCode')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          value={company.postalCode}
                          placeholder={t('addressSettings.plh_PostalCode')}
                          required
                          disabled
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className='p-0'
                        controlId='validationCity'
                      >
                        <Form.Label>{t('addressSettings.lbl_City')}</Form.Label>
                        <Form.Control
                          type='text'
                          value={company.city}
                          placeholder={t('addressSettings.plh_City')}
                          required
                          disabled
                        />
                      </Form.Group>
                    </Row>
                    <Form.Group as={Row} controlId='validationPhoneNumber'>
                      <Form.Label>{t('userSettings.lbl_Phone')}</Form.Label>
                      <Form.Control
                        required
                        type='text'
                        value={company.phone}
                        placeholder={t('userSettings.plh_Phone')}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationWebsite'>
                      <Form.Label>
                        {t('companySettings.lbl_Website')}
                      </Form.Label>
                      <Form.Control
                        required
                        type='text'
                        placeholder={t('companySettings.plh_Website')}
                        value={company.webSite}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className='mx-3'>
                    <Form.Group as={Row} controlId='validationShortCompanyName'>
                      <Form.Label>
                        {t('companySettings.lbl_ShortName')}
                      </Form.Label>
                      <Form.Control
                        required
                        type='text'
                        placeholder={t('companySettings.plh_ShortName')}
                        value={company.shortName}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationVATNumber'>
                      <Form.Label>
                        {t('companySettings.lbl_VatNumber')}
                      </Form.Label>
                      <Form.Control
                        required
                        type='text'
                        value={company.vatNumber}
                        placeholder={t('companySettings.plh_VatNumber')}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Row} controlId='validationAddress2'>
                      <Form.Label>
                        {t('addressSettings.lbl_AddressLine2')}
                      </Form.Label>
                      <Form.Control
                        value={company.addressLine2}
                        type='text'
                        placeholder={t('addressSettings.plh_AddressLine2')}
                        disabled
                      />
                    </Form.Group>
                    <Row className='mb-3'>
                      <Form.Group
                        as={Col}
                        className='p-0 me-2'
                        controlId='validationState'
                      >
                        <Form.Label>
                          {t('addressSettings.lbl_State')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder={t('addressSettings.plh_State')}
                          required
                          value={company.state}
                          disabled
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        className='p-0'
                        controlId='validationCountry'
                      >
                        <Form.Label>
                          {t('addressSettings.lbl_Country')}
                        </Form.Label>
                        <Form.Control
                          required
                          type='text'
                          placeholder={t('addressSettings.plh_Country')}
                          disabled
                          value={company.country?.shortName}
                        />
                      </Form.Group>
                    </Row>
                    <Form.Group as={Row} controlId='validationEmail'>
                      <Form.Label>{t('userSettings.lbl_Email')}</Form.Label>
                      <Form.Control
                        required
                        type='text'
                        placeholder={t('userSettings.plh_Email')}
                        disabled
                        value={company.email}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleHide} variant='secondary'>
            {t('common.btn_Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

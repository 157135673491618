import { SellerApi } from 'api/SellerApi';
import { SellerProductList } from 'components/seller/products/Product/SellerProductList';
import {
  ProductBreadcrumb,
  ProductBreadcrumbItem,
} from 'components/shared/products/ProductBreadcrumb';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductModel } from 'models/shared/product/ProductModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import './SellerProducts.css';

export const FavoriteProducts = () => {
  const [products, setproducts] = useState<ProductModel[]>([]);
  const [mounted, setMounted] = useState<boolean>(false);
  const [productBreadCrumbItems, setproductBreadCrumbItems] = useState<
    ProductBreadcrumbItem[]
  >([]);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');

  const searchString = '';
  const pageNumber = 1;
  const orderBy = ''; // No ordering in 1st version
  const pageSize = 500; // No paging in 1st version

  const selectedCustomerId = appState.sellerCurrentCustomerId;
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const getProducts = useCallback(
    async (didCancel: boolean, orderBy?: string) => {
      const productsResponse = await SellerApi.getFavoriteProducts(
        currentUser.companyId,
        selectedCustomerId,
        currentUser.id,
        searchString,
        pageNumber,
        orderBy || '',
        pageSize,
        userLocaleId
      );
      if (!didCancel) {
        setproducts(productsResponse.data);
      }
    },
    [currentUser.companyId, currentUser.id, selectedCustomerId, userLocaleId]
  );

  useEffect(() => {
    let didCancel = false;

    getProducts(didCancel);

    return () => {
      didCancel = true;
    };
  }, [
    currentUser.companyId,
    selectedCustomerId,
    userLocaleId,
    currentUser.id,
    getProducts,
  ]);

  useEffect(() => {
    let breadCrumbItems: ProductBreadcrumbItem[] = [];

    breadCrumbItems.push({
      name: t('common.lbl_Favourites'),
      classId: 0,
      classLevel: 0,
      linkType: 'Favorites',
      code: '',
    });

    setproductBreadCrumbItems(breadCrumbItems);
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);

  const BuyerBreadcrumbNode = (
    <ProductBreadcrumb
      items={productBreadCrumbItems}
      productClassesRoute='/seller/product-classes/'
      productSubClassesRoute='/seller/product-subclasses/'
    />
  );

  return (
    <>
      {mounted &&
        ReactDOM.createPortal(
          BuyerBreadcrumbNode,
          document &&
            document.getElementsByClassName('fixed-portal-receiver')[0]
        )}
      <SellerProductList
        items={products}
        productDetailRoute={'/seller/product-details'}
        getProducts={getProducts}
      />
    </>
  );
};

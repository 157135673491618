import { BuyerApi } from 'api/BuyerApi';
import {
  DealActive,
  DealDisabled,
  DealFinshed,
  DealLost,
} from 'assets/icons/StatusIcons';
import { BuyerDealsCard } from 'components/buyer/dealshome/BuyerDealsCard';
import { BuyerDealsCardDraft } from 'components/buyer/dealshome/BuyerDealsCardDraft';
import { EditDealNameModal } from 'components/buyer/dealshome/EditDealNameModal';
import { LightTooltip } from 'components/shared/LightTooltip';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { useSetCurrentProject } from 'hooks/useSetCurrentProject';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import {
  AllProjectsNewDataModel,
  FilterStateModel,
} from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { MouseEvent, useContext, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
  BriefcaseFill,
  CartPlus,
  ClipboardPlus,
  FolderFill,
  Trash,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const dealStatusMap: {
  [Draft: string]: JSX.Element;
  Lost: JSX.Element;
  Active: JSX.Element;
  Finished: JSX.Element;
} = {
  all: <DealDisabled size={15} className='icon-ez-gray mx-1' />,
  Draft: <BriefcaseFill size={15} className='icon-ez-gray mx-1' />,
  Lost: <DealLost size={15} className='icon-ez-black mx-1' />,
  Active: <DealActive size={15} className='icon-ez-green mx-1' />,
  Finished: <DealFinshed size={15} className='icon-ez-gray mx-1' />,
};

type PropTypes = {
  project: AllProjectsNewDataModel;
  index: number;
  currentUser: LoginResponseUserModel;
  filterState: FilterStateModel;
};

export const BuyerDealsRow = ({
  project,
  index,
  currentUser,
  filterState,
}: PropTypes) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const navigator = useHistory();
  const setCurrentProject = useSetCurrentProject();
  const setUserProfile = useSetUserProfile();

  const { appState } = useContext(AppContext);

  const handleMouseEnter = (index: number) => {
    setIsHovered(true);
  };
  const handleMouseLeave = (index: number) => {
    setIsHovered(false);
  };

  const handleUpdateDeal = async (name: string | null) => {
    name !== null &&
      (await BuyerApi.bulkUpdateDeal(currentUser.companyId, project.id, {
        dealStatus: name,
      }));

    await appState.getProjects();
  };

  const handleProjectInquiryNew = async () => {
    setIsLoading(true);
    const response = await BuyerApi.projectInquiry.createProjectInquiry(
      currentUser.companyId,
      project.id
    );

    await setCurrentProject(project.id);

    navigator.push(`/buyer/project/${project.id}/project-inquiry/${response}`);
  };

  const handleProjectOrderNew = async () => {
    setIsLoading(true);
    const response = await BuyerApi.projectOrder.createProjectOrder(
      currentUser.companyId,
      project.id
    );

    await setCurrentProject(project.id);

    navigator.push(`/buyer/project/${project.id}/project-order/${response}`);
  };

  const handleDelete = async (event: MouseEvent<SVGElement>) => {
    if (window.confirm(`${t('projectSettings.lbl_DeleteProject')}?`)) {
      await BuyerApi.deleteProject(currentUser.companyId, project.id);

      await setCurrentProject(null);
      await setUserProfile(null);

      window.location.reload();
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Card.Body className='card-body-dealsRow mb-5 project-view-card'>
        <Row className='px-4 justify-content-between align-items-baseline project-view-name'>
          <Col>
            <Row id='manage-project' className=' flex-nowrap'>
              <Col
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
                className='d-flex'
                style={{ marginLeft: '-20px' }}
                md={11}
              >
                <span className='deals-dealname-container'>
                  <FolderFill
                    style={{ marginLeft: '-10px' }}
                    size={16}
                    className='icon-ez me-1'
                  />
                  <h5 className='mx-1 deals-dealname wrap-ellipsis'>
                    {project.name}
                  </h5>
                  {project.inquiryProject === null && (
                    <>
                      <ClipboardPlus
                        data-tip
                        data-for={`deal-row-project-inquiry-new_${project.id}`}
                        onClick={handleProjectInquiryNew}
                        className='icon-ez cursor-pointer'
                        size={16}
                      />

                      <LightTooltip
                        place='bottom'
                        id={`deal-row-project-inquiry-new_${project.id}`}
                      >
                        <span className='buyer-nav-item-label'>
                          {t('projectSettings.lbl_NewProjectInquiry')}
                        </span>
                      </LightTooltip>
                    </>
                  )}
                  {project.orderProject === null && (
                    <>
                      <CartPlus
                        data-tip
                        data-for={`deal-row-project-order-new_${project.id}`}
                        onClick={handleProjectOrderNew}
                        className='icon-ez cursor-pointer'
                        size={16}
                      />

                      <LightTooltip
                        place='bottom'
                        id={`deal-row-project-order-new_${project.id}`}
                      >
                        <span className='buyer-nav-item-label'>
                          {t('projectSettings.lbl_NewProjectOrder')}
                        </span>
                      </LightTooltip>
                    </>
                  )}
                </span>
                {isHovered && (
                  <EditDealNameModal
                    projectId={project.id}
                    currentName={project.name}
                    currentUser={currentUser}
                  />
                )}

                {isHovered &&
                  project.dealGroups?.length < 1 &&
                  appState.userProfile?.currentProject?.inquiryProjectId ===
                    null &&
                  appState.userProfile?.currentProject?.orderProjectId ===
                    null && (
                    <Trash
                      className='icon-ez-red ms-2 cursor-pointer'
                      onClick={handleDelete}
                    />
                  )}
              </Col>
              <Col
                className='d-flex justify-content-end align-items-center'
                lg={2}
              ></Col>
            </Row>
          </Col>
          <Col />
          <Col />
          <Col />
        </Row>

        <Row>
          <hr className='deals-dealname-divider mb-1' />
        </Row>

        {(project.inquiryProject !== null || project.orderProject !== null) && (
          <BuyerDealsCardDraft
            projectId={project.id}
            index={index}
            inquiryProject={project.inquiryProject}
            orderProject={project.orderProject}
          />
        )}

        {project?.dealGroups?.map((deal, index) => (
          <>
            <BuyerDealsCard
              project={project}
              currentUser={currentUser}
              dealsDetail={deal}
              index={index}
            />
          </>
        ))}
      </Card.Body>
    </>
  );
};

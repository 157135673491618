import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { MouseEvent } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export const SuppliersPageGuide = () => {
  const { t } = useTranslation(['components', 'introGuides']);

  const handleStartTour = (event: MouseEvent) => {
    introJs()
      .setOptions({
        scrollToElement: false,
        nextLabel: t('common.btn_Next', { ns: 'components' }),
        prevLabel: t('common.btn_Back', { ns: 'components' }),
        steps: [
          {
            element: '#list-of-suppliers',
            title: t('suppliersListGuide.hd_ListOfSuppliers', {
              ns: 'introGuides',
            }),
            intro: t('suppliersListGuide.inf_ListOfSuppliers', {
              ns: 'introGuides',
            }),
          },
          {
            element: '#add-a-supplier',
            title: t('suppliersListGuide.hd_AddASupplier', {
              ns: 'introGuides',
            }),
            intro: t('suppliersListGuide.inf_AddASupplier', {
              ns: 'introGuides',
            }),
          },
          {
            element: '.price-list',
            title: t('suppliersListGuide.hd_PriceList', {
              ns: 'introGuides',
            }),
            intro: t('suppliersListGuide.inf_PriceList', {
              ns: 'introGuides',
            }),
          },
        ],
      })
      .start();
  };

  return (
    <div>
      <QuestionCircle
        onClick={handleStartTour}
        size={40}
        className='btn-floating-help me-3'
      />
    </div>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { CreateNewProjectModal } from 'components/buyer/project/CreateNewProjectModal/CreateNewProjectModal';
import { useSetCurrentProject } from 'hooks/useSetCurrentProject';
import { CurrentProjects } from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  Card,
  FormControl,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { FolderPlus, Search } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

type PropTypes = {
  setIsEditing: (value: boolean) => void;
  recentProjects: CurrentProjects[];
  setRecentProjects: (value: CurrentProjects[]) => void;
};

export const BuyerRecentCurrentProjects = ({
  setIsEditing,
  recentProjects,
  setRecentProjects,
}: PropTypes) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openNewProjectModal, setOpenNewProjectModal] = useState(false);

  const { t } = useTranslation('components');
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const navigate = useHistory();

  const setCurrentProject = useSetCurrentProject();

  const handleNewProjectModalShow = () => setOpenNewProjectModal(true);
  const handleNewProjectModalClosed = () => {
    setOpenNewProjectModal(false);
    setIsEditing(false);
  };

  const handleProjectSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setIsLoading(true);

    if (query !== '') {
      const response = await BuyerApi.projectsQuickSearch(
        currentUser.companyId,
        event.target.value
      );

      setRecentProjects(response);
    } else await getRecentProjects();

    setIsLoading(false);
  };

  const getRecentProjects = async () => {
    const response = await BuyerApi.getRecentCurrentProjects(
      currentUser.companyId,
      currentUser.id
    );

    setRecentProjects(response);
  };

  const handleSearchDebounced = AwesomeDebouncePromise(
    handleProjectSearch,
    400
  );

  /* Detect click outside the tags-search div */
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !(event.target as Element).classList?.contains('projects-input') &&
        !openNewProjectModal
      ) {
        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openNewProjectModal, ref, setIsEditing]);

  const handleSetCurrentProject = async (projectId: number | null) => {
    setIsEditing(true);
    await setCurrentProject(projectId);
    await getRecentProjects();
    setIsEditing(false);
  };

  return (
    <>
      <CreateNewProjectModal
        open={openNewProjectModal}
        redirectToProject
        handleClosed={handleNewProjectModalClosed}
        setIsEditing={setIsEditing}
      />
      <div ref={ref} className='recent-projects-container'>
        <Card style={{ width: '250px' }} className='recent-projects p-1'>
          <InputGroup
            className={`border rounded py-0 my-0 ${
              isFocused ? 'custom-focused' : ''
            }`}
          >
            <InputGroup.Text className='btn' id='search-addon'>
              <Search />
            </InputGroup.Text>
            <FormControl
              onFocus={() => setIsFocused(true)}
              className='projects-input my-0 py-0 px-3 border-0'
              onChange={handleSearchDebounced}
              autoFocus
              onBlur={() => setIsFocused(false)}
              // onBlur={handleBlur}
            />
          </InputGroup>
          {/* 
          <div
            onClick={handleNewProjectModalShow}
            className='text-start icon-ez-gray recent-projects-header-text mt-2 ps-2 d-flex align-items-center cursor-pointer'
          >
            <FolderPlus size={24} className='icon-ez px-1 me-1' />{' '}
            {t('projectSettings.lbl_NewProject')}...{' '}
          </div> */}

          <hr
            style={{ height: '1px' }}
            className='mx-1 mb-0  mt-3 icon-ez-gray'
          />

          {isLoading ? (
            <div className='d-flex justify-content-center p-3'>
              <Spinner animation='border' />
            </div>
          ) : (
            <Card.Body className='justify-content-start text-start'>
              <div
                // onClick={() => handleSetCurrentProject(null)}
                className='mb-2'
              >
                <div className='mb-2'>
                  <Button
                    onClick={handleNewProjectModalShow}
                    className='ms-4 text-center btn btn-eleczap d-flex flex-row align-items-center rounded'
                  >
                    <FolderPlus size={20} />
                    <span className='ms-2'>
                      {t('projectSettings.lbl_NewProject')}
                    </span>
                  </Button>
                </div>
              </div>

              {recentProjects.length > 0 &&
                recentProjects.map((project) => (
                  <div
                    onClick={() => handleSetCurrentProject(project.id)}
                    className='mb-2'
                  >
                    {project.isCurrent ? (
                      <strong className='cursor-pointer'>{project.name}</strong>
                    ) : (
                      <span className='cursor-pointer icon-ez-black'>
                        {project.name}
                      </span>
                    )}{' '}
                  </div>
                ))}
            </Card.Body>
          )}
        </Card>
      </div>
    </>
  );
};

import { SellerApi } from 'api/SellerApi';
import {
  InvoiceOverdue,
  InvoicePaid,
  InvoiceUnpaidSeller,
} from 'assets/icons/StatusIcons';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  InvoiceModel,
  InvoiceRowModel,
} from 'models/seller/documents/InvoiceModels';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Col, Form, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { truncateDecimals } from 'utils/truncateDecimals';

type UrlParamsType = {
  invoiceId: string;
};

export const Invoice = () => {
  const [invoice, setInvoice] = useState<InvoiceModel>({} as InvoiceModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { appState } = useContext(AppContext);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');
  const validateUnitExists = useValidateUnitExists();

  let { invoiceId } = useParams<UrlParamsType>();

  useEffect(() => {
    setIsLoading(true);
    const getInvoice = async (): Promise<void> => {
      const response = await SellerApi.getInvoice(
        currentUser.companyId,
        parseInt(invoiceId),
        userLocaleId
      );

      setInvoice(response);
      setIsLoading(false);
    };
    getInvoice();
  }, [currentUser.companyId, invoiceId, userLocaleId]);

  const columns: TableColumn<InvoiceRowModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      sortable: true,
      width: '60px',
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode,
      cell: (row) =>
        row.productId === null ? (
          <span>{row.productCode}</span>
        ) : (
          <Link
            to={`/seller/product-details/${row.productId}`}
            className='btn-link-bold'
          >
            {row.productCode}
          </Link>
        ),
      sortable: true,
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name,
      sortable: true,
      grow: 6,
    },
    {
      name: t('documents.lbl_Qty'),
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          <span>
            {row.quantity} <small>{validateUnitExists(row.unit)}</small>
          </span>
        </>
      ),
      sortable: true,
      right: true,
    },
    {
      name: t('documents.lbl_PriceUnit'),
      selector: (row) => row.priceWithDiscount,
      cell: (row) =>
        formatMonetaryValue(
          invoice.currencyCode,
          row.priceWithDiscount,
          appState,
          row.decimalPlaces
        ),
      sortable: true,
      right: true,
    },
    {
      name: t('projectSettings.lbl_TotalPrice'),
      selector: (row) => row.total,
      cell: (row) =>
        row.total
          ? formatMonetaryValue(
              invoice.currencyCode,
              truncateDecimals(row.total, row.decimalPlaces), //TOMAS problem
              appState
            )
          : '-',
      sortable: true,
      right: true,
    },
  ];

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className='container-fluid p-2'>
        <Card>
          <Card.Header>
            <Row>
              <Col lg={4} />
              <Col className='text-center' lg={4}>
                <h2>
                  {t('documents.hd_Invoice')} {invoice.code}
                </h2>
              </Col>
              <Col
                className='text-center d-flex justify-content-lg-end justify-content-sm-center'
                lg={4}
              >
                <h3>
                  {invoice.status === 'Overdue' && (
                    <>
                      {t('documents.lbl_Overdue')}{' '}
                      <InvoiceOverdue className='icon-ez-red' />
                    </>
                  )}
                  {invoice.status === 'Unpaid' && (
                    <>
                      {t('documents.lbl_Unpaid')}{' '}
                      <InvoiceUnpaidSeller className='icon-ez-yellow' />
                    </>
                  )}
                  {invoice.status === 'Paid' && (
                    <>
                      {t('documents.lbl_Paid')}{' '}
                      <InvoicePaid className='icon-ez' />
                    </>
                  )}
                </h3>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_Date')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(invoice.documentDate).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_UpdatedOn')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(invoice.lastUpdated).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentBy')}:
                  </Col>
                  <Col>
                    <strong>{invoice.sentByContactName}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentTo')}:
                  </Col>
                  <Col>
                    <strong>{invoice.sentToContactName}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'></Col>
                  <Col>
                    <span className='btn-link-bold'>
                      {invoice.sentToCompanyName}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row className='flex-nowrap'>
                  <Col className='text-end pe-0 text-nowrap'>
                    <Row className='mb-0'>
                      <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_LinkedOrder')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                    </Row>

                    {invoice.deliveryAddressLine2 !== null && <br />}

                    {invoice.deliveryAddressLine3 !== null && <br />}

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_PaymentTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_DeliveryTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_DueDate')}:</Col>
                    </Row>
                  </Col>

                  <Col
                    className='pe-3 d-flex flex-column'
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        <span className='btn-link-bold-no-link'>
                          {invoice.projectName}
                        </span>
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {invoice.orderId !== null ? (
                          <Link
                            to={`/seller/order/${invoice.orderId}`}
                            className='btn-link-bold'
                          >
                            {invoice.orderCode}
                          </Link>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='d-flex flex-column text-nowrap'>
                        {invoice.deliveryAddressLine1 !== null ? (
                          <strong>{invoice.deliveryAddressLine1}</strong>
                        ) : (
                          <br />
                        )}
                        <strong>{invoice.deliveryAddressLine2}</strong>
                        <strong>{invoice.deliveryAddressLine3}</strong>
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {invoice.paymentTerm !== null &&
                        invoice.paymentTerm !== '' ? (
                          <strong>{invoice.paymentTerm}</strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {invoice.deliveryTerm !== null &&
                        invoice.deliveryTerm !== '' ? (
                          <strong>{invoice.deliveryTerm}</strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        <strong>
                          {dayjs(invoice.dueDate).format('DD-MM-YYYY')}
                        </strong>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='my-5'>
              <DataTable
                noDataComponent={
                  <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
                }
                columns={columns}
                data={invoice.rows}
              />
            </Row>

            <Row className='justify-content-between'>
              <Col lg={6}>
                <Form.Group className='mb-3' controlId='comments'>
                  <Form.Label>{t('common.lbl_Comments')}</Form.Label>
                  <Form.Control
                    readOnly
                    as='textarea'
                    rows={4}
                    value={invoice.comment}
                  />
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Row className='mt-3'>
                  <Col>
                    <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                  </Col>
                  <Col>
                    <h3 className='text-end'>
                      {useFormatMonetaryValue(
                        invoice.currencyCode,
                        invoice.total
                      )}
                    </h3>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <h4 className='text-end'>{t('documents.lbl_Vat20')}:</h4>
                  </Col>
                  <Col>
                    <h4 className='text-end'>
                      {useFormatMonetaryValue(
                        invoice.currencyCode,
                        invoice.vatTotal
                      )}
                    </h4>
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col>
                    <h3 className='text-end'>
                      {t('documents.lbl_TotalPlusVat')}:
                    </h3>
                  </Col>
                  <Col>
                    <h3 className='text-end'>
                      {useFormatMonetaryValue(
                        invoice.currencyCode,
                        invoice.totalWithVat
                      )}
                    </h3>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

import { Image } from 'react-bootstrap';

type PropTypes = {
  imageUrl: string | null;
  initials: string | JSX.Element;
  size: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
};

export const AvatarWithFallback = ({
  imageUrl,
  initials,
  size,
  ...props
}: PropTypes): JSX.Element => {
  const xSmallAvatar =
    imageUrl !== null ? (
      <>
        <Image
          className='thick-rounded-border-primary'
          {...props}
          src={imageUrl}
          roundedCircle
          style={{
            width: '1.2rem',
            height: '1.2rem',
          }}
        />
      </>
    ) : (
      <span className='rounded-initials-x-small text-nowrap rounded-circle border'>
        {initials}
      </span>
    );

  const smallAvatar =
    imageUrl !== null ? (
      <>
        <Image
          className='thick-rounded-border-primary'
          {...props}
          style={{
            width: '1.8rem',
            height: '1.8rem',
          }}
          src={imageUrl}
          roundedCircle
        />
      </>
    ) : (
      <span className='rounded-initials text-nowrap rounded-circle border'>
        {initials}
      </span>
    );

  const mediumAvatar =
    imageUrl !== null ? (
      <Image
        className='profileImage userTable-profileImage thick-rounded-border-primary'
        src={imageUrl}
        roundedCircle
      />
    ) : (
      <div className='rounded-circle userTable-initials'>{initials}</div>
    );

  const largeAvatar =
    imageUrl !== null ? (
      <Image
        className='profileImage userTable-profileImage thick-rounded-border-primary'
        src={imageUrl}
        roundedCircle
        style={{
          width: '2.3rem',
          height: '2.3rem',
        }}
      />
    ) : (
      <div className='rounded-circle customerTalbe-initals'>{initials}</div>
    );

  const xLargeAvatar =
    imageUrl !== null ? (
      <Image
        style={{
          borderWidth: '4px',
        }}
        className='userImage thick-rounded-border-primary rounded-circle p-0'
        src={imageUrl}
        roundedCircle
      />
    ) : (
      <div className='rounded-circle'>
        <span
          style={{
            borderWidth: '4px',
          }}
          className='rounded-circle account-initials'
        >
          {initials}
        </span>
      </div>
    );

  const avatarToDisplay = () => {
    if (size === 'x-small') return xSmallAvatar;

    if (size === 'small') return smallAvatar;

    if (size === 'medium') return mediumAvatar;

    if (size === 'large') return largeAvatar;

    if (size === 'x-large') return xLargeAvatar;

    return <div />;
  };

  return avatarToDisplay();
};

import { BuyerApi } from 'api/BuyerApi';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { BuyerDealsFilterTag } from 'components/buyer/dealshome/BuyerDealsFilterTag';
import { DealTagModel } from 'models/buyer/deals/DealsModels';
import { FilterStateModel } from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Badge, Card, FormControl, InputGroup } from 'react-bootstrap';
import { CaretDownFill, Search, TagsFill } from 'react-bootstrap-icons';

type PropTypes = {
  currentUser: LoginResponseUserModel;
  t: any;
  handleTagFilter: (tagIds: number[]) => void;
  filterState: FilterStateModel;
};

export const BuyerDealsTagSearch = ({
  currentUser,
  t,
  filterState,
  handleTagFilter,
}: PropTypes) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [lastTags, setLastTags] = useState<DealTagModel[]>([]);
  const [tagIds, setTagIds] = useState<number[]>([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleClick = async (): Promise<void> => {
    setIsEditing(!isEditing);

    const response = await BuyerApi.getLastTags(currentUser.companyId);

    setLastTags(response);
  };

  useEffect(() => {
    const getFirstTags = async (): Promise<void> => {
      const response = await BuyerApi.getLastTags(currentUser.companyId);

      setLastTags(response);
    };

    getFirstTags();
  }, [currentUser.companyId]);

  const handleSearchTag = async (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;

    const response = await BuyerApi.getLastTags(currentUser.companyId, query);

    setLastTags(response);
  };

  const handleSearchDebounced = AwesomeDebouncePromise(handleSearchTag, 400);

  const handleUsedClick = (tag: DealTagModel) => {
    let clone = [...tagIds];

    const index = clone.indexOf(tag.id);

    if (clone.includes(tag.id) && index > -1) {
      clone.splice(index, 1);
    } else clone.push(tag.id);

    setTagIds(clone);
    handleTagFilter(clone);
    setIsEditing(false);
  };

  /* Detect click outside the tags-search div */
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !(event.target as Element).classList?.contains('btn-tags-filter')
      ) {
        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <div>
        <div
          className='btn btn-eleczap ms-2 btn-tags-filter'
          onClick={handleClick}
        >
          <TagsFill
            size={15}
            style={{ cursor: 'pointer' }}
            className='icon-ez-white dropdown-toggle'
          />{' '}
          {t('deals.lbl_Tags')} <CaretDownFill />
        </div>
        {isEditing ? (
          <div ref={ref} className='recent-tags-container'>
            <Card className='recent-tags p-1'>
              <InputGroup
                className={`border rounded ${isFocused && 'custom-focused'}`}
              >
                <InputGroup.Text className='btn' id='search-addon'>
                  <Search />
                </InputGroup.Text>
                <FormControl
                  className='tags-input mb-1 mt-1 px-3 border-0'
                  onChange={handleSearchDebounced}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  autoFocus
                  // onBlur={handleBlur}
                />
              </InputGroup>

              <div className='text-center icon-ez-gray recent-tags-header-text mt-1'>
                {t('common.hd_RecentlyUsed')}
              </div>

              <hr className='mx-1 mt-3 mb-1' />

              <Card.Body className='justify-content-start text-start'>
                {lastTags !== null &&
                  lastTags.map((tag) => (
                    <>
                      <Badge
                        onClick={() => handleUsedClick(tag)}
                        pill
                        className={
                          filterState.tagIds.includes(tag.id)
                            ? 'deals-pill-inverse m-1 cursor-pointer'
                            : 'deals-pill m-1 cursor-pointer'
                        }
                      >
                        {tag.name}
                      </Badge>
                      <br />
                    </>
                  ))}
              </Card.Body>
            </Card>
          </div>
        ) : (
          <div />
        )}
      </div>
      {lastTags !== null &&
        lastTags.map(
          (tag) =>
            filterState.tagIds.includes(tag.id) && (
              <BuyerDealsFilterTag
                handleUsedClick={handleUsedClick}
                tag={tag}
              />
            )
        )}
    </>
  );
};

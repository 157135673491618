import { TermsAndConditionsModal } from 'components/shared/TermsAndConditionsModal';
import { AppContext } from 'contexts/user/AppContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './sellerFooter.css';

export const SellerFooter = () => {
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [languageTermsToShow, setLanguageTermsToShow] = useState<string>(
    '/TermsANdConditions_EN.pdf'
  );

  const { t } = useTranslation('components');

  const { appState } = useContext(AppContext);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  useEffect(() => {
    if (userLocaleId === 2)
      setLanguageTermsToShow('/TermsANdConditions_ET.pdf');
    else setLanguageTermsToShow('/TermsANdConditions_EN.pdf');
  }, [userLocaleId]);

  return (
    <div
      className='footer eleczap-footer fixed-bottom d-print-none'
      style={{ marginLeft: '75px' }}
    >
      <div className='d-flex justify-content-between mx-1'>
        <div className='d-flex align-items-center'>
          <span
            onClick={() => setShowTerms(true)}
            className='cursor-pointer mx-1'
          >
            {t('userOnboarding.hd_TermsAndConditions')}
          </span>
          <a
            className='text-center mx-5'
            style={{ textDecoration: 'none', color: '#6c757d' }}
            href='mailto:support@eleczap.com'
          >
            support@eleczap.com
          </a>{' '}
        </div>
        <span className='text-center'>© 2023 - ElecZap</span>
      </div>
      <TermsAndConditionsModal
        showTerms={showTerms}
        setShowTerms={setShowTerms}
        languageTermsToShow={languageTermsToShow}
      />
    </div>
  );
};

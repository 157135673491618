import NoProductImage from 'media/EZ_new_notext_border_grey.png';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';

type ImageWithZoomProps = {
  src: string | null;
  width: number;
  height: number;
  zoomedMaxWidth: number;
  zoomedMaxHeight: number;
  alt: string;
};

export const ImageWithZoom = (props: ImageWithZoomProps) => {
  const [imgSrc, setImgSrc] = useState<string | null>(props.src);
  const onError = () => setImgSrc(NoProductImage);

  useEffect(() => {
    setImgSrc(props.src);
  }, [props.src]);

  const renderZoomedImage = (overlayProps: OverlayInjectedProps) => (
    <Popover {...overlayProps}>
      <Popover.Body>
        <img
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
          }}
          src={imgSrc ?? NoProductImage}
          alt={props.alt}
          onError={onError}
        ></img>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      placement='right'
      delay={{ show: 50, hide: 100 }}
      overlay={renderZoomedImage}
    >
      <img
        src={imgSrc ?? NoProductImage}
        alt={props.alt}
        onError={onError}
        style={{
          display: 'inline',
          maxWidth: props.width,
          maxHeight: props.height,
        }}
      />
    </OverlayTrigger>
  );
};

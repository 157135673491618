import 'bootstrap/dist/css/bootstrap.min.css';
import { Trash } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { SellerApi } from 'api/SellerApi';
import { getAllStocksSharedWithMe } from 'components/seller/settings/stocks/StocksSharedWithMe/utilsStocksSharedWithMe';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksSharedWithMeModel } from 'models/shared/stocksSharedWithMe/StocksSharedWithMeModel';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type PropTypes = {
  stock: StocksSharedWithMeModel;
  setStocks: (values: StocksSharedWithMeModel[]) => void;
  currentUser: LoginResponseUserModel;
  isDisabled: boolean;
};

export const RemoveSharedStockWithMe = ({
  stock,
  setStocks,
  currentUser,
  isDisabled,
}: PropTypes) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { t } = useTranslation('components');

  const handleDelete = async () => {
    await SellerApi.deleteStockSharedWithMe(currentUser.companyId, stock.id);

    await getAllStocksSharedWithMe(currentUser, setStocks);
  };

  return (
    <>
      <Button
        variant='btn btn-eleczap-danger-noBorder'
        disabled={isDisabled}
        onClick={handleShow}
      >
        <Trash />
      </Button>

      <Modal
        size='sm'
        show={show}
        onHide={handleClose}
        id='buyerUserDeactivate'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('stockSettings.hd_RemoveStockSharedWithYou', {
              stockName: stock.name,
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans
            i18nKey={t('stockSettings.inf_StocksSharedWithYou_RemoveStock', {
              stockName: stock.name,
            })}
          ></Trans>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Cancel')}
          </Button>
          <Button variant='danger' onClick={handleDelete}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { AppContext } from 'contexts/user/AppContext';
import {
  BuyerGroupedProductListPagedResult,
  GroupedProductModel,
} from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { ProductQuickSearchGroupedPagedModel } from 'models/buyer/product/ProductQuickSearchGroupedModel';
import { ProductModel } from 'models/shared/product/ProductModel';
import { useContext, useEffect } from 'react';

export const useMergeProductsAndPricesSearchSimple = (
  productsQuery: UseInfiniteQueryResult<
    ProductQuickSearchGroupedPagedModel,
    unknown
  >,
  pricesQuery: UseInfiniteQueryResult<
    BuyerGroupedProductListPagedResult,
    unknown
  >,
  setPages: (value: BuyerGroupedProductListPagedResult[]) => void
) => {
  const { appState, appContextDispatch } = useContext(AppContext);

  useEffect(() => {
    const clonedPages: BuyerGroupedProductListPagedResult[] = [];

    productsQuery.data?.pages.forEach((page) => {
      const clonedPageData: GroupedProductModel[] = [];

      page.data.forEach((product) => {
        const newProducts: ProductModel[] = [];
        product.products.forEach(
          (item) =>
            (appState.buyerCurrentSuppliers?.findIndex(
              (supplier) =>
                supplier.value === item.sellerId && supplier.isSelected
            ) !== -1 ||
              appState.buyerCurrentSuppliers?.findIndex((supplier) =>
                supplier.subSellers?.some(
                  (subSeller) =>
                    subSeller.value === item.sellerId && subSeller.isSelected
                )
              ) !== -1) &&
            newProducts.push({
              id: item.id,
              code: item.code,
              name: item.name,
              unit: '',
              orderStep: 0,
              manufacturerName: item.manufacturerName ?? '',
              image: '',
              isFavorite: false,
              productClasses: [],
              moqPrices: item.moqPrices ?? [],
              stockLevels: [],
              packageSize: 0,
              sellerCompanyName: item.sellerName,
              sellerCompanyId: item.sellerId,
              sellerCompanyShortName: item.sellerShortName,
            })
        );

        const productGeneralInfo: GroupedProductModel = {
          productCode: product.productCode,
          products: newProducts,
        };

        clonedPageData.push(productGeneralInfo);
      });

      clonedPages.push({
        currentPage: 1,
        totalItems: 20,
        totalPages: 1,
        data: clonedPageData,
        parents: [],
      });
    });

    pricesQuery.data?.pages.forEach((page) => {
      const pageData = page.data;

      let matchedProduct: GroupedProductModel;

      pageData?.forEach((fetchedProduct) => {
        clonedPages.forEach((page) => {
          const result = page.data.find(
            (product) => product.productCode === fetchedProduct.productCode
          );

          if (result) matchedProduct = result;
        });

        if (matchedProduct) {
          matchedProduct.products = fetchedProduct.products;
          matchedProduct.quantity = fetchedProduct.quantity;
        }
      });
    });

    setPages(clonedPages);
  }, [
    appState.buyerCurrentSuppliers,
    pricesQuery.data?.pages,
    productsQuery.data?.pages,
    setPages,
  ]);
};

export const useMergeProductsAndPricesSearchMain = (
  productsQuery: UseInfiniteQueryResult<
    BuyerGroupedProductListPagedResult,
    unknown
  >,
  pricesQuery: UseInfiniteQueryResult<
    BuyerGroupedProductListPagedResult,
    unknown
  >,
  setPages: (value: BuyerGroupedProductListPagedResult[]) => void
) => {
  const { appState, appContextDispatch } = useContext(AppContext);

  useEffect(() => {
    const clonedPages: BuyerGroupedProductListPagedResult[] = [];

    productsQuery.data?.pages.forEach((page) => {
      const clonedPageData: GroupedProductModel[] = [];

      page.data.forEach((product) => {
        const newProducts: ProductModel[] = [];
        product.products.forEach(
          (item) =>
            (appState.buyerCurrentSuppliers?.findIndex(
              (supplier) =>
                supplier.value === item.sellerCompanyId && supplier.isSelected
            ) !== -1 ||
              appState.buyerCurrentSuppliers?.findIndex((supplier) =>
                supplier.subSellers?.some(
                  (subSeller) =>
                    subSeller.value === item.sellerCompanyId &&
                    subSeller.isSelected
                )
              ) !== -1) &&
            newProducts.push({
              id: item.id,
              code: item.code,
              name: item.name,
              unit: '',
              orderStep: 0,
              manufacturerName: item.manufacturerName ?? '',
              image: '',
              isFavorite: false,
              productClasses: [],
              moqPrices: item.moqPrices,
              stockLevels: [],
              packageSize: 0,
              sellerCompanyName: item.sellerCompanyName,
              sellerCompanyId: item.sellerCompanyId,
              sellerCompanyShortName: item.sellerCompanyShortName,
            })
        );

        const productGeneralInfo: GroupedProductModel = {
          productCode: product.productCode,
          products: newProducts,
        };

        clonedPageData.push(productGeneralInfo);
      });

      clonedPages.push({
        currentPage: 1,
        totalItems: 20,
        totalPages: 1,
        data: clonedPageData,
        parents: [],
      });
    });

    pricesQuery.data?.pages.forEach((page) => {
      const pageData = page.data;

      let matchedProduct: GroupedProductModel;

      pageData?.forEach((fetchedProduct) => {
        clonedPages.forEach((page) => {
          const result = page.data.find(
            (product) => product.productCode === fetchedProduct.productCode
          );

          if (result) matchedProduct = result;
        });

        if (matchedProduct) {
          matchedProduct.products = fetchedProduct.products;
          matchedProduct.quantity = fetchedProduct.quantity;
        }
      });
    });

    setPages(clonedPages);
  }, [
    appState.buyerCurrentSuppliers,
    pricesQuery.data?.pages,
    productsQuery.data?.pages,
    setPages,
  ]);
};

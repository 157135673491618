import { useFormikHookForOnboardingPassword } from 'components/shared/onboarding/CustomerOnboarding/CustomerOnboardingPassword/hooksCustomerOnboardingPassword';
import { MouseEventHandler } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { PlayFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import '../customerOnboardingIntro.css';

type PropTypes = {
  invitationToken: string;
  prevStep: MouseEventHandler;
};

export const CustomerOnboardingPassword = ({
  invitationToken,
  prevStep,
}: PropTypes): JSX.Element => {
  const { t } = useTranslation('components');

  const formik = useFormikHookForOnboardingPassword(invitationToken, t);

  return (
    <Card className='onboarding-intro-card'>
      <Card.Body>
        <h4 className='mb-3'>{t('userOnboarding.hd_SetYourPassword')}</h4>
        <Form>
          <Form.Group className='mb-3' controlId='formSetPassword'>
            <Form.Label>{t('changePassword.lbl_NewPassword')}</Form.Label>
            <Form.Control
              value={formik.values.newPassword || ''}
              type='password'
              name='newPassword'
              placeholder={t('changePassword.plh_EnterNewPassword')}
              onChange={formik.handleChange}
              isInvalid={
                formik.touched.newPassword && !!formik.errors.newPassword
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.newPassword}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formConfirmPassword'>
            <Form.Label>{t('changePassword.lbl_ConfirmPassword')}</Form.Label>
            <Form.Control
              value={formik.values.confirmNewPassword || ''}
              type='password'
              name='confirmNewPassword'
              onChange={formik.handleChange}
              placeholder={t('changePassword.plh_ConfirmNewPassword')}
              isInvalid={
                formik.touched.confirmNewPassword &&
                !!formik.errors.confirmNewPassword
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.confirmNewPassword}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        <p className='text-muted'>
          {t('changePassword.lbl_NewPasswordExplanation')}
        </p>
        <Button className='btn btn-eleczap float-start' onClick={prevStep}>
          <PlayFill className='iconPlayBack' />
          {t('common.btn_Back')}
        </Button>
        <Button
          className='btn btn-eleczap float-end mt-3'
          onClick={formik.handleSubmit}
        >
          {t('common.btn_Save')}
          <PlayFill />
        </Button>
      </Card.Body>
    </Card>
  );
};

import { SellerApi } from 'api/SellerApi';
import { EditableImageUploadModal } from 'components/shared/controls/EditableImageWithFallback/EditableImageUploadModal';
import { ProductBreadcrumb } from 'components/shared/products/ProductBreadcrumb';
import 'components/shared/products/ProductClasses/ProductClasses.css';
import { ProductClassesList } from 'components/shared/products/ProductClasses/ProductClassesList';
import { ProductClassesQuickMenu } from 'components/shared/products/ProductClasses/ProductClassesQuickMenu';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { SellerProductClassWithSubclassesModel } from 'models/seller/product/SellerProductClassWithSubclassesModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

export const ProductClasses = () => {
  const [productClasses, setproductClasses] = useState<
    SellerProductClassWithSubclassesModel[]
  >([]);
  const [quickMenuItems, setquickMenuItems] = useState<
    SellerProductClassWithSubclassesModel[]
  >([]);
  const [mounted, setMounted] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const selectedCustomerId = appState.sellerCurrentCustomerId;
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const [imageProductClassId, setImageProductClassId] = useState<number>(-1);
  const [showImageUploadModal, setShowImageUploadModal] =
    useState<boolean>(false);
  const [refreshClasses, setRefreshClasses] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const handleProductClassImageUpload = (productClassId: number) => {
    setImageProductClassId(productClassId);
    setShowImageUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setShowImageUploadModal(false);
  };

  const handleImageUpload = (file: File) => {
    const data = new FormData();
    data.append('file', file);
    return SellerApi.uploadProductClassImage(
      currentUser.companyId,
      imageProductClassId,
      data
    ).then((x) => {
      setRefreshClasses(!refreshClasses);
    });
  };

  /*  const productTreeOptions: ProductTreeOption[] = [
    { name: 'ETIM', value: 0 },
    { name: 'Seller', value: 1 },
  ]; */

  useEffect(() => {
    let didCancel = false;

    const getProductClasses = async () => {
      const level = 1;
      const productClassInfo = await SellerApi.getAllProductClasses(
        currentUser.companyId,
        selectedCustomerId,
        level,
        null,
        userLocaleId
      );
      if (!didCancel) {
        setproductClasses(productClassInfo.productClasses);
        if (level === 1) {
          const firstLevelClasses = productClassInfo.productClasses.filter(
            (pc) => pc.level === 1
          );
          setquickMenuItems(firstLevelClasses);
        }
      }
    };

    getProductClasses();

    return () => {
      didCancel = true;
    };
  }, [currentUser.companyId, selectedCustomerId, userLocaleId, refreshClasses]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const BuyerBreadcrumbNode = (
    <ProductBreadcrumb
      isStatic
      productClassesRoute='/seller/product-classes/'
      productSubClassesRoute='/seller/product-subclasses/'
    />
  );

  return (
    <>
      <EditableImageUploadModal
        show={showImageUploadModal}
        handleCloseUploadModal={handleCloseUploadModal}
        uploadProductImageRequirementsText={t(
          'editableImageWithFallback.lbl_UploadProductImageRequirements',
          { maxFileSizeKb: 5000 }
        )}
        maxFileSizekB={5000}
        accept={'.png, .jpg, .jpeg, .gif, .bmp, .tif, .tiff'}
        handleImageUpload={handleImageUpload}
      ></EditableImageUploadModal>
      <div className='container-fluid px-2 pb-2 mt-1'>
        <Card className='card-wrapper'>
          <Card.Header className='p-2 mt-2'>
            {/*  <ProductTreeSelect
              options={productTreeOptions}
              selectedOption={0}
            /> */}
            {mounted &&
              ReactDOM.createPortal(
                BuyerBreadcrumbNode,
                document &&
                  document.getElementsByClassName('fixed-portal-receiver')[0]
              )}
            <ProductClassesQuickMenu
              handleProductClassImageUpload={handleProductClassImageUpload}
              items={quickMenuItems}
              productSubClassesRoute='/seller/product-classes'
              favoriteProductsRoute='/seller/favorite-products'
              viewType='Seller'
            />
          </Card.Header>
          <ProductClassesList
            handleProductClassImageUpload={handleProductClassImageUpload}
            imageReadOnly={false}
            items={productClasses}
            productListRoute='/seller/product-classes'
            productSubClassesRoute='/seller/product-subclasses'
          />
        </Card>
      </div>
    </>
  );
};

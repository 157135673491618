import { useInfiniteQuery } from '@tanstack/react-query';
import { BuyerApi } from 'api/BuyerApi';
import { BuyerProductList } from 'components/buyer/products/Product/BuyerProductList';
import {
  ProductBreadcrumb,
  ProductBreadcrumbItem,
} from 'components/shared/products/ProductBreadcrumb';
import { AppContext } from 'contexts/user/AppContext';
import { GroupedProductModel } from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import './BuyerProducts.css';

export const FavoriteProducts = () => {
  const [products, setproducts] = useState<GroupedProductModel[]>([]);
  const [productBreadCrumbItems, setproductBreadCrumbItems] = useState<
    ProductBreadcrumbItem[]
  >([]);

  const [mounted, setMounted] = useState<boolean>(false);

  const [orderBy, setOrderBy] = useState<string>('');
  const [selectedManufacturers, setSelectedManufacturers] = useState<string[]>(
    []
  );

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const searchString = '';
  const pageNumber = 1;
  const pageSize = 500; // No paging in 1st version

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const { t } = useTranslation('components');

  const getProducts = useCallback(async () => {
    const productsResponse = await BuyerApi.getFavoriteProductsGrouped(
      currentUser.companyId,
      currentUser.id,
      searchString,
      pageNumber,
      orderBy,
      pageSize,
      userLocaleId
    );

    return productsResponse;
  }, [currentUser.companyId, currentUser.id, orderBy, userLocaleId]);

  const { data, isLoading, fetchNextPage } = useInfiniteQuery(
    [`favorites_${searchString}`, currentUser.companyId, userLocaleId],
    getProducts,
    {
      enabled: !!currentUser.companyId,
      staleTime: 100 * 60 * 30,
      getNextPageParam: (lastPage, pages) => lastPage.data,
    }
  );

  useEffect(() => {
    let breadCrumbItems: ProductBreadcrumbItem[] = [];

    breadCrumbItems.push({
      name: t('common.lbl_Favourites'),
      classId: 0,
      classLevel: 0,
      linkType: 'Favorites',
      code: '',
    });

    setproductBreadCrumbItems(breadCrumbItems);
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);

  const BuyerBreadcrumbNode = (
    <ProductBreadcrumb
      items={productBreadCrumbItems}
      productClassesRoute='/buyer/product-classes/'
      productSubClassesRoute='/buyer/product-subclasses/'
    />
  );

  return (
    <>
      {mounted &&
        ReactDOM.createPortal(
          BuyerBreadcrumbNode,
          document &&
            document.getElementsByClassName('fixed-portal-receiver')[0]
        )}
      {data && (
        <BuyerProductList
          items={data.pages}
          productDetailRoute={'/buyer/product-details'}
          setSelectedManufacturers={setSelectedManufacturers}
          selectedManufacturers={selectedManufacturers}
        />
      )}
    </>
  );
};

import { ApplicationSettingsApi } from 'api/ApplicationSettingsApi';
import { CurrencyModel } from 'models/shared/currency/CurrencyModel';
import { useEffect } from 'react';

export const useGetAvailableCurrencies = (
  setCurrencies: (value: CurrencyModel[]) => void
) => {
  useEffect(() => {
    const getAvailableCountries = async () => {
      const availableCurrencies = await ApplicationSettingsApi.getCurrencies();
      setCurrencies(availableCurrencies);
    };
    getAvailableCountries();
  }, [setCurrencies]);
};

import { BuyerApi } from 'api/BuyerApi';
import { LightTooltip } from 'components/shared/LightTooltip';
import { AppContext } from 'contexts/user/AppContext';
import { useSetCurrentProject } from 'hooks/useSetCurrentProject';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProjectOrder } from 'pages/buyer/ProjectOrder/ProjectOrder';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Badge, Nav, Offcanvas } from 'react-bootstrap';
import { ArrowsAngleExpand, CartFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

type PropTypes = {
  windowIsNarrow?: boolean;
};

export const ContinueProjectOrderButton = ({ windowIsNarrow }: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);

  const [numberOfRows, setNumberOfRows] = useState<number | null>(null);

  const { t } = useTranslation('components');

  const navigator = useHistory();

  const { appState, appContextDispatch } = useContext(AppContext);

  const setCurrentProject = useSetCurrentProject();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleContinueOrder = () => {
    window.screen.availWidth < 800
      ? navigator.push(
          `/buyer/project/${appState.userProfile?.currentProject?.id}/project-order/${appState.userProfile?.currentProject?.orderProjectId}`
        )
      : handleShow();
  };

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  useEffect(() => {
    const getNumberOfRows = async () => {
      if (appState.userProfile?.currentProject.orderProjectId) {
        const response = await BuyerApi.projectOrder.getProjectOrderRows(
          currentUser.companyId,
          appState.userProfile?.currentProject.orderProjectId,
          appState.userProfile?.currentProject.id
        );

        setNumberOfRows(response.length);

        appContextDispatch({
          type: 'BUYER_SET_CURRENT_ORDER_PROJECT_ROWS',
          currentOrderProjectRows: response.length,
        });
      }
    };

    getNumberOfRows();
  }, [
    appContextDispatch,
    appState.userProfile?.currentProject.id,
    appState.userProfile?.currentProject.orderProjectId,
    currentUser.companyId,
  ]);

  return !windowIsNarrow ? (
    <>
      <Nav.Item
        data-tip
        data-for={`/buyer/project/${appState.userProfile?.currentProject?.id}/project-order/${appState.userProfile?.currentProject?.orderProjectId}`}
        style={{ cursor: 'pointer' }}
        onClick={handleContinueOrder}
        className='ms-4 text-center position-relative float-end'
      >
        <Badge
          className='position-absolute'
          style={{ right: '-12px', top: '-5px' }}
          bg='success'
        >
          {appState.currentOrderProjectRows}
        </Badge>

        <span className='buyer-nav-item d-flex flex-column align-items-center'>
          <CartFill className='buyer-nav-item-icon-white icon-ez' size={34} />
        </span>
      </Nav.Item>

      <LightTooltip
        place='bottom'
        id={`/buyer/project/${appState.userProfile?.currentProject?.id}/project-order/${appState.userProfile?.currentProject?.orderProjectId}`}
      >
        <span className='buyer-nav-item-label'>
          {t('projectSettings.lbl_ContinueProjectOrder')}
        </span>
      </LightTooltip>

      <Offcanvas
        // scroll
        // backdrop={false}
        style={{ maxWidth: '1270px', minWidth: '1190px' }}
        placement={'end'}
        show={show}
        onHide={handleHide}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link
              onClick={handleHide}
              className='btn-link-bold'
              to={`/buyer/project/${appState.userProfile?.currentProject?.id}/project-order/${appState.userProfile?.currentProject?.orderProjectId}`}
            >
              {t('projectSettings.lbl_ContinueProjectOrder')}{' '}
              <ArrowsAngleExpand className='icon-ez' />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <div style={{ background: '#f7f9fc', overflowY: 'scroll' }}>
          <ProjectOrder
            passedProjectOrderId={
              appState.userProfile?.currentProject.orderProjectId ?? null
            }
            passedProjectId={appState.userProfile?.currentProject.id}
            handleHide={handleHide}
          />
        </div>
      </Offcanvas>
    </>
  ) : (
    <>
      <Nav.Item
        style={{ cursor: 'pointer' }}
        onClick={handleContinueOrder}
        className='ms-4 text-center'
      >
        <span className='buyer-nav-item buyer-nav-item-icon-container'>
          {t('projectSettings.lbl_ContinueProjectOrder')}
        </span>
      </Nav.Item>
    </>
  );
};

import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Form } from 'react-bootstrap';

type PropTypes = {
  image: string | null;
  initials: string;
  firstName: string;
  lastName: string;
  userId: number;
  id: number;
  isContact: boolean;
  fromPage: string;
  handleChange: (
    check: boolean,
    { userId, id }: { userId: number; id: number },

    setIsChecked: (value: boolean) => void
  ) => void;
};

export const AssigContactRow = ({
  image,
  initials,
  handleChange,
  userId,
  id,
  firstName,
  lastName,
  isContact,
  fromPage,
}: PropTypes) => {
  const [isChecked, setIsChecked] = useState<boolean>(isContact);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  useEffect(() => {
    fromPage === 'customerInvitation' &&
      userId === currentUser.id &&
      handleChange(true, { userId, id }, setIsChecked);
  }, []);

  return (
    <Form.Check
      className='d-flex align-items-center mb-3'
      checked={isChecked}
      style={{ height: '28px' }}
      type='checkbox'
      onChange={(event) =>
        handleChange(event.target.checked, { userId, id }, setIsChecked)
      }
      label={
        <span className='ms-3 d-flex align-items-center'>
          <AvatarWithFallback
            imageUrl={image}
            initials={initials}
            size={'small'}
          />
          <span className='ms-3'>{`${firstName} ${lastName}`}</span>
        </span>
      }
    />
  );
};

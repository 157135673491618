import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { useFormik } from 'formik';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import {
  ProjectInquiryModel,
  ProjectInquiryUpdateModel,
} from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { getProjectInquiry } from 'pages/buyer/ProjectInquiry/utilsProjectInquiry';
import { useContext, useEffect } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

export const useFormikHookForProject = (
  projectDetails: ProjectInquiryModel,
  setProjectDetails: (value: ProjectInquiryModel) => void,
  projectId: number | null
): any => {
  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');
  const setUserProfile = useSetUserProfile();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleSubmit = async (
    values: ProjectInquiryUpdateModel
  ): Promise<void> => {
    toast.dismiss();

    projectId &&
      (await BuyerApi.projectInquiry.updateProjectInquiry(
        currentUser.companyId,
        projectId,
        projectDetails.id,

        values
      ));
    if (projectId) {
      const response = await BuyerApi.projectInquiry.getProjectInquiry(
        currentUser.companyId,
        projectId,
        projectDetails.id
      );

      setProjectDetails(response);
    }
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),
  });

  const formik = useFormik<ProjectInquiryModel>({
    initialValues: { ...projectDetails },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedProject: ProjectInquiryUpdateModel = {
        requestedDeliveryDate: values.requestedDeliveryDate,
        offerDeadlineDate: values.offerDeadlineDate,
        deliveryAddressId: values.deliveryAddressId,
        customerInquiryCode: values.customerInquiryCode,
        comment: values.comment,
      };
      await handleSubmit(updatedProject);

      formik.setSubmitting(false);
    },
  });

  return formik;
};

export const useGetProjectDetails = (
  projectInquiryId: number | null,
  projectId: number | null,
  companyId: number,
  setProjectDetails: (values: ProjectInquiryModel) => void,
  setIsLoading: (value: boolean) => void
) => {
  useEffect(() => {
    const getProject = async (): Promise<void> => {
      projectInquiryId &&
        projectId &&
        (await getProjectInquiry(
          projectId,
          projectInquiryId,
          companyId,
          setProjectDetails
        ));

      setIsLoading(false);
    };

    getProject();
  }, [companyId, projectInquiryId, projectId, setIsLoading, setProjectDetails]);
};

import { CheckCircleFill } from 'react-bootstrap-icons';
import { Flip, ToastPosition, toast } from 'react-toastify';

export const SuccessToast = (
  title: string,
  closeOnClick: boolean = false,
  position: ToastPosition = 'bottom-right'
) => {
  return toast(title, {
    position,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick,
    pauseOnHover: true,
    draggable: false,
    theme: 'colored',
    transition: Flip,
    icon: <CheckCircleFill size={24} className='icon-ez-green' />,
  });
};

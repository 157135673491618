import { BrokenLink } from 'assets/icons/BrokenLink';
import { StarFillCut } from 'assets/icons/StarFillCut';
import { prefix } from 'inline-style-prefixer';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { ButtonGroup } from 'react-bootstrap';
import { CheckCircleFill, Star, StarFill } from 'react-bootstrap-icons';

type PropTypes = {
  supplier: BuyerCurrentSuppliers;
  handleClick: () => Promise<void>;
  allOptionsAreSelected: boolean;
  isSelected: boolean;
  isEditing: boolean | undefined;
  isFavorite: boolean | undefined;
  ifOptionIsForAllSuppliers: boolean;
  isLastSupplierSelected: boolean;
  handleUpdateFavoriteSeller: () => Promise<void>;
};

export const BuyerSupplierButtonForSelectionOption = ({
  supplier,
  handleClick,
  allOptionsAreSelected,
  isSelected,
  isEditing,
  isFavorite,
  ifOptionIsForAllSuppliers,
  isLastSupplierSelected,
  handleUpdateFavoriteSeller,
}: PropTypes) => {
  const styles = { position: 'absolute', left: '-8', top: '-3' };

  const prefixedStyles = prefix(styles);

  /* Cases for option buttons */

  /* Case: If option is for AllSuppliers */
  const AllSuppliersButton = (
    <div
      onClick={handleClick}
      className={
        allOptionsAreSelected
          ? 'supplier-selection-option-container active wrap-ellipsis'
          : 'supplier-selection-option-container wrap-ellipsis'
      }
    >
      {allOptionsAreSelected ? (
        <CheckCircleFill className='icon-ez-white me-2' />
      ) : (
        <CheckCircleFill className='icon-ez-gray me-2' />
      )}
      <span className='wrap-ellipsis'>{supplier.name}</span>
    </div>
  );

  /* Case: If option in NOT for AllSuppliers i.e regular option */

  /* Case1: Favorite LAST active supplier left */
  const LastFavoriteActiveSupplier = (
    <div
      className={`supplier-selection-option-container ${
        isSelected ? 'active' : ''
      }`}
      data-tip
      data-for={`supplier-selector-tooltip-${supplier.value}`}
    >
      <div>
        {isSelected ? (
          <CheckCircleFill className='icon-ez-white me-2' />
        ) : (
          <CheckCircleFill className='icon-ez-gray me-2' />
        )}
      </div>

      <div
        className={
          'btn btn-eleczap supplier-selection-option d-flex align-items-center justify-content-start disabled position-relative wrap-ellipsis'
        }
      >
        <span className=' position-relative'>
          {supplier.isActive && (
            <StarFillCut
              style={prefixedStyles}
              size={15}
              className={
                allOptionsAreSelected || isSelected ? 'me-3' : 'me-3 icon-ez'
              }
            />
          )}
          <span className='wrap-ellipsis'>{supplier.name}</span>
        </span>
      </div>
    </div>
  );

  /* Case2: Favorite active supplier left */
  const FavoriteActiveSupplier = (
    <div
      onClick={handleClick}
      className={`supplier-selection-option-container ${
        isSelected ? 'active' : ''
      }`}
      data-tip
      data-for={`supplier-selector-tooltip-${supplier.value}`}
    >
      <div>
        {isSelected ? (
          <CheckCircleFill className='icon-ez-white me-2' />
        ) : (
          <CheckCircleFill className='icon-ez-gray me-2' />
        )}
      </div>
      <div
        className={
          allOptionsAreSelected || isSelected
            ? supplier.isActive
              ? 'supplier-selection-option  position-relative wrap-ellipsis'
              : 'supplier-selection-option  position-relative supplier-selection-option-accessdisabled wrap-ellipsis'
            : 'supplier-selection-option position-relative wrap-ellipsis'
        }
      >
        {supplier.isActive ? (
          <></>
        ) : (
          <BrokenLink
            style={{ position: 'absolute', left: '2', top: '0' }}
            size={15}
            className={
              allOptionsAreSelected || isSelected ? 'me-3' : 'me-3 icon-ez'
            }
          />
        )}{' '}
        <span className=' position-relative'>
          {supplier.isActive && (
            <StarFillCut
              style={prefixedStyles}
              size={15}
              className={
                allOptionsAreSelected || isSelected ? 'me-3' : 'me-3 icon-ez'
              }
            />
          )}
          <span className='wrap-ellipsis'>{supplier.name}</span>
        </span>
      </div>
    </div>
  );

  /* Case3: Regular LAST active supplier left */
  const LastRegularActiveSupplier = (
    <div
      className={`supplier-selection-option-container disabled ${
        isSelected ? 'active ' : ''
      }`}
    >
      <div>
        {isSelected ? (
          <CheckCircleFill className='icon-ez-white me-2' />
        ) : (
          <CheckCircleFill className='icon-ez-gray me-2' />
        )}
      </div>

      <div
        className={
          'supplier-selection-option position-relative wrap-ellipsis disabled'
        }
        data-tip
        data-for={`supplier-selector-tooltip-${supplier.value}`}
      >
        <div>
          <span className='wrap-ellipsis'>{supplier.name}</span>
        </div>
      </div>
    </div>
  );

  /* Case4: Regular active supplier left */
  const RegularActiveSupplier = (
    <div
      onClick={handleClick}
      className={`supplier-selection-option-container ${
        isSelected ? 'active' : ''
      }`}
    >
      <div>
        {isSelected ? (
          <CheckCircleFill className='icon-ez-white me-2' />
        ) : (
          <CheckCircleFill className='icon-ez-gray me-2' />
        )}
      </div>

      <div
        data-tip
        data-for={`supplier-selector-tooltip-${supplier.value}`}
        className={
          allOptionsAreSelected || isSelected
            ? `wrap-ellipsis  ${
                isEditing ? 'ps-1' : ''
              }  supplier-selection-option ${
                supplier.isActive
                  ? ''
                  : 'supplier-selection-option-accessdisabled'
              } `
            : `wrap-ellipsis  ${
                isEditing ? 'ps-1' : ''
              }  supplier-selection-option`
        }
      >
        {supplier.isActive ? (
          <></>
        ) : (
          <BrokenLink
            style={{ position: 'absolute', left: '2', top: '0' }}
            size={15}
            className={
              allOptionsAreSelected || isSelected ? 'me-3' : 'me-3 icon-ez'
            }
          />
        )}
        <span className='wrap-ellipsis'>{supplier.name}</span>
      </div>
    </div>
  );

  const buttonToRender = () => {
    let toRender = <></>;
    if (ifOptionIsForAllSuppliers) toRender = AllSuppliersButton;

    if (!ifOptionIsForAllSuppliers) {
      if (isFavorite) {
        toRender = isLastSupplierSelected
          ? LastFavoriteActiveSupplier
          : FavoriteActiveSupplier;
      } else {
        const buttonGroupWithEditing = (
          <ButtonGroup>
            {isEditing && supplier.isActive && (
              <div
                className={
                  allOptionsAreSelected || isSelected
                    ? ' wrap-ellipsis'
                    : ' wrap-ellipsis'
                }
              >
                {isFavorite ? (
                  <StarFill size={20} />
                ) : (
                  <Star onClick={handleUpdateFavoriteSeller} size={20} />
                )}
              </div>
            )}

            {isLastSupplierSelected
              ? LastRegularActiveSupplier
              : RegularActiveSupplier}
          </ButtonGroup>
        );

        toRender = buttonGroupWithEditing;
      }
    }

    return toRender;
  };

  return buttonToRender();
};

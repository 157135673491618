import {
  DealActive,
  DealDisabled,
  DealFinshed,
  DealLost,
  InquiryDisabled,
  InquiryNoReplyBuyer,
  InquiryOfferSent,
  InquirySending,
  InvoiceOverdue,
  InvoicePaid,
  InvoiceUnpaidBuyer,
  OfferExpired,
  OfferNoReplyBuyer,
  OfferOrderMade,
  OrderConfirmed,
  OrderDisabled,
  OrderDispatched,
  OrderSending,
  OrderUnconfirmed,
} from 'assets/icons/StatusIcons';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { BuyerDealsTagSearch } from 'components/buyer/dealshome/BuyerDealsTagSearch';
import { useGetAllUsers } from 'components/shared/settings/Users/Users/hooksUsers';
import { AppContext } from 'contexts/user/AppContext';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { CreateNewProjectButton } from 'layout/CreateNewProjectButton';
import {
  AllProjectsNewModel,
  FilterStateModel,
} from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { UserModel } from 'models/shared/user/UserModel';
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import {
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  Clipboard,
  FileEarmark,
  FilterSquare,
  FilterSquareFill,
  Receipt,
  Search,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { getUrlParams } from 'utils/getUrlParams';

const dealStatusMap: {
  [Draft: string]: JSX.Element;
  Lost: JSX.Element;
  Active: JSX.Element;
  Finished: JSX.Element;
} = {
  Draft: <DealDisabled size={15} className='icon-ez-gray mx-1' />,
  Lost: <DealLost size={15} className='icon-ez-red mx-1' />,
  Active: <DealActive size={15} className='icon-ez-green mx-1' />,
  Finished: <DealFinshed size={15} className='icon-ez mx-1' />,
};

const inquiryStatusMap: {
  [Unsent: string]: JSX.Element;
  NoReply: JSX.Element;
  OfferMade: JSX.Element;
  all: JSX.Element;
  Sending: JSX.Element;
} = {
  all: <Clipboard className='icon-ez-gray' />,
  Unsent: <InquiryDisabled size={15} className='icon-ez-gray mx-1' />,
  NoReply: <InquiryNoReplyBuyer size={15} className='icon-ez-yellow mx-1' />,
  OfferMade: <InquiryOfferSent size={15} className='icon-ez mx-1' />,
  Sending: <InquirySending size={15} className='icon-ez-gray mx-1' />,
};

const offerStatusMap: {
  [Expired: string]: JSX.Element;
  NoReply: JSX.Element;
  OrderMade: JSX.Element;
  all: JSX.Element;
} = {
  all: <FileEarmark size={20} className='icon-ez-gray mx-1' />,
  Expired: <OfferExpired size={15} className='icon-ez-red mx-1' />,
  NoReply: <OfferNoReplyBuyer size={15} className='icon-ez-yellow mx-1' />,
  OrderMade: <OfferOrderMade size={15} className='icon-ez mx-1' />,
};

const orderStatusMap: {
  [Unsent: string]: JSX.Element;
  Unconfirmed: JSX.Element;
  Confirmed: JSX.Element;
  Dispatched: JSX.Element;
  all: JSX.Element;
  Sending: JSX.Element;
} = {
  all: <OrderDisabled size={20} className='icon-ez-gray mx-1' />,
  Unsent: <OrderDisabled size={15} className='icon-ez-gray mx-1' />,
  Unconfirmed: <OrderUnconfirmed size={15} className='icon-ez-yellow mx-1' />,
  Confirmed: <OrderConfirmed size={15} className='icon-ez-green mx-1' />,
  Dispatched: <OrderDispatched size={15} className='icon-ez mx-1' />,
  Sending: <OrderSending size={15} className='icon-ez-gray mx-1' />,
};

const invoiceStatusMap: {
  [Overdue: string]: JSX.Element;
  Unpaid: JSX.Element;
  Paid: JSX.Element;
  all: JSX.Element;
} = {
  all: <Receipt size={20} className='icon-ez-gray mx-1' />,
  Overdue: <InvoiceOverdue size={15} className='mx-1 icon-ez-red' />,
  Unpaid: <InvoiceUnpaidBuyer className='icon-ez-yellow mx-1' />,
  Paid: <InvoicePaid size={15} className='icon-ez mx-1' />,
};

type PropTypes = {
  getProjects: (filterState: FilterStateModel) => Promise<AllProjectsNewModel>;
  currentUser: LoginResponseUserModel;
  totalNumberOfPages: number;
  setFilterState: (
    state:
      | FilterStateModel
      | ((prevState: FilterStateModel) => FilterStateModel)
  ) => void;
  filterState: FilterStateModel;
};

export const BuyerDealsFilter = ({
  getProjects,
  currentUser,
  setFilterState,
  filterState,
  totalNumberOfPages,
}: PropTypes) => {
  const [urlFetched, setUrlFetched] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [users, setUsers] = useState<UserModel>({} as UserModel);

  const { t } = useTranslation('components');

  const setUserProfile = useSetUserProfile();

  const { appState, appContextDispatch } = useContext(AppContext);

  useGetAllUsers(currentUser, setUsers, 500);

  /* Read filters from URL on mount */
  useEffect(() => {
    const ownerId = getUrlParams('ownerId');
    const dealStatus = getUrlParams('dealStatus');
    const inquiryStatus = getUrlParams('inquiryStatus');
    const offerStatus = getUrlParams('offerStatus');
    const orderStatus = getUrlParams('orderStatus');
    const invoiceStatus = getUrlParams('invoiceStatus');
    const searchString = getUrlParams('searchString');
    const tagIds = getUrlParams('tagIds');

    appState.userProfile?.id &&
      setFilterState({
        ownerId: ownerId ?? 'all',
        dealStatus,
        inquiryStatus,
        offerStatus,
        orderStatus,
        invoiceStatus,
        searchString,
        pageNumber: 1,
        tagIds:
          tagIds?.split(',').map((item) => {
            return parseInt(item, 10);
          }) !== undefined
            ? tagIds?.split(',').map((item) => {
                return parseInt(item, 10);
              })
            : [],
      });

    appContextDispatch({
      type: 'BUYER_SET_PROJECTS_FILTER',
      buyerDealsFilter: {
        ownerId: ownerId ?? 'all',
        dealStatus,
        inquiryStatus,
        offerStatus,
        orderStatus,
        invoiceStatus,
        searchString,
        pageNumber: 1,
        tagIds:
          tagIds?.split(',').map((item) => {
            return parseInt(item, 10);
          }) !== undefined
            ? tagIds?.split(',').map((item) => {
                return parseInt(item, 10);
              })
            : [],
      },
    });

    setUrlFetched(true);
  }, [appContextDispatch, appState.userProfile?.id, setFilterState]);

  const handleInquiryStatus = (name: string | null) => {
    let cloneState = { ...appState.buyerDealsFilter };

    cloneState.inquiryStatus = name;
    cloneState.pageNumber = 1;
    updateUrl(cloneState);

    appContextDispatch({
      type: 'BUYER_SET_PROJECTS_FILTER',
      buyerDealsFilter: cloneState,
    });
  };

  const handleOfferStatus = (name: string | null) => {
    let cloneState = { ...appState.buyerDealsFilter };

    cloneState.offerStatus = name;
    cloneState.pageNumber = 1;
    updateUrl(cloneState);

    appContextDispatch({
      type: 'BUYER_SET_PROJECTS_FILTER',
      buyerDealsFilter: cloneState,
    });
  };

  const handleOrderStatus = (name: string | null) => {
    let cloneState = { ...appState.buyerDealsFilter };

    cloneState.orderStatus = name;
    cloneState.pageNumber = 1;
    updateUrl(cloneState);

    appContextDispatch({
      type: 'BUYER_SET_PROJECTS_FILTER',
      buyerDealsFilter: cloneState,
    });
  };

  const handleInvoiceStatus = (name: string | null) => {
    let cloneState = { ...appState.buyerDealsFilter };

    cloneState.invoiceStatus = name;
    cloneState.pageNumber = 1;
    updateUrl(cloneState);

    appContextDispatch({
      type: 'BUYER_SET_PROJECTS_FILTER',
      buyerDealsFilter: cloneState,
    });
  };

  const handleDealsByMe = async (event: FormEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;

    // await Api.updateDealsByMe(currentUser.companyId, currentUser.id, {
    //   dealsByMe: value === '1',
    // });

    // await setUserProfile(appState.buyerCurrentSupplierId || null);
    let cloneState = { ...appState.buyerDealsFilter };

    cloneState.ownerId = value;
    cloneState.pageNumber = 1;

    updateUrl(cloneState);
    appContextDispatch({
      type: 'BUYER_SET_PROJECTS_FILTER',
      buyerDealsFilter: cloneState,
    });
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    let cloneState = { ...appState.buyerDealsFilter };

    cloneState.searchString = value;
    cloneState.pageNumber = 1;
    updateUrl(cloneState);

    appContextDispatch({
      type: 'BUYER_SET_PROJECTS_FILTER',
      buyerDealsFilter: cloneState,
    });
  };

  const handleTagFilter = (tagIds: number[]) => {
    let cloneState = { ...appState.buyerDealsFilter };

    cloneState.tagIds = tagIds;
    cloneState.pageNumber = 1;
    updateUrl(cloneState);

    appContextDispatch({
      type: 'BUYER_SET_PROJECTS_FILTER',
      buyerDealsFilter: cloneState,
    });
  };

  const handleSearchDebounced = AwesomeDebouncePromise(handleSearch, 400);

  const updateUrl = (clonedState: FilterStateModel) => {
    let url = window.location.href.split('?')[0];

    url = `${url}?ownerId=${clonedState.ownerId ?? 'all'}&dealStatus=${
      clonedState.dealStatus !== undefined && clonedState.dealStatus !== null
        ? clonedState.dealStatus
        : 'all'
    }&inquiryStatus=${
      clonedState.inquiryStatus !== undefined &&
      clonedState.inquiryStatus !== null
        ? clonedState.inquiryStatus
        : 'all'
    }&offerStatus=${
      clonedState.offerStatus !== undefined && clonedState.offerStatus !== null
        ? clonedState.offerStatus
        : 'all'
    }&orderStatus=${
      clonedState.orderStatus !== undefined && clonedState.orderStatus !== null
        ? clonedState.orderStatus
        : 'all'
    }&invoiceStatus=${
      clonedState.invoiceStatus !== undefined &&
      clonedState.invoiceStatus !== null
        ? clonedState.invoiceStatus
        : 'all'
    }&searchString=${clonedState.searchString}&tagIds=${
      clonedState.tagIds !== undefined ? clonedState.tagIds.join(',') : ''
    }`;

    const nextURL = url;

    const nextTitle = 'ElecZap';
    const nextState = {
      additionalInformation: 'Updated the URL with filters',
    };

    // This will replace the current entry in the browser's history, without reloading
    window.history.replaceState(nextState, nextTitle, nextURL);
  };

  return (
    <div
      style={{
        width: '100%',
        zIndex: 300,
        backgroundColor: 'white',
        paddingTop: '10px',
      }}
      className='projects-filters'
    >
      <Card.Header className='p-2'>
        <Row className='pb-1' style={{ textAlign: 'center' }}>
          <div
            className='btn-toolbar justify-content-between'
            role='toolbar'
            aria-label='Toolbar with button groups'
          >
            <div className='input-group align-items-center'>
              <CreateNewProjectButton />

              <div className='p-1 ms-4 rounded-start btn-dealsBy'>
                {t('deals.lbl_DealsBy')}
              </div>
              <Form.Select
                value={appState.buyerDealsFilter?.ownerId ?? ''}
                onChange={handleDealsByMe}
                className='select-dropdown-no-left-radius dropdown-dealsBy'
              >
                <option value='all'>{t('deals.lbl_Everybody')}</option>
                <option value={appState.userProfile?.id}>
                  {t('deals.lbl_Me')}
                </option>
                {users.data
                  ?.sort((a, b) =>
                    a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1
                  )
                  .map(
                    (user) =>
                      user.isInvitationCompleted &&
                      user.id !== appState.userProfile?.id && (
                        <option
                          value={user.id}
                          className={!user.isActive ? 'icon-ez-light-gray' : ''}
                        >{`${user.firstName} ${user.lastName}`}</option>
                      )
                  )}
              </Form.Select>
              <InputGroup
                className={`border rounded ${isFocused && 'custom-focused'}`}
              >
                <InputGroup.Text className='btn' id='search-addon'>
                  <Search />
                </InputGroup.Text>
                <Form.Control
                  style={{ paddingLeft: 0, width: '15rem' }}
                  className='border-0 search-for-deals-input'
                  as='input'
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onChange={handleSearchDebounced}
                  type='text'
                  placeholder={t('deals.plh_SearchDeals')}
                />
              </InputGroup>

              <BuyerDealsTagSearch
                filterState={appState.buyerDealsFilter}
                handleTagFilter={handleTagFilter}
                currentUser={currentUser}
                t={t}
              />
            </div>
          </div>
        </Row>
      </Card.Header>
      <Card.Body className='buyerDealsHome'>
        <Row className='px-4'>
          {/* Deals filter  */}
          {/* <Col>
            <Card className='border'>
              <Card.Header className='card-title'>
                {
                  dealStatusMap[
                    filterState.dealStatus !== null &&
                    filterState.dealStatus !== 'all'
                      ? filterState.dealStatus
                      : 'Draft'
                  ]
                }{' '}
                {t('deals.lbl_Deals')}
                <div className='btn-group float-end'>
                  <DropdownButton
                    className='filter-eleczap-deals noCaret'
                    drop='down'
                    align={{ lg: 'end' }}
                    onSelect={handleDealStatus}
                    variant='light'
                    title={
                      filterState.dealStatus !== null &&
                      filterState.dealStatus !== 'all' ? (
                        <FilterSquareFill className='icon-ez' size={20} />
                      ) : (
                        <FilterSquare className='icon-ez' size={20} />
                      )
                    }
                  >
                    <Dropdown.Item className='mx-1' eventKey='all'>
                      <h5>{t('deals.lbl_All')}</h5>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Draft'>
                      <BriefcaseFill size={15} className='mx-1' />{' '}
                      {t('documents.lbl_Draft')}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Lost'>
                      <DealLost size={15} className='icon-ez-red mx-1' />{' '}
                      {t('documents.lbl_Lost')}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Active'>
                      <DealActive size={15} className='icon-ez-green mx-1' />{' '}
                      {t('documents.lbl_Active')}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Finished'>
                      <DealFinshed size={15} className='icon-ez mx-1' />{' '}
                      {t('documents.lbl_Finished')}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Card.Header>
            </Card>
          </Col> */}

          {/* Inquiry filter  */}

          <Col>
            <Card className='border'>
              <Card.Header className='card-title'>
                {
                  inquiryStatusMap[
                    appState.buyerDealsFilter.inquiryStatus !== null
                      ? appState.buyerDealsFilter.inquiryStatus
                      : 'all'
                  ]
                }{' '}
                {t('documents.hd_Inquiry')}
                <div className='btn-group float-end'>
                  <DropdownButton
                    className='filter-eleczap-deals noCaret'
                    drop='down'
                    onSelect={handleInquiryStatus}
                    align={{ lg: 'end' }}
                    variant='light'
                    title={
                      appState.buyerDealsFilter.inquiryStatus !== null &&
                      appState.buyerDealsFilter.inquiryStatus !== 'all' ? (
                        <FilterSquareFill className='icon-ez' size={20} />
                      ) : (
                        <FilterSquare className='icon-ez' size={20} />
                      )
                    }
                  >
                    <Dropdown.Item className='mx-1' eventKey='all'>
                      <h5>{t('deals.lbl_All')}</h5>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='NoReply'>
                      <InquiryNoReplyBuyer
                        size={20}
                        className='icon-ez-yellow mx-1'
                      />{' '}
                      {t('documents.lbl_NoReply')}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='OfferMade'>
                      <InquiryOfferSent size={20} className='icon-ez mx-1' />{' '}
                      {t('documents.lbl_OfferMade')}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Card.Header>
            </Card>
          </Col>

          {/* Offer filter  */}

          <Col>
            <Card className='border'>
              <Card.Header className='card-title'>
                {
                  offerStatusMap[
                    appState.buyerDealsFilter.offerStatus !== null
                      ? appState.buyerDealsFilter.offerStatus
                      : 'all'
                  ]
                }{' '}
                {t('documents.hd_Offer')}
                <div className='btn-group float-end'>
                  <DropdownButton
                    className='filter-eleczap-deals noCaret'
                    drop='down'
                    onSelect={handleOfferStatus}
                    align={{ lg: 'end' }}
                    variant='light'
                    title={
                      appState.buyerDealsFilter.offerStatus !== null &&
                      appState.buyerDealsFilter.offerStatus !== 'all' ? (
                        <FilterSquareFill className='icon-ez' size={20} />
                      ) : (
                        <FilterSquare className='icon-ez' size={20} />
                      )
                    }
                  >
                    <Dropdown.Item className='mx-1' eventKey='all'>
                      <h5>{t('deals.lbl_All')}</h5>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Expired'>
                      <OfferExpired size={20} className='mx-1 icon-ez-red' />{' '}
                      {t('documents.lbl_Expired')}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='NoReply'>
                      <OfferNoReplyBuyer
                        size={20}
                        className='icon-ez-yellow mx-1'
                      />{' '}
                      {t('documents.lbl_NoReply')}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='OrderMade'>
                      <OfferOrderMade size={20} className='icon-ez mx-1' />{' '}
                      {t('documents.lbl_OrderMade')}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Card.Header>
            </Card>
          </Col>

          {/* Order filter  */}

          <Col>
            <Card className='border'>
              <Card.Header className='card-title'>
                {
                  orderStatusMap[
                    appState.buyerDealsFilter.orderStatus !== null
                      ? appState.buyerDealsFilter.orderStatus
                      : 'all'
                  ]
                }{' '}
                {t('documents.hd_Order')}
                <div className='btn-group float-end'>
                  <DropdownButton
                    className='filter-eleczap-deals noCaret'
                    drop='down'
                    align={{ lg: 'end' }}
                    onSelect={handleOrderStatus}
                    variant='light'
                    title={
                      appState.buyerDealsFilter.orderStatus !== null &&
                      appState.buyerDealsFilter.orderStatus !== 'all' ? (
                        <FilterSquareFill className='icon-ez' size={20} />
                      ) : (
                        <FilterSquare className='icon-ez' size={20} />
                      )
                    }
                  >
                    <Dropdown.Item className='mx-1' eventKey='all'>
                      <h5>{t('deals.lbl_All')}</h5>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Unconfirmed'>
                      <OrderUnconfirmed
                        size={20}
                        className='icon-ez-yellow mx-1'
                      />{' '}
                      {t('documents.lbl_Unconfirmed')}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Confirmed'>
                      <OrderConfirmed
                        size={20}
                        className='icon-ez-green mx-1'
                      />{' '}
                      {t('documents.lbl_Confirmed')}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Dispatched'>
                      <div className='icon-ez float-start mx-1'>
                        <OrderDispatched />
                      </div>{' '}
                      {t('documents.lbl_Dispatched')}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Card.Header>
            </Card>
          </Col>

          {/* Invoice filter  */}

          <Col>
            <Card className='border'>
              <Card.Header className='card-title'>
                {
                  invoiceStatusMap[
                    appState.buyerDealsFilter.invoiceStatus !== null
                      ? appState.buyerDealsFilter.invoiceStatus
                      : 'all'
                  ]
                }{' '}
                {t('documents.hd_Invoice')}
                <div className='btn-group float-end'>
                  <DropdownButton
                    className='filter-eleczap-deals noCaret'
                    drop='down'
                    align={{ lg: 'end' }}
                    onSelect={handleInvoiceStatus}
                    variant='light'
                    title={
                      appState.buyerDealsFilter.invoiceStatus !== null &&
                      appState.buyerDealsFilter.invoiceStatus !== 'all' ? (
                        <FilterSquareFill className='icon-ez' size={20} />
                      ) : (
                        <FilterSquare className='icon-ez' size={20} />
                      )
                    }
                  >
                    <Dropdown.Item className='mx-1' eventKey='all'>
                      <h5>{t('deals.lbl_All')}</h5>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Overdue'>
                      <InvoiceOverdue size={20} className='mx-1 icon-ez-red' />{' '}
                      {t('documents.lbl_Overdue')}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Unpaid'>
                      <InvoiceUnpaidBuyer
                        size={20}
                        className='icon-ez-yellow mx-1'
                      />{' '}
                      {t('documents.lbl_Unpaid')}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey='Paid'>
                      <InvoicePaid size={20} className='icon-ez mx-1' />{' '}
                      {t('documents.lbl_Paid')}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Card.Header>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </div>
  );
};

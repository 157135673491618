/* Hooks utilized in Company.tsx (Company settings)*/
import { IdentityApi } from 'api/IdentityApi';
import { useFormik } from 'formik';
import { CustomerOnboardingDetailsModel } from 'models/shared/customerOnboarding/customerOnboardingDetailsModel';
import { CustomerOnboardingUpdateDetailsModel } from 'models/shared/customerOnboarding/customerOnboardingUpdateDetailsModel';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* Formik init */
export const useFormikHook = (
  company: CustomerOnboardingDetailsModel,
  invitationToken: string,
  nextStep: () => void,
  t: any
): any => {
  const handleSubmit = async (
    values: CustomerOnboardingUpdateDetailsModel
  ): Promise<void> => {
    console.log('test');
    toast.dismiss();
    await IdentityApi.updateCustomerDetails(invitationToken, values);

    toast.info(t('companySettings.inf_CompanySettingsSaved'));
    nextStep();
  };

  const validationSchema = yup.object({
    name: yup.string().required(t('common.err_ValueRequired')),
    shortName: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(70, t('common.err_ValueMax70')),
    addressLine1: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(150, t('common.err_ValueMax150')),
    addressLine2: yup.string().nullable().max(150, t('common.err_ValueMax150')),
    vatNumber: yup.string().nullable().max(50, t('common.err_ValueMax50')),
    postalCode: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),
    city: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),
    state: yup.string().nullable().max(50, t('common.err_ValueMax50')),
    webSite: yup.string().max(50, t('common.err_ValueMax50')).nullable(),
    email: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .email(t('common.err_EmailInvalid'))
      .max(50, t('common.err_ValueMax50')),
    phone: yup
      .string()
      .nullable()
      .max(50, t('common.err_ValueMax50'))
      .nullable(),
  });

  const formik = useFormik<CustomerOnboardingDetailsModel>({
    initialValues: { ...company },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedCompany: CustomerOnboardingUpdateDetailsModel = {
        name: values.name,
        shortName: values.shortName,
        email: values.email,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        postalCode: values.postalCode,
        city: values.city,
        state: values.state,
        phone: values.phone,
        webSite: values.webSite,
        vatNumber: values.vatNumber,
        registryNumber: values.registryNumber,
      };
      await handleSubmit(updatedCompany);
      formik.setSubmitting(false);
    },
  });

  return formik;
};

export const useGetCompanyDetails = (
  setCompany: (value: CustomerOnboardingDetailsModel) => void,
  invitationToken: string
): void => {
  useEffect(() => {
    const getCompany = async () => {
      const company = await IdentityApi.getCustomerDetails(invitationToken);

      setCompany(company);
    };

    getCompany();
  }, [invitationToken, setCompany]);
};

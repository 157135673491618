import { BuyerApi } from 'api/BuyerApi';
import { ImageWithZoom } from 'components/buyer/products/Product/ImageWithZoom';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { useGetFormattedPrice } from 'hooks/useGetFormattedPrice';
import { useListenElementResize } from 'hooks/useListenElementResize';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import {
  BuyerProjectRowInfoGroupedModel,
  ProjectRowGroupedModel,
} from 'models/buyer/project/GroupedProjectModels';
import {
  ProjectModel,
  ProjectRowBulkUpdateModel,
} from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { bulkUpdateProjectRows } from 'pages/buyer/Project/utilsProject';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  Image,
  SkipForwardFill,
  StarFill,
  Trash,
  X,
} from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { getMOQColumns } from 'utils/getMOQColumns';
import { getStockLevelColumns } from 'utils/getStockLevelColumns';

type PropTypes = {
  projectDetails: ProjectModel;
  getDataSource: () => Promise<void>;
  dataSource: BuyerProjectRowInfoGroupedModel[];
  setDataSource: (value: BuyerProjectRowInfoGroupedModel[]) => void;
  filteredData: (
    data: BuyerProjectRowInfoGroupedModel[]
  ) => BuyerProjectRowInfoGroupedModel[];
};

export const ProjectProductsGridGrouped = ({
  projectDetails,
  getDataSource,
  setDataSource,
  dataSource,
  filteredData,
}: PropTypes) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { appState } = useContext(AppContext);
  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);
  const [widthOfGridContainer, setWidthOfGridContainer] = useState<number>(0);
  const [currentRequiredQtyFocused, setCurrentRequiredQtyFocused] =
    useState<number>(0);

  const [isRequiredQtyUpdating, setIsRequiredQtyUpdating] =
    useState<boolean>(false);

  const [isExtraTableExpanded, setIsExtraTableExpanded] =
    useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const canSeePrices = useUserCanSeePrices();
  const getFormattedPrice = useGetFormattedPrice();

  const { t } = useTranslation('components');

  useEffect(() => {
    const getData = async () => {
      await getDataSource();
      setIsLoading(false);
    };

    getData();
  }, [currentUser, getDataSource, projectDetails.id]);

  const fillAllSupplierQtyWithrequiredQuantity = async () => {
    if (!isRequiredQtyUpdating) {
      let clonedDataSource = [...dataSource];
      clonedDataSource.forEach((r) =>
        r.rows.forEach((product) => {
          const newQuantity =
            appState.buyerCurrentSuppliers &&
            appState.buyerCurrentSuppliers.findIndex(
              (x) => x.isSelected && x.value === product.sellerId
            ) > -1
              ? product.requiredQuantity
              : product.supplierQuantity;
          product.supplierQuantity = newQuantity;
        })
      );

      setDataSource(clonedDataSource);

      let bulkUpdateRows: ProjectRowBulkUpdateModel[] = [];

      clonedDataSource.forEach((row) => {
        row.rows.forEach((r) => {
          bulkUpdateRows.push({
            projectRowId: r.id,
            supplierQuantity: r.supplierQuantity,
          });
        });
      });

      await bulkUpdateProjectRows(
        bulkUpdateRows,
        currentUser,
        projectDetails.id
      );
      await getDataSource();
    }
  };

  const deleteAllSupplierQtyWithrequiredQuantity = async () => {
    let clonedDataSource = [...dataSource];
    clonedDataSource.forEach((r) =>
      r.rows.forEach((product) => {
        if (
          appState.buyerCurrentSuppliers &&
          appState.buyerCurrentSuppliers.findIndex(
            (x) => x.isSelected && x.value === product.sellerId
          ) > -1
        ) {
          product.supplierQuantity = 0;
        }
      })
    );

    setDataSource(clonedDataSource);

    let bulkUpdateRows: ProjectRowBulkUpdateModel[] = [];

    clonedDataSource.forEach((row) => {
      row.rows.forEach((prod) => {
        bulkUpdateRows.push({
          projectRowId: prod.id,
          supplierQuantity: prod.supplierQuantity,
        });
      });
    });

    await bulkUpdateProjectRows(bulkUpdateRows, currentUser, projectDetails.id);
    await getDataSource();
  };

  const handleDeleteRow = async (rows: ProjectRowGroupedModel[]) => {
    setIsLoading(true);
    toast.dismiss();
    await BuyerApi.deleteProjectRows(
      currentUser.companyId,
      projectDetails.id,
      rows.map((x) => x.id)
    );
    // toast.success(t('projectSettings.inf_RowDeleted'));
    await getDataSource();
    setIsLoading(false);
  };

  let allRowsSuppQtyIsNotZero = false;

  dataSource.forEach((r) => {
    r.rows
      .filter((row) => !row.isDisabled)
      .every((row) => {
        if (row.supplierQuantity === 0) {
          allRowsSuppQtyIsNotZero = true;
          return false;
        } else return true;
      });
  });

  let favoriteStockExists = false;

  dataSource !== undefined &&
    dataSource.forEach((r) => {
      r.rows.forEach((tr) => {
        tr.stockLevels?.forEach((stockLevel) => {
          if (stockLevel.isFavoriteStock) favoriteStockExists = true;
        });
      });
    });

  const moqColumns = getMOQColumns(getFormattedPrice, t);

  const stockLevelColumns = getStockLevelColumns(
    t,
    favoriteStockExists,
    favoriteStockExists
  );

  useListenElementResize('.project-product-grid-container', {
    setWidthOfElement: setWidthOfGridContainer,
  });

  const namePopover = (name: string) => (
    <Popover>
      <Popover.Body>{name}</Popover.Body>
    </Popover>
  );

  const columns: TableColumn<BuyerProjectRowInfoGroupedModel>[] = [
    {
      name: '#',
      cell: (row, index) => <span>{row.rows[0].rowNo}</span>,
      selector: (row) => row.rows[0].rowNo,
      sortable: false,
      width: '40px',
      right: true,
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.rows[0].productCode,
      format: (row) => {},
      cell: (row) => (
        <Row className='wrap-ellipsis'>
          {row.rows[0].productId === null ? (
            <></>
          ) : (
            <a
              href={`/buyer/project-product-details/${encodeURIComponent(
                row.productCode
              )}`}
            >
              {row.productCode}
            </a>
          )}
        </Row>
      ),
      maxWidth: '100px',
      sortable: true,
    },
    {
      name: <Image />,
      selector: (row) => row.rows[0].productUrl,
      cell: (row) => (
        <Row style={{ maxWidth: '65px', minWidth: '65px' }}>
          {row.rows.map((product) => (
            <Row className='align-items-center'>
              <ImageWithZoom
                src={product.imageUrl}
                width={40}
                height={40}
                alt='ProductImage'
                zoomedMaxHeight={300}
                zoomedMaxWidth={300}
              ></ImageWithZoom>
            </Row>
          ))}
        </Row>
      ),
      sortable: false,
      maxWidth: '50px',
      minWidth: '50px',
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.rows[0].name,
      sortable: true,
      cell: (row) => (
        <Row className='wrap-ellipsis'>
          {row.rows.map((product) => (
            <div className=' d-flex align-items-center'>
              {product.productId === null ? (
                <></>
              ) : (
                <span
                  className={
                    product.isDisabled
                      ? 'icon-ez-light-gray wrap-ellipsis'
                      : 'wrap-ellipsis'
                  }
                >
                  <OverlayTrigger
                    trigger='hover'
                    placement='auto'
                    overlay={namePopover(product.name)}
                  >
                    <span>{product.name}</span>
                  </OverlayTrigger>
                </span>
              )}
            </div>
          ))}
        </Row>
      ),
      // maxWidth: isExtraTableExpanded ? '150px' : '',
      grow: 1,
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
      conditionalCellStyles: [
        {
          when: (row: BuyerProjectRowInfoGroupedModel) =>
            row.rows.every((v) => v.isDisabled),
          style: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
      ],
    },
    {
      name: t('sellerSwitch.lbl_Supplier'),
      selector: (row) => row.rows[0].supplierName,
      sortable: false,
      cell: (row) => (
        <Row className='wrap-ellipsis justify-content-start'>
          {row.rows.map((prod) => (
            <div
              className={`align-items-center d-flex justify-content-start ${
                prod.isDisabled ? 'icon-ez-light-gray' : ''
              }`}
            >
              <span className={'wrap-ellipsis'}>{prod.supplierName}</span>
            </div>
          ))}
        </Row>
      ),
      width: '80px',
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
    },
    {
      name: t('projectSettings.lbl_RequiredTotalQty'),
      selector: (row) => row.rows[0].requiredQuantity,
      cell: (row) => (
        <Row className='justify-content-center'>
          <Row className='align-items-center'></Row>
        </Row>
      ),
      width: '120px',
      sortable: true,
      center: true,
    },
    {
      name: (
        <div>
          {allRowsSuppQtyIsNotZero ? (
            <SkipForwardFill
              style={{ cursor: 'pointer' }}
              onClick={fillAllSupplierQtyWithrequiredQuantity}
              className='icon-ez ms-2'
            />
          ) : (
            <X
              style={{ cursor: 'pointer' }}
              onClick={deleteAllSupplierQtyWithrequiredQuantity}
              className='icon-ez-red ms-2'
            />
          )}{' '}
          {t('projectSettings.lbl_SupplierQty')}
        </div>
      ),
      selector: (row) => row.rows[0].supplierQuantity,
      cell: (row) => (
        <Row className='justify-content-center'>
          {row.rows.map((prod) => (
            <Row className='align-items-center'></Row>
          ))}
        </Row>
      ),
      sortable: false,
      width: '130px',
    },
    {
      name: t('projectSettings.lbl_Priceunit'),
      selector: (row) => row.rows[0].moqPrices?.toString(),
      cell: (row) => (
        <Row
          className='justify-content-end text-end'
          style={{ maxWidth: '95px', minWidth: '95px' }}
        >
          {row.rows.map((prod) => {
            const reverseClone =
              prod.moqPrices !== undefined ? [...prod.moqPrices].reverse() : [];
            const popover = (
              <Popover id='popover-moqPrices'>
                <Popover.Body>
                  <DataTable columns={moqColumns} data={prod.moqPrices} />
                </Popover.Body>
              </Popover>
            );

            return (
              <Row className='align-items-center text-end'>
                <Col>
                  {canSeePrices(
                    prod.productId !== null ? (
                      prod.moqPrices?.length > 1 ? (
                        <OverlayTrigger
                          trigger='hover'
                          placement='auto'
                          overlay={popover}
                        >
                          <strong>
                            <span
                              className={
                                prod.isDisabled
                                  ? 'icon-ez-light-gray'
                                  : 'btn-link-bold'
                              }
                            >
                              {prod.moqPrices !== undefined
                                ? getFormattedPrice(
                                    reverseClone.find(
                                      (moq) => prod.supplierQuantity >= moq.moq
                                    ) || prod.moqPrices[0]
                                  )
                                : '-'}
                            </span>
                          </strong>
                        </OverlayTrigger>
                      ) : (
                        <span
                          className={
                            prod.isDisabled ? 'icon-ez-light-gray' : ''
                          }
                        >
                          {prod.moqPrices !== undefined
                            ? getFormattedPrice(prod.moqPrices[0])
                            : '-'}
                        </span>
                      )
                    ) : (
                      <span
                        className={prod.isDisabled ? 'icon-ez-light-gray' : ''}
                      >
                        -
                      </span>
                    )
                  )}
                </Col>
              </Row>
            );
          })}
        </Row>
      ),
      sortable: false,
      right: true,
      maxWidth: '80px',
      minWidth: '80px',
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
    },
    {
      name: t('projectSettings.lbl_TotalPrice'),
      selector: (row) => row.rows[0].totalPrice,
      cell: (row) => (
        <Row
          className='justify-content-end text-end'
          style={{ maxWidth: '95px', minWidth: '95px' }}
        >
          {row.rows.map((prod) => {
            if (prod.productCode === undefined) return null;

            return (
              <Row className='align-items-center'>
                {prod.productId === null ? (
                  canSeePrices(
                    <span
                      className={
                        prod.isDisabled
                          ? 'icon-ez-light-gray text-end justify-content-end'
                          : 'text-end justify-content-end'
                      }
                    >
                      -
                    </span>
                  )
                ) : (
                  <span
                    className={
                      prod.isDisabled
                        ? 'icon-ez-light-gray text-end justify-content-end'
                        : 'text-end justify-content-end'
                    }
                  >
                    {canSeePrices(
                      prod.moqPrices !== undefined
                        ? formatMonetaryValue(
                            prod.moqPrices[0].currencyCode,
                            prod.totalPrice,
                            appState
                          )
                        : '-'
                    )}
                  </span>
                )}
              </Row>
            );
          })}
        </Row>
      ),
      sortable: true,
      right: true,
      maxWidth: '85px',
      minWidth: '85px',
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
    },
    {
      name: isExtraTableExpanded ? (
        <ChevronDoubleRight
          className='cursor-pointer icon-ez'
          onClick={() => setIsExtraTableExpanded(false)}
        />
      ) : (
        <ChevronDoubleLeft
          className='cursor-pointer icon-ez'
          onClick={() => setIsExtraTableExpanded(true)}
        />
      ),
      sortable: false,
      cell: (row) => (
        <Row className='align-items-center'>
          {row.productCode !== undefined &&
            row.rows[0].rowNo === hoveredRowId && (
              <Trash
                style={{ cursor: 'pointer' }}
                onClick={() => handleDeleteRow(row.rows)}
                className='icon-ez-red'
              />
            )}
        </Row>
      ),
      width: '60px',
      center: true,
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
      //   maxWidth: '65px',
      //   minWidth: '65px',
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    rows: {
      // style: {
      //   maxWidth: widthOfGridContainer * 0.25,
      // },
    },
    cells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row: BuyerProjectRowInfoGroupedModel) =>
        row.rows.every((y) => y.supplierQuantity < 1),
      style: {
        backgroundColor: '#f0f0f0',
      },
    },
    {
      when: (row: BuyerProjectRowInfoGroupedModel) =>
        row.rows.every((y) => y.isDisabled),
      style: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        // filter: 'blur(2px)',
      },
    },
    // {
    //   when: (row: BuyerProjectRowInfoGroupedModel) =>
    //     row.rows.every((y) => y.isDisabled) && !showDisabledRows,
    //   style: {
    //     display: 'none',
    //     // filter: 'blur(2px)',
    //   },
    // },
  ];

  const handleRowMouseEnter = (row: BuyerProjectRowInfoGroupedModel) => {
    setHoveredRowId(row.rows[0].rowNo);
  };
  const handleRowMouseLeave = (row: BuyerProjectRowInfoGroupedModel) => {
    setHoveredRowId(null);
  };

  const columnsExtra: TableColumn<BuyerProjectRowInfoGroupedModel>[] = [
    {
      name: (
        <span>
          {' '}
          <StarFill style={{ marginTop: -5 }} /> {t('common.lbl_Stock')}
        </span>
      ),
      selector: (row) => row.rows[0].stockLevels?.toString(),
      cell: (row) => (
        <Row
          className='justify-content-end text-end'
          style={{ maxWidth: '85px', minWidth: '85px', minHeight: '31.44' }}
        >
          {row.rows.map((prod) => {
            const favStock = prod.stockLevels?.filter(
              (stock) => stock.isFavoriteStock
            );

            const itemHasFavoriteStock = favStock?.length > 0;

            return (
              <Row
                style={{ minHeight: '31.44px' }}
                className='align-items-center'
              >
                {row.productCode !== undefined && (
                  <strong className='text-center'>
                    <span
                      className={prod.isDisabled ? 'icon-ez-light-gray' : ''}
                    >
                      {favStock !== undefined && itemHasFavoriteStock
                        ? favStock[0].inStock
                        : '-'}
                    </span>
                  </strong>
                )}
              </Row>
            );
          })}
        </Row>
      ),
      sortable: false,
      right: true,
      maxWidth: '70px',
      minWidth: '70px',
      omit: !favoriteStockExists,
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
    },
    {
      name: <span className='text-end'>{t('projectSettings.lbl_Stocks')}</span>,
      selector: (row) => row.rows[0].stockLevels?.toString(),
      cell: (row) => (
        <Row
          className='justify-content-end text-end'
          style={{ maxWidth: '85px', minWidth: '85px', minHeight: '31.44' }}
        >
          {row.rows.map((prod) => {
            let totalStockLevel = 0;

            prod.stockLevels?.forEach((stockLevel) => {
              totalStockLevel += stockLevel.inStock;
            });

            const popover = (
              <Popover style={{ minWidth: '500px' }} id='popover-stockLevels'>
                <Popover.Header as='h3'>
                  {t('projectSettings.lbl_Stocks')}
                </Popover.Header>
                <Popover.Body>
                  <DataTable
                    columns={stockLevelColumns}
                    data={prod.stockLevels}
                  />
                </Popover.Body>
              </Popover>
            );
            if (prod.productCode === undefined) return <></>;

            return (
              <Row className='align-items-center'>
                {prod.stockLevels?.length > 1 ? (
                  <OverlayTrigger
                    trigger='hover'
                    placement='auto'
                    overlay={popover}
                  >
                    <span>
                      <strong
                        className={
                          prod.isDisabled
                            ? 'icon-ez-light-gray'
                            : 'btn-link-bold'
                        }
                      >
                        {prod.productId === null ? '-' : totalStockLevel}
                      </strong>
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span className={prod.isDisabled ? 'icon-ez-light-gray' : ''}>
                    {prod.productId === null ? '-' : totalStockLevel}
                  </span>
                )}
              </Row>
            );
          })}
        </Row>
      ),
      sortable: false,
      right: true,
      maxWidth: '70px',
      minWidth: '70px',
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
      //   maxWidth: '65px',
      //   minWidth: '65px',
    },
    {
      name: t('common.lbl_Arriving'),
      selector: (row) => row.rows[0].arriving,
      sortable: true,
      right: true,
      maxWidth: '70px',
      minWidth: '70px',
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
      cell: (row) => (
        <Row
          className='justify-content-end text-end'
          style={{ maxWidth: '85px', minWidth: '85px' }}
        >
          {row.rows.map((prod) => {
            let totalArriving = 0;
            prod.stockLevels?.forEach((stockLevel) => {
              totalArriving += stockLevel.arriving;
            });

            if (row.productCode === undefined) return <></>;

            return (
              <Row className='align-items-center'>
                <span className={prod.isDisabled ? 'icon-ez-light-gray' : ''}>
                  {prod.productId === null ? '-' : totalArriving}
                </span>
              </Row>
            );
          })}
        </Row>
      ),
    },
    {
      name: `${t('projectSettings.lbl_OrderStep')} / ${t(
        'productListHeader.lbl_Package'
      )}`,
      selector: (row) => row.rows[0].orderStep,
      cell: (row) => (
        <Row
          className='justify-content-end text-end'
          style={{ maxWidth: '85px', minWidth: '85px' }}
        >
          {row.rows.map((prod) => {
            return (
              <Row className='align-items-center'>
                {row.productCode !== undefined && (
                  <span
                    style={{ whiteSpace: 'nowrap' }}
                    className={prod.isDisabled ? 'icon-ez-light-gray' : ''}
                  >
                    {prod.productId === null ? (
                      '-'
                    ) : (
                      <>
                        {prod.orderStep} / <small>{prod.packageSize}</small>
                      </>
                    )}
                  </span>
                )}
              </Row>
            );
          })}
        </Row>
      ),
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
      sortable: true,
      right: true,
      maxWidth: '85px',
      minWidth: '85px',
    },
  ];

  return (
    <>
      {isLoading && <FullScreenLoader />}

      <Row>
        <Col lg={isExtraTableExpanded ? 9 : 12}>
          <DataTable
            // progressPending={isLoading}
            onRowMouseEnter={handleRowMouseEnter}
            onRowMouseLeave={handleRowMouseLeave}
            columns={columns}
            data={filteredData(dataSource)}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
          />
        </Col>

        {isExtraTableExpanded && (
          <Col lg={isExtraTableExpanded ? 3 : 0}>
            <DataTable
              // progressPending={isLoading}
              onRowMouseEnter={handleRowMouseEnter}
              onRowMouseLeave={handleRowMouseLeave}
              columns={columnsExtra}
              data={filteredData(dataSource)}
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

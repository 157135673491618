import { ProjectInquiryRowModel } from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';

type PropTypes = {
  onChange?: (checked: boolean, row: ProjectInquiryRowModel) => Promise<void>;
  onBulkChange?: (checked: boolean) => Promise<void>;
  checked: boolean;
  row?: ProjectInquiryRowModel;
};

export const CustomToggle = ({
  checked,
  onChange,
  onBulkChange,
  row,
}: PropTypes) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = async (checked: boolean) => {
    setIsChecked(!isChecked);
    onChange && row && (await onChange(checked, row));
    onBulkChange && (await onBulkChange(checked));
  };

  return (
    <Switch
      onChange={handleChange}
      // type='switch'
      checked={isChecked}
      aria-label='radio 1'
      onColor='#1b9ad2'
      handleDiameter={20}
      height={15}
      boxShadow='0 0 2px 2px #6c757d'
      onHandleColor='#1b9ad2'
    />
  );
};

import {
  SellersUsersDataModel,
  SellersUsersModel,
} from 'models/buyer/suppliers/SellersUsersModel';
import { Image } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './supplierTable.css';

const customStyles = {
  rows: {
    style: {
      backgroundColor: '#F2F2F2', // override the row height
    },
  },
};

type PropTypes = {
  suppliersUsers: SellersUsersModel;
  handlePageChange: (page: number) => Promise<void>;
  handleRowsPerChange: (rowsAmount: number) => Promise<void>;
  handleOnSort: (selectedColumn: any, sortDirection: any) => Promise<void>;
  resetPaginationToggle: boolean;
};

export const SupplierUserTable = ({
  suppliersUsers,
  handlePageChange,
  handleRowsPerChange,
  handleOnSort,
  resetPaginationToggle,
}: PropTypes): JSX.Element => {
  const { t } = useTranslation('components');

  const columns: TableColumn<SellersUsersDataModel>[] = [
    {
      name: <Image />,
      selector: (row) => row.initials,
      cell: (row) => (
        <div className='rounded-circle customerTalbe-initals'>
          {row.initials}
        </div>
      ),
      maxWidth: '4.5rem',
      minWidth: '4.5rem',
      center: true,
    },
    {
      name: t('customers.lbl_Name'),
      selector: (row) => row.fullName,
      cell: (row) => (
        <Link className='link-eleczap' to={`supplier-info?id=${row.id}`}>
          {row.fullName}
        </Link>
      ),
      sortable: true,
      maxWidth: '15rem',
      minWidth: '15rem',
    },
    {
      name: t('customers.lbl_E-mail'),
      selector: (row) => row.email,
      cell: (row) => (
        <a href={`mailto:${row.email}`} className='link-eleczap'>
          {row.email}
        </a>
      ),
    },

    {
      name: t('customers.lbl_Phone'),
      selector: (row) => row.phone,
    },
    {
      name: t('customers.lbl_Phone'),
      selector: (row) => row.position,
    },
    // {
    //   name: <FolderCheck className='icon-supplierActiveProject' />,
    //   selector: (row) => row.activeDeals,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },

    // {
    //   name: <FileEarmarkMinusFill className='icon-supplierOffer' />,
    //   selector: (row) => row.offers,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },
    // {
    //   name: <CartDashFill className='icon-supplierOrder' />,
    //   selector: (row) => row.orders,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },
    // {
    //   name: <ExclamationSquareFill className='icon-supplierInvoice' />,
    //   selector: (row) => row.overdueInvoices,
    //   sortable: true,
    //   center: true,
    //   maxWidth: '3.7rem',
    //   minWidth: '3.7rem',
    //   style: {
    //     color: 'var(--primary)',
    //   },
    // },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: t('common.lbl_RowsPerPage'),
    rangeSeparatorText: t('common.lbl_Of'),
  };

  return (
    <DataTable
      noDataComponent={
        <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
      }
      highlightOnHover
      responsive
      columns={columns}
      data={suppliersUsers.data}
      pagination
      customStyles={customStyles}
      paginationServer
      paginationComponentOptions={paginationComponentOptions}
      paginationTotalRows={suppliersUsers.totalItems}
      onChangeRowsPerPage={handleRowsPerChange}
      onChangePage={handlePageChange}
      paginationResetDefaultPage={resetPaginationToggle}
      sortServer
      onSort={handleOnSort}
    />
  );
};

import { SellerApi } from 'api/SellerApi';
import {
  OrderCancelled,
  OrderConfirmed,
  OrderDispatched,
  OrderSending,
  OrderUnconfirmed,
} from 'assets/icons/StatusIcons';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { OrderModel, OrderRowModel } from 'models/seller/documents/OrderModels';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { CartPlusFill } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { truncateDecimals } from 'utils/truncateDecimals';

type UrlParamsType = {
  orderId: string;
};

export const Order = () => {
  const [order, setOrder] = useState<OrderModel>({} as OrderModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isQuickOrderLoading, setIsQuickOrderLoading] =
    useState<boolean>(false);

  const { appState } = useContext(AppContext);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');
  const validateUnitExists = useValidateUnitExists();

  let { orderId } = useParams<UrlParamsType>();

  useEffect(() => {
    setIsLoading(true);
    const getOffer = async (): Promise<void> => {
      const response = await SellerApi.getOrder(
        currentUser.companyId,
        parseInt(orderId),
        userLocaleId
      );

      setOrder(response);
      setIsLoading(false);
    };
    getOffer();
  }, [currentUser.companyId, orderId, userLocaleId]);

  const columns: TableColumn<OrderRowModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      sortable: true,
      width: '60px',
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode,
      cell: (row) =>
        row.productId === null ? (
          <span>{row.productCode}</span>
        ) : (
          <Link
            to={`/seller/product-details/${row.productId}`}
            className='btn-link-bold'
          >
            {row.productCode}
          </Link>
        ),
      sortable: true,
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name,
      sortable: true,
      grow: 7,
    },
    {
      name: t('documents.lbl_DispatchDate'),
      selector: (row) => row.deliveryDate,
      cell: (row) =>
        row.deliveryDate === 'Dispatched' ? (
          <span className='icon-ez-green'>{row.deliveryDate}</span>
        ) : row.deliveryDate === 'Unconfirmed' ? (
          <span className='icon-ez-red'>{row.deliveryDate}</span>
        ) : row.deliveryDate === null ? (
          <span> - </span>
        ) : (
          <span>{dayjs(row.deliveryDate).format('DD.MM.YYYY')}</span>
        ),
      sortable: true,
      right: true,
    },
    {
      name: t('documents.lbl_Qty'),
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          <span>
            {row.quantity} <small>{validateUnitExists(row.unit)}</small>
          </span>
        </>
      ),
      sortable: true,
      right: true,
    },
    {
      name: t('documents.lbl_PriceUnit'),
      selector: (row) => row.priceWithDiscount,
      cell: (row) =>
        formatMonetaryValue(
          order.currencyCode,
          row.priceWithDiscount,
          appState,
          row.decimalPlaces
        ),
      sortable: true,
      right: true,
    },

    {
      name: t('projectSettings.lbl_TotalPrice'),
      selector: (row) => row.total,
      cell: (row) =>
        row.total
          ? formatMonetaryValue(
              order.currencyCode,
              truncateDecimals(row.total, row.decimalPlaces),
              appState
            )
          : '-',
      sortable: true,
      right: true,
    },
  ];

  const handleQuickOrder = async () => {
    setIsQuickOrderLoading(true);

    const createResponse = await SellerApi.createQuickOrder(
      currentUser.companyId,
      {
        originalOrderId: order.id,
      }
    );

    const projectUrl = `${window && window.location.origin}/buyer/projects/${
      createResponse.projectId
    }/project-order/${createResponse.projectOrderId}?isQuickOrder=true`;

    const windowFeatures = 'popup,left=100,top=100,width=1600,height=800';

    window.open(projectUrl, '', windowFeatures);

    setIsQuickOrderLoading(false);
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className='container-fluid p-2'>
        <Card>
          <Card.Header>
            <Row>
              <Col lg={4}>
                {appState.userProfile?.isMarketplace && (
                  <Button
                    onClick={handleQuickOrder}
                    className='btn- btn-eleczap d-flex align-items-center justify-content-between'
                  >
                    <CartPlusFill size={20} className='me-2' /> Quick order{' '}
                    {isQuickOrderLoading && (
                      <Spinner className='ms-2' animation='border' size='sm' />
                    )}
                  </Button>
                )}
              </Col>
              <Col className='text-center' lg={4}>
                <h2>
                  {t('documents.hd_Order')} {order.code}
                </h2>
              </Col>
              <Col
                className='text-center d-flex justify-content-lg-end justify-content-sm-center'
                lg={4}
              >
                <h3>
                  {order.status === 'Unconfirmed' && (
                    <>
                      {t('documents.lbl_Unconfirmed')}{' '}
                      <OrderUnconfirmed className='icon-ez-yellow' />
                    </>
                  )}
                  {order.status === 'Confirmed' && (
                    <>
                      {t('documents.lbl_Confirmed')}{' '}
                      <OrderConfirmed className='icon-ez-green' />
                    </>
                  )}
                  {order.status === 'Dispatched' && (
                    <>
                      {t('documents.lbl_Dispatched')}{' '}
                      <OrderDispatched className='icon-ez' />
                    </>
                  )}
                  {order.status === 'Sending' && (
                    <>
                      {t('documents.lbl_Sending')}{' '}
                      <OrderSending className='icon-ez' />
                    </>
                  )}
                  {order.status === 'Cancelled' && (
                    <>
                      {t('documents.lbl_Cancelled')}{' '}
                      <OrderCancelled className='icon-ez-red ms-1' />
                    </>
                  )}
                </h3>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_Date')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(order.documentDate).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_UpdatedOn')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(order.lastUpdated).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentBy')}:
                  </Col>
                  <Col>
                    <strong>{order.sentByContactName}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentTo')}:
                  </Col>
                  <Col>
                    <span className='btn-link-bold'>
                      {order.sentToCompanyName}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row className='flex-nowrap'>
                  <Col className='text-end pe-0 text-nowrap'>
                    <Row className='mb-0'>
                      <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_LinkedOffer')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_MyOrderNumber')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>
                        {t('projectSettings.lbl_RequestedDeliveryDate')}:
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                    </Row>

                    {order.deliveryAddressLine2 !== null && <br />}

                    {order.deliveryAddressLine3 !== null && <br />}

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_PaymentTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_DeliveryTerms')}:</Col>
                    </Row>
                  </Col>

                  <Col
                    className='pe-3 d-flex flex-column'
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        <span className='btn-link-bold-no-link'>
                          {order.projectName}
                        </span>
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.offerCode !== null && order.offerCode !== '' ? (
                          <Link
                            to={`/seller/offer/${order.offerId}`}
                            className='btn-link-bold'
                          >
                            {order.offerCode}
                          </Link>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.customerOrderCode !== null &&
                        order.customerOrderCode !== '' ? (
                          <strong>{order.customerOrderCode}</strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.requestedDeliveryDate !== null ? (
                          <strong>
                            {dayjs(order.requestedDeliveryDate).format(
                              'DD.MM.YYYY'
                            )}
                          </strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='d-flex flex-column text-nowrap'>
                        {order.deliveryAddressLine1 !== null ? (
                          <strong>{order.deliveryAddressLine1}</strong>
                        ) : (
                          <br />
                        )}
                        <strong>{order.deliveryAddressLine2}</strong>
                        <strong>{order.deliveryAddressLine3}</strong>
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.paymentTerm !== null ? (
                          <strong>{order.paymentTerm} days</strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.deliveryTerm !== null ? (
                          <strong>{order.deliveryTerm}</strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='my-5'>
              <DataTable
                noDataComponent={
                  <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
                }
                columns={columns}
                data={order.rows}
              />
            </Row>

            <Row className='justify-content-between'>
              <Col lg={6}>
                <Form.Group className='mb-3' controlId='comments'>
                  <Form.Label>{t('common.lbl_Comments')}</Form.Label>
                  <Form.Control
                    readOnly
                    as='textarea'
                    rows={4}
                    value={order.comment}
                  />
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Row className='mt-3'>
                  <Col>
                    <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                  </Col>
                  <Col>
                    <h3 className='text-end'>
                      {useFormatMonetaryValue(order.currencyCode, order.total)}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-end'>
                    {t('common.inf_TotalPriceSubtext')}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

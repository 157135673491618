import { LightTooltip } from 'components/shared/LightTooltip';
import { BuyerSuppliersSelector } from 'layout/BuyerSuppliersSelector';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Nav, Navbar, Spinner } from 'react-bootstrap';
import { BriefcaseFill, PeopleFill, PlugFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../contexts/user/AppContext';
import logo from '../media/ElecZap Logo White.png';
import './buyerHeader.css';

type PropTypes = {
  setShowSelector: (value: boolean) => void;
};

export const BuyerSidebar = ({ setShowSelector }: PropTypes) => {
  const [width, setWidth] = useState<number>(window && window.innerWidth);
  const { appState } = useContext(AppContext);
  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const navigate = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='container-xxl buyer-header-container-xxl d-print-none align-items-center flex-column'>
      <Navbar.Brand className='pt-0'>
        <Link className='bg-logo ' to='/buyer/product-classes'>
          <img
            style={{ marginLeft: '-6px' }}
            className='buyer-logo-header'
            alt='ElecZap'
            src={logo}
          />
        </Link>
      </Navbar.Brand>

      <Nav className='nav-container flex-column align-items-start mt-3'>
        {<BuyerSuppliersSelector setShowSelector={setShowSelector} />}

        <Nav.Item
          data-tip
          data-for={`/buyer/product-classes`}
          style={{ cursor: 'pointer', marginTop: '18px' }}
          onClick={() => navigate.push('/buyer/product-classes')}
          className={`text-center nav-item-rounded ${
            navigate.location.pathname.includes('buyer/product-classes')
              ? 'nav-item-rounded-active'
              : ''
          }`}
        >
          <span className='buyer-nav-item-white d-flex flex-column align-items-center'>
            <PlugFill className='buyer-nav-item-icon icon-ez-white' size={38} />
          </span>
        </Nav.Item>
        <LightTooltip id={`/buyer/product-classes`}>
          <h3 className='buyer-nav-item-label'>
            {t('buyerHeader.lbl_Products')}
          </h3>
        </LightTooltip>

        <Nav.Item
          data-tip
          data-for={`/buyer/deals`}
          style={{ cursor: 'pointer', marginTop: '18px' }}
          onClick={() => navigate.push('/buyer/deals')}
          className={`text-center nav-item-rounded ${
            navigate.location.pathname.includes('buyer/deals')
              ? 'nav-item-rounded-active'
              : ''
          }`}
          id='projectsPage'
        >
          <span className='buyer-nav-item-white d-flex flex-column align-items-center position-relative'>
            {appState.buyerDeals?.isFetching && (
              <Spinner
                style={{
                  position: 'absolute',
                  right: '-2px',
                  top: '-2px',
                }}
                animation='border'
                size='sm'
              />
            )}

            <BriefcaseFill
              className='buyer-nav-item-icon icon-ez-white'
              size={38}
            />
          </span>
        </Nav.Item>
        <LightTooltip id={`/buyer/deals`}>
          <h3 className='buyer-nav-item-label'>
            {t('buyerHeader.lbl_Projects')}
          </h3>
        </LightTooltip>

        <Nav.Item
          data-tip
          data-for={`/buyer/suppliers`}
          style={{ cursor: 'pointer', marginTop: '18px' }}
          onClick={() => navigate.push('/buyer/suppliers')}
          className={`text-center nav-item-rounded ${
            navigate.location.pathname.includes('buyer/suppliers')
              ? 'nav-item-rounded-active'
              : ''
          }`}
          id='suppliersPage'
        >
          <span className='buyer-nav-item-white d-flex flex-column align-items-center'>
            <PeopleFill
              className='buyer-nav-item-icon icon-ez-white'
              size={38}
            />
          </span>
        </Nav.Item>
        <LightTooltip id={`/buyer/suppliers`}>
          <h3 className='buyer-nav-item-label'>
            {t('buyerSettingsDropdownButton.lbl_Suppliers')}
          </h3>
        </LightTooltip>
      </Nav>
    </div>
  );
};

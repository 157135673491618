import { ApplicationSettingsApi } from 'api/ApplicationSettingsApi';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { useEffect } from 'react';

export const useGetLocalizations = (
  setLocalizations: (values: LocalizationModel[]) => void
) => {
  useEffect(() => {
    const getLocalizations = async () => {
      const localizations = await ApplicationSettingsApi.getLocalizations();
      setLocalizations(localizations);
    };
    getLocalizations();
  }, [setLocalizations]);
};

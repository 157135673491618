import { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Collapse,
  FormControl,
  Row,
} from 'react-bootstrap';
import {
  ArrowDownCircleFill,
  ArrowRightCircleFill,
  CartPlusFill,
  FilePdf,
  SquareHalf,
  StarFill,
} from 'react-bootstrap-icons';
import Card from 'react-bootstrap/Card';
import OTL50Grey from './../../../../media/products/MAA1050A10.png';
import 'components/shared/products/ProductDetails/ProductDetails.css';

export const BuyerProductDetails = (): JSX.Element => {
  const [open, setOpen] = useState(true);

  return (
    <div className='container-fluid p-4'>
      <Card className='p-4'>
        <Card.Header className='p-3 position-relative'>
          <Row>
            <Col>
              <Row>
                <Col xs={2}>
                  <Button variant='btn btn-eleczap'>
                    <StarFill />
                  </Button>
                </Col>
                <Col xs={7}>
                  <h2>Product code</h2>
                </Col>
              </Row>
            </Col>
            <Col className='text-end'>
              <Row>
                <Col>
                  <h2>Supplier 1</h2>
                </Col>
                <Col>
                  <Button variant='btn btn-eleczap me-2'>
                    <SquareHalf />
                  </Button>
                  <Button variant='btn btn-eleczap'>
                    <FilePdf />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col className='text-end'>
              <Row>
                <Col>
                  <h2>Supplier 2</h2>
                </Col>
                <Col>
                  <Button variant='btn btn-eleczap me-2'>
                    <SquareHalf />
                  </Button>
                  <Button variant='btn btn-eleczap'>
                    <FilePdf />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col className='text-end'>
              <Row>
                <Col>
                  <h2>Supplier 3</h2>
                </Col>
                <Col>
                  <Button variant='btn btn-eleczap me-2'>
                    <SquareHalf />
                  </Button>
                  <Button variant='btn btn-eleczap'>
                    <FilePdf />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col></Col>
            <Col className='col-lg-3 text-center'>
              <img
                className='img-Aporduct'
                alt='MAA1050A10'
                src={OTL50Grey}
                width='350px'
              />
            </Col>
            <Col className='col-lg-3 text-center'>
              <img
                className='img-Aporduct'
                alt='MAA1050A10'
                src={OTL50Grey}
                width='350px'
              />
            </Col>
            <Col className='col-lg-3 text-center'>
              <img
                className='img-Aporduct'
                alt='MAA1050A10'
                src={OTL50Grey}
                width='350px'
              />
            </Col>
          </Row>
          <Row className='p-3'>
            <Col>Product name</Col>
            <Col className='text-center'>
              OTL50 grey 1xAl/Cu 1,5-50mm² 1000V Universal terminal
            </Col>
            <Col className='text-center'>
              OTL50 grey 1xAl/Cu 1,5-50mm² 1000V Universal terminal
            </Col>
            <Col className='text-center'>
              OTL50 grey 1xAl/Cu 1,5-50mm² 1000V Universal terminal
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className='product-card-scroll-body'>
          {/* Price and availability */}
          <Card>
            <Card.Header
              className='header-aProduct'
              onClick={() => setOpen(!open)}
              aria-controls='priceAndAvailability'
              aria-expanded={open}
            >
              {open ? <ArrowDownCircleFill /> : <ArrowRightCircleFill />} Price
              and availability
            </Card.Header>
            <Collapse in={open}>
              <Card.Body id='priceAndAvailability'>
                <Row>
                  <Col className='pt-1 pb-1'>Price (MOQ 1):</Col>
                  <Col className='ms-1 pt-1 pb-1'>-</Col>
                  <Col className='ms-1 pt-1 pb-1'>-</Col>
                  <Col className='ms-1 pt-1 pb-1'>-</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Price (MOQ 50):</Col>
                  <Col className='ms-1 pt-1 pb-1'>2.89 €</Col>
                  <Col className='ms-1 pt-1 pb-1'>2.89 €</Col>
                  <Col className='ms-1 pt-1 pb-1'>2.89 €</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Price (MOQ 250):</Col>
                  <Col className='ms-1 pt-1 pb-1'>2.69 €</Col>
                  <Col className='ms-1 pt-1 pb-1'>2.69 €</Col>
                  <Col className='ms-1 pt-1 pb-1'>2.69 €</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Price (MOQ 500):</Col>
                  <Col className='ms-1 pt-1 pb-1'>2.45 €</Col>
                  <Col className='ms-1 pt-1 pb-1'>2.45 €</Col>
                  <Col className='ms-1 pt-1 pb-1'>2.45 €</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>In stock (all stocks):</Col>
                  <Col className='ms-1 pt-1 pb-1'>1008 pcs</Col>
                  <Col className='ms-1 pt-1 pb-1'>1008 pcs</Col>
                  <Col className='ms-1 pt-1 pb-1'>1008 pcs</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>In stock (favorite stocks):</Col>
                  <Col className='ms-1 pt-1 pb-1'>240 pcs</Col>
                  <Col className='ms-1 pt-1 pb-1'>240 pcs</Col>
                  <Col className='ms-1 pt-1 pb-1'>240 pcs</Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col className='ms-1 pt-1 pb-1'>
                    <Row>
                      <Col className='pt-1 pb-1'>Add to cart</Col>
                      <Col xs={5}>
                        <ButtonGroup aria-label='Basic example'>
                          <FormControl type='number' placeholder='MOQ' />
                          <Button variant='btn btn-eleczap'>
                            <CartPlusFill />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col className='ms-1 pt-1 pb-1'>
                    <Row>
                      <Col className='pt-1 pb-1'>Add to cart</Col>
                      <Col xs={5}>
                        <ButtonGroup aria-label='Basic example'>
                          <FormControl type='number' placeholder='MOQ' />
                          <Button variant='btn btn-eleczap'>
                            <CartPlusFill />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col className='ms-1 pt-1 pb-1'>
                    <Row>
                      <Col className='pt-1 pb-1'>Add to cart</Col>
                      <Col xs={5}>
                        <ButtonGroup aria-label='Basic example'>
                          <FormControl type='number' placeholder='MOQ' />
                          <Button variant='btn btn-eleczap'>
                            <CartPlusFill />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Collapse>
          </Card>
          {/* General parameters */}
          <Card>
            <Card.Header
              className='header-aProduct'
              onClick={() => setOpen(!open)}
              aria-controls='generalParameters'
              aria-expanded={open}
            >
              {open ? <ArrowDownCircleFill /> : <ArrowRightCircleFill />}{' '}
              General parameters
            </Card.Header>
            <Collapse in={open}>
              <Card.Body id='generalParameters'>
                <Row>
                  <Col className='pt-1 pb-1'>Product description</Col>
                  <Col className='ms-1 pt-1 pb-1'>
                    Universal terminals OTL50 are designed for all types of
                    copper and aluminium conductors with cross-section from 1,5
                    mm² up to 50 mm². Up to three (depending on the
                    cross-section) copper wires can be placed to each clamp of
                    the OTL terminal. IP protection class for OTL terminals is
                    IP20.
                  </Col>
                  <Col className='ms-1 pt-1 pb-1'>
                    Universal terminals OTL50 are designed for all types of
                    copper and aluminium conductors with cross-section from 1,5
                    mm² up to 50 mm². Up to three (depending on the
                    cross-section) copper wires can be placed to each clamp of
                    the OTL terminal. IP protection class for OTL terminals is
                    IP20.
                  </Col>
                  <Col className='ms-1 pt-1 pb-1'>
                    Universal terminals OTL50 are designed for all types of
                    copper and aluminium conductors with cross-section from 1,5
                    mm² up to 50 mm². Up to three (depending on the
                    cross-section) copper wires can be placed to each clamp of
                    the OTL terminal. IP protection class for OTL terminals is
                    IP20.
                  </Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Product code:</Col>
                  <Col className='ms-1 pt-1 pb-1'>MAA1050A10</Col>
                  <Col className='ms-1 pt-1 pb-1'>MAA1050A10</Col>
                  <Col className='ms-1 pt-1 pb-1'>MAA1050A10</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>EAN:</Col>
                  <Col className='ms-1 pt-1 pb-1'>4744397013811</Col>
                  <Col className='ms-1 pt-1 pb-1'>4744397013811</Col>
                  <Col className='ms-1 pt-1 pb-1'>4744397013811</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Name of the manufacturer:</Col>
                  <Col className='ms-1 pt-1 pb-1'>Morek</Col>
                  <Col className='ms-1 pt-1 pb-1'>Morek</Col>
                  <Col className='ms-1 pt-1 pb-1'>Morek</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>ETIM Class:</Col>
                  <Col className='ms-1 pt-1 pb-1'>EC000897</Col>
                  <Col className='ms-1 pt-1 pb-1'>EC000897</Col>
                  <Col className='ms-1 pt-1 pb-1'>EC000897</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Supplier's class:</Col>
                  <Col className='ms-1 pt-1 pb-1'>MAA1</Col>
                  <Col className='ms-1 pt-1 pb-1'>MAA1</Col>
                  <Col className='ms-1 pt-1 pb-1'>MAA1</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Brand name:</Col>
                  <Col className='ms-1 pt-1 pb-1'>Morek</Col>
                  <Col className='ms-1 pt-1 pb-1'>Morek</Col>
                  <Col className='ms-1 pt-1 pb-1'>Morek</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Product series:</Col>
                  <Col className='ms-1 pt-1 pb-1'>OTL Universal Terminals</Col>
                  <Col className='ms-1 pt-1 pb-1'>OTL Universal Terminals</Col>
                  <Col className='ms-1 pt-1 pb-1'>OTL Universal Terminals</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Country of origin:</Col>
                  <Col className='ms-1 pt-1 pb-1'>Finland</Col>
                  <Col className='ms-1 pt-1 pb-1'>Finland</Col>
                  <Col className='ms-1 pt-1 pb-1'>Finland</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>CN8 code:</Col>
                  <Col className='ms-1 pt-1 pb-1'>85369010</Col>
                  <Col className='ms-1 pt-1 pb-1'>85369010</Col>
                  <Col className='ms-1 pt-1 pb-1'>85369010</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Unit:</Col>
                  <Col className='ms-1 pt-1 pb-1'>pcs</Col>
                  <Col className='ms-1 pt-1 pb-1'>pcs</Col>
                  <Col className='ms-1 pt-1 pb-1'>pcs</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Package size:</Col>
                  <Col className='ms-1 pt-1 pb-1'>50</Col>
                  <Col className='ms-1 pt-1 pb-1'>50</Col>
                  <Col className='ms-1 pt-1 pb-1'>50</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Net weight:</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,03 kg</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,03 kg</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,03 kg</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Net lenght:</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,05 m</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,05 m</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,05 m</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Net width:</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,018 m</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,018 m</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,018 m</Col>
                </Row>
                <Row>
                  <Col className='pt-1 pb-1'>Net depth:</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,043 m</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,043 m</Col>
                  <Col className='ms-1 pt-1 pb-1'>0,043 m</Col>
                </Row>
              </Card.Body>
            </Collapse>
          </Card>
          {/* ETIM product class parameter */}
          <Card>
            <Card.Header
              className='header-aProduct'
              onClick={() => setOpen(!open)}
              aria-controls='ETIMProductClassParameter'
              aria-expanded={open}
            >
              {open ? <ArrowDownCircleFill /> : <ArrowRightCircleFill />} ETIM
              product class parameter
            </Card.Header>
            <Collapse in={open}>
              <Card.Body id='ETIMProductClassParameter'>
                <Row>Dynamicaly generated data</Row>
              </Card.Body>
            </Collapse>
          </Card>
          {/*  Product history */}
          <Card>
            <Card.Header
              className='header-aProduct'
              onClick={() => setOpen(!open)}
              aria-controls='productHistory'
              aria-expanded={open}
            >
              {open ? <ArrowDownCircleFill /> : <ArrowRightCircleFill />}{' '}
              Product history
            </Card.Header>
            <Collapse in={open}>
              <Card.Body id='productHistory'>
                <Row>Dynamicaly generated data</Row>
              </Card.Body>
            </Collapse>
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
};

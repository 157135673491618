import { Api } from 'api/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { useSignIn, useSignOut } from 'react-auth-kit';
import { Button, Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { decodeToken } from 'react-jwt';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { IdentityApi } from '../../api/IdentityApi';
import { AppContext } from '../../contexts/user/AppContext';
import '../../css/light.css';
import '../../css/site.css';
import logo from '../../media/ElecZap_new_logo.png';
import './login.css';

type LoginFormValues = {
  email: string;
  password: string;
};

export const Login = () => {
  const { t, i18n } = useTranslation('pages');
  const signIn = useSignIn();
  const signOut = useSignOut();
  const location = useLocation();
  const history = useHistory();
  const { appState, appContextDispatch } = useContext(AppContext);

  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .email(t('login.err_EmailInvalid'))
      .required(t('login.err_EmailRequired')),
    password: yup.string().required(t('login.err_PasswordRequired')),
  });

  const initialValues: LoginFormValues = { email: '', password: '' };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      toast.dismiss();

      signOut();

      const loginResponse = await IdentityApi.login({ ...values });
      const decodedToken = decodeToken(loginResponse.accessToken) as {
        exp: number;
      };
      const expiresIn = Math.floor(
        (decodedToken.exp - Date.now().valueOf() / 1000) / 60
      );

      const signInOk = signIn({
        token: loginResponse.accessToken,
        expiresIn: expiresIn,
        tokenType: 'Bearer',
        authState: loginResponse.user,
      });

      if (signInOk) {
        const user = await Api.getUserWithAuthHeader(
          loginResponse.user.companyId,
          loginResponse.user.id,
          loginResponse.accessToken
        );

        appContextDispatch({
          type: 'USER_SET_PROFILE',
          userProfile: user,
        });

        if (appState) {
          const uiLanguage =
            appState.userProfile?.localization?.cultureName?.slice(0, 2);
          i18n.changeLanguage(uiLanguage);
        }

        const defaultPath =
          loginResponse.user.defaultView === 'Seller'
            ? '/seller/deals'
            : '/buyer/product-classes';
        const { from } = (location.state as any) || {
          from: { pathname: defaultPath },
        };

        history.replace(from);
      } else {
        throw Error(t('login.err_UserSignInFail'));
      }
    },
  });

  return (
    <div className='wrapper'>
      <div className='main d-flex justify-content-center w-100'>
        <div className='content d-flex p-0'>
          <div className='container d-flex flex-column'>
            <div className='row h-100'>
              <div className='col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100'>
                <div className='d-table-cell align-middle'>
                  <Card>
                    <Card.Header className='text-center'>
                      <a href='https://www.eleczap.com/'>
                        <img
                          className='img-fluid logo-login'
                          alt='ElecZap logo'
                          src={logo}
                        />
                      </a>
                      <h1 className='mt-3'>ElecZap</h1>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={formik.handleSubmit} noValidate>
                        <Form.Group
                          className='mb-2 from-control form-control-lg'
                          controlId='formEmail'
                        >
                          <Form.Label>{t('login.lbl_Email')}</Form.Label>
                          <Form.Control
                            type='input'
                            name='email'
                            placeholder={t('login.plh_enterEmail')}
                            onChange={formik.handleChange}
                            isInvalid={
                              formik.touched.email && !!formik.errors.email
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {formik.errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className='from-control form-control-lg'
                          controlId='formPassword'
                        >
                          <Form.Label>{t('login.lbl_Password')}</Form.Label>
                          <Form.Control
                            type='password'
                            name='password'
                            placeholder={t('login.plh_enterPassword')}
                            onChange={formik.handleChange}
                            isInvalid={
                              formik.touched.password &&
                              !!formik.errors.password
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {formik.errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Link
                          className='link-forgotPassword'
                          to='/forgot-password'
                        >
                          {t('login.link_ForgotPassword')}
                        </Link>
                        <div className='text-center'>
                          <Button
                            type='submit'
                            className='btn btn-primary btn-lg btn-signIn'
                            disabled={formik.isSubmitting}
                          >
                            {t('login.btn_SignIn')}
                          </Button>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { BuyerProductGridHeader } from 'components/buyer/products/Product/BuyerProductGridHeader ';
import { BuyerProductListBoxGrouped } from 'components/buyer/products/Product/BuyerProductListBoxGrouped';
import { BuyerProductListHeader } from 'components/buyer/products/Product/BuyerProductListHeader';
import { BuyerProductListTileGrouped } from 'components/buyer/products/Product/BuyerProductListTileGrouped';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { useCheckIfFavoriteStockExists } from 'hooks/useCheckIfFavoriteStockExists';
import { useResetQtyForProductList } from 'hooks/useResetQtyForProductList';
import {
  BuyerGroupedProductListPagedResult,
  GroupedProductModel,
} from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

type PropsType = {
  items: BuyerGroupedProductListPagedResult[] | undefined;
  productDetailRoute: string;
  getProducts?: (didCancel: boolean, orderBy: string) => Promise<void>;
  isLoading?: boolean | false;
  isPricesLoading?: boolean | false;
  setPageNumber?: (value: number) => void;
  setOrderBy?: (value: string) => void;
  setSelectedManufacturers: (value: string[]) => void;
  selectedManufacturers: string[];
};

export const BuyerProductListSimple = ({
  items,
  productDetailRoute,
  getProducts,
  isLoading,
  selectedManufacturers,
  setSelectedManufacturers,
  isPricesLoading,
  setPageNumber,
  setOrderBy,
}: PropsType) => {
  const [favoriteStockExists, setFavoriteStockExists] =
    useState<boolean>(false);
  const ifFavoriteStockExists = useCheckIfFavoriteStockExists();

  const [mounted, setMounted] = useState<boolean>(false);

  const { appState, appContextDispatch } = useContext(AppContext);

  const resetQtyForProductList = useResetQtyForProductList();

  const { t } = useTranslation('components');

  useEffect(() => {
    const listOfProducts: GroupedProductModel[] | null = [];
    items?.forEach((item) =>
      item.data?.forEach((data) => listOfProducts.push(data))
    );

    appContextDispatch({
      type: 'BUYER_SET_CURRENT_PRODUCT_LIST',
      currentProducts: listOfProducts,
    });

    return () => {
      resetQtyForProductList();
    };
  }, [appContextDispatch, items]);

  useEffect(() => {
    const checkFavStock = async () => {
      try {
        const favStock = await ifFavoriteStockExists();

        setFavoriteStockExists(favStock);
      } catch (err) {
        console.log(err);
      }
    };

    let isSubSeller: boolean = false;

    appState.buyerCurrentSuppliers
      ?.filter((supplier) => supplier.isSelected && supplier.isMarketplace)
      .forEach((seller) =>
        seller.subSellers?.forEach((subSeller) => {
          if (subSeller.value === appState.buyerCurrentSupplierId)
            isSubSeller = subSeller.isSubSeller && seller.isMarketplace;
        })
      );

    !isSubSeller && checkFavStock();
  }, [
    appState.buyerCurrentSupplierId,
    appState.buyerCurrentSuppliers,
    ifFavoriteStockExists,
  ]);

  const BuyerProductHeaderNode =
    appState.productsView === 'GRID' ? (
      <BuyerProductGridHeader
        favoriteStockExists={favoriteStockExists}
        // getProducts={getProducts}
        setPageNumber={setPageNumber}
        selectedManufacturers={selectedManufacturers}
        setSelectedManufacturers={setSelectedManufacturers}
        setOrderBy={setOrderBy}
      />
    ) : (
      // <div />
      <BuyerProductListHeader
        favoriteStockExists={favoriteStockExists}
        // getProducts={getProducts}
        setPageNumber={setPageNumber}
        setOrderBy={setOrderBy}
        selectedManufacturers={selectedManufacturers}
        setSelectedManufacturers={setSelectedManufacturers}
      />
    );

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      resetQtyForProductList();
    });

    return () => {
      window.removeEventListener('beforeunload', () => {
        resetQtyForProductList();
      });
    };
  }, []);

  const favouriteSellerId = useMemo(() => {
    return (
      appState.buyerCurrentSuppliers?.find((x) => x.isFavourite)?.value ?? -1
    );
  }, [appState.buyerCurrentSuppliers]);

  // sortProductsByProductCode();

  return (
    <div className='container-fluid px-2 pb-2' style={{ marginTop: '1rem' }}>
      {mounted &&
        ReactDOM.createPortal(
          BuyerProductHeaderNode,
          document &&
            document.getElementsByClassName('fixed-portal-receiver')[0]
        )}

      <div className='pt-0 pb-5'>
        {isLoading ? (
          <h3 className='text-center' style={{ marginTop: '150px' }}>
            <FullScreenLoader />
          </h3>
        ) : items && items.length > 0 ? (
          appState.productsView === 'GRID' ? (
            <div className='product-grid-container'>
              {items ? (
                items?.map((page) =>
                  page.data?.map((x) => (
                    <BuyerProductListBoxGrouped
                      favoriteStockExists={favoriteStockExists}
                      key={x.productCode}
                      item={x}
                      favouriteSellerId={favouriteSellerId}
                      productDetailRoute={productDetailRoute}
                      isPricesLoading={isPricesLoading}
                    />
                  ))
                )
              ) : (
                <></>
              )}
            </div>
          ) : (
            <>
              {items ? (
                items?.map((page) =>
                  page.data?.map((x) => (
                    <BuyerProductListTileGrouped
                      favoriteStockExists={favoriteStockExists}
                      key={x.productCode}
                      item={x}
                      favouriteSellerId={favouriteSellerId}
                      productDetailRoute={productDetailRoute}
                      isPricesLoading={isPricesLoading}
                    />
                  ))
                )
              ) : (
                <></>
              )}
            </>
          )
        ) : (
          <div className='text-center' style={{ marginTop: '150px' }}>
            <h3>{t('productListTile.inf_NoProductResults1')}</h3>
            <h3>{t('productListTile.inf_NoProductResults2')}</h3>
          </div>
        )}
      </div>
    </div>
  );
};

import {
  InvoiceOverdue,
  InvoicePaid,
  InvoiceUnpaidBuyer,
} from 'assets/icons/StatusIcons';
import { BuyerDealsTagInput } from 'components/buyer/dealshome/BuyerDealsTagInput';
import { LightTooltip } from 'components/shared/LightTooltip';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { DealInvoiceModel } from 'models/buyer/deals/DealsModels';
import { DealGroupModel } from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
  ChevronDoubleDown,
  ChevronDoubleUp,
  ClockHistory,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';

const invoiceStatusMap: {
  [Overdue: string]: JSX.Element;
  Unpaid: JSX.Element;
  Paid: JSX.Element;
} = {
  Overdue: <InvoiceOverdue size={15} className='mx-1 icon-ez-red' />,
  Unpaid: <InvoiceUnpaidBuyer className='icon-ez-yellow mx-1' />,
  Paid: <InvoicePaid size={15} className='icon-ez mx-1' />,
};

type PropTypes = {
  invoice: DealInvoiceModel;
  sellerName: string;
  currentUser: LoginResponseUserModel;
  dealsDetail: DealGroupModel;
  singular?: boolean;
};

export const BuyerDealsInvoiceCard = ({
  invoice,
  sellerName,
  currentUser,
  dealsDetail,
  singular,
}: PropTypes) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { appState } = useContext(AppContext);
  const canSeePrices = useUserCanSeePrices();
  const history = useHistory();

  const { t } = useTranslation('components');

  return !singular ? (
    <Card
      style={{
        height: isOpen ? '100%' : '45px',
      }}
      className='card-deals-secondary card-deals-invoice-card bg-light border m-0 p-1'
    >
      <Card.Header className='bg-light p-1'>
        <Row>
          <Col>
            <div className='d-flex align-items-baseline'>
              <h5 className='link-toDocument me-2'>
                <Link
                  to={`/buyer/invoice/${invoice.id}`}
                  className='link-eleczap'
                >
                  {invoice.code}
                </Link>
              </h5>
              <span className='wrap-ellipsis'>{sellerName}</span>
            </div>
          </Col>

          <Col>
            {isOpen ? (
              <ChevronDoubleUp
                onClick={() => setIsOpen(false)}
                className='icon-ez cursor-pointer'
              />
            ) : (
              <ChevronDoubleDown
                onClick={() => setIsOpen(true)}
                className='icon-ez cursor-pointer'
              />
            )}{' '}
          </Col>
          <Col>
            <span
              data-tip
              data-for={`invoice-tooltip-${invoice?.id}`}
              className='float-end'
            >
              {invoiceStatusMap[invoice.status]}
            </span>

            <LightTooltip id={`invoice-tooltip-${invoice?.id}`}>
              <span>
                {invoice.status === 'Overdue' && (
                  <>{t('documents.lbl_Overdue')}</>
                )}
                {invoice.status === 'Unpaid' && (
                  <>{t('documents.lbl_Unpaid')}</>
                )}
                {invoice.status === 'Paid' && <>{t('documents.lbl_Paid')}</>}
              </span>
            </LightTooltip>
          </Col>
        </Row>
        {isOpen && (
          <Row className='mb-3'>
            <Col>
              <BuyerDealsTagInput
                currentUser={currentUser}
                dealsDetail={dealsDetail}
                type={'Inquiry'}
                t={t}
              />
            </Col>
          </Row>
        )}
        {isOpen && (
          <Row>
            <Col>
              <span data-tip data-for={`sent-on-invoice-tooltip${invoice.id}`}>
                <ClockHistory size={15} className='icon-ez-gray' />{' '}
                {dayjs(invoice.documentDate).format('DD.MM.YYYY')}
              </span>

              <LightTooltip
                place='bottom'
                id={`sent-on-invoice-tooltip${invoice.id}`}
              >
                <span>{t('documents.lbl_InvoiceDue')}</span>
              </LightTooltip>
            </Col>
            <Col className='d-flex justify-content-end align-items-end'>
              <span className='float-end'>
                {canSeePrices(
                  formatMonetaryValue(
                    invoice.currencyCode,
                    invoice.total,
                    appState
                  )
                )}
              </span>
            </Col>
          </Row>
        )}
      </Card.Header>
    </Card>
  ) : (
    <Card
      onClick={() => history.push(`/buyer/invoice/${invoice.id}`)}
      className='card-deals-secondary card-deals-invoice-card bg-light border m-0 p-1 cursor-pointer'
    >
      <Card.Header className='bg-light p-1'>
        <Row className='mb-3'>
          <Col>
            <h5 className='link-toDocument'>
              <Link
                to={`/buyer/invoice/${invoice.id}`}
                className='link-eleczap'
              >
                {invoice.code}
              </Link>
            </h5>
          </Col>
          <Col>
            <span
              data-tip
              data-for={`invoice-tooltip-${invoice?.id}`}
              className='float-end'
            >
              {invoiceStatusMap[invoice.status]}
            </span>

            <LightTooltip id={`invoice-tooltip-${invoice?.id}`}>
              <span>
                {invoice.status === 'Overdue' && (
                  <>{t('documents.lbl_Overdue')}</>
                )}
                {invoice.status === 'Unpaid' && (
                  <>{t('documents.lbl_Unpaid')}</>
                )}
                {invoice.status === 'Paid' && <>{t('documents.lbl_Paid')}</>}
              </span>
            </LightTooltip>
          </Col>
        </Row>

        <Row>
          <Col>
            <span data-tip data-for={`sent-on-invoice-tooltip${invoice.id}`}>
              <ClockHistory size={15} className='icon-ez-gray' />{' '}
              {dayjs(invoice.documentDate).format('DD.MM.YYYY')}
            </span>

            <LightTooltip
              place='bottom'
              id={`sent-on-invoice-tooltip${invoice.id}`}
            >
              <span>{t('documents.lbl_InvoiceDue')}</span>
            </LightTooltip>
          </Col>
          <Col className='d-flex justify-content-end align-items-end'>
            <span className='float-end'>
              {canSeePrices(
                formatMonetaryValue(
                  invoice.currencyCode,
                  invoice.total,
                  appState
                )
              )}
            </span>
          </Col>
        </Row>
      </Card.Header>
    </Card>
  );
};

import { useFormikHookForEditYourSharedStocks } from 'components/seller/settings/stocks/stocksModals/EditYourSharedStocks/hooksEditYourSharedStocks';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';

type PropTypes = {
  stock: StocksSharedToPartnersModel;
  currentUser: LoginResponseUserModel;
  isDisabled: boolean;
  setStocks: (values: StocksSharedToPartnersModel[]) => void;
  t: any;
};

export const EditYourSharedStocks = ({
  stock,
  setStocks,
  currentUser,
  isDisabled,
  t,
}: PropTypes) => {
  const [show, setShow] = useState(false);

  const formik = useFormikHookForEditYourSharedStocks(
    currentUser,
    stock,
    setStocks,
    setShow,
    t
  );

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant='btn btn-eleczap-noBorder' onClick={handleShow}>
        <PencilSquare />
      </Button>

      <Modal
        size='sm'
        show={show}
        onHide={handleClose}
        id='buyerUserDeactivate'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('stockSettings.lbl_PriceListCode')} {stock.stockName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3 mt-3' controlId='Form.priceformulaCode'>
              <Form.Label className='required'>
                {t('stockSettings.lbl_PriceListCode')}
              </Form.Label>
              <Form.Control
                required
                onChange={formik.handleChange}
                value={formik.values.priceformulaCode || ''}
                disabled={isDisabled}
                name='priceformulaCode'
                isInvalid={
                  formik.touched.priceformulaCode &&
                  !!formik.errors.priceformulaCode
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.priceformulaCode}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button
            disabled={isDisabled}
            variant='bnt btn-eleczap'
            onClick={formik.handleSubmit}
          >
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { useFormik } from 'formik';
import { useGetUserActiveRole } from 'hooks/useGetUserActiveRole';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { UserUpdateModel } from 'models/shared/user/UserUpdateModel';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Globe, Save } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AnyBuyerRole, AnySellerRole, useRBAC } from '../../../hooks/useRBAC';

type UserSettingsProps = {
  localizations: LocalizationModel[];
  user: UserModelById;
  handleSubmit: (data: UserUpdateModel) => Promise<void>;
  viewType: 'Seller' | 'Buyer';
};

export const UserSettings = ({
  localizations,
  user,
  handleSubmit,
  viewType,
}: UserSettingsProps) => {
  const { t } = useTranslation('components');
  const hasSellerRole = useRBAC(AnySellerRole);
  const hasBuyerRole = useRBAC(AnyBuyerRole);
  const canSwithchDefaultView = hasSellerRole && hasBuyerRole;
  const getUserActiveRole = useGetUserActiveRole();

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t('userSettings.err_FirstNameRequired'))
      .max(50, t('userSettings.err_FirstNameMax')),
    lastName: yup
      .string()
      .required(t('userSettings.err_LastNameRequired'))
      .max(50, t('userSettings.err_LastNameMax')),
    position: yup
      .string()
      .nullable()
      .max(50, t('userSettings.err_PositionMax')),
    linkedInProfile: yup
      .string()
      .nullable()
      .url(t('userSettings.err_LinkedInInvalid'))
      .max(150, t('userSettings.err_LinkedInMax')),
    phone: yup.string().nullable().max(50, t('userSettings.err_PhoneMax')),
  });

  const activeRoleName = getUserActiveRole(user, viewType);

  const formik = useFormik<UserModelById>({
    initialValues: { ...user },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedUser: UserUpdateModel = {
        firstName: values.firstName,
        lastName: values.lastName,
        defaultView: values.defaultView,
        isActive: user.isActive,
        linkedInProfile: values.linkedInProfile,
        localizationId: values.localization.id,
        phone: values.phone,
        position: values.position,
        canPlaceOrders: user.canPlaceOrders,
        canSeePrices: user.canSeePrices,
        cancelInvitation: false,
        roleName: activeRoleName,
        email: values.email,
      };
      await handleSubmit(updatedUser);
      formik.setSubmitting(false);
    },
  });

  return (
    <Card className='p-4'>
      <Card.Header>
        <h4>{t('userSettings.lbl_Header')}</h4>
      </Card.Header>
      <Card.Body>
        <div className='row'>
          <div className='col'>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Row>
                <Col className='mx-3'>
                  <Form.Group as={Row} controlId='validationFirstName'>
                    <Form.Label>{t('userSettings.lbl_FirstName')}</Form.Label>
                    <Form.Control
                      value={formik.values.firstName || ''}
                      type='input'
                      name='firstName'
                      placeholder={t('userSettings.plh_FirstName')}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.firstName && !!formik.errors.firstName
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Row} controlId='validationCompany'>
                    <Form.Label>{t('userSettings.lbl_Company')}</Form.Label>
                    <Form.Control
                      type='input'
                      name='companyName'
                      value={formik.values.companyName || ''}
                      aria-describedby='inputGroupPrepend'
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Row} controlId='validationUserEmail'>
                    <Form.Label>{t('userSettings.lbl_Email')}</Form.Label>
                    <Form.Control
                      type='input'
                      name='email'
                      value={formik.values.email || ''}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId='validationUserLinkedINAccount'
                  >
                    <Form.Label>{t('userSettings.lbl_LinkedIn')}</Form.Label>
                    <Form.Control
                      type='input'
                      name='linkedInProfile'
                      value={formik.values.linkedInProfile || ''}
                      placeholder={t('userSettings.plh_LinkedIn')}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.linkedInProfile &&
                        !!formik.errors.linkedInProfile
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.linkedInProfile}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId='validationUserCurrentInterfaceLanguage'
                  >
                    <Form.Label column sm={12}>
                      <h5>
                        {t('userSettings.lbl_Language')} <Globe />
                      </h5>
                      <Form.Select
                        aria-label='Current active language'
                        name='localization.id'
                        value={formik.values.localization?.id}
                        onChange={formik.handleChange}
                      >
                        {localizations.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.cultureLongName}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Label>
                  </Form.Group>

                  {canSwithchDefaultView && (
                    <Form.Group
                      as={Row}
                      controlId='validationUserDefaultLoginRole'
                    >
                      <Form.Label as='legend' column sm={5}>
                        <h5>{t('userSettings.lbl_DefaultView')}</h5>
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          type='radio'
                          label={t('userSettings.lbl_Buyer')}
                          name='defaultView'
                          value='Buyer'
                          id='formBuyer'
                          onChange={formik.handleChange}
                          checked={formik.values.defaultView === 'Buyer'}
                        />
                        <Form.Check
                          type='radio'
                          label={t('userSettings.lbl_Seller')}
                          name='defaultView'
                          value='Seller'
                          id='formSeller'
                          onChange={formik.handleChange}
                          checked={formik.values.defaultView === 'Seller'}
                        />
                      </Col>
                    </Form.Group>
                  )}
                </Col>
                <Col className='mx-3'>
                  <Form.Group as={Row} controlId='validationLastName'>
                    <Form.Label>{t('userSettings.lbl_LastName')}</Form.Label>
                    <Form.Control
                      name='lastName'
                      value={formik.values.lastName || ''}
                      type='input'
                      placeholder={t('userSettings.plh_LastName')}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.lastName && !!formik.errors.lastName
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Row} controlId='validationUserPosition'>
                    <Form.Label>{t('userSettings.lbl_Position')}</Form.Label>
                    <Form.Control
                      name='position'
                      value={formik.values.position || ''}
                      type='input'
                      placeholder={t('userSettings.plh_Position')}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.position && !!formik.errors.position
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.position}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Row} controlId='validationUserPhoneNumber'>
                    <Form.Label>{t('userSettings.lbl_Phone')}</Form.Label>
                    <Form.Control
                      name='phone'
                      value={formik.values.phone || ''}
                      type='input'
                      placeholder={t('userSettings.plh_Phone')}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.phone && !!formik.errors.phone}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Button
                type='submit'
                variant='btn btn-eleczap mt-3'
                className='float-end'
                disabled={formik.isSubmitting}
              >
                <Save className='mb-1 me-1' />
                {t('common.btn_Save')}
              </Button>
            </Form>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

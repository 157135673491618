import { Button } from 'react-bootstrap';

export const ErrorFallback = () => {
  const onTryAgain = () => {
    localStorage.clear();
    window && window.location.reload();
  };

  return (
    <main className='main d-flex justify-content-center w-100'>
      <div className='container d-flex flex-column'>
        <div className='row h-100'>
          <div className='col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100'>
            <div className='d-table-cell align-middle'>
              <div className='text-center'>
                <h1 className='display-1 fw-bold'>Sorry :(</h1>
                <p className='h1'>An unexpected error occured.</p>
                <Button onClick={onTryAgain} className='btn btn-eleczap btn-lg'>
                  Click here to clear cache and try again!
                </Button>

                <p className='h2 fw-normal mt-3 mb-4'>
                  Please try reloading the page. If the problem persists, please
                  contact customer support.{' '}
                  <a
                    className='btn-link-bold'
                    href='mailto:support@eleczap.com'
                  >
                    support@eleczap.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

import { GlobalNotifications } from 'components/buyer/settings/buyerNotifications/GlobalNotifications';
import { PersonalNotifications } from 'components/buyer/settings/buyerNotifications/PersonalNotifications';
import { AppContext } from 'contexts/user/AppContext';
import { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const BuyerNotifications = () => {
  const { t } = useTranslation('components');

  const { appState } = useContext(AppContext);

  const isAdmin =
    appState.userProfile?.roleNames.includes('BuyerAdministrator');

  return (
    <Card className='p-4'>
      <Card.Header>
        <h4>{t('userSettings.lbl_Notifications')}</h4>
      </Card.Header>
      <Card.Body>
        <Row className='justify-content-between'>
          {/* Global notifications */}
          {isAdmin && <GlobalNotifications />}

          {/* Personal notifications */}
          <PersonalNotifications />
          <Col lg={2} />
        </Row>
      </Card.Body>
    </Card>
  );
};

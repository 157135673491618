import { ApplicationSettingsApi } from 'api/ApplicationSettingsApi';
import { SellerApi } from 'api/SellerApi';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { SellerProductSearch } from 'layout/SellerProductSearch';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Form, Nav, Navbar, Row } from 'react-bootstrap';
import { Globe } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../contexts/user/AppContext';
import { SellerCustomersDropdownList } from './SellerCustomersDropDownList';
import './sellerHeader.css';
import { SellerSettingsDropdownButton } from './SellerSettingsDropdownButton';

type PropTypes = {
  requiresNoAuth?: boolean;
  localizationId?: number;
};

export const SellerHeader = ({
  requiresNoAuth = false,
  localizationId,
}: PropTypes) => {
  const [width, setWidth] = useState<number>(window && window.innerWidth);
  const [localizations, setLocalizations] = useState<LocalizationModel[]>([]);
  const [currentLocalizationId, setCurrentLocalizationId] = useState<number>(
    localizationId ?? 1
  );

  const { appState } = useContext(AppContext);
  const { t, i18n } = useTranslation('components');
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const selectedCustomerId = appState.sellerCurrentCustomerId;
  const pageNumber = 1;
  const orderBy = ''; // No ordering in 1st version
  const maxResults = 5;
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const navigate = useHistory();

  const handleSearch = async (searchString: string) => {
    const productsResponse = await SellerApi.findProducts(
      currentUser.companyId,
      selectedCustomerId,
      searchString,
      pageNumber,
      orderBy,
      maxResults,
      userLocaleId,
      true
    );
    return productsResponse;
  };

  const handleSearchDebounced = AwesomeDebouncePromise(handleSearch, 400);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (localizationId) {
      const selectedLng = localizations.find((l) => l.id === localizationId);

      setCurrentLocalizationId(localizationId);

      i18n.changeLanguage(selectedLng?.cultureName?.slice(0, 2));
    }
  }, [i18n, localizationId, localizations]);

  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedLng = localizations.find(
      (l) => l.id === parseInt(event.target.value)
    );
    setCurrentLocalizationId(parseInt(event.target.value));

    i18n.changeLanguage(selectedLng?.cultureName?.slice(0, 2));
  };

  /* Localization dropdown data */
  useEffect(() => {
    const getLocalizations = async () => {
      const localizations = await ApplicationSettingsApi.getLocalizations();
      setLocalizations(localizations);
    };
    getLocalizations();
  }, []);

  return width > 990 ? (
    <Navbar className='sticky-top navbar-seller navbar-light navbar-bg d-print-none py-0 ms-2'>
      <div className='container-xxl seller-header-container-xxl align-items-center'>
        {requiresNoAuth ? (
          <Form.Group
            className='float-end'
            as={Row}
            controlId='validationUserCurrentInterfaceLanguage'
          >
            {' '}
            <Form.Label column sm={12}>
              <h5>
                <Globe className='icon-ez me-2' />{' '}
                {t('userSettings.lbl_Language')}
              </h5>
              <Form.Select
                aria-label='Current active language'
                name='localization.id'
                value={currentLocalizationId}
                onChange={handleLanguageChange}
              >
                {localizations.map((e, key) => {
                  return (
                    <option key={key} value={e.id}>
                      {e.cultureLongName}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Label>
          </Form.Group>
        ) : (
          <>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              {!(
                navigate.location.pathname.includes('settings') ||
                navigate.location.pathname.includes('customers')
              ) && <SellerCustomersDropdownList />}

              <Nav className='ms-3 align-items-center nav-container'>
                <SellerProductSearch
                  handleSearch={handleSearchDebounced}
                  searchRoute='/seller/search'
                  detailsRoute='/seller/product-details'
                />
              </Nav>

              <SellerSettingsDropdownButton
                initials={appState.userProfile?.initials}
                profileImage={appState.userProfile?.profileImage}
              />
            </Navbar.Collapse>
          </>
        )}
      </div>
    </Navbar>
  ) : (
    <></>
  );
};

import { BuyerProductClassWithSubclassesModel } from 'models/buyer/product/BuyerProductClassWithSubclassesModel';
import { SellerProductClassWithSubclassesModel } from 'models/seller/product/SellerProductClassWithSubclassesModel';
import { Col, Row } from 'react-bootstrap';
import { ProductClassWithProductsItem } from './ProductClassWithProductsItem';

type PropsType = {
  item:
    | SellerProductClassWithSubclassesModel
    | BuyerProductClassWithSubclassesModel;
  productListRoute: string;
  imageReadonly: boolean;
  handleProductClassImageUpload: (productClassId: number) => void;
};

export const ProductClassWithProducts = ({
  item,
  productListRoute,
  imageReadonly,
  handleProductClassImageUpload
}: PropsType) => {
  return (
    <section
      className='product-class-section p-3 mt-1'
      id={item.code}
      style={{ minHeight: '210px' }}
    >
      <h2>{item.name}</h2>
      <hr className='icon-ez' />
      <Row>
        {item.subClasses &&
          item.subClasses.map((subClass) => (
            <Col
              className={`product-class-section-child ${subClass.code} p-3 mx-2 mb-3`}
            >
              <ProductClassWithProductsItem
                handleProductClassImageUpload={handleProductClassImageUpload}
                item={subClass}
                productListRoute={productListRoute}
                imageReadonly={imageReadonly}
              />
            </Col>
          ))}
      </Row>
    </section>
  );
};

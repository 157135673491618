import { Star, StarFill } from 'react-bootstrap-icons';

type PropTypes = {
  isFavorite: boolean;
  handleAdd: () => Promise<void>;
  handleRemove: () => Promise<void>;
};

export const FavoriteProductSwitch = ({
  isFavorite,
  handleAdd,
  handleRemove,
}: PropTypes) => {
  return isFavorite ? (
    <StarFill
      style={{ cursor: 'pointer' }}
      fontSize='20px'
      className='icon-ez'
      onClick={() => handleRemove()}
    />
  ) : (
    <Star
      style={{ cursor: 'pointer' }}
      fontSize='20px'
      className='icon-ez'
      onClick={() => handleAdd()}
    />
  );
};

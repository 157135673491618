import { IdentityApi } from 'api/IdentityApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useFormik } from 'formik';
import { RequestPasswordResetModel } from 'models/identity/RequestPasswordResetModel';
import { Button, Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import '../../css/light.css';
import '../../css/site.css';

export const ForgotPassword = (): JSX.Element => {
  const { t } = useTranslation('components');
  const history = useHistory();

  const validationSchema = yup.object({
    username: yup
      .string()
      .required(t('userSettings.err_EmailRequired'))
      .email(t('userSettings.err_EmailInvalid'))
      .max(50, t('userSettings.err_EmailMax')),
  });

  const initialValues: RequestPasswordResetModel = {
    username: '',
    passwordResetUrlTemplate: `${
      window && window.location.origin
    }/reset-password?resetToken={resetToken}`,
  };

  const formik = useFormik<RequestPasswordResetModel>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values: RequestPasswordResetModel) => {
      const requestPasswordReset: RequestPasswordResetModel = {
        username: values.username,
        passwordResetUrlTemplate: values.passwordResetUrlTemplate,
      };
      await handleSubmit(requestPasswordReset);
      formik.setSubmitting(false);
    },
  });

  const handleSubmit = async (
    requestPasswordReset: RequestPasswordResetModel
  ): Promise<void> => {
    toast.dismiss();
    await IdentityApi.requestPasswordReset(requestPasswordReset);

    history.replace('/login');
    //toast.success(t('forgotPassword.inf_PasswordResetRequestSentToEmail'));
  };

  return (
    <div className='wrapper'>
      <div className='main d-flex justify-content-center w-100'>
        <div className='content d-flex p-0'>
          <div className='container d-flex flex-column'>
            <div className='row h-100'>
              <div className='col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100'>
                <div className='d-table-cell align-middle'>
                  <div className='text-center mt-4'>
                    <h1>{t('forgotPassword.hd_ForgotPassword')}</h1>
                    <p className='lead'>
                      {t('forgotPassword.lbl_EmailToResetPassword')}
                    </p>
                  </div>

                  <Card>
                    <Card.Body>
                      <div className='m-sm-4'>
                        <Form onSubmit={formik.handleSubmit}>
                          <Form.Group
                            className='mb-3 form-control-lg'
                            controlId='formEmail'
                          >
                            <Form.Label>
                              {t('userSettings.lbl_Email')}
                            </Form.Label>
                            <Form.Control
                              name='username'
                              value={formik.values.username}
                              onChange={formik.handleChange}
                              type='email'
                              placeholder='Enter your email'
                              isInvalid={
                                formik.touched.username &&
                                !!formik.errors.username
                              }
                            />
                            <Form.Control.Feedback type='invalid'>
                              {formik.errors.username}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <div className='text-center'>
                            <Button
                              className='btn btn-primary btn-lg'
                              type='submit'
                              disabled={formik.isSubmitting}
                            >
                              {t('forgotPassword.btn_ResetPassword')}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

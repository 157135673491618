import { BuyerApi } from 'api/BuyerApi';
import { InquiryGroupSupplierSelectorOption } from 'components/buyer/documents/InquiryGroupSupplierSelector/InquiryGroupSupplierSelectorOption';
import { AppContext } from 'contexts/user/AppContext';
import { useListenElementResize } from 'hooks/useListenElementResize';
import {
  InquiryGroupAdditionalInquiriesResponseModel,
  ProjectInquiryAddRemoveSupplierModel,
} from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Badge,
  Button,
  Col,
  FormControl,
  InputGroup,
  ListGroup,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import {
  CheckCircleFill,
  PeopleFill,
  Search,
  XCircleFill,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  suppliers: ProjectInquiryAddRemoveSupplierModel[];
  projectId: number;
  dealGroupId: number;
  refreshData: () => Promise<void>;
};

export const InquiryGroupSupplierSelector = ({
  suppliers,
  projectId,
  dealGroupId,
  refreshData,
}: PropTypes) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [sentDataResponse, setSentDataResponse] = useState<
    InquiryGroupAdditionalInquiriesResponseModel[]
  >([]);

  const [showErrors, setShowErrors] = useState<boolean>(false);

  const [show, setShow] = useState<boolean>(false);

  const [heightOfGridContainer, setHeightOfGridContainer] = useState<number>(0);
  const [selectedSuppliers, setSelectedSuppliers] = useState<
    ProjectInquiryAddRemoveSupplierModel[]
  >([]);

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);

  useEffect(() => {
    setSelectedSuppliers(suppliers);
  }, [suppliers]);

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  const filterSuppliers = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(event.target.value);
  };

  useListenElementResize('.project-inquiry-grid-table', {
    setHeightOfElement: setHeightOfGridContainer,
  });

  const handleSupplierAdd = (
    selectedSupplier: ProjectInquiryAddRemoveSupplierModel
  ) => {
    const clonedSelected = [...selectedSuppliers];

    clonedSelected.push(selectedSupplier);

    setSelectedSuppliers(clonedSelected);
  };

  const handleSupplierRemove = (
    selectedSupplier: ProjectInquiryAddRemoveSupplierModel
  ) => {
    const clonedSelected = [...selectedSuppliers];

    const targetIndex = clonedSelected.findIndex(
      (supplier) =>
        supplier.sellerCompanyId === selectedSupplier.sellerCompanyId
    );

    targetIndex !== -1 && clonedSelected.splice(targetIndex, 1);

    targetIndex !== -1 && setSelectedSuppliers(clonedSelected);
  };

  const handleSupplierContactUpdate = (
    selectedSupplier: ProjectInquiryAddRemoveSupplierModel
  ) => {
    const clonedSelected = [...selectedSuppliers];

    const targetIndex = clonedSelected.findIndex(
      (supplier) =>
        supplier.sellerCompanyId === selectedSupplier.sellerCompanyId
    );

    if (targetIndex !== -1) {
      clonedSelected[targetIndex].externalSellerContactId =
        selectedSupplier.externalSellerContactId;

      setSelectedSuppliers(clonedSelected);
    }
  };

  const handleSendAdditionalInquiries = async () => {
    setIsLoading(true);
    if (selectedSuppliers?.length > 0) {
      const filteredSelected: ProjectInquiryAddRemoveSupplierModel[] = [];

      selectedSuppliers.forEach((selected) => {
        const index = suppliers.findIndex(
          (offerSuppliers) =>
            offerSuppliers.sellerCompanyId === selected.sellerCompanyId
        );

        if (index === -1) {
          filteredSelected.push(selected);
        }
      });

      const response = await BuyerApi.buyerDealsGroup.sendAdditionalInquiries(
        currentUser.companyId,
        dealGroupId,
        filteredSelected
      );

      setSentDataResponse(response);

      setShow(false);

      setShowErrors(true);
      setIsLoading(false);
    }
  };

  const handleErrorsClose = async () => {
    await refreshData();

    setIsLoading(false);
    handleHide();
    setShowErrors(false);
    setShow(false);
  };

  const errorsNode = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col>
            <h5>{t('companySettings.lbl_CompanyName')}</h5>
          </Col>
          <Col>
            <h5>{t('common.lbl_Error')}</h5>
          </Col>
          <Col>
            <h5>{t('projectSettings.lbl_SupplierInquiryCreated')}</h5>
          </Col>
          <Col>
            <h5>{t('projectSettings.lbl_SupplierInquirySent')}</h5>
          </Col>
        </Row>
        {sentDataResponse?.map((error, index) => (
          <Row className='border' key={index}>
            <Col>
              {
                appState.buyerCurrentSuppliers?.find(
                  (supplier) => supplier.value === error.id
                )?.name
              }
            </Col>
            <Col>
              {error.errorCode
                ? t(`error.${error.errorCode}`, { ns: 'server' })
                : null}
            </Col>
            <Col>
              {error.errorCode === null ? (
                <CheckCircleFill className='icon-ez-green' />
              ) : (
                <XCircleFill className='icon-ez-red' />
              )}
            </Col>
            <Col>
              {!error.sendingFailed ? (
                <CheckCircleFill className='icon-ez-green' />
              ) : (
                <XCircleFill className='icon-ez-red' />
              )}
            </Col>
          </Row>
        ))}
      </>
    );
  };

  return (
    <>
      <OverlayTrigger
        placement='bottom'
        trigger={'hover'}
        overlay={
          <Tooltip>{t('projectSettings.btn_ManageInquirySuppliers')}</Tooltip>
        }
      >
        <Button
          type={'button'}
          onClick={handleShow}
          variant='btn btn-eleczap'
          className='position-relative'
        >
          <PeopleFill size={20} />

          <Badge
            pill
            style={{ position: 'absolute', right: 0, top: '-5px' }}
            bg='success'
          >
            {suppliers?.length}
          </Badge>
        </Button>
      </OverlayTrigger>

      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <h3>{t('projectSettings.btn_ManageInquirySuppliers')}</h3>
        </Modal.Header>

        <Modal.Body style={{ overflowY: 'auto', maxHeight: '50vh' }}>
          <div className='project-suppliers-selector-container rounded  position-relative'>
            <div className='px-3 py-3'>
              <h4 className='text-center'>
                {t('projectSettings.lbl_SendInquiryTo')}
              </h4>
            </div>

            <InputGroup
              style={{ width: '200px', margin: '0 auto' }}
              className={`border rounded py-0 my-0 mb-2 ${
                isFocused ? 'custom-focused' : ''
              }`}
            >
              <InputGroup.Text className='btn' id='search-addon'>
                <Search />
              </InputGroup.Text>
              <FormControl
                onFocus={() => setIsFocused(true)}
                className='projects-input my-0 py-0 px-3 border-0'
                onChange={filterSuppliers}
                onBlur={() => setIsFocused(false)}
                // onBlur={handleBlur}
              />
            </InputGroup>

            <ListGroup
              style={{
                minHeight: '200px',
                maxHeight: heightOfGridContainer - 30,
              }}
              variant='flush'
              className='project-suppliers-selector'
            >
              {appState?.buyerCurrentSuppliers
                ?.filter((supplier) =>
                  filterQuery !== ''
                    ? supplier.name
                        .toLowerCase()
                        .includes(filterQuery.toLowerCase()) &&
                      supplier.isActive
                    : supplier.isActive
                )
                .sort((a, b) =>
                  a.isSelected === b.isSelected ? 0 : a.isSelected ? -1 : 1
                )
                .map((supplier) => (
                  <InquiryGroupSupplierSelectorOption
                    currentUser={currentUser}
                    externalContactId={
                      suppliers?.find(
                        (sup) => sup.sellerCompanyId === supplier.value
                      )?.externalSellerContactId ?? null
                    }
                    isSelected={
                      selectedSuppliers?.findIndex(
                        (sup) => sup.sellerCompanyId === supplier.value
                      ) !== -1
                    }
                    isDisabled={
                      suppliers?.findIndex(
                        (sup) => sup.sellerCompanyId === supplier.value
                      ) !== -1
                    }
                    supplier={supplier}
                    handleSupplierAdd={handleSupplierAdd}
                    handleSupplierRemove={handleSupplierRemove}
                    handleSupplierContactUpdate={handleSupplierContactUpdate}
                  />
                ))}
            </ListGroup>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button disabled={isLoading} variant='secondary' onClick={handleHide}>
            {t('common.btn_Cancel')}
          </Button>
          <Button
            disabled={isLoading}
            variant='btn btn-eleczap'
            onClick={handleSendAdditionalInquiries}
          >
            {t('common.btn_Ok')}{' '}
            {isLoading && <Spinner animation='border' size='sm' />}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrors} onHide={handleErrorsClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorsNode()}</Modal.Body>
        <Button variant='btn btn-eleczap' onClick={handleErrorsClose}>
          {t('common.btn_Close')}
        </Button>
      </Modal>
    </>
  );
};

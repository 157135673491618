import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { useGetAvailableCurrencies } from 'hooks/useGetAvailableCurrencies';
import { ExternalSellerImportPricelistResponseModel } from 'models/buyer/suppliers/ExternalSupplierModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { CurrencyModel } from 'models/shared/currency/CurrencyModel';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import { FileEarmarkArrowUp } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  externalSellerId: number;
  refreshSuppliers: () => Promise<void>;
  currencyCode: string;
};

export const ExternalSupplierImportPriceList = ({
  externalSellerId,
  refreshSuppliers,
  currencyCode,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currencies, setCurrencies] = useState<CurrencyModel[]>([]);

  const [file, setFile] = useState<File | null>(null);
  const [importMethod, setImportMethod] = useState<string>(
    'OverwriteExistingAddNew'
  );
  const [ignoreFirstRow, setIgnoreFirstRow] = useState<boolean>(false);

  const [importComplete, setImportComplete] = useState<boolean>(false);

  const [dragging, setDragging] = useState(false);

  const [importedDataResponse, setImportedDataResponse] =
    useState<ExternalSellerImportPricelistResponseModel>(
      {} as ExternalSellerImportPricelistResponseModel
    );

  const [showErrors, setShowErrors] = useState<boolean>(false);

  const { t } = useTranslation(['components', 'server']);
  const { appState } = useContext(AppContext);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const fileInputRef = useRef<HTMLInputElement>(null);

  useGetAvailableCurrencies(setCurrencies);

  const resetModal = () => {
    setIsLoading(false);
    setFile(null);
    setImportMethod('OverwriteExistingAddNew');
    setImportComplete(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    resetModal();
  };

  const handleImportPricelist = async () => {
    setIsLoading(true);

    const data = new FormData();

    if (file === null || appState?.userProfile?.localization?.id === undefined)
      return null;

    data.append('File', file);
    data.append('CurrencyCode', currencyCode);
    data.append('ImportMethod', importMethod);
    data.append(
      'LocalizationId',
      appState?.userProfile?.localization?.id?.toString()
    );
    data.append('IgnoreFirstRow', ignoreFirstRow.toString());
    try {
      const response = await BuyerApi.importPricelistExternalSeller(
        currentUser.companyId,
        externalSellerId,
        data
      );

      if (response.errors === null) {
        setImportComplete(true);
        await refreshSuppliers();
        setIsLoading(false);
      } else {
        toast.error(t('externalSupplierPricelist.err_ImportAborted'));
        setShowErrors(true);
        setIsLoading(false);
      }

      setImportedDataResponse(response);
      setFile(null);
      setIsLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (e) {
      setIsLoading(false);
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        setFile(selectedFile);
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    file && setFile(file);
  };

  const errorsNode = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col>
            <h5>#</h5>
          </Col>
          <Col>
            <h5>{t('productListHeader.lbl_ProductCode')}</h5>
          </Col>
          <Col>
            <h5>{t('common.lbl_Error')}</h5>
          </Col>
        </Row>
        {importedDataResponse.errors?.map((error, index) => (
          <Row className='border' key={index}>
            <Col>{error.rowNo}</Col>
            <Col>{error.productCode}</Col>
            <Col>{t(`error.${error.errorCode}`, { ns: 'server' })}</Col>
          </Row>
        ))}
      </>
    );
  };

  const handleErrorsClose = () => {
    setShowErrors(false);
  };

  return (
    <>
      <FileEarmarkArrowUp
        className='cursor-pointer'
        size={20}
        onClick={handleShow}
      />

      <Modal show={showErrors} onHide={handleErrorsClose}>
        <Modal.Header>
          <Modal.Title>
            {t('externalSupplierPricelist.err_ImportAborted')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorsNode()}</Modal.Body>
        <Button variant='btn btn-eleczap' onClick={handleErrorsClose}>
          {t('common.btn_Close')}
        </Button>
      </Modal>

      <Modal
        show={show}
        size='sm'
        id='add-external-supplier-pricelist'
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {importComplete
              ? t('externalSupplierPricelist.hd_ImportComplete')
              : t('externalSupplierPricelist.hd_ImportPricelist')}
          </Modal.Title>
        </Modal.Header>

        {importComplete ? (
          <Modal.Body className='p-4'>
            <p>
              {t('externalSupplierPricelist.inf_ImportComplete', {
                countNew: importedDataResponse.newProductsCount,
                countExisting: importedDataResponse.overWrittenProductsCount,
              })}
            </p>
          </Modal.Body>
        ) : (
          <Modal.Body
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className='p-4'
          >
            <p>{t('externalSupplierPricelist.inf_ImportProductsPart1')}</p>

            <p>{t('externalSupplierPricelist.inf_ImportProductsPart2')}</p>

            <a href='/templates/ElecZap_PriceListImport_EN.xlsx'>
              <Button className='mb-3' variant='btn btn-eleczap'>
                {t('externalSupplierPricelist.btn_DownloadTemplate')}
              </Button>
            </a>

            <Form noValidate>
              <FormGroup className='mb-2' controlId='validationFile'>
                <Form.Label className='required'>
                  {t('externalSupplierPricelist.lbl_SelectPricelistFile')}
                </Form.Label>
                <input
                  onChange={handleFileChange}
                  accept='.xlsx'
                  type='file'
                  style={{ display: 'none' }}
                  name='file'
                  id='file'
                  ref={fileInputRef}
                />

                <br />
                <div className='d-flex align-items-center'>
                  <Button
                    variant='btn btn-eleczap'
                    onClick={() => fileInputRef.current?.click()}
                  >
                    {t('common.btn_ChooseFile')}
                  </Button>
                  <span className='ms-2'>{file?.name}</span>
                </div>
              </FormGroup>

              <Form.Group className='mb-2' controlId='validationCurrency'>
                <Form.Label>
                  {t('externalSupplierPricelist.lbl_PricelistCurrency')}
                </Form.Label>
                <Form.Select
                  name='currency'
                  required
                  value={currencyCode}
                  disabled
                  // onChange={(event) => setCurrencyCode(event.target.value)}
                >
                  {currencies &&
                    currencies.map((currency) => (
                      <option value={currency.code}>
                        {currency.symbol} {currency.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className='mb-2' controlId='validationImportMethod'>
                <Form.Label>
                  {t('externalSupplierPricelist.lbl_ImportMethod')}
                </Form.Label>
                <Form.Select
                  name='importMethod'
                  required
                  value={importMethod}
                  onChange={(event) => setImportMethod(event.target.value)}
                >
                  <option value='OverwriteExistingAddNew'>
                    {t(
                      'externalSupplierPricelist.lbl_OverwriteExistingAddNewProduct'
                    )}
                  </option>
                  <option value='OverwriteExistingSkipNew'>
                    {t(
                      'externalSupplierPricelist.lbl_OverwriteExistingSkipNewProduct'
                    )}
                  </option>
                  <option value='IgnoreExistingAddNew'>
                    {t(
                      'externalSupplierPricelist.lbl_IgnoreExistingOnlyNewProduct'
                    )}
                  </option>
                </Form.Select>
              </Form.Group>

              <FormGroup className='mt-3' controlId='validationIgnoreFirstRow'>
                <Form.Check
                  label={t('externalSupplierPricelist.lbl_IgnoreFirstRow')}
                  type='switch'
                  onChange={(event) => setIgnoreFirstRow(event.target.checked)}
                  name='file'
                />
              </FormGroup>
            </Form>
          </Modal.Body>
        )}

        {importComplete ? (
          <Modal.Footer>
            <Button variant='btn btn-eleczap' onClick={handleClose}>
              {t('common.btn_Close')}
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='secondary'
              onClick={handleClose}
            >
              {t('common.btn_Cancel')}
            </Button>
            <Button
              disabled={file === null || isLoading}
              variant='btn btn-eleczap'
              onClick={handleImportPricelist}
            >
              {t('common.btn_Ok')}{' '}
              {isLoading && <Spinner animation='border' size='sm' />}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

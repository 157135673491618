import { IdentityApi } from 'api/IdentityApi';
import { useFormik } from 'formik';
import { UserOnboardingPasswordModel } from 'models/shared/userOnboarding/UserOnboardingPasswordModel';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* Formik init */
export const useFormikHookForOnboardingPassword = (
  invitationToken: string,
  t: any
): any => {
  const handleSubmit = async (
    values: UserOnboardingPasswordModel
  ): Promise<void> => {
    toast.dismiss();
    await IdentityApi.completeUserOnboarding(invitationToken, values);
    toast.info(t('account.inf_UserSettingsSaved'));
  };

  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .required(t('changePassword.err_PasswordRequired'))
      .max(50, t('common.err_ValueMax50')),

    confirmNewPassword: yup
      .string()
      .required(t('changePassword.err_ConfirmPasswordRequired'))
      .max(50, t('common.err_ValueMax50')),
  });

  const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
  };

  const formik = useFormik<UserOnboardingPasswordModel>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedPassword: UserOnboardingPasswordModel = {
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
      };
      await handleSubmit(updatedPassword);
      window.location.replace(window && window.location.origin);
      formik.setSubmitting(false);
    },
  });

  return formik;
};

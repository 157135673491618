import { DealTagModel } from 'models/seller/deals/DealsModels';
import { Badge } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';

type PropTypes = {
  tag: DealTagModel;
  handleUsedClick: (tag: DealTagModel) => void;
};

export const SellerDealsFilterTag = ({ tag, handleUsedClick }: PropTypes) => {
  const handleDeleteTag = () => {
    handleUsedClick(tag);
  };

  return (
    <Badge
      onClick={handleDeleteTag}
      pill
      className='deals-pill-inverse mx-1 px-2 cursor-pointer'
    >
      {tag.name} <X />
    </Badge>
  );
};

import { CompanyApi } from 'api/CompanyApi';
import { UserNewSetRole } from 'components/shared/settings/Users/UserNewSetRole';
import { AnyAdminRole, useRBAC } from 'hooks/useRBAC';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { CreateUserInvitationModel } from 'models/shared/user/CreateUserInvitationModel';
import { ChangeEvent, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type DataRow = {
  email: string;
  isSeller: boolean;
  isBuyer: boolean;
};

type PropTypes = {
  currentUser: LoginResponseUserModel;
  viewType: 'Seller' | 'Buyer';
  localizations: LocalizationModel[];
  defaultLocalizationId: number;
};

export const UserNew = ({
  currentUser,
  viewType,
  localizations,
  defaultLocalizationId,
}: PropTypes): JSX.Element => {
  const { t } = useTranslation('components');
  const [show, setShow] = useState(false);
  const [localizationId, setLocalizationId] = useState<number>(0);
  const [rawEmails, setRawEmails] = useState<string>('');
  const [screenIndex, setScreenIndex] = useState<0 | 1>(0);
  const [emails, setEmails] = useState<string[]>([]);
  const [emailsMap, setEmailsMap] = useState<DataRow[]>([]);
  const hasRightsToEdit = useRBAC(AnyAdminRole);
  let emailsValid = false;

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRawEmailsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setRawEmails(value);
  };

  const getValidatedEmails = (): string[] => {
    let cleanEmails = rawEmails.split(/\s*,\s*|\s+/);
    cleanEmails = cleanEmails.filter((item) => item); //Remove empty values caused from whitespaces

    let emails: string[] = [];

    let invalidEmailFound = false;
    cleanEmails.forEach((email) => {
      const normalizedEmail = email.trim().toLowerCase();

      if (!emailRegex.test(normalizedEmail)) {
        toast.error('Entered value contains invalid email.');
        invalidEmailFound = true;
        return;
      }
      emails.push(normalizedEmail);
    });

    emailsValid = invalidEmailFound === false && cleanEmails.length > 0;

    return emails;
  };

  useEffect(() => {
    let prepData: DataRow[] = [];

    emails.forEach((email) => {
      prepData.push({
        email,
        isSeller: viewType === 'Seller',
        isBuyer: viewType === 'Buyer',
      });
    });

    setEmailsMap(prepData);
  }, [emails, viewType]);

  const handleInviteUsers = async (): Promise<void> => {
    // Invited users get Regular User role, after onboarding, it can be changed to Administrator

    const selectedLocalizationId =
      localizationId === 0 ? defaultLocalizationId : localizationId;

    await Promise.all(
      emailsMap.map((email) => {
        const invitation: CreateUserInvitationModel = {
          userName: email.email,
          isSellerRegularUser: email.isSeller,
          isBuyerRegularUser: email.isBuyer,
          localizationId: selectedLocalizationId,
          activationUrlTemplate: `${
            window && window.location.origin
          }/user-onboarding?invitationToken={invitationToken}`,
        };

        return CompanyApi.createUserInvitation(
          currentUser.companyId,
          invitation
        );
      })
    );

    toast.success(t('userSettings.inf_InvitationSent'));
    setRawEmails('');
    handleClose();
  };

  const handleLocalizationChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setLocalizationId(Number.parseInt(event.target.value));
  };

  const goToAssignRolesScreen = () => {
    const sanitizedEmails = getValidatedEmails();
    if (!emailsValid) {
      return;
    }

    setEmails(sanitizedEmails);
    setScreenIndex(1);
  };

  const goToInviteUsersScreen = () => {
    setScreenIndex(0);
  };

  return (
    <>
      <Button
        variant='btn btn-eleczap me-3'
        disabled={!hasRightsToEdit}
        onClick={handleShow}
      >
        {t('userSettings.lbl_AddNewUsers')}
      </Button>

      <Modal show={show} onHide={handleClose} id='AddNewUser'>
        <Modal.Header closeButton>
          <Modal.Title>{t('userSettings.lbl_AddNewUsers')}</Modal.Title>
        </Modal.Header>
        {screenIndex === 0 ? (
          <Modal.Body className='p-4'>
            {t('userSettings.lbl_UserImportDescription')}
            <Form className='mt-3'>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlTextarea1'
              >
                <Form.Control
                  placeholder={t('userSettings.plh_UserEmailAddresses')}
                  as='textarea'
                  onChange={handleRawEmailsChange}
                  rows={3}
                  value={rawEmails}
                />
              </Form.Group>
              <Form.Group
                as={Row}
                controlId='validationUserCurrentInterfaceLanguage'
              >
                <Form.Label column sm={12}>
                  <h5>{t('userSettings.lbl_Language')}</h5>
                  <Form.Select
                    aria-label='Current active language'
                    name='localization.id'
                    value={
                      localizationId === 0
                        ? defaultLocalizationId
                        : localizationId
                    }
                    onChange={handleLocalizationChange}
                  >
                    {localizations.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.cultureLongName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Label>
              </Form.Group>
            </Form>
          </Modal.Body>
        ) : (
          <UserNewSetRole setEmailsMap={setEmailsMap} emailsMap={emailsMap} />
        )}
        {screenIndex === 0 ? (
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              {t('common.btn_Close')}
            </Button>
            <Button
              className='d-flex align-items-center'
              disabled={!hasRightsToEdit}
              variant='btn btn-eleczap me-3'
              onClick={goToAssignRolesScreen}
            >
              {t('common.btn_Next')} <CaretRightFill className='ms-2' />
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              className='d-flex align-items-center'
              variant='secondary'
              onClick={goToInviteUsersScreen}
            >
              <CaretLeftFill className='me-2' /> {t('common.btn_Back')}
            </Button>
            <Button
              disabled={!hasRightsToEdit}
              variant='btn btn-eleczap me-3'
              onClick={handleInviteUsers}
            >
              {t('userSettings.lbl_AddNewUsers')}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

import { useQuery } from '@tanstack/react-query';
import { BuyerApi } from 'api/BuyerApi';
import { LogoLoader } from 'components/shared/Loaders/LogoLoader';
import { ProductBreadcrumb } from 'components/shared/products/ProductBreadcrumb';
import 'components/shared/products/ProductClasses/ProductClasses.css';
import { ProductClassesList } from 'components/shared/products/ProductClasses/ProductClassesList';
import { ProductClassesQuickMenu } from 'components/shared/products/ProductClasses/ProductClassesQuickMenu';
import { ProductTreeSelect } from 'components/shared/products/ProductClasses/ProductTreeSelect';
import { AppContext } from 'contexts/user/AppContext';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { useUpdateBuyerCurrentProductSupplierInLocalStorage } from 'hooks/useUpdateBuyerCurrentProductSupplierInLocalStorage';
import { ProductsPageGuide } from 'introGuides/ProductsPageGuide';
import { BuyerProductClassWithSubclassesModel } from 'models/buyer/product/BuyerProductClassWithSubclassesModel';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card } from 'react-bootstrap';
import ReactDOM from 'react-dom';

export const ProductClasses = () => {
  const [quickMenuItems, setquickMenuItems] = useState<
    BuyerProductClassWithSubclassesModel[]
  >([]);
  const [mounted, setMounted] = useState<boolean>(false);

  const [selectedSupplierFromProductTree, setSelectedSupplierFromProductTree] =
    useState<BuyerCurrentSuppliers>({} as BuyerCurrentSuppliers);
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState, appContextDispatch } = useContext(AppContext);
  const setUserProfile = useSetUserProfile();

  const updateBuyerCurrentProductSupplierInLocalStorage =
    useUpdateBuyerCurrentProductSupplierInLocalStorage();

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const productTreeOptions: BuyerCurrentSuppliers[] | null =
    appState.buyerCurrentSuppliers !== null
      ? appState.buyerCurrentSuppliers.filter((supplier) => supplier.isSelected)
      : null;

  const handleProductClassImageUpload = (productClassId: number): void => {};

  /* Combined useEffect with three cases */
  useEffect(() => {
    const rawSavedBuyerCurrentProductsSupplier = localStorage.getItem(
      `_buyerCurrentProductsSupplier_${currentUser.id}`
    );

    let currentSupplier: BuyerCurrentSuppliers = {} as BuyerCurrentSuppliers;

    const foundSupplier = appState.buyerCurrentSuppliers?.find(
      (supplier) =>
        supplier.isActive && supplier.value === appState.buyerCurrentSupplierId
    );

    let savedBuyerCurrentProductsSupplier: BuyerCurrentSuppliers =
      {} as BuyerCurrentSuppliers;

    if (rawSavedBuyerCurrentProductsSupplier !== null) {
      savedBuyerCurrentProductsSupplier = JSON.parse(
        rawSavedBuyerCurrentProductsSupplier
      );
    }

    let selected = appState.buyerCurrentSuppliers?.find(
      (supplier) => supplier.value === savedBuyerCurrentProductsSupplier.value
    );

    /* Case -1: If currentSupplier does NOT exists in current suppliers list */
    if (selected === undefined) {
      selected = appState.buyerCurrentSuppliers?.find((option) =>
        option.subSellers?.some(
          (subSeller) =>
            subSeller.value === savedBuyerCurrentProductsSupplier.value
        )
      );

      selected = selected?.subSellers?.find(
        (subSeller) =>
          subSeller.value === savedBuyerCurrentProductsSupplier.value
      );

      if (selected !== undefined) {
        // console.log('Case -2');
        currentSupplier = selected;
      } else {
        // console.log('Case -1');
        currentSupplier =
          appState.buyerCurrentSuppliers?.filter(
            (supplier) => supplier.isFavourite
          )[0] ?? ({} as BuyerCurrentSuppliers);
      }
    } else {
      /* Case0: If STALED localStorageExists */
      if (
        rawSavedBuyerCurrentProductsSupplier !== null &&
        savedBuyerCurrentProductsSupplier.isActive === undefined
      ) {
        /* Same as Case2 */
        if (appState?.buyerCurrentSuppliers !== null) {
          // console.log('Case 0');
          currentSupplier = appState?.buyerCurrentSuppliers[0];
          updateBuyerCurrentProductSupplierInLocalStorage(currentSupplier);
        }
      }

      /* Case1: If correct localStorageExists */
      if (
        rawSavedBuyerCurrentProductsSupplier !== null &&
        savedBuyerCurrentProductsSupplier.isActive !== undefined
      ) {
        // console.log('Case 1');
        currentSupplier = savedBuyerCurrentProductsSupplier;
      }

      /* Case2: If localStorage does NOT exist AND its new load with no context*/
      if (
        rawSavedBuyerCurrentProductsSupplier === null &&
        foundSupplier === undefined
      ) {
        // console.log('Case 2');
        if (appState?.buyerCurrentSuppliers !== null)
          currentSupplier = appState?.buyerCurrentSuppliers.filter(
            (supplier) => supplier.isFavourite
          )[0];
        updateBuyerCurrentProductSupplierInLocalStorage(currentSupplier);
      }

      /* Case3: If localStorage does NOT exist AND context also exists */
      if (
        rawSavedBuyerCurrentProductsSupplier === null &&
        foundSupplier !== undefined
      ) {
        // console.log('Case 3');
        currentSupplier = foundSupplier;
      }

      if (
        foundSupplier === undefined &&
        appState?.buyerCurrentSuppliers !== null
      ) {
        const firstSupplier = appState?.buyerCurrentSuppliers[0];

        currentSupplier = firstSupplier;
      }
    }

    /* Finally set currentSuppliers into other states */
    setSelectedSupplierFromProductTree(currentSupplier);

    appContextDispatch({
      type: 'SELLER_SET_SUPPLIER',
      supplierId: currentSupplier?.value,
    });

    appState.buyerCurrentSuppliers && setUserProfile(currentSupplier?.value);
  }, [
    appContextDispatch,
    appState.buyerCurrentSupplierId,
    appState.buyerCurrentSuppliers,
    currentUser.id,
    setUserProfile,
    updateBuyerCurrentProductSupplierInLocalStorage,
  ]);

  const getProductClasses = useCallback(async () => {
    try {
      const level = 1;
      let productClassInfo;

      productClassInfo = await BuyerApi.getAllProductClasses(
        currentUser.companyId,
        selectedSupplierFromProductTree?.value,
        level,
        null,
        userLocaleId
      );

      if (level === 1) {
        const firstLevelClasses = productClassInfo.productClasses.filter(
          (pc) => pc.level === 1
        );
        setquickMenuItems(firstLevelClasses);
      }

      return productClassInfo.productClasses;
    } catch (err) {}
  }, [
    currentUser.companyId,
    selectedSupplierFromProductTree?.value,
    userLocaleId,
  ]);

  const { data, isLoading } = useQuery(
    [
      `productClass_${selectedSupplierFromProductTree?.value}`,
      currentUser.companyId,
      userLocaleId,
      selectedSupplierFromProductTree?.value,
    ],
    getProductClasses,
    {
      enabled:
        !!currentUser.companyId &&
        !!selectedSupplierFromProductTree?.value &&
        selectedSupplierFromProductTree?.isActive,
      staleTime: 1000 * 60 * 300,
    }
  );

  useEffect(() => {
    if (data) {
      const firstLevelClasses = data.filter((pc) => pc.level === 1);
      setquickMenuItems(firstLevelClasses);
    }
  }, [data]);

  const BuyerBreadcrumbNode = (
    <ProductBreadcrumb
      isStatic
      productClassesRoute='/buyer/product-classes/'
      productSubClassesRoute='/buyer/product-subclasses/'
    />
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <ProductsPageGuide />

      {/* <NewElecZapVideoGuides /> */}

      <div id='product-classes-start' className='intro-guide-start' />
      <div className='container-fluid px-2 pb-2 mt-1'>
        <Card className='card-wrapper' style={{ minHeight: '90vh' }}>
          <Card.Header className='p-2 mt-2'>
            {mounted &&
              ReactDOM.createPortal(
                BuyerBreadcrumbNode,
                document &&
                  document.getElementsByClassName('fixed-portal-receiver')[0]
              )}
          </Card.Header>
          <Card.Header className='p-2 mt-2'>
            <ProductTreeSelect
              setSelectedSupplierFromProductTree={
                setSelectedSupplierFromProductTree
              }
              options={productTreeOptions}
              selectedOption={selectedSupplierFromProductTree}
            />
            {isLoading ? (
              <LogoLoader />
            ) : (
              <ProductClassesQuickMenu
                handleProductClassImageUpload={handleProductClassImageUpload}
                items={quickMenuItems}
                productSubClassesRoute='/buyer/product-classes'
                favoriteProductsRoute='/buyer/favorite-products'
                viewType='Buyer'
              />
            )}
          </Card.Header>
          {isLoading ? (
            <LogoLoader />
          ) : (
            data && (
              <ProductClassesList
                handleProductClassImageUpload={handleProductClassImageUpload}
                imageReadOnly={true}
                items={data}
                productListRoute='/buyer/product-classes'
                productSubClassesRoute='/buyer/product-subclasses'
              />
            )
          )}
        </Card>
      </div>
    </>
  );
};

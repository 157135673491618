import { BuyerApi } from 'api/BuyerApi';
import {
  OrderCancelled,
  OrderConfirmed,
  OrderDispatched,
  OrderSending,
  OrderUnconfirmed,
} from 'assets/icons/StatusIcons';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useAddProductsToProjectInquiry } from 'hooks/useAddProductsToProjectInquiry';
import { useAddProductsToProjectOrder } from 'hooks/useAddProductsToProjectOrder';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { OrderModel, OrderRowModel } from 'models/buyer/documents/OrderModels';
import { ProjectImportProductsModel } from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  CartPlusFill,
  CheckCircleFill,
  Clipboard2PlusFill,
} from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { SiMicrosoftexcel } from 'react-icons/si';
import { TbTruckDelivery } from 'react-icons/tb';
import { Link, useParams } from 'react-router-dom';
import { handleDownloadOrderExcel } from 'utils/downloadExcelSheet';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { formatMonetaryValueWithoutDecimals } from 'utils/formatMonetaryValueWithoutDecimals';
import { truncateDecimals } from 'utils/truncateDecimals';

type UrlParamsType = {
  orderId: string;
};

type PropTypes = {
  passedOrderId?: number;
};

export const Order = ({ passedOrderId }: PropTypes) => {
  const [order, setOrder] = useState<OrderModel>({} as OrderModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { appState } = useContext(AppContext);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const canSeePrices = useUserCanSeePrices();
  const validateUnitExists = useValidateUnitExists();

  const addProductsToInquiry = useAddProductsToProjectInquiry();
  const addProductsToOrder = useAddProductsToProjectOrder();

  const { t } = useTranslation('components');

  let { orderId } = useParams<UrlParamsType>();

  const downloadExcel = () => {
    handleDownloadOrderExcel(
      order.rows,
      order.sentToCompanyName,
      `${order.sentToCompanyName}_ORDER_${order.code}`
    );
  };

  useEffect(() => {
    setIsLoading(true);
    const orderIdInUse = passedOrderId ?? parseInt(orderId);
    const getOffer = async (): Promise<void> => {
      const response = await BuyerApi.getOrder(
        currentUser.companyId,
        orderIdInUse,
        userLocaleId
      );

      setOrder(response);
      setIsLoading(false);
    };
    getOffer();
  }, [currentUser.companyId, orderId, passedOrderId, userLocaleId]);

  const columns: TableColumn<OrderRowModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      sortable: false,
      width: '60px',
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode,
      cell: (row) =>
        row.isReplaced ? (
          <div className='d-flex flex-column align-items-start'>
            <span>{row.productCode}</span>
            {row.productId !== null ? (
              <Link
                to={`/buyer/product-details/${row.productCode}`}
                className='btn-link-bold'
              >
                <small>{row.productCode}</small>{' '}
              </Link>
            ) : (
              <small>
                <del>{row.initialCode}</del>
              </small>
            )}
          </div>
        ) : row.productId === null ? (
          <span>{row.productCode}</span>
        ) : (
          <Link
            to={`/buyer/product-details/${row.productCode}`}
            className='btn-link-bold'
          >
            {row.productCode}
          </Link>
        ),
      sortable: false,
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name,
      cell: (row) =>
        row.isReplaced ? (
          <div className='d-flex flex-column align-items-start'>
            <span>{row.name}</span>

            <small>
              <del>{row.initialName}</del>
            </small>
          </div>
        ) : (
          <span>{row.name}</span>
        ),
      sortable: false,
      grow: 2,
    },

    {
      name: (
        <span className='text-end'>{t('documents.lbl_OrderQuantity')}</span>
      ),
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          <span>
            {row.quantity} <small>{validateUnitExists(row.unit)}</small>
          </span>
        </>
      ),
      sortable: false,
      right: true,
      minWidth: '100px',
      maxWidth: '100px',
    },

    {
      name: (
        <span className='text-end'>{t('documents.lbl_ConfirmedQuantity')}</span>
      ),
      selector: (row) => row.confirmedCount ?? 'confirmedCount',
      cell: (row) => (
        <div>
          {row.deliveries?.length === 0 ? (
            <div>
              {row.confirmedCount} <small>{validateUnitExists(row.unit)}</small>
            </div>
          ) : (
            row.deliveries?.map((delivery) => (
              <div>
                {delivery.confirmedCount}{' '}
                <small>{validateUnitExists(row.unit)}</small>
              </div>
            ))
          )}
        </div>
      ),
      sortable: false,
      right: true,
      minWidth: '110px',
      maxWidth: '110px',
    },

    {
      name: (
        <span className='text-center'>{t('documents.lbl_ConfirmedDate')}</span>
      ),

      selector: (row) => row.deliveryDate,
      cell: (row) =>
        row.deliveries?.length === 0 ? (
          <div>
            {row.deliveryDate === 'Dispatched' ? (
              <div className='icon-ez-green'>{row.deliveryDate}</div>
            ) : row.deliveryDate === 'Unconfirmed' ? (
              <div className='icon-ez-red'>{row.deliveryDate}</div>
            ) : row.deliveryDate === null ? (
              <div> - </div>
            ) : (
              <div>{dayjs(row.deliveryDate).format('DD.MM.YYYY')}</div>
            )}
          </div>
        ) : (
          <div>
            {row.deliveries?.map((delivery) =>
              delivery.deliveryDate === 'Dispatched' ? (
                <div className='icon-ez-green'>{delivery.deliveryDate}</div>
              ) : delivery.deliveryDate === 'Unconfirmed' ? (
                <div className='icon-ez-red'>{delivery.deliveryDate}</div>
              ) : delivery.deliveryDate === null ? (
                <div> - </div>
              ) : (
                <div>{dayjs(delivery.deliveryDate).format('DD.MM.YYYY')}</div>
              )
            )}
          </div>
        ),

      sortable: false,
      center: true,
      minWidth: '110px',
      maxWidth: '110px',
    },

    {
      name: t('documents.lbl_Dispatched'),
      selector: (row) => row.confirmedCount ?? 'confirmedCount',
      cell: (row) => (
        <div>
          {row.deliveredCount ?? '-'}{' '}
          <small>{validateUnitExists(row.unit)}</small>
        </div>
      ),
      sortable: false,
      right: true,
      maxWidth: '110px',
      minWidth: '110px',
    },

    {
      name: '',
      selector: (row) => row.quantity,
      cell: (row) => {
        return row.quantity === row.deliveredCount ? (
          <span>
            <CheckCircleFill className='icon-ez-green' />{' '}
            <TbTruckDelivery size={20} />
          </span>
        ) : (
          <span>
            <span className='icon-ez-yellow'>
              {t('documents.inf_QtysRemaining', {
                quantities: isNaN(row.quantity - (row.deliveredCount ?? 0))
                  ? row.quantity
                  : row.quantity - (row.deliveredCount ?? 0),
              })}
            </span>{' '}
            <TbTruckDelivery size={20} />{' '}
          </span>
        );
      },
      sortable: false,
      right: true,
      minWidth: '160px',
      maxWidth: '160px',
    },

    {
      name: t('documents.lbl_PriceUnit'),
      selector: (row) => row.priceWithDiscount ?? 'priceWithDiscount',
      cell: (row) =>
        canSeePrices(
          row.decimalPlaces
            ? formatMonetaryValue(
                order.currencyCode,
                row.priceWithDiscount,
                appState,
                row.decimalPlaces
              )
            : formatMonetaryValueWithoutDecimals(
                order.currencyCode,
                row.priceWithDiscount,
                appState
              )
        ),
      sortable: false,
      right: true,
      maxWidth: '110px',
      minWidth: '110px',
    },

    {
      name: t('projectSettings.lbl_TotalPrice'),
      selector: (row) => row.total ?? 'total',
      cell: (row) =>
        row.total === null
          ? '-'
          : canSeePrices(
              row.decimalPlaces
                ? formatMonetaryValue(
                    order.currencyCode,
                    truncateDecimals(row.total, row.decimalPlaces),
                    appState
                  )
                : formatMonetaryValueWithoutDecimals(
                    order.currencyCode,
                    row.total,
                    appState
                  )
            ),
      sortable: false,
      right: true,
      maxWidth: '110px',
      minWidth: '110px',
    },
  ];

  const customStyles = {
    rows: {
      style: {
        display: 'flex',
        alignItems: 'start',
      },
    },
  };

  const handleProjectInquireProducts = async () => {
    setIsLoading(true);
    const cleanedProducts: any[] = [];

    order.rows.forEach((row) =>
      cleanedProducts.push(
        row.productId !== null
          ? [row.productCode, row.quantity]
          : [
              row.isReplaced
                ? row.initialCode ?? '*EMPTY_CODE*'
                : row.productCode ?? '*EMPTY_CODE*',
              row.quantity,
              row.isReplaced ? row.initialName : row.name,
            ]
      )
    );

    const cleanedProductsText: ProjectImportProductsModel = {
      productsText: cleanedProducts.join('\n'),
      saveUnknownProducts: true,
    };

    await addProductsToInquiry('Document', {
      groupedProducts: null,
      importProductsText: cleanedProductsText,
    });

    setIsLoading(false);
  };

  const handleProjectOrderProducts = async () => {
    setIsLoading(true);
    const cleanedProducts: any[] = [];

    order.rows.forEach((row) =>
      cleanedProducts.push(
        row.productId !== null
          ? [row.productCode, row.quantity]
          : [
              row.isReplaced
                ? row.initialCode ?? '*EMPTY_CODE*'
                : row.productCode ?? '*EMPTY_CODE*',
              row.quantity,
              row.isReplaced ? row.initialName : row.name,
            ]
      )
    );

    const cleanedProductsText: ProjectImportProductsModel = {
      productsText: cleanedProducts.join('\n'),
      saveUnknownProducts: true,
    };

    await addProductsToOrder('Document', {
      groupedProducts: null,
      importProductsText: cleanedProductsText,
    });

    setIsLoading(false);
  };

  const isToExternalSeller = appState.buyerCurrentSuppliers?.filter(
    (supplier) => supplier.value === order.sellerCompanyId
  )[0]?.isExternalSeller;

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className='container-fluid p-2'>
        <Card>
          <Card.Header>
            <Row>
              <Col lg={4}>
                <h3>
                  <Link
                    to={`/buyer/${
                      isToExternalSeller
                        ? 'external-supplier-info'
                        : 'supplier-info'
                    }?id=${order.sellerCompanyId}`}
                    className='btn-link-bold'
                  >
                    {order.sentToCompanyShortName ?? order.sentToCompanyName}
                  </Link>
                </h3>
                <h4>{order.sentToContactName}</h4>
              </Col>
              <Col className='text-center' lg={4}>
                <h2>
                  {t('documents.hd_Order')} {order.code}
                </h2>
              </Col>
              <Col
                className='text-center d-flex justify-content-lg-end justify-content-sm-center'
                lg={4}
              >
                <h3>
                  {order.status === 'Unconfirmed' && (
                    <>
                      {t('documents.lbl_Unconfirmed')}{' '}
                      <OrderUnconfirmed className='icon-ez-yellow' />
                    </>
                  )}
                  {order.status === 'Confirmed' && (
                    <>
                      {t('documents.lbl_Confirmed')}{' '}
                      <OrderConfirmed className='icon-ez-green' />
                    </>
                  )}
                  {order.status === 'Dispatched' && (
                    <>
                      {t('documents.lbl_Dispatched')}{' '}
                      <OrderDispatched className='icon-ez' />
                    </>
                  )}
                  {order.status === 'Sending' && (
                    <>
                      {t('documents.lbl_Sending')}{' '}
                      <OrderSending className='icon-ez-gray' />
                    </>
                  )}

                  {order.status === 'Cancelled' && (
                    <>
                      {t('documents.lbl_Cancelled')}{' '}
                      <OrderCancelled className='icon-ez-red ms-1' />
                    </>
                  )}
                </h3>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_Date')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(order.documentDate).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_UpdatedOn')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(order.lastUpdated).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentBy')}:
                  </Col>
                  <Col>
                    <strong>{order.sentByContactName}</strong>
                  </Col>
                </Row>

                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentTo')}:
                  </Col>
                  <Col>
                    <Link
                      to={`/buyer/${
                        isToExternalSeller
                          ? 'external-supplier-info'
                          : 'supplier-info'
                      }?id=${order.sellerCompanyId}`}
                      className='btn-link-bold'
                    >
                      {order.sentToCompanyShortName ?? order.sentToCompanyName}{' '}
                      {order.subSellerId &&
                        appState.buyerCurrentSuppliers
                          ?.find(
                            (supplier) =>
                              supplier.isMarketplace &&
                              supplier.subSellers?.some(
                                (subSeller) =>
                                  subSeller.value === order.subSellerId
                              )
                          )
                          ?.subSellers?.find(
                            (subSeller) => subSeller.value === order.subSellerId
                          )?.name}
                    </Link>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row className='flex-nowrap'>
                  <Col className='text-end pe-0 text-nowrap'>
                    <Row className='mb-0'>
                      <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_LinkedOffer')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_MyOrderNumber')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>
                        {t('projectSettings.lbl_RequestedDeliveryDate')}:
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                    </Row>

                    {order.deliveryAddressLine2 !== null && <br />}

                    {order.deliveryAddressLine3 !== null && <br />}

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_PaymentTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_DeliveryTerms')}:</Col>
                    </Row>
                  </Col>

                  <Col
                    className='pe-3 d-flex flex-column'
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        <strong>{order.projectName}</strong>
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.offerCode !== null && order.offerCode !== '' ? (
                          <Link
                            to={`/buyer/offer/${order.offerId}`}
                            className='btn-link-bold'
                          >
                            {order.offerCode}
                          </Link>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.customerOrderCode !== null &&
                        order.customerOrderCode !== '' ? (
                          <strong>{order.customerOrderCode}</strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.requestedDeliveryDate !== null ? (
                          <strong>
                            {dayjs(order.requestedDeliveryDate).format(
                              'DD.MM.YYYY'
                            )}
                          </strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='d-flex flex-column flex-nowrap text-nowrap'>
                        {order.deliveryAddressLine1 !== null ? (
                          <strong>{order.deliveryAddressLine1}</strong>
                        ) : (
                          <br />
                        )}
                        <strong>{order.deliveryAddressLine2}</strong>
                        <strong>{order.deliveryAddressLine3}</strong>
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.paymentTerm !== null ? (
                          <strong>{order.paymentTerm} days</strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        {order.deliveryTerm !== null ? (
                          <strong>{order.deliveryTerm}</strong>
                        ) : (
                          <br />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='my-2'>
              <Col></Col>
              <Col>
                <ButtonGroup className='d-flex float-end'>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>{t('documents.btn_ExportToExcel')}</Tooltip>
                    }
                  >
                    <Button
                      type={'button'}
                      onClick={downloadExcel}
                      variant='btn btn-eleczap'
                    >
                      <SiMicrosoftexcel size={20} />
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        {t('documents.btn_AddProductsToProjectInquiry')}
                      </Tooltip>
                    }
                  >
                    <Button
                      disabled={isLoading}
                      onClick={handleProjectInquireProducts}
                      className='btn btn-eleczap d-flex align-items-center  px-1'
                    >
                      <Clipboard2PlusFill size={20} className='me-1' />{' '}
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        {t('documents.btn_AddProductsToProjectOrder')}
                      </Tooltip>
                    }
                  >
                    <Button
                      disabled={isLoading}
                      onClick={handleProjectOrderProducts}
                      className='btn btn-eleczap d-flex align-items-center px-1'
                    >
                      <CartPlusFill size={20} className='me-1' />{' '}
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </Col>
            </Row>

            <Row className='my-5'>
              <DataTable
                noDataComponent={
                  <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
                }
                columns={columns}
                data={order.rows}
                customStyles={customStyles}
              />
            </Row>

            <Row className='mb-3'>
              <Col lg={6}>
                <span>{t('documents.lbl_SupplierComments')} : </span>{' '}
                {order.status !== 'Cancelled'
                  ? order.confirmationComment
                  : order.cancelReason}
              </Col>
            </Row>

            <Row className='justify-content-between'>
              <Col lg={6}>
                <Form.Group className='mb-3' controlId='comments'>
                  <Form.Label>{t('documents.lbl_YourComments')}</Form.Label>
                  <Form.Control
                    readOnly
                    as='textarea'
                    rows={4}
                    value={order.comment}
                  />
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Row className='mt-3'>
                  <Col>
                    <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                  </Col>
                  <Col>
                    <h3 className='text-end'>
                      {canSeePrices(
                        useFormatMonetaryValue(order.currencyCode, order.total)
                      )}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-end'>
                    {t('common.inf_TotalPriceSubtext')}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { useSetCurrentProject } from 'hooks/useSetCurrentProject';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import {
  ProjectAddProductsModel,
  ProjectImportProductsErrorModel,
} from 'models/buyer/project/ProjectModels';
import { ProjectInquiryCreateRowModel } from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { addProductsToProjectInquiry } from 'pages/buyer/ProjectInquiry/utilsProjectInquiry';
import { useContext } from 'react';
import { useAuthUser } from 'react-auth-kit';

export const useAddProductsToProjectInquiry = () => {
  const { appState, appContextDispatch } = useContext(AppContext);

  const setUserProfile = useSetUserProfile();

  const setCurrentProject = useSetCurrentProject();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const addProductsToProject = async (
    requestFromPage: 'ProductList' | 'Document',
    products: ProjectAddProductsModel
  ): Promise<ProjectImportProductsErrorModel[] | null> => {
    let responseFromImport;

    /* When Current Project and Inquiry exists */
    if (
      appState.userProfile?.currentProject &&
      appState.userProfile?.currentProject.inquiryProjectId
    ) {
      responseFromImport = await addProduct(
        appState.userProfile?.currentProject.id,
        appState.userProfile?.currentProject.inquiryProjectId,
        requestFromPage,
        products
      );
    }

    /* When Current Project exists but Inquiry does NOT */
    if (
      appState.userProfile?.currentProject &&
      appState.userProfile?.currentProject.inquiryProjectId === null
    ) {
      responseFromImport = await createInquiryProject(
        appState.userProfile?.currentProject.id,
        requestFromPage,
        products
      );
    }

    /* When Current Project and Inquiry both do NOT exists */
    if (appState.userProfile?.currentProject === null) {
      responseFromImport = await createProjectAndInquiry(
        requestFromPage,
        products
      );
    }

    return responseFromImport ?? null;
  };

  const addProduct = async (
    projectId: number,
    projectInquiryId: number,
    requestFromPage: 'ProductList' | 'Document',
    products: ProjectAddProductsModel
  ): Promise<ProjectImportProductsErrorModel[] | null> => {
    const productsToAdd: ProjectInquiryCreateRowModel[] = [];
    let responseFromImport;

    switch (requestFromPage) {
      case 'Document':
        if (products.importProductsText) {
          responseFromImport =
            await BuyerApi.projectInquiry.importProductFromText(
              currentUser.companyId,
              projectId,
              projectInquiryId,
              products.importProductsText
            );
        }
        break;

      case 'ProductList':
        if (productsToAdd) {
          products.groupedProducts?.forEach((groupedProduct) => {
            if (groupedProduct?.quantity) {
              const product = groupedProduct.products[0];

              const productToAdd: ProjectInquiryCreateRowModel = {
                productId: product.id ?? null,
                sellerCompanyId: product?.sellerCompanyId ?? null,
                productCode: product?.code,
                productName: product?.name,
                requiredQuantity: groupedProduct.quantity,
                productExists: !(product.id === undefined || product.id === 0),
              };

              productsToAdd.push(productToAdd);
            }
          });

          await addProductsToProjectInquiry(
            productsToAdd,
            currentUser.companyId,
            projectId,
            projectInquiryId
          );
        }
        break;

      default:
        break;
    }

    const response = await BuyerApi.projectInquiry.getProjectInquiryRows(
      currentUser.companyId,
      projectInquiryId,
      projectId
    );

    appContextDispatch({
      type: 'BUYER_SET_CURRENT_INQUIRY_PROJECT_ROWS',
      currentInquiryProjectRows: response.length,
    });

    return responseFromImport ?? null;
  };

  const createInquiryProject = async (
    projectId: number,
    requestFromPage: 'ProductList' | 'Document',
    products: ProjectAddProductsModel
  ): Promise<ProjectImportProductsErrorModel[] | null> => {
    // Create inquiry project
    const response = await BuyerApi.projectInquiry.createProjectInquiry(
      currentUser.companyId,
      projectId
    );

    // Get fresh iquiryProject
    setUserProfile(null);

    // Add product to that inquiry
    const responseFromImport = await addProduct(
      projectId,
      response,
      requestFromPage,
      products
    );

    return responseFromImport ?? null;
  };

  const createProjectAndInquiry = async (
    requestFromPage: 'ProductList' | 'Document',
    products: ProjectAddProductsModel
  ): Promise<ProjectImportProductsErrorModel[] | null> => {
    // Create project
    const response = await BuyerApi.projects.createProjectAndProjectInquiry(
      currentUser.companyId
    );

    await setCurrentProject(response.projectId);

    // Get fresh iquiryProject
    setUserProfile(null);

    const responseFromImport = await addProduct(
      response.projectId,
      response.inquiryProjectId,
      requestFromPage,
      products
    );

    return responseFromImport ?? null;
  };

  return addProductsToProject;
};

import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import * as React from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Redirect, Route, RouteComponentProps } from 'react-router';

interface PropsType {
  Component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  path: string;
  exact?: boolean;
  allowedUserNameDomains: string[];
}

/**
 * Hidden Feature Route - validates if current logged in user has required username domain name to access the route
 * Use for wireframes or new features access to which by customers should be prohibited.
 * @param param0 Route properties
 * @returns If current user is allowed to acces, returns component to render, otherwise login page
 */
export const HiddenFeatureRoute = ({
  /*  */ Component,
  path,
  exact,
  allowedUserNameDomains,
}: PropsType): JSX.Element => {
  const authUser = useAuthUser();
  const user = authUser() as LoginResponseUserModel;

  const userNameDomain = user.userName.split('@').pop() || '';

  const isAuthorized =
    allowedUserNameDomains &&
    allowedUserNameDomains.indexOf(userNameDomain) !== -1;

  return (
    <Route
      path={path}
      exact={exact}
      render={(props: RouteComponentProps) =>
        isAuthorized ? <Component {...props} /> : <Redirect to={'/login'} />
      }
    />
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import {
  OrderCreateForSessionModel,
  OrderModel,
} from 'models/buyer/documents/OrderModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card } from 'react-bootstrap';
import { ChevronDoubleRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  orders: OrderCreateForSessionModel[];
  order: OrderCreateForSessionModel;
  handleOpenOrder: (sellerCompanyId: number) => void;
  index: number;
  currentSellerOrderOpened: number;
};

export const OrderUnsentClosedCard = ({
  orders,
  order,
  handleOpenOrder,
  index,
  currentSellerOrderOpened,
}: PropTypes) => {
  const { t } = useTranslation('components');

  const [fetchedOrder, setFetchedOrder] = useState<OrderModel>(
    {} as OrderModel
  );

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  useEffect(() => {
    const getFetchedOrder = async (): Promise<void> => {
      if (order.sentId !== null) {
        const response = await BuyerApi.getOrder(
          currentUser.companyId,
          order.sentId,
          userLocaleId
        );

        setFetchedOrder(response);
      }
    };
    getFetchedOrder();
  }, [currentUser.companyId, order.sentId, userLocaleId]);

  return (
    <Card
      style={{ width: '210px' }}
      className={
        index >
        orders.findIndex(
          (order) => order.sellerCompanyId === currentSellerOrderOpened
        )
          ? 'py-3 px-1 me-2 mt-2 ms-3'
          : 'py-3 px-1 ms-2 mt-2 me-3'
      }
    >
      <div
        onClick={() => handleOpenOrder(order.sellerCompanyId)}
        className='cursor-pointer'
      >
        <h3 className='d-flex flex-row align-items-center justify-content-between'>
          <span className='wrap-ellipsis'>
            <div className='wrap-ellipsis'>{order.sellerCompanyName}</div>
            {order.sentId === null ? (
              <span className='icon-ez-gray me-2'>
                {t('documents.lbl_Unsent')}
              </span>
            ) : (
              <>
                {fetchedOrder.status === 'Sending' && (
                  <span className='icon-ez-gray me-2'>
                    {t('documents.lbl_Sending')}
                  </span>
                )}

                {fetchedOrder.status === 'Unconfirmed' && (
                  <span className='icon-ez-yellow me-2'>
                    {t('documents.lbl_Unconfirmed')}
                  </span>
                )}
                {fetchedOrder.status === 'Confirmed' && (
                  <span className='icon-ez me-2'>
                    {t('documents.lbl_Confirmed')}
                  </span>
                )}
                {fetchedOrder.status === 'Dispatched' && (
                  <span className='icon-ez me-2'>
                    {t('documents.lbl_Dispatched')}
                  </span>
                )}
              </>
            )}
          </span>

          <div>
            <ChevronDoubleRight size={30} className='icon-ez me-2 mt-1' />
          </div>
        </h3>
      </div>
    </Card>
  );
};

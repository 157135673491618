import { ExternalSupplierCompanyInfo } from 'components/buyer/supplierInfo/ExternalSupplierCompanyInfo';
import { ExternalSupplierContacts } from 'components/buyer/supplierInfo/ExternalSupplierContacts';
import { ExternalSupplierProfileCard } from 'components/buyer/supplierInfo/ExternalSupplierProfileCard';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getUrlParams } from 'utils/getUrlParams';

export const ExternalSupplierInfo = () => {
  const [company, setCompany] = useState<SupplierCompanyModel>(
    {} as SupplierCompanyModel
  );

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');

  const supplierId = parseInt(getUrlParams('id') || '0');

  return (
    <div className='container-fluid p-2'>
      <Card className='p-1 settingsPage'>
        <Tab.Container
          id='supplierrCompanyInfo'
          defaultActiveKey='supplierCompanyInfo'
        >
          <div className='row flex-nowrap'>
            <div className='col col-side-menu-supplier-customer'>
              <div className='mb-4'>
                <ExternalSupplierProfileCard
                  supplierId={supplierId}
                  company={company}
                />
              </div>

              <Nav className='flex-column border p-0 mb-4 tabContainerSettings'>
                <Nav.Item className='border-bottom nav-item-hover'>
                  <Nav.Link eventKey='supplierCompanyInfo'>
                    {t('companySettings.hd_CompanyInformation')}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className='nav-item-hover'>
                  <Nav.Link eventKey='supplierContacts'>
                    {t('common.hd_Contacts')}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className='col col-lg-10 '>
              <Tab.Content>
                <Tab.Pane eventKey='supplierCompanyInfo'>
                  <ExternalSupplierCompanyInfo
                    company={company}
                    setCompany={setCompany}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey='supplierContacts'>
                  <ExternalSupplierContacts
                    supplierName={company.name}
                    supplierId={supplierId}
                  />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </Card>
    </div>
  );
};

import { ApplicationSettingsApi } from 'api/ApplicationSettingsApi';
import { CountryModel } from 'models/shared/country/CountryModel';
import { useEffect } from 'react';

export const useGetAvailableCountries = (
  setCountries: (value: CountryModel[]) => void
) => {
  useEffect(() => {
    const getAvailableCountries = async () => {
      const availableCountries = await ApplicationSettingsApi.getCountries();
      setCountries(availableCountries);
    };
    getAvailableCountries();
  }, [setCountries]);
};

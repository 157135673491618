import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { AddressModel } from 'models/shared/address/AddressModel';
import React, { ChangeEvent } from 'react';
import { getAllAddresses } from './utilsDeliveryAddresses';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

type PropTypes = {
  currentUser: LoginResponseUserModel;
  setAddresses: (values: AddressModel) => void;
  setResetPaginationToggle: (value: boolean) => void;
  setQuery: (value: string) => void;
  t: any;
};

export const DeliveryAddressSearch = ({
  currentUser,
  setAddresses,
  setResetPaginationToggle,
  setQuery,
  t,
}: PropTypes) => {
  const handleQueryChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const query: string = event.target.value;

    setQuery(query);

    setResetPaginationToggle(true);
    await getAllAddresses(currentUser, setAddresses, 1, query);
    setResetPaginationToggle(false);
  };

  const searchAPIDebounced = AwesomeDebouncePromise(handleQueryChange, 500);

  return (
    <input
      style={{ maxWidth: '175px' }}
      onChange={searchAPIDebounced}
      type='search'
      id='SearchText'
      name='SearchText'
      className='form-control d-none d-sm-inline-block'
      placeholder={t('addressSettings.plh_SearchForAddress')}
      aria-label='Search'
    />
  );
};

import 'bootstrap/dist/css/bootstrap.min.css';
import { DeliveryAddressesEdit } from 'components/buyer/settings/deliveryAddresses/DeliveryAddressEdit/DeliveryAddressesEdit';
import { DeliveryAddressesDelete } from 'components/buyer/settings/deliveryAddresses/DeliveryAddressesDelete';
import { DeliveryAddressesNew } from 'components/buyer/settings/deliveryAddresses/DeliveryAddressNew/DeliveryAddressesNew';
import { DeliveryAddressSearch } from 'components/buyer/settings/deliveryAddresses/DeliveryAddressSearch';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  AddressModel,
  AddressModelById,
} from 'models/shared/address/AddressModel';
import { AddressUpdateModel } from 'models/shared/address/AddressUpdateModel';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { getAllAddresses, handleSubmit } from '../utilsDeliveryAddresses';
import { useGetAllAddresses } from './hooksDeliveryAddresses';

const DeliveryAddresses = () => {
  const [addresses, setAddresses] = useState<AddressModel>({} as AddressModel);
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('name');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  useGetAllAddresses(currentUser, setAddresses);

  const { t } = useTranslation('components');

  const handleVisibleChange = async (
    checked: boolean,
    address: AddressModelById,
    field: string
  ) => {
    address.isVisible = checked;

    const updatedAddress: AddressUpdateModel = {
      name: address.name,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      postalCode: address.postalCode,
      city: address.city,
      state: address.state,
      countryCode: address.country.code,
      isVisible: field === 'isVisible' ? checked : address.isVisible,
      isDefault: field === 'isDefault' ? checked : address.isDefault,
    };

    await handleSubmit(currentUser, updatedAddress, address, t);
    await getAllAddresses(
      currentUser,
      setAddresses,
      pageNumber,
      query,
      rowsPerPage,
      sortColumn
    );
  };

  const columns = [
    {
      name: t('addressSettings.lbl_Name'),
      selector: (row: AddressModelById) => row.name,
      sortable: true,
      left: true,
      maxWidth: '260px',
      minWidth: '260px',
    },
    {
      name: t('addressSettings.lbl_Address'),
      selector: (row: AddressModelById) => row.fullAddress,
      maxWidth: '500px',
      minWidth: '500px',
    },
    {
      name: t('addressSettings.lbl_Visible'),
      selector: (row: AddressModelById) => row.isVisible,
      sortable: true,
      cell: (row: AddressModelById) => (
        <Form.Check
          onChange={(event) =>
            handleVisibleChange(event.target.checked, row, 'isVisible')
          }
          checked={row.isVisible}
          type='switch'
          id='defaultAadress'
        />
      ),
      center: true,
      // maxWidth: '105px',
      // minWidth: '85px',
    },

    {
      name: t('addressSettings.lbl_Default'),
      selector: (row: AddressModelById) => row.isDefault,
      sortable: true,
      cell: (row: AddressModelById) => (
        <Form.Check
          onChange={(event) =>
            handleVisibleChange(event.target.checked, row, 'isDefault')
          }
          checked={row.isDefault}
          type='radio'
          aria-label='radio 1'
        />
      ),
      center: true,
      // maxWidth: '120px',
      // minWidth: '90px',
    },

    {
      name: '',
      cell: (row: AddressModelById) => (
        <DeliveryAddressesEdit
          handleSubmit={handleSubmit}
          address={row}
          setAddresses={setAddresses}
          currentUser={currentUser}
          t={t}
        />
      ),
      center: true,
      maxWidth: '40px',
      minWidth: '40px',
    },
    {
      name: '',
      cell: (row: AddressModelById) => (
        <DeliveryAddressesDelete
          address={row}
          setAddresses={setAddresses}
          currentUser={currentUser}
          t={t}
        />
      ),
      center: true,
      maxWidth: '40px',
      minWidth: '40px',
    },
  ];

  const handlePageChange = async (page: number) => {
    setPageNumber(page);
    await getAllAddresses(
      currentUser,
      setAddresses,
      page,
      query,
      rowsPerPage,
      sortColumn
    );
  };

  const handleRowsPerChange = async (rowsAmount: number) => {
    setRowsPerPage(rowsAmount);
    await getAllAddresses(
      currentUser,
      setAddresses,
      pageNumber,
      query,
      rowsAmount,
      sortColumn
    );
  };
  const handleOnSort = async (selectedColumn: any, sortDirection: any) => {
    let field = '';
    switch (selectedColumn.id) {
      case 1:
        field = 'name';
        break;

      case 3:
        field = 'isVisible';
        break;

      case 4:
        field = 'isDefault';
        break;
      default:
        field = 'name';
    }

    const sortingColumn =
      sortDirection === 'desc' ? `${field}_${sortDirection}` : field;
    setSortColumn(sortingColumn);
    await getAllAddresses(
      currentUser,
      setAddresses,
      pageNumber,
      query,
      rowsPerPage,
      sortingColumn
    );
  };

  const paginationComponentOptions = {
    rowsPerPageText: t('common.lbl_RowsPerPage'),
    rangeSeparatorText: t('common.lbl_Of'),
  };

  return (
    <Card className='p-4'>
      <Card.Header>
        <h4>{t('addressSettings.hd_DeliveryAddresses')}</h4>
        <div className='input-group mt-4'>
          <DeliveryAddressesNew t={t} setAddresses={setAddresses} />
          <DeliveryAddressSearch
            currentUser={currentUser}
            setAddresses={setAddresses}
            setResetPaginationToggle={setResetPaginationToggle}
            setQuery={setQuery}
            t={t}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <DataTable
          noDataComponent={
            <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
          }
          striped
          highlightOnHover
          responsive
          columns={columns}
          data={addresses.data}
          pagination
          paginationServer
          paginationComponentOptions={paginationComponentOptions}
          paginationTotalRows={addresses.totalItems}
          onChangeRowsPerPage={handleRowsPerChange}
          onChangePage={handlePageChange}
          paginationResetDefaultPage={resetPaginationToggle}
          sortServer
          onSort={handleOnSort}
        />
      </Card.Body>
    </Card>
  );
};

export default DeliveryAddresses;

/* Hooks utilized in Company.tsx (Company settings)*/
import { IdentityApi } from 'api/IdentityApi';
import { useFormik } from 'formik';
import {
  BuyerRegistrationRequestModel,
  BuyerRegistrationUpdateDetailsModel,
} from 'models/buyer/buyerRegistration/BuyerRegistrationModels';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* Formik init */
export const useFormikHookForBuyerRegistrationDetails = (
  company: BuyerRegistrationUpdateDetailsModel,
  setCompany: (value: BuyerRegistrationUpdateDetailsModel) => void,
  nextStep: () => void,

  buyerRegistrationData: BuyerRegistrationRequestModel | null,
  setBuyerRegistrationData: (value: BuyerRegistrationRequestModel) => void
): any => {
  const { t } = useTranslation('components');

  const handleSubmit = async (
    values: BuyerRegistrationUpdateDetailsModel
  ): Promise<void> => {
    toast.dismiss();
    setCompany(values);

    if (buyerRegistrationData !== null) {
      setBuyerRegistrationData({
        ...buyerRegistrationData,
        buyerCompanyRegistrationModel: values,
      });
    }

    const canCompanyBeCreatedResponse =
      await IdentityApi.checkCanCompanyBeCreated(
        values.registryNumber,
        values.countryCode
      );

    if (canCompanyBeCreatedResponse) nextStep();
    else {
      formik.setSubmitting(false);
      toast.error(t('buyerRegistration.err_CompanyCannotBeCreated'));
    }
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(t('common.err_ValueRequired'))
      .max(70, t('common.err_ValueMax70')),
    registryNumber: yup
      .string()
      .nullable()
      .required(t('common.err_ValueRequired'))
      .max(70, t('common.err_ValueMax70')),
    shortName: yup.string().nullable().max(70, t('common.err_ValueMax70')),
    addressLine1: yup.string().nullable().max(150, t('common.err_ValueMax150')),
    addressLine2: yup.string().nullable().max(150, t('common.err_ValueMax150')),
    vatNumber: yup.string().nullable().max(50, t('common.err_ValueMax50')),
    postalCode: yup.string().nullable().max(50, t('common.err_ValueMax50')),
    city: yup.string().nullable().max(50, t('common.err_ValueMax50')),
    state: yup.string().nullable().max(50, t('common.err_ValueMax50')),
    webSite: yup.string().max(50, t('common.err_ValueMax50')).nullable(),
    email: yup
      .string()
      .nullable()
      .required(t('common.err_ValueRequired'))
      .email(t('common.err_EmailInvalid'))
      .max(50, t('common.err_ValueMax50')),
    phone: yup
      .string()
      .nullable()
      .max(50, t('common.err_ValueMax50'))
      .nullable(),
  });

  const formik = useFormik<BuyerRegistrationUpdateDetailsModel>({
    initialValues:
      company?.name !== undefined
        ? { ...company }
        : {
            name: '',
            shortName: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            postalCode: '',
            city: '',
            state: '',
            phone: '',
            webSite: '',
            vatNumber: '',
            registryNumber: '',
            countryCode: 'AD',
          },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedCompany: BuyerRegistrationUpdateDetailsModel = {
        name: values.name,
        shortName: values.shortName,
        email: values.email,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        postalCode: values.postalCode,
        city: values.city,
        state: values.state,
        phone: values.phone,
        webSite: values.webSite,
        vatNumber: values.vatNumber,
        registryNumber: values.registryNumber,
        countryCode: values.countryCode,
      };

      await handleSubmit(updatedCompany);
      formik.setSubmitting(false);
    },
  });

  return formik;
};

import {
  getSellerStocks,
  getSupplierCompanyInfo,
} from 'components/buyer/supplierInfo/utilsSupplierInfo';
import { AppContext } from 'contexts/user/AppContext';
import { SellerStocksModel } from 'models/buyer/suppliers/SellerStocksModel';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect } from 'react';

export const useGetSellerStocks = (
  setSellerStocks: (value: SellerStocksModel[]) => void,
  currentUser: LoginResponseUserModel,
  supplierId: string | null
) => {
  useEffect(() => {
    const getStocks = async () => {
      supplierId !== null &&
        (await getSellerStocks(
          setSellerStocks,
          currentUser,
          parseInt(supplierId)
        ));
    };

    getStocks();
  }, [currentUser, supplierId, setSellerStocks]);
};

export const useGetSupplierCompanyInfo = (
  setCompany: (value: SupplierCompanyModel) => void,
  currentUser: LoginResponseUserModel,
  supplierId: number | null,
  anyHinderance?: boolean
) => {
  const { appState } = useContext(AppContext);

  const isSubSeller =
    appState.buyerCurrentSuppliers?.filter(
      (supplier) => supplier.value === supplierId
    ).length === 0;

  useEffect(() => {
    const getCompanyInfo = async (): Promise<void> => {
      !anyHinderance &&
        supplierId !== null &&
        supplierId !== undefined &&
        (await getSupplierCompanyInfo(setCompany, currentUser, supplierId));
    };

    !isSubSeller && getCompanyInfo();
  }, [currentUser, supplierId, setCompany, anyHinderance, isSubSeller]);
};

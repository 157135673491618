import FilePicker from '@mavedev/react-file-picker';
import { IdentityApi } from 'api/IdentityApi';
import {
  InquiryDeclined,
  InquiryDisabled,
  InquiryNoReplySeller,
  InquiryOfferSent,
  InquirySending,
  OfferExpired,
} from 'assets/icons/StatusIcons';
import { ExternalSellerContactProfileModal } from 'components/seller/externalSeller/ExternalSellerContactProfileModal';
import { ExternalSellerCustomerProfileModal } from 'components/seller/externalSeller/ExternalSellerCustomerProfileModal';
import { ExternalSellerDeclineModal } from 'components/seller/externalSeller/ExternalSellerDeclineModal';
import { ExternalSellerSendOfferModal } from 'components/seller/externalSeller/ExternalSellersSendOfferModal';
import { CustomExternalSellerRowInput } from 'components/shared/CustomInputs/CustomExternalSellerRowInput';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { ExternalSellerAttachmentItem } from 'components/shared/deals/ProjectAttachmentItem/ExternalSellerAttachmentItem';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { SellerFooter } from 'layout/SellerFooter';
import { SellerHeader } from 'layout/SellerHeader';
import { ExternalSellerBuyerContactModel } from 'models/seller/contacts/ExternalSellerBuyerContactModel';
import { CustomerCompanyModel } from 'models/seller/customers/CustomerCompanyModel';
import {
  ExternalSellerInquiryModel,
  ExternalSellerInquiryRowChangeType,
  ExternalSellerInquiryRowExtendedModel,
} from 'models/seller/documents/ExternalSellerInquiryModels';
import { ExternalSellerOfferUpsertModel } from 'models/seller/documents/ExternalSellerOfferModels';
import {
  bulkEditExternalSellerInquiryRows,
  createExternalSellerOfferRows,
  mapExternalSellerInquiryRows,
} from 'pages/seller/Inquiry/utilsInquiry';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  ArrowCounterclockwise,
  ArrowRepeat,
  Paperclip,
  PlusCircleFill,
  Trash,
} from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useTranslation } from 'react-i18next';
import { GiCherry } from 'react-icons/gi';
import { useHistory } from 'react-router';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { formatMonetaryValueWithoutDecimals } from 'utils/formatMonetaryValueWithoutDecimals';
import { getUrlParams } from 'utils/getUrlParams';
import './Inquiry.css';

type LocalStorageType = {
  paymentTerms: string | null;
  deliveryTerms: string | null;
  offerValidUntil: string | null;
  isoDate: string | null;
  yourComment: string | null;
  extendedRows: ExternalSellerInquiryRowExtendedModel[];
  headerExpectedDelivery: string | null;
};

export const ExternalSellerInquiry = () => {
  const [inquiry, setInquiry] = useState<ExternalSellerInquiryModel>(
    {} as ExternalSellerInquiryModel
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [paymentTerms, setPaymentTerms] = useState<string>('');
  const [deliveryTerms, setDeliveryTerms] = useState<string>('');
  const [offerValidUntil, setOfferValidUntil] = useState<string | null>(null);
  const [isoDate, setIsoDate] = useState<string | null>(null);
  const [yourComment, setYourComment] = useState<string>('');
  const [extendedRows, setExtendedRows] = useState<
    ExternalSellerInquiryRowExtendedModel[]
  >([]);
  const [allowCherryPicking, setAllowCherryPicking] = useState<boolean>(true);
  const [offer, setOffer] = useState<ExternalSellerOfferUpsertModel>(
    {} as ExternalSellerOfferUpsertModel
  );

  const [customer, setCustomer] = useState<CustomerCompanyModel>(
    {} as CustomerCompanyModel
  );
  const [contact, setContact] = useState<ExternalSellerBuyerContactModel>(
    {} as ExternalSellerBuyerContactModel
  );

  const [documentTotal, setDocumentTotal] = useState<number>(0);

  const [headerExpectedDelivery, setHeaderExpectedDelivery] = useState<
    string | null
  >(null);

  const { appState } = useContext(AppContext);

  const localStorageKey = `_elecZappApp_Seller_Offer_Unsent_${inquiry.code}_${inquiry.sentToCompanyName}`;

  const { t } = useTranslation('components');
  const validateUnitExists = useValidateUnitExists();

  const navigate = useHistory();

  const isDocumentUneditable: boolean = inquiry.isReadOnly;

  let accessToken = getUrlParams('accessToken');

  const setLocalStorage = (
    offerValidUntil: string | null,
    isoDate: string | null,
    headerExpectedDelivery: string | null,
    updatedExtendedRows: ExternalSellerInquiryRowExtendedModel[]
  ) => {
    const localStorageData: LocalStorageType = {
      paymentTerms,
      deliveryTerms,
      offerValidUntil,
      isoDate,
      yourComment,
      extendedRows: updatedExtendedRows,
      headerExpectedDelivery,
    };

    localStorage.removeItem(localStorageKey);
    localStorage.setItem(localStorageKey, JSON.stringify(localStorageData));
  };

  const getLocalStorageData = useCallback(() => {
    const rawData = localStorage.getItem(localStorageKey);

    if (rawData) {
      const data: LocalStorageType = JSON.parse(rawData);

      setPaymentTerms(data.paymentTerms ?? '');
      setDeliveryTerms(data.deliveryTerms ?? '');
      setOfferValidUntil(data.offerValidUntil);
      setIsoDate(data.isoDate);
      setYourComment(data.yourComment ?? '');
      !inquiry.isUpdated && setExtendedRows(data.extendedRows);
      setHeaderExpectedDelivery(data.headerExpectedDelivery);
    }
  }, [inquiry.isUpdated, localStorageKey]);

  const recallInquiryUpdated = useCallback(
    async (inquiry: ExternalSellerInquiryModel) => {
      if (accessToken && inquiry.isUpdated) {
        await IdentityApi.recallInquiryUpdated(accessToken);
      }
    },
    [accessToken]
  );

  const getInquiry = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    if (accessToken !== null) {
      const response = await IdentityApi.getExternalSellerInquiry(accessToken);

      if (response.offerExists)
        navigate.push(`/external-seller-offer?accessToken=${accessToken}`);

      setInquiry(response);

      const mappedRows = mapExternalSellerInquiryRows(response.rows);

      setExtendedRows(mappedRows);
      setIsLoading(false);
      getLocalStorageData();

      setTimeout(() => recallInquiryUpdated(response), 5000);
    }
  }, [accessToken, getLocalStorageData, navigate, recallInquiryUpdated]);

  useEffect(() => {
    accessToken && getInquiry();
  }, [accessToken, getInquiry]);

  /* Get customer contact */
  useEffect(() => {
    const getContact = async () => {
      if (accessToken) {
        const response =
          await IdentityApi.getExternalSellerCustomerContactProfile(
            accessToken,
            'inquiry'
          );

        setContact(response);
      }
    };

    getContact();
  }, [accessToken]);

  /* Get customer profile */
  useEffect(() => {
    const getCustomer = async () => {
      if (accessToken) {
        const response = await IdentityApi.getExternalSellerCustomerProfile(
          accessToken,
          'inquiry'
        );

        setCustomer(response);
      }
    };

    getCustomer();
  }, [accessToken]);

  /* Calculate total of document */
  useEffect(() => {
    let sum = 0;

    for (const row of extendedRows) {
      sum += (row.price ?? 0) * (row.quantity ?? 0);
    }

    setDocumentTotal(sum);
  }, [extendedRows]);

  const handleRowChange = (
    rowChangeValues: ExternalSellerInquiryRowChangeType,
    rowNo: number,
    column: 'code' | 'name' | 'qty' | 'price' | 'expectedDelivery'
  ) => {
    const clonedExtendedRows = [...extendedRows];
    const {
      replacedProductCode,
      replacedProductName,
      quantity,
      price,
      expectedDelivery,
    } = rowChangeValues;

    const index = clonedExtendedRows.findIndex((row) => row.rowNo === rowNo);

    switch (column) {
      case 'code':
        if (clonedExtendedRows[index].isReplaced)
          clonedExtendedRows[index].replacedProductCode = replacedProductCode;
        if (clonedExtendedRows[index].isNew)
          clonedExtendedRows[index].productCode = replacedProductCode;
        break;

      case 'name':
        if (clonedExtendedRows[index].isReplaced)
          clonedExtendedRows[index].replacedProductName = replacedProductName;
        if (clonedExtendedRows[index].isNew)
          clonedExtendedRows[index].name = replacedProductName;
        break;

      case 'qty':
        clonedExtendedRows[index].quantity = quantity;
        break;

      case 'price':
        clonedExtendedRows[index].price =
          price && price !== '' ? parseFloat(price) : null;
        break;

      case 'expectedDelivery':
        clonedExtendedRows[index].expectedDelivery = expectedDelivery;
        break;
      default:
        break;
    }

    setExtendedRows(clonedExtendedRows);
    setLocalStorage(
      offerValidUntil,
      isoDate,
      headerExpectedDelivery,
      clonedExtendedRows
    );
  };

  useEffect(() => {
    getLocalStorageData();
  }, [getLocalStorageData]);

  const toggleShowReplacementInputs = (rowNo: number) => {
    const clonedExtendedRows = [...extendedRows];
    const index = clonedExtendedRows.findIndex((row) => row.rowNo === rowNo);

    clonedExtendedRows[index].showReplacebleInputs =
      !clonedExtendedRows[index].showReplacebleInputs;

    clonedExtendedRows[index].isReplaced =
      !clonedExtendedRows[index].isReplaced;

    if (!clonedExtendedRows[index].showReplacebleInputs) {
      clonedExtendedRows[index].replacedProductCode = null;
      clonedExtendedRows[index].replacedProductName = null;
    }

    setExtendedRows(clonedExtendedRows);
    setLocalStorage(
      offerValidUntil,
      isoDate,
      headerExpectedDelivery,
      clonedExtendedRows
    );
  };

  const handleDateChange = async (day: Date) => {
    const pickedDate = dayjs(day).format('DD-MM-YYYY');

    setOfferValidUntil(pickedDate);
    setIsoDate(dayjs(day).toISOString());

    setLocalStorage(
      pickedDate,
      dayjs(day).toISOString(),
      headerExpectedDelivery,
      extendedRows
    );
  };

  const disabledDays = {
    from: new Date(
      dayjs().startOf('month').year(),
      dayjs().startOf('month').month(),
      1
    ),
    to: new Date(),
  };

  const updateLocalOffer = useCallback(() => {
    if (accessToken !== null) {
      const finalRows = createExternalSellerOfferRows(extendedRows);

      const updateData: ExternalSellerOfferUpsertModel = {
        deliveryTerm: deliveryTerms,
        paymentTerm: paymentTerms,
        validUntil: isoDate,
        comment: yourComment,
        expectedDelivery: headerExpectedDelivery,
        rows: finalRows,
        allowSplitting: allowCherryPicking,
      };

      // await IdentityApi.createExternalSellerOffer(accessToken, updateData);

      setOffer(updateData);
    }
  }, [
    accessToken,
    allowCherryPicking,
    deliveryTerms,
    extendedRows,
    headerExpectedDelivery,
    isoDate,
    paymentTerms,
    yourComment,
  ]);

  useEffect(() => {
    updateLocalOffer();
  }, [updateLocalOffer]);

  const handleAddEmptyRow = () => {
    const clonedExtendedRows = [...extendedRows];

    const newRowNo =
      Math.max(...clonedExtendedRows.map((row) => row.rowNo)) + 1;

    clonedExtendedRows.push({
      rowNo: newRowNo,
      targetPrice: null,
      price: null,
      isNew: true,
      isReplaced: false,
    } as unknown as ExternalSellerInquiryRowExtendedModel);

    setExtendedRows(clonedExtendedRows);
    setLocalStorage(
      offerValidUntil,
      isoDate,
      headerExpectedDelivery,
      clonedExtendedRows
    );
  };

  const handleDeleteRow = (rowNo: number) => {
    const clonedExtendedRows = [...extendedRows];
    const filtered = clonedExtendedRows.filter((row) => row.rowNo !== rowNo);

    setExtendedRows(filtered);
  };

  const handleHeaderWeeksChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    const changedRows = bulkEditExternalSellerInquiryRows(extendedRows, {
      fieldName: 'expectedDelivery',
      fieldValue: {
        expectedDelivery: value,
      } as ExternalSellerInquiryRowChangeType,
    });

    setHeaderExpectedDelivery(value);

    setExtendedRows(changedRows);
    setLocalStorage(offerValidUntil, isoDate, value, changedRows);
  };

  const columns: TableColumn<ExternalSellerInquiryRowExtendedModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      sortable: false,
      width: '60px',
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode || 'productCode',
      cell: (row) => (
        <div className='d-flex align-items-start flex-column justify-content-start'>
          {row.showReplacebleInputs || row.isReplaced ? (
            <CustomExternalSellerRowInput
              isDocumentUneditable={isDocumentUneditable}
              cellValue={row.replacedProductCode}
              colName='code'
              updateRow={(updatedValue: string | null) =>
                handleRowChange(
                  {
                    replacedProductCode: updatedValue,
                  } as ExternalSellerInquiryRowChangeType,
                  row.rowNo,
                  'code'
                )
              }
            />
          ) : row.showReplacebleInputs || row.isNew ? (
            <CustomExternalSellerRowInput
              isDocumentUneditable={isDocumentUneditable}
              cellValue={row.productCode}
              colName='code'
              updateRow={(updatedValue: string | null) =>
                handleRowChange(
                  {
                    replacedProductCode: updatedValue,
                  } as ExternalSellerInquiryRowChangeType,
                  row.rowNo,
                  'code'
                )
              }
            />
          ) : (
            <span>{row.productCode}</span>
          )}
          {(row.showReplacebleInputs || row.isReplaced) && (
            <small>{row.productCode}</small>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name ?? 'name',
      cell: (row) => (
        <div className='d-flex align-items-start flex-column justify-content-start'>
          {row.showReplacebleInputs || row.isReplaced ? (
            <CustomExternalSellerRowInput
              isDocumentUneditable={isDocumentUneditable}
              cellValue={row.replacedProductName}
              colName='name'
              updateRow={(updatedValue: string | null) =>
                handleRowChange(
                  {
                    replacedProductName: updatedValue,
                  } as ExternalSellerInquiryRowChangeType,
                  row.rowNo,
                  'name'
                )
              }
            />
          ) : row.showReplacebleInputs || row.isNew ? (
            <CustomExternalSellerRowInput
              isDocumentUneditable={isDocumentUneditable}
              cellValue={row.name}
              colName='name'
              updateRow={(updatedValue: string | null) =>
                handleRowChange(
                  {
                    replacedProductName: updatedValue,
                  } as ExternalSellerInquiryRowChangeType,
                  row.rowNo,
                  'name'
                )
              }
            />
          ) : (
            <span>{row.name}</span>
          )}
          {(row.showReplacebleInputs || row.isReplaced) && (
            <small>{row.name}</small>
          )}
        </div>
      ),
      sortable: false,
      grow: 4,
    },
    {
      name: t('documents.lbl_ExpectedDelivery'),
      selector: (row) => row.expectedDelivery ?? 'expectedDelivery',
      cell: (row) => (
        <>
          <div className='d-flex align-items-end flex-column justify-content-start'>
            <CustomExternalSellerRowInput
              isDocumentUneditable={isDocumentUneditable}
              cellValue={row.expectedDelivery}
              colName='expectedDelivery'
              updateRow={(updatedValue: string | null) =>
                handleRowChange(
                  {
                    expectedDelivery: updatedValue,
                  } as unknown as ExternalSellerInquiryRowChangeType,
                  row.rowNo,
                  'expectedDelivery'
                )
              }
            />
          </div>
        </>
      ),
      sortable: false,
      center: true,
    },
    {
      name: t('documents.lbl_Qty'),
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          <div className='d-flex align-items-end flex-column justify-content-start'>
            {row.isNew && (
              <CustomExternalSellerRowInput
                isDocumentUneditable={isDocumentUneditable}
                colName='qty'
                cellValue={row.quantity}
                isNumberField
                updateRow={(updatedValue: number | null) =>
                  handleRowChange(
                    {
                      quantity: updatedValue,
                    } as ExternalSellerInquiryRowChangeType,
                    row.rowNo,
                    'qty'
                  )
                }
              />
            )}
            {!row.isNew && (
              <span>
                {row.quantity} <small>{validateUnitExists(row.unit)}</small>
              </span>
            )}
          </div>
        </>
      ),
      maxWidth: '70px',
      sortable: false,
      right: true,
    },
    {
      name: t('documents.lbl_TargetPrice'),
      selector: (row) => row.targetPrice,
      cell: (row) =>
        `${formatMonetaryValueWithoutDecimals(
          inquiry.currencyCode,
          row.targetPrice,
          appState
        )}`,
      sortable: false,
      right: true,
    },
    {
      name: t('documents.lbl_PriceUnit'),
      selector: (row) => row.price ?? 'price',
      cell: (row) =>
        isDocumentUneditable ? (
          row.price ?? '-'
        ) : (
          <CustomExternalSellerRowInput
            isDocumentUneditable={isDocumentUneditable}
            colName='price'
            cellValue={row.price}
            rowNo={row.rowNo}
            rowsLength={extendedRows.length}
            updateRow={(updatedValue: number | null) =>
              handleRowChange(
                { price: updatedValue } as ExternalSellerInquiryRowChangeType,
                row.rowNo,
                'price'
              )
            }
          />
        ),
      sortable: false,
      center: true,
    },

    {
      name: t('documents.lbl_Replace'),
      selector: (row) => row.allowReplacement,
      cell: (row) =>
        row.isNew ? (
          <Trash
            className='icon-ez-red cursor-pointer'
            onClick={() => handleDeleteRow(row.rowNo)}
          />
        ) : row.allowReplacement ? (
          row.showReplacebleInputs ? (
            <ArrowRepeat
              onClick={() => toggleShowReplacementInputs(row.rowNo)}
              className='icon-ez-red cursor-pointer'
            />
          ) : (
            <ArrowRepeat
              onClick={() => toggleShowReplacementInputs(row.rowNo)}
              className='icon-ez cursor-pointer'
            />
          )
        ) : (
          <ArrowRepeat className='icon-ez-gray' />
        ),
      sortable: false,
      center: true,
      omit: isDocumentUneditable,
    },

    {
      name: t('common.lbl_Total'),
      selector: (row) => 'total',
      cell: (row) =>
        formatMonetaryValue(
          inquiry.currencyCode,
          (row.price ?? 0) * (row.quantity ?? 0),
          appState
        ),
      right: true,
    },
  ];

  const inputFile = useRef<HTMLInputElement>(null);

  const handleOpenFilePicker = () => {
    inputFile.current !== null && inputFile.current.click();
  };

  const handleDeleteAttachment = async (id: number) => {
    let timeout: any = 0;

    toast.success(t('projectSettings.inf_DeletingFile'), {
      className: 'delete-attachment-toast',
      autoClose: 5000,
      pauseOnHover: true,
      closeButton: (
        <Button
          className='btn-undo-delete-attachment'
          onClick={() => clearTimeout(timeout)}
        >
          <ArrowCounterclockwise size={20} />
        </Button>
      ),
    });

    timeout = setTimeout(async () => {
      accessToken &&
        (await IdentityApi.deleteExternalSellerOfferAttachment(
          accessToken,
          id,
          'Offer'
        ));

      await getInquiry();
    }, 5000);
  };

  const handleAddNewFile = async (file: File) => {
    const reader = new FileReader();
    const formData = new FormData();

    formData.append('file', file);
    formData.append('documentType', 'Offer');

    const input: HTMLInputElement | null =
      document.querySelector('input[type=file]');

    if (input !== null) {
      input.value = '';
    }

    reader.readAsDataURL(file);
    reader.onload = async function () {
      reader.result !== undefined &&
        accessToken &&
        (await IdentityApi.addExternalSellerOfferAttachment(
          accessToken,
          formData
        ));

      await getInquiry();
    };
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <SellerHeader
        requiresNoAuth
        localizationId={inquiry.localizationId ?? 1}
      />
      <div className='container-fluid p-2'>
        <Card>
          <Card.Header>
            <Row>
              <Col lg={4}>
                <ExternalSellerCustomerProfileModal company={customer} />
                <ExternalSellerContactProfileModal
                  companyName={customer.name}
                  contact={contact}
                />

                <div className='d-flex align-items-center'>
                  <Dropdown className='me-4' as={ButtonGroup}>
                    <Dropdown.Toggle
                      id='project-view-attachments-toggle'
                      className='btn btn-eleczap dropdown-toggle ms-3'
                    >
                      <Paperclip /> {t('common.btn_AllFiles')}(
                      {inquiry.attachments?.length})
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='super-colors'>
                      {inquiry.attachments?.map((file, index: number) => (
                        <ExternalSellerAttachmentItem
                          handleDeleteAttachment={handleDeleteAttachment}
                          attachment={file}
                          index={index}
                          accessToken={''}
                          type={'Offer'}
                        />
                      ))}

                      <Dropdown.Divider />
                      <FilePicker
                        maxSize={10}
                        sizeUnit='MB'
                        extensions={[
                          '.jpg',
                          '.png',
                          '.jpeg',
                          '.pdf',
                          '.txt',
                          '.doc',
                          '.docx',
                          '.xls',
                          '.xlsx',
                          '.ppt',
                          '.pptx',
                          '.dwg',
                          '.dxf',
                        ]}
                        onFilePicked={(file) => {
                          handleAddNewFile(file);
                        }}
                        onError={(code) => {
                          toast.error(code);
                        }}
                      >
                        <Dropdown.Item
                          onClick={handleOpenFilePicker}
                          eventKey='4'
                        >
                          {t('projectSettings.lbl_AddNewAttachment')}
                        </Dropdown.Item>
                      </FilePicker>
                    </Dropdown.Menu>
                  </Dropdown>

                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>{t('documents.lbl_AllowCherryPicking')}</Tooltip>
                    }
                  >
                    <div className='d-flex justify-content-center'>
                      <Switch
                        checked={allowCherryPicking}
                        onChange={(checked) => setAllowCherryPicking(checked)}
                        onColor='#1b9ad2'
                        className='react-switch'
                        id='icon-switch'
                        checkedHandleIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 15,
                              paddingRight: 2,
                            }}
                          >
                            <GiCherry className='icon-ez' />
                          </div>
                        }
                        uncheckedHandleIcon={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              fontSize: 15,
                              paddingRight: 2,
                            }}
                          >
                            <GiCherry className='icon-ez-gray' />
                          </div>
                        }
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </Col>
              <Col className='text-center' lg={4}>
                <h2>
                  {t('documents.hd_Offer')} {inquiry.code}
                </h2>
              </Col>
              <Col
                className='text-center d-flex justify-content-lg-end justify-content-sm-center'
                lg={4}
              >
                <h3 className='d-flex align-items-center'>
                  {inquiry.status === 'Sending' && (
                    <>
                      {t('documents.lbl_Sending')}{' '}
                      <InquirySending className='icon-ez-gray ms-1' />
                    </>
                  )}
                  {inquiry.status === 'Unsent' && (
                    <>
                      {t('documents.lbl_Unsent')}{' '}
                      <InquiryDisabled className='icon-ez-gray ms-1' />
                    </>
                  )}
                  {inquiry.status === 'NoReply' && (
                    <>
                      {t('documents.lbl_NoReply')}{' '}
                      <InquiryNoReplySeller className='icon-ez-yellow ms-1' />
                    </>
                  )}
                  {inquiry.status === 'OfferMade' && (
                    <>
                      {t('documents.lbl_OfferMade')}{' '}
                      <InquiryOfferSent className='icon-ez ms-1' />
                    </>
                  )}

                  {inquiry.status === 'Declined' && (
                    <>
                      {t('documents.lbl_Declined')}{' '}
                      <InquiryDeclined className='icon-ez-red ms-1' />
                    </>
                  )}

                  {inquiry.status === 'Expired' && (
                    <>
                      {t('documents.lbl_Expired')}{' '}
                      <OfferExpired className='icon-ez-gray' />
                    </>
                  )}
                </h3>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <Row className='mb-0'>
                  <Col className='document-header-left-col text-nowrap'>
                    {t('documents.lbl_InquiryReceived')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(inquiry.documentDate).format('DD.MM.YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_OfferDeadline')}:
                  </Col>
                  <Col>
                    <strong
                      className={
                        dayjs().isAfter(inquiry.offerDeadlineDate)
                          ? 'icon-ez-red'
                          : ''
                      }
                    >
                      {inquiry.offerDeadlineDate === null
                        ? '-'
                        : dayjs(inquiry.offerDeadlineDate).format('DD.MM.YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('projectSettings.lbl_RequestedDeliveryDate')}:
                  </Col>
                  <Col>
                    <strong>
                      {inquiry.requestedDeliveryDate
                        ? dayjs(inquiry.requestedDeliveryDate).format(
                            'DD.MM.YYYY'
                          )
                        : '-'}
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_InquiryTo')}:
                  </Col>
                  <Col>
                    <strong>{inquiry.sentToContactName}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'></Col>
                  <Col>
                    <strong>{inquiry.sentToCompanyName}</strong>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row className='flex-nowrap'>
                  <Col className='text-end pe-0 text-nowrap'>
                    <Row className='mb-0'>
                      <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                    </Row>
                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_CustomerInquiryNumber')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                    </Row>

                    {inquiry.deliveryAddressLine2 !== null &&
                      inquiry.deliveryAddressLine2 !== '' && <br />}

                    {inquiry.deliveryAddressLine3 !== null &&
                      inquiry.deliveryAddressLine3 !== '' && <br />}

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_DeliveryTerms')}:</Col>
                    </Row>
                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_PaymentTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='required'>
                        {t('documents.lbl_OfferValidUntil')}:
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_ExpectedDelivery')}:</Col>
                    </Row>
                  </Col>

                  <Col
                    className='pe-3 d-flex flex-column'
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        <strong>{inquiry.projectName}</strong>
                      </Col>
                    </Row>
                    <Row className='mb-0'>
                      <span className='text-start document-header-right-col'>
                        {inquiry.code !== null && inquiry.code !== '' ? (
                          <strong>{inquiry.code}</strong>
                        ) : (
                          <br />
                        )}
                      </span>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='d-flex flex-column flex-nowrap text-nowrap'>
                        {inquiry.deliveryAddressLine1 !== null ? (
                          <strong>{inquiry.deliveryAddressLine1}</strong>
                        ) : (
                          <br />
                        )}
                        <strong>
                          {inquiry.deliveryAddressLine2 !== null ||
                          inquiry.deliveryAddressLine2 !== '' ? (
                            inquiry.deliveryAddressLine2
                          ) : (
                            <br />
                          )}
                        </strong>
                        <strong>
                          {inquiry.deliveryAddressLine3 !== null ||
                          inquiry.deliveryAddressLine3 !== '' ? (
                            inquiry.deliveryAddressLine3
                          ) : (
                            <br />
                          )}
                        </strong>
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>
                        {!isDocumentUneditable ? (
                          <FormControl
                            className='shorter-height-fields'
                            maxLength={25}
                            max={25}
                            value={deliveryTerms}
                            onChange={(event) => {
                              setDeliveryTerms(event.target.value);
                              setLocalStorage(
                                offerValidUntil,
                                isoDate,
                                headerExpectedDelivery,
                                extendedRows
                              );
                            }}
                          />
                        ) : (
                          <span>{deliveryTerms ?? <br />}</span>
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>
                        {!isDocumentUneditable ? (
                          <FormControl
                            className='shorter-height-fields'
                            maxLength={25}
                            max={25}
                            value={paymentTerms}
                            disabled={isDocumentUneditable}
                            onChange={(event) => {
                              setPaymentTerms(event.target.value);
                              setLocalStorage(
                                offerValidUntil,
                                isoDate,
                                headerExpectedDelivery,
                                extendedRows
                              );
                            }}
                          />
                        ) : (
                          <span>{paymentTerms ?? <br />}</span>
                        )}
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>
                        {isDocumentUneditable ? (
                          <span>
                            {offerValidUntil
                              ? dayjs(offerValidUntil).format('DD-MM-YYYY')
                              : '-'}
                          </span>
                        ) : (
                          <DayPickerInput
                            dayPickerProps={{
                              fromMonth: new Date(),
                              disabledDays: disabledDays,
                            }}
                            style={{ width: '100%' }}
                            component={(props: any) => (
                              <InputGroup>
                                <FormControl
                                  readOnly
                                  className='shorter-height-fields'
                                  {...props}
                                />
                              </InputGroup>
                            )}
                            value={offerValidUntil || t('common.lbl_DDMMYYYY')}
                            onDayChange={handleDateChange}
                          />
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Select
                          style={{
                            height: '22px',
                            fontSize: '10px',
                          }}
                          value={headerExpectedDelivery ?? 'null'}
                          onChange={handleHeaderWeeksChange}
                        >
                          <option value={'null'}>-</option>
                          <option value='In stock'>
                            {t('common.lbl_Instock')}
                          </option>
                          {Array.from(Array(30).keys()).map((week, index) => (
                            <option
                              value={`${index + 1} ${
                                index === 0 ? 'Week' : 'Weeks'
                              }`}
                            >{`${index + 1} ${
                              index === 0
                                ? t('documents.lbl_Week')
                                : t('documents.lbl_Weeks')
                            }`}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='my-5'>
              <DataTable
                noDataComponent={
                  <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
                }
                columns={columns}
                data={extendedRows}
              />
              {!isDocumentUneditable && (
                <span
                  onClick={handleAddEmptyRow}
                  className='icon-ez ms-3 cursor-pointer'
                >
                  <PlusCircleFill className='icon-ez me-3' />{' '}
                  {t('documents.lbl_AddNewRow')}
                </span>
              )}
            </Row>

            <Row className='mb-3'>
              <Col lg={6}>
                <span>{t('documents.lbl_CustomerComments')} : </span>{' '}
                {inquiry.comment}
              </Col>
            </Row>

            <Row className='justify-content-between'>
              <Col lg={6}>
                <Form.Group className='mb-3' controlId='comments'>
                  <Form.Label>{t('documents.lbl_YourComments')}</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={4}
                    disabled={isDocumentUneditable}
                    value={yourComment}
                    onChange={(event) => {
                      setYourComment(event.target.value);
                      setLocalStorage(
                        offerValidUntil,
                        isoDate,
                        headerExpectedDelivery,
                        extendedRows
                      );
                    }}
                  />
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Row className='mt-3'>
                  <Col>
                    <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                  </Col>
                  <Col>
                    <h3 className='text-end'>
                      {useFormatMonetaryValue(
                        inquiry.currencyCode,
                        documentTotal
                      )}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-end'>
                    {t('common.inf_TotalPriceSubtext')}
                  </Col>
                </Row>
              </Col>
            </Row>
            {!isDocumentUneditable && (
              <Row>
                <Col>
                  <ExternalSellerDeclineModal
                    getInquiry={getInquiry}
                    accessToken={accessToken}
                  />
                </Col>
                <Col>
                  <span className='float-end'>
                    <ExternalSellerSendOfferModal
                      accessToken={accessToken}
                      customerName={inquiry.sentByCompanyName}
                      count={
                        extendedRows.filter((row) => row.price !== null).length
                      }
                      totalRows={extendedRows.length}
                      offer={offer}
                      offerValidUntil={offerValidUntil}
                    />
                  </span>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </div>
      <SellerFooter />
    </>
  );
};

import { useFormikHookForNewProjectModal } from 'components/buyer/project/CreateNewProjectModal/hooksCreateNewProjectModal';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropsType = {
  open: boolean;
  redirectToProject?: boolean;
  handleClosed: () => void;
  handleSubmitted?: (newProjectId: number) => void;
  setIsEditing?: (value: boolean) => void;
};

export const CreateNewProjectModal = ({
  open,
  redirectToProject = false,
  handleClosed,
  handleSubmitted,
  setIsEditing,
}: PropsType) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
    handleClosed();
    setIsEditing && setIsEditing(false);
  };

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');

  const formik = useFormikHookForNewProjectModal(
    currentUser,
    setShow,
    t,
    handleClosed,
    handleSubmitted
  );

  const handleSubmit = () => {
    formik.handleSubmit();
    setIsEditing && setIsEditing(false);
  };

  return (
    <>
      <Modal
        className='create-new-project-modal'
        size='sm'
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('projectSettings.hd_CreateNewProject')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('projectSettings.inf_NewProjectPart1')}
            <br />
            {t('projectSettings.inf_NewProjectPart2')}
          </p>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Control
                required
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder={t('projectSettings.plh_ProjectName')}
                name='name'
                type='input'
                isInvalid={formik.touched.name && !!formik.errors.name}
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='btn btn-eleczap' onClick={handleSubmit}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import 'bootstrap/dist/css/bootstrap.min.css';
import { useGetAvailableCountries } from 'hooks/useGetAvailabeCountries';
import { AnyAdminRole, useRBAC } from 'hooks/useRBAC';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { CompanyModel } from 'models/shared/company/CompanyModel';
import { CountryModel } from 'models/shared/country/CountryModel';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Spinner } from 'react-bootstrap';
import { Save } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { useFormikHook } from './hooksCompany';

type PropTypes = {
  company: CompanyModel;
};

export const Company = ({ company }: PropTypes) => {
  const [countries, setCountries] = useState<CountryModel[]>([]);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');

  const formik = useFormikHook(company, currentUser, t);

  const hasRightsToEdit = useRBAC(AnyAdminRole);

  useGetAvailableCountries(setCountries);

  return (
    <Row>
      <Col style={{ maxWidth: '1056px' }}>
        <Card className='p-4'>
          <Card.Header>
            <h4>{t('companySettings.hd_CompanyInformation')}</h4>
          </Card.Header>
          <Card.Body>
            <div className='row'>
              <div className='col'>
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col className='mx-3'>
                      <Form.Group as={Row} controlId='validationName'>
                        <Form.Label>
                          {t('companySettings.lbl_CompanyName')}
                        </Form.Label>
                        <Form.Control
                          required
                          type='text'
                          placeholder={t('companySettings.plh_CompanyName')}
                          value={formik.values.name}
                          disabled
                        />
                      </Form.Group>
                      <Form.Group as={Row} controlId='validationRegistryNumber'>
                        <Form.Label>
                          {t('companySettings.lbl_RegisterNumber')}
                        </Form.Label>
                        <Form.Control
                          required
                          type='text'
                          placeholder='Registry number'
                          value={formik.values.registryNumber}
                          disabled
                        />
                      </Form.Group>
                      <Form.Group as={Row} controlId='validationAddressLine1'>
                        <Form.Label>
                          {t('addressSettings.lbl_AddressLine1')}
                        </Form.Label>
                        <Form.Control
                          value={formik.values.addressLine1}
                          onChange={formik.handleChange}
                          required
                          name='addressLine1'
                          type='text'
                          placeholder={t('addressSettings.lbl_AddressLine1')}
                          disabled={!hasRightsToEdit}
                          isInvalid={
                            formik.touched.addressLine1 &&
                            !!formik.errors.addressLine1
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          {formik.errors.addressLine1}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Row className='mb-3'>
                        <Form.Group
                          as={Col}
                          className='p-0 me-2'
                          controlId='validationPostalCode'
                        >
                          <Form.Label>
                            {t('addressSettings.lbl_PostalCode')}
                          </Form.Label>
                          <Form.Control
                            value={formik.values.postalCode}
                            onChange={formik.handleChange}
                            type='text'
                            name='postalCode'
                            placeholder={t('addressSettings.plh_PostalCode')}
                            required
                            disabled={!hasRightsToEdit}
                            isInvalid={
                              formik.touched.postalCode &&
                              !!formik.errors.postalCode
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {formik.errors.postalCode}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          className='p-0'
                          controlId='validationCity'
                        >
                          <Form.Label>
                            {t('addressSettings.lbl_City')}
                          </Form.Label>
                          <Form.Control
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            type='text'
                            name='city'
                            disabled={!hasRightsToEdit}
                            placeholder={t('addressSettings.plh_City')}
                            required
                            isInvalid={
                              formik.touched.city && !!formik.errors.city
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {formik.errors.city}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Form.Group as={Row} controlId='validationPhone'>
                        <Form.Label>{t('userSettings.lbl_Phone')}</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          placeholder={t('userSettings.plh_Phone')}
                          name='phone'
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          disabled={!hasRightsToEdit}
                          isInvalid={
                            formik.touched.phone && !!formik.errors.phone
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          {formik.errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Row} controlId='validationWebSite'>
                        <Form.Label>
                          {t('companySettings.lbl_Website')}
                        </Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='webSite'
                          placeholder={t('companySettings.plh_Website')}
                          value={formik.values.webSite}
                          onChange={formik.handleChange}
                          disabled={!hasRightsToEdit}
                          isInvalid={
                            formik.touched.webSite && !!formik.errors.webSite
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          {formik.errors.webSite}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className='mx-3'>
                      <Form.Group as={Row} controlId='validationShortName'>
                        <Form.Label>
                          {t('companySettings.lbl_ShortName')}
                        </Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='shortName'
                          placeholder={t('companySettings.plh_ShortName')}
                          value={formik.values.shortName}
                          onChange={formik.handleChange}
                          disabled={!hasRightsToEdit}
                          isInvalid={
                            formik.touched.shortName &&
                            !!formik.errors.shortName
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          {formik.errors.shortName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Row} controlId='validationVatNumber'>
                        <Form.Label>
                          {t('companySettings.lbl_VatNumber')}
                        </Form.Label>
                        <Form.Control
                          required
                          type='text'
                          placeholder={t('companySettings.plh_VatNumber')}
                          value={formik.values.vatNumber}
                        />

                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Row} controlId='validationAddressLine2'>
                        <Form.Label>
                          {t('addressSettings.lbl_AddressLine2')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder={t('addressSettings.plh_AddressLine2')}
                          name='addressLine2'
                          value={formik.values.addressLine2}
                          onChange={formik.handleChange}
                          disabled={!hasRightsToEdit}
                          isInvalid={
                            formik.touched.addressLine2 &&
                            !!formik.errors.addressLine2
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          {formik.errors.addressLine2}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Row className='mb-3'>
                        <Form.Group
                          as={Col}
                          className='p-0 me-2'
                          controlId='validationState'
                        >
                          <Form.Label>
                            {t('addressSettings.lbl_State')}
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder={t('addressSettings.plh_State')}
                            name='state'
                            required
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            disabled={!hasRightsToEdit}
                            isInvalid={
                              formik.touched.state && !!formik.errors.state
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {formik.errors.state}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          className='p-0'
                          controlId='validationCountryCode'
                        >
                          <Form.Label>
                            {t('addressSettings.lbl_Country')}
                          </Form.Label>
                          <Form.Select
                            name='country.code'
                            required
                            placeholder={t('addressSettings.plh_Country')}
                            disabled={!hasRightsToEdit}
                            value={
                              formik.values.country !== undefined
                                ? formik.values.country.code
                                : 'AD'
                            }
                            onChange={formik.handleChange}
                          >
                            {countries.map((country, index) => (
                              <option key={index} value={country.code}>
                                {country.shortName}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Row>
                      <Form.Group as={Row} controlId='validationEmail'>
                        <Form.Label>{t('userSettings.lbl_Email')}</Form.Label>
                        <Form.Control
                          required
                          name='email'
                          placeholder={t('userSettings.plh_Email')}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          disabled={!hasRightsToEdit}
                          isInvalid={
                            formik.touched.email && !!formik.errors.email
                          }
                        />
                        <Form.Control.Feedback type='invalid'>
                          {formik.errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {hasRightsToEdit && (
                    <Button
                      variant='btn btn-eleczap mt-3'
                      className='float-end'
                      disabled={formik.isSubmitting}
                      type='submit'
                    >
                      <Save className='mb-1 me-1' />
                      {t('common.btn_Save')}{' '}
                      {formik.isSubmitting && (
                        <Spinner animation='border' size='sm' />
                      )}
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

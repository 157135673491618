import { SellerApi } from 'api/SellerApi';
import { SellerProductList } from 'components/seller/products/Product/SellerProductList';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductModel } from 'models/shared/product/ProductModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Spinner } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { getUrlParams } from 'utils/getUrlParams';

export const Search = () => {
  const { t } = useTranslation('components');

  const [products, setproducts] = useState<ProductModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [mounted, setMounted] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const [totalProducts, setTotalProducts] = useState<number>(0);

  const pageNumber = 1;
  const orderBy = ''; // No ordering in 1st version
  const pageSize = 500; // No paging in 1st version

  const searchString = getUrlParams('search') || '';

  const selectedCustomerId = appState.sellerCurrentCustomerId;
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const findProducts = useCallback(
    async (didCancel: boolean, orderBy?: string) => {
      setIsLoading(true);
      const productsResponse = await SellerApi.findProducts(
        currentUser.companyId,
        selectedCustomerId,
        searchString,
        pageNumber,
        orderBy || '',
        pageSize,
        userLocaleId,
        false
      );
      if (!didCancel) {
        setproducts(productsResponse.data);
        setTotalProducts(productsResponse.totalItems);
      }
      setIsLoading(false);
    },
    [currentUser.companyId, searchString, selectedCustomerId, userLocaleId]
  );

  useEffect(() => {
    let didCancel = false;

    findProducts(didCancel);

    return () => {
      didCancel = true;
    };
  }, [
    currentUser.companyId,
    searchString,
    userLocaleId,
    selectedCustomerId,
    findProducts,
  ]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const searchSummary = `${t(
    'common.lbl_YouSearchedFor'
  )} ${searchString}: ${totalProducts} ${t('common.lbl_Products')}`;

  const BuyerBreadcrumbNode = (
    <h5 className='pt-2 ms-2 p-0 seller-breadcrumb'>{searchSummary}</h5>
  );
  return isLoading ? (
    <Spinner className='center-spinner' animation='border' />
  ) : (
    <>
      {products.length > 0 ? (
        <>
          {mounted &&
            ReactDOM.createPortal(
              BuyerBreadcrumbNode,
              document &&
                document.getElementsByClassName('fixed-portal-receiver')[0]
            )}
          <SellerProductList
            isLoading={isLoading}
            items={products}
            productDetailRoute={'/seller/product-details'}
            getProducts={findProducts}
          />
        </>
      ) : (
        <div className='text-center' style={{ marginTop: '150px' }}>
          <h3>{t('productListTile.inf_NoProductResults1')}</h3>
          <h3>{t('productListTile.inf_NoProductResults2')}</h3>
        </div>
      )}
    </>
  );
};

import { SellerApi } from 'api/SellerApi';
import { getAllStocksSharedToPartners } from 'components/seller/settings/stocks/StocksSharedToPartners/utilsStocksSharedToPartners';
import { useFormik } from 'formik';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksMyModel } from 'models/shared/stocksMy/StocksMyModel';
import {
  ShareStockModel,
  StocksSharedToPartnersModel,
} from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* Formik init */
export const useFormikHookForShareStockWithPartner = (
  currentUser: LoginResponseUserModel,
  stock: StocksMyModel,
  setShow: (values: boolean) => void,
  t: any,
  setStocksSharedToPartners: (values: StocksSharedToPartnersModel[]) => void
): any => {
  const handleSubmit = async (values: {
    userName: string;
    shareArriving: boolean;
    priceformulaCode: string;
  }): Promise<void> => {
    toast.dismiss();

    const stockToShareData: ShareStockModel = {
      stockId: stock.id,
      userName: values.userName,
      shareArriving: values.shareArriving,
      acceptanceUrl: `${window && window.location.origin}/seller/settings`,
      helpUrl: '',
      priceformulaCode: values.priceformulaCode,
    };

    await SellerApi.shareStock(currentUser.companyId, stockToShareData);
    toast.success(t('stockSettings.inf_InvitationSent'));
  };

  const validationSchema = yup.object({
    userName: yup.string().email().required(t('common.err_ValueRequired')),
    priceformulaCode: yup.string().required(t('common.err_ValueRequired')),
  });

  const initialValues: {
    userName: string;
    shareArriving: boolean;
    priceformulaCode: string;
  } = {
    userName: '',
    shareArriving: false,
    priceformulaCode: '',
  };

  const formik = useFormik<{
    userName: string;
    shareArriving: boolean;
    priceformulaCode: string;
  }>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const shareStock: {
        userName: string;
        shareArriving: boolean;
        priceformulaCode: string;
      } = {
        userName: values.userName,
        shareArriving: values.shareArriving,
        priceformulaCode: values.priceformulaCode,
      };
      await handleSubmit(shareStock);
      //   await getAllMyStocks(currentUser, setStocks);
      await getAllStocksSharedToPartners(
        currentUser,
        setStocksSharedToPartners
      );
      setShow(false);
      formik.resetForm();
      formik.setSubmitting(false);
    },
  });

  return formik;
};

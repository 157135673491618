import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { useRefreshAllSuppliers } from 'hooks/useRefreshAllSuppliers';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Modal } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  supplierName: string;
  supplierId: number;
  refreshSuppliers: () => void;
};

export const ExternalSupplierRemove = ({
  supplierName,
  supplierId,
  refreshSuppliers,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState, appContextDispatch } = useContext(AppContext);

  const refreshAllSuppliers = useRefreshAllSuppliers();

  const handleRemoveSupplier = async () => {
    toast.dismiss();
    await BuyerApi.softDeleteExternalSeller(currentUser.companyId, supplierId);

    refreshSuppliers();

    await refreshAllSuppliers();

    toast.info(t('externalSupplier.inf_ExternalSupplierRemovedSuccesfully'));
    setShow(false);
  };

  return (
    <>
      <Trash
        onClick={() => setShow(true)}
        className='icon-ez-red cursor-pointer'
      />

      <Modal
        show={show}
        size='sm'
        id='remove-external-supplier'
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('externalSupplier.hd_RemoveSupplier')}</Modal.Title>
        </Modal.Header>

        <Modal.Body className='p-4'>
          <p>{t('externalSupplier.inf_RemoveSupplier', { supplierName })}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShow(false)}>
            {t('common.btn_Cancel')}
          </Button>
          <Button variant='danger' onClick={handleRemoveSupplier}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { BuyerProductClassWithSubclassesModel } from 'models/buyer/product/BuyerProductClassWithSubclassesModel';
import { SellerProductClassWithSubclassesModel } from 'models/seller/product/SellerProductClassWithSubclassesModel';
import { ProductClassWithProducts } from './ProductClassWithProducts';
import { ProductClassWithSubclasses } from './ProductClassWithSubclasses';

type PropsType = {
  items:
    | SellerProductClassWithSubclassesModel[]
    | BuyerProductClassWithSubclassesModel[];
  productListRoute: string;
  productSubClassesRoute: string;
  imageReadOnly: boolean;
  handleProductClassImageUpload: (productClassId: number) => void;
};

export const ProductClassesList = ({
  items,
  productListRoute,
  productSubClassesRoute,
  imageReadOnly,
  handleProductClassImageUpload
}: PropsType) => {
 
  return (
    <>
      {items.map((productClass, index) => {
        switch (productClass.linkType) {
          case 'Subclasses':
            return (
              <ProductClassWithSubclasses
                handleProductClassImageUpload={handleProductClassImageUpload}
                imageReadonly={imageReadOnly}
                index={index}
                key={`pc${productClass.id}`}
                item={productClass}
                productListRoute={productListRoute}
                productSubClassesRoute={productSubClassesRoute}
              />
            );
          case 'Products':
            return (
              <ProductClassWithProducts
              handleProductClassImageUpload={handleProductClassImageUpload}
                imageReadonly={imageReadOnly}
                key={`pp${productClass.id}`}
                item={productClass}
                productListRoute={productListRoute}
              />
            );
          case 'Favorites':
            return null;
          case 'ThisClassProducts':
            return <div></div>;
          default:
            return (
              <ProductClassWithSubclasses
                handleProductClassImageUpload={handleProductClassImageUpload}
                index={index}
                imageReadonly={imageReadOnly}
                key={`pd${productClass.id}`}
                item={productClass}
                productListRoute={productListRoute}
                productSubClassesRoute={productSubClassesRoute}
              />
            );
        }
      })}
      {/* <div style={{ height: '500px' }} /> */}
    </>
  );
};

import { AppContext } from 'contexts/user/AppContext';
import { RowMOQPriceModel } from 'models/buyer/common/RowMOQPriceModel';
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';

type PropsType = {
  moqs: RowMOQPriceModel[];
  uniqueMoq: number;
};

export const ProductMoqPrice = ({ moqs, uniqueMoq }: PropsType) => {
  const { appState } = useContext(AppContext);
  return (
    <>
      <Col className='ms-1 pt-1 pb-1 text-center multiple-seller-card-product-max-width'>
        {moqs.map((moq) => (
          <Row className='justify-content-center'>
            {uniqueMoq === moq.moq &&
              formatMonetaryValue(
                moq.currencyCode,
                moq.price,
                appState,
                moq.decimalPlaces
              )}
          </Row>
        ))}
      </Col>
    </>
  );
};

import { ApplicationSettingsApi } from 'api/ApplicationSettingsApi';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { CustomerUserTable } from 'components/seller/customers/CustomerTables/CustomerUserTable';
import { UsersTable } from 'components/seller/customers/CustomerTables/UsersTable';
import { ModalInviteCustomer } from 'components/seller/customers/ModalInviteCustomer/ModalInviteCustomer';
import {
  useGetAllCustomers,
  useGetAllCustomersUsers,
} from 'components/seller/customers/hooksCustomer';
import {
  deleteCustomer,
  getAllCustomers,
  getAllCustomersUsers,
  getCustomerPricelistExport,
  reinviteCustomer,
} from 'components/seller/customers/utilsCustomer';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { AppContext } from 'contexts/user/AppContext';
import { useSaveFileAs } from 'hooks/useSaveFileAs';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  BuyersCompaniesAndUsersDataModel,
  BuyersCompaniesAndUsersModel,
} from 'models/seller/customers/BuyersCompaniesAndUsersModel';
import { BuyersUsersModel } from 'models/seller/customers/BuyersUsersModel';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './customer.css';

export const CustomersContainer = () => {
  const [customers, setCustomers] = useState<BuyersCompaniesAndUsersModel>(
    {} as BuyersCompaniesAndUsersModel
  );
  const [customersUsers, setCustomersUsers] = useState<BuyersUsersModel>(
    {} as BuyersUsersModel
  );
  const [customerFilter, setCustomerFilter] = useState<string>('');

  /* Search customers and users states */
  const [resetPaginationToggle, setResetPaginationToggle] =
    useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [localizations, setLocalizations] = useState<LocalizationModel[]>([]);
  const saveFile = useSaveFileAs();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);
  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const { t } = useTranslation('components');

  useEffect(() => {
    const getLocalizations = async () => {
      const localizations = await ApplicationSettingsApi.getLocalizations();
      setLocalizations(localizations);
    };
    getLocalizations();
  }, []);

  useGetAllCustomers(currentUser, setCustomers);
  useGetAllCustomersUsers(currentUser, setCustomersUsers);

  const handleChangeSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const inputQuery = event.target.value;
    setQuery(query);
    setResetPaginationToggle(false);
    customerFilter === 'users'
      ? await getAllCustomersUsers(
          currentUser,
          setCustomersUsers,
          pageNumber,
          inputQuery,
          rowsPerPage,
          sortColumn
        )
      : await getAllCustomers(
          currentUser,
          setCustomers,
          pageNumber,
          inputQuery,
          rowsPerPage,
          sortColumn
        );

    setResetPaginationToggle(true);
  };

  const searchAPIDebounced = AwesomeDebouncePromise(handleChangeSearch, 500);

  const handlePageChange = async (page: number) => {
    setPageNumber(page);
    customerFilter === 'users'
      ? await getAllCustomersUsers(
          currentUser,
          setCustomersUsers,
          page,
          query,
          rowsPerPage,
          sortColumn
        )
      : await getAllCustomers(
          currentUser,
          setCustomers,
          page,
          query,
          rowsPerPage,
          sortColumn
        );
  };

  const handleRowsPerChange = async (rowsAmount: number) => {
    setRowsPerPage(rowsAmount);
    customerFilter === 'users'
      ? await getAllCustomersUsers(
          currentUser,
          setCustomersUsers,
          pageNumber,
          query,
          rowsAmount,
          sortColumn
        )
      : await getAllCustomers(
          currentUser,
          setCustomers,
          pageNumber,
          query,
          rowsAmount,
          sortColumn
        );
  };
  const handleOnSort = async (selectedColumn: any, sortDirection: any) => {
    const sortingColumn =
      sortDirection === 'desc'
        ? `${selectedColumn.selector}_${sortDirection}`
        : selectedColumn.selector;
    setSortColumn(sortingColumn);
    customerFilter === 'users'
      ? await getAllCustomersUsers(
          currentUser,
          setCustomersUsers,
          pageNumber,
          query,
          rowsPerPage,
          sortDirection
        )
      : await getAllCustomers(
          currentUser,
          setCustomers,
          pageNumber,
          query,
          rowsPerPage,
          sortDirection
        );
  };

  const handlePriceListExport = async (
    compnanyId: number,
    setFileIsSaving: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setFileIsSaving(true);

    const fileBlob = await getCustomerPricelistExport(
      currentUser,
      compnanyId,
      userLocaleId
    );
    saveFile(fileBlob);

    setFileIsSaving(false);
  };

  const handleDeleteCustomer = async (id: number) => {
    await deleteCustomer(currentUser, id);

    await getAllCustomers(
      currentUser,
      setCustomers,
      pageNumber,
      query,
      rowsPerPage,
      sortColumn
    );

    SuccessToast(t('customers.inf_InvitationCancelled'));
  };

  const handleReinviteCustomer = async (
    customer: BuyersCompaniesAndUsersDataModel
  ) => {
    await reinviteCustomer(currentUser, customer);
    SuccessToast(t('customers.inf_InvitationSent'));
  };

  const handleCustomerStatusChanged = async (id: number, isActive: boolean) => {
    const data = [...customers.data];

    let idx = data.findIndex((x) => {
      return x.id === id;
    });

    if (idx > -1) {
      data[idx].isActive = isActive;
    }

    setCustomers({
      currentPage: customers.currentPage,
      totalItems: customers.totalItems,
      data: data,
      totalPages: customers.totalPages,
    });
  };

  const tableToShow = () => {
    switch (customerFilter) {
      case 'companiesAndUsers':
        return (
          <CustomerUserTable
            handlePageChange={handlePageChange}
            handleRowsPerChange={handleRowsPerChange}
            handleOnSort={handleOnSort}
            handlePriceListExport={handlePriceListExport}
            handleDeleteCustomer={handleDeleteCustomer}
            handleReinviteCustomer={handleReinviteCustomer}
            resetPaginationToggle={resetPaginationToggle}
            handleCustomerStatusChanged={handleCustomerStatusChanged}
            showUsers
            customers={customers}
          />
        );

      case 'users':
        return (
          <UsersTable
            handlePageChange={handlePageChange}
            handleRowsPerChange={handleRowsPerChange}
            handleOnSort={handleOnSort}
            resetPaginationToggle={resetPaginationToggle}
            customersContacts={customersUsers}
          />
        );

      case 'companies':
        return (
          <CustomerUserTable
            handlePageChange={handlePageChange}
            handleRowsPerChange={handleRowsPerChange}
            handleOnSort={handleOnSort}
            handlePriceListExport={handlePriceListExport}
            handleDeleteCustomer={handleDeleteCustomer}
            handleReinviteCustomer={handleReinviteCustomer}
            handleCustomerStatusChanged={handleCustomerStatusChanged}
            resetPaginationToggle={resetPaginationToggle}
            showUsers={false}
            customers={customers}
          />
        );

      default:
        return (
          <CustomerUserTable
            handlePageChange={handlePageChange}
            handleRowsPerChange={handleRowsPerChange}
            handleOnSort={handleOnSort}
            handlePriceListExport={handlePriceListExport}
            handleDeleteCustomer={handleDeleteCustomer}
            handleReinviteCustomer={handleReinviteCustomer}
            handleCustomerStatusChanged={handleCustomerStatusChanged}
            resetPaginationToggle={resetPaginationToggle}
            showUsers
            customers={customers}
          />
        );
    }
  };

  return (
    <div className='container-fluid p-2'>
      <Card className='mt-1'>
        <Card.Header>
          <h1>{t('customers.lbl_YourCustomers')}</h1>
          <Row>
            <Col>
              <div className='input-group'>
                {/* <CustomerFilter setCustomerFilter={setCustomerFilter} /> */}

                <input
                  style={{ maxWidth: '175px' }}
                  onChange={searchAPIDebounced}
                  type='search'
                  id='SearchText'
                  name='SearchText'
                  className='form-control d-none d-sm-inline-block'
                  placeholder={t('customers.plh_SearchCustomers')}
                  aria-label='Search'
                />
              </div>
            </Col>
            <Col>
              <ModalInviteCustomer
                currentUser={currentUser}
                localizations={localizations}
                refreshCustomers={async () =>
                  getAllCustomers(
                    currentUser,
                    setCustomers,
                    pageNumber,
                    query,
                    rowsPerPage,
                    sortColumn
                  )
                }
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>{tableToShow()}</Card.Body>
      </Card>
    </div>
  );
};

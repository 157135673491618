import { CancelTokenSource } from 'axios';
import { GroupedProductModel } from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import {
  AllProjectsNewModel,
  AllProjectsNewReactQueryModel,
  FilterStateModel,
} from 'models/buyer/project/ProjectModels';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { createContext } from 'react';

export type Action =
  | { type: 'USER_SET_PROFILE'; userProfile: UserModelById }
  | { type: 'SELLER_SET_CUSTOMER'; customerId: number | null }
  | { type: 'SELLER_SET_SUPPLIER'; supplierId: number | null }
  | {
      type: 'BUYER_SET_CURRENT_SUPPLIERS';
      suppliers: BuyerCurrentSuppliers[] | null;
    }
  | {
      type: 'BUYER_SET_ACTIVE_SELLER_COUNT';
      activeSupplierCount: number | null;
    }
  | {
      type: 'BUYER_SET_CURRENT_INQUIRY_PROJECT_ROWS';
      currentInquiryProjectRows: number | null;
    }
  | {
      type: 'BUYER_SET_CURRENT_ORDER_PROJECT_ROWS';
      currentOrderProjectRows: number | null;
    }
  | {
      type: 'BUYER_SET_CURRENT_PRODUCT_LIST';
      currentProducts: GroupedProductModel[] | null;
    }
  | {
      type: 'BUYER_SET_CURRENT_PRODUCT_LIST_WITH_QTYS';
      currentProductsWithQtys: GroupedProductModel[];
    }
  | {
      type: 'BUYER_SET_FIRST_TIME_LOGIN_DONE';
      buyerFirstTimeLoginDone: boolean;
    }
  | {
      type: 'BUYER_SET_DEALS';
      buyerDeals: AllProjectsNewReactQueryModel;
    }
  | {
      type: 'BUYER_SET_GET_PROJECTS';
      getProjects: () => Promise<AllProjectsNewModel>;
    }
  | {
      type: 'BUYER_SET_PROJECTS_FILTER';
      buyerDealsFilter: FilterStateModel;
    }
  | {
      type: 'BUYER_SET_NEW_SOURCE_SEARCH_PRODUCTS';
      buyerProductsMainSearchSource: CancelTokenSource;
    }
  | {
      type: 'SET_PRODUCTS_VIEW';
      productsView: 'LIST' | 'GRID';
    };

export type AppContextType = {
  userProfile: UserModelById | null;
  sellerCurrentCustomerId: number | null;
  buyerCurrentSupplierId: number | null;
  buyerCurrentSuppliers: BuyerCurrentSuppliers[] | null;
  buyerCurrentSuppliersActiveCount: number | null;
  currentInquiryProjectRows: number | null;
  currentOrderProjectRows: number | null;
  currentProducts: GroupedProductModel[] | null;
  currentProductsWithQtys: GroupedProductModel[];
  buyerFirstTimeLoginDone: boolean;
  buyerDeals: AllProjectsNewReactQueryModel;
  getProjects: () => Promise<AllProjectsNewModel>;
  buyerDealsFilter: FilterStateModel;
  buyerProductsMainSearchSource: CancelTokenSource;
  productsView: 'LIST' | 'GRID';
};

export const AppContext = createContext<{
  appState: AppContextType;
  appContextDispatch: React.Dispatch<Action>;
}>({
  appState: {} as AppContextType,
  appContextDispatch: () => {},
});

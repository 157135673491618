import { useQuery } from '@tanstack/react-query';
import { BuyerApi } from 'api/BuyerApi';
import axios, { CancelTokenSource } from 'axios';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { useGetBuyerDeals } from 'hooks/useGetBuyerDeals';
import { GroupedProductModel } from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import {
  AllProjectsNewModel,
  AllProjectsNewReactQueryModel,
  FilterStateModel,
} from 'models/buyer/project/ProjectModels';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { useCallback, useEffect, useReducer } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { Action, AppContext, AppContextType } from './AppContext';

const localAppStorageKey = '_elecZapApp';

type InputProviderProps = {
  children: React.ReactNode;
};

const getAppStorageValue = () => {
  const appString = localStorage.getItem(localAppStorageKey) as string;
  return JSON.parse(appString) as AppContextType;
};

const setAppStorageValue = (value: AppContextType) => {
  localStorage.setItem(localAppStorageKey, JSON.stringify(value));
};

const setUserProfile = (state: AppContextType, userProfile: UserModelById) => {
  state.userProfile = userProfile;
  setAppStorageValue(state);
  return state;
};

const setSellerCustomer = (
  state: AppContextType,
  customerId: number | null
) => {
  state.sellerCurrentCustomerId = customerId;
  setAppStorageValue(state);
  state = getAppStorageValue();
  return state;
};

const setBuyerSupplier = (state: AppContextType, supplierId: number | null) => {
  if (state === null || state.userProfile === null) {
    return state;
  }

  state.buyerCurrentSupplierId = supplierId;
  setAppStorageValue(state);

  return state;
};

const setBuyerSuppliers = (
  state: AppContextType,
  suppliers: BuyerCurrentSuppliers[] | null
) => {
  if (state === null || state.userProfile === null) {
    return state;
  }

  state.buyerCurrentSuppliers = suppliers;
  setAppStorageValue(state);

  return state;
};

const setBuyerActiveSellerCount = (
  state: AppContextType,
  supplierCount: number | null
) => {
  if (state === null) {
    return state;
  }

  state.buyerCurrentSuppliersActiveCount = supplierCount;
  return state;
};

const setProjectInquiryRowsCount = (
  state: AppContextType,
  rowCount: number | null
) => {
  if (state === null) {
    return state;
  }

  state.currentInquiryProjectRows = rowCount;

  setAppStorageValue(state);
  return state;
};

const setProjectOrderRowsCount = (
  state: AppContextType,
  rowCount: number | null
) => {
  if (state === null) {
    return state;
  }

  state.currentOrderProjectRows = rowCount;

  setAppStorageValue(state);
  return state;
};

const setCurrentProductList = (
  state: AppContextType,
  currentProducts: GroupedProductModel[] | null
) => {
  if (state === null) {
    return state;
  }

  state.currentProducts = currentProducts;

  setAppStorageValue(state);
  return state;
};

const setCurrentProductListWithQtys = (
  state: AppContextType,
  currentProductsWithQtys: GroupedProductModel[]
) => {
  if (state === null) {
    return state;
  }

  state.currentProductsWithQtys = currentProductsWithQtys;

  setAppStorageValue(state);
  return state;
};

const setBuyerFirstTimeLoginDone = (
  state: AppContextType,
  buyerFirstTimeLoginDone: boolean
) => {
  if (state === null) {
    return state;
  }

  state.buyerFirstTimeLoginDone = buyerFirstTimeLoginDone;

  setAppStorageValue(state);
  return state;
};

const setBuyerDeals = (
  state: AppContextType,
  buyerDeals: AllProjectsNewReactQueryModel
) => {
  if (state === null) {
    return state;
  }

  state.buyerDeals = buyerDeals;

  setAppStorageValue(state);
  return state;
};

const setBuyerGetProjects = (
  state: AppContextType,
  getProjects: () => Promise<AllProjectsNewModel>
) => {
  if (state === null) {
    return state;
  }

  state.getProjects = getProjects;

  setAppStorageValue(state);
  return state;
};

const setBuyerDealsFilter = (
  state: AppContextType,
  buyerDealsFilter: FilterStateModel
) => {
  if (state === null) {
    return state;
  }

  state.buyerDealsFilter = buyerDealsFilter;

  setAppStorageValue(state);
  return state;
};

const setBuyerMainDealSearchSource = (
  state: AppContextType,
  buyerProductsMainSearchSource: CancelTokenSource
) => {
  if (state === null) {
    return state;
  }

  state.buyerProductsMainSearchSource = buyerProductsMainSearchSource;

  setAppStorageValue(state);
  return state;
};

const setProductsView = (
  state: AppContextType,
  productsView: 'LIST' | 'GRID'
) => {
  if (state === null) {
    return state;
  }

  state.productsView = productsView;

  setAppStorageValue(state);
  return state;
};

const appContextReducer = (state: AppContextType, action: Action) => {
  switch (action.type) {
    case 'USER_SET_PROFILE':
      return { ...setUserProfile(state, action.userProfile) };
    case 'SELLER_SET_CUSTOMER':
      return { ...setSellerCustomer(state, action.customerId) };
    case 'SELLER_SET_SUPPLIER':
      return {
        ...setBuyerSupplier(state, action.supplierId),
      };
    case 'BUYER_SET_CURRENT_SUPPLIERS':
      return {
        ...setBuyerSuppliers(state, action.suppliers),
      };
    case 'BUYER_SET_ACTIVE_SELLER_COUNT':
      return {
        ...setBuyerActiveSellerCount(state, action.activeSupplierCount),
      };
    case 'BUYER_SET_CURRENT_INQUIRY_PROJECT_ROWS':
      return {
        ...setProjectInquiryRowsCount(state, action.currentInquiryProjectRows),
      };
    case 'BUYER_SET_CURRENT_ORDER_PROJECT_ROWS':
      return {
        ...setProjectOrderRowsCount(state, action.currentOrderProjectRows),
      };

    case 'BUYER_SET_CURRENT_PRODUCT_LIST':
      return {
        ...setCurrentProductList(state, action.currentProducts),
      };

    case 'BUYER_SET_CURRENT_PRODUCT_LIST_WITH_QTYS':
      return {
        ...setCurrentProductListWithQtys(state, action.currentProductsWithQtys),
      };

    case 'BUYER_SET_FIRST_TIME_LOGIN_DONE':
      return {
        ...setBuyerFirstTimeLoginDone(state, action.buyerFirstTimeLoginDone),
      };

    case 'BUYER_SET_DEALS':
      return {
        ...setBuyerDeals(state, action.buyerDeals),
      };

    case 'BUYER_SET_GET_PROJECTS':
      return {
        ...setBuyerGetProjects(state, action.getProjects),
      };

    case 'BUYER_SET_PROJECTS_FILTER':
      return {
        ...setBuyerDealsFilter(state, action.buyerDealsFilter),
      };

    case 'BUYER_SET_NEW_SOURCE_SEARCH_PRODUCTS':
      return {
        ...setBuyerMainDealSearchSource(
          state,
          action.buyerProductsMainSearchSource
        ),
      };

    case 'SET_PRODUCTS_VIEW':
      return {
        ...setProductsView(state, action.productsView),
      };

    default:
      return state;
  }
};

export const AppContextProvider = ({ children }: InputProviderProps) => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');

  const currentState = getAppStorageValue();

  const getBuyerDeals = useGetBuyerDeals();

  const initialState: AppContextType = {
    sellerCurrentCustomerId: 0,
    buyerCurrentSupplierId: 0,
    buyerCurrentSuppliers: [],
    userProfile: {} as UserModelById,
    buyerCurrentSuppliersActiveCount: 0,
    currentInquiryProjectRows: null,
    currentOrderProjectRows: null,
    currentProducts: null,
    currentProductsWithQtys: [],
    buyerFirstTimeLoginDone: false,
    buyerDeals: getBuyerDeals,
    buyerDealsFilter: {
      ownerId: 'all',
      dealStatus: null,
      inquiryStatus: null,
      offerStatus: null,
      orderStatus: null,
      invoiceStatus: null,
      searchString: '',
      pageNumber: 1,
      tagIds: [],
    },
    getProjects: function (): Promise<AllProjectsNewModel> {
      throw new Error('Function not implemented.');
    },
    buyerProductsMainSearchSource: axios.CancelToken.source(),
    productsView: 'LIST',
  };

  const [appState, appContextDispatch] = useReducer(
    appContextReducer,
    currentState || initialState
  );

  const getProjects = useCallback(async () => {
    let sellerIds =
      appState.buyerCurrentSuppliers === null
        ? null
        : appState.buyerCurrentSuppliers
            .filter((supplier) => supplier.isSelected)
            .map((filteredSupplier) => filteredSupplier.value);

    const response = await BuyerApi.projects.getProjectsNew(
      currentUser?.companyId,
      sellerIds,
      appState.buyerDealsFilter
    );

    // setFirstFetchDone(true);
    // setMoreDealsLoading(false);

    return response;
  }, [
    appState.buyerCurrentSuppliers,
    appState.buyerDealsFilter,
    currentUser?.companyId,
  ]);

  useEffect(() => {
    appContextDispatch({
      type: 'BUYER_SET_GET_PROJECTS',
      getProjects: getProjects,
    });
  }, [getProjects]);

  // Move useGetBuyerDeals here
  const { data, isLoading, isError, isFetching } = useQuery(
    [
      'deals',
      currentUser?.companyId,
      appState.buyerDealsFilter,
      appState.buyerCurrentSuppliers,
    ],
    getProjects,
    {
      enabled:
        !!currentUser?.companyId &&
        !!appState.buyerDealsFilter?.ownerId &&
        window.location.pathname.includes('buyer'),
      staleTime: 1000 * 60 * 7,
      keepPreviousData: true,

      onSuccess: (data) => {
        appContextDispatch({
          type: 'BUYER_SET_DEALS',
          buyerDeals: {
            data,
            isLoading: false,
            isError: false,
            isFetching: false,
          },
        });

        SuccessToast(t('deals.inf_DealsViewIsUptoDate'));
      },
      onError: (error) => {
        appContextDispatch({
          type: 'BUYER_SET_DEALS',
          buyerDeals: {
            data: {} as AllProjectsNewModel,
            isLoading: false,
            isError: true,
            isFetching: false,
          },
        });
      },

      onSettled: (data) => {
        appContextDispatch({
          type: 'BUYER_SET_DEALS',
          buyerDeals: {
            ...appState.buyerDeals,
            isFetching: false,
          },
        });
      },
    }
  );

  useEffect(() => {
    appContextDispatch({
      type: 'BUYER_SET_DEALS',
      buyerDeals: {
        ...appState.buyerDeals,
        isFetching,
      },
    });
  }, [isFetching, appContextDispatch]);

  return (
    <AppContext.Provider value={{ appState, appContextDispatch }}>
      {children}
    </AppContext.Provider>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { FileEarmarkX } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  externalSellerId: number;
  externalSellerName: string;
  refreshSuppliers: () => Promise<void>;
};

export const ExternalSupplierDeletePricelist = ({
  externalSellerId,
  externalSellerName,
  refreshSuppliers,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await BuyerApi.deletePricelistExternalSeller(
      currentUser.companyId,
      externalSellerId
    );

    await refreshSuppliers();

    setIsLoading(false);

    handleClose();
  };

  return (
    <>
      <FileEarmarkX
        onClick={handleShow}
        className='cursor-pointer icon-ez-red'
        size={20}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {t('externalSupplierPricelist.hd_DeletePricelist')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('externalSupplierPricelist.inf_DeletePricelist', {
            supplierName: externalSellerName,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant='secondary'
            onClick={handleClose}
          >
            {t('common.btn_Cancel')}
          </Button>
          <Button disabled={isLoading} variant='danger' onClick={handleDelete}>
            {t('common.btn_Ok')}{' '}
            {isLoading && <Spinner animation='border' size='sm' />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { ProductListHeaderSorter } from 'components/shared/products/Product/ProductListHeaderSorter';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { AppContext } from 'contexts/user/AppContext';
import { useAddProductsToProjectInquiry } from 'hooks/useAddProductsToProjectInquiry';
import { useAddProductsToProjectOrder } from 'hooks/useAddProductsToProjectOrder';
import { useGetAmpwiseManufacturers } from 'hooks/useGetAmpwiseManufacturers';
import { useResetQtyForProductList } from 'hooks/useResetQtyForProductList';
import { ManufacturerImagesModel } from 'models/buyer/common/ManufacturerImages';
import { GroupedProductModel } from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { CartFill, Clipboard2Fill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropsType = {
  getProducts?: (didCancel: boolean, orderBy: string) => Promise<void>;
  favoriteStockExists: boolean;
  setPageNumber?: (value: number) => void;
  setOrderBy?: (value: string) => void;
  selectedManufacturers: string[];
  setSelectedManufacturers: (value: string[]) => void;
};

export const BuyerProductListHeader = ({
  getProducts,
  favoriteStockExists,
  setPageNumber,
  setSelectedManufacturers,
  selectedManufacturers,
  setOrderBy,
}: PropsType) => {
  const [currentSort, setCurrentSort] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productListWithQtys, setProductListWithQtys] = useState<
    GroupedProductModel[]
  >([]);
  const [ampwiseManufacturers, setAmpwiseManufacturers] = useState<
    ManufacturerImagesModel[]
  >([]);

  useGetAmpwiseManufacturers(setAmpwiseManufacturers);

  const { t } = useTranslation('components');

  const { appState, appContextDispatch } = useContext(AppContext);

  const resetQtyForProductList = useResetQtyForProductList();

  const addProductsToInquiry = useAddProductsToProjectInquiry();
  const addProductsToOrder = useAddProductsToProjectOrder();

  let productClassListHeaderClasses = [
    'buyer-product-class-list-sticky-header',
  ];

  const handleSortASC = async (column: string) => {
    setCurrentSort(column);
    setOrderBy && setOrderBy(column);
  };

  const handleSortDESC = async (column: string) => {
    setCurrentSort(column);
    setOrderBy && setOrderBy(column);
  };

  useEffect(() => {
    const productsWithQtys = appState.currentProductsWithQtys.filter(
      (prod) => prod.quantity !== 0
    );

    setProductListWithQtys(productsWithQtys);
  }, [appState.currentProductsWithQtys]);

  const handleInquireProducts = async () => {
    setIsLoading(true);

    if (productListWithQtys.length === 0) return;

    if (productListWithQtys) {
      await addProductsToInquiry('ProductList', {
        groupedProducts: productListWithQtys,
        importProductsText: null,
      });

      resetQtyForProductList();

      setIsLoading(false);

      SuccessToast(t('projectSettings.inf_ProductAdded'));
    }
    setIsLoading(false);
  };

  const handleOrderProducts = async () => {
    setIsLoading(true);

    if (productListWithQtys.length === 0) return;

    if (productListWithQtys) {
      await addProductsToOrder('ProductList', {
        groupedProducts: productListWithQtys,
        importProductsText: null,
      });

      resetQtyForProductList();

      setIsLoading(false);

      SuccessToast(t('projectSettings.inf_ProductAdded'));
    }
    setIsLoading(false);
  };

  const handleManufacturerSelect = (name: string) => () => {
    let cloned = [...selectedManufacturers];

    var index = cloned.indexOf(name);
    if (index !== -1) {
      cloned.splice(index, 1);
    }

    selectedManufacturers.includes(name)
      ? setSelectedManufacturers(cloned)
      : setSelectedManufacturers([...cloned, name]);
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className={productClassListHeaderClasses.join(' ')}>
        <div className='maunfacturer-selector-container d-flex flex-row justify-content-end'>
          {appState.buyerCurrentSuppliers?.findIndex(
            (supplier) => supplier.name === 'Ampwise'
          ) !== -1 &&
            ampwiseManufacturers.map((name) => {
              return (
                <div
                  style={{
                    backgroundImage: `url(${name.image})`,
                    backgroundPosition: '50% 50%',
                    backgroundSize: 'contain',
                  }}
                  className={`${
                    selectedManufacturers.includes(name.name)
                      ? 'maunfacturer-selector-active'
                      : 'maunfacturer-selector'
                  }  mx-2 px-2 py-1`}
                  onClick={handleManufacturerSelect(name.name)}
                >
                  {/* <img
                  src={imagUrl}
                  alt={name.name}
                  onError={() => (imagUrl = NoProductImage)}
                /> */}
                </div>
              );
            })}
        </div>
        <Card
          className='card-wrapper shadow mb-0 mx-2'
          style={{ marginTop: '31px' }}
        >
          <Card.Header className='p-0'>
            <Row>
              <Col style={{ maxWidth: '120px' }}>
                {/* <StarFill fontSize='20px' className='ms-3' />
              <Image fontSize={20} className='ms-7' /> */}
              </Col>
              <Col>
                <Row>
                  <ProductListHeaderSorter
                    handleSortASC={() => handleSortASC('code')}
                    handleSortDESC={() => handleSortDESC('code_desc')}
                    currentSort={currentSort}
                    sortProperty='code'
                    sortTitle={t('productListHeader.lbl_ProductCode')}
                    style={{ maxWidth: '120px', minWidth: '120px' }}
                    className='d-flex align-items-end'
                  />

                  <ProductListHeaderSorter
                    handleSortASC={() => handleSortASC('product_class')}
                    handleSortDESC={() => handleSortDESC('product_class_desc')}
                    currentSort={currentSort}
                    sortProperty='product_class'
                    sortTitle={t('productListHeader.lbl_ProductClass')}
                    className='d-flex align-items-end'
                  />

                  <Col
                    style={{
                      maxWidth: '110px',
                    }}
                    className='justify-content-start d-flex align-items-end'
                  >
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip>{t('sellerSwitch.lbl_Supplier')}</Tooltip>
                      }
                    >
                      <h5>{t('sellerSwitch.lbl_Supplier')}</h5>
                    </OverlayTrigger>
                  </Col>
                  {/* 
                  {favoriteStockExists ? (
                    <Col
                      style={{
                        maxWidth: '70px',
                        minWidth: '70px',
                      }}
                      className='justify-content-end d-flex align-items-end pe-0'
                    >
                      <h5 className='text-end'>
                        <StarFill style={{ marginTop: -5 }} />{' '}
                        {t('common.lbl_Stock')}
                      </h5>
                    </Col>
                  ) : (
                    <Col
                      style={{
                        maxWidth: '70px',
                        minWidth: '70px',
                      }}
                      className='justify-content-end d-flex align-items-end pe-0'
                    />
                  )} */}

                  <ProductListHeaderSorter
                    handleSortASC={() => handleSortASC('in_stock')}
                    handleSortDESC={() => handleSortDESC('in_stock_desc')}
                    currentSort={currentSort}
                    sortProperty='in_stock'
                    sortTitle={t('common.lbl_Instock')}
                    style={{
                      maxWidth: '90px',
                      minWidth: '90px',
                      paddingLeft: '10px',
                      paddingRight: '5px',
                    }}
                    className='justify-content-end d-flex align-items-end wrap-ellipsis'
                  />

                  <ProductListHeaderSorter
                    handleSortASC={() => handleSortASC('arriving')}
                    handleSortDESC={() => handleSortDESC('arriving_desc')}
                    currentSort={currentSort}
                    sortProperty='arriving'
                    sortTitle={t('common.lbl_Arriving')}
                    style={{
                      maxWidth: '80px',
                      minWidth: '80px',
                      paddingLeft: '10px',
                      paddingRight: '5px',
                    }}
                    className='justify-content-end d-flex align-items-end wrap-ellipsis'
                  />

                  <ProductListHeaderSorter
                    handleSortASC={() => handleSortASC('order_step')}
                    handleSortDESC={() => handleSortDESC('order_step_desc')}
                    currentSort={currentSort}
                    sortProperty='order_step'
                    sortTitle={`${t('productListHeader.lbl_OrderStep')}`}
                    style={{
                      maxWidth: '170px',
                      minWidth: '170px',
                      paddingLeft: '10px',
                      paddingRight: '5px',
                    }}
                    className='justify-content-end d-flex align-items-end wrap-ellipsis'
                  />

                  <ProductListHeaderSorter
                    handleSortASC={() => handleSortASC('price')}
                    handleSortDESC={() => handleSortDESC('price_desc')}
                    sortProperty='price'
                    currentSort={currentSort}
                    sortTitle={t('productListHeader.lbl_Price')}
                    style={{
                      maxWidth: '70px',
                      minWidth: '70px',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    }}
                    className='justify-content-end d-flex align-items-end me-1'
                  />
                </Row>
              </Col>

              <Col
                style={{ maxWidth: '180px', minWidth: '180px' }}
                className='align-self-center'
              >
                <ButtonGroup className='float-end me-1 '>
                  <Button
                    disabled={productListWithQtys.length < 1}
                    onClick={handleInquireProducts}
                    className='btn btn-eleczap me-1 d-flex align-items-center px-1'
                    style={{ maxWidth: '80px' }}
                  >
                    <Clipboard2Fill className='me-1' />{' '}
                    <span className='wrap-ellipsis'>
                      {t('documents.btn_Inquire')}
                    </span>
                  </Button>
                  <Button
                    disabled={productListWithQtys.length < 1}
                    onClick={handleOrderProducts}
                    className='btn btn-eleczap d-flex align-items-center px-1'
                    style={{ maxWidth: '80px' }}
                  >
                    <span className='wrap-ellipsis'>
                      {t('documents.btn_Order')}
                    </span>{' '}
                    <CartFill className='ms-1' />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Card.Header>
        </Card>
      </div>
    </>
  );
};

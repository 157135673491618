import { BuyerApi } from 'api/BuyerApi';
import { ImageWithZoom } from 'components/buyer/products/Product/ImageWithZoom';
import { ProjectAddProductForm } from 'components/buyer/projectOrder/ProjectAddProductForm/ProjectAddProductForm';
import { ProjectOrderRowSupplierSelector } from 'components/buyer/projectOrder/ProjectOrderRowSupplierSelector/ProjectOrderRowSupplierSelector';
import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { CustomEditableText } from 'components/shared/CustomInputs/CustomEditableText';
import { CustomEditableProductCode } from 'components/shared/CustomInputs/CustomeEditableProductCode';
import { CustomRequiredQuantityInput } from 'components/shared/CustomInputs/CustomRequiredQtyInput';
import { CustomSupplierQuantityInput } from 'components/shared/CustomInputs/CustomSupplierQtyInput';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { useGetFormattedPrice } from 'hooks/useGetFormattedPrice';
import { useListenElementResize } from 'hooks/useListenElementResize';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import {
  BuyerProjectRowInfoGroupedModel,
  ProjectRowGroupedModel,
} from 'models/buyer/project/GroupedProjectModels';
import { ProjectRowBulkUpdateModel } from 'models/buyer/project/ProjectModels';
import {
  ProjectOrderModel,
  ProjectOrderRowGroupedModel,
} from 'models/buyer/projectOrder/ProjectOrderModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { bulkUpdateProjectRows } from 'pages/buyer/ProjectOrder/utilsProjectOrder';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
  ChevronDoubleDown,
  ChevronDoubleUp,
  SkipForwardFill,
  Trash,
  X,
} from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { getMOQColumns } from 'utils/getMOQColumns';
import { getStockLevelColumns } from 'utils/getStockLevelColumns';

import './styles.css';

type PropTypes = {
  projectDetails: ProjectOrderModel;
  projectId: number;
  getDataSource: () => Promise<void>;
  setDataSource: (value: ProjectOrderRowGroupedModel[]) => void;
  dataSource: ProjectOrderRowGroupedModel[];
  showDisabledRows: boolean;
  filteredData: (
    data: ProjectOrderRowGroupedModel[]
  ) => ProjectOrderRowGroupedModel[];
};

export const ProjectOrderProductsGridGrouped = ({
  projectDetails,
  projectId,
  setDataSource,
  getDataSource,
  dataSource,
  filteredData,
  showDisabledRows,
}: PropTypes) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);
  const [widthOfGridContainer, setWidthOfGridContainer] = useState<number>(0);
  const [currentRequiredQtyFocused, setCurrentRequiredQtyFocused] =
    useState<number>(0);

  const [isRequiredQtyUpdating, setIsRequiredQtyUpdating] =
    useState<boolean>(false);

  const [isExtraTableExpanded, setIsExtraTableExpanded] =
    useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const canSeePrices = useUserCanSeePrices();
  const getFormattedPrice = useGetFormattedPrice();

  const validateUnitExists = useValidateUnitExists();

  const { t } = useTranslation('components');

  const fillAllSupplierQtyWithrequiredQuantity = async () => {
    if (!isRequiredQtyUpdating) {
      let clonedDataSource = [...dataSource];
      clonedDataSource.forEach((r) =>
        r.rows.forEach((product) => {
          const newQuantity =
            (appState.buyerCurrentSuppliers &&
              appState.buyerCurrentSuppliers.findIndex(
                (x) => x.isSelected && x.value === product.sellerId
              ) > -1) ||
            (appState.buyerCurrentSuppliers &&
              appState.buyerCurrentSuppliers.findIndex((x) =>
                x.subSellers?.some(
                  (subSeller) =>
                    subSeller.isSelected && subSeller.value === product.sellerId
                )
              ) > -1)
              ? product.requiredQuantity
              : product.supplierQuantity;
          product.supplierQuantity = newQuantity;
        })
      );

      setDataSource(clonedDataSource);

      let bulkUpdateRows: ProjectRowBulkUpdateModel[] = [];

      clonedDataSource.forEach((row) => {
        row.rows.forEach((r) => {
          bulkUpdateRows.push({
            projectRowId: r.id,
            supplierQuantity: r.supplierQuantity,
          });
        });
      });

      await bulkUpdateProjectRows(
        bulkUpdateRows,
        currentUser,
        projectId,
        projectDetails.id
      );
      await getDataSource();
    }
  };

  const deleteAllSupplierQtyWithrequiredQuantity = async () => {
    let clonedDataSource = [...dataSource];
    clonedDataSource.forEach((r) =>
      r.rows.forEach((product) => {
        if (
          (appState.buyerCurrentSuppliers &&
            appState.buyerCurrentSuppliers.findIndex(
              (x) => x.isSelected && x.value === product.sellerId
            ) > -1) ||
          (appState.buyerCurrentSuppliers &&
            appState.buyerCurrentSuppliers.findIndex((x) =>
              x.subSellers?.some(
                (subSeller) =>
                  subSeller.isSelected && subSeller.value === product.sellerId
              )
            ) > -1)
        ) {
          product.supplierQuantity = 0;
        }
      })
    );

    setDataSource(clonedDataSource);

    let bulkUpdateRows: ProjectRowBulkUpdateModel[] = [];

    clonedDataSource.forEach((row) => {
      row.rows.forEach((prod) => {
        bulkUpdateRows.push({
          projectRowId: prod.id,
          supplierQuantity: prod.supplierQuantity,
        });
      });
    });

    await bulkUpdateProjectRows(
      bulkUpdateRows,
      currentUser,
      projectId,
      projectDetails.id
    );
    await getDataSource();
  };

  let favoriteStockExists = false;

  dataSource !== undefined &&
    dataSource.forEach((r) => {
      r.rows.forEach((tr) => {
        tr.stockLevels?.forEach((stockLevel) => {
          if (stockLevel.isFavoriteStock) favoriteStockExists = true;
        });
      });
    });

  const stockLevelColumns = getStockLevelColumns(
    t,
    favoriteStockExists,
    favoriteStockExists
  );

  const moqColumns = getMOQColumns(getFormattedPrice, t);
  const { appState } = useContext(AppContext);

  useEffect(() => {
    const getData = async () => {
      await getDataSource();
      setIsLoading(false);
    };

    appState.userProfile?.currentProject?.orderProjectId !== null && getData();
  }, [
    appState.userProfile?.currentProject?.orderProjectId,
    currentUser,
    getDataSource,
  ]);

  const handleDeleteRow = async (rows: ProjectRowGroupedModel[]) => {
    setIsLoading(true);
    toast.dismiss();
    await BuyerApi.projectOrder.deleteProjectOrderRows(
      currentUser.companyId,
      projectId,
      projectDetails.id,
      rows.map((x) => x.id)
    );
    // toast.success(t('projectSettings.inf_RowDeleted'));
    await getDataSource();
    setIsLoading(false);
  };

  useListenElementResize('.project-inquiry-grid', {
    setWidthOfElement: setWidthOfGridContainer,
  });

  const namePopover = (name: string) => (
    <Popover>
      <Popover.Body>{name}</Popover.Body>
    </Popover>
  );

  let allRowsSuppQtyIsZero = true;

  dataSource.forEach((r) => {
    r.rows
      .filter((row) => !row.isDisabled)
      .forEach((row) => {
        if (row.supplierQuantity !== 0) {
          allRowsSuppQtyIsZero = false;
          return;
        }
      });
  });

  const columns: TableColumn<BuyerProjectRowInfoGroupedModel>[] = [
    {
      name: '#',
      cell: (row, index) => <span>{row.rows[0].rowNo}</span>,
      selector: (row) => row.rows[0].rowNo,
      sortable: false,
      width: '30px',
      right: true,
      style: {
        // backgroundColor: '#f7f9fc !important',
        display: 'flex',
        alignItems: 'start',
      },
    },
    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('productDetailsGeneralInfo.lbl_ProductCode')}
        </span>
      ),
      selector: (row) => row.rows[0].productCode,
      cell: (row) => {
        const index = dataSource?.findIndex(
          (data) => data.productCode === row.productCode
        );

        return (
          index !== -1 && (
            <Row className='wrap-ellipsis'>
              <div className='row-min-height '>
                {row.rows[0].productId === null ? (
                  <CustomEditableProductCode
                    currentUser={currentUser}
                    row={row}
                    projectId={projectId}
                    projectOrderId={projectDetails.id}
                    getDataSource={getDataSource}
                    inputString={row.rows[0].productCode}
                    isHovered={row.rows[0].rowNo === hoveredRowId}
                  />
                ) : (
                  <a
                    className='btn-link-bold'
                    href={`/buyer/project-product-details/${encodeURIComponent(
                      row.productCode
                    )}`}
                  >
                    {row.productCode}
                  </a>
                )}
              </div>
              {row.rows.map((product) =>
                (product.isDisabled && !showDisabledRows) ||
                appState?.buyerCurrentSuppliers?.find(
                  (supplier) => supplier.value === product.sellerId
                )?.isMarketplace ? null : (
                  <div className='wrap-ellipsis row-min-height d-flex align-items-center ms-4'>
                    {product.supplierName ? (
                      <AvatarWithFallback
                        imageUrl={
                          appState?.buyerCurrentSuppliers?.find(
                            (supplier) => supplier.value === product.sellerId
                          )?.logo ?? null
                        }
                        initials={
                          product.supplierName
                            ? product.supplierName[0].toUpperCase()
                            : ''
                        }
                        size={'x-small'}
                      />
                    ) : (
                      <ProjectOrderRowSupplierSelector
                        supplierMethod='ADD_NEW'
                        getDataSource={getDataSource}
                        rowId={row.rows[0].id}
                        projectId={projectId}
                        projectOrderId={projectDetails.id}
                        rows={dataSource[index]?.rows}
                      />
                    )}
                    <span
                      className={`ms-1 text-start  ${
                        product.isDisabled ? 'icon-ez-light-gray' : ''
                      }`}
                    >
                      {product.supplierName}
                    </span>
                  </div>
                )
              )}
              {row.rows.length === 1 && row.rows[0].sellerId === 0 ? null : (
                <div className='wrap-ellipsis row-min-height d-flex align-items-end ms-4'>
                  <ProjectOrderRowSupplierSelector
                    supplierMethod='DUPLICATE'
                    getDataSource={getDataSource}
                    rowId={row.rows[0].id}
                    projectId={projectId}
                    projectOrderId={projectDetails.id}
                    rows={dataSource[index]?.rows}
                  />
                </div>
              )}
            </Row>
          )
        );
      },
      maxWidth: '180px',
      sortable: false,
      style: {
        display: 'flex',
        alignItems: 'start',
        paddingRight: '20px',
      },
    },
    {
      name: (
        <span style={{ fontSize: 'larger', marginLeft: '20px' }}>
          {t('projectSettings.lbl_ProductName')}
        </span>
      ),
      selector: (row) => row.rows[0].name,
      sortable: false,
      cell: (row) => (
        <Row className='wrap-ellipsis'>
          <div className='row-min-height ' />
          {row.rows.map((product) =>
            (product.isDisabled && !showDisabledRows) ||
            appState?.buyerCurrentSuppliers?.find(
              (supplier) => supplier.value === product.sellerId
            )?.isMarketplace ? null : (
              <div className='row-min-height d-flex align-items-center'>
                <div className='me-1'>
                  <ImageWithZoom
                    src={product.imageUrl}
                    width={20}
                    height={20}
                    zoomedMaxWidth={100}
                    zoomedMaxHeight={100}
                    alt={''}
                  />
                </div>
                {product.productId === null ? (
                  <OverlayTrigger
                    trigger='hover'
                    placement='auto'
                    overlay={namePopover(product.name)}
                  >
                    <CustomEditableText
                      projectOrderId={projectDetails.id}
                      currentUser={currentUser}
                      row={product}
                      projectId={projectId}
                      getDataSource={getDataSource}
                      inputString={product.name}
                      isHovered={row.rows[0].rowNo === hoveredRowId}
                    />
                  </OverlayTrigger>
                ) : (
                  <span
                    className={
                      product.isDisabled
                        ? 'icon-ez-light-gray wrap-ellipsis'
                        : 'wrap-ellipsis'
                    }
                  >
                    <OverlayTrigger
                      trigger='hover'
                      placement='auto'
                      overlay={namePopover(product.name)}
                    >
                      <span>{product.name}</span>
                    </OverlayTrigger>
                  </span>
                )}
              </div>
            )
          )}
        </Row>
      ),
      grow: 1,
      style: {
        display: 'flex',
        alignItems: 'start',
      },
      conditionalCellStyles: [
        {
          when: (row: BuyerProjectRowInfoGroupedModel) =>
            row.rows.every((v) => v.isDisabled),
          style: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
      ],
    },

    {
      name: (
        <span className='text-center' style={{ fontSize: 'larger' }}>
          {t('projectSettings.lbl_RequiredTotalQty')}
        </span>
      ),
      selector: (row) => row.rows[0].requiredQuantity,
      cell: (row) => (
        <Row className='justify-content-center'>
          <Row className='align-items-center'>
            {row.rows.every((r) => r.isDisabled) ? (
              <span className='icon-ez-light-gray'>
                {t('projectSettings.inf_SupplierIsDisabled')}
              </span>
            ) : (
              !row.rows.every((r) => r.moqPrices === undefined) && (
                <CustomRequiredQuantityInput
                  currentUser={currentUser}
                  rows={row.rows}
                  projectId={projectId}
                  projectOrderId={projectDetails.id}
                  getDataSource={getDataSource}
                  setCurrentRequiredQtyFocused={setCurrentRequiredQtyFocused}
                  setIsRequiredQtyUpdating={setIsRequiredQtyUpdating}
                />
              )
            )}
          </Row>
        </Row>
      ),
      width: '120px',
      sortable: false,
      center: true,
      style: {
        display: 'flex',
        alignItems: 'start',
      },
    },

    {
      name: (
        <div className='d-flex justify-content-between align-items-center float-end ms-2'>
          {allRowsSuppQtyIsZero ? (
            <SkipForwardFill
              style={{ cursor: 'pointer' }}
              onClick={fillAllSupplierQtyWithrequiredQuantity}
              className='icon-ez ms-2'
            />
          ) : (
            <X
              style={{ cursor: 'pointer' }}
              onClick={deleteAllSupplierQtyWithrequiredQuantity}
              className='icon-ez-red ms-2'
            />
          )}{' '}
          <span className='ms-2' style={{ fontSize: 'larger' }}>
            {t('projectSettings.lbl_SupplierQty')}
          </span>
        </div>
      ),
      selector: (row) => row.rows[0].supplierQuantity,
      cell: (row) => {
        const sum = row.rows.reduce(
          (partialSum, row) => partialSum + row.supplierQuantity,
          0
        );
        return (
          <Row className='wrap-ellipsis justify-content-center'>
            <div className='row-min-height text-center w-100'>
              <span
                className={` ${
                  sum < row.rows[0].requiredQuantity ? 'icon-ez-red' : ''
                }`}
              >
                {sum}
              </span>
            </div>
            {row.rows.map((product) =>
              (product.isDisabled && !showDisabledRows) ||
              appState?.buyerCurrentSuppliers?.find(
                (supplier) => supplier.value === product.sellerId
              )?.isMarketplace
                ? null
                : row.rows[0].sellerId !== 0 && (
                    <div className='wrap-ellipsis row-min-height d-flex align-items-center ms-4'>
                      <CustomSupplierQuantityInput
                        isHidden={
                          product.isDisabled || product.moqPrices === undefined
                        }
                        currentUser={currentUser}
                        row={product}
                        projectDetails={projectDetails}
                        getDataSource={getDataSource}
                        isRequiredQtyUpdating={isRequiredQtyUpdating}
                        currentRequiredQtyFocused={currentRequiredQtyFocused}
                        projectId={projectId}
                        setIsExtraTableExpanded={setIsExtraTableExpanded}
                        setIsLoading={setIsLoading}
                      />
                    </div>
                  )
            )}
          </Row>
        );
      },
      sortable: false,
      width: '130px',
      center: true,
      style: {
        display: 'flex',
        alignItems: 'start',
      },
    },

    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('projectSettings.lbl_Priceunit')}
        </span>
      ),
      selector: (row) => row.rows[0].moqPrices?.toString(),
      cell: (row) => (
        <Row className='wrap-ellipsis justify-content-center'>
          <div className='row-min-height row-max-height' />
          {row.rows.map((prod) => {
            const reverseClone =
              prod.moqPrices !== undefined ? [...prod.moqPrices].reverse() : [];
            const popover = (
              <Popover id='popover-moqPrices'>
                <Popover.Body>
                  <DataTable columns={moqColumns} data={prod.moqPrices} />
                </Popover.Body>
              </Popover>
            );

            return (prod.isDisabled && !showDisabledRows) ||
              appState?.buyerCurrentSuppliers?.find(
                (supplier) => supplier.value === prod.sellerId
              )?.isMarketplace ? null : (
              <Row className='text-end row-min-height row-max-height'>
                <Col className='d-flex align-items-center justify-content-end'>
                  {canSeePrices(
                    prod.productId !== null ? (
                      prod.moqPrices?.length > 1 ? (
                        <OverlayTrigger
                          trigger='hover'
                          placement='auto'
                          overlay={popover}
                        >
                          <strong>
                            <span
                              className={
                                prod.isDisabled
                                  ? 'icon-ez-light-gray'
                                  : 'btn-link-bold'
                              }
                            >
                              {prod.moqPrices !== undefined ? (
                                <span>
                                  {getFormattedPrice(
                                    reverseClone.find(
                                      (moq) => prod.supplierQuantity >= moq.moq
                                    ) || prod.moqPrices[0]
                                  )}{' '}
                                  /{' '}
                                  <small>
                                    {' '}
                                    {validateUnitExists(prod.unit)}
                                  </small>
                                </span>
                              ) : (
                                '-'
                              )}
                            </span>
                          </strong>
                        </OverlayTrigger>
                      ) : (
                        <span
                          className={
                            prod.isDisabled ? 'icon-ez-light-gray' : ''
                          }
                        >
                          {prod.moqPrices !== undefined ? (
                            <span>
                              {getFormattedPrice(prod.moqPrices[0])} /{' '}
                              <small> {validateUnitExists(prod.unit)}</small>
                            </span>
                          ) : (
                            '-'
                          )}
                        </span>
                      )
                    ) : (
                      <span
                        className={prod.isDisabled ? 'icon-ez-light-gray' : ''}
                      >
                        -
                      </span>
                    )
                  )}
                </Col>
              </Row>
            );
          })}
        </Row>
      ),
      sortable: false,
      right: true,
      maxWidth: '130px',
      minWidth: '130px',
      style: {
        display: 'flex',
        alignItems: 'start',
      },
    },

    {
      name: (
        <span style={{ fontSize: 'larger' }}>
          {t('projectSettings.lbl_TotalPrice')}
        </span>
      ),
      selector: (row) => row.rows[0].totalPrice,
      cell: (row) => {
        const sum = row.rows.reduce(
          (partialSum, row) => partialSum + row.totalPrice,
          0
        );

        formatMonetaryValue(
          row.rows[0].moqPrices[0].currencyCode,
          sum,
          appState
        );

        return (
          <Row className='wrap-ellipsis justify-content-end row-min-height'>
            <div className='row-min-height text-end w-100'>
              <strong className='text-end'>
                {formatMonetaryValue(
                  row.rows[0].moqPrices[0].currencyCode,
                  sum,
                  appState
                )}
              </strong>
            </div>
            {row.rows.map((product) =>
              (product.isDisabled && !showDisabledRows) ||
              appState?.buyerCurrentSuppliers?.find(
                (supplier) => supplier.value === product.sellerId
              )?.isMarketplace ? null : product.productId === null ? (
                canSeePrices(
                  <span
                    className={
                      product.isDisabled
                        ? 'icon-ez-light-gray text-end justify-content-end row-min-height d-flex align-items-center'
                        : 'text-end justify-content-end row-min-height d-flex align-items-center'
                    }
                  >
                    -
                  </span>
                )
              ) : (
                <span
                  className={
                    product.isDisabled
                      ? 'icon-ez-light-gray text-end justify-content-end row-min-height d-flex align-items-center'
                      : 'text-end justify-content-end row-min-height d-flex align-items-center'
                  }
                >
                  {canSeePrices(
                    product.moqPrices !== undefined
                      ? formatMonetaryValue(
                          product.moqPrices[0].currencyCode,
                          product.totalPrice,
                          appState
                        )
                      : '-'
                  )}
                </span>
              )
            )}
          </Row>
        );
      },
      sortable: false,
      right: true,
      maxWidth: '95px',
      minWidth: '95px',
      style: {
        display: 'flex',
        alignItems: 'start',
      },
    },

    {
      name: <div></div>,
      sortable: false,
      cell: (row) => (
        <Row className='align-items-center'>
          {row.productCode !== undefined &&
            row.rows[0].rowNo === hoveredRowId && (
              <Trash
                style={{ cursor: 'pointer' }}
                onClick={() => handleDeleteRow(row.rows)}
                className='icon-ez-red'
              />
            )}
        </Row>
      ),
      maxWidth: '30px',
      minWidth: '30px',
      center: true,
      style: {
        display: 'flex',
        alignItems: 'stretch',

        // backgroundColor: '#f7f9fc !important',
      },
      //   maxWidth: '65px',
      //   minWidth: '65px',
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    table: {
      style: {
        backgroundColor: '#f7f9fc !important',
        borderRight: '0 !important',
      },
    },

    headRow: {
      style: {
        backgroundColor: '#f7f9fc !important',
        borderBottom: '0 !important',
        // display: 'none',
      },
    },
    rows: {
      style: {
        // maxWidth: widthOfGridContainer,
        borderBottom: '0 !important',
        marginBottom: '5px !important',
        minHeight: '40px !important',
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },

    noData: {
      style: {
        display: 'none',
      },
    },

    cells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
  };

  const customStyles0 = {
    headCells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    table: {
      style: {
        backgroundColor: '#f7f9fc !important',
        borderRight: '0 !important',
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f7f9fc !important',
        borderBottom: '0 !important',
      },
    },

    rows: {
      style: {
        display: 'none',
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row: BuyerProjectRowInfoGroupedModel) =>
        row.rows.every((y) => y.isDisabled),
      style: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        // filter: 'blur(2px)',
      },
    },
  ];

  const handleRowMouseEnter = (row: BuyerProjectRowInfoGroupedModel) => {
    setHoveredRowId(row.rows[0].rowNo);
  };
  const handleRowMouseLeave = (row: BuyerProjectRowInfoGroupedModel) => {
    setHoveredRowId(null);
  };

  const columnsExtra: TableColumn<ProjectOrderRowGroupedModel>[] = [
    {
      name: <span className='text-end'>{t('projectSettings.lbl_Stocks')}</span>,
      selector: (row) => row.rows[0].stockLevels?.toString(),
      cell: (row) => (
        <Row
          className='justify-content-end text-end'
          style={{ maxWidth: '85px', minWidth: '85px', minHeight: '31.44' }}
        >
          <div className='row-min-height' />
          {row.rows.map((prod) => {
            let totalStockLevel = 0;

            prod.stockLevels?.forEach((stockLevel) => {
              totalStockLevel += stockLevel.inStock;
            });

            const popover = (
              <Popover style={{ minWidth: '500px' }} id='popover-stockLevels'>
                <Popover.Header as='h3'>
                  {t('projectSettings.lbl_Stocks')}
                </Popover.Header>
                <Popover.Body>
                  <DataTable
                    columns={stockLevelColumns}
                    data={prod.stockLevels}
                  />
                </Popover.Body>
              </Popover>
            );
            if (prod.productCode === undefined) return <></>;

            return prod.isDisabled && !showDisabledRows ? null : (
              <Row
                style={{ minHeight: '31.44px' }}
                className='align-items-center'
              >
                {prod.stockLevels?.length > 1 ? (
                  <OverlayTrigger
                    trigger='hover'
                    placement='auto'
                    overlay={popover}
                  >
                    <span>
                      <strong
                        className={
                          prod.isDisabled
                            ? 'icon-ez-light-gray'
                            : 'btn-link-bold'
                        }
                      >
                        {prod.productId === null ? '-' : totalStockLevel}
                      </strong>
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span className={prod.isDisabled ? 'icon-ez-light-gray' : ''}>
                    {prod.productId === null ? '-' : totalStockLevel}
                  </span>
                )}
              </Row>
            );
          })}
          {row.rows[0].sellerId !== 0 && <div className='row-min-height' />}
        </Row>
      ),
      sortable: false,
      right: true,
      omit: !isExtraTableExpanded,
      maxWidth: '70px',
      minWidth: '70px',
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
      //   maxWidth: '65px',
      //   minWidth: '65px',
    },
    {
      name: t('common.lbl_Arriving'),
      selector: (row) => row.rows[0].arriving,
      sortable: false,
      right: true,
      maxWidth: '70px',
      minWidth: '70px',
      omit: !isExtraTableExpanded,
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
      cell: (row) => (
        <Row
          className='justify-content-end text-end'
          style={{ maxWidth: '85px', minWidth: '85px' }}
        >
          <div className='row-min-height' />
          {row.rows.map((prod) => {
            let totalArriving = 0;
            prod.stockLevels?.forEach((stockLevel) => {
              totalArriving += stockLevel.arriving;
            });

            if (row.productCode === undefined) return <></>;

            return prod.isDisabled && !showDisabledRows ? null : (
              <Row
                style={{ minHeight: '31.44px' }}
                className='align-items-center'
              >
                <span className={prod.isDisabled ? 'icon-ez-light-gray' : ''}>
                  {prod.productId === null ? '-' : totalArriving}
                </span>
              </Row>
            );
          })}
          {row.rows[0].sellerId !== 0 && <div className='row-min-height' />}
        </Row>
      ),
    },
    {
      name: (
        <span className='text-end'>
          {`${t('projectSettings.lbl_OrderStep')} / ${t(
            'productListHeader.lbl_Package'
          )}`}
        </span>
      ),
      selector: (row) => row.rows[0].orderStep,
      cell: (row) => (
        <Row
          className={'justify-content-end text-end'}
          style={{ maxWidth: '85px', minWidth: '85px' }}
        >
          <div className='row-min-height' />
          {row.rows.map((prod) => {
            return prod.isDisabled && !showDisabledRows ? null : (
              <Row
                style={{ minHeight: '31.44px' }}
                className={
                  prod.supplierQuantity % prod.orderStep !== 0 &&
                  appState.buyerCurrentSuppliers
                    ?.filter((supplier) => supplier.isSelected)
                    .findIndex(
                      (supplier) => supplier.value === prod.sellerId
                    ) !== -1
                    ? 'custom-project-table-input px-2 supplier-qty-error align-items-center'
                    : 'align-items-center'
                }
              >
                {row.productCode !== undefined && (
                  <span
                    style={{ whiteSpace: 'nowrap' }}
                    className={prod.isDisabled ? 'icon-ez-light-gray' : ''}
                  >
                    {prod.productId === null ? (
                      '-'
                    ) : (
                      <>
                        {prod.orderStep} / <small>{prod.packageSize}</small>
                      </>
                    )}
                  </span>
                )}
              </Row>
            );
          })}
          {row.rows[0].sellerId !== 0 && <div className='row-min-height' />}
        </Row>
      ),
      style: {
        display: 'flex',
        alignItems: 'stretch',
      },
      sortable: false,
      right: true,
      omit: !isExtraTableExpanded,
      maxWidth: '85px',
      minWidth: '85px',
    },
  ];

  return (
    <>
      {isLoading && <FullScreenLoader />}

      <Row className='flex-nowrap justify-content-between'>
        <Col
          style={{
            transitionDuration: '0.2s',
            maxWidth: !isExtraTableExpanded ? '100%' : 'calc(100% - 320px)',
          }}
          // lg={isExtraTableExpanded ? 9 : 11}
        >
          <DataTable
            className='project-inquiry-grid-table'
            onRowMouseEnter={handleRowMouseEnter}
            onRowMouseLeave={handleRowMouseLeave}
            columns={columns}
            data={filteredData(dataSource)}
            customStyles={customStyles}
            noDataComponent={
              <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
            }
            conditionalRowStyles={conditionalRowStyles}
            expandableIcon={{
              collapsed: <ChevronDoubleDown className='icon-ez' />,
              expanded: <ChevronDoubleUp className='icon-ez' />,
            }}
          />
        </Col>
        {filteredData(dataSource).length !== 0 && (
          <Col
            className='d-flex justify-content-end'
            style={{
              transitionDuration: '0.2s',
              maxWidth: !isExtraTableExpanded ? '102px' : '320px',
            }}
          >
            <div
              onClick={() => setIsExtraTableExpanded(!isExtraTableExpanded)}
              className='project-order-stocks-toggle'
            >
              {!isExtraTableExpanded ? (
                <ArrowLeftCircleFill className='icon-ez-white ms-2' />
              ) : (
                <ArrowRightCircleFill className='icon-ez-white ms-2' />
              )}{' '}
              <span className='icon-ez-white project-order-stocks-toggle-text'>
                {t('common.lbl_Stock')}
              </span>{' '}
            </div>
            {isExtraTableExpanded && (
              <DataTable
                onRowMouseEnter={handleRowMouseEnter}
                onRowMouseLeave={handleRowMouseLeave}
                columns={columnsExtra}
                noDataComponent={
                  <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
                }
                data={filteredData(dataSource)}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
              />
            )}
          </Col>
        )}
      </Row>

      <ProjectAddProductForm
        getDataSource={getDataSource}
        projectOrderId={projectDetails.id}
        projectId={projectId}
      />
    </>
  );
};

import { ArrowRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export const BuyerRegistrationEmailSent = () => {
  const { t } = useTranslation('components');

  return (
    <div
      style={{ height: '100vh' }}
      className='d-flex flex-column justify-content-center align-items-center'
    >
      <h1>{t('buyerRegistration.inf_ConfirmationEmailSent')}</h1>
      <h3>
        <a
          className='btn-link-bold mt-2'
          rel='noreferrer'
          href='https://eleczap.com/'
        >
          {t('buyerRegistration.lbl_BackToHomePage')} <ArrowRight />
        </a>
      </h3>{' '}
    </div>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { useFormik } from 'formik';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import {
  ProjectOrderModel,
  ProjectOrderUpdateModel,
} from 'models/buyer/projectOrder/ProjectOrderModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { getProjectOrderDetails } from 'pages/buyer/ProjectOrder/utilsProjectOrder';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

type UrlParamsType = {
  projectId: string;
  projectOrderId: string;
};

export const useGetProjectOrderDetails = (
  currentUser: LoginResponseUserModel,
  setProjectDetails: (values: ProjectOrderModel) => void,
  setIsLoading: (value: boolean) => void,
  projectId: number,
  projectOrderId: number
) => {
  useEffect(() => {
    const getProject = async (): Promise<void> => {
      projectId &&
        projectOrderId &&
        (await getProjectOrderDetails(
          projectId,
          projectOrderId,
          currentUser,
          setProjectDetails
        ));

      setIsLoading(false);
    };

    getProject();
  }, [
    currentUser,
    currentUser.companyId,
    projectId,
    projectOrderId,
    setIsLoading,
    setProjectDetails,
  ]);
};

/* Formik init */
export const useFormikHookForProjectOrder = (
  currentUser: LoginResponseUserModel,
  projectDetails: ProjectOrderModel,
  setProjectDetails: (values: ProjectOrderModel) => void,
  projectId: number,
  projectOrderId: number,
  t: any
): any => {
  const [currentProjectName, setCurrentProjectName] = useState('');
  const setUserProfile = useSetUserProfile();

  const { appState } = useContext(AppContext);

  const handleSubmit = async (
    currentUser: LoginResponseUserModel,
    values: ProjectOrderUpdateModel
  ): Promise<void> => {
    toast.dismiss();
    projectOrderId &&
      (await BuyerApi.projectOrder.updateProjectOrder(
        currentUser.companyId,
        projectId,
        projectOrderId,
        values
      ));

    projectOrderId &&
      (await getProjectOrderDetails(
        projectId,
        projectOrderId,
        currentUser,
        setProjectDetails
      ));
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),
  });

  const formik = useFormik<ProjectOrderModel>({
    initialValues: { ...projectDetails },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedProject: ProjectOrderUpdateModel = {
        requestedDeliveryDate: values.requestedDeliveryDate,
        deliveryAddressId: values.deliveryAddressId,
      };
      await handleSubmit(currentUser, updatedProject);

      formik.setSubmitting(false);
    },
  });

  return formik;
};

/* Hooks utilized in Company.tsx (Company settings)*/

import { CompanyApi } from 'api/CompanyApi';
import { useFormik } from 'formik';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { CompanyModel } from 'models/shared/company/CompanyModel';
import { CompanyUpdateModel } from 'models/shared/company/CompanyUpdateModel';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* Formik init */
export const useFormikHook = (
  company: CompanyModel,
  currentUser: LoginResponseUserModel,
  t: any
): any => {
  const handleSubmit = async (values: CompanyUpdateModel): Promise<void> => {
    toast.dismiss();
    await CompanyApi.updateCompanies(currentUser.companyId, values);

    toast.info(t('companySettings.inf_CompanySettingsSaved'));
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .nullable()
      .required(t('common.err_ValueRequired'))
      .max(70, t('common.err_ValueMax70')),
    shortName: yup
      .string()
      .nullable()
      .required(t('common.err_ValueRequired'))
      .max(70, t('common.err_ValueMax70')),

    addressLine1: yup
      .string()
      .nullable()
      .required(t('common.err_ValueRequired'))
      .max(150, t('common.err_ValueMax150')),

    addressLine2: yup.string().nullable().max(150, t('common.err_ValueMax150')),

    postalCode: yup
      .string()
      .nullable()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    city: yup
      .string()
      .nullable()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    state: yup.string().nullable().max(50, t('common.err_ValueMax50')),

    webSite: yup.string().nullable().max(50, t('common.err_ValueMax50')),

    email: yup
      .string()
      .nullable()
      .required(t('common.err_ValueRequired'))
      .email(t('common.err_EmailInvalid'))
      .max(50, t('common.err_ValueMax50')),

    phone: yup.string().nullable().max(50, t('common.err_ValueMax50')),
  });

  const formik = useFormik<CompanyModel>({
    initialValues: { ...company },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedCompany: CompanyUpdateModel = {
        shortName: values.shortName,
        isSeller: values.isSeller,
        isBuyer: values.isBuyer,
        email: values.email,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        postalCode: values.postalCode,
        city: values.city,
        state: values.state,
        countryCode: values.country.code,
        vatNumber: values.vatNumber,
        phone: values.phone,
        webSite: values.webSite,
      };
      await handleSubmit(updatedCompany);
      formik.setSubmitting(false);
    },
  });

  return formik;
};

export const useGetCompanyDetails = (
  currentUser: LoginResponseUserModel,
  setCompany: (value: CompanyModel) => void
): void => {
  useEffect(() => {
    const getCompany = async () => {
      const company = await CompanyApi.getCompanies(currentUser.companyId);

      setCompany(company);
    };

    getCompany();
  }, [currentUser, currentUser.companyId, setCompany]);
};

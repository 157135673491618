import { getBuildDate } from 'clearCache';
import { useFormik } from 'formik';
import { ChangePasswordModel } from 'models/shared/user/ChangePasswordModel';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import packageJson from '../../../../package.json';

type ChangePasswordProps = {
  handleSubmit: (data: ChangePasswordModel) => Promise<void>;
};

export const ChangePassword = ({ handleSubmit }: ChangePasswordProps) => {
  const { t } = useTranslation('components');

  const validationSchema = yup.object({
    password: yup.string().required(t('changePassword.err_PasswordRequired')),
    newPassword: yup
      .string()
      .required(t('changePassword.err_NewPasswordRequired'))
      .min(8, t('changePassword.err_NewPasswordLength')),
    confirmNewPassword: yup
      .string()
      .required(t('changePassword.err_ConfirmNewPasswordRequired'))
      .oneOf(
        [yup.ref('newPassword'), null],
        t('changePassword.err_PasswordsMustMatch')
      ),
  });

  const initialValues: ChangePasswordModel = {
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      await handleSubmit(values);
      formik.setSubmitting(false);
    },
  });

  return (
    <Card className='p-4'>
      <Card.Header>
        <h4>{t('changePassword.lbl_Header')}</h4>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row>
            <Col>
              <Form.Group as={Row} controlId='validationCurrentPassword'>
                <Form.Label>{t('changePassword.lbl_Password')}</Form.Label>
                <Form.Control
                  type='password'
                  name='password'
                  placeholder={t('changePassword.plh_EnterPassword')}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.password && !!formik.errors.password
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Row} controlId='validationNewPassword'>
                <Form.Label>{t('changePassword.lbl_NewPassword')}</Form.Label>
                <Form.Control
                  type='password'
                  name='newPassword'
                  placeholder={t('changePassword.plh_EnterNewPassword')}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.newPassword && !!formik.errors.newPassword
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.newPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Row}
                className='mb-2'
                controlId='validationConfirmPassword'
              >
                <Form.Label>
                  {t('changePassword.lbl_ConfirmPassword')}
                </Form.Label>
                <Form.Control
                  type='password'
                  name='confirmNewPassword'
                  placeholder={t('changePassword.plh_ConfirmNewPassword')}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.confirmNewPassword &&
                    !!formik.errors.confirmNewPassword
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.confirmNewPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <span className='text-muted'>
                {t('changePassword.lbl_NewPasswordExplanation')}
              </span>

              <Button
                variant='btn btn-eleczap mt-7'
                className='float-end'
                type='submit'
                disabled={formik.isSubmitting}
              >
                {t('changePassword.btn_ChangePassword')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>

      <Card.Footer>
        <small>Build date: {getBuildDate(packageJson.buildDate)}</small>
      </Card.Footer>
    </Card>
  );
};

import axios, { AxiosResponse } from 'axios';
import { apiResponseInterceptor } from './ApiInterceptors';

const client = axios.create({ baseURL: '/api' });
client.interceptors.response.use(undefined, apiResponseInterceptor);

export const responseBody = (response: AxiosResponse) => response.data;
export const createdAt = (response: AxiosResponse) =>
  response.headers['location'];

export const NoAuthApiClient = {
  get: (url: string) => client.get(url).then(responseBody),
  post: (url: string, body: {}) =>
    client.post(url, body, {}).then(responseBody),
  postCreate: (url: string, body: {}) => client.post(url, body).then(createdAt),
  put: (url: string, body: {}) => client.put(url, body).then(responseBody),
  delete: (url: string) => client.delete(url).then(responseBody),
};

import { ImgHTMLAttributes, useEffect, useState, MouseEvent } from "react";
import { Button } from "react-bootstrap";
import { Trash, Upload } from "react-bootstrap-icons";
import "./EditableImageWithFallback.css";

type PropsType = ImgHTMLAttributes<any> & {
  fallback: string;
  handleImageUpload: () => void;
  handleImageDelete?: () => void;
  noDelete?: boolean;
  readonly: boolean;
};

/** Renders the image, on error renders the fallback image.
 * Provides image upload and deletion functionality */
export const EditableImageWithFallbackButtons = ({
  fallback,
  handleImageUpload,
  handleImageDelete,
  noDelete,
  src,
  readonly,
  ...props
}: PropsType) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);
  const [isFallback, setIsFallback] = useState(false);

  const handleContainerClick = (event: MouseEvent<HTMLDivElement>) => {
    let element = event.target as HTMLElement;
    if (element.tagName !== "IMG") {
      event.preventDefault();
    }
  };

  const onError = () => {
    setImgSrc(fallback);
    setIsFallback(true);
  };

  useEffect(() => {
    if (src) {
      setIsFallback(false);
      setImgSrc(src);
    } else {
      setIsFallback(true);
      setImgSrc(fallback);
    }
  }, [src, fallback]);

  return (
    <div className="overlay-image-container" onClick={handleContainerClick}>
      <img src={imgSrc} alt="" onError={onError} {...props} />
      {readonly === true ? (
        <></>
      ) : (
        <div className="overlay-content" style={{ padding: 0 }}>
          <Button
            variant="btn btn-eleczap-noBorder"
            onClick={handleImageUpload}
          >
            <Upload />
          </Button>
          {noDelete === true ? (
            <></>
          ) : (
            <Button
              variant="btn btn-eleczap-danger-noBorder"
              onClick={handleImageDelete}
              disabled={isFallback}
            >
              <Trash />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

import * as React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { BuyerRole, SellerRole, useRBAC } from '../../../hooks/useRBAC';

interface RBACRouteProps {
    Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    path: string;
    exact?: boolean;
    requiredRoles: SellerRole[] | BuyerRole[];
}

/**
 * Role Based Access Control Route - validates current logged in user has required roles to access the route
 * @param param0 Route properties
 * @returns If current user has required role, returns component to render, otherwise login page
 */
export const RBACRoute = ({ Component, path, exact, requiredRoles }: RBACRouteProps): JSX.Element => {
    const isAuthorized = useRBAC(requiredRoles);

    return (
        <Route path={path} exact={exact}
            render={(props: RouteComponentProps) => 
                isAuthorized ? (
                    <Component {...props} />
                ) : (
                    <Redirect to = {'/login'}
                    />
                )
           }
        />
    );
};
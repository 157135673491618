import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { ProjectRowGroupedModel } from 'models/buyer/project/GroupedProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useRef, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Badge, Button, Form, Popover, Spinner } from 'react-bootstrap';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  supplierMethod: 'DUPLICATE' | 'ADD_NEW';
  rowId: number;
  projectId: number;
  projectOrderId: number;
  getDataSource: () => Promise<void>;
  rows: ProjectRowGroupedModel[];
};

export const ProjectOrderRowSupplierSelector = ({
  getDataSource,
  projectId,
  projectOrderId,
  supplierMethod,
  rowId,
  rows,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const [selectedNewSupplier, setSelectedNewSupplier] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');

  const handleAddSupplier = async () => {
    setIsLoading(true);
    try {
      if (selectedNewSupplier) {
        if (supplierMethod === 'DUPLICATE')
          await BuyerApi.projectOrder.duplicateProjectOrderRows(
            currentUser.companyId,
            projectId,
            projectOrderId,
            { rowId, supplierId: selectedNewSupplier }
          );

        if (supplierMethod === 'ADD_NEW')
          await BuyerApi.projectOrder.addSupplierProjectOrderRows(
            currentUser.companyId,
            projectId,
            projectOrderId,
            { rowId, supplierId: selectedNewSupplier }
          );

        await getDataSource();
      }

      setShow(false);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  /* Detect click outside the tags-search div */
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setShow]);

  const popover = (
    <div ref={ref}>
      <Popover.Body className='d-flex align-items-center'>
        <Form.Select
          style={{
            marginLeft: '-15px',
            maxWidth: '200px',
            minWidth: '80px',
          }}
          onChange={(event) =>
            setSelectedNewSupplier(
              event.target.value === '' ? null : parseInt(event.target.value)
            )
          }
          className='border rounded icon-ez wrap-ellipsis'
        >
          <option value={''}>-</option>
          {appState.buyerCurrentSuppliers
            ?.filter((supplier) => !supplier.isMarketplace)
            .map(
              (supplier) =>
                !supplier.isArchived &&
                rows.findIndex((row) => row.sellerId === supplier.value) ===
                  -1 && (
                  <option value={supplier.value} className='icon-ez'>
                    {supplier.name}
                  </option>
                )
            )}
          {appState.buyerCurrentSuppliers
            ?.filter((supplier) => supplier.isMarketplace)
            .map((supplier) =>
              supplier.subSellers?.map(
                (subSeller) =>
                  !subSeller.isArchived &&
                  rows.findIndex((row) => row.sellerId === subSeller.value) ===
                    -1 && (
                    <option value={subSeller.value} className='icon-ez'>
                      {supplier.name} / {subSeller.name}
                    </option>
                  )
              )
            )}
        </Form.Select>
        <Button
          disabled={selectedNewSupplier === null || isLoading}
          className='btn btn-eleczap'
          onClick={handleAddSupplier}
        >
          {isLoading ? (
            <Spinner animation='border' size='sm' />
          ) : (
            t('projectSettings.lbl_Add')
          )}
        </Button>
      </Popover.Body>
    </div>
  );

  return (
    // <OverlayTrigger
    //   show={show}
    //   trigger='click'
    //   placement='right'
    //   overlay={popover}
    // >
    //   <span
    //     onClick={() => setShow(!show)}
    //     className='icon-ez cursor-pointer d-flex align-items-center'
    //   >
    //     <PlusCircleFill className='icon-ez me-2' />
    //     {t('projectSettings.lbl_AddNewSupplier')}
    //   </span>
    // </OverlayTrigger>

    <div>
      <span
        onClick={() => setShow(!show)}
        className='icon-ez cursor-pointer d-flex align-items-center position-relative'
      >
        {rows[0].sellerId === 0 && (
          <Badge pill className='me-1' bg='danger'>
            !
          </Badge>
        )}

        <PlusCircleFill className='icon-ez me-2' />
        {t('projectSettings.lbl_AddNewSupplier')}
      </span>

      {show && popover}
    </div>
  );
};

import { SellersCompaniesAndContactsModel } from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { BulkImageValidateModel } from 'models/seller/bulkimageupload/BulkImageUploadValidateModel';
import { BuyerContactsModel } from 'models/seller/contacts/BuyerContactsModel';
import { BuyerContactsUpdateModel } from 'models/seller/contacts/BuyerContactsUpdateModel';
import { AvailableCustomersForOnboardingModel } from 'models/seller/customers/AvailableCustomersForOnboardingModel';
import { BuyerCreditInfoModel } from 'models/seller/customers/BuyerCreditInfoModel';
import {
  BuyerExternalStocksModel,
  BuyerUpdateExternalStocksModel,
} from 'models/seller/customers/BuyerExternalStocksModel';
import {
  BuyerStocksModel,
  BuyerUpdateStocksModel,
} from 'models/seller/customers/BuyerStocksModel';
import { BuyersCompaniesAndUsersModel } from 'models/seller/customers/BuyersCompaniesAndUsersModel';
import { BuyersUsersModel } from 'models/seller/customers/BuyersUsersModel';
import { CreateCustomerInvitationModel } from 'models/seller/customers/CreateCustomerInvitationModel';
import { CustomerCompanyModel } from 'models/seller/customers/CustomerCompanyModel';
import { ResendCustomerInvitationModel } from 'models/seller/customers/ResendCustomerInvitationModel';
import { SellerCustomerContactModel } from 'models/seller/customers/SellerCustomerContactModel';
import {
  DealCreateTagModel,
  DealTagModel,
} from 'models/seller/deals/DealsModels';
import { InquiryModel } from 'models/seller/documents/InquiryModels';
import { InvoiceModel } from 'models/seller/documents/InvoiceModels';
import { OfferModel } from 'models/seller/documents/OfferModels';
import {
  OrderModel,
  QuickOrderProjectsCreateModel,
  QuickOrderProjectsCreateResponseModel,
} from 'models/seller/documents/OrderModels';
import {
  SellerGlobalNotificationsModel,
  SellerGlobalNotificationsUpdateModel,
  SellerPeronalNotificationsModel,
  SellerPeronalNotificationsUpdateModel,
} from 'models/seller/notifications/SellerNotificationsModels';
import { SellerFavoriteProductCreateModel } from 'models/seller/product/SellerFavoriteProductCreateModel';
import { SellerProductClassInfoModel } from 'models/seller/product/SellerProductClassInfoModel';
import { SellerProductModelPagedResult } from 'models/seller/product/SellerProductModelPagedResult';
import {
  AllProjectsModel,
  FilterStateModel,
} from 'models/seller/project/ProjectModels';
import { ProductDetailsModel } from 'models/shared/product/ProductDetailsModel';
import { StocksMyModel } from 'models/shared/stocksMy/StocksMyModel';
import { StocksMyUpdateModel } from 'models/shared/stocksMy/StocksMyUpdateModel';
import {
  ShareStockModel,
  StocksSharedToPartnersModel,
} from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';
import { StocksSharedToPartnersUpdateModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersUpdateModel';
import { StocksSharedWithMeModel } from 'models/shared/stocksSharedWithMe/StocksSharedWithMeModel';
import { StocksSharedWithMeUpdateModel } from 'models/shared/stocksSharedWithMe/StocksSharedWithMeUpdateModel';
import { FileBlob } from './ApiClient';
import { SellerApiClient } from './SellerApiClient';

/** API for methods available only on Seller level */
export const SellerApi = {
  /* My Stocks */
  getAllMyStocks: (companyId: number): Promise<StocksMyModel[]> =>
    SellerApiClient.get(`${companyId}/Stocks`),

  createMyStock: (
    companyId: number,
    stock: StocksMyUpdateModel
  ): Promise<StocksMyModel> =>
    SellerApiClient.postCreate(`${companyId}/Stocks`, stock),

  updateMyStock: (
    companyId: number,
    stock: StocksMyUpdateModel,
    stockID: number
  ): Promise<StocksMyModel> =>
    SellerApiClient.put(`${companyId}/Stocks/${stockID}`, stock),

  deleteMyStock: (companyId: number, stockID: number): Promise<StocksMyModel> =>
    SellerApiClient.delete(`${companyId}/Stocks/${stockID}`),

  /* Stocks Shared With Me */
  getAllStocksSharedWithMe: (
    companyId: number
  ): Promise<StocksSharedWithMeModel[]> =>
    SellerApiClient.get(`${companyId}/ExternalStocks`),

  updateStockSharedWithMe: (
    companyId: number,
    stock: StocksSharedWithMeUpdateModel,
    stockID: number
  ): Promise<StocksSharedWithMeModel> =>
    SellerApiClient.put(`${companyId}/ExternalStocks/${stockID}`, stock),

  deleteStockSharedWithMe: (
    companyId: number,
    stockID: number
  ): Promise<StocksSharedWithMeModel> =>
    SellerApiClient.delete(`${companyId}/ExternalStocks/${stockID}`),

  /* Stocks shared to partners */
  getAllStocksSharedToPartners: (
    companyId: number
  ): Promise<StocksSharedToPartnersModel[]> =>
    SellerApiClient.get(`${companyId}/SharedStocks`),

  updateStockSharedToPartner: (
    companyId: number,
    stock: StocksSharedToPartnersUpdateModel,
    stockID: number
  ): Promise<void> =>
    SellerApiClient.put(`${companyId}/SharedStocks/${stockID}`, stock),

  deleteStockSharedToPartner: (
    companyId: number,
    stockID: number
  ): Promise<void> =>
    SellerApiClient.delete(`${companyId}/SharedStocks/${stockID}`),

  shareStock: (companyId: number, shareStock: ShareStockModel): Promise<void> =>
    SellerApiClient.post(`${companyId}/SharedStocks`, shareStock),

  /* Customers */
  getAllCustomersForOnboarding: (
    companyId: number,
    name: string | null,
    registryNumber?: string
  ): Promise<AvailableCustomersForOnboardingModel[]> =>
    SellerApiClient.get(
      `${companyId}/Customers?name=${
        name !== null ? name : ''
      }&registryNumber=${registryNumber !== undefined ? registryNumber : ''}`
    ),

  createCustomerInvitation: (
    companyId: number,
    invitation: CreateCustomerInvitationModel
  ): Promise<void> =>
    SellerApiClient.postCreateV2(`${companyId}/Customers`, invitation),

  resendCustomerInvitation: (
    companyId: number,
    invitation: ResendCustomerInvitationModel
  ): Promise<void> =>
    SellerApiClient.post(`${companyId}/Customers/ResendInvitation`, invitation),

  deleteCustomer: (companyId: number, customerId: number): Promise<void> =>
    SellerApiClient.delete(`${companyId}/Customers/${customerId}`),

  getSellerCustomerContacts: (
    companyId: number,
    customerId: number
  ): Promise<SellerCustomerContactModel[]> =>
    SellerApiClient.get(`${companyId}/customers/${customerId}/Contacts`),

  getBuyersCompaniesAndUsers: (
    companyId: number,
    page?: number | undefined,
    searchString?: string | undefined,
    rowsPerPage?: number | undefined,
    sortColumn?: string | undefined
  ): Promise<BuyersCompaniesAndUsersModel> =>
    SellerApiClient.get(
      `${companyId}/Buyers/CompaniesAndUsers?SearchString=${
        searchString !== undefined ? searchString : ''
      }&PageSize=${rowsPerPage !== undefined ? rowsPerPage : 10}&PageNumber=${
        page !== undefined ? page : 1
      }&OrderBy=${sortColumn !== undefined ? sortColumn : 'name'}`
    ),

  getBuyersCompanies: (
    companyId: number,
    page?: number | undefined,
    searchString?: string | undefined,
    rowsPerPage?: number | undefined,
    sortColumn?: string | undefined
  ): Promise<BuyersCompaniesAndUsersModel> =>
    SellerApiClient.get(
      `${companyId}/Buyers/Companies?SearchString=${
        searchString !== undefined ? searchString : ''
      }&PageSize=${rowsPerPage !== undefined ? rowsPerPage : 10}&PageNumber=${
        page !== undefined ? page : 1
      }&OrderBy=${sortColumn !== undefined ? sortColumn : 'name'}`
    ),

  getBuyersUsers: (
    companyId: number,
    page?: number | undefined,
    searchString?: string | undefined,
    rowsPerPage?: number | undefined,
    sortColumn?: string | undefined
  ): Promise<BuyersUsersModel> =>
    SellerApiClient.get(
      `${companyId}/Buyers/Users?SearchString=${
        searchString !== undefined ? searchString : ''
      }&PageSize=${rowsPerPage !== undefined ? rowsPerPage : 500}&PageNumber=${
        page !== undefined ? page : 1
      }&OrderBy=${sortColumn !== undefined ? sortColumn : 'name'}`
    ),

  getMarketplaceEndSuppliersCompaniesAndContacts: (
    companyId: number,
    page?: number | undefined,
    searchString?: string | undefined,
    rowsPerPage?: number | undefined,
    sortColumn?: string | undefined
  ): Promise<SellersCompaniesAndContactsModel> =>
    SellerApiClient.get(
      `${companyId}/Buyers/ExternalCompanies?SearchString=${
        searchString !== undefined ? searchString : ''
      }&PageSize=${rowsPerPage ?? 100}&PageNumber=${
        page !== undefined ? page : 1
      }&OrderBy=${sortColumn !== undefined ? sortColumn : 'name'}`
    ),

  getCustomerCompanyInfo: (
    companyId: number,
    customerCompanyId: number
  ): Promise<CustomerCompanyModel> =>
    SellerApiClient.get(`${companyId}/Buyers/Companies/${customerCompanyId}`),

  getCustomerCreditInfo: (
    companyId: number,
    customerCompanyId: number
  ): Promise<BuyerCreditInfoModel> =>
    SellerApiClient.get(
      `${companyId}/Buyers/${customerCompanyId}/BuyerCreditInfo`
    ),

  getCustomerPricelistExport: (
    companyId: number,
    customerCompanyId: number,
    localizationId: number
  ): Promise<FileBlob> =>
    SellerApiClient.getFile(
      `${companyId}/Buyers/${customerCompanyId}/BuyerPriceListExport?localizationId=${localizationId}`
    ),

  getBuyerStocks: (
    companyId: number,
    customerCompanyId: number
  ): Promise<BuyerStocksModel[]> =>
    SellerApiClient.get(`${companyId}/Buyers/${customerCompanyId}/BuyerStocks`),

  getBuyerExternalStocks: (
    companyId: number,
    customerCompanyId: number
  ): Promise<BuyerExternalStocksModel[]> =>
    SellerApiClient.get(
      `${companyId}/Buyers/${customerCompanyId}/BuyerExternalStocks`
    ),

  updateBuyerStocks: (
    companyId: number,
    customerCompanyId: number,
    stock: BuyerUpdateStocksModel,
    stockId: number
  ): Promise<void> =>
    SellerApiClient.put(
      `${companyId}/Buyers/${customerCompanyId}/BuyerStocks/${stockId}`,
      stock
    ),

  updateBuyerExternalStocks: (
    companyId: number,
    customerCompanyId: number,
    stock: BuyerUpdateExternalStocksModel,
    stockId: number
  ): Promise<void> =>
    SellerApiClient.put(
      `${companyId}/Buyers/${customerCompanyId}/BuyerExternalStocks/${stockId}`,
      stock
    ),

  getBuyerContacts: (
    companyId: number,
    customerCompanyId: number
  ): Promise<BuyerContactsModel[]> =>
    SellerApiClient.get(
      `${companyId}/Buyers/${customerCompanyId}/BuyerContacts`
    ),

  updateBuyerContacts: (
    companyId: number,
    customerCompanyId: number,
    updateContacts: BuyerContactsUpdateModel
  ): Promise<void> =>
    SellerApiClient.put(
      `${companyId}/Buyers/${customerCompanyId}/BuyerContacts`,
      updateContacts
    ),

  // Products
  getAllProductClasses: (
    companyId: number,
    buyerCompanyId: number | null,
    level: number | null,
    classId: number | null,
    localizationId: number | null
  ): Promise<SellerProductClassInfoModel> =>
    SellerApiClient.get(
      `${companyId}/SellerProductClasses?buyerCompanyId=${
        buyerCompanyId || ''
      }&level=${level || ''}&classId=${
        classId || ''
      }&localizationId=${localizationId}`
    ),

  getProducts: (
    companyId: number,
    buyerCompanyId: number | null,
    productClassId: string,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null
  ): Promise<SellerProductModelPagedResult> =>
    SellerApiClient.get(
      `${companyId}/SellerProducts?buyerCompanyId=${
        buyerCompanyId || ''
      }&productClassId=${productClassId || ''}&searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}`
    ),

  findProducts: (
    companyId: number,
    buyerCompanyId: number | null,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null,
    isSimpleSearch: boolean
  ): Promise<SellerProductModelPagedResult> =>
    SellerApiClient.get(
      `${companyId}/SellerProducts?buyerCompanyId=${
        buyerCompanyId || ''
      }&searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}&isSimpleSearch=${isSimpleSearch}`
    ),

  getProduct: (
    companyId: number,
    buyerCompanyId: number | null,
    productId: string,
    localizationId: number
  ): Promise<ProductDetailsModel> =>
    SellerApiClient.get(
      `${companyId}/SellerProducts/${productId}?buyerCompanyId=${
        buyerCompanyId || ''
      }&localizationId=${localizationId}`
    ),

  /*  Product images */
  uploadProductImage: (
    companyId: number,
    productId: number,
    file: FormData
  ): Promise<string> =>
    SellerApiClient.postCreate(
      `${companyId}/products/${productId}/images`,
      file
    ),

  deleteProductImage: (companyId: number, productId: number): Promise<string> =>
    SellerApiClient.delete(`${companyId}/products/${productId}/images`),

  /* Projects */
  getAllProjects: (
    companyId: number,
    buyerCompanyId: number | null,
    filterState?: FilterStateModel
  ): Promise<AllProjectsModel> =>
    filterState !== undefined
      ? SellerApiClient.get(
          `${companyId}/SellerProjects?buyerCompanyId=${
            buyerCompanyId || ''
          }&dealsByMe=${
            filterState.dealsByMe !== undefined
              ? filterState.dealsByMe
              : 'false'
          }&dealStatus=${
            filterState.dealStatus !== undefined &&
            filterState.dealStatus !== null &&
            filterState.dealStatus !== 'all'
              ? filterState.dealStatus
              : ''
          }&inquiryStatus=${
            filterState.inquiryStatus !== undefined &&
            filterState.inquiryStatus !== null &&
            filterState.inquiryStatus !== 'all'
              ? filterState.inquiryStatus
              : ''
          }&offerStatus=${
            filterState.offerStatus !== undefined &&
            filterState.offerStatus !== null &&
            filterState.offerStatus !== 'all'
              ? filterState.offerStatus
              : ''
          }&orderStatus=${
            filterState.orderStatus !== undefined &&
            filterState.orderStatus !== null &&
            filterState.orderStatus !== 'all'
              ? filterState.orderStatus
              : ''
          }&invoiceStatus=${
            filterState.invoiceStatus !== undefined &&
            filterState.invoiceStatus !== null &&
            filterState.invoiceStatus !== 'all'
              ? filterState.invoiceStatus
              : ''
          }&searchString=${
            filterState.searchString !== undefined &&
            filterState.searchString !== null &&
            filterState.searchString !== 'null' &&
            filterState.searchString !== 'undefined'
              ? filterState.searchString
              : ''
          }${
            filterState.tagIds.length > 0 && !isNaN(filterState.tagIds[0])
              ? filterState.tagIds.map((id) => '&TagIds=' + id).join('')
              : ''
          }&PageSize=30&PageNumber=${filterState.pageNumber}`
        )
      : SellerApiClient.get(`${companyId}/SellerProjects?PageSize=30`),

  /* Inquiries */
  getInquiry: (
    companyId: number,
    inquiryId: number,
    localizationId: number
  ): Promise<InquiryModel> =>
    SellerApiClient.get(
      `${companyId}/SellerInquiries/${inquiryId}?localizationId=${localizationId}`
    ),

  /* Offers */
  getOffer: (
    companyId: number,
    offerId: number,
    localizationId: number
  ): Promise<OfferModel> =>
    SellerApiClient.get(
      `${companyId}/SellerOffers/${offerId}?localizationId=${localizationId}`
    ),

  recallInquiryUpdated: (companyId: number, offerId: number): Promise<void> =>
    SellerApiClient.put(
      `${companyId}/SellerOffers/${offerId}/RecallInquiryUpdated`,
      {}
    ),

  /* Orders */
  getOrder: (
    companyId: number,
    orderId: number,
    localizationId: number
  ): Promise<OrderModel> =>
    SellerApiClient.get(
      `${companyId}/SellerOrders/${orderId}?localizationId=${localizationId}`
    ),

  createQuickOrder: (
    companyId: number,
    originalOrderId: QuickOrderProjectsCreateModel
  ): Promise<QuickOrderProjectsCreateResponseModel> =>
    SellerApiClient.post(`${companyId}/QuickOrderProjects`, originalOrderId),

  getQuickOrder: (companyId: number, projectId: number): Promise<string> =>
    SellerApiClient.get(`${companyId}/QuickOrderProjects/${projectId}`),

  /* Invoices */
  getInvoice: (
    companyId: number,
    invoiceId: number,
    localizationId: number
  ): Promise<InvoiceModel> =>
    SellerApiClient.get(
      `${companyId}/SellerInvoices/${invoiceId}?localizationId=${localizationId}`
    ),

  /* Favorite products */
  createFavoriteProduct: (
    companyId: number,
    userId: number,
    favoriteProduct: SellerFavoriteProductCreateModel
  ): Promise<number> =>
    SellerApiClient.post(
      `${companyId}/users/${userId}/SellerFavoriteProducts`,
      favoriteProduct
    ),

  deleteFavoriteProduct: (
    companyId: number,
    userId: number,
    productId: number
  ): Promise<void> =>
    SellerApiClient.delete(
      `${companyId}/users/${userId}/SellerFavoriteProducts/${productId}`
    ),

  getFavoriteProducts: (
    companyId: number,
    buyerCompanyId: number | null,
    userId: number,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null
  ): Promise<SellerProductModelPagedResult> =>
    SellerApiClient.get(
      `${companyId}/users/${userId}/SellerFavoriteProducts?buyerCompanyId=${
        buyerCompanyId || ''
      }&searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}`
    ),

  /* Tags */
  createTag: (
    companyId: number,
    dealId: number,
    tag: DealCreateTagModel
  ): Promise<number> =>
    SellerApiClient.post(`${companyId}/SellerTags/${dealId}`, tag),

  getLastTags: (
    companyId: number,
    searchString?: string
  ): Promise<DealTagModel[]> =>
    SellerApiClient.get(
      `${companyId}/SellerTags?SearchString=${searchString || ''}`
    ),

  updateTag: (
    companyId: number,
    tagId: number,
    dealId: number
  ): Promise<void> =>
    SellerApiClient.put(`${companyId}/SellerTags/${dealId}/${tagId}`, {}),

  deleteTag: (
    companyId: number,
    tagId: number,
    dealId: number
  ): Promise<void> =>
    SellerApiClient.delete(`${companyId}/SellerTags/${dealId}/${tagId}`),

  /* Notifications */
  getSellerGlobalNotifications: (
    companyId: number
  ): Promise<SellerGlobalNotificationsModel> =>
    SellerApiClient.get(`${companyId}/SellerNotificationSettings`),

  updateSellerGlobalNotifications: (
    companyId: number,
    notificationSettings: SellerGlobalNotificationsUpdateModel
  ): Promise<void> =>
    SellerApiClient.put(
      `${companyId}/SellerNotificationSettings`,
      notificationSettings
    ),

  getSellerPersonalNotifications: (
    companyId: number,
    userId: number
  ): Promise<SellerPeronalNotificationsModel> =>
    SellerApiClient.get(
      `${companyId}/users/${userId}/SellerUserNotificationSettings`
    ),

  updateSellerPersonalNotifications: (
    companyId: number,
    userId: number,
    notificationSettings: SellerPeronalNotificationsUpdateModel
  ): Promise<void> =>
    SellerApiClient.put(
      `${companyId}/users/${userId}/SellerUserNotificationSettings`,
      notificationSettings
    ),

  /*  Product images */
  bulkUploadValidate: (
    companyId: number,
    fileNames: string[]
  ): Promise<BulkImageValidateModel> =>
    SellerApiClient.post(
      `${companyId}/products/images/BulkUploadValidate`,
      fileNames
    ),

  /*  Product images */
  bulkUpload: (companyId: number, file: FormData): Promise<string> =>
    SellerApiClient.postCreate(`${companyId}/products/images/BulkUpload`, file),

  /*  Product images */
  uploadProductClassImage: (
    companyId: number,
    productClassId: number,
    file: FormData
  ): Promise<void> =>
    SellerApiClient.postCreate(
      `${companyId}/SellerProductClasses/${productClassId}/Images`,
      file
    ),

  setBuyerActive: (
    companyId: number,
    buyerCompanyId: number,
    isActive: boolean
  ): Promise<void> =>
    SellerApiClient.put(`${companyId}/Buyers/Companies`, {
      isActive: isActive,
      buyerCompanyId: buyerCompanyId,
    }),
};

import { Api } from 'api/Api';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext } from 'react';
import { useAuthUser } from 'react-auth-kit';

/** Updates user current project in AppContext based on the id of the project */
export const useSetCurrentProject = () => {
  const { appContextDispatch } = useContext(AppContext);
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const setCurrentProject = async (
    id: number | null,
    sellerCompanyId?: number
  ) => {
    await Api.setCurrentProject(currentUser.companyId, currentUser.id, {
      id: id,
    });
    const user = await Api.getUser(
      currentUser.companyId,
      currentUser.id,
      sellerCompanyId
    );
    appContextDispatch({
      type: 'USER_SET_PROFILE',
      userProfile: user,
    });
  };

  return setCurrentProject;
};

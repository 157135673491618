import { BuyerApi } from 'api/BuyerApi';
import { LightTooltip } from 'components/shared/LightTooltip';
import { AppContext } from 'contexts/user/AppContext';
import { useRefreshAllSuppliers } from 'hooks/useRefreshAllSuppliers';
import { BuyerSupplierSelectionOption } from 'layout/BuyerSupplierSelectionOption';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { SellersCompaniesAndContactsModel } from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Badge } from 'react-bootstrap';
import {
  CaretLeftFill,
  CaretRightFill,
  PersonLinesFill,
} from 'react-bootstrap-icons';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import './BuyerSuppliersSelector.css';

type PropTypes = {
  setShowSelector: (value: boolean) => void;
};

export const BuyerSuppliersSelector = ({ setShowSelector }: PropTypes) => {
  const [isBuyerSuppliersSelectorOpen, setIsBuyerSuppliersSelectorOpen] =
    useState<boolean>(false);

  const [suppliers, setSuppliers] = useState<SellersCompaniesAndContactsModel>(
    {} as SellersCompaniesAndContactsModel
  );

  const [selectedSuppliers, setSelectedSuppliers] = useState<
    BuyerCurrentSuppliers[]
  >([]);

  const [allOptionsAreSelected, setAllOptionsAreSelected] =
    useState<boolean>(false);

  const [allSuppliersOptions, setAllSuppliersOption] = useState<
    BuyerCurrentSuppliers[]
  >([]);

  const [mounted, setMounted] = useState<boolean>(false);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [showEditing, setShowEditing] = useState<boolean>(false);

  const [hasAtleastOneSupplier, setHasAtleastOneSupplier] =
    useState<boolean>(true);

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const navigate = useHistory();

  const { appContextDispatch, appState } = useContext(AppContext);

  const refreshAllSuppliers = useRefreshAllSuppliers();

  const getAllSuppliersInfo = useCallback(async () => {
    // We are still using CompaniesAndUsers since Companies endpoint returns users as well. NEEDS FIXING
    const response = await BuyerApi.getSellersCompaniesAndContacts(
      currentUser.companyId
    );

    response.data.length !== appState?.buyerCurrentSuppliers?.length &&
      (await refreshAllSuppliers());
  }, [currentUser.companyId]);

  /* Get all suppliers */
  useEffect(() => {
    getAllSuppliersInfo();
  }, [getAllSuppliersInfo]);

  const handleSelectorClick = () => {
    setIsBuyerSuppliersSelectorOpen(!isBuyerSuppliersSelectorOpen);
    setShowSelector(!isBuyerSuppliersSelectorOpen);
  };

  const totalSuppliersNumber = appState.buyerCurrentSuppliers?.filter(
    (supplier) => supplier.isActive && !supplier.isMarketplace
  ).length;

  let totalSubSuppliers = 0;

  appState.buyerCurrentSuppliers
    ?.filter((supplier) => supplier.isActive && supplier.subSellers)
    .forEach((item) => {
      if (item.subSellers) {
        totalSubSuppliers += item.subSellers.length;
      }
    });

  const selectedSuppliersNumber = allSuppliersOptions.filter(
    (supplier) =>
      supplier.isSelected && supplier.isActive && !supplier.isMarketplace
  ).length;

  let totalSelectedSubSuppliers = 0;

  appState.buyerCurrentSuppliers
    ?.filter(
      (supplier) =>
        supplier.isActive && supplier.isMarketplace && supplier.isSelected
    )
    .forEach((item) => {
      if (item.subSellers)
        totalSelectedSubSuppliers += item.subSellers.filter(
          (subSeller) => subSeller.isSelected
        ).length;
    });

  const updateLocalStorageAndAppContextValue = useCallback(
    async (sups: BuyerCurrentSuppliers[]) => {
      appContextDispatch({
        type: 'BUYER_SET_CURRENT_SUPPLIERS',
        suppliers: sups || null,
      });

      localStorage.removeItem(`_buyerCurrentSuppliers_${currentUser.id}`);
      localStorage.setItem(
        `_buyerCurrentSuppliers_${currentUser.id}`,
        JSON.stringify(sups)
      );
    },
    [appContextDispatch, currentUser.id]
  );

  /* Set initial value */
  useEffect(() => {
    const setInitialValue = async () => {
      let savedBuyerCurrentSuppliers: BuyerCurrentSuppliers[];

      /* Check if buyer even has any suppliers */
      const buyerHasAtleastOneSupplier =
        appState.buyerCurrentSuppliers?.length !== 0;

      /* If is false, redirect to suppliers page and show the noSuppliers modal and do not load the supplier selector. */

      if (!buyerHasAtleastOneSupplier) {
        setHasAtleastOneSupplier(false);
        navigate.push('/buyer/suppliers');
        return;
      }

      /* Otherwise if buyer has a at least 1 supplier, proceed as before */
      if (appState?.buyerCurrentSuppliers !== undefined) {
        if (appState?.buyerCurrentSuppliers !== null) {
          savedBuyerCurrentSuppliers = appState?.buyerCurrentSuppliers;

          let index: number = 0;
          let needsRefreshOfSuppliers: boolean = false;

          for (let s of appState.buyerCurrentSuppliers) {
            index = savedBuyerCurrentSuppliers.findIndex(
              (y) => y.value === s.value
            );

            if (
              savedBuyerCurrentSuppliers[index]?.isActive !== s?.isActive ||
              savedBuyerCurrentSuppliers[index]?.isArchived !== s?.isArchived ||
              savedBuyerCurrentSuppliers[index]?.isFavourite !==
                s?.isFavourite ||
              savedBuyerCurrentSuppliers[index]?.isExternalSeller !==
                s?.isExternalSeller
            ) {
              needsRefreshOfSuppliers = true;
              break;
            }
          }

          if (needsRefreshOfSuppliers) {
            savedBuyerCurrentSuppliers = appState?.buyerCurrentSuppliers.map(
              (x) => {
                return {
                  isActive: x.isActive,
                  isArchived: x.isArchived,
                  name: x.name,
                  value: x.value,
                  isSelected: true,
                  isFavourite: x.isFavourite,
                  isExternalSeller: x.isExternalSeller,
                  logo: x?.logo,
                  currencyCode: x?.currencyCode,
                  isMarketplace: x?.isMarketplace,
                  subSellers: x?.subSellers,
                  isSubSeller: x?.isSubSeller,
                };
              }
            );
            updateLocalStorageAndAppContextValue(savedBuyerCurrentSuppliers);
          } else
            updateLocalStorageAndAppContextValue(savedBuyerCurrentSuppliers);
        }
        //  else {
        //   savedBuyerCurrentSuppliers = appState?.buyerCurrentSuppliers.map((supplier) => {
        //     return {
        //       name: supplier?.name,
        //       value: supplier?.id,
        //       isActive: supplier?.isActive ?? true,
        //       isArchived: supplier?.isArchived ?? true,
        //       isSelected: true,
        //       isFavourite: supplier?.isFavoriteSeller,
        //       isExternalSeller: supplier?.isExternalSeller,
        //     };
        //   });

        //   updateLocalStorageAndAppContextValue(savedBuyerCurrentSuppliers);
        // }
      }
    };

    setInitialValue();
  }, [
    appContextDispatch,
    appState.buyerCurrentSuppliers,
    currentUser.id,
    navigate,
    updateLocalStorageAndAppContextValue,
  ]);

  const handleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleMouseEnterToEdit = () => {
    setShowEditing(true);
  };

  const handleMouseLeaveToEdit = () => {
    setShowEditing(false);
  };

  /* Check for all suppliers selected  */
  useEffect(() => {
    const allOptions: BuyerCurrentSuppliers[] = [];

    appState.buyerCurrentSuppliers?.forEach((supplier) => {
      allOptions.push({
        name: supplier.name,
        value: supplier.value,
        isActive: supplier.isActive,
        isArchived: supplier.isArchived,
        isSelected: supplier.isSelected,
        isFavourite: supplier.isFavourite,
        isExternalSeller: supplier?.isExternalSeller,
        logo: supplier?.logo,
        currencyCode: supplier?.currencyCode,
        isMarketplace: supplier?.isMarketplace,
        subSellers: supplier?.subSellers,
        isSubSeller: supplier?.isSubSeller,
      });
    });

    setAllSuppliersOption(allOptions);
  }, [appState.buyerCurrentSuppliers]);

  useEffect(() => {
    setAllOptionsAreSelected(
      allSuppliersOptions.findIndex((supplier) => !supplier.isSelected) ===
        -1 &&
        allSuppliersOptions.findIndex((supplier) =>
          supplier.subSellers?.some((subSeller) => !subSeller.isSelected)
        ) === -1
    );
  }, [allSuppliersOptions]);

  const BuyerSelectorNode = (
    <div
      style={{ overflowY: 'auto' }}
      onMouseEnter={handleMouseEnterToEdit}
      onMouseLeave={handleMouseLeaveToEdit}
      className={`d-flex flex-column supplier-selection-accordion py-2 px-0 mb-0 pb-0 d-print-none ${
        isBuyerSuppliersSelectorOpen ? 'selector-open' : 'selector-close'
      }`}
    >
      {/* <div
        style={{ marginLeft: '-50px' }}
        onClick={handleIsEditing}
        className={`btn ${'btn-eleczap-noBorder'}  d-flex align-items-center me-3`}
      >
        {showEditing || isEditing ? (
          <PencilSquare size={20} />
        ) : (
          <div style={{ width: 20 }} />
        )}
      </div> */}

      {appState.buyerCurrentSuppliers?.filter((supplier) => supplier.isActive)
        ?.length !== 1 && (
        <BuyerSupplierSelectionOption
          allOptionsAreSelected={allOptionsAreSelected}
          setAllOptionsAreSelected={setAllOptionsAreSelected}
          allSuppliersOptions={allSuppliersOptions}
          setAllSuppliersOption={setAllSuppliersOption}
          supplier={{
            name: t('buyerHeader.lbl_AllSuppliers'),
            value: 0,
            isActive: true,
            isArchived: true,
            isSelected:
              appState?.buyerCurrentSuppliers?.length ===
              selectedSuppliers?.length,
            isFavourite: false,
            isExternalSeller: false,
            logo: null,
            currencyCode: 'EUR',
            isMarketplace: false,
            subSellers: null,
            isSubSeller: false,
          }}
          // allSuppliers={suppliers.data}
          updateLocalStorageAndAppContextValue={
            updateLocalStorageAndAppContextValue
          }
        />
      )}

      {appState.buyerCurrentSuppliers
        ?.filter((supplier) => supplier.isActive)
        ?.map(
          (supplier, index) =>
            !supplier.isArchived && (
              <>
                <BuyerSupplierSelectionOption
                  index={index}
                  allOptionsAreSelected={allOptionsAreSelected}
                  setAllOptionsAreSelected={setAllOptionsAreSelected}
                  allSuppliersOptions={allSuppliersOptions}
                  setAllSuppliersOption={setAllSuppliersOption}
                  supplier={{
                    name: supplier.name,
                    value: supplier.value,
                    isActive: supplier.isActive,
                    isArchived: supplier.isArchived,
                    isSelected: supplier.isMarketplace
                      ? supplier.subSellers?.length ===
                        supplier.subSellers?.filter(
                          (subSeller) => subSeller.isSelected
                        )?.length
                      : supplier.isSelected,
                    isFavourite: supplier.isFavourite,
                    isExternalSeller: supplier?.isExternalSeller,
                    logo: supplier?.logo,
                    currencyCode: supplier?.currencyCode,
                    isMarketplace: supplier?.isMarketplace,
                    subSellers: supplier?.subSellers,
                    isSubSeller: supplier?.isSubSeller,
                  }}
                  isFavorite={supplier.isFavourite}
                  // allSuppliers={appState.buyerCurrentSuppliers?.filter(supplier => supplier.isActive)}
                  // getAllSuppliersInfo={getAllSuppliersInfo}
                  updateLocalStorageAndAppContextValue={
                    updateLocalStorageAndAppContextValue
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
                <div className='ps-3'>
                  {supplier.isMarketplace &&
                    supplier.subSellers?.map(
                      (subSeller, index) =>
                        !subSeller.isArchived && (
                          <BuyerSupplierSelectionOption
                            index={index}
                            allOptionsAreSelected={allOptionsAreSelected}
                            setAllOptionsAreSelected={setAllOptionsAreSelected}
                            allSuppliersOptions={allSuppliersOptions}
                            setAllSuppliersOption={setAllSuppliersOption}
                            supplier={{
                              name: subSeller.name,
                              value: subSeller.value,
                              isActive: subSeller.isActive,
                              isArchived: subSeller.isArchived,
                              isSelected: subSeller.isSelected,

                              isFavourite: subSeller.isFavourite,
                              isExternalSeller: subSeller?.isExternalSeller,
                              logo: subSeller?.logo,
                              currencyCode: subSeller?.currencyCode,
                              isMarketplace: subSeller?.isMarketplace,
                              subSellers: subSeller?.subSellers,
                              isSubSeller: subSeller?.isSubSeller,
                            }}
                            isFavorite={subSeller.isFavourite}
                            // allSuppliers={appState.buyerCurrentSuppliers?.filter(supplier => supplier.isActive)}
                            // getAllSuppliersInfo={getAllSuppliersInfo}
                            updateLocalStorageAndAppContextValue={
                              updateLocalStorageAndAppContextValue
                            }
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                          />
                        )
                    )}
                </div>
              </>
            )
        )}
    </div>
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      {mounted &&
        ReactDOM.createPortal(
          BuyerSelectorNode,
          document &&
            document.getElementsByClassName(
              'buyer-supplier-selector-portal-receiver'
            )[0]
        )}
      {
        <div
          id='suppliers-filter'
          data-tip
          data-for='buyer-current-suppliers-filter'
          onClick={handleSelectorClick}
          className='icon-ez-white my-3 cursor-pointer d-flex justify-content-center align-items-center'
        >
          <div className='position-relative'>
            <PersonLinesFill size={30} style={{ marginTop: '-5px' }} />

            <Badge
              className='position-absolute'
              style={{ right: '-18px', top: '-25px' }}
              bg={
                (totalSuppliersNumber ?? 0) + (totalSubSuppliers ?? 0) ===
                selectedSuppliersNumber + totalSelectedSubSuppliers
                  ? 'info'
                  : 'warning'
              }
            >
              <span style={{ fontSize: 'larger' }}>
                {selectedSuppliersNumber + totalSelectedSubSuppliers}
              </span>{' '}
              / {(totalSuppliersNumber ?? 0) + (totalSubSuppliers ?? 0)}
            </Badge>
          </div>

          {isBuyerSuppliersSelectorOpen ? (
            <CaretLeftFill style={{ marginTop: '-5px' }} className='ms-2' />
          ) : (
            <CaretRightFill style={{ marginTop: '-5px' }} className='' />
          )}
        </div>
      }

      <LightTooltip place='bottom' id={`buyer-current-suppliers-filter`}>
        <strong style={{ fontSize: '20px' }}>
          {t('buyerHeader.lbl_SuppliersFilter')}
        </strong>
      </LightTooltip>
    </>
  );
};

import FilePicker from '@mavedev/react-file-picker';
import { BuyerApi } from 'api/BuyerApi';
import { CompanyApi } from 'api/CompanyApi';
import { ProjectInquiryImportExcel } from 'components/buyer/projectInquiry/ProjectInquiryImportExcel';
import { ProjectInquiryImportProductsModal } from 'components/buyer/projectInquiry/ProjectInquiryImportProductsModal/ProjectInquiryImportProductsModal';
import { DeliveryAddressesNew } from 'components/buyer/settings/deliveryAddresses/DeliveryAddressNew/DeliveryAddressesNew';
import {
  getAddressById,
  getAllAddresses,
} from 'components/buyer/settings/deliveryAddresses/utilsDeliveryAddresses';
import { ProjectAttachmentItem } from 'components/shared/deals/ProjectAttachmentItem/ProjectAttachmentItem';
import { ErrorToast } from 'components/shared/toasts/ErrorToast';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useSetCurrentProject } from 'hooks/useSetCurrentProject';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { ProjectInquiryModel } from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  AddressModel,
  AddressModelById,
} from 'models/shared/address/AddressModel';
import { ProjectAttachmentsModel } from 'models/shared/attachments/AttachmentModel';
import { getProjectInquiry } from 'pages/buyer/ProjectInquiry/utilsProjectInquiry';
import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  ArrowCounterclockwise,
  Paperclip,
  PencilSquare,
  X,
} from 'react-bootstrap-icons';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  projectDetails: ProjectInquiryModel;
  formik: any;
  setProjectDetails: (value: ProjectInquiryModel) => void;
  setShowDisabledRows: (value: boolean) => void;
  showDisabledRows: boolean;
  projectId: number;
  getDataSource: () => Promise<void>;
};

export const ProjectInquiryHeader = ({
  projectDetails,
  formik,
  setProjectDetails,
  getDataSource,
  projectId,
}: PropTypes) => {
  const [isNameEditing, setIsNameEditing] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [shownView, setShownView] = useState<'orders' | 'inquiries'>(
    'inquiries'
  );

  const setCurrentProject = useSetCurrentProject();

  const [asOptionShow, setAsOptionShow] = useState<boolean>(false);

  const [requestedIsoDate, setRequestedIsoDate] = useState<string | null>(null);
  const [offerIsoDate, setOfferIsoDate] = useState<string | null>(null);
  const [requestedDate, setRequestedDate] = useState<string | null>('');
  const [offerDate, setOfferDate] = useState<string | null>(null);

  const [deliveryAddressId, setDeliveryAddressId] = useState<number>(0);

  const [addresses, setAddresses] = useState<AddressModel>({} as AddressModel);
  const [filteredAddresses, setFilteredAddresses] = useState<
    AddressModelById[]
  >([]);

  const [currentProjectName, setCurrentProjectName] = useState('');
  const setUserProfile = useSetUserProfile();

  const { appState } = useContext(AppContext);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { t } = useTranslation('components');

  /* Get all addresses */
  useEffect(() => {
    const getAddresses = async () => {
      await getAllAddresses(
        currentUser,
        setAddresses,
        undefined,
        undefined,
        undefined,
        'isDefault_desc'
      );
    };

    getAddresses();
  }, [currentUser]);

  useEffect(() => {
    /* filter out not visible address */

    let clonedAddress = { ...addresses };

    if (clonedAddress.data !== undefined) {
      const filtered = clonedAddress.data.filter(
        (address) => address.isVisible
      );

      setFilteredAddresses(filtered);

      if (projectDetails.deliveryAddressId === null) {
        formik.setFieldValue('deliveryAddressId', filtered[0]?.id);

        formik.handleSubmit();
      }
    }
  }, [addresses, projectDetails.deliveryAddressId]);

  useEffect(() => {
    addresses.data !== undefined &&
      addresses.data[0] !== undefined &&
      projectDetails.deliveryAddressId &&
      setDeliveryAddressId(
        projectDetails.deliveryAddressId !== addresses.data[0].id
          ? projectDetails.deliveryAddressId
          : addresses.data[0].id
      );
  }, [addresses.data, projectDetails.deliveryAddressId]);

  const inputFile = useRef<HTMLInputElement>(null);

  const handleProjectNameBlur = async (): Promise<void> => {
    setIsNameEditing(false);

    await BuyerApi.projects.updateProjectName(
      currentUser.companyId,
      projectId,
      {
        name: formik.values.name,
      }
    );

    const response = await BuyerApi.projectInquiry.getProjectInquiry(
      currentUser.companyId,
      projectId,
      projectDetails.id
    );

    await setCurrentProject(projectId);

    // If project name was changed, reload user profile with updated project name
    // into AppContext so that current project name in header is updated also
    await setUserProfile(appState.buyerCurrentSupplierId);

    setProjectDetails(response);
  };

  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setIsNameEditing(false);
      await handleProjectNameBlur();
    }
  };

  const handleOpenFilePicker = () => {
    inputFile.current !== null && inputFile.current.click();
  };

  const handleAddNewFile = async (file: File) => {
    const reader = new FileReader();
    const formData = new FormData();

    formData.append('file', file);
    formData.append('inquiryProjectId', projectDetails.id.toString());

    const input: HTMLInputElement | null =
      document.querySelector('input[type=file]');

    if (input !== null) {
      input.value = '';
    }

    reader.readAsDataURL(file);
    reader.onload = async function () {
      reader.result !== undefined &&
        (await CompanyApi.addNewInquiryAttachment(
          currentUser.companyId,
          projectId,
          formData
        ));

      await getProjectInquiry(
        projectId,
        projectDetails.id,
        currentUser.companyId,
        setProjectDetails
      );
    };
  };

  const handleDeleteAttachment = async (id: number) => {
    let timeout: any = 0;

    toast.success(t('projectSettings.inf_DeletingFile'), {
      className: 'delete-attachment-toast',
      autoClose: 5000,
      pauseOnHover: true,
      closeButton: (
        <Button
          className='btn-undo-delete-attachment'
          onClick={() => clearTimeout(timeout)}
        >
          <ArrowCounterclockwise size={20} />
        </Button>
      ),
    });

    timeout = setTimeout(async () => {
      await CompanyApi.deleteInquiryAttachment(
        currentUser.companyId,
        projectId,
        projectDetails.id,
        id
      );

      await getProjectInquiry(
        projectId,
        projectDetails.id,

        currentUser.companyId,
        setProjectDetails
      );
    }, 5000);
  };

  const handleOpenRecentInquiries = () => {
    setShow(true);
    setShownView('inquiries');
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleOpenRecentOrders = () => {
    setShow(true);
    setShownView('orders');
  };

  const disabledDays = {
    from: new Date(
      new Date().getFullYear() - 1,
      dayjs().startOf('month').month(),
      1
    ),
    to: new Date(
      dayjs().startOf('month').year(),
      dayjs().startOf('month').month(),
      new Date().getDate() - 1
    ),
  };

  const handleDateDelete =
    (field: 'requestedDeliveryDate' | 'offerDeadlineDate') => () => {
      if (field === 'requestedDeliveryDate') {
        setRequestedDate(t('common.lbl_DDMMYYYY'));
        setRequestedIsoDate(null);
      }

      if (field === 'offerDeadlineDate') {
        setOfferDate(t('common.lbl_DDMMYYYY'));
        setOfferIsoDate(null);
      }
      formik.setFieldValue(field, null);

      formik.handleSubmit();
    };

  const handleDateChange =
    (field: 'requestedDeliveryDate' | 'offerDeadlineDate') =>
    async (day: Date) => {
      const pickedDate = dayjs(day).format('DD-MM-YYYY');

      if (field === 'requestedDeliveryDate') {
        setRequestedDate(pickedDate);
        setRequestedIsoDate(dayjs(day).toISOString());
      }

      if (field === 'offerDeadlineDate') {
        setOfferDate(pickedDate);
        setOfferIsoDate(dayjs(day).toISOString());
      }

      await formik.setFieldValue(field, dayjs(day).toISOString());

      await formik.handleSubmit();
    };

  useEffect(() => {
    if (projectDetails.requestedDeliveryDate) {
      setRequestedDate(
        dayjs(projectDetails.requestedDeliveryDate).format('DD-MM-YYYY')
      );
      setRequestedIsoDate(projectDetails.requestedDeliveryDate);
    } else {
      setRequestedDate(t('common.lbl_DDMMYYYY'));
      setRequestedIsoDate(null);
    }

    if (projectDetails.offerDeadlineDate) {
      setOfferDate(
        dayjs(projectDetails.offerDeadlineDate).format('DD-MM-YYYY')
      );
      setOfferIsoDate(projectDetails.offerDeadlineDate);
    } else {
      setOfferDate(t('common.lbl_DDMMYYYY'));
      setOfferIsoDate(null);
    }
  }, [projectDetails.offerDeadlineDate, projectDetails.requestedDeliveryDate]);

  const setNewDeliverAddressAsSelected = async (value: number) => {
    const response = await getAddressById(currentUser, value);

    const clonedAddresses: AddressModel = { ...addresses };

    clonedAddresses.data.unshift(response);

    setAddresses(clonedAddresses);

    setDeliveryAddressId(value);
  };

  const handleDeliveryChange = async (event: FormEvent<HTMLSelectElement>) => {
    event.stopPropagation();

    const valueOfThisFunction =
      parseInt(event.currentTarget.value) === -1
        ? null
        : parseInt(event.currentTarget.value);

    if (formik.values.deliveryAddressId !== valueOfThisFunction) {
      if (event.currentTarget.value === 'addNewAddress') {
        setAsOptionShow(true);
      } else {
        setDeliveryAddressId(parseInt(event.currentTarget.value));
        formik.setFieldValue('deliveryAddressId', valueOfThisFunction);
        formik.handleSubmit();
      }
    }
  };

  return (
    <Card.Header className='px-2 py-0 '>
      <Row>
        <Col lg={6}>
          <Row className='mb-2'>
            <Col>
              {!isNameEditing ? (
                <h1>
                  {formik.values.name}{' '}
                  <PencilSquare
                    onClick={() => setIsNameEditing(true)}
                    style={{ cursor: 'pointer' }}
                    fontSize={'14px'}
                    className='icon-ez'
                  />
                </h1>
              ) : (
                <FormControl
                  autoFocus
                  name='name'
                  style={{ fontSize: '23px', fontWeight: '600' }}
                  className='borderless-input'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onBlur={handleProjectNameBlur}
                  onKeyUp={handleKeyUp}
                  aria-label='project-name'
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='d-flex align-items-center'>
                <div className='me-3'>
                  <ProjectInquiryImportProductsModal
                    projectId={projectId}
                    getDataSource={getDataSource}
                    projectDetails={projectDetails}
                  />
                </div>

                <ProjectInquiryImportExcel
                  refreshData={getDataSource}
                  projectId={projectId}
                  projectInquiryId={projectDetails.id}
                />

                <Dropdown drop='down' as={ButtonGroup}>
                  <Dropdown.Toggle
                    id='project-view-attachments-toggle'
                    className='btn btn-eleczap dropdown-toggle ms-3'
                  >
                    <Paperclip /> {t('common.btn_AllFiles')} (
                    {projectDetails.attachments?.length})
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='super-colors'>
                    {projectDetails.attachments?.map(
                      (attachment: ProjectAttachmentsModel, index: number) => (
                        <ProjectAttachmentItem
                          handleDeleteAttachment={handleDeleteAttachment}
                          attachment={attachment}
                          index={index}
                        />
                      )
                    )}

                    <Dropdown.Divider />
                    <FilePicker
                      maxSize={10}
                      sizeUnit='MB'
                      extensions={[
                        '.jpg',
                        '.png',
                        '.jpeg',
                        '.pdf',
                        '.txt',
                        '.doc',
                        '.docx',
                        '.xls',
                        '.xlsx',
                        '.ppt',
                        '.pptx',
                        '.dwg',
                        '.dxf',
                      ]}
                      onFilePicked={(file) => {
                        handleAddNewFile(file);
                      }}
                      onError={(code) => {
                        ErrorToast(code.toString());
                      }}
                    >
                      <Dropdown.Item
                        onClick={handleOpenFilePicker}
                        eventKey='4'
                      >
                        {t('projectSettings.lbl_AddNewAttachment')}
                      </Dropdown.Item>
                    </FilePicker>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>

            {/* <Col className='d-flex justify-content-end'>
          <Button
            onClick={handleOpenRecentInquiries}
            className='btn btn-eleczap mx-3'
          >
            See recent Inquiries
          </Button>
          <Button onClick={handleOpenRecentOrders} className='btn btn-eleczap'>
            See recent Orders
          </Button>
        </Col> */}
          </Row>
        </Col>

        <Col className='text-end'>
          <Row className='mb-2'>
            <Col>{t('documents.lbl_OfferDeadline')}:</Col>
            <Col lg={5}>
              <DayPickerInput
                format='DD-MM-YYYY'
                dayPickerProps={{
                  fromMonth: new Date(),
                  disabledDays: disabledDays,
                }}
                style={{ width: '100%' }}
                component={(props: any) => (
                  <InputGroup>
                    <FormControl className='shorter-height-fields' {...props} />
                    <InputGroup.Text
                      onClick={handleDateDelete('offerDeadlineDate')}
                      className='shorter-height-fields'
                      style={{ cursor: 'pointer' }}
                    >
                      <X className='icon-ez-red' size={21} />
                    </InputGroup.Text>
                  </InputGroup>
                )}
                value={
                  offerDate === 'Invalid Date'
                    ? t('common.lbl_DDMMYYYY')
                    : offerDate ?? t('common.lbl_DDMMYYYY')
                }
                onDayChange={handleDateChange('offerDeadlineDate')}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col>{t('projectSettings.lbl_RequestedDeliveryDate')}:</Col>
            <Col lg={5}>
              <DayPickerInput
                format='DD-MM-YYYY'
                dayPickerProps={{
                  fromMonth: new Date(),
                  disabledDays: disabledDays,
                }}
                style={{ width: '100%' }}
                component={(props: any) => (
                  <InputGroup>
                    <FormControl className='shorter-height-fields' {...props} />
                    <InputGroup.Text
                      onClick={handleDateDelete('requestedDeliveryDate')}
                      className='shorter-height-fields'
                      style={{ cursor: 'pointer' }}
                    >
                      <X className='icon-ez-red' size={21} />
                    </InputGroup.Text>
                  </InputGroup>
                )}
                value={
                  requestedDate === 'Invalid Date'
                    ? t('common.lbl_DDMMYYYY')
                    : requestedDate ?? t('common.lbl_DDMMYYYY')
                }
                onDayChange={handleDateChange('requestedDeliveryDate')}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col>{t('documents.lbl_MyInquiryNumber')}:</Col>
            <Col lg={5}>
              <FormControl
                className='shorter-height-fields'
                onChange={formik.handleChange}
                onBlur={formik.handleSubmit}
                name='customerInquiryCode'
                value={formik.values.customerInquiryCode}
              />
            </Col>
          </Row>
          <Row className='mb-0'>
            <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
            <Col lg={5}>
              <Form.Select
                className='shorter-height-fields'
                value={deliveryAddressId !== 0 ? deliveryAddressId : -1}
                style={{ width: '100%' }}
                onChange={handleDeliveryChange}
              >
                <option value={-1}>
                  {t('addressSettings.lbl_SelectDeliveryAddress')}
                </option>
                {filteredAddresses !== undefined &&
                  filteredAddresses.map((address, key) => (
                    <option value={address.id}>
                      {address.name} ({address.fullAddress})
                    </option>
                  ))}
                <DeliveryAddressesNew
                  asOption
                  asOptionShow={asOptionShow}
                  setAsOptionShow={setAsOptionShow}
                  setAddresses={setAddresses}
                  setNewDeliverAddressAsSelected={
                    setNewDeliverAddressAsSelected
                  }
                  t={t}
                />
              </Form.Select>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <RecentDocsDrawer
        shownView={shownView}
        show={show}
        handleClose={handleClose}
      /> */}
    </Card.Header>
  );
};

import FilePicker from '@mavedev/react-file-picker';
import { CompanyApi } from 'api/CompanyApi';
import { ProjectAttachmentItem } from 'components/shared/deals/ProjectAttachmentItem/ProjectAttachmentItem';
import { ProjectModel } from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProjectAttachmentsModel } from 'models/shared/attachments/AttachmentModel';
import { getProjectDetails } from 'pages/buyer/Project/utilsProject';
import { useRef, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  FormControl,
  Row,
} from 'react-bootstrap';
import {
  ArrowCounterclockwise,
  Paperclip,
  PencilSquare,
} from 'react-bootstrap-icons';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  projectDetails: ProjectModel;
  formik: any;
  getDataSource: () => Promise<void>;
  setProjectDetails: (value: ProjectModel) => void;
  setShowDisabledRows: (value: boolean) => void;
  showDisabledRows: boolean;
};

export const ProjectHeader = ({
  projectDetails,
  formik,
  getDataSource,
  setProjectDetails,
  setShowDisabledRows,
  showDisabledRows,
}: PropTypes) => {
  const [isNameEditing, setIsNameEditing] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [shownView, setShownView] = useState<'orders' | 'inquiries'>(
    'inquiries'
  );

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { t } = useTranslation('components');

  const inputFile = useRef<HTMLInputElement>(null);

  const handleProjectNameBlur = async (): Promise<void> => {
    setIsNameEditing(false);
    await formik.handleSubmit();
  };

  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setIsNameEditing(false);
      await formik.handleSubmit();
    }
  };

  const handleOpenFilePicker = () => {
    inputFile.current !== null && inputFile.current.click();
  };

  const handleAddNewFile = async (file: File) => {
    const reader = new FileReader();
    const formData = new FormData();

    formData.append('file', file);

    const input: HTMLInputElement | null =
      document.querySelector('input[type=file]');

    if (input !== null) {
      input.value = '';
    }

    reader.readAsDataURL(file);
    reader.onload = async function () {
      reader.result !== undefined &&
        (await CompanyApi.addNewAttachment(
          currentUser.companyId,
          projectDetails.id,
          formData
        ));

      await getProjectDetails(
        projectDetails.id,
        currentUser,
        setProjectDetails
      );
    };
  };

  const handleDeleteAttachment = async (id: number) => {
    let timeout: any = 0;

    toast.success(t('projectSettings.inf_DeletingFile'), {
      className: 'delete-attachment-toast',
      autoClose: 5000,
      pauseOnHover: true,
      closeButton: (
        <Button
          className='btn-undo-delete-attachment'
          onClick={() => clearTimeout(timeout)}
        >
          <ArrowCounterclockwise size={20} />
        </Button>
      ),
    });

    timeout = setTimeout(async () => {
      await CompanyApi.deleteAttachment(
        currentUser.companyId,
        projectDetails.id,
        id
      );
      await getProjectDetails(
        projectDetails.id,
        currentUser,
        setProjectDetails
      );
    }, 5000);
  };

  const handleOpenRecentInquiries = () => {
    setShow(true);
    setShownView('inquiries');
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleOpenRecentOrders = () => {
    setShow(true);
    setShownView('orders');
  };

  return (
    <Card.Header className='p-3'>
      <Row className='mb-2'>
        <Col>
          {!isNameEditing ? (
            <h2>
              {formik.values.name}{' '}
              <PencilSquare
                onClick={() => setIsNameEditing(true)}
                style={{ cursor: 'pointer' }}
                fontSize={'14px'}
                className='icon-ez'
              />
            </h2>
          ) : (
            <FormControl
              name='name'
              className='borderless-input'
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={handleProjectNameBlur}
              onKeyUp={handleKeyUp}
              aria-label='project-name'
              autoFocus
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='d-flex align-items-center'>
            {/* <ProjectImportProductsModal
              getDataSource={getDataSource}
              projectDetails={projectDetails}
            /> */}

            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                id='project-view-attachments-toggle'
                className='btn btn-eleczap dropdown-toggle ms-3'
              >
                <Paperclip /> {t('common.btn_AllFiles')} (
                {projectDetails.attachments.length})
              </Dropdown.Toggle>
              <Dropdown.Menu className='super-colors'>
                {projectDetails.attachments.map(
                  (attachment: ProjectAttachmentsModel, index: number) => (
                    <ProjectAttachmentItem
                      handleDeleteAttachment={handleDeleteAttachment}
                      attachment={attachment}
                      index={index}
                    />
                  )
                )}

                <Dropdown.Divider />
                <FilePicker
                  maxSize={10}
                  sizeUnit='MB'
                  extensions={[
                    '.jpg',
                    '.png',
                    '.jpeg',
                    '.pdf',
                    '.txt',
                    '.doc',
                    '.docx',
                    '.xls',
                    '.xlsx',
                    '.ppt',
                    '.pptx',
                    '.dwg',
                    '.dxf',
                  ]}
                  onFilePicked={(file) => {
                    handleAddNewFile(file);
                  }}
                  onError={(code) => {
                    toast.error(code);
                  }}
                >
                  <Dropdown.Item onClick={handleOpenFilePicker} eventKey='4'>
                    {t('projectSettings.lbl_AddNewAttachment')}
                  </Dropdown.Item>
                </FilePicker>
              </Dropdown.Menu>
            </Dropdown>
            <Form.Check
              checked={showDisabledRows}
              onChange={(event) => setShowDisabledRows(event?.target.checked)}
              className='ms-4'
              type='switch'
              label={t('projectSettings.lbl_ShowDisabledRows')}
            />
          </div>
        </Col>

        {/* <Col className='d-flex justify-content-end'>
          <Button
            onClick={handleOpenRecentInquiries}
            className='btn btn-eleczap mx-3'
          >
            See recent Inquiries
          </Button>
          <Button onClick={handleOpenRecentOrders} className='btn btn-eleczap'>
            See recent Orders
          </Button>
        </Col> */}
      </Row>
      {/* <RecentDocsDrawer
        shownView={shownView}
        show={show}
        handleClose={handleClose}
      /> */}
    </Card.Header>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { getAllSuppliersAndContacts } from 'components/buyer/suppliers/utilsSuplliers';
import { useFormik } from 'formik';
import { useRefreshAllSuppliers } from 'hooks/useRefreshAllSuppliers';
import { ExternalSupplierCreateModel } from 'models/buyer/suppliers/ExternalSupplierModels';
import { SellersCompaniesAndContactsModel } from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

export const useFormikForCreateExternalSupplier = (
  setSuppliers: (value: SellersCompaniesAndContactsModel) => void,
  setShow: (value: boolean) => void
) => {
  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const refreshAllSuppliers = useRefreshAllSuppliers();

  const handleSubmit = async (values: ExternalSupplierCreateModel) => {
    toast.dismiss();
    await BuyerApi.createExternalSeller(currentUser.companyId, values);

    toast.success(t('externalSupplier.inf_ExternalSupplierCreatedSuccess'));

    await getAllSuppliersAndContacts(currentUser, setSuppliers);

    await refreshAllSuppliers();
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    firstName: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    lastName: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    email: yup
      .string()
      .email(t('common.err_EmailInvalid'))
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),

    countryCode: yup.string(),
    currencyCode: yup.string(),
  });

  const initialValues: ExternalSupplierCreateModel = {
    name: '',
    shortName: '',
    firstName: '',
    lastName: '',
    email: '',
    countryCode: 'AD',
    currencyCode: 'EUR',
    localizationId: 1,
  };

  const formik = useFormik<ExternalSupplierCreateModel>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema,

    onSubmit: async (values) => {
      const createSupplier: ExternalSupplierCreateModel = {
        name: values.name,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        countryCode: values.countryCode,
        currencyCode: values.currencyCode,
        localizationId: values.localizationId,
        shortName: values.shortName,
      };

      await handleSubmit(createSupplier);
      setShow(false);
      formik.resetForm();
      formik.setSubmitting(false);
    },
  });

  return formik;
};

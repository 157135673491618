import { AppContext } from 'contexts/user/AppContext';
import { useContext } from 'react';

export const useFormatNumber = () => {
  const { appState } = useContext(AppContext);
  const cultureName = appState.userProfile?.localization?.cultureName;
  const formatNumber = (value: number, maxFractionDigits?: number): string => {
    if (isNaN(value)) {
      return '';
    }

    return Intl.NumberFormat(cultureName, {
      maximumFractionDigits: maxFractionDigits,
    }).format(value);
  };

  return formatNumber;
};

import { Row, Col, Card } from 'react-bootstrap';
import { TagFill, Paperclip } from 'react-bootstrap-icons';

type PropTypes = {
  creatorName: string;
  creatorInitials: string;
};

export const BuyerDealsEmptyCard = ({
  creatorName,
  creatorInitials,
}: PropTypes) => {
  return (
    <Row className='px-1 justify-content-between'>
      {/* Deals */}

      <Col>
        <hr className='deals-dealname-divider mb-1' />
        <Card className='card-deals p-0'>
          <Card.Header className='py-1 px-2'>
            <Row className='mb-3'>
              <Col lg={10}>
                <TagFill size={15} className='icon-ez' />
              </Col>
              <Col lg={2}></Col>
            </Row>
            <Row className='row'>
              <Col>
                <span className='float-start mt-2'>{creatorName}</span>
              </Col>
              <Col>
                <span className='float-end rounded-initials text-nowrap rounded-circle border'>
                  {creatorInitials}
                </span>
                <Paperclip className='icon-ez mt-2 me-1 float-end' />
              </Col>
            </Row>
          </Card.Header>
        </Card>
      </Col>

      <Col />
      <Col />
      <Col />
      <Col />
    </Row>
  );
};

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { AppContext } from 'contexts/user/AppContext';
import { ProductQuickSearchGroupedModel } from 'models/buyer/product/ProductQuickSearchGroupedModel';
import { useContext, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { MenuListProps, SingleValue, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import './ProductSearch.css';

export type ProductOption = {
  value: number | 'AllResults';
  productCode: string;
  suppliersData: { supplierName: string; name: string }[];
};

type PropsType = {
  searchRoute: string;
  detailsRoute: string;
  handleSearch: (
    searchString: string,
    sellerIds: number[] | null
  ) => Promise<ProductQuickSearchGroupedModel[]>;
};

export const BuyerProductSearchByCode = ({
  searchRoute,
  detailsRoute,
  handleSearch,
}: PropsType) => {
  const { t } = useTranslation('components');

  const [searchString, setSearchString] = useState<string>('');
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const history = useHistory();

  const { appState } = useContext(AppContext);

  const handleInputChange = (newalue: string) => {
    setIsSearching(true);
    setSearchString(newalue);
  };

  /* TODO: Dennis - Clarify how to display products from multiple sellers on search */
  const findProducts = async (
    searchString: string
  ): Promise<ProductOption[]> => {
    setIsSearching(true);
    let sellerIds =
      appState.buyerCurrentSuppliers === null
        ? null
        : appState.buyerCurrentSuppliers
            .filter(
              (supplier) => supplier.isSelected && !supplier.isMarketplace
            )
            .map((filteredSupplier) => filteredSupplier.value);

    appState.buyerCurrentSuppliers
      ?.filter((supplier) => supplier.isSelected && supplier.isMarketplace)
      .forEach((filteredSubSeller) =>
        filteredSubSeller.subSellers?.forEach((subSeller) =>
          sellerIds?.push(subSeller.value)
        )
      );

    setTotalItems(0);

    const productsResponse = await handleSearch(searchString, sellerIds);

    const productsRender: ProductOption[] = [];

    const items = productsResponse;

    let totalItemsCount = productsResponse.length;
    for (let i = 0; i < items.length; ++i) {
      productsRender.push({
        value: items[i].products[0].id,
        productCode: items[i].productCode,
        suppliersData: items[i].products.map((x) => {
          return {
            supplierName: x.sellerName,
            name: x.name,
          };
        }),
      });
    }

    const options = productsRender;

    setTotalItems(totalItemsCount);

    setIsSearching(false);

    return options;
  };

  const formatOptionLabel = (option: ProductOption): JSX.Element =>
    option.value !== 'AllResults' ? (
      <div
        className='d-flex flex-row justify-content-between'
        key={option.productCode}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-3'>{option.productCode}</div>
            <div className='col-9'>
              {option.suppliersData.map((opt) => {
                return (
                  <div>
                    <span className=''>
                      {opt.name} <b>{opt.supplierName}</b>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div key={option.value} className='text-center'>
        <span className='btn-link-bold'>{option.value}</span>
      </div>
    );

  const handleProductSelect = (option: SingleValue<ProductOption>): void => {
    if (option !== null) {
      const url = `${detailsRoute}/${option?.productCode}`;

      history.push(url);
      document.getElementsByClassName('select-product-search__input')[0] !==
        undefined &&
        (
          document.getElementsByClassName(
            'select-product-search__input'
          )[0] as HTMLElement
        ).blur();
    }
  };

  const handleAllResults = (): void => {
    const searchParam = new URLSearchParams({
      search: searchString,
    });
    const url = `${searchRoute}?ByCode=true&${searchParam}`;

    history.push(url);

    document.getElementsByClassName('select-product-search__input')[0] !==
      undefined &&
      (
        document.getElementsByClassName(
          'select-product-search__input'
        )[0] as HTMLElement
      ).blur();
  };

  const CustomMenuList = (
    props: MenuListProps<ProductOption, false>
  ): JSX.Element => (
    <components.MenuList {...props} className='customReactSelectMenu'>
      {props.children}

      {!isSearching && (
        <div
          onClick={handleAllResults}
          key='AllResults'
          className='text-center btn-all-search-results btn-link-bold'
        >{`${t('productSearch.lbl_AllSearchSesults')} (${totalItems})`}</div>
      )}
    </components.MenuList>
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAllResults();
    }
  };

  // const Control = ({
  //   children,
  //   ...props
  // }: ControlProps<ProductOption, false>) => (
  //   <components.Control {...props}>
  //     <Search className='ms-2' /> {children}
  //   </components.Control>
  // );

  return (
    <Nav.Item style={{ flexGrow: 1 }} className='me-0'>
      <div
        style={{ maxWidth: '50%' }}
        className='input-group input-group-navbar flex-row flex-nowrap float-end'
        id='productSearch'
      >
        <span
          className={
            'search-icon-header' + searchRoute.includes('seller')
              ? 'search-icon-header-seller'
              : ''
          }
        >
          <Search />
        </span>
        <AsyncSelect
          blurInputOnSelect
          onInputChange={handleInputChange}
          className={
            searchRoute.includes('seller')
              ? 'header-search-input d-sm-inline-block header-search-input-seller'
              : 'header-search-input d-sm-inline-block'
          }
          aria-label='Search'
          placeholder={t('productSearch.lbl_SearchProductsByCode')}
          openMenuOnFocus={false}
          openMenuOnClick={false}
          noOptionsMessage={() => t('common.lbl_NoOptions')}
          loadingMessage={() => t('common.lbl_Loading')}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            MenuList: CustomMenuList,
            // Control,
          }}
          formatOptionLabel={formatOptionLabel}
          onKeyDown={handleKeyDown}
          onChange={handleProductSelect}
          loadOptions={AwesomeDebouncePromise(findProducts, 400)}
          controlShouldRenderValue={false}
          classNamePrefix='select-product-search'
        />
      </div>
    </Nav.Item>
  );
};

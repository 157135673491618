import { IdentityApi } from 'api/IdentityApi';
import { ExternalSellerOfferUpsertModel } from 'models/seller/documents/ExternalSellerOfferModels';
import { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { FileEarmarkFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  accessToken: string | null;
  offer: ExternalSellerOfferUpsertModel;
  count: number;
  offerNumber: string;
  getOffer: () => Promise<void>;
};

export const ExternalSellerUpdateOfferModal = ({
  accessToken,
  offer,
  count,
  offerNumber,
  getOffer,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleUpdateOffer = async () => {
    setIsLoading(true);
    if (accessToken) {
      await IdentityApi.updateExternalSellerOffer(accessToken, offer);
      await getOffer();
      toast.success(t('documents.inf_OfferSent'));
    }

    handleClose();

    setIsLoading(false);
  };

  return (
    <>
      <Button
        disabled={isLoading || count === 0}
        onClick={handleShow}
        className='btn btn-eleczap float-end d-flex align-items-center'
      >
        <FileEarmarkFill className='me-1' />
        {t('documents.btn_UpdateOffer')}
      </Button>

      <Modal size='sm' show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{t('documents.hd_UpdateOffer')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{t('documents.inf_UpdateOffer', { offerNumber })}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={isLoading}
            onClick={handleClose}
            variant='secondary'
          >
            {t('common.btn_Cancel')}
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleUpdateOffer}
            variant='btn btn-eleczap'
          >
            {t('common.btn_Ok')}{' '}
            {isLoading && <Spinner animation='border' size='sm' />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

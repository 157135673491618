import { BuyerApi } from 'api/BuyerApi';
import {
  OfferExpired,
  OfferNoReplyBuyer,
  OfferOrderMade,
} from 'assets/icons/StatusIcons';
import { SupplierMoreInfoPane } from 'components/buyer/documents/SupplierMoreInfoPane';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useAddProductsToProjectInquiry } from 'hooks/useAddProductsToProjectInquiry';
import { useAddProductsToProjectOrder } from 'hooks/useAddProductsToProjectOrder';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useUserCanOrder } from 'hooks/useUserCanOrder';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { OfferModel, OfferRowModel } from 'models/buyer/documents/OfferModels';
import {
  OrderCreateForSessionModel,
  OrderRowModel,
} from 'models/buyer/documents/OrderModels';
import {
  ProjectImportProductsModel,
  ProjectModel,
} from 'models/buyer/project/ProjectModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  CartFill,
  CartPlusFill,
  Clipboard2PlusFill,
} from 'react-bootstrap-icons';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { SiMicrosoftexcel } from 'react-icons/si';
import { Link, useHistory, useParams } from 'react-router-dom';
import { handleDownloadOfferExcel } from 'utils/downloadExcelSheet';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { truncateDecimals } from 'utils/truncateDecimals';

import './Offer.css';

type UrlParamsType = {
  offerId: string;
};

export const Offer = () => {
  const [offer, setOffer] = useState<OfferModel>({} as OfferModel);
  const [showCanvas, setShowCanvas] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { appState } = useContext(AppContext);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const history = useHistory();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const canSeePrices = useUserCanSeePrices();
  const validateUnitExists = useValidateUnitExists();
  const addProductsToInquiry = useAddProductsToProjectInquiry();
  const addProductsToOrder = useAddProductsToProjectOrder();

  const [canOrder, cannotOrderExplanation] = useUserCanOrder();

  const { t } = useTranslation('components');

  let { offerId } = useParams<UrlParamsType>();

  const downloadExcel = () => {
    handleDownloadOfferExcel(
      offer.rows,
      offer.sentByCompanyName,
      `${offer.sentByCompanyName}_OFFER_${offer.code}`
    );
  };

  const handleShowCanvas = (): void => {
    setShowCanvas((prevState) => !prevState);

    const sidePaneInfoRect = document.querySelector(
      '.supplier-side-pane-info'
    ) as HTMLElement;
    const offerBodyInfoRect = document.querySelector(
      '.offer-body-info-container'
    );
    const offerHeaderRect = document.querySelector('.card-header');

    if (offerBodyInfoRect !== null && offerHeaderRect !== null) {
      const offset =
        offerBodyInfoRect.getBoundingClientRect().height +
        offerHeaderRect.getBoundingClientRect().height;

      if (sidePaneInfoRect !== null) {
        sidePaneInfoRect.style.height = `${offset + 4}px`;
      }
    }
  };

  const handleOfferSort = (
    selectedColumn: TableColumn<OfferRowModel>,
    sortDirection: SortOrder
  ) => {
    BuyerApi.getOffer(
      currentUser.companyId,
      parseInt(offerId),
      userLocaleId,
      selectedColumn.sortField,
      sortDirection === 'desc'
    ).then((response) => {
      setOffer(response);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    const getOffer = async (): Promise<void> => {
      const response = await BuyerApi.getOffer(
        currentUser.companyId,
        parseInt(offerId),
        userLocaleId
      );

      setOffer(response);
      setIsLoading(false);
    };
    getOffer();
  }, [currentUser.companyId, offerId, userLocaleId]);

  const columns: TableColumn<OfferRowModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      width: '60px',
      sortable: false,
      sortField: 'RowNo',
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode,
      cell: (row) =>
        row.isReplaced ? (
          <div className='d-flex flex-column align-items-start justify-content-end'>
            <span>{row.replacementCode}</span>
            {row.productId !== null ? (
              <Link
                to={`/buyer/product-details/${row.productCode}`}
                className='btn-link-bold'
              >
                <small>
                  <del>{row.productCode}</del>
                </small>{' '}
              </Link>
            ) : (
              <small>
                <del>{row.productCode}</del>
              </small>
            )}
          </div>
        ) : row.productId === null || row.isNew ? (
          <span>{row.isReplaced ? row.replacementCode : row.productCode}</span>
        ) : (
          <Link
            to={`/buyer/product-details/${row.productCode}`}
            className='btn-link-bold'
          >
            {row.productCode}
          </Link>
        ),
      sortable: false,
      sortField: 'ProductCode',
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name,
      cell: (row) =>
        row.isReplaced ? (
          <div className='d-flex flex-column align-items-start justify-content-end'>
            <span>{row.replacementName}</span>

            <small>
              <del>{row.name}</del>
            </small>
          </div>
        ) : row.isNew ? (
          <span>{row.name}</span>
        ) : (
          <span>{row.name}</span>
        ),
      sortable: false,
      sortField: 'Name',
      grow: 6,
    },
    {
      name: t('documents.lbl_ExpectedDelivery'),
      selector: (row) => row.expectedDelivery ?? 'expectedDelivery',
      cell: (row) => {
        const firstChars = row.expectedDelivery?.split(' ')[0];

        return (
          <span className='wrap-ellipsis'>
            {row.expectedDelivery && firstChars
              ? isNaN(parseInt(firstChars))
                ? t('common.lbl_Instock')
                : `${parseInt(firstChars)} ${
                    parseInt(firstChars) === 1
                      ? t('documents.lbl_Week')
                      : t('documents.lbl_Weeks')
                  }`
              : '-'}
          </span>
        );
      },
    },
    {
      name: t('documents.lbl_Qty'),
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          <span>
            {row.quantity} <small>{validateUnitExists(row.unit)}</small>
          </span>
        </>
      ),
      right: true,
      sortable: false,
      sortField: 'Quantity',
    },
    {
      name: t('documents.lbl_PriceUnit'),
      selector: (row) => row.priceWithDiscount,
      cell: (row) =>
        canSeePrices(
          <span>
            {formatMonetaryValue(
              offer.currencyCode,
              row.priceWithDiscount,
              appState,
              row.decimalPlaces
            )}
            {row.unit && ' / '}
            <small>{validateUnitExists(row.unit)}</small>
          </span>
        ),
      right: true,
      sortable: false,
      sortField: 'Price',
    },

    {
      name: t('projectSettings.lbl_TotalPrice'),
      selector: (row) => row.total,
      cell: (row) =>
        row.total
          ? canSeePrices(
              formatMonetaryValue(
                offer.currencyCode,
                truncateDecimals(row.total, row.decimalPlaces),
                appState
              )
            )
          : '-',
      right: true,
      sortable: false,
      sortField: 'TotalPrice',
    },
  ];

  const getExternalContacts = async (sellerCompanyId: number) => {
    const response = await BuyerApi.getExternalSellerContacts(
      currentUser.companyId,
      sellerCompanyId
    );

    return response;
  };

  const handleOrder = async (): Promise<void> => {
    let order: OrderCreateForSessionModel = {} as OrderCreateForSessionModel;

    order.comment = offer.comment;
    order.deliveryAddressId = 0;
    order.project = {
      id: offer.projectId,
      name: offer.projectName,
    } as ProjectModel;
    order.requestedDeliveryTime = null;
    order.paymentTerm = offer.paymentTerm;
    order.deliveryTerm = offer.deliveryTerm;

    /* Map project rows to order rows */
    const data = offer.rows;

    let orderRows: OrderRowModel[] = [];

    data.forEach((row: OfferRowModel) => {
      const orderRow: OrderRowModel = {
        id: row.id,
        productId: row.productId,
        rowNo: row.rowNo,
        productCode: row.isReplaced ? row.replacementCode : row.productCode,
        name: row.isReplaced ? row.replacementName : row.name,
        quantity: row.quantity,
        unit: row.unit,
        price: row.price,
        priceWithDiscount: row.priceWithDiscount,
        discount: 0,
        total: row.total,
        requestedDeliveryTime: '',
        deliveryDate: '',
        deliveredCount: 0,
        invoicedCount: 0,
        decimalPlaces: row.decimalPlaces,
        isReplaced: row.isReplaced,
        initialCode: row.isReplaced ? row.productCode : null,
        initialName: row.isReplaced ? row.name : null,
      };
      orderRows.push(orderRow);
    });

    order.totalSum = offer.total;
    order.currencyCode = offer.currencyCode;

    order.isExternalSeller =
      appState.buyerCurrentSuppliers?.find(
        (seller) => seller.value === offer.sellerCompanyId
      )?.isExternalSeller ?? false;

    order.sentId = null;

    order.rows = orderRows;

    order.sellerCompanyId = offer.sellerCompanyId;
    order.sellerCompanyName = offer.sentByCompanyName;

    /* If external seller, set contact id as well */

    if (order.isExternalSeller) {
      const externalSellerContacts = await getExternalContacts(
        order.sellerCompanyId
      );

      const externalContactId = externalSellerContacts.find(
        (contact) =>
          `${contact.firstName} ${contact.lastName}` === offer.sentToContactName
      )?.id;

      order.externalSellerContactId = externalContactId ?? null;
    } else order.externalSellerContactId = null;

    sessionStorage.removeItem('orders_unsent');

    sessionStorage.setItem('orders_unsent', JSON.stringify([order]));

    history.push(
      `/buyer/order-unsent?offerId=${offerId}&offerCode=${offer.code}&dealId=${offer.dealId}`
    );
  };

  const handleProjectInquireProducts = async () => {
    setIsLoading(true);
    const cleanedProducts: any[] = [];

    offer.rows.forEach((row) =>
      cleanedProducts.push(
        row.productId !== null
          ? [row.productCode ?? '*EMPTY_CODE*', row.quantity]
          : [row.productCode ?? '*EMPTY_CODE*', row.quantity, row.name]
      )
    );

    const cleanedProductsText: ProjectImportProductsModel = {
      productsText: cleanedProducts.join('\n'),
      saveUnknownProducts: true,
    };

    await addProductsToInquiry('Document', {
      groupedProducts: null,
      importProductsText: cleanedProductsText,
    });

    setIsLoading(false);
  };

  const handleProjectOrderProducts = async () => {
    setIsLoading(true);
    const cleanedProducts: any[] = [];

    offer.rows.forEach((row) =>
      cleanedProducts.push(
        row.productId !== null
          ? [row.productCode ?? '*EMPTY_CODE*', row.quantity]
          : [row.productCode ?? '*EMPTY_CODE*', row.quantity, row.name]
      )
    );

    const cleanedProductsText: ProjectImportProductsModel = {
      productsText: cleanedProducts.join('\n'),
      saveUnknownProducts: true,
    };

    await addProductsToOrder('Document', {
      groupedProducts: null,
      importProductsText: cleanedProductsText,
    });

    setIsLoading(false);
  };

  const isToExternalSeller = appState.buyerCurrentSuppliers?.filter(
    (supplier) => supplier.value === offer.sellerCompanyId
  )[0]?.isExternalSeller;

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div
        style={{ overflowX: showCanvas ? 'auto' : 'clip' }}
        className='container-fluid p-2 d-flex flex-row mb-4'
        id='buyer-offer-card'
      >
        <Card className='flex-grow-1'>
          <Card.Header>
            <Row>
              <Col>
                <h3>
                  <Link
                    to={`/buyer/${
                      isToExternalSeller
                        ? 'external-supplier-info'
                        : 'supplier-info'
                    }?id=${offer.sellerCompanyId}`}
                    className='btn-link-bold'
                  >
                    {offer.sentByCompanyShortName ?? offer.sentByCompanyName}
                  </Link>
                </h3>
                <h4>{offer.sentByContactName}</h4>
              </Col>
              <Col className='text-center' lg={4}>
                <h2>
                  {t('documents.hd_Offer')} {offer.code}{' '}
                  <small>
                    {offer.isExternal && `(${t('documents.hd_OfferOnBehalf')})`}
                  </small>
                </h2>
              </Col>
              <Col
                className='text-center d-flex justify-content-lg-end justify-content-sm-center'
                lg={4}
              >
                <h3 className='d-flex flex-nowrap align-items-center text-nowrap'>
                  {offer.status === 'NoReply' && (
                    <>
                      {t('documents.lbl_NoReply')}{' '}
                      <OfferNoReplyBuyer className='icon-ez-yellow ms-2' />
                    </>
                  )}
                  {offer.status === 'Expired' && (
                    <>
                      {t('documents.lbl_Expired')}{' '}
                      <OfferExpired className='icon-ez-red ms-2' />
                    </>
                  )}
                  {offer.status === 'OrderMade' && (
                    <>
                      {t('documents.lbl_OrderMade')}{' '}
                      <OfferOrderMade className='icon-ez ms-2' />
                    </>
                  )}
                </h3>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row className='offer-body-info-container'>
              <Col>
                <Row className='flex-nowrap'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_Date')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(offer.documentDate).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='text-nowrap document-header-left-col'>
                    {t('documents.lbl_UpdatedOn')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(offer.lastUpdated).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentBy')}:
                  </Col>
                  <Col>
                    <Link
                      className='btn-link-bold'
                      to={`/buyer/supplier-info?id=${offer.sellerCompanyId}`}
                    >
                      {offer.sentByCompanyShortName ?? offer.sentByCompanyName}
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentTo')}:
                  </Col>
                  <Col>
                    <strong>{offer.sentToCompanyName}</strong>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row className='flex-nowrap'>
                  <Col className='text-end pe-0 text-nowrap'>
                    <Row className='mb-0'>
                      <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                    </Row>
                    {offer.deliveryAddressLine2 !== null &&
                      offer.deliveryAddressLine2 !== '' && <br />}

                    {offer.deliveryAddressLine3 !== null &&
                      offer.deliveryAddressLine3 !== '' && <br />}

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_PaymentTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_DeliveryTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_ValidUntil')}:</Col>
                    </Row>
                  </Col>

                  <Col
                    className='pe-3 d-flex flex-column'
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <Row className='text-start document-header-right-col'>
                      <strong>{offer.projectName}</strong>
                    </Row>

                    <Row className='text-start document-header-right-col'>
                      <Col className='d-flex flex-column text-nowrap'>
                        {offer.deliveryAddressLine1 !== null ? (
                          <strong>{offer.deliveryAddressLine1}</strong>
                        ) : (
                          <br />
                        )}
                        <strong>
                          {offer.deliveryAddressLine2 !== null ||
                          offer.deliveryAddressLine2 !== '' ? (
                            offer.deliveryAddressLine2
                          ) : (
                            <br />
                          )}
                        </strong>
                        <strong>
                          {offer.deliveryAddressLine3 !== null ||
                          offer.deliveryAddressLine3 !== '' ? (
                            offer.deliveryAddressLine3
                          ) : (
                            <br />
                          )}
                        </strong>
                      </Col>
                    </Row>

                    <Row className='text-start document-header-right-col'>
                      {offer.paymentTerm !== null &&
                      offer.paymentTerm !== '' ? (
                        <strong>{offer.paymentTerm}</strong>
                      ) : (
                        <br />
                      )}
                    </Row>

                    <Row className='text-start document-header-right-col'>
                      {offer.deliveryTerm !== null ? (
                        <strong>{offer.deliveryTerm}</strong>
                      ) : (
                        <br />
                      )}
                    </Row>

                    <Row className='text-start document-header-right-col'>
                      {offer.validUntil !== null ? (
                        <strong>
                          {dayjs(offer.validUntil).format('DD-MM-YYYY')}
                        </strong>
                      ) : (
                        <br />
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='my-2'>
              <Col></Col>
              <Col>
                <ButtonGroup className='d-flex float-end'>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>{t('documents.btn_ExportToExcel')}</Tooltip>
                    }
                  >
                    <Button
                      type={'button'}
                      onClick={downloadExcel}
                      variant='btn btn-eleczap'
                    >
                      <SiMicrosoftexcel size={20} />
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        {t('documents.btn_AddProductsToProjectInquiry')}
                      </Tooltip>
                    }
                  >
                    <Button
                      disabled={isLoading}
                      onClick={handleProjectInquireProducts}
                      className='btn btn-eleczap d-flex align-items-center  px-1'
                    >
                      <Clipboard2PlusFill size={20} className='me-1' />{' '}
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        {t('documents.btn_AddProductsToProjectOrder')}
                      </Tooltip>
                    }
                  >
                    <Button
                      disabled={isLoading}
                      onClick={handleProjectOrderProducts}
                      className='btn btn-eleczap d-flex align-items-center px-1'
                    >
                      <CartPlusFill size={20} className='me-1' />{' '}
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </Col>
            </Row>

            <Row className='my-5'>
              <DataTable
                noDataComponent={
                  <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
                }
                columns={columns}
                data={offer.rows}
                onSort={handleOfferSort}
                sortServer={true}
              />
            </Row>

            <Row className='justify-content-between'>
              <Col lg={6}>
                <Form.Group className='mb-3' controlId='comments'>
                  <Form.Label>{t('common.lbl_Comments')}</Form.Label>
                  <Form.Control
                    readOnly
                    as='textarea'
                    rows={4}
                    value={offer.comment}
                  />
                </Form.Group>
              </Col>

              <Col lg={showCanvas ? 4 : 3}>
                <Row className='mt-3'>
                  <Col>
                    <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                  </Col>
                  <Col>
                    <h3 className='text-end'>
                      {canSeePrices(
                        useFormatMonetaryValue(offer.currencyCode, offer.total)
                      )}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-end'>
                    {t('common.inf_TotalPriceSubtext')}
                  </Col>
                </Row>
              </Col>
            </Row>
            {!offer.orderMadeFromAnotherOffer && offer.status === 'NoReply' && (
              <Row>
                <Col></Col>
                <Col>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='orderButtonTooltip' hidden={canOrder}>
                        {cannotOrderExplanation}
                      </Tooltip>
                    }
                  >
                    <span className='float-end'>
                      <Button
                        disabled={!canOrder}
                        onClick={handleOrder}
                        className='btn btn-eleczap float-end'
                      >
                        <CartFill /> {t('documents.btn_Order')}
                      </Button>
                    </span>
                  </OverlayTrigger>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>

        <SupplierMoreInfoPane
          isExternalSeller={
            appState.buyerCurrentSuppliers?.find(
              (seller) => seller.value === offer.sellerCompanyId
            )?.isExternalSeller ?? false
          }
          showCanvas={showCanvas}
          handleShowCanvas={handleShowCanvas}
          supplierName={offer.sentToCompanyName}
          supplierId={offer.sellerCompanyId}
          offerRows={offer.rows}
        />
      </div>
    </>
  );
};

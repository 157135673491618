import { HitHorizonsApiClient } from 'api/HitHorizonsApiClient';
import axios from 'axios';
import {
  HitHorizoneGetSuggestionsResultModel,
  HitHorizonsGetCompanyBasicDetailsModel,
} from 'models/hitHorizonsModels';

export const HitHorizonsApi = {
  testProxy: (): Promise<HitHorizoneGetSuggestionsResultModel> =>
    axios.get(`/quotes`),

  getSuggestions: (
    APIKey: string,
    query: string,
    maxResults: number
  ): Promise<HitHorizoneGetSuggestionsResultModel> =>
    HitHorizonsApiClient(APIKey).get(
      `/Suggestions?Query=${query}&MaxResults=${maxResults}`
    ),

  getCompanyBasicDetails: (
    APIKey: string,
    horizonId: string
  ): Promise<HitHorizonsGetCompanyBasicDetailsModel> =>
    HitHorizonsApiClient(APIKey).get(`/DetailBasic?HitHorizonsId=${horizonId}`),
};

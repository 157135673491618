import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type UserProfileImageUploadProps = {
  profileImage: string;
  initials: string;
  handleImageUpload: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const OnboardingProfileImageUpload = ({
  profileImage,
  initials,
  handleImageUpload,
}: UserProfileImageUploadProps) => {
  const { t } = useTranslation('components');

  return (
    <>
      <AvatarWithFallback
        imageUrl={profileImage}
        initials={initials}
        size='x-large'
      />

      <Form.Group controlId='formFileSm' className='mt-3 ms-3'>
        <input
          type='file'
          id='profileImage'
          accept='.png, .jpeg, .jpg'
          onChange={handleImageUpload}
          className='inputfile'
        />
        <label htmlFor='profileImage' className='btn btn-eleczap'>
          {t('profileImageUpload.lbl_Upload')}
        </label>
      </Form.Group>
    </>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import {
  ProjectInquiryBulkUpdateRowModel,
  ProjectInquiryCreateRowModel,
  ProjectInquiryModel,
  ProjectInquiryUpdateRowModel,
} from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { toast } from 'react-toastify';

export const getProjectInquiry = async (
  projectId: number,
  projectInquiryId: number,
  companyId: number,
  setProjectDetails: (values: ProjectInquiryModel) => void
) => {
  const response = await BuyerApi.projectInquiry.getProjectInquiry(
    companyId,
    projectId,
    projectInquiryId
  );

  setProjectDetails(response);
};

export const updateProjectInquiryRows = async (
  projectRows: ProjectInquiryUpdateRowModel,
  companyId: number,
  projectId: number,
  projectInquiryId: number,
  rowId: number
) => {
  toast.dismiss();
  await BuyerApi.projectInquiry.updateProjectInquiryRow(
    companyId,
    projectId,
    projectInquiryId,
    projectRows,
    rowId
  );
};

export const updateBulkProjectInquiryRows = async (
  projectRows: ProjectInquiryBulkUpdateRowModel,
  companyId: number,
  projectId: number,
  projectInquiryId: number
) => {
  toast.dismiss();
  await BuyerApi.projectInquiry.updateBulkProjectInquiryRows(
    companyId,
    projectId,
    projectInquiryId,
    projectRows
  );
};

export const addProductsToProjectInquiry = async (
  projectRows: ProjectInquiryCreateRowModel[],
  companyId: number,
  projectId: number,
  projectInquiryId: number,
  t?: any
) => {
  toast.dismiss();
  await BuyerApi.projectInquiry.createProjectInquiryRows(
    companyId,
    projectId,
    projectInquiryId,
    projectRows
  );
};

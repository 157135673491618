import { useQueryClient } from '@tanstack/react-query';
import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useSetCurrentProject } from 'hooks/useSetCurrentProject';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const defaultProjectName = `Project-${dayjs().format('DDMMYYHHmmss')}`;

/* Formik init */
export const useFormikHookForNewProjectModal = (
  currentUser: LoginResponseUserModel,
  setShow: (values: boolean) => void,
  t: any,
  handleClosed: () => void,
  handleSubmitted?: (newProjectId: number) => void
): any => {
  const history = useHistory();
  const setCurrentProject = useSetCurrentProject();
  const setUserProfile = useSetUserProfile();

  const queryClient = useQueryClient();

  const { appState } = useContext(AppContext);

  const handleSubmit = async (values: {
    name: string;
    sellerCompanyId: number;
  }): Promise<void> => {
    toast.dismiss();
    const newId = await BuyerApi.createProject(currentUser.companyId, values);

    if (handleSubmitted) {
      handleSubmitted(newId);
    }
    await setCurrentProject(newId, values.sellerCompanyId);
    queryClient.invalidateQueries({ queryKey: ['deals'] });
    toast.info(t('projectSettings.inf_ProjectCreated'));
    await setUserProfile(null);

    history.push('/buyer/deals');
    window.location.reload();
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('common.err_ValueRequired'))
      .max(50, t('common.err_ValueMax50')),
  });

  const initialValues: { name: string; sellerCompanyId: number } = {
    name: defaultProjectName,
    sellerCompanyId: 0,
  };

  const formik = useFormik<{ name: string; sellerCompanyId: number }>({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values: { name: string; sellerCompanyId: number }) => {
      const createNewProject: { name: string; sellerCompanyId: number } = {
        name: values.name,
        sellerCompanyId: appState.buyerCurrentSupplierId || 0,
      };
      await handleSubmit(createNewProject);
      setShow(false);
      handleClosed();
      formik.resetForm();
      formik.setSubmitting(false);
    },
  });

  return formik;
};

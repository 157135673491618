import { useEffect } from 'react';

export const useListenElementResize = (
  className: string,
  heightWidthFunctions: {
    setWidthOfElement?: (value: number) => void;
    setHeightOfElement?: (value: number) => void;
  }
) => {
  /* Listen to resize of window */
  useEffect(() => {
    const containerNode = document.querySelector(className);

    const { setWidthOfElement, setHeightOfElement } = heightWidthFunctions;

    containerNode?.clientWidth &&
      setWidthOfElement &&
      setWidthOfElement(containerNode?.clientWidth);

    containerNode?.clientHeight &&
      setHeightOfElement &&
      setHeightOfElement(containerNode?.clientHeight);

    const handleResizeWindow = (event: any) => {
      containerNode?.clientWidth &&
        setWidthOfElement &&
        setWidthOfElement(containerNode?.clientWidth);

      containerNode?.clientWidth &&
        setHeightOfElement &&
        setHeightOfElement(containerNode?.clientHeight);
    };
    window.addEventListener('resize', handleResizeWindow);

    return () => window.removeEventListener('resize', handleResizeWindow);
  }, [className, heightWidthFunctions]);
};

import { XCircleFill } from 'react-bootstrap-icons';
import { Flip, ToastPosition, toast } from 'react-toastify';

export const ErrorToast = (
  title: string,
  closeOnClick: boolean = false,
  position: ToastPosition = 'bottom-right'
) => {
  return toast(title, {
    position,
    autoClose: !closeOnClick && 5000,
    hideProgressBar: false,
    closeOnClick,
    pauseOnHover: true,
    draggable: false,
    theme: 'colored',
    transition: Flip,
    icon: <XCircleFill size={24} className='icon-ez-red' />,
  });
};

import { BuyerApi } from 'api/BuyerApi';
import { useUpdateBuyerCurrentProductSupplierInLocalStorage } from 'hooks/useUpdateBuyerCurrentProductSupplierInLocalStorage';
import { BuyerSupplierButtonForSelectionOption } from 'layout/BuyerSupplierButtonForSelectionOption';

import { AppContext } from 'contexts/user/AppContext';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useCallback, useContext } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

type PropTypes = {
  allSuppliersOptions: BuyerCurrentSuppliers[];
  setAllSuppliersOption: (values: BuyerCurrentSuppliers[]) => void;
  supplier: BuyerCurrentSuppliers;
  // allSuppliers: SellersCompaniesAndUsersDataModel[];
  isFavorite?: boolean;
  getAllSuppliersInfo?: () => Promise<void>;
  updateLocalStorageAndAppContextValue: (
    suppliers: BuyerCurrentSuppliers[]
  ) => void;
  allOptionsAreSelected: boolean;
  setAllOptionsAreSelected: (value: boolean) => void;
  isEditing?: boolean;
  setIsEditing?: (value: boolean) => void;
  index?: number;
};

export const BuyerSupplierSelectionOption = ({
  allSuppliersOptions,
  setAllSuppliersOption,
  supplier,
  // allSuppliers,
  isFavorite,
  getAllSuppliersInfo,
  updateLocalStorageAndAppContextValue,
  allOptionsAreSelected,
  setAllOptionsAreSelected,
  isEditing,
  setIsEditing,
  index,
}: PropTypes) => {
  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const supplierId = supplier.value;
  const supplierName = supplier.name;
  const { appState } = useContext(AppContext);

  const isSelected = supplier.isSelected;

  const updateBuyerCurrentProductSupplierInLocalStorage =
    useUpdateBuyerCurrentProductSupplierInLocalStorage();

  const handleClick = useCallback(async () => {
    let clonedSelectedSuppliers = [...allSuppliersOptions];
    let indexOfId = clonedSelectedSuppliers.findIndex(
      (supplierItem) => supplierItem.value === supplierId
    );

    if (indexOfId === -1) {
      indexOfId = clonedSelectedSuppliers.findIndex((clonedSelectedSupplier) =>
        clonedSelectedSupplier.subSellers?.some(
          (subSeller) => subSeller.value === supplierId
        )
      );
    }

    const indexOfSubSellerId = clonedSelectedSuppliers[
      indexOfId
    ]?.subSellers?.findIndex((subSeller) => subSeller.value === supplierId);

    /* If options other tahn subSellers are clicked */
    if (indexOfSubSellerId === -1 || indexOfSubSellerId === undefined) {
      /* If AllSupplier is clicked */
      if (supplierId === 0) {
        if (!allOptionsAreSelected) {
          clonedSelectedSuppliers.forEach((supplier) => {
            supplier.isSelected = true;

            supplier.subSellers?.forEach(
              (subSeller) => (subSeller.isSelected = true)
            );
          });

          setAllOptionsAreSelected(true);
        } else {
          setAllOptionsAreSelected(false);
          /* Find supplier with favorite seller */
          const favSellerIndex = allSuppliersOptions.findIndex(
            (supplier) => supplier.isFavourite && supplier.isActive
          );

          /* Set fav supplier */
          if (favSellerIndex !== -1) {
            clonedSelectedSuppliers.forEach((supplier) => {
              supplier.isSelected = false;
              supplier.subSellers?.forEach(
                (subSeller) => (subSeller.isSelected = false)
              );
            });

            clonedSelectedSuppliers[favSellerIndex].isSelected = true;

            updateBuyerCurrentProductSupplierInLocalStorage(
              clonedSelectedSuppliers[favSellerIndex]
            );
          } else toast.error(t('buyerHeader.err_NoFavoriteSellerSelected'));
          /* Else show toast that no favorite seller selected */
        }
      }

      /* If Other regular options are clicked */
      if (supplierId !== 0 && !supplier.isMarketplace) {
        if (!isSelected) {
          clonedSelectedSuppliers[indexOfId].isSelected = true;
        } else {
          clonedSelectedSuppliers[indexOfId].isSelected = false;

          /* If clicked supplier exists in localStorage, remove it as stale value */
          const rawSavedBuyerCurrentProductsSupplier = localStorage.getItem(
            `_buyerCurrentProductsSupplier_${currentUser.id}`
          );

          if (rawSavedBuyerCurrentProductsSupplier !== null) {
            const indexOfFirstSelectedSupplier =
              clonedSelectedSuppliers.findIndex(
                (supplier) => supplier.isSelected
              );

            const savedBuyerCurrentProductsSupplier = JSON.parse(
              rawSavedBuyerCurrentProductsSupplier
            );

            if (savedBuyerCurrentProductsSupplier.value === supplierId) {
              updateBuyerCurrentProductSupplierInLocalStorage(
                clonedSelectedSuppliers[indexOfFirstSelectedSupplier]
              );
            }
          }

          setAllOptionsAreSelected(false);
        }
      }

      /* If Marketplace options are clicked */
      if (supplierId !== 0 && supplier.isMarketplace) {
        if (!isSelected) {
          clonedSelectedSuppliers[indexOfId].isSelected = true;

          clonedSelectedSuppliers[indexOfId].subSellers?.forEach(
            (subSeller) => (subSeller.isSelected = true)
          );
        } else {
          clonedSelectedSuppliers[indexOfId].isSelected = false;

          clonedSelectedSuppliers[indexOfId].subSellers?.forEach(
            (subSeller) => (subSeller.isSelected = false)
          );

          /* If clicked supplier exists in localStorage, remove it as stale value */
          const rawSavedBuyerCurrentProductsSupplier = localStorage.getItem(
            `_buyerCurrentProductsSupplier_${currentUser.id}`
          );

          if (rawSavedBuyerCurrentProductsSupplier !== null) {
            const indexOfFirstSelectedSupplier =
              clonedSelectedSuppliers.findIndex(
                (supplier) => supplier.isSelected
              );

            const savedBuyerCurrentProductsSupplier = JSON.parse(
              rawSavedBuyerCurrentProductsSupplier
            );

            if (savedBuyerCurrentProductsSupplier.value === supplierId) {
              updateBuyerCurrentProductSupplierInLocalStorage(
                clonedSelectedSuppliers[indexOfFirstSelectedSupplier]
              );
            }
          }

          setAllOptionsAreSelected(false);
        }
      }
    }

    /* If subSellers are clicked */
    if (indexOfSubSellerId !== -1 && indexOfSubSellerId !== undefined) {
      const targetSubSellers = clonedSelectedSuppliers[indexOfId].subSellers;

      if (targetSubSellers) {
        if (!targetSubSellers[indexOfSubSellerId].isSelected) {
          targetSubSellers[indexOfSubSellerId].isSelected = true;

          clonedSelectedSuppliers[indexOfId].subSellers = targetSubSellers;

          if (
            targetSubSellers.filter((target) => target.isSelected).length > 0
          ) {
            clonedSelectedSuppliers[indexOfId].isSelected = true;
          }
        } else {
          targetSubSellers[indexOfSubSellerId].isSelected = false;

          clonedSelectedSuppliers[indexOfId].subSellers = targetSubSellers;

          /* If clicked supplier exists in localStorage, remove it as stale value */
          const rawSavedBuyerCurrentProductsSupplier = localStorage.getItem(
            `_buyerCurrentProductsSupplier_${currentUser.id}`
          );

          if (rawSavedBuyerCurrentProductsSupplier !== null) {
            const indexOfFirstSelectedSupplier =
              clonedSelectedSuppliers.findIndex((clonedSelectedSupplier) =>
                clonedSelectedSupplier.subSellers?.some(
                  (subSeller) => subSeller.isSelected
                )
              );

            const savedBuyerCurrentProductsSupplier = JSON.parse(
              rawSavedBuyerCurrentProductsSupplier
            );

            if (savedBuyerCurrentProductsSupplier.value === supplierId) {
              updateBuyerCurrentProductSupplierInLocalStorage(
                clonedSelectedSuppliers[indexOfFirstSelectedSupplier]
              );
            }
          }

          setAllOptionsAreSelected(false);
          if (
            targetSubSellers.filter((target) => target.isSelected).length === 0
          )
            clonedSelectedSuppliers[indexOfId].isSelected = false;
        }
      }
    }

    setAllSuppliersOption(clonedSelectedSuppliers);
    updateLocalStorageAndAppContextValue(clonedSelectedSuppliers);
  }, [
    allOptionsAreSelected,
    allSuppliersOptions,
    currentUser.id,
    isSelected,
    setAllOptionsAreSelected,
    setAllSuppliersOption,
    supplier.isMarketplace,
    supplierId,
    t,
    updateBuyerCurrentProductSupplierInLocalStorage,
    updateLocalStorageAndAppContextValue,
  ]);

  const handleUpdateFavoriteSeller = async () => {
    await BuyerApi.updateFavoriteSeller(currentUser.companyId, currentUser.id, {
      id: supplier.value,
    });

    getAllSuppliersInfo !== undefined && (await getAllSuppliersInfo());

    toast.success(t('suppliersPage.inf_FavoriteSupplierChanged'));
    setIsEditing && setIsEditing(false);
  };

  /* Check if last supplier left selected, then disallow to unselect that */
  let activeSuppliers = [];

  for (let supplier of allSuppliersOptions) {
    if (supplier.isMarketplace) {
      if (supplier.subSellers) {
        for (let subSeller of supplier.subSellers) {
          if (subSeller.isSelected) {
            activeSuppliers.push(subSeller);
          }
        }
      }
    } else {
      if (supplier.isSelected) {
        activeSuppliers.push(supplier);
      }
    }
  }

  const isLastSupplierSelectedLeft = activeSuppliers.length === 1;

  // const isLastSupplierSelected = isLastSupplierSelectedLeft;

  const isLastSupplierSelected =
    isLastSupplierSelectedLeft && activeSuppliers[0].value === supplier.value;

  const ifOptionIsForAllSuppliers = supplier.value === 0;

  return (
    <>
      <BuyerSupplierButtonForSelectionOption
        supplier={supplier}
        handleClick={handleClick}
        allOptionsAreSelected={allOptionsAreSelected}
        isSelected={isSelected}
        isEditing={isEditing}
        isFavorite={isFavorite}
        ifOptionIsForAllSuppliers={ifOptionIsForAllSuppliers}
        isLastSupplierSelected={isLastSupplierSelected}
        handleUpdateFavoriteSeller={handleUpdateFavoriteSeller}
      />
      {isLastSupplierSelected && (
        <ReactTooltip
          effect='solid'
          className='border'
          type='light'
          place='bottom'
          id={`supplier-selector-tooltip-${supplier.value}`}
        >
          <span>{t('projectSettings.lbl_CannotUnselectOnlySupplier')}</span>{' '}
          {/* //TODO: Translate string */}
        </ReactTooltip>
      )}
    </>
  );
};

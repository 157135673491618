import { IdentityApi } from 'api/IdentityApi';
import { useFormik } from 'formik';
import { CustomerOnboardingContactDetailsModel } from 'models/shared/customerOnboarding/customerOnboardingContactDetailsModel';
import { CustomerOnboardingUpdateContactDetailsModel } from 'models/shared/customerOnboarding/customerOnboardingUpdateContactDetailsModel';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* Formik init */
export const useFormikHookForOnboardingDetails = (
  setCustomerDetails: (values: CustomerOnboardingContactDetailsModel) => void,
  customer: CustomerOnboardingContactDetailsModel,
  invitationToken: string,
  nextStep: () => void,
  t: any
): any => {
  const handleSubmit = async (
    values: CustomerOnboardingUpdateContactDetailsModel
  ): Promise<void> => {
    toast.dismiss();
    await IdentityApi.updateCustomerContactDetails(invitationToken, values);
    toast.info(t('account.inf_UserSettingsSaved'));
    nextStep();
  };

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t('userSettings.err_FirstNameRequired'))
      .max(50, t('userSettings.err_FirstNameMax')),
    lastName: yup
      .string()
      .required(t('userSettings.err_LastNameRequired'))
      .max(50, t('userSettings.err_LastNameMax')),
    position: yup
      .string()
      .max(50, t('userSettings.err_PositionMax'))
      .nullable(),
    linkedInProfile: yup
      .string()
      .nullable()
      .url(t('userSettings.err_LinkedInInvalid'))
      .max(150, t('userSettings.err_LinkedInMax')),
    phone: yup.string().max(50, t('userSettings.err_PhoneMax')).nullable(),
  });

  const formik = useFormik<CustomerOnboardingContactDetailsModel>({
    initialValues: { ...customer },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedCustomer: CustomerOnboardingUpdateContactDetailsModel = {
        firstName: values.firstName,
        lastName: values.lastName,
        position: values.position,
        linkedInProfile: values.linkedInProfile,
        phone: values.phone,
      };
      await handleSubmit(updatedCustomer);
      const newResponse = await IdentityApi.getCustomerContactDetails(
        invitationToken
      );

      setCustomerDetails(newResponse);

      nextStep();
      formik.setSubmitting(false);
    },
  });

  return formik;
};

/* Get customer onboarding details */
export const useGetCustomerOnboardingContactDetails = (
  setCustomer: (values: CustomerOnboardingContactDetailsModel) => void,
  invitationToken: string
) => {
  useEffect(() => {
    const getWelcomeDetails = async () => {
      const response = await IdentityApi.getCustomerContactDetails(
        invitationToken
      );

      setCustomer(response);
    };

    invitationToken !== '' && getWelcomeDetails();
  }, [invitationToken, setCustomer]);
};

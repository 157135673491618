import { BuyerApi } from 'api/BuyerApi';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { useListenElementResize } from 'hooks/useListenElementResize';
import { ProductOptionGroupedType } from 'models/buyer/project/ProjectModels';
import { ProjectInquiryCreateRowModel } from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { addProductsToProjectInquiry } from 'pages/buyer/ProjectInquiry/utilsProjectInquiry';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import {
  GroupBase,
  SingleValue,
  SingleValueProps,
  components,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import './ProjectInquiryAddProductForm.css';

type PropTypes = {
  projectId: number;
  projectInquiryId: number | null;
  getDataSource: () => Promise<void>;
};

const customStyles = {
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: '280px',
    border: '1px solid lightgray',
    marginLeft: '1rem',
    borderRadius: '5px',
  }),
  menu: () => ({
    marginTop: 2,
    width: 400,
    border: '1px solid lightgray',
    marginLeft: '1rem',
    borderRadius: '5px',
  }),
};

export const ProjectInquiryAddProductForm = ({
  projectId,
  projectInquiryId,
  getDataSource,
}: PropTypes) => {
  const [query, setQuery] = useState<string>('');
  const [qty, setQty] = useState<number | ''>('');
  const [product, setProduct] = useState<SingleValue<ProductOptionGroupedType>>(
    {} as ProductOptionGroupedType
  );
  const [isProductCodeNew, setIsProductCodeNew] = useState<boolean>(true);
  const [selectedSupplierFromProductTree, setSelectedSupplierFromProductTree] =
    useState<BuyerCurrentSuppliers>({} as BuyerCurrentSuppliers);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchRequestIsPending, setSearchRequestIsPending] =
    useState<boolean>(false);
  const [widthOfGridContainer, setWidthOfGridContainer] = useState<number>(0);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  useListenElementResize('.project-product-grid-container', {
    setWidthOfElement: setWidthOfGridContainer,
  });

  const userLocaleId = appState.userProfile?.localization?.id || 1;
  const selectedBuyerSupplierId = appState.buyerCurrentSupplierId;

  const { t } = useTranslation('components');

  const productTreeOptions: BuyerCurrentSuppliers[] | null =
    appState.buyerCurrentSuppliers;

  useEffect(() => {
    appState.buyerCurrentSuppliers &&
      setSelectedSupplierFromProductTree(appState.buyerCurrentSuppliers[0]);
  }, [appState.buyerCurrentSuppliers]);

  const loadOptions = async (
    input: string
  ): Promise<ProductOptionGroupedType[]> => {
    const response = await BuyerApi.getQuickProductsGrouped(
      currentUser.companyId,
      input,
      userLocaleId
    );

    const options = response.data.map((prod) => {
      return {
        value: prod.products.map((x) => {
          return { sellerId: x.sellerId, productId: x.id, productCode: x.code };
        }),
        label: `${prod.products[0].code} (${prod.products[0].name})`,
        name: prod.products[0].name,
      } as ProductOptionGroupedType;
    });

    options.unshift({
      value: [],
      label: `${t('projectSettings.lbl_Add')}: ${input}`,
      name: '',
    });

    setSearchRequestIsPending(false);

    return options;
  };

  const handleProductChange = async (
    newValue: SingleValue<ProductOptionGroupedType>
  ) => {
    setProduct(newValue);

    const codeIsNew =
      newValue?.value == null || newValue.value.length < 1
        ? true
        : newValue?.value[0].productId === 0;

    /* Check if free product */
    setIsProductCodeNew(codeIsNew);

    codeIsNew && handleAddNewCode();

    codeIsNew
      ? document.getElementById('project-add-product-name')?.focus()
      : document.getElementById('project-add-product-qty')?.focus();
  };

  const resetForm = () => {
    setSearchRequestIsPending(false);
    setIsProductCodeNew(true);
    setQty('');
    setProduct({
      value: null,
      label: '',
      name: '',
    });
  };

  const handleAddProduct = async (): Promise<void> => {
    setIsLoading(true);

    let newRows: ProjectInquiryCreateRowModel =
      {} as ProjectInquiryCreateRowModel;
    /* If product is existing */
    if (allowedToAddRow) {
      const pv =
        product !== null &&
        product.value !== undefined &&
        product.value !== null
          ? product.value[0]
          : null;
      const enabledSuppliers = appState.buyerCurrentSuppliers?.filter(
        (s) => !s.isArchived
      );

      const row: ProjectInquiryCreateRowModel = {
        productId:
          pv?.productId === undefined || pv?.productId === 0
            ? null
            : pv?.productId,
        sellerCompanyId:
          pv?.sellerId === undefined || pv?.sellerId === -1
            ? null
            : pv?.sellerId,
        productCode: pv?.productCode ?? null,
        productName: product.name,
        requiredQuantity: qty,
        productExists: !(pv?.productId === undefined || pv?.productId === 0),
      };

      newRows = row;

      projectInquiryId &&
        (await addProductsToProjectInquiry(
          [newRows],
          currentUser.companyId,
          projectId,
          projectInquiryId,
          t
        ));

      await getDataSource();
      resetForm();

      (
        document.getElementsByClassName(
          'select__single-value'
        )[0] as HTMLElement
      ).innerText = '';

      const selectPlaceholderNode = document.getElementsByClassName(
        'select__placeholder'
      )[0];

      if (selectPlaceholderNode !== undefined)
        (selectPlaceholderNode as HTMLElement).innerText = t(
          'projectSettings.plh_ProductCode'
        );
    }

    setIsLoading(false);
  };

  const handleKeyPress = async (event: any): Promise<void> => {
    if (event.key === 'Enter') {
      await handleAddProduct();
      document.getElementById('project-add-product-qty')?.blur();
    }
  };

  const formatOptionLabel = (option: ProductOptionGroupedType): JSX.Element => (
    <div
      className='d-flex flex-row justify-content-between wrap-ellipsis'
      key={
        option.value === null || option.value === undefined
          ? 'x'
          : option.value.join(',')
      }
    >
      <div>
        <span>{option.label}</span>
      </div>
    </div>
  );

  const SingleValue = ({
    children,
    ...props
  }: SingleValueProps<
    ProductOptionGroupedType,
    false,
    GroupBase<ProductOptionGroupedType>
  >) => (
    <components.SingleValue {...props}>
      {product && product.value && product.value.length > 0
        ? product.value[0].productCode
        : product?.label}
    </components.SingleValue>
  );

  const handleAddNewCode = () => {
    setIsProductCodeNew(true);
    setProduct({
      value: [{ productCode: query, productId: 0, sellerId: -1 }],
      label: query,
      name: '',
    });
    document.getElementById('project-add-product-name')?.focus();
  };

  const handleProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    product !== null && JSON.stringify(product) !== '{}'
      ? setProduct({
          value: [
            {
              productId:
                product.value === null ? 0 : product.value[0].productId,
              sellerId: selectedSupplierFromProductTree.value,
              productCode:
                product.value === null ? '' : product.value[0].productCode,
            },
          ],
          label: product.label,
          name: event.target.value,
        })
      : setProduct({
          value: [
            {
              productId: null,
              sellerId: -1,
              productCode: null,
            },
          ],
          label: null,
          name: event.target.value,
        });
  };

  const handleProductCodeKeyPress = (event: any) => {
    // if ((event.key === 'Tab' || event.key === 'Enter') && isProductCodeNew) {
    //   event.preventDefault();
    //   handleAddNewCode();
    // }
  };

  const handleInputChange = (newValue: string) => {
    setQuery(newValue);
    setSearchRequestIsPending(true);
  };

  const handleBlur = () => {
    if (searchRequestIsPending) {
      setProduct({
        value: [{ productId: 0, sellerId: -1, productCode: '' }],
        label: `${t('projectSettings.lbl_Add')}: ${query}`,
        name: '',
      });

      handleAddNewCode();
    }

    setSearchRequestIsPending(false);
  };

  const allowedToAddRow =
    product !== null &&
    JSON.stringify(product) !== '{}' &&
    (product.name !== '' || product.value !== null) &&
    qty !== '';

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Row
        id='project-view-add-product-form'
        className='mt-3'
        // style={{ width: widthOfGridContainer * 0.8 }}
      >
        <Col>
          <Row className='mx-3'>{t('projectSettings.lbl_AddProduct')}</Row>
          <Row>
            <Col style={{ maxWidth: '161px' }}>
              <InputGroup className='align-items-center'>
                <AsyncSelect
                  isMulti={false}
                  placeholder={t('projectSettings.plh_ProductCode')}
                  onFocus={resetForm}
                  onBlur={handleBlur}
                  components={{
                    DropdownIndicator: null,
                    SingleValue,
                    // MenuList,
                  }}
                  styles={customStyles}
                  onKeyDown={handleProductCodeKeyPress}
                  onInputChange={handleInputChange}
                  loadingMessage={() => t('common.lbl_Loading')}
                  formatOptionLabel={formatOptionLabel}
                  noOptionsMessage={() =>
                    query === ''
                      ? t('common.lbl_StartTyping')
                      : t('common.lbl_NoOptions')
                  }
                  value={product}
                  onChange={handleProductChange}
                  loadOptions={AwesomeDebouncePromise(loadOptions, 400)}
                  classNamePrefix='select'
                />
              </InputGroup>
            </Col>

            <Col className={'ps-2 pe-0'}>
              <FormControl
                id='project-add-product-name'
                onChange={handleProductNameChange}
                value={product?.name}
                readOnly={!isProductCodeNew}
                placeholder={t('projectSettings.plh_ProductName')}
                style={{ cursor: isProductCodeNew ? 'text' : 'not-allowed' }}
              />
            </Col>
            <Col
              className={isProductCodeNew ? 'pe-1 ps-0 ms-0' : 'px-1'}
              style={{ maxWidth: '130px' }}
            >
              <FormControl
                value={qty}
                onChange={(event) =>
                  event.target.value !== '' &&
                  !isNaN(parseInt(event.target.value))
                    ? setQty(parseInt(event.target.value))
                    : setQty('')
                }
                placeholder={t('projectSettings.plh_Qty')}
                className='ms-1'
                onKeyPress={handleKeyPress}
                id='project-add-product-qty'
              />
            </Col>

            <Col style={{ maxWidth: '50px' }}>
              <PlusCircleFill
                style={
                  product !== null &&
                  JSON.stringify(product) !== '{}' &&
                  qty !== ''
                    ? { cursor: 'pointer' }
                    : {}
                }
                onClick={handleAddProduct}
                fontSize={25}
                className={allowedToAddRow ? 'ms-1 icon-ez' : 'ms-1'}
              />
            </Col>
          </Row>
        </Col>
        <Col
          style={{
            maxWidth: '228px',
          }}
        />
      </Row>
    </>
  );
};

import { IdentityApi } from 'api/IdentityApi';
import { OrderCancelled } from 'assets/icons/StatusIcons';
import { useState } from 'react';
import { Button, Form, FormGroup, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  accessToken: string | null;
  getOrder: () => Promise<void>;
};

export const ExternalSellerOrderCancelModal = ({
  accessToken,
  getOrder,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleDecline = async () => {
    setIsLoading(true);
    if (accessToken) {
      await IdentityApi.cancelExternalSellerOrder(accessToken, { comment });
      toast.success(t('documents.inf_OrderCancelled'));

      await getOrder();
    }

    handleClose();

    setIsLoading(false);
  };

  return (
    <>
      <Button
        disabled={isLoading}
        onClick={handleShow}
        className='btn-outline-danger d-flex align-items-center'
      >
        <OrderCancelled className='icon-ez-red me-1' />{' '}
        {t('documents.btn_CancelOrder')}
      </Button>

      <Modal size='sm' show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{t('documents.hd_CancelOrder')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{t('documents.inf_CancelOrder')}</p>

          <FormGroup>
            <Form.Label>{t('documents.lbl_ReasonForCancellation')}</Form.Label>
            <Form.Control
              as='textarea'
              onChange={(event) => setComment(event.target.value)}
            />
          </FormGroup>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={isLoading}
            onClick={handleClose}
            variant='secondary'
          >
            {t('common.btn_Cancel')}
          </Button>
          <Button
            disabled={isLoading || comment === ''}
            onClick={handleDecline}
            variant='danger'
          >
            {t('common.btn_Ok')}{' '}
            {isLoading && <Spinner animation='border' size='sm' />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { EditableImageWithFallbackButtons } from 'components/shared/controls/EditableImageWithFallback/EditableImageWithFallbackButtons';
import { ImageWithFallback } from 'components/shared/ImageWithFallback';
import { LightTooltip } from 'components/shared/LightTooltip';
import { ProductBreadcrumbItem } from 'components/shared/products/ProductBreadcrumb';
import NoProductImage from 'media/ElecZap_new_logo_grayscale_cropped.png';
import FavouriteImage from 'media/Favourite.png';
import { BuyerProductClassWithSubclassesModel } from 'models/buyer/product/BuyerProductClassWithSubclassesModel';
import { SellerProductClassWithSubclassesModel } from 'models/seller/product/SellerProductClassWithSubclassesModel';
import { ProductClassResultLinkType } from 'models/shared/product/ProductClassResultLinkType';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { HashLink as Link } from 'react-router-hash-link';

type PropsType = {
  defaultCollapse?: boolean;
  items:
    | SellerProductClassWithSubclassesModel[]
    | BuyerProductClassWithSubclassesModel[];
  productSubClassesRoute: string;
  favoriteProductsRoute: string;
  productBreadCrumbItems?: ProductBreadcrumbItem[];
  isLoading?: boolean;
  viewType: 'Seller' | 'Buyer';
  handleProductClassImageUpload: (productClassId: number) => void;
};

export const ProductClassesQuickMenu = ({
  items,
  defaultCollapse,
  productSubClassesRoute,
  favoriteProductsRoute,
  productBreadCrumbItems,
  isLoading,
  viewType,
  handleProductClassImageUpload,
}: PropsType) => {
  const { t } = useTranslation('components');
  const [scrolledStage1, setScrolledStage1] = useState<boolean>(false);

  const history = useHistory();

  let navbarClasses = defaultCollapse
    ? ['product-class-section-header-container', 'mt-0', 'p-2', 'header-small']
    : [
        'product-class-section-header-container',
        'mt-3',
        'p-1',
        'header-small-hidden',
      ];
  if (scrolledStage1) {
    navbarClasses.pop();
    navbarClasses.push('header-small shadow');
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (!defaultCollapse) {
      const linkHref = event.currentTarget.getAttribute('href');

      const sectionId = linkHref?.split('#')[1];

      const targetElement = document.querySelector(
        `.product-class-section-header [id="${sectionId}"]`
      );

      targetElement !== null &&
        targetElement.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  };

  useEffect(() => {
    const delayedScrollAfterLoad = () => {
      var url = window.location.href;

      const sectionId = url?.split('#')[1];

      var elmnt = document.getElementById(`${sectionId}`);

      items.length > 0 &&
        elmnt !== null &&
        elmnt.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    };

    !isLoading && setTimeout(() => delayedScrollAfterLoad(), 1000);
  }, [items.length, defaultCollapse, isLoading]);

  useEffect(() => {
    const io = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Add 'active' class if observation target is inside viewport
          if (entry.isIntersecting) {
            /* Change url state */
            var url = window.location.href;

            const sectionId = entry.target.id.toString();

            const nextURL = url?.split('#')[0] + `#${sectionId}`;

            const nextTitle = 'ElecZap';
            const nextState = {
              additionalInformation: 'Updated the URL with Product code',
            };

            // This will replace the current entry in the browser's history, without reloading
            sectionId !== '' &&
              AwesomeDebouncePromise(
                () =>
                  window.history.replaceState(nextState, nextTitle, nextURL),
                1000
              )();

            /* Assign correct active class to section */
            document
              .querySelectorAll(`.product-class-section-header`)
              .forEach((item) => {
                item.classList.remove('active');
              });
            document
              .querySelector(`.product-class-section-header.pc${sectionId}`)
              ?.classList.add('active');
          }
        });
      },
      { rootMargin: '-30% 0px -60% 0px', threshold: 0 }
    );

    let listOfSectionsHTML = document.querySelectorAll(
      '.product-class-section'
    );
    listOfSectionsHTML.forEach(
      (element: any) => !defaultCollapse && io.observe(element)
    );

    let topLevelMenu = document.querySelectorAll(
      '.product-class-section-header-default'
    );

    topLevelMenu.forEach(
      (element: any) => !defaultCollapse && io.observe(element)
    );

    return () => {
      listOfSectionsHTML.forEach((element: any) => io.unobserve(element));
    };
  });

  const scrollParentToChild = (parent: Element, child: Element) => {
    // Where is the parent on page
    var parentRect = parent.getBoundingClientRect();
    // What can you see?
    var parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    };

    // Where is the child
    var childRect = child.getBoundingClientRect();
    // Is the child viewable?
    var isViewable =
      childRect.left >= parentRect.left &&
      childRect.right <= parentRect.left + parentViewableArea.width;

    // if you can't see the child try to scroll parent
    if (!isViewable) {
      // Should we scroll using top or bottom? Find the smaller ABS adjustment
      const scrollLeft =
        childRect.left - parentRect.left - window.innerWidth / 2;
      const scrollRight =
        childRect.right - parentRect.right + window.innerWidth / 2;
      if (Math.abs(scrollLeft) < Math.abs(scrollRight)) {
        // we're near the top of the list
        parent.scrollTo({
          left: parent.scrollLeft + scrollLeft,
          behavior: 'smooth',
        });
      } else {
        // we're near the bottom of the list
        parent.scrollTo({
          left: parent.scrollLeft + scrollRight,
          behavior: 'smooth',
        });
      }
    }
  };

  const checkActiveClassAndScrollToCenter = useCallback(
    (newActiveClass?: Element) => {
      const container: Element = document.querySelectorAll('.header-small')[0];

      const activeClassNode =
        newActiveClass ??
        document.querySelectorAll('.product-class-section-header.active')[0];

      if (container !== undefined && activeClassNode !== undefined) {
        scrollParentToChild(container, activeClassNode);
      }
    },
    []
  );

  /* IO observe for quick menu */
  useEffect(() => {
    window.addEventListener(
      'scroll',
      AwesomeDebouncePromise(() => checkActiveClassAndScrollToCenter(), 100)
    );

    return () =>
      window.removeEventListener(
        'scroll',
        AwesomeDebouncePromise(() => checkActiveClassAndScrollToCenter(), 100)
      );
  }, [checkActiveClassAndScrollToCenter]);

  /* Check if top level menu is off the screen */
  useEffect(() => {
    const io = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setScrolledStage1(!entry.isIntersecting);
        });
      },
      { rootMargin: '-60px 0px 60px 0px' }
    );

    let topLevelMenu = document.querySelectorAll(
      '.product-class-section-header-container-default'
    );

    topLevelMenu.forEach(
      (element: any) => !defaultCollapse && io.observe(element)
    );

    return () => {
      topLevelMenu.forEach((element: any) => io.unobserve(element));
    };
  });

  /* Highlight parent class in other levels quickmenu */
  useEffect(() => {
    const highlightCorrectParent = () => {
      if (productBreadCrumbItems && productBreadCrumbItems[0]) {
        const code = productBreadCrumbItems[0].code;

        const newActiveClass = document.querySelector(
          `.product-class-section-header.pc${code}`
        );

        newActiveClass?.classList.add('active');

        newActiveClass && checkActiveClassAndScrollToCenter(newActiveClass);

        // document
        //   .querySelector(`.product-class-section-header.pc${code}`)
        //   ?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
    };
    setTimeout(() => highlightCorrectParent(), 1000);
  }, [checkActiveClassAndScrollToCenter, productBreadCrumbItems]);

  const getLink = (
    linkType: ProductClassResultLinkType,
    productCode: string
  ) => {
    return linkType === 'Favorites'
      ? favoriteProductsRoute
      : `${productSubClassesRoute}#${productCode}`;
  };

  const QuickmenuNode = (
    <Row className={navbarClasses.join(' ')}>
      {items.map((productClass, index) => (
        <Col
          style={{ height: '85px' }}
          data-tip
          data-for={`productclass-quickmenu-${productClass.id}`}
          className={`product-class-section-header pc${productClass.code} p-3 mx-2 mb-3`}
        >
          <>
            <Link
              onClick={handleClick}
              to={getLink(productClass.linkType, productClass.code)}
            >
              <ImageWithFallback
                alt='Product class image'
                className='product-class-image p-2 mb-2'
                src={productClass.image}
                fallback={index === 0 ? FavouriteImage : NoProductImage}
              />
            </Link>
            <Link
              className='product-class-link'
              onClick={handleClick}
              to={getLink(productClass.linkType, productClass.code)}
            >
              {productClass.name}
            </Link>
            <Link
              className='product-class-qty'
              onClick={handleClick}
              to={getLink(productClass.linkType, productClass.code)}
            >
              {`${productClass.productCount} ${t('common.lbl_Products')}`}
            </Link>
          </>

          <LightTooltip
            place='bottom'
            id={`productclass-quickmenu-${productClass.id}`}
          >
            <span>
              {index === 0 ? t('common.lbl_Favourites') : productClass.name}
            </span>
          </LightTooltip>
        </Col>
      ))}
    </Row>
  );

  return (
    <>
      {!defaultCollapse && (
        <Row
          id='productsClasses'
          className={[
            'product-class-section-header-container',
            'product-class-section-header-container-default',
            'mt-3',
            'p-1',
          ].join(' ')}
        >
          {items.map((productClass, index) => (
            <Col
              data-tip
              data-for={`productclass-fullmenu-${productClass.id}`}
              className={`product-class-section-header-unchanged pc${productClass.code} p-3 mx-2 mb-3`}
            >
              <>
                <Link
                  onClick={handleClick}
                  to={getLink(productClass.linkType, productClass.code)}
                >
                  <EditableImageWithFallbackButtons
                    noDelete={true}
                    alt={productClass.code}
                    readonly={viewType === 'Buyer' || index === 0}
                    className='product-class-image p-3 mb-2'
                    src={productClass.image}
                    fallback={index === 0 ? FavouriteImage : NoProductImage}
                    handleImageUpload={() => {
                      handleProductClassImageUpload(productClass.id);
                    }}
                  />
                </Link>
                <Link
                  className='product-class-link'
                  onClick={handleClick}
                  to={getLink(productClass.linkType, productClass.code)}
                >
                  {index === 0 ? t('common.lbl_Favourites') : productClass.name}
                </Link>
                <Link
                  className='product-class-qty'
                  onClick={handleClick}
                  to={getLink(productClass.linkType, productClass.code)}
                >
                  {`${productClass.productCount} ${t('common.lbl_Products')}`}
                </Link>
              </>
            </Col>
          ))}
        </Row>
      )}

      {/* {!defaultCollapse &&
        ReactDOM.createPortal(
          QuickmenuNode,
          document &&
            document.getElementsByClassName('fixed-portal-receiver')[0]
        )} */}

      {QuickmenuNode}

      {/* {defaultCollapse && QuickmenuNode} */}
    </>
  );
};

import {
  DealActive,
  DealFinshed,
  DealLost,
  InquiryDeclined,
  InquiryDisabled,
  InquiryNoReplyBuyer,
  InquiryOfferSent,
  InquirySending,
  OfferExpired,
  OfferNoReplyBuyer,
  OfferOrderMade,
  OrderCancelled,
  OrderConfirmed,
  OrderDisabled,
  OrderDispatched,
  OrderSending,
  OrderUnconfirmed,
} from 'assets/icons/StatusIcons';
import { useSetCurrentProject } from 'hooks/useSetCurrentProject';
import { ProjectsNewDataProjectModel } from 'models/buyer/project/ProjectModels';
import { Card, Col, Row } from 'react-bootstrap';
import { BriefcaseFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const dealStatusMap: {
  [Draft: string]: JSX.Element;
  Lost: JSX.Element;
  Active: JSX.Element;
  Finished: JSX.Element;
} = {
  Draft: <BriefcaseFill size={15} className='icon-ez-gray mx-1' />,
  Lost: <DealLost size={15} className='icon-ez-black mx-1' />,
  Active: <DealActive size={15} className='icon-ez-green mx-1' />,
  Finished: <DealFinshed size={15} className='icon-ez-gray mx-1' />,
};

const inquiryStatusMap: {
  [Unsent: string]: JSX.Element;
  NoReply: JSX.Element;
  OfferMade: JSX.Element;
  Sending: JSX.Element;
  Declined: JSX.Element;
  Expired: JSX.Element;
} = {
  Unsent: <InquiryDisabled size={15} className='icon-ez-gray mx-1' />,
  NoReply: <InquiryNoReplyBuyer size={15} className='icon-ez-yellow mx-1' />,
  OfferMade: <InquiryOfferSent size={15} className='icon-ez mx-1' />,
  Sending: <InquirySending size={15} className='icon-ez-gray mx-1' />,
  Declined: <InquiryDeclined className='icon-ez-red mx-1' />,
  Expired: <OfferExpired size={15} className='icon-ez-red mx-1' />,
};

const offerStatusMap: {
  [Expired: string]: JSX.Element;
  NoReply: JSX.Element;
  OrderMade: JSX.Element;
} = {
  Expired: <OfferExpired size={15} className='icon-ez-red mx-1' />,
  NoReply: <OfferNoReplyBuyer size={15} className='icon-ez-yellow mx-1' />,
  OrderMade: <OfferOrderMade size={15} className='icon-ez mx-1' />,
};

const orderStatusMap: {
  [Unsent: string]: JSX.Element;
  Unconfirmed: JSX.Element;
  Confirmed: JSX.Element;
  Dispatched: JSX.Element;
  Cancelled: JSX.Element;
} = {
  Unsent: <OrderDisabled size={15} className='icon-ez-gray mx-1' />,
  Unconfirmed: <OrderUnconfirmed size={15} className='icon-ez-yellow mx-1' />,
  Confirmed: <OrderConfirmed size={15} className='icon-ez-green mx-1' />,
  Dispatched: <OrderDispatched size={15} className='icon-ez mx-1' />,
  Sending: <OrderSending size={15} className='icon-ez-gray mx-1' />,
  Cancelled: <OrderCancelled className='icon-ez-red mx-1' />,
};

type PropTypes = {
  projectId: number;
  index: number;
  inquiryProject: ProjectsNewDataProjectModel;
  orderProject: ProjectsNewDataProjectModel;
};

export const BuyerDealsCardDraft = ({
  projectId,
  index,
  inquiryProject,
  orderProject,
}: PropTypes) => {
  const { t } = useTranslation('components');

  const setCurrentProject = useSetCurrentProject();

  const history = useHistory();

  const handleProjectInquiry = async () => {
    await setCurrentProject(projectId);

    history.push(
      `/buyer/project/${projectId}/project-inquiry/${inquiryProject.id}`
    );
  };

  const handleProjectOrder = async () => {
    await setCurrentProject(projectId);

    history.push(
      `/buyer/project/${projectId}/project-order/${orderProject.id}`
    );
  };

  return (
    <Row className='px-3 mb-2 justify-content-between project-view-deal'>
      {/* Inquiries */}

      <Col>
        {inquiryProject !== null ? (
          <Card
            style={{ height: '100%' }}
            onClick={handleProjectInquiry}
            className='card-deals-secondary bg-light border m-0 p-1 cursor-pointer'
          >
            <Card.Body className='bg-light p-1'>
              <Row className='align-items-end'>
                <Col md={7}>{/* <TagFill className='icon-ez' /> */}</Col>
                <Col className='text-end icon-ez-yellow'>
                  {t('deals.hd_DraftInquiry')}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ) : (
          <div
            className='d-flex align-items-center mx-0 px-0 justify-content-center'
            style={{
              minHeight: '100%',
              paddingBottom: '0.5rem',
              marginTop: index === 0 ? '-2px' : '4px',
            }}
          ></div>
        )}
      </Col>

      {/* <Col
        className='d-flex align-items-center mx-0 px-0'
        style={{ maxWidth: '24px' }}
      >
        {(dealsDetail.offer !== null ||
          dealsDetail.order !== null ||
          (dealsDetail.invoices !== null &&
            dealsDetail.invoices.length > 0)) && (
          <hr className='icon-ez-light-gray' style={{ width: '24px' }} />
        )}
      </Col> */}

      {/* Offers */}

      <Col>
        <div
          className='d-flex align-items-center mx-0 px-0 justify-content-center'
          style={{
            minHeight: '100%',
            paddingBottom: '0.5rem',
            marginTop: index === 0 ? '-2px' : '4px',
          }}
        >
          {/* {(dealsDetail.order !== null ||
              (dealsDetail.invoices !== null &&
                dealsDetail.invoices.length > 0)) && (
              <>
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
                <ChevronRight className='icon-ez-light-gray' />
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
              </>
            )} */}
        </div>
      </Col>

      {/* <Col
        className='d-flex align-items-center mx-0 px-0'
        style={{ maxWidth: '24px' }}
      >
        {(dealsDetail.order !== null ||
          (dealsDetail.invoices !== null &&
            dealsDetail.invoices.length > 0)) && (
          <hr className='icon-ez-light-gray' style={{ width: '24px' }} />
        )}
      </Col> */}

      {/* Orders */}

      <Col>
        {orderProject !== null ? (
          <Card
            style={{ height: '100%' }}
            onClick={handleProjectOrder}
            className='card-deals-secondary bg-light border m-0 p-1 cursor-pointer'
          >
            <Card.Body className='bg-light p-1'>
              <Row className='align-items-end'>
                <Col md={7}>{/* <TagFill className='icon-ez' /> */}</Col>
                <Col className='text-end icon-ez-yellow'>
                  {t('deals.hd_DraftOrder')}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ) : (
          <div
            className='d-flex align-items-center mx-0 px-0 justify-content-center'
            style={{
              minHeight: '100%',
              paddingBottom: '0.5rem',
              marginTop: index === 0 ? '-2px' : '4px',
            }}
          ></div>
        )}
      </Col>

      {/* <Col
        className='d-flex align-items-center mx-0 px-0'
        style={{ maxWidth: '24px' }}
      >
        {dealsDetail.invoices !== null && dealsDetail.invoices.length > 0 && (
          <hr className='icon-ez-light-gray' style={{ width: '24px' }} />
        )}
      </Col> */}

      {/* Invoices */}

      <Col>
        <div
          className='d-flex align-items-center mx-0 px-0 justify-content-center'
          style={{
            minHeight: '100%',
            paddingBottom: '0.5rem',
            marginTop: index === 0 ? '-2px' : '4px',
          }}
        ></div>
      </Col>
    </Row>
  );
};

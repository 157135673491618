import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useAuthUser } from 'react-auth-kit';

/**
 * If authenticated user can see price information, returns the passed in value, otherwise returns null.
 * @returns function that returns the original passed in value, if user is allowed to see prices, null otherwise
 */
export const useUserCanSeePrices = () => {
  const authUser = useAuthUser();
  const user = authUser() as LoginResponseUserModel;

  const canSeePrices = (value: any) => {
    return user.hasRightToSeePrices ? value : null;
  };

  return canSeePrices;
};

import { useFormatNumber } from 'hooks/useFormatNumber';
import { useShowDataOrHide } from 'hooks/useShowDataOrHide';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { ProductDetailsModel } from 'models/shared/product/ProductDetailsModel';
import { useState } from 'react';
import { Card, Col, Collapse, Row } from 'react-bootstrap';
import {
  ArrowDownCircleFill,
  ArrowRightCircleFill,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropsType = {
  products: ProductDetailsModel[];
};

export const ProductDetailsGeneralInfo = ({ products }: PropsType) => {
  const { t } = useTranslation('components');
  const [open, setOpen] = useState(true);
  const showDataOrHide = useShowDataOrHide();
  const validateUnitExists = useValidateUnitExists();

  const formatNumber = useFormatNumber();

  return (
    <Card className='width-max-content'>
      <Card.Header
        className='header-aProduct p-2 position-sticky'
        onClick={() => setOpen(!open)}
        aria-controls='generalParameters'
        aria-expanded={open}
      >
        {open ? (
          <ArrowDownCircleFill className='d-print-none' />
        ) : (
          <ArrowRightCircleFill className='d-print-none' />
        )}
        <span className='p-1 multiple-seller-card-header-max-width-colored'>
          {t('productDetailsGeneralInfo.lbl_GeneralParameters')}
        </span>
      </Card.Header>
      <Collapse in={open}>
        <Card.Body id='generalParameters'>
          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_ProductDescription')}:
            </Col>
            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {item.description}
                </Col>
              ))}
          </Row>

          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_ProductCode')}:
            </Col>
            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {item.code}
                </Col>
              ))}
          </Row>

          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_Ean')}:
            </Col>

            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {item.barcode}
                </Col>
              ))}
          </Row>

          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_ManufacturerName')}:
            </Col>

            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {item.manufacturerName}
                </Col>
              ))}
          </Row>

          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_EtimClass')}:
            </Col>
            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {item.etimClass}
                </Col>
              ))}
          </Row>

          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_SupplierClass')}:
            </Col>

            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {item.productClasses && item.productClasses[0]?.name}
                </Col>
              ))}
          </Row>

          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_BrandName')}:
            </Col>

            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {item.brandName}
                </Col>
              ))}
          </Row>

          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_ProductSeries')}:
            </Col>

            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {item.series}
                </Col>
              ))}
          </Row>

          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_CountryOfOrign')}:
            </Col>

            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {item.country && item.country.shortName}
                </Col>
              ))}
          </Row>

          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_CN8Code')}:
            </Col>

            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {item.cn8code}
                </Col>
              ))}
          </Row>

          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_Unit')}:
            </Col>
            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {validateUnitExists(item.unit)}
                </Col>
              ))}
          </Row>

          <Row>
            <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
              {t('productDetailsGeneralInfo.lbl_PackageSize')}:
            </Col>

            {products &&
              products.map((item) => (
                <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                  {item.packageSize}
                </Col>
              ))}
          </Row>

          {(products.length > 1 || products[0].netWeight) && (
            <Row>
              <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
                {t('productDetailsGeneralInfo.lbl_NetWeight')}:
              </Col>

              {products &&
                products.map((item) => (
                  <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                    {item.netWeight ? formatNumber(item.netWeight) : '-'} kg
                  </Col>
                ))}
            </Row>
          )}

          {(products.length > 1 || products[0].netLength) && (
            <Row>
              <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
                {t('productDetailsGeneralInfo.lbl_NetLenght')}:
              </Col>

              {products &&
                products.map((item) => (
                  <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                    {item.netLength ? formatNumber(item.netLength, 4) : '-'}{' '}
                    {item.erpUnit}
                  </Col>
                ))}
            </Row>
          )}

          {(products.length > 1 || products[0].netWidth) && (
            <Row>
              <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
                {t('productDetailsGeneralInfo.lbl_NetWidth')}:
              </Col>

              {products &&
                products.map((item) => (
                  <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                    {item.netWidth ? formatNumber(item.netWidth, 4) : '-'}{' '}
                    {item.erpUnit}
                  </Col>
                ))}
            </Row>
          )}

          {(products.length > 1 || products[0].netDepth) && (
            <Row>
              <Col className='pt-1 pb-1 text-end multiple-seller-card-header-max-width'>
                {t('productDetailsGeneralInfo.lbl_NetDepth')}:
              </Col>

              {products &&
                products.map((item) => (
                  <Col className='ms-1 pt-1 pb-1 multiple-seller-card-product-max-width'>
                    {item.netDepth ? formatNumber(item.netDepth, 4) : '-'}{' '}
                    {item.erpUnit}
                  </Col>
                ))}
            </Row>
          )}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

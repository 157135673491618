import {
  DealActive,
  DealFinshed,
  DealLost,
  InquiryDisabled,
  InquiryNoReplySeller,
  InquiryOfferSent,
  InquirySending,
  OfferExpired,
  OfferNoReplySeller,
  OfferOrderMade,
  OrderConfirmed,
  OrderDisabled,
  OrderDispatched,
  OrderSending,
  OrderUnconfirmed,
} from 'assets/icons/StatusIcons';
import { SellerDealsInvoiceCard } from 'components/seller/dealshome/SellerDealsInvoiceCard';
import { SellerDealsTagInput } from 'components/seller/dealshome/SellerDealsTagInput';
import { DealsNameTooltip } from 'components/shared/deals/DealsNameTooltip/DealsNameTooltip';
import { ProjectAttachmentItem } from 'components/shared/deals/ProjectAttachmentItem/ProjectAttachmentItem';
import { LightTooltip } from 'components/shared/LightTooltip';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { DealModel } from 'models/seller/deals/DealsModels';
import { ProjectAttachmentsModel } from 'models/shared/attachments/AttachmentModel';
import { useContext } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import {
  BriefcaseFill,
  ClockHistory,
  Envelope,
  Paperclip,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';

const dealStatusMap: {
  [Draft: string]: JSX.Element;
  Lost: JSX.Element;
  Active: JSX.Element;
  Finished: JSX.Element;
} = {
  Draft: <BriefcaseFill size={15} className='icon-ez-gray mx-1' />,
  Lost: <DealLost size={15} className='icon-ez-balck mx-1' />,
  Active: <DealActive size={15} className='icon-ez-green mx-1' />,
  Finished: <DealFinshed size={15} className='icon-ez-gray mx-1' />,
};

const inquiryStatusMap: {
  [Unsent: string]: JSX.Element;
  NoReply: JSX.Element;
  OfferMade: JSX.Element;
  Sending: JSX.Element;
} = {
  Unsent: <InquiryDisabled size={15} className='icon-ez-gray mx-1' />,
  NoReply: <InquiryNoReplySeller size={15} className='icon-ez-yellow mx-1' />,
  OfferMade: <InquiryOfferSent size={15} className='icon-ez mx-1' />,
  Sending: <InquirySending size={15} className='icon-ez-gray mx-1' />,
};

const offerStatusMap: {
  [Expired: string]: JSX.Element;
  NoReply: JSX.Element;
  OrderMade: JSX.Element;
} = {
  Expired: <OfferExpired size={15} className='icon-ez-red mx-1' />,
  NoReply: <OfferNoReplySeller size={15} className='icon-ez-yellow mx-1' />,
  OrderMade: <OfferOrderMade size={15} className='icon-ez mx-1' />,
};

const orderStatusMap: {
  [Unsent: string]: JSX.Element;
  Unconfirmed: JSX.Element;
  Confirmed: JSX.Element;
  Dispatched: JSX.Element;
} = {
  Unsent: <OrderDisabled size={15} className='icon-ez-gray mx-1' />,
  Unconfirmed: <OrderUnconfirmed size={15} className='icon-ez-yellow mx-1' />,
  Confirmed: <OrderConfirmed size={15} className='icon-ez-green mx-1' />,
  Dispatched: <OrderDispatched size={15} className='icon-ez mx-1' />,
  Sending: <OrderSending size={15} className='icon-ez-gray mx-1' />,
};

type PropTypes = {
  dealsDetail: DealModel;
  index: number;
  lastStepSeenAt: number;
  currentUser: LoginResponseUserModel;
  getProjects: () => Promise<void>;
};

export const SellerDealsCard = ({
  dealsDetail,
  index,
  lastStepSeenAt,
  getProjects,
  currentUser,
}: PropTypes) => {
  const { appState } = useContext(AppContext);
  const history = useHistory();

  const { t } = useTranslation('components');

  const orderStatusText = (
    <span>
      {dealsDetail.order?.status === 'Unconfirmed' && (
        <>{t('documents.lbl_Unconfirmed')}</>
      )}
      {dealsDetail.order?.status === 'Confirmed' && (
        <>{t('documents.lbl_Confirmed')}</>
      )}
      {dealsDetail.order?.status === 'Dispatched' && (
        <>{t('documents.lbl_Dispatched')}</>
      )}
      {dealsDetail.order?.status === 'Sending' && (
        <>{t('documents.lbl_Sending')}</>
      )}
    </span>
  );

  const inquiryStatusText = (
    <span>
      {dealsDetail.inquiry?.status === 'Sending' && (
        <>{t('documents.lbl_Sending')}</>
      )}
      {dealsDetail.inquiry?.status === 'Unsent' && (
        <>{t('documents.lbl_Unsent')}</>
      )}
      {dealsDetail.inquiry?.status === 'NoReply' && (
        <>{t('documents.lbl_NoReply')}</>
      )}
      {dealsDetail.inquiry?.status === 'OfferMade' && (
        <>{t('documents.lbl_OfferMade')}</>
      )}
    </span>
  );

  return (
    <Row className='px-3 justify-content-between'>
      {/* Deals */}

      <Col>
        {index === 0 && (
          <hr
            className='deals-dealname-divider mb-1'
            style={{ marginLeft: '-25px' }}
          />
        )}
        <Card className='card-deals p-0'>
          <Card.Header className='py-1 px-2'>
            <Row className='mb-3'>
              <Col lg={10} sm={10}>
                <SellerDealsTagInput
                  dealsDetail={dealsDetail}
                  getProjects={getProjects}
                  currentUser={currentUser}
                  t={t}
                />
              </Col>
              <Col lg={2} sm={2}>
                <span
                  data-tip
                  data-for={`deals-tooltip-${dealsDetail.id}`}
                  className='float-end'
                >
                  {dealStatusMap[dealsDetail.dealStatus]}
                </span>

                <LightTooltip id={`deals-tooltip-${dealsDetail.id}`}>
                  <span>
                    {dealsDetail.dealStatus === 'Draft' && (
                      <>{t('documents.lbl_Draft')}</>
                    )}
                    {dealsDetail.dealStatus === 'Active' && (
                      <>{t('documents.lbl_Active')}</>
                    )}
                    {dealsDetail.dealStatus === 'Lost' && (
                      <>{t('documents.lbl_Lost')}</>
                    )}
                    {dealsDetail.dealStatus === 'Finished' && (
                      <>{t('documents.lbl_Finished')}</>
                    )}
                  </span>
                </LightTooltip>
              </Col>
            </Row>
            <Row className='row align-items-end'>
              <Col className='float-start mt-2 wrap-ellipsis' lg={7} md={7}>
                <span>{dealsDetail.buyerCompanyName}</span>
              </Col>
              <Col className='d-flex align-items-end justify-content-end'>
                <span className='icon-ez float-end '>
                  {dealsDetail.attachments?.length > 0 ? (
                    <Dropdown>
                      <Dropdown.Toggle
                        bsPrefix='deals-attachments'
                        variant='light'
                        className=''
                      >
                        <Paperclip />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='super-colors'>
                        {dealsDetail.attachments?.map(
                          (
                            attachment: ProjectAttachmentsModel,
                            index: number
                          ) => (
                            <ProjectAttachmentItem
                              attachment={attachment}
                              index={index}
                            />
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null}
                </span>
                <span className='float-end'>
                  <DealsNameTooltip
                    owners={dealsDetail.owners}
                    dealId={dealsDetail.id}
                  />
                </span>
              </Col>
            </Row>
          </Card.Header>
        </Card>
      </Col>

      {/* <Col
        className='d-flex align-items-center mx-0 px-0'
        style={{ maxWidth: '24px' }}
      >
        {(dealsDetail.inquiry !== null ||
          dealsDetail.offer !== null ||
          dealsDetail.order !== null ||
          (dealsDetail.invoices !== null &&
            dealsDetail.invoices.length > 0)) && (
          <hr className='icon-ez-light-gray' style={{ width: '24px' }} />
        )}
      </Col> */}

      {/* Iquiries */}

      <Col>
        {index === 0 && (
          <hr
            style={{ marginLeft: '-36px' }}
            className='deals-dealname-divider mb-1'
          />
        )}
        {dealsDetail.inquiry !== undefined && dealsDetail.inquiry !== null ? (
          <Card
            onClick={() =>
              history.push(`/seller/inquiry/${dealsDetail.inquiry?.id}`)
            }
            className='card-deals-secondary bg-light border m-0 p-1 cursor-pointer'
          >
            <Card.Header className='bg-light p-1'>
              <Row className='mb-3'>
                <Col lg={10} md={10}>
                  <h5
                    style={{
                      maxWidth:
                        window && window.innerWidth > 1000 ? '200px' : '111px',
                    }}
                    className='link-toDocument wrap-ellipsis'
                  >
                    <Link
                      to={`/seller/inquiry/${dealsDetail.inquiry?.id}`}
                      className='link-eleczap'
                    >
                      {dealsDetail.inquiry?.code ?? inquiryStatusText}
                    </Link>
                    {dealsDetail.inquiry?.customerInquiryCode && (
                      <span className='icon-ez-gray'>
                        {' '}
                        / {dealsDetail.inquiry?.customerInquiryCode ?? ''}
                      </span>
                    )}
                  </h5>
                </Col>
                <Col lg={2} sm={2}>
                  <span
                    data-tip
                    data-for={`inquiry-tooltip-${dealsDetail.inquiry?.id}`}
                    className='float-end'
                  >
                    {inquiryStatusMap[dealsDetail.inquiry?.status]}
                  </span>
                  <LightTooltip
                    id={`inquiry-tooltip-${dealsDetail.inquiry?.id}`}
                  >
                    {inquiryStatusText}
                  </LightTooltip>
                </Col>
              </Row>
              <Row className='align-items-end'>
                <Col>
                  <span
                    data-tip
                    data-for={`sent-on-inquiry-tooltip${dealsDetail.inquiry?.id}`}
                    className='float-start'
                  >
                    <Envelope size={15} className='icon-ez mb-1' />{' '}
                    {dayjs(dealsDetail.inquiry?.documentDate).format(
                      'DD.MM.YYYY'
                    )}
                  </span>
                  <LightTooltip
                    place='bottom'
                    id={`sent-on-inquiry-tooltip${dealsDetail.inquiry?.id}`}
                  >
                    <span>{t('documents.lbl_SentOn')}</span>
                  </LightTooltip>
                </Col>
                <Col></Col>
              </Row>
            </Card.Header>
          </Card>
        ) : (
          <div
            className='d-flex align-items-center mx-0 px-0 justify-content-center'
            style={{
              minHeight: '100%',
              paddingBottom: '0.5rem',
              marginTop: index === 0 ? '-2px' : '4px',
            }}
          >
            {/* {(dealsDetail.offer !== null ||
              dealsDetail.order !== null ||
              (dealsDetail.invoices !== null &&
                dealsDetail.invoices.length > 0)) && (
              <>
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
                <ChevronRight className='icon-ez-light-gray' />
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
              </>
            )} */}
          </div>
        )}
      </Col>

      {/* <Col
        className='d-flex align-items-center mx-0 px-0'
        style={{ maxWidth: '24px' }}
      >
        {(dealsDetail.offer !== null ||
          dealsDetail.order !== null ||
          (dealsDetail.invoices !== null &&
            dealsDetail.invoices.length > 0)) && (
          <hr className='icon-ez-light-gray' style={{ width: '24px' }} />
        )}
      </Col> */}

      {/* Offers */}

      <Col>
        {index === 0 && (
          <hr
            style={{ marginLeft: '-25px' }}
            className='deals-dealname-divider mb-1'
          />
        )}
        {dealsDetail.offer !== undefined && dealsDetail.offer !== null ? (
          <Card
            onClick={() =>
              history.push(`/seller/offer/${dealsDetail.offer?.id}`)
            }
            className='card-deals-secondary bg-light border m-0 p-1 cursor-pointer'
          >
            <Card.Header className='bg-light p-1'>
              <Row className='mb-3'>
                <Col>
                  <h5 className='link-toDocument'>
                    <Link
                      to={`/seller/offer/${dealsDetail.offer?.id}`}
                      className='link-eleczap'
                    >
                      {dealsDetail.offer?.code}
                    </Link>
                  </h5>
                </Col>
                <Col>
                  <span
                    data-tip
                    data-for={`offer-tooltip-${dealsDetail.offer?.id}`}
                    className='float-end'
                  >
                    {offerStatusMap[dealsDetail.offer?.status]}
                  </span>
                  <LightTooltip id={`offer-tooltip-${dealsDetail.offer?.id}`}>
                    <span>
                      {dealsDetail.offer?.status === 'NoReply' && (
                        <>{t('documents.lbl_NoReply')}</>
                      )}
                      {dealsDetail.offer?.status === 'Expired' && (
                        <>{t('documents.lbl_Expired')}</>
                      )}
                      {dealsDetail.offer?.status === 'OrderMade' && (
                        <>{t('documents.lbl_OrderMade')}</>
                      )}
                    </span>
                  </LightTooltip>
                </Col>
              </Row>
              <Row className='align-items-end'>
                <Col>
                  <span
                    data-tip
                    data-for={`valid-until-offer-tooltip${dealsDetail.offer?.id}`}
                  >
                    <ClockHistory size={15} className='icon-ez-gray' />{' '}
                    {dayjs(dealsDetail.offer?.documentDate).format(
                      'DD.MM.YYYY'
                    )}
                  </span>

                  <LightTooltip
                    place='bottom'
                    id={`valid-until-offer-tooltip${dealsDetail.offer?.id}`}
                  >
                    <span>{t('documents.lbl_ValidUntil')}</span>
                  </LightTooltip>
                </Col>
                <Col className='d-flex justify-content-end align-items-end'>
                  <span className='float-end'>
                    {formatMonetaryValue(
                      dealsDetail.offer?.currencyCode,
                      dealsDetail.offer?.total,
                      appState
                    )}
                  </span>
                </Col>
              </Row>
            </Card.Header>
          </Card>
        ) : (
          <div
            className='d-flex align-items-center mx-0 px-0 justify-content-center'
            style={{
              minHeight: '100%',
              paddingBottom: '0.5rem',
              marginTop: index === 0 ? '-2px' : '4px',
            }}
          >
            {/* {(dealsDetail.order !== null ||
              (dealsDetail.invoices !== null &&
                dealsDetail.invoices.length > 0)) && (
              <>
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
                <ChevronRight className='icon-ez-light-gray' />
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
              </>
            )} */}
          </div>
        )}
      </Col>

      {/* <Col
        className='d-flex align-items-center mx-0 px-0'
        style={{ maxWidth: '24px' }}
      >
        {(dealsDetail.order !== null ||
          (dealsDetail.invoices !== null &&
            dealsDetail.invoices.length > 0)) && (
          <hr className='icon-ez-light-gray' style={{ width: '24px' }} />
        )}
      </Col> */}

      {/* Orders */}

      <Col>
        {index === 0 && (
          <hr
            style={{ marginLeft: '-25px' }}
            className='deals-dealname-divider mb-1'
          />
        )}
        {dealsDetail.order !== undefined && dealsDetail.order !== null ? (
          <Card
            onClick={() =>
              history.push(`/seller/order/${dealsDetail.order?.id}`)
            }
            className='card-deals-secondary bg-light border m-0 p-1 cursor-pointer'
          >
            <Card.Header className='bg-light p-1'>
              <Row className='mb-3'>
                <Col lg={10} sm={10}>
                  <h5
                    style={{
                      maxWidth:
                        window && window.innerWidth > 1000 ? '200px' : '111px',
                    }}
                    className='link-toDocument wrap-ellipsis'
                  >
                    <Link
                      to={`/seller/order/${dealsDetail.order?.id}`}
                      className='link-eleczap'
                    >
                      {dealsDetail.order?.code ?? orderStatusText}
                    </Link>
                    {dealsDetail.order?.customerOrderCode && (
                      <span className='icon-ez-gray'>
                        {' '}
                        / {dealsDetail.order?.customerOrderCode ?? ''}
                      </span>
                    )}
                  </h5>
                </Col>
                <Col lg={2} sm={2}>
                  <span
                    data-tip
                    data-for={`order-tooltip-${dealsDetail.order?.id}`}
                    className='float-end'
                  >
                    {orderStatusMap[dealsDetail.order?.status]}
                  </span>

                  <LightTooltip id={`order-tooltip-${dealsDetail.order?.id}`}>
                    {orderStatusText}
                  </LightTooltip>
                </Col>
              </Row>
              <Row className='align-items-end'>
                <Col>
                  <span
                    data-tip
                    data-for={`sent-on-order-tooltip${dealsDetail.order?.id}`}
                  >
                    <Envelope size={15} className='icon-ez mb-1' />{' '}
                    {dayjs(dealsDetail.order?.documentDate).format(
                      'DD.MM.YYYY'
                    )}
                  </span>

                  <LightTooltip
                    place='bottom'
                    id={`sent-on-order-tooltip${dealsDetail.order?.id}`}
                  >
                    <span>{t('documents.lbl_SentOn')}</span>
                  </LightTooltip>
                </Col>
                <Col className='d-flex justify-content-end align-items-end'>
                  <span className='float-end'>
                    {formatMonetaryValue(
                      dealsDetail.order?.currencyCode,
                      dealsDetail.order?.total,
                      appState
                    )}
                  </span>
                </Col>
              </Row>
            </Card.Header>
          </Card>
        ) : (
          <div
            className='d-flex align-items-center mx-0 px-0 justify-content-center'
            style={{
              minHeight: '100%',
              paddingBottom: '0.5rem',
              marginTop: index === 0 ? '-2px' : '4px',
            }}
          >
            {/* {dealsDetail.invoices !== null && dealsDetail.invoices.length > 0 && (
              <>
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
                <ChevronRight className='icon-ez-light-gray' />
                <hr className='icon-ez-light-gray' style={{ width: '50%' }} />
              </>
            )} */}
          </div>
        )}
      </Col>

      {/* <Col
        className='d-flex align-items-center mx-0 px-0'
        style={{ maxWidth: '24px' }}
      >
        {dealsDetail.invoices !== null && dealsDetail.invoices.length > 0 && (
          <hr className='icon-ez-light-gray' style={{ width: '24px' }} />
        )}
      </Col> */}

      {/* Invoices */}

      <Col>
        {index === 0 && (
          <hr
            style={{ marginLeft: '-25px' }}
            className='deals-dealname-divider mb-1'
          />
        )}

        {dealsDetail.invoices !== null &&
        dealsDetail.invoices[0] !== undefined ? (
          dealsDetail.invoices.length > 1 ? (
            dealsDetail.invoices.map((invoice, index) => (
              <SellerDealsInvoiceCard invoice={invoice} />
            ))
          ) : (
            <SellerDealsInvoiceCard
              singular
              invoice={dealsDetail.invoices[0]}
            />
          )
        ) : (
          <div
            className='d-flex align-items-center mx-0 px-0 justify-content-center'
            style={{
              minHeight: '100%',
              paddingBottom: '0.5rem',
              marginTop: index === 0 ? '-2px' : '4px',
            }}
          ></div>
        )}
      </Col>
    </Row>
  );
};

import { SellerApi } from 'api/SellerApi';
import {
  OfferExpired,
  OfferNoReplySeller,
  OfferOrderMade,
} from 'assets/icons/StatusIcons';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { OfferModel, OfferRowModel } from 'models/seller/documents/OfferModels';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Alert, Card, Col, Form, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { formatMonetaryValueWithoutDecimals } from 'utils/formatMonetaryValueWithoutDecimals';
import { truncateDecimals } from 'utils/truncateDecimals';

type UrlParamsType = {
  offerId: string;
};

export const Offer = () => {
  const [offer, setOffer] = useState<OfferModel>({} as OfferModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { appState } = useContext(AppContext);

  const userLocaleId = appState.userProfile?.localization?.id || 1;

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');
  const validateUnitExists = useValidateUnitExists();

  let { offerId } = useParams<UrlParamsType>();

  const recallInquiryUpdated = useCallback(
    async (offer: OfferModel) => {
      if (offer.inquiryUpdated) {
        await SellerApi.recallInquiryUpdated(
          currentUser.companyId,
          parseInt(offerId)
        );
        setShowAlert(false);
      }
    },
    [currentUser.companyId, offerId]
  );

  useEffect(() => {
    setIsLoading(true);
    const getOffer = async (): Promise<void> => {
      const response = await SellerApi.getOffer(
        currentUser.companyId,
        parseInt(offerId),
        userLocaleId
      );

      setOffer(response);
      setIsLoading(false);

      setTimeout(() => recallInquiryUpdated(response), 5000);
    };
    getOffer();
  }, [currentUser.companyId, offerId, recallInquiryUpdated, userLocaleId]);

  const columns: TableColumn<OfferRowModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      sortable: true,
      width: '60px',
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode,
      cell: (row) =>
        row.productId === null ? (
          <span>{row.productCode}</span>
        ) : (
          <Link
            to={`/seller/product-details/${row.productId}`}
            className='btn-link-bold'
          >
            {row.productCode}
          </Link>
        ),
      sortable: true,
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name,
      sortable: true,

      grow: 6,
    },
    {
      name: t('documents.lbl_Qty'),
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          <span>
            {row.quantity} <small>{validateUnitExists(row.unit)}</small>
          </span>
        </>
      ),
      sortable: true,
      right: true,
    },
    {
      name: <span className='text-end'>{t('documents.lbl_TargetPrice')}</span>,
      selector: (row) => row.targetPrice ?? 'targetPrice',
      cell: (row) =>
        `${formatMonetaryValueWithoutDecimals(
          offer.currencyCode,
          row.targetPrice,
          appState
        )}`,
      sortable: true,
      right: true,
    },
    {
      name: t('documents.lbl_PriceUnit'),
      selector: (row) => row.priceWithDiscount,
      cell: (row) =>
        formatMonetaryValue(
          offer.currencyCode,
          row.priceWithDiscount,
          appState,
          row.decimalPlaces
        ),
      sortable: true,
      right: true,
    },

    {
      name: t('projectSettings.lbl_TotalPrice'),
      selector: (row) => row.total,
      cell: (row) =>
        formatMonetaryValue(
          offer.currencyCode,
          truncateDecimals(row.total, row.decimalPlaces),
          appState
        ),
      sortable: true,
      right: true,
    },
  ];

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className='container-fluid p-2'>
        {showAlert && (
          <Alert
            style={{
              opacity: showAlert ? '1' : '0',
              transitionDuration: '0.2s',
              zIndex: 3000,
              right: 0,
              top: '10px',
            }}
            variant='info'
            className='p-2 position-absolute'
          >
            <h3 className='text-center p-2 pb-0'>
              {t('documents.inf_InquiryHasUpdated')}
            </h3>
          </Alert>
        )}
        <Card>
          <Card.Header>
            <Row>
              <Col lg={4} />
              <Col className='text-center' lg={4}>
                <h2>
                  {t('documents.hd_Offer')} {offer.code}
                </h2>
              </Col>
              <Col
                className='text-center d-flex justify-content-lg-end justify-content-sm-center'
                lg={4}
              >
                <h3>
                  {offer.status === 'NoReply' && (
                    <>
                      {t('documents.lbl_NoReply')}{' '}
                      <OfferNoReplySeller className='icon-ez-yellow' />
                    </>
                  )}
                  {offer.status === 'Expired' && (
                    <>
                      {t('documents.lbl_Expired')}{' '}
                      <OfferExpired className='icon-ez-red' />
                    </>
                  )}
                  {offer.status === 'OrderMade' && (
                    <>
                      {t('documents.lbl_OrderMade')}{' '}
                      <OfferOrderMade className='icon-ez' />
                    </>
                  )}
                </h3>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_Date')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(offer.documentDate).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_UpdatedOn')}:
                  </Col>
                  <Col>
                    <strong>
                      {dayjs(offer.lastUpdated).format('DD-MM-YYYY')}
                    </strong>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentBy')}:
                  </Col>
                  <Col>
                    <strong>{offer.sentByContactName}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'>
                    {t('documents.lbl_SentTo')}:
                  </Col>
                  <Col>
                    <strong>{offer.sentToContactName}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col className='document-header-left-col'></Col>
                  <Col>
                    <Link
                      to={`/seller/a-customer?id=${offer.buyerCompanyId}`}
                      className='btn-link-bold'
                    >
                      {offer.sentToCompanyName}
                    </Link>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row className='flex-nowrap'>
                  <Col className='text-end pe-0 text-nowrap'>
                    <Row className='mb-0'>
                      <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                    </Row>

                    {offer.deliveryAddressLine2 !== null && <br />}

                    {offer.deliveryAddressLine3 !== null && <br />}

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_PaymentTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('creditInfo.lbl_DeliveryTerms')}:</Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col>{t('documents.lbl_ValidUntil')}:</Col>
                    </Row>
                  </Col>

                  <Col
                    className='pe-3 d-flex flex-column'
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <Row className='mb-0'>
                      <Col className='text-start document-header-right-col'>
                        <span className='btn-link-bold-no-link'>
                          {offer.projectName}
                        </span>
                      </Col>
                    </Row>

                    <Row className='mb-0'>
                      <Col className='d-flex flex-column text-nowrap'>
                        {offer.deliveryAddressLine1 !== null ? (
                          <strong>{offer.deliveryAddressLine1}</strong>
                        ) : (
                          <br />
                        )}
                        <strong>{offer.deliveryAddressLine2}</strong>
                        <strong>{offer.deliveryAddressLine3}</strong>
                      </Col>
                    </Row>

                    <Row className='text-start document-header-right-col'>
                      {offer.paymentTerm !== null &&
                      offer.paymentTerm !== '' ? (
                        <strong>{offer.paymentTerm}</strong>
                      ) : (
                        <br />
                      )}
                    </Row>

                    <Row className='text-start document-header-right-col'>
                      {offer.deliveryTerm !== null ? (
                        <strong>{offer.deliveryTerm}</strong>
                      ) : (
                        <br />
                      )}
                    </Row>

                    <Row className='text-start document-header-right-col'>
                      {offer.validUntil !== null ? (
                        <strong>
                          {dayjs(offer.validUntil).format('DD-MM-YYYY')}
                        </strong>
                      ) : (
                        <br />
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='my-5'>
              <DataTable
                noDataComponent={
                  <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
                }
                columns={columns}
                data={offer.rows}
              />
            </Row>

            <Row className='justify-content-between'>
              <Col lg={6}>
                <Form.Group className='mb-3' controlId='comments'>
                  <Form.Label>{t('common.lbl_Comments')}</Form.Label>
                  <Form.Control
                    readOnly
                    as='textarea'
                    rows={4}
                    value={offer.comment}
                  />
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Row className='mt-3'>
                  <Col>
                    <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                  </Col>
                  <Col>
                    <h3 className='text-end'>
                      {useFormatMonetaryValue(offer.currencyCode, offer.total)}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-end'>
                    {t('common.inf_TotalPriceSubtext')}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { AppContext } from 'contexts/user/AppContext';
import { useSetCurrentProject } from 'hooks/useSetCurrentProject';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { ProjectAddProductsModel } from 'models/buyer/project/ProjectModels';
import { ProjectOrderRowCreateModel } from 'models/buyer/projectOrder/ProjectOrderModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { addProductsToProjectOrder } from 'pages/buyer/ProjectOrder/utilsProjectOrder';
import { useContext } from 'react';
import { useAuthUser } from 'react-auth-kit';

export const useAddProductsToProjectOrder = () => {
  const { appState, appContextDispatch } = useContext(AppContext);

  const setUserProfile = useSetUserProfile();

  const setCurrentProject = useSetCurrentProject();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const addProductsToProject = async (
    requestFromPage: 'ProductList' | 'Document',
    products: ProjectAddProductsModel
  ) => {
    /* When Current Project and Inquiry exists */
    if (
      appState.userProfile?.currentProject &&
      appState.userProfile?.currentProject.orderProjectId
    ) {
      await addProduct(
        appState.userProfile?.currentProject.id,
        appState.userProfile?.currentProject.orderProjectId,
        requestFromPage,
        products
      );
    }

    /* When Current Project exists but Inquiry does NOT */
    if (
      appState.userProfile?.currentProject &&
      appState.userProfile?.currentProject.orderProjectId === null
    ) {
      await createOrderProject(
        appState.userProfile?.currentProject.id,
        requestFromPage,
        products
      );
    }

    /* When Current Project and Inquiry both do NOT exists */
    if (appState.userProfile?.currentProject === null) {
      await createProjectAndOrder(requestFromPage, products);
    }
  };

  const addProduct = async (
    projectId: number,
    projectOrderId: number,
    requestFromPage: 'ProductList' | 'Document',
    products: ProjectAddProductsModel
  ): Promise<void> => {
    const productsToAdd: ProjectOrderRowCreateModel[] = [];

    switch (requestFromPage) {
      case 'Document':
        if (products.importProductsText) {
          await BuyerApi.projectOrder.importProductFromText(
            currentUser.companyId,
            projectId,
            projectOrderId,
            products.importProductsText
          );
        }
        break;

      case 'ProductList':
        if (productsToAdd) {
          products.groupedProducts?.forEach((groupedProduct) => {
            if (groupedProduct?.quantity) {
              groupedProduct.products.forEach((product) => {
                productsToAdd.push({
                  productId: product?.id ?? null,
                  sellerCompanyId: product?.sellerCompanyId ?? null,
                  productCode: product?.code ?? null,
                  requiredQuantity: groupedProduct.quantity ?? 0,
                  supplierQuantity:
                    (appState.buyerCurrentSuppliers?.filter(
                      (supplier) => supplier.isActive && supplier.isSelected
                    ).length === 1 &&
                      appState.buyerCurrentSuppliers?.filter(
                        (supplier) => supplier.isActive && supplier.isSelected
                      )[0].value === product.sellerCompanyId) ||
                    groupedProduct.products.length === 1
                      ? groupedProduct.quantity ?? 0
                      : 0,
                });
              });
            }
          });

          await addProductsToProjectOrder(
            productsToAdd,
            currentUser,
            projectId,
            projectOrderId
          );
        }
        break;

      default:
        break;
    }

    const response = await BuyerApi.projectOrder.getProjectOrderRows(
      currentUser.companyId,
      projectOrderId,
      projectId
    );

    appContextDispatch({
      type: 'BUYER_SET_CURRENT_ORDER_PROJECT_ROWS',
      currentOrderProjectRows: response.length,
    });
  };

  const createOrderProject = async (
    projectId: number,
    requestFromPage: 'ProductList' | 'Document',
    products: ProjectAddProductsModel
  ) => {
    // Create order project
    const response = await BuyerApi.projectOrder.createProjectOrder(
      currentUser.companyId,
      projectId
    );

    // Get fresh orderProject
    setUserProfile(null);

    // Add product to that order
    await addProduct(projectId, response, requestFromPage, products);
  };

  const createProjectAndOrder = async (
    requestFromPage: 'ProductList' | 'Document',
    products: ProjectAddProductsModel
  ) => {
    // Create project
    const response = await BuyerApi.projects.createProjectAndProjectOrder(
      currentUser.companyId
    );

    await setCurrentProject(response.projectId);

    // Get fresh orderProject
    setUserProfile(null);

    await addProduct(
      response.projectId,
      response.orderProjectId,
      requestFromPage,
      products
    );
  };

  return addProductsToProject;
};

import { SellerApi } from 'api/SellerApi';
import { AssigContactRow } from 'components/seller/customers/ModalAssignContacts/AssignContactRow';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { BuyerContactsModel } from 'models/seller/contacts/BuyerContactsModel';
import { useCallback, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type PropTypes = {
  customerId: number;
  fromPage: 'customerInvitation' | 'customerContacts';
  show: boolean;
  setShow: (value: boolean) => void;
  preAssignTaskFunction?: (...args: any) => Promise<void>;
  postAssignTaskFunction?: () => Promise<void>;
  parentModal?: {
    modalNumber: 0 | 1;
    setModalNumber: (value: 0 | 1) => void;
    handleClose: () => void;
  };
};

export const ModalAssignContacts = ({
  customerId,
  show,
  setShow,
  preAssignTaskFunction,
  postAssignTaskFunction,
  parentModal,
  fromPage,
}: PropTypes) => {
  const { t } = useTranslation('components');
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const [unselectedValues, setUnselectedValues] = useState<number[]>([]);
  const [buyerContacts, setBuyerContacts] = useState<BuyerContactsModel[]>([]);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const getContacts = useCallback(async (): Promise<void> => {
    const response = await SellerApi.getBuyerContacts(
      currentUser.companyId,
      customerId
    );

    setBuyerContacts(response);
  }, [currentUser.companyId, customerId]);

  useEffect(() => {
    show && customerId !== undefined && getContacts();
  }, [currentUser.companyId, customerId, getContacts, show]);

  const handleClose = (): void => {
    if (parentModal !== undefined) {
      parentModal.setModalNumber(0);
    } else {
      setShow(false);
    }
  };

  /* Collect IDs on checklist change */
  const handleChange = (
    check: boolean,
    { userId, id }: { userId: number; id: number },
    setIsChecked: (value: boolean) => void
  ): void => {
    setIsChecked(check);

    let clonedSelectedValues = [...selectedValues];
    let clonedUnselectedValues = [...unselectedValues];

    const indexOfUserId = clonedSelectedValues.indexOf(userId);
    const indexOfId = clonedUnselectedValues.indexOf(id);

    if (check) {
      clonedSelectedValues.push(userId);
      indexOfId !== -1 && clonedUnselectedValues.splice(indexOfId, 1);
    } else {
      indexOfUserId !== -1 && clonedSelectedValues.splice(indexOfUserId, 1);
      clonedUnselectedValues.push(id);
    }

    const uniqueSelectedValues = [...Array.from(new Set(clonedSelectedValues))];
    const uniqueUnselectedValues = [
      ...Array.from(new Set(clonedUnselectedValues)),
    ];

    setSelectedValues(uniqueSelectedValues);
    setUnselectedValues(uniqueUnselectedValues);
  };

  const handleAssignContacts = async (): Promise<void> => {
    toast.dismiss();
    if (preAssignTaskFunction)
      await preAssignTaskFunction(
        fromPage === 'customerInvitation' && selectedValues
      );

    fromPage === 'customerContacts' &&
      (await SellerApi.updateBuyerContacts(currentUser.companyId, customerId, {
        userIds: selectedValues,
        contactIds: unselectedValues,
      }));

    if (postAssignTaskFunction) await postAssignTaskFunction();

    getContacts();

    parentModal?.handleClose();
    handleClose();
    setShow(false);

    fromPage === 'customerContacts' &&
      toast.info(t('customers.inf_AssignedContactsUpdated'));
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('customers.lbl_AssignContacts')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form className='p-2'>
            {buyerContacts.map((contact) => (
              <AssigContactRow
                image={contact.profileImage}
                initials={contact.initials}
                firstName={contact.firstName}
                lastName={contact.lastName}
                userId={contact.userId}
                handleChange={handleChange}
                isContact={contact.isContact}
                id={contact.id}
                fromPage={fromPage}
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {fromPage === 'customerInvitation'
              ? t('common.btn_Back')
              : t('common.btn_Close')}
          </Button>
          <Button
            disabled={selectedValues.length < 1}
            className='btn btn-eleczap'
            onClick={handleAssignContacts}
          >
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

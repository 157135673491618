import { IdentityApi } from 'api/IdentityApi';
import { useFormik } from 'formik';
import {
  BuyerRegistrationRequestModel,
  BuyerRegistrationUpdateContactDetailsModel,
} from 'models/buyer/buyerRegistration/BuyerRegistrationModels';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* Formik init */
export const useFormikHookForBuyerRegistrationUserDetails = (
  customer: BuyerRegistrationUpdateContactDetailsModel,
  setCustomer: (value: BuyerRegistrationUpdateContactDetailsModel) => void,
  nextStep: () => void,
  buyerRegistrationData: BuyerRegistrationRequestModel | null,
  setBuyerRegistrationData: (value: BuyerRegistrationRequestModel) => void
): any => {
  const { t } = useTranslation('components');

  const handleSubmit = async (
    values: BuyerRegistrationUpdateContactDetailsModel
  ): Promise<void> => {
    if (buyerRegistrationData !== null) {
      setBuyerRegistrationData({
        ...buyerRegistrationData,
        buyerUserRegistrationModel: values,
      });
    }

    const isUsernameAvailableResponse =
      await IdentityApi.checkIsUsernameAvailable(values.username);

    if (isUsernameAvailableResponse) nextStep();
    else {
      formik.setSubmitting(false);
      toast.error(t('buyerRegistration.err_UsernameAlreadyTaken'));
    }
  };

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .nullable()
      .required(t('userSettings.err_FirstNameRequired'))
      .max(50, t('userSettings.err_FirstNameMax')),
    lastName: yup
      .string()
      .nullable()
      .required(t('userSettings.err_LastNameRequired'))
      .max(50, t('userSettings.err_LastNameMax')),
    username: yup
      .string()
      .nullable()
      .email()
      .required(t('userSettings.err_LastNameRequired'))
      .max(50, t('userSettings.err_LastNameMax')),
    position: yup
      .string()
      .max(50, t('userSettings.err_PositionMax'))
      .nullable(),
    linkedInProfile: yup
      .string()
      .nullable()
      .url(t('userSettings.err_LinkedInInvalid'))
      .max(150, t('userSettings.err_LinkedInMax')),
    phone: yup.string().max(50, t('userSettings.err_PhoneMax')).nullable(),
  });

  const formik = useFormik<BuyerRegistrationUpdateContactDetailsModel>({
    initialValues:
      customer?.firstName !== undefined
        ? { ...customer }
        : {
            username: '',
            firstName: '',
            lastName: '',
            position: '',
            linkedInProfile: '',
            phone: '',
            termsAndConditionsAccepted: customer.termsAndConditionsAccepted,
          },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedCustomer: BuyerRegistrationUpdateContactDetailsModel = {
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        position: values.position,
        linkedInProfile: values.linkedInProfile,
        phone: values.phone,
        termsAndConditionsAccepted: customer.termsAndConditionsAccepted,
      };
      await handleSubmit(updatedCustomer);
      formik.setSubmitting(false);
    },
  });

  return formik;
};

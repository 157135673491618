import { HitHorizonsApi } from 'api/HitHorizonsApi';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { ExternalSellerAlreadyActive } from 'components/buyer/suppliers/ExternalSupplier/ExternalSupplierAlreadyActive';
import { ExternalSupplierRestore } from 'components/buyer/suppliers/ExternalSupplier/ExternalSupplierRestore';
import { useFormikForCreateExternalSupplier } from 'components/buyer/suppliers/ExternalSupplier/hooksExternalSupplier';
import { mapHitHorizonsSuppliersToOptions } from 'components/buyer/suppliers/utilsSuplliers';
import { useGetAvailableCountries } from 'hooks/useGetAvailabeCountries';
import { useGetAvailableCurrencies } from 'hooks/useGetAvailableCurrencies';
import { useGetLocalizations } from 'hooks/useGetLocalizations';
import { AnyAdminRole, useRBAC } from 'hooks/useRBAC';
import {
  SellersCompaniesAndContactsDataModel,
  SellersCompaniesAndContactsModel,
} from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { CountryModel } from 'models/shared/country/CountryModel';
import { CurrencyModel } from 'models/shared/currency/CurrencyModel';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { useState } from 'react';
import { Button, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';

const key = 'c7d9098cc749ce0949239e4a89ca84ee';

type PropTypes = {
  setSuppliers: (value: SellersCompaniesAndContactsModel) => void;
  suppliers: SellersCompaniesAndContactsModel;
  setParentModalShow?: (value: boolean) => void;
};

type SupplierOption = {
  value: string;
  label: string;
  CountryCode: string;
};

export const ExternalSupplierNew = ({
  setSuppliers,
  suppliers,
  setParentModalShow,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);

  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [localizations, setLocalizations] = useState<LocalizationModel[]>([]);
  const [currencies, setCurrencies] = useState<CurrencyModel[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [supplierQuery, setSupplierQuery] = useState<string>('');
  const [supplier, setSupplier] = useState<SupplierOption>(
    {} as SupplierOption
  );

  const [alreadyExistsShow, setAlreadyExistsShow] = useState<boolean>(false);
  const [restoreShow, setRestoreShow] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const hasRightsToEdit = useRBAC(AnyAdminRole);

  const formik = useFormikForCreateExternalSupplier(setSuppliers, setShow);

  useGetLocalizations(setLocalizations);
  useGetAvailableCountries(setCountries);
  useGetAvailableCurrencies(setCurrencies);

  const closeModal = () => {
    setShow(false);
    formik.resetForm();
  };

  const showModal = async () => {
    setShow(true);

    HitHorizonsApi.testProxy();
  };

  const handleSubmitForm = async () => {
    await formik.handleSubmit();
    setParentModalShow && setParentModalShow(false);
  };

  const isSupplierAlreadyActive = (): {
    isActive: boolean;
    activeSupplier: SellersCompaniesAndContactsDataModel;
  } | null => {
    if (suppliers.data === undefined) return null;

    let clonedData = [...suppliers.data];

    const foundIndex = clonedData.findIndex(
      (supplier) =>
        supplier.name.toUpperCase() ===
          formik.values.name.toLocaleUpperCase() && !supplier.isArchived
    );

    return {
      isActive: foundIndex !== -1,
      activeSupplier: clonedData[foundIndex],
    };
  };

  const isSupplierArchived = (): {
    isActive: boolean;
    externalSupplier: SellersCompaniesAndContactsDataModel;
  } | null => {
    if (suppliers.data === undefined) return null;

    let clonedData = [...suppliers.data];

    const foundIndex = clonedData.findIndex(
      (supplier) =>
        supplier.name.toUpperCase() ===
          formik.values.name.toLocaleUpperCase() && supplier.isArchived
    );

    return {
      isActive: foundIndex !== -1,
      externalSupplier: clonedData[foundIndex],
    };
  };

  const targetSupplier = isSupplierAlreadyActive();

  const targetExternalSupplier = isSupplierArchived();

  const handleCheckCompanyName = async () => {
    setIsLoading(true);

    /* IF seller already is active */
    if (targetSupplier) {
      setAlreadyExistsShow(targetSupplier.isActive);

      // formik.resetForm();
    }
    /* IF seller is archived */
    if (targetExternalSupplier) setRestoreShow(targetExternalSupplier.isActive);

    setIsLoading(false);
  };

  const handleSupplierChange = async (
    newValue: SingleValue<SupplierOption>
  ): Promise<void> => {
    formik.setFieldValue('name', newValue?.label);
    formik.setFieldValue('countryCode', newValue?.CountryCode.toUpperCase());
  };

  const loadOptions = async (input: string): Promise<SupplierOption[]> => {
    const response = await HitHorizonsApi.getSuggestions(key, input, 5);

    const options = mapHitHorizonsSuppliersToOptions(response);

    return options;
  };

  const debouncedLoadOptions = AwesomeDebouncePromise(loadOptions, 800);

  return (
    <>
      <Button
        disabled={!hasRightsToEdit}
        variant='btn btn-eleczap'
        onClick={showModal}
      >
        {t('externalSupplier.btn_AddASupplier')}
      </Button>

      <ExternalSellerAlreadyActive
        setAlreadyExistsShow={setAlreadyExistsShow}
        alreadyExistsShow={alreadyExistsShow}
        isSupplierAlreadyActive={isSupplierAlreadyActive}
        formik={formik}
      />

      <ExternalSupplierRestore
        setRestoreShow={setRestoreShow}
        restoreShow={restoreShow}
        setSuppliers={setSuppliers}
        isSupplierArchived={isSupplierArchived}
        closeParentModal={closeModal}
      />

      {!alreadyExistsShow && !restoreShow && (
        <Modal show={show} id='add-external-supplier' onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('externalSupplier.btn_AddASupplier')}</Modal.Title>
          </Modal.Header>

          <Modal.Body className='p-4'>
            <p>{t('externalSupplier.inf_ExternalSupplierPart1')}</p>

            <p>{t('externalSupplier.inf_ExternalSupplierPart2')}</p>

            <Form noValidate>
              {/* <Form.Group controlId='validationCompany'>
                <Form.Label>{t('customers.lbl_Company')}</Form.Label>
                <AsyncSelect
                  // onInputChange={(newValue: string) =>
                  //   setSupplierQuery(newValue)
                  // }
                  loadingMessage={() => t('common.lbl_Loading')}
                  noOptionsMessage={() =>
                    supplierQuery === ''
                      ? t('common.lbl_StartTyping')
                      : t('common.lbl_NoOptions')
                  }
                  value={supplier}
                  onChange={handleSupplierChange}
                  loadOptions={debouncedLoadOptions}
                />
              </Form.Group> */}

              <FormGroup className='mb-2' controlId='validationName'>
                <Form.Label className='required'>
                  {t('companySettings.lbl_CompanyName')}
                </Form.Label>
                <Form.Control
                  name='name'
                  value={formik.values.name}
                  onBlur={handleCheckCompanyName}
                  onChange={formik.handleChange}
                  placeholder={t('customers.plh_ExternalCompanyName')}
                  isInvalid={formik.touched.name && !!formik.errors.name}
                />{' '}
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.name}
                </Form.Control.Feedback>
              </FormGroup>

              <FormGroup className='mb-2' controlId='validationName'>
                <Form.Label className='required'>
                  {t('companySettings.lbl_ShortName')}
                </Form.Label>
                <Form.Control
                  name='shortName'
                  value={formik.values.shortName}
                  onChange={formik.handleChange}
                  placeholder={t('customers.plh_ExternalCompanyName')}
                  isInvalid={
                    formik.touched.shortName && !!formik.errors.shortName
                  }
                />{' '}
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.shortName}
                </Form.Control.Feedback>
              </FormGroup>

              <Form.Group className='mb-2' controlId='validationCountryCode'>
                <Form.Label className='required'>
                  {t('addressSettings.lbl_Country')}
                </Form.Label>
                <Form.Select
                  name='countryCode'
                  required
                  value={
                    formik.values.countryCode !== undefined
                      ? formik.values.countryCode
                      : 'AD'
                  }
                  onChange={formik.handleChange}
                >
                  {countries.map((country, index) => (
                    <option key={index} value={country.code}>
                      {country.shortName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className='mb-2' controlId='validationCurrency'>
                <Form.Label className='required'>
                  {t('externalSupplier.lbl_Currency')}
                </Form.Label>
                <Form.Select
                  name='currencyCode'
                  required
                  value={
                    formik.values.currencyCode !== undefined
                      ? formik.values.currencyCode
                      : 'EUR'
                  }
                  onChange={formik.handleChange}
                >
                  {currencies &&
                    currencies.map((currency) => (
                      <option value={currency.code}>
                        {currency.symbol} {currency.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <FormGroup className='mb-2' controlId='validationFirstName'>
                <Form.Label className='required'>
                  {t('externalSupplier.lbl_ContactFirstName')}
                </Form.Label>
                <Form.Control
                  name='firstName'
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  placeholder={t('externalSupplier.plh_FirstName')}
                  isInvalid={
                    formik.touched.firstName && !!formik.errors.firstName
                  }
                />{' '}
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.firstName}
                </Form.Control.Feedback>
              </FormGroup>

              <FormGroup className='mb-2' controlId='validationLastName'>
                <Form.Label className='required'>
                  {t('externalSupplier.lbl_ContactLastName')}
                </Form.Label>
                <Form.Control
                  name='lastName'
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  placeholder={t('externalSupplier.plh_LastName')}
                  isInvalid={
                    formik.touched.lastName && !!formik.errors.lastName
                  }
                />{' '}
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.lastName}
                </Form.Control.Feedback>
              </FormGroup>

              <FormGroup className='mb-2' controlId='validationEmail'>
                <Form.Label className='required'>
                  {t('externalSupplier.lbl_ContactEmail')}
                </Form.Label>
                <Form.Control
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  type='email'
                  placeholder={t('externalSupplier.plh_Email')}
                  isInvalid={formik.touched.lastName && !!formik.errors.email}
                />{' '}
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.email}
                </Form.Control.Feedback>
              </FormGroup>

              <Form.Group as={Row}>
                <Form.Label className='text-start'>
                  {t('userSettings.lbl_Language')}
                  <Form.Select
                    aria-label='Current active language'
                    name='localizationId'
                    value={formik.values.localizationId}
                    onChange={formik.handleChange}
                  >
                    {localizations.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.cultureLongName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Label>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={closeModal}>
              {t('common.btn_Close')}
            </Button>
            <Button variant='btn btn-eleczap' onClick={handleSubmitForm}>
              {t('common.btn_Ok')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

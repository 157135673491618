import { BuyerApi } from 'api/BuyerApi';
import { getSupplierCompanyInfo } from 'components/buyer/supplierInfo/utilsSupplierInfo';
import { useFormik } from 'formik';
import {
  ExternalSupplierModel,
  ExternalSupplierUpdateModel,
} from 'models/buyer/suppliers/ExternalSupplierModels';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

/* Formik init */
export const useFormikHookForExternalSeller = (
  company: SupplierCompanyModel,
  setCompany: (value: SupplierCompanyModel) => void,
  supplierId: number | null
): any => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { t } = useTranslation('components');

  const handleSubmit = async (
    values: ExternalSupplierUpdateModel
  ): Promise<void> => {
    toast.dismiss();
    if (supplierId !== null) {
      await BuyerApi.updateExternalSeller(
        currentUser.companyId,
        supplierId,
        values
      );

      await getSupplierCompanyInfo(setCompany, currentUser, supplierId);

      toast.info(t('companySettings.inf_CompanySettingsSaved'));
    }
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .nullable()
      .required(t('common.err_ValueRequired'))
      .max(70, t('common.err_ValueMax70')),

    shortName: yup.string().nullable().max(70, t('common.err_ValueMax70')),

    email: yup.string().email().nullable().max(70, t('common.err_ValueMax70')),

    addressLine1: yup.string().nullable().max(70, t('common.err_ValueMax70')),

    addressLine2: yup.string().nullable().max(70, t('common.err_ValueMax70')),

    postalCode: yup.string().nullable().max(70, t('common.err_ValueMax70')),

    city: yup.string().nullable().max(70, t('common.err_ValueMax70')),

    state: yup.string().nullable().max(70, t('common.err_ValueMax70')),

    phone: yup.string().nullable().max(70, t('common.err_ValueMax70')),

    webSite: yup.string().nullable().max(70, t('common.err_ValueMax70')),
    registryNumber: yup.string().nullable().max(70, t('common.err_ValueMax70')),
    vatNumber: yup.string().nullable().max(70, t('common.err_ValueMax70')),
  });

  const formik = useFormik<ExternalSupplierModel>({
    initialValues: { ...company },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const updatedCompany: ExternalSupplierUpdateModel = {
        shortName: values.shortName,
        email: values.email,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        postalCode: values.postalCode,
        city: values.city,
        state: values.state,
        countryCode: values.country.code,
        phone: values.phone,
        webSite: values.webSite,
        name: values.name,
        registryNumber: values.registryNumber,
        vatNumber: values.vatNumber,
        margin: values.margin,
        isSubSeller: values.isSubSeller,
      };
      await handleSubmit(updatedCompany);
      formik.setSubmitting(false);
    },
  });

  return formik;
};

import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';

/**
 * Determines if authenticated user can place orders
 * @returns canOrder, explanation
 */
export const useUserCanOrder = () => {
  const authUser = useAuthUser();
  const user = authUser() as LoginResponseUserModel;
  const { t } = useTranslation('components');

  const canOrder = user.hasRightToOrder;
  const explanation = canOrder
    ? ''
    : t('common.inf_ContactAdministratorToActivate');

  return [canOrder, explanation] as const;
};

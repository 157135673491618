import { AppContext } from 'contexts/user/AppContext';
import { useContext } from 'react';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';

export const useFormatMonetaryValue = (
  currencyCode: string,
  value: number,
  decimalPlaces?: number
): string => {
  const { appState } = useContext(AppContext);

  return formatMonetaryValue(currencyCode, value, appState, decimalPlaces);
};

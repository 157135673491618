import {
  OfferComparisonOfferModel,
  OfferComparisonOfferRowExtendedModel,
  OfferComparisonRowModel,
} from 'models/buyer/documents/OfferModels';
import { ExternalSellerOfferRowExtendedModel } from 'models/seller/documents/ExternalSellerOfferModels';
import { OfferOnBehalf } from 'pages/buyer/Offer/OfferOnBehalf';
import { useCallback, useEffect, useState } from 'react';
import { Button, Offcanvas, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  FileEarmarkPlusFill,
  FileEarmarkPostFill,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  rows: OfferComparisonRowModel[];
  projectName: string;
  dealGroupId: number;
  offers: OfferComparisonOfferModel[];
  getComparison: () => Promise<void>;
  supplierIds?: number[];
  offerId?: number;
  offerRows?: OfferComparisonOfferRowExtendedModel[];
  hideLabel?: boolean;
};

export const OfferOnBehalfDrawer = ({
  rows,
  projectName,
  dealGroupId,
  offers,
  getComparison,
  supplierIds,
  offerId,
  hideLabel,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);
  const [mappedRows, setMappedRows] = useState<
    ExternalSellerOfferRowExtendedModel[]
  >([]);

  const { t } = useTranslation('components');

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const mapRows = useCallback(() => {
    const mappingRows: ExternalSellerOfferRowExtendedModel[] = [];

    rows?.forEach((row) => {
      const rowToAdd: ExternalSellerOfferRowExtendedModel = {
        price: null,
        showReplacebleInputs: false,
        isNew: false,
        isReplaced: false,
        productId: row.productId,
        rowNo: row.rowNo,
        productCode: row.productCode,
        name: row.productName,
        quantity: row.quantity,
        targetPrice: row.targetPrice,
        unit: row.unit,
        allowReplacement: row.allowReplacement,
        expectedDelivery: null,
        replacementCode: null,
        replacementName: null,
        replacedProductCode: null,
        replacedProductName: null,
      };

      mappingRows.push(rowToAdd);
    });

    setMappedRows(mappingRows);
  }, [rows]);

  useEffect(() => {
    offerId === undefined && mapRows();
  }, [mapRows, offerId]);

  return (
    <>
      {offerId ? (
        <OverlayTrigger
          trigger={'hover'}
          placement='bottom'
          overlay={<Tooltip>{t('documents.btn_UpdateOfferOnBehalf')}</Tooltip>}
        >
          <Button
            onClick={handleShow}
            variant='btn btn-eleczap'
            className='p-1 d-flex align-items-center'
          >
            <FileEarmarkPostFill className='me-1' size={20} />
            {/* {hideLabel === undefined && t('documents.btn_UpdateOfferOnBehalf')} */}
          </Button>
        </OverlayTrigger>
      ) : (
        offers?.length < (supplierIds?.length ?? 0) && (
          <OverlayTrigger
            placement='bottom'
            trigger={'hover'}
            overlay={<Tooltip>{t('documents.btn_AddOfferOnBehalf')}</Tooltip>}
          >
            <Button
              onClick={handleShow}
              variant='btn btn-eleczap'
              className='p-1 d-flex align-items-center'
              disabled={offers?.length === supplierIds?.length}
            >
              <FileEarmarkPlusFill className='me-1' size={20} />
            </Button>
          </OverlayTrigger>
        )
      )}
      <Offcanvas
        placement='end'
        style={{ minWidth: '800px', maxWidth: '1250px', zIndex: '4000' }}
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {t('documents.btn_AddOfferOnBehalf')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <OfferOnBehalf
            getComparison={getComparison}
            offers={offers}
            rows={mappedRows}
            projectName={projectName}
            dealGroupId={dealGroupId}
            handleClose={handleClose}
            offerId={offerId}
            supplierIds={supplierIds}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

/* Hooks utilized in StocksSharedWithMe.tsx (StocksSharedWithMe settings)*/

import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { StocksSharedWithMeModel } from 'models/shared/stocksSharedWithMe/StocksSharedWithMeModel';
import { useEffect } from 'react';
import { getAllStocksSharedWithMe } from './utilsStocksSharedWithMe';

/* Get all addresses */
export const useGetAllStocksSharedWithMe = (
  currentUser: LoginResponseUserModel,
  setStocks: (values: StocksSharedWithMeModel[]) => void
) => {
  useEffect(() => {
    getAllStocksSharedWithMe(currentUser, setStocks);
  }, [currentUser, currentUser.companyId, currentUser.id, setStocks]);
};

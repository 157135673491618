import { SellerApi } from 'api/SellerApi';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  ExternalSellerOrderConfirmRowModel,
  ExternalSellerOrderRowModel,
} from 'models/seller/documents/ExternalSellerOrderModels';
import { OrderModel } from 'models/seller/documents/OrderModels';

export const getOrder = async (
  currentUser: LoginResponseUserModel,
  orderId: number,
  localizationId: number
): Promise<OrderModel> => {
  const response = await SellerApi.getOrder(
    currentUser.companyId,
    orderId,
    localizationId
  );

  return response;
};

export const createExternalSellerOrderConfirmRows = (
  rows: ExternalSellerOrderRowModel[]
): ExternalSellerOrderConfirmRowModel[] => {
  const finalRows: ExternalSellerOrderConfirmRowModel[] = [];

  for (const row of rows) {
    finalRows.push({
      deliveredCount: row.deliveredCount,
      deliveryDate: row.deliveryDate,
      rowNo: row.rowNo,
      deliveries: row.deliveries,
    });
  }

  return finalRows;
};

import 'bootstrap/dist/css/bootstrap.min.css';
import { BuyerDeals } from 'components/buyer/dealshome/BuyerDeals';

import Card from 'react-bootstrap/Card';
import '../../css/light.css';
import '../../css/site.css';
import './buyerDealsHome.css';

export const BuyerDealsHome = () => {
  return (
    <div className='container-fluid p-2'>
      <Card>
        <BuyerDeals />
      </Card>
      <div className='p-4' />
    </div>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import {
  OrderCreateFromCherryPickOfferModel,
  OrderCreateFromOfferModel,
  OrderModel,
  OrderUpdateDraftModel,
} from 'models/buyer/documents/OrderModels';
import { ProjectOrderCreateOrderModel } from 'models/buyer/projectOrder/ProjectOrderModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';

export const getOrder = async (
  currentUser: LoginResponseUserModel,
  orderId: number,
  localizationId: number
): Promise<OrderModel> => {
  const response = await BuyerApi.getOrder(
    currentUser.companyId,
    orderId,
    localizationId
  );

  return response;
};

export const updateOrder = async (
  currentUser: LoginResponseUserModel,
  order: OrderUpdateDraftModel,
  orderId: number
): Promise<void> => {
  await BuyerApi.updateOrder(currentUser.companyId, orderId, order);
};

export const createOrder = async (
  currentUser: LoginResponseUserModel,
  order: ProjectOrderCreateOrderModel
): Promise<number> => {
  const response = await BuyerApi.projectOrder.createOrderFromProjectOrder(
    currentUser.companyId,
    order
  );

  return response;
};

export const createOrderFromOffer = async (
  currentUser: LoginResponseUserModel,
  order: OrderCreateFromOfferModel
): Promise<number> => {
  const response = await BuyerApi.createOrderFromOffer(
    currentUser.companyId,
    order
  );

  return response;
};

export const createOrderFromCherryPickOffer = async (
  currentUser: LoginResponseUserModel,
  order: OrderCreateFromCherryPickOfferModel
): Promise<number> => {
  const response = await BuyerApi.createOrderFromCherryPickOffer(
    currentUser.companyId,
    order
  );

  return response;
};

import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { ProductListHeaderSorter } from 'components/shared/products/Product/ProductListHeaderSorter';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { AppContext } from 'contexts/user/AppContext';
import { useAddProductsToProjectInquiry } from 'hooks/useAddProductsToProjectInquiry';
import { useAddProductsToProjectOrder } from 'hooks/useAddProductsToProjectOrder';
import { useGetAmpwiseManufacturers } from 'hooks/useGetAmpwiseManufacturers';
import { useResetQtyForProductList } from 'hooks/useResetQtyForProductList';
import { ManufacturerImagesModel } from 'models/buyer/common/ManufacturerImages';
import { GroupedProductModel } from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { CartFill, Clipboard2Fill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type PropsType = {
  getProducts?: (didCancel: boolean, orderBy: string) => Promise<void>;
  favoriteStockExists: boolean;
  setPageNumber?: (value: number) => void;
  selectedManufacturers: string[];
  setOrderBy?: (value: string) => void;
  setSelectedManufacturers: (value: string[]) => void;
};

export const BuyerProductGridHeader = ({
  getProducts,
  favoriteStockExists,
  setPageNumber,
  setSelectedManufacturers,
  selectedManufacturers,
  setOrderBy,
}: PropsType) => {
  const [currentSort, setCurrentSort] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productListWithQtys, setProductListWithQtys] = useState<
    GroupedProductModel[]
  >([]);
  const [ampwiseManufacturers, setAmpwiseManufacturers] = useState<
    ManufacturerImagesModel[]
  >([]);

  const [expandHeader, setExpandHeader] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const { appState, appContextDispatch } = useContext(AppContext);

  const resetQtyForProductList = useResetQtyForProductList();

  const addProductsToInquiry = useAddProductsToProjectInquiry();
  const addProductsToOrder = useAddProductsToProjectOrder();

  useGetAmpwiseManufacturers(setAmpwiseManufacturers);

  let productClassListHeaderClasses = [
    'buyer-product-class-list-sticky-header',
  ];

  const handleSortASC = async (column: string) => {
    setCurrentSort(column);
    setOrderBy && setOrderBy(column);
  };

  const handleSortDESC = async (column: string) => {
    setCurrentSort(column);
    setOrderBy && setOrderBy(column);
  };

  useEffect(() => {
    const productsWithQtys = appState.currentProductsWithQtys.filter(
      (prod) => prod.quantity !== 0
    );

    setProductListWithQtys(productsWithQtys);
  }, [appState.currentProductsWithQtys]);

  const handleInquireProducts = async () => {
    setIsLoading(true);

    if (productListWithQtys.length === 0) return;

    if (productListWithQtys) {
      await addProductsToInquiry('ProductList', {
        groupedProducts: productListWithQtys,
        importProductsText: null,
      });

      resetQtyForProductList();

      setIsLoading(false);

      SuccessToast(t('projectSettings.inf_ProductAdded'));
    }
    setIsLoading(false);
  };

  const handleOrderProducts = async () => {
    setIsLoading(true);

    if (productListWithQtys.length === 0) return;

    if (productListWithQtys) {
      await addProductsToOrder('ProductList', {
        groupedProducts: productListWithQtys,
        importProductsText: null,
      });

      resetQtyForProductList();

      setIsLoading(false);

      SuccessToast(t('projectSettings.inf_ProductAdded'));
    }
    setIsLoading(false);
  };

  const handleManufacturerSelect = (name: string) => () => {
    let cloned = [...selectedManufacturers];

    var index = cloned.indexOf(name);
    if (index !== -1) {
      cloned.splice(index, 1);
    }

    selectedManufacturers.includes(name)
      ? setSelectedManufacturers(cloned)
      : setSelectedManufacturers([...cloned, name]);
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className='d-flex justify-content-end align-items-center me-2'>
        {
          <div
            style={{
              transitionDuration: '0.2s',
              width: expandHeader ? '1000px' : '0px',
            }}
            className={productClassListHeaderClasses.join(' ')}
          >
            <div className='card-wrapper bg-light shadow mb-0 mx-2'>
              <div className='p-2'>
                <Row>
                  <Col>
                    <div className='d-flex justify-content-center'>
                      <ProductListHeaderSorter
                        handleSortASC={() => handleSortASC('code')}
                        handleSortDESC={() => handleSortDESC('code_desc')}
                        currentSort={currentSort}
                        sortProperty='code'
                        sortTitle={t('productListHeader.lbl_ProductCode')}
                        className='justify-content-end d-flex align-items-center'
                      />

                      <ProductListHeaderSorter
                        handleSortASC={() => handleSortASC('product_class')}
                        handleSortDESC={() =>
                          handleSortDESC('product_class_desc')
                        }
                        currentSort={currentSort}
                        sortProperty='product_class'
                        sortTitle={t('productListHeader.lbl_ProductClass')}
                        className=' justify-content-end d-flex align-items-center'
                      />

                      <Col className='justify-content-center d-flex align-items-end'>
                        <OverlayTrigger
                          placement='bottom'
                          overlay={
                            <Tooltip>{t('sellerSwitch.lbl_Supplier')}</Tooltip>
                          }
                        >
                          <h5>{t('sellerSwitch.lbl_Supplier')}</h5>
                        </OverlayTrigger>
                      </Col>

                      <ProductListHeaderSorter
                        handleSortASC={() => handleSortASC('in_stock')}
                        handleSortDESC={() => handleSortDESC('in_stock_desc')}
                        currentSort={currentSort}
                        sortProperty='in_stock'
                        sortTitle={t('common.lbl_Instock')}
                        style={{
                          paddingLeft: '10px',
                          paddingRight: '5px',
                        }}
                        className='justify-content-center d-flex align-items-end wrap-ellipsis'
                      />

                      <ProductListHeaderSorter
                        handleSortASC={() => handleSortASC('arriving')}
                        handleSortDESC={() => handleSortDESC('arriving_desc')}
                        currentSort={currentSort}
                        sortProperty='arriving'
                        sortTitle={t('common.lbl_Arriving')}
                        style={{
                          paddingLeft: '10px',
                          paddingRight: '5px',
                        }}
                        className='justify-content-center d-flex align-items-center wrap-ellipsis'
                      />

                      <ProductListHeaderSorter
                        handleSortASC={() => handleSortASC('order_step')}
                        handleSortDESC={() => handleSortDESC('order_step_desc')}
                        currentSort={currentSort}
                        sortProperty='order_step'
                        sortTitle={`${t('productListHeader.lbl_OrderStep')}`}
                        style={{
                          paddingLeft: '10px',
                          paddingRight: '5px',
                        }}
                        className='justify-content-center d-flex align-items-center wrap-ellipsis'
                      />

                      <ProductListHeaderSorter
                        handleSortASC={() => handleSortASC('price')}
                        handleSortDESC={() => handleSortDESC('price_desc')}
                        sortProperty='price'
                        currentSort={currentSort}
                        sortTitle={t('productListHeader.lbl_Price')}
                        style={{
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                        className='justify-content-center d-flex align-items-center me-1'
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        }

        {/* <div
          onClick={() => setExpandHeader(!expandHeader)}
          className={
            expandHeader
              ? 'product-grid-header product-grid-header-expanded'
              : 'product-grid-header product-grid-header-unexpanded'
          }
        >
          <BiSort
            size={30}
            className={expandHeader ? 'icon-ez' : 'icon-ez-white'}
          />
        </div> */}

        <div className='maunfacturer-selector-container d-flex flex-row justify-content-between'>
          {appState.buyerCurrentSuppliers?.findIndex(
            (supplier) => supplier.name === 'Ampwise'
          ) !== -1 &&
            ampwiseManufacturers.map((name) => {
              return (
                <div
                  style={{
                    backgroundImage: `url(${name.image})`,
                    backgroundPosition: '50% 50%',
                    backgroundSize: 'contain',
                  }}
                  className={`${
                    selectedManufacturers.includes(name.name)
                      ? 'maunfacturer-selector-active'
                      : 'maunfacturer-selector'
                  }  mx-2 px-2 py-1`}
                  onClick={handleManufacturerSelect(name.name)}
                >
                  {/* <img
                  src={imagUrl}
                  alt={name.name}
                  onError={() => (imagUrl = NoProductImage)}
                /> */}
                </div>
              );
            })}
        </div>

        <div
          style={{ maxWidth: '180px', minWidth: '180px' }}
          className='align-self-center'
        >
          <ButtonGroup className='float-end me-1 '>
            <Button
              disabled={productListWithQtys.length < 1}
              onClick={handleInquireProducts}
              className='btn btn-eleczap me-1 d-flex align-items-center px-1'
              style={{ maxWidth: '80px' }}
            >
              <Clipboard2Fill className='me-1' />{' '}
              <span className='wrap-ellipsis'>
                {t('documents.btn_Inquire')}
              </span>
            </Button>
            <Button
              disabled={productListWithQtys.length < 1}
              onClick={handleOrderProducts}
              className='btn btn-eleczap d-flex align-items-center px-1'
              style={{ maxWidth: '80px' }}
            >
              <span className='wrap-ellipsis'>{t('documents.btn_Order')}</span>{' '}
              <CartFill className='ms-1' />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </>
  );
};

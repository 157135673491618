import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { UserModelById } from 'models/shared/user/UserModelById';

import { UserActivate } from './userStates/UserActivate';
import { UserDeactivate } from './userStates/UserDeactivate';

type ProptTypes = {
  user: UserModelById;
  currentUser: LoginResponseUserModel;
  isDisabled: boolean;
  t: any;
  handleStatusChange: (
    value: boolean,
    user: UserModelById,
    field: string
  ) => Promise<void>;
};

export const UserStates = ({
  user,
  currentUser,
  isDisabled,
  handleStatusChange,
  t,
}: ProptTypes) => {
  return (
    <>
      {user.isActive ? (
        <UserDeactivate
          currentUser={currentUser}
          user={user}
          isDisabled={isDisabled}
          handleStatusChange={handleStatusChange}
          t={t}
        />
      ) : (
        <UserActivate
          user={user}
          isDisabled={isDisabled}
          handleStatusChange={handleStatusChange}
          t={t}
        />
      )}
    </>
  );
};

import { IdentityApi } from 'api/IdentityApi';
import { useGetLocalizations } from 'hooks/useGetLocalizations';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { ChangeEvent, MouseEventHandler, useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { PlayFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import ElecZapLogo from './../../../../media/ElecZap_new_logo.png';
import './customerOnboardingIntro.css';

type PropTypes = {
  nextStep: MouseEventHandler;
  invitationToken: string;
};

export const CustomerOnboardingIntro = ({
  nextStep,
  invitationToken,
}: PropTypes): JSX.Element => {
  const [companyName, setCompanyName] = useState<string>('');
  const [localizations, setLocalizations] = useState<LocalizationModel[]>([]);
  const [localizationId, setLocalizationId] = useState<number>(1);

  const { t, i18n } = useTranslation('components');

  /* Get welcome details */
  useEffect(() => {
    const getWelcomeDetails = async () => {
      const response = await IdentityApi.getCustomerWelcomeDetails(
        invitationToken
      );

      setCompanyName(response.companyName);
      setLocalizationId(response.localizationId);
    };

    invitationToken !== '' && getWelcomeDetails();
  }, [invitationToken, setCompanyName]);

  /* Set UI language */
  useEffect(() => {
    const selectedLng = localizations.find((l) => l.id === localizationId);
    i18n.changeLanguage(selectedLng?.cultureName?.slice(0, 2));
  }, [localizations, localizationId, i18n]);

  useGetLocalizations(setLocalizations);

  const handleLocalizationChange = async (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const localeId = Number.parseInt(event.target.value);
    await IdentityApi.updateCustomerContactLocalization(invitationToken, {
      localizationId: localeId,
    });
    setLocalizationId(localeId);
  };

  return (
    <Card className='onboarding-intro-card'>
      <Card.Body className='text-center'>
        <img className='logo-onboarding' src={ElecZapLogo} alt='eleczap-logo' />
        <h1 className='mt-3'>{t('userOnboarding.hd_WelcomeToElecZap')}</h1>

        <p>
          {t('userOnboarding.lbl_InvitationExplanation', {
            companyName: companyName,
          })}
          <br />
          {t('userOnboarding.inf_Intro2')}
        </p>
        <Form.Group>
          <Form.Label column lg={3} className='text-start'>
            {t('userSettings.lbl_Language')}
            <Form.Select
              aria-label='Current active language'
              value={localizationId}
              onChange={handleLocalizationChange}
            >
              {localizations.map((e, key) => {
                return (
                  <option key={key} value={e.id}>
                    {e.cultureLongName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Label>
        </Form.Group>
        <Button className='btn btn-eleczap float-end mt-3' onClick={nextStep}>
          {t('common.btn_Next')}
          <PlayFill />
        </Button>
      </Card.Body>
    </Card>
  );
};

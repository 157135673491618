import { BuyerRegistrationCompanyInfo } from 'components/shared/onboarding/BuyerRegistration/BuyerRegistrationCompanyInfo/BuyerRegistrationCompanyInfo';
import { BuyerRegistrationContactDetails } from 'components/shared/onboarding/BuyerRegistration/BuyerRegistrationContactDetails/BuyerRegistrationContactDetails';
import { BuyerRegistrationIntro } from 'components/shared/onboarding/BuyerRegistration/BuyerRegistrationIntro';
import { BuyerRegistrationPassword } from 'components/shared/onboarding/BuyerRegistration/BuyerRegistrationPassword/BuyerRegistrationPassword';
import { BuyerRegistrationRequestModel } from 'models/buyer/buyerRegistration/BuyerRegistrationModels';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './buyerRegistrationIntro.css';

export const BuyerRegistrationContainer = (): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);

  const [buyerRegistrationData, setBuyerRegistrationData] =
    useState<BuyerRegistrationRequestModel | null>({
      localizationId: 1,
    } as BuyerRegistrationRequestModel);

  const { t } = useTranslation('components');

  const buyerRegistrationStorageKey = '_eleczapBuyerRegistration';

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const onBoardingStep = () => {
    switch (step) {
      case 1:
        return (
          <BuyerRegistrationIntro
            nextStep={nextStep}
            buyerRegistrationData={buyerRegistrationData}
            setBuyerRegistrationData={setBuyerRegistrationData}
          />
        );

      case 2:
        return (
          <BuyerRegistrationCompanyInfo
            nextStep={nextStep}
            prevStep={prevStep}
            buyerRegistrationData={buyerRegistrationData}
            setBuyerRegistrationData={setBuyerRegistrationData}
            setLogoFile={setLogoFile}
          />
        );

      case 3:
        return (
          <BuyerRegistrationContactDetails
            nextStep={nextStep}
            prevStep={prevStep}
            buyerRegistrationData={buyerRegistrationData}
            setBuyerRegistrationData={setBuyerRegistrationData}
            setProfileImageFile={setProfileImageFile}
          />
        );

      case 4:
        return (
          <BuyerRegistrationPassword
            prevStep={prevStep}
            buyerRegistrationData={buyerRegistrationData}
            setBuyerRegistrationData={setBuyerRegistrationData}
            logo={logoFile}
            profileImage={profileImageFile}
          />
        );

      default:
    }
  };

  const getBuyerRegistrationDataFromStorage = () => {
    const rawData = localStorage.getItem(buyerRegistrationStorageKey);

    if (rawData !== null) {
      setShowModal(true);
    }

    const data = JSON.parse(rawData || 'null');
    setBuyerRegistrationData(data ?? ({} as BuyerRegistrationRequestModel));
  };

  const setBuyerRegistrationDataToStorage = useCallback(() => {
    localStorage.removeItem(buyerRegistrationStorageKey);
    localStorage.setItem(
      buyerRegistrationStorageKey,
      JSON.stringify(buyerRegistrationData)
    );
  }, [buyerRegistrationData]);

  const removeBuyerRegistrationDataFromStorage = () => {
    localStorage.removeItem(buyerRegistrationStorageKey);
  };

  useEffect(() => {
    getBuyerRegistrationDataFromStorage();
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', setBuyerRegistrationDataToStorage);

    return () =>
      window.removeEventListener(
        'beforeunload',
        setBuyerRegistrationDataToStorage
      );
  }, [setBuyerRegistrationDataToStorage]);

  const handleStartFresh = () => {
    setBuyerRegistrationData({} as BuyerRegistrationRequestModel);
    setShowModal(false);
    removeBuyerRegistrationDataFromStorage();
  };

  const handleContinue = () => {
    setShowModal(false);
  };

  return (
    <div className='main main-onboarding'>
      <div className='container-fluid'>
        <div className='p-4 d-flex flex-row'>
          <Card className='card-onboarding card-onboardingChild '>
            <Card.Header>
              <Row className='justify-content-center flex-column'>
                <Col className='col-onboardingSteps'>
                  <div
                    className={`rounded-circle onboardingSteps ${
                      step === 1 ? 'active' : ''
                    }`}
                  >
                    1
                  </div>
                  <h6 className='text'>{t('userOnboarding.hd_Welcome')}</h6>
                </Col>
                <Col className='col-onboardingSteps'>
                  <div
                    className={`rounded-circle onboardingSteps ${
                      step === 2 ? 'active' : ''
                    }`}
                  >
                    2
                  </div>
                  <h6 className='text'>
                    {t('companySettings.hd_CompanyInformation')}
                  </h6>
                </Col>
                <Col className='col-onboardingSteps'>
                  <div
                    className={`rounded-circle onboardingSteps ${
                      step === 3 ? 'active' : ''
                    }`}
                  >
                    3
                  </div>
                  <h6 className='text'>{t('userOnboarding.hd_YourDetails')}</h6>
                </Col>
                <Col className='col-onboardingSteps'>
                  <div
                    className={`rounded-circle onboardingSteps ${
                      step === 4 ? 'active' : ''
                    }`}
                  >
                    4
                  </div>
                  <h6 className='text'>
                    {t('userOnboarding.hd_SetYourPassword')}
                  </h6>
                </Col>
              </Row>
            </Card.Header>
          </Card>
          {onBoardingStep()}
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('buyerRegistration.hd_PreviousSessionRestored')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('buyerRegistration.inf_PreviousSessionRestored')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleStartFresh}>
            {t('buyerRegistration.btn_StartFresh')}
          </Button>
          <Button variant='btn btn-eleczap' onClick={handleContinue}>
            {t('common.btn_Continue')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

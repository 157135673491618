import axios, { AxiosRequestConfig } from 'axios';
import { createdAt, fileBlob, responseBody } from './ApiClient';
import {
  apiResponseInterceptor,
  jwtRequestInterceptor,
} from './ApiInterceptors';

const client = axios.create({ baseURL: '/api/sellers' });
const clientV2 = axios.create({ baseURL: '/api/v2/sellers' });
client.interceptors.response.use(undefined, apiResponseInterceptor);
client.interceptors.request.use(jwtRequestInterceptor);
clientV2.interceptors.response.use(undefined, apiResponseInterceptor);
clientV2.interceptors.request.use(jwtRequestInterceptor);

/** API client for using REST API only for Seller level */
export const SellerApiClient = {
  get: (url: string) => client.get(url).then(responseBody),
  getFile: (url: string) =>
    client
      .get(url, {
        responseType: 'blob',
      } as AxiosRequestConfig)
      .then(fileBlob),
  post: (url: string, body: {}) => client.post(url, body).then(responseBody),
  postCreate: (url: string, body: {}) => client.post(url, body).then(createdAt),
  postCreateV2: (url: string, body: {}) =>
    clientV2.post(url, body).then(createdAt),
  put: (url: string, body: {}) => client.put(url, body).then(responseBody),
  delete: (url: string) => client.delete(url).then(responseBody),
};

import { useFormikHookForBuyerRegistrationPassword } from 'components/shared/onboarding/BuyerRegistration/BuyerRegistrationPassword/hooksBuyerRegistrationPassword';
import { BuyerRegistrationRequestModel } from 'models/buyer/buyerRegistration/BuyerRegistrationModels';
import { MouseEventHandler, useState } from 'react';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import { EyeFill, PlayFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import '../buyerRegistrationIntro.css';

type PropTypes = {
  prevStep: MouseEventHandler;
  buyerRegistrationData: BuyerRegistrationRequestModel | null;
  setBuyerRegistrationData: (value: BuyerRegistrationRequestModel) => void;
  logo: File | null;
  profileImage: File | null;
};

export const BuyerRegistrationPassword = ({
  prevStep,
  buyerRegistrationData,
  setBuyerRegistrationData,
  logo,
  profileImage,
}: PropTypes): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation('components');

  const formik = useFormikHookForBuyerRegistrationPassword(
    buyerRegistrationData,
    setBuyerRegistrationData,
    logo,
    profileImage
  );

  return (
    <Card className='onboarding-intro-card'>
      <Card.Body>
        <h4 className='mb-3'>{t('userOnboarding.hd_SetYourPassword')}</h4>
        <Form>
          <Form.Group className='mb-3' controlId='formSetPassword'>
            <Form.Label className='required'>
              {t('changePassword.lbl_NewPassword')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                value={formik.values.newPassword || ''}
                type={showPassword ? 'text' : 'password'}
                name='newPassword'
                placeholder={t('changePassword.plh_EnterNewPassword')}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.newPassword && !!formik.errors.newPassword
                }
              />
              <InputGroup.Text id='basic-addon1'>
                <EyeFill
                  onMouseDown={() => setShowPassword(true)}
                  onMouseUp={() => setShowPassword(false)}
                />
              </InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback type='invalid'>
              {formik.errors.newPassword}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formConfirmPassword'>
            <Form.Label className='required'>
              {t('changePassword.lbl_ConfirmPassword')}
            </Form.Label>
            <Form.Control
              value={formik.values.confirmNewPassword || ''}
              type='password'
              name='confirmNewPassword'
              onChange={formik.handleChange}
              placeholder={t('changePassword.plh_ConfirmNewPassword')}
              isInvalid={
                formik.touched.confirmNewPassword &&
                !!formik.errors.confirmNewPassword
              }
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.confirmNewPassword}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        <p className='text-muted'>
          {t('changePassword.lbl_NewPasswordExplanation')}
        </p>
        <Button className='btn btn-eleczap float-start' onClick={prevStep}>
          <PlayFill className='iconPlayBack' />
          {t('common.btn_Back')}
        </Button>
        <Button
          className='btn btn-eleczap float-end mt-3'
          onClick={formik.handleSubmit}
        >
          {t('common.btn_Save')}
          <PlayFill />
        </Button>
      </Card.Body>
    </Card>
  );
};

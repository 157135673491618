import { useGetSupplierCompanyInfo } from 'components/buyer/supplierInfo/hooksSupplierInfo';
import { SupplierProfileCard } from 'components/buyer/supplierInfo/SupplierProfileCard';
import { getSupplierCreditInfo } from 'components/buyer/supplierInfo/utilsSupplierInfo';
import { SellerCreditInfoModel } from 'models/buyer/suppliers/SellerCreditInfoModel';
import { SellersCompaniesAndContactsDataModel } from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link45deg } from 'react-bootstrap-icons';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type PropTypes = {
  isSupplierAlreadyActive: () => {
    isActive: boolean;
    activeSupplier: SellersCompaniesAndContactsDataModel;
  } | null;
  setAlreadyExistsShow: (value: boolean) => void;
  alreadyExistsShow: boolean;
  formik: any;
};

export const ExternalSellerAlreadyActive = ({
  isSupplierAlreadyActive,
  setAlreadyExistsShow,
  alreadyExistsShow,
  formik,
}: PropTypes) => {
  const [creditInfo, setCreditInfo] = useState<SellerCreditInfoModel>(
    {} as SellerCreditInfoModel
  );

  const [company, setCompany] = useState<SupplierCompanyModel>(
    {} as SupplierCompanyModel
  );

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const targetSupplier = isSupplierAlreadyActive();

  const targetSupplierExists =
    targetSupplier && targetSupplier.activeSupplier !== undefined;

  useGetSupplierCompanyInfo(
    setCompany,
    currentUser,
    targetSupplier?.activeSupplier?.id || null,
    !alreadyExistsShow
  );

  useEffect(() => {
    const getCreditInfo = async (): Promise<void> => {
      alreadyExistsShow &&
        targetSupplierExists &&
        !targetSupplier.activeSupplier.isExternalSeller &&
        (await getSupplierCreditInfo(
          setCreditInfo,
          currentUser,
          targetSupplier.activeSupplier.id
        ));
    };

    getCreditInfo();
  }, [
    alreadyExistsShow,
    currentUser,
    targetSupplier?.activeSupplier?.id,
    targetSupplier?.activeSupplier?.isExternalSeller,
    targetSupplierExists,
  ]);

  const { t } = useTranslation('components');

  const handleClose = () => {
    setAlreadyExistsShow(false);
    formik.resetForm();
  };

  const popover = targetSupplierExists ? (
    <Popover className='p-0' id='popover-basic'>
      <div className='col-side-menu-supplier-customer'>
        <SupplierProfileCard company={company} creditInfo={creditInfo} />
      </div>
    </Popover>
  ) : (
    <></>
  );

  return (
    <Modal
      show={alreadyExistsShow}
      size='sm'
      id='restore-external-supplier'
      onHide={() => setAlreadyExistsShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('externalSupplier.hd_SupplierAlreadyExists')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className='p-4'>
        {targetSupplierExists &&
        !targetSupplier.activeSupplier.isExternalSeller ? (
          <OverlayTrigger
            key={1}
            trigger='hover'
            overlay={popover}
            placement='left'
          >
            <p>
              {targetSupplierExists && (
                <Trans
                  i18nKey={t('externalSupplier.inf_SupplierAlreadyExists')}
                >
                  {[
                    'We have found ',

                    <span>
                      {!targetSupplier.activeSupplier.isExternalSeller ? (
                        <Link
                          className='link-eleczap'
                          to={`supplier-info?id=${targetSupplier.activeSupplier.id}`}
                          target='_blank'
                        >
                          <span>
                            {targetSupplier.activeSupplier.name} <Link45deg />
                          </span>
                        </Link>
                      ) : (
                        <span className='link-eleczap'>
                          {targetSupplier.activeSupplier.name}
                        </span>
                      )}
                    </span>,
                    ' is already connected and is active in your Customers list. Please select a different name.',
                  ]}
                </Trans>
              )}
            </p>
          </OverlayTrigger>
        ) : (
          <p>
            {targetSupplierExists && (
              <Trans i18nKey={t('externalSupplier.inf_SupplierAlreadyExists')}>
                {[
                  'We have found ',

                  <span>
                    {!targetSupplier.activeSupplier.isExternalSeller ? (
                      <Link
                        className='link-eleczap'
                        to={`supplier-info?id=${targetSupplier.activeSupplier.id}`}
                        target='_blank'
                      >
                        <span>
                          {targetSupplier.activeSupplier.name} <Link45deg />
                        </span>
                      </Link>
                    ) : (
                      <span className='link-eleczap'>
                        {targetSupplier.activeSupplier.name}
                      </span>
                    )}
                  </span>,
                  ' is already connected and is active in your Customers list. Please select a different name.',
                ]}
              </Trans>
            )}
          </p>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          {t('common.btn_Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import {
  DealActive,
  DealDisabled,
  DealFinshed,
  DealLost,
} from 'assets/icons/StatusIcons';
import { BuyerDealsEmptyCard } from 'components/buyer/dealshome/BuyerDealsEmptyCard';
import { SellerDealsCard } from 'components/seller/dealshome/SellerDealsCard';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { AllProjectsDataModel } from 'models/seller/project/ProjectModels';
import { Card, Col, Row } from 'react-bootstrap';
import { BriefcaseFill, FolderFill } from 'react-bootstrap-icons';

const dealStatusMap: {
  [Draft: string]: JSX.Element;
  Lost: JSX.Element;
  Active: JSX.Element;
  Finished: JSX.Element;
} = {
  all: <DealDisabled size={15} className='icon-ez-gray mx-1' />,
  Draft: <BriefcaseFill size={15} className='icon-ez-gray mx-1' />,
  Lost: <DealLost size={15} className='icon-ez-black mx-1' />,
  Active: <DealActive size={15} className='icon-ez-green mx-1' />,
  Finished: <DealFinshed size={15} className='icon-ez-gray mx-1' />,
};
type PropTypes = {
  project: AllProjectsDataModel;
  index: number;
  currentUser: LoginResponseUserModel;
  getProjects: () => Promise<void>;
};

export const SellerDealsRow = ({
  project,
  index,
  currentUser,
  getProjects,
}: PropTypes) => {
  let lastStepSeenAt = 0;

  /* Check what is the last step in the whole deal */

  project.deals.forEach((deal) => {
    let maximumStepFound = 0;
    if (deal.invoices !== null && deal.invoices.length > 0) lastStepSeenAt = 4;
    else if (deal.order !== null && deal.order?.code !== null)
      maximumStepFound = 3;
    else if (deal.offer !== null && deal.offer?.code !== null)
      maximumStepFound = 2;
    else if (deal.inquiry !== null && deal.inquiry?.code !== null)
      maximumStepFound = 1;

    if (maximumStepFound >= lastStepSeenAt) lastStepSeenAt = maximumStepFound;
  });

  /* Check for bulk status */
  let tmpStatus = project.deals[0].dealStatus;
  let multipleStatus = false;

  for (var jndex = 1; jndex < project.deals.length; jndex++) {
    if (project.deals[jndex].dealStatus !== tmpStatus) {
      multipleStatus = true;
      break;
    }
  }

  return (
    <Card.Body className='card-body-dealsRow mb-1'>
      <Row className='px-4 justify-content-between align-items-baseline'>
        <Col>
          <Row className=' flex-nowrap'>
            <Col
              className='d-flex'
              style={{ marginLeft: '-20px' }}
              lg={10}
              md={1}
            >
              <span className='deals-dealname-container'>
                <FolderFill
                  style={{ marginLeft: '-10px' }}
                  size={16}
                  className='icon-ez me-1'
                />
                <h5 className='mx-1'>{project.name}</h5>
              </span>
            </Col>
            <Col lg={2}>
              <span className='filter-eleczap-deals deal-step-status deal-status float-end'>
                {multipleStatus
                  ? dealStatusMap['all']
                  : dealStatusMap[tmpStatus]}
              </span>
            </Col>
          </Row>
        </Col>
        <Col />
        <Col />
        <Col />
        <Col />
      </Row>
      {project.deals.length > 0 ? (
        project.deals.map((deal, index) => (
          <SellerDealsCard
            currentUser={currentUser}
            lastStepSeenAt={lastStepSeenAt}
            dealsDetail={deal}
            getProjects={getProjects}
            index={index}
          />
        ))
      ) : (
        <BuyerDealsEmptyCard creatorName={'No user'} creatorInitials={'NU'} />
      )}
    </Card.Body>
  );
};

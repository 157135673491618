import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { AppContext } from 'contexts/user/AppContext';
import { SellerCreditInfoModel } from 'models/buyer/suppliers/SellerCreditInfoModel';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { useContext } from 'react';
import { Card, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';

type PropTypes = {
  company: SupplierCompanyModel;
  creditInfo: SellerCreditInfoModel;
};

export const SupplierProfileCard = ({ company, creditInfo }: PropTypes) => {
  const { t } = useTranslation('components');

  const { appState } = useContext(AppContext);

  return (
    <Card className='border mb-0'>
      <Card.Body>
        <Row className='justify-content-center'>
          <AvatarWithFallback
            imageUrl={company.logo}
            initials={company.initials}
            size='x-large'
          />
        </Row>

        <Row>
          <h4 className='text-center mt-4'>{company.name}</h4>
        </Row>
        <Row>
          <h5 className='text-center'>
            <a href={`mailto:${company.email}`} className='icon-ez'>
              {company.email}
            </a>
          </h5>
        </Row>

        {creditInfo.paymentTerm !== null && (
          <Row>
            <Row>
              <strong className='icon-ez-gray'>
                <small>{t('creditInfo.lbl_PaymentTerms')}</small>
              </strong>
            </Row>
            <Row>
              <strong>{creditInfo.paymentTerm}</strong>
            </Row>
          </Row>
        )}

        {creditInfo.deliveryTerm !== null && (
          <Row className='mt-2 '>
            <Row>
              <strong className='icon-ez-gray'>
                <small>{t('creditInfo.lbl_DeliveryTerms')}</small>
              </strong>
            </Row>
            <Row>
              <strong>{creditInfo.deliveryTerm}</strong>
            </Row>
          </Row>
        )}

        {!company.isExternalSeller && (
          <Row className='mt-2'>
            <Row className=''>
              <strong className='icon-ez-gray'>
                <small>{t('creditInfo.lbl_TotalCredit')}</small>
              </strong>
            </Row>
            <Row>
              <strong>
                {formatMonetaryValue(
                  creditInfo.currencyCode,
                  creditInfo.limit,
                  appState
                )}
              </strong>
            </Row>
          </Row>
        )}

        {!company.isExternalSeller && (
          <Row className='mt-2'>
            <Row>
              <strong className='icon-ez-gray'>
                <small>{t('creditInfo.lbl_RemainingCredit')}</small>
              </strong>
            </Row>
            <Row>
              <strong>
                {formatMonetaryValue(
                  creditInfo.currencyCode,
                  creditInfo.remainingCredit,
                  appState
                )}
              </strong>
            </Row>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

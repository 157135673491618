/* Functions utilized in DeliveryAddresses.tsx (Delivery Address settings)*/

import { BuyerApi } from 'api/BuyerApi';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  AddressModel,
  AddressModelById,
} from 'models/shared/address/AddressModel';
import { AddressUpdateModel } from 'models/shared/address/AddressUpdateModel';
import { toast } from 'react-toastify';

/* Get all addresses */
export const getAllAddresses = async (
  currentUser: LoginResponseUserModel,
  setAddresses: (values: AddressModel) => void,
  page?: number | undefined,
  searchString?: string | undefined,
  rowsPerPage?: number | undefined,
  sortColumn?: string | undefined
) => {
  const allAddresses: AddressModel = await BuyerApi.getAllDeliveryAddresses(
    currentUser.companyId,
    currentUser.id,
    page,
    searchString,
    rowsPerPage,
    sortColumn
  );

  setAddresses(allAddresses);
};

export const getAddressById = async (
  currentUser: LoginResponseUserModel,
  deliveryAddressId: number
): Promise<AddressModelById> => {
  const deliveryAddress: AddressModelById =
    await BuyerApi.getDeliveryAddressById(
      currentUser.companyId,
      currentUser.id,
      deliveryAddressId
    );

  return deliveryAddress;
};

export const handleSubmit = async (
  currentUser: LoginResponseUserModel,
  values: AddressUpdateModel,
  address: AddressModelById,
  t: any
): Promise<void> => {
  toast.dismiss();
  await BuyerApi.updateDeliveryAddresses(
    currentUser.companyId,
    currentUser.id,
    values,
    address.id
  );
  SuccessToast(t('addressSettings.inf_DeliveryAddressSaved'));
};

import { useQuery } from '@tanstack/react-query';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useMemo } from 'react';
import { useAuthUser } from 'react-auth-kit';

export const useGetBuyerDeals = () => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState } = useContext(AppContext);
  const { data, isLoading, isError, isFetching } = useQuery(
    [
      'deals',
      currentUser?.companyId,
      appState.buyerDealsFilter,
      appState.buyerCurrentSuppliers,
    ],
    appState.getProjects,
    {
      enabled: !!currentUser?.companyId && !!currentUser,
      staleTime: 100 * 60 * 30,
      keepPreviousData: true,
    }
  );

  const value = useMemo(
    () => ({ data, isLoading, isError, isFetching }),
    [data, isLoading, isError, isFetching]
  );

  return value;
};

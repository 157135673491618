import { Api } from 'api/Api';
import { ApplicationSettingsApi } from 'api/ApplicationSettingsApi';
import { AppContext } from 'contexts/user/AppContext';
import { useFormik } from 'formik';
import { useGetUserActiveRole } from 'hooks/useGetUserActiveRole';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { UserModelById } from 'models/shared/user/UserModelById';
import { UserUpdateModel } from 'models/shared/user/UserUpdateModel';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import { Dropdown, DropdownButton, Form, Image, Row } from 'react-bootstrap';
import {
  BoxArrowDownLeft,
  GearFill,
  Globe,
  NintendoSwitch,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AnySellerRole, useRBAC } from '../hooks/useRBAC';

type SettingsDropdownButtonProps = {
  initials: string | undefined;
  profileImage: string | undefined;
};

export const BuyerSettingsDropdownButton = ({
  initials,
  profileImage,
}: SettingsDropdownButtonProps) => {
  const { t, i18n } = useTranslation('components');
  const signOut = useSignOut();
  const isAuthorized = useRBAC(AnySellerRole);

  const [localizations, setLocalizations] = useState<LocalizationModel[]>([]);
  const [user, setUser] = useState<UserModelById>({} as UserModelById);

  const [isAutoCloseDropdown, setIsAutoCoseDropdown] = useState<boolean>(true);

  const { appContextDispatch, appState } = useContext(AppContext);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const setChangedViewToSessionStorage = (view: string) => {
    sessionStorage.setItem('_elecZapUserView', view);
  };

  const setUserProfile = useSetUserProfile();

  const getUserActiveRole = useGetUserActiveRole();

  const navigate = useHistory();

  const signOutBuyer = () => {
    /* Remove stale supplierId for buyer */
    appContextDispatch({
      type: 'SELLER_SET_SUPPLIER',
      supplierId: null,
    });

    // appContextDispatch({
    //   type: 'BUYER_SET_CURRENT_SUPPLIERS',
    //   suppliers: null,
    // });

    signOut();
  };

  /* User details form initial data */
  useEffect(() => {
    const getUser = async () => {
      const user = await Api.getUser(
        currentUser.companyId,
        currentUser.id,
        appState.buyerCurrentSupplierId
      );
      setUser(user);
    };
    getUser();
  }, [currentUser.companyId, currentUser.id, appState.buyerCurrentSupplierId]);

  /* Localization dropdown data */
  useEffect(() => {
    const getLocalizations = async () => {
      const localizations = await ApplicationSettingsApi.getLocalizations();
      setLocalizations(localizations);
    };
    getLocalizations();
  }, []);

  const activeRoleName = getUserActiveRole(user, 'Buyer');

  const handleUserSettingsSubmit = async (user: UserUpdateModel) => {
    toast.dismiss();
    await Api.updateUser(currentUser.companyId, currentUser.id, user);

    const updatedUser = await setUserProfile(
      appState.buyerCurrentSupplierId || null
    );
    setUser(updatedUser);

    const selectedLng = localizations.find(
      (l) => l.id === Number(user.localizationId)
    );
    i18n.changeLanguage(selectedLng?.cultureName?.slice(0, 2));

    toast.info(t('account.inf_UserSettingsSaved'));
  };

  const formik = useFormik<UserModelById>({
    initialValues: { ...user },
    enableReinitialize: true,

    onSubmit: async (values) => {
      const updatedUser: UserUpdateModel = {
        firstName: values.firstName,
        lastName: values.lastName,
        defaultView: values.defaultView,
        isActive: user.isActive,
        linkedInProfile: values.linkedInProfile,
        localizationId: values.localization.id,
        phone: values.phone,
        position: values.position,
        canPlaceOrders: user.canPlaceOrders,
        canSeePrices: user.canSeePrices,
        cancelInvitation: false,
        roleName: activeRoleName,
        email: values.email,
      };
      await handleUserSettingsSubmit(updatedUser);
      formik.setSubmitting(false);
    },
  });

  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(event);
    formik.submitForm();
  };

  return (
    <DropdownButton
      onMouseLeave={() => setIsAutoCoseDropdown(true)}
      autoClose={isAutoCloseDropdown ? true : 'outside'}
      variant='btn btn-eleczap caret-off nav-userSettings buyer-header-initials rounded-circle'
      id='buyerSettings'
      title={
        profileImage ? (
          <Image className='avatar' src={profileImage} roundedCircle />
        ) : (
          initials
        )
      }
    >
      <Dropdown.Item as={Link} className='eleczap-link' to='/buyer/settings'>
        <GearFill className='icon-ez me-2' />{' '}
        {t('buyerSettingsDropdownButton.lbl_Settings')}
      </Dropdown.Item>
      {/* <Dropdown.Item as={Link} className='eleczap-link' to='/buyer/suppliers'>
        <PersonLinesFill className='icon-ez me-2' />{' '}
        {t('buyerSettingsDropdownButton.lbl_Suppliers')}
      </Dropdown.Item> */}
      {isAuthorized && (
        <Dropdown.Item as={Link} className='eleczap-link' to='/seller/deals'>
          <NintendoSwitch className='icon-ez me-2' />{' '}
          {t('buyerSettingsDropdownButton.lbl_SwitchToSeller')}
        </Dropdown.Item>
      )}
      {/*  <Dropdown.Divider />
      <Dropdown.Item onClick={() => navigate.push('/')}>
        <Link className='eleczap-link' to={'/'}>
          {t('buyerSettingsDropdownButton.lbl_QuickGuide')}
        </Link>
      </Dropdown.Item> */}
      <Dropdown.Divider />
      <Dropdown.Item onClick={signOutBuyer}>
        <BoxArrowDownLeft className='icon-ez me-2' />{' '}
        {t('buyerSettingsDropdownButton.lbl_SignOut')}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item onMouseEnter={() => setIsAutoCoseDropdown(false)}>
        <Form.Group as={Row} controlId='validationUserCurrentInterfaceLanguage'>
          <Form.Label column sm={12}>
            <h5>
              <Globe className='icon-ez me-2' />{' '}
              {t('userSettings.lbl_Language')}
            </h5>
            <Form.Select
              aria-label='Current active language'
              name='localization.id'
              value={formik.values.localization?.id}
              onChange={handleLanguageChange}
            >
              {localizations.map((e, key) => {
                return (
                  <option key={key} value={e.id}>
                    {e.cultureLongName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Label>
        </Form.Group>
      </Dropdown.Item>
    </DropdownButton>
  );
};

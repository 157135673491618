import { useCheckIfFavoriteStockExists } from 'hooks/useCheckIfFavoriteStockExists';
import { ProductModel } from 'models/shared/product/ProductModel';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { SellerProductListHeader } from './SellerProductListHeader';
import { SellerProductListTile } from './SellerProductListTile';

type PropsType = {
  items: ProductModel[];
  productDetailRoute: string;
  getProducts: (didCancel: boolean, orderBy: string) => Promise<void>;
  isLoading?: boolean | false;
};

export const SellerProductList = ({
  items,
  productDetailRoute,
  getProducts,
  isLoading,
}: PropsType) => {
  const [favoriteStockExists, setFavoriteStockExists] =
    useState<boolean>(false);
  const ifFavoriteStockExists = useCheckIfFavoriteStockExists();

  useEffect(() => {
    const checkFavStock = async () => {
      setFavoriteStockExists(await ifFavoriteStockExists());
    };

    checkFavStock();
  }, [ifFavoriteStockExists]);

  return (
    <div className='container-fluid px-2 pb-2' style={{ marginTop: '3.8rem' }}>
      <SellerProductListHeader getProducts={getProducts} />
      <div className='pt-4 pb-5'>
        {isLoading ? (
          <h3 className='text-center' style={{ marginTop: '150px' }}>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>...</span>
            </Spinner>
          </h3>
        ) : (
          items !== undefined &&
          items.map((item) => (
            <SellerProductListTile
              favoriteStockExists={favoriteStockExists}
              key={item.id}
              item={item}
              productDetailRoute={productDetailRoute}
            />
          ))
        )}
      </div>
    </div>
  );
};

import { SellerApi } from 'api/SellerApi';
import { FavoriteProductSwitch } from 'components/shared/products/Product/FavoriteProductSwitch';
import { useEffect, useState } from 'react';

type PropTypes = {
  isFavorite: boolean;
  companyId: number;
  userId: number;
  productId: number;
};

export const SellerFavoriteProductSwitch = ({
  isFavorite,
  companyId,
  userId,
  productId,
}: PropTypes) => {
  const [on, setOn] = useState<boolean>(isFavorite);

  useEffect(() => {
    setOn(isFavorite);
  }, [isFavorite, productId]);

  const add = async () => {
    await SellerApi.createFavoriteProduct(companyId, userId, {
      productId: productId,
    });
    setOn(true);
  };

  const remove = async () => {
    await SellerApi.deleteFavoriteProduct(companyId, userId, productId);
    setOn(false);
  };

  return (
    <FavoriteProductSwitch
      isFavorite={on}
      handleAdd={add}
      handleRemove={remove}
    />
  );
};

import {
  getAllSupplierCompanies,
  getAllSuppliers,
  getAllSuppliersAndContacts,
  getAllSuppliersUsers,
} from 'components/buyer/suppliers/utilsSuplliers';
import { SellersCompaniesAndContactsModel } from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { SellersCompaniesAndUsersModel } from 'models/buyer/suppliers/SellersCompanyAndUsersModel';
import { SellersUsersModel } from 'models/buyer/suppliers/SellersUsersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useEffect } from 'react';

export const useGetAllSuppliers = (
  currentUser: LoginResponseUserModel,
  setSuppliers: (values: SellersCompaniesAndUsersModel) => void
) => {
  useEffect(() => {
    getAllSuppliers(currentUser, setSuppliers);
  }, [currentUser, setSuppliers]);
};

export const useGetAllSupplierCompanies = (
  currentUser: LoginResponseUserModel,
  setSuppliers: (values: SellersCompaniesAndUsersModel) => void
) => {
  useEffect(() => {
    getAllSupplierCompanies(currentUser, setSuppliers);
  }, [currentUser, setSuppliers]);
};

export const useGetAllSuppliersUsers = (
  currentUser: LoginResponseUserModel,
  setSuppliersUsers: (values: SellersUsersModel) => void
) => {
  useEffect(() => {
    getAllSuppliersUsers(currentUser, setSuppliersUsers);
  }, [currentUser, setSuppliersUsers]);
};

export const useGetAllSuppliersAndContacts = (
  currentUser: LoginResponseUserModel,
  setSuppliersUsers: (values: SellersCompaniesAndContactsModel) => void,
  page?: number | undefined,
  searchString?: string | undefined,
  rowsPerPage?: number | undefined,
  sortColumn?: string | undefined
) => {
  useEffect(() => {
    getAllSuppliersAndContacts(
      currentUser,
      setSuppliersUsers,
      page,
      searchString,
      rowsPerPage,
      sortColumn
    );
  }, [currentUser, setSuppliersUsers]);
};

import { RowStockLevelModel } from 'models/buyer/common/RowStockLevelModel';
import { StarFill } from 'react-bootstrap-icons';

export const getStockLevelColumns = (
  t: any,
  favoriteStockExists?: boolean,
  itemHasFavoriteStock?: boolean
) => {
  const stockLevelColumns = [
    {
      name: '',
      selector: (row: RowStockLevelModel) => row.stockName,
      cell: (row: RowStockLevelModel, index: number) =>
        index === 0 && favoriteStockExists ? (
          <span className='d-flex align-items-center'>
            <StarFill className='me-1' />
          </span>
        ) : null,

      width: '30px',
      omit: !itemHasFavoriteStock,
    },
    {
      name: '',
      selector: (row: RowStockLevelModel) => row.stockName,
    },
    {
      name: t('common.lbl_Instock'),
      selector: (row: RowStockLevelModel) => row.inStock,
      right: true,
    },
    {
      name: t('common.lbl_Arriving'),
      selector: (row: RowStockLevelModel) => row.arriving,
      cell: (row: RowStockLevelModel) => row.arriving || '',
      right: true,
    },
  ];

  return stockLevelColumns;
};

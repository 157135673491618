import { BuyerApi } from 'api/BuyerApi';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { ExternalSellerContactModel } from 'models/buyer/contacts/ExternalSellerContactsModel';
import { ProjectInquiryModel } from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { getProjectInquiry } from 'pages/buyer/ProjectInquiry/utilsProjectInquiry';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Form, FormCheck, ListGroupItem } from 'react-bootstrap';

type PropTypes = {
  supplier: BuyerCurrentSuppliers;
  isSelected: boolean;
  externalContactId: number | null;
  currentUser: LoginResponseUserModel;
  projectId: number;
  projectInquiryId: number | null;
  setProjectDetails: (value: ProjectInquiryModel) => void;
};

export const ProjectInquirySupplierSelectorOption = ({
  supplier,
  isSelected,
  currentUser,
  externalContactId,
  projectId,
  projectInquiryId,
  setProjectDetails,
}: PropTypes) => {
  const [selected, setSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [externalContacts, setExternalContacts] = useState<
    ExternalSellerContactModel[]
  >([]);

  const [selectedExternalContact, setSelectedExternalContact] = useState<
    number | null
  >(null);

  const { appState } = useContext(AppContext);

  /* Get external contacts */
  useEffect(() => {
    const getExternalContacts = async () => {
      const response = await BuyerApi.getExternalSellerContacts(
        currentUser.companyId,
        supplier.value
      );

      setExternalContacts(response);
      setSelectedExternalContact(externalContactId ?? response[0].id);
    };

    supplier.isExternalSeller && getExternalContacts();
  }, [
    currentUser.companyId,
    externalContactId,
    supplier.isExternalSeller,
    supplier.value,
  ]);

  const toggleSelect = useCallback(
    async (isChecked: boolean) => {
      setIsLoading(true);
      if (isChecked) {
        projectInquiryId &&
          (await BuyerApi.projectInquiry.addProjectInquirySupplier(
            currentUser.companyId,
            projectId,
            projectInquiryId,
            {
              sellerCompanyId: supplier.value,
              externalSellerContactId: selectedExternalContact,
            }
          ));
      } else {
        projectInquiryId &&
          (await BuyerApi.projectInquiry.removeProjectInquirySupplier(
            currentUser.companyId,
            projectId,
            projectInquiryId,
            {
              sellerCompanyId: supplier.value,
              externalSellerContactId: selectedExternalContact,
            }
          ));
      }

      projectInquiryId &&
        (await getProjectInquiry(
          projectId,
          projectInquiryId,
          currentUser.companyId,
          setProjectDetails
        ));

      setSelected(isSelected);
      setIsLoading(false);
    },
    [
      currentUser.companyId,
      isSelected,
      projectId,
      projectInquiryId,
      selectedExternalContact,
      setProjectDetails,
      supplier.value,
    ]
  );

  useEffect(() => {
    const checkIfSupplierStillSelected = async () => {
      const supplierStillActive =
        appState?.buyerCurrentSuppliers?.findIndex(
          (currentSupplier) =>
            currentSupplier.value === supplier.value &&
            currentSupplier.isSelected
        ) !== -1;

      if (!supplierStillActive) {
        await toggleSelect(false);
      }
    };

    checkIfSupplierStillSelected();
  }, [appState?.buyerCurrentSuppliers, supplier.value, toggleSelect]);

  const toggleCheckSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    await toggleSelect(isChecked);
  };

  const toggleClickSelect = async (event: any) => {
    if (!isSelected && event.target.tagName !== 'INPUT') {
      await toggleSelect(!isSelected);
      setSelected(isSelected);
    }
  };

  const handleContactChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    const contactId = parseInt(event.target.value);

    setSelectedExternalContact(contactId);

    projectInquiryId &&
      (await BuyerApi.projectInquiry.addProjectInquirySupplier(
        currentUser.companyId,
        projectId,
        projectInquiryId,
        {
          sellerCompanyId: supplier.value,
          externalSellerContactId: contactId,
        }
      ));
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <ListGroupItem
        onClick={toggleClickSelect}
        disabled={!supplier.isSelected}
        className={
          isSelected
            ? 'project-supplier-option-selected cursor-pointer'
            : 'project-supplier-option cursor-pointer'
        }
      >
        <FormCheck
          className='wrap-ellipsis project-supplier-option-checkbox cursor-pointer'
          type='checkbox'
          label={
            <strong
              className={
                !supplier.isSelected
                  ? 'icon-ez-light-gray'
                  : isSelected
                  ? 'icon-ez-white'
                  : 'icon-ez'
              }
            >
              {supplier.name}
            </strong>
          }
          checked={isSelected && supplier.isSelected}
          onChange={toggleCheckSelect}
        />
        {supplier.isExternalSeller && isSelected && (
          <Form.Select
            style={{
              maxWidth: '200px',
            }}
            onChange={handleContactChange}
            className='border rounded p-0 ps-2 m-0 ms-3 icon-ez'
            value={selectedExternalContact ?? undefined}
          >
            {externalContacts.map((contact) => (
              <option value={contact.id} className='icon-ez'>
                {contact.firstName} {contact.lastName}
              </option>
            ))}
          </Form.Select>
        )}
      </ListGroupItem>
    </>
  );
};

import { StocksMy } from './stocks/StocksMy/StocksMy';
import { StocksSharedWithMe } from './stocks/StocksSharedWithMe/StocksSharedWithMe';
import StocksSharedToPartners from './stocks/StocksSharedToPartners/StocksSharedToPartners';
import { StocksSharedToPartnersModel } from 'models/shared/stocksSharedToPartners/StocksSharedToPartnersModel';

import { useState } from 'react';

export const Stocks = (): JSX.Element => {
  const [stocksSharedToPartners, setStocksSharedToPartners] = useState<
    StocksSharedToPartnersModel[]
  >([]);

  return (
    <>
      <StocksMy setStocksSharedToPartners={setStocksSharedToPartners} />
      <StocksSharedWithMe />
      <StocksSharedToPartners
        stocks={stocksSharedToPartners}
        setStocks={setStocksSharedToPartners}
      />
    </>
  );
};

import { ManufacturerImagesModel } from 'models/buyer/common/ManufacturerImages';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { CountryModel } from 'models/shared/country/CountryModel';
import { CurrencyModel } from 'models/shared/currency/CurrencyModel';
import { NoAuthApiClient } from './NoAuthApiClient';

export const ApplicationSettingsApi = {
  getLocalizations: (): Promise<LocalizationModel[]> =>
    NoAuthApiClient.get('/applicationsettings/localizations'),

  getCountries: (): Promise<CountryModel[]> =>
    NoAuthApiClient.get('/applicationsettings/Countries'),

  getSellerRoles: (): Promise<string[]> =>
    NoAuthApiClient.get('/applicationsettings/SellerRoles'),

  getBuyerRoles: (): Promise<string[]> =>
    NoAuthApiClient.get('/applicationsettings/BuyerRoles'),

  getCurrencies: (): Promise<CurrencyModel[]> =>
    NoAuthApiClient.get('/applicationsettings/Currencies'),

  getAmpwiseManufacturers: (): Promise<string[]> =>
    NoAuthApiClient.get('/applicationsettings/AmpwiseManufacturers'),

  getAmpwiseManufacturerImages: (): Promise<ManufacturerImagesModel[]> =>
    NoAuthApiClient.get('/applicationsettings/ManufacturerImages'),
};

import { BuyerApi } from 'api/BuyerApi';
import { AppContextType } from 'contexts/user/AppContext';
import {
  OfferComparisonModel,
  OfferComparisonOfferExtendModel,
  OfferComparisonOfferRowExtendedModel,
  OfferComparisonRowModel,
} from 'models/buyer/documents/OfferModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ChangeEvent } from 'react';

export const handleSelectPriceChange =
  (
    row: OfferComparisonOfferRowExtendedModel,
    offer: OfferComparisonOfferExtendModel,
    offerComparison: OfferComparisonModel,
    setOfferComparison: (value: OfferComparisonModel) => void
  ) =>
  (event: ChangeEvent<HTMLInputElement>) => {
    const targetIndex = offer.rows.findIndex(
      (extendedRow) => extendedRow.id === row.id
    );

    if (targetIndex === -1) return;

    const clonedRows = [...offer.rows];

    clonedRows[targetIndex].isSelected = event.target.checked;

    const clonedOfferComparison = { ...offerComparison };

    const targetOfferIndex = offerComparison.offers.findIndex(
      (loopedOffer) => loopedOffer.id === offer.id
    );

    if (targetOfferIndex === -1) return;

    clonedOfferComparison.offers[targetOfferIndex].rows = clonedRows;

    setOfferComparison(clonedOfferComparison);
  };

export const handleBulkSelectPriceChange =
  (
    offer: OfferComparisonOfferExtendModel,
    offerComparison: OfferComparisonModel,
    setOfferComparison: (value: OfferComparisonModel) => void
  ) =>
  (event: ChangeEvent<HTMLInputElement>) => {
    const clonedRows = [...offer.rows];

    clonedRows.forEach((row) => (row.isSelected = event.target.checked));

    const clonedOfferComparison = { ...offerComparison };

    const targetOfferIndex = offerComparison.offers.findIndex(
      (loopedOffer) => loopedOffer.id === offer.id
    );

    if (targetOfferIndex === -1) return;

    clonedOfferComparison.offers[targetOfferIndex].rows = clonedRows;

    setOfferComparison(clonedOfferComparison);
  };

export const getAllRowsWithSameCode = (
  row: OfferComparisonOfferRowExtendedModel,
  offerComparison: OfferComparisonModel,
  appState: AppContextType
) => {
  const allPricesWithSameCode: OfferComparisonOfferRowExtendedModel[] = [];

  const selectedSuppliers = appState?.buyerCurrentSuppliers?.filter(
    (supplier) => supplier.isSelected
  );
  const selectedSupplierIds = selectedSuppliers?.map(
    (supplier) => supplier.value
  );

  offerComparison.offers.forEach((offersOffer) => {
    if (selectedSupplierIds?.includes(offersOffer.sellerCompanyId)) {
      const matchedRow = offersOffer.rows.find(
        (offersRow) =>
          offersRow.productCode === row.productCode &&
          offersRow.rowNo === row.rowNo
      );
      matchedRow &&
        allPricesWithSameCode.push({ ...matchedRow, isSelected: false });
    }
  });

  return allPricesWithSameCode;
};

export const getAllRowsWithSameCodeForBestPrice = (
  row: OfferComparisonRowModel,
  offerComparison: OfferComparisonModel,
  appState: AppContextType
) => {
  const allPricesWithSameCode: OfferComparisonOfferRowExtendedModel[] = [];

  const selectedSuppliers = appState?.buyerCurrentSuppliers?.filter(
    (supplier) => supplier.isSelected
  );
  const selectedSupplierIds = selectedSuppliers?.map(
    (supplier) => supplier.value
  );

  offerComparison.offers.forEach((offersOffer) => {
    if (selectedSupplierIds?.includes(offersOffer.sellerCompanyId)) {
      const matchedRow = offersOffer.rows.find(
        (offersRow) => offersRow.productCode === row.productCode
      );
      matchedRow &&
        allPricesWithSameCode.push({ ...matchedRow, isSelected: false });
    }
  });

  return allPricesWithSameCode;
};

export const getExternalContacts = async (
  sellerCompanyId: number,
  currentUser: LoginResponseUserModel
) => {
  const response = await BuyerApi.getExternalSellerContacts(
    currentUser.companyId,
    sellerCompanyId
  );

  return response;
};

export const getBaseForPercent = (
  row: OfferComparisonOfferRowExtendedModel,
  offerComparison: OfferComparisonModel,
  baseForPercentageIsTargetPrice: boolean,
  bestPrice: OfferComparisonOfferRowExtendedModel
) => {
  const inquiryRowIndex = offerComparison.rows.findIndex(
    (inquiryRow) =>
      inquiryRow.productCode === row.productCode ||
      inquiryRow.productCode === row.replacementCode
  );

  const baseForPercent = baseForPercentageIsTargetPrice
    ? offerComparison.rows[inquiryRowIndex]?.targetPrice
    : bestPrice?.priceWithDiscount;

  return baseForPercent;
};

import { RowMOQPriceModel } from 'models/buyer/common/RowMOQPriceModel';

export const getMOQColumns = (
  getFormattedPrice: (moq: RowMOQPriceModel) => string,
  t: any
) => {
  return [
    {
      name: t('common.lbl_Moq'),
      selector: (row: RowMOQPriceModel) => row.moq,
    },
    {
      name: t('projectSettings.lbl_Priceunit'),
      selector: (row: RowMOQPriceModel) => getFormattedPrice(row),
    },
  ];
};

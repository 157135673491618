import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { BrowserHistoryOptions, createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({} as BrowserHistoryOptions);
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${process.env.REACT_APP_APPINSIGHTS_KEY};IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/`,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    disableTelemetry: process.env.NODE_ENV === 'development', // Disable error logging in local dev
  },
});
appInsights.loadAppInsights();

import { FileBlob } from 'api/ApiClient';
import { BuyerApi } from 'api/BuyerApi';
import { SellersCompaniesAndContactsModel } from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { SellersCompaniesAndUsersModel } from 'models/buyer/suppliers/SellersCompanyAndUsersModel';
import { SellersUsersModel } from 'models/buyer/suppliers/SellersUsersModel';
import { HitHorizoneGetSuggestionsResultModel } from 'models/hitHorizonsModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';

/* TODO:" calls are same and needs refactoring" */
export const getAllSuppliers = async (
  currentUser: LoginResponseUserModel,
  setSuppliers: (values: SellersCompaniesAndContactsModel) => void,
  page?: number | undefined,
  searchString?: string | undefined,
  rowsPerPage?: number | undefined,
  sortColumn?: string | undefined
): Promise<void> => {
  const response = await BuyerApi.getSellersCompaniesAndContacts(
    currentUser.companyId,
    page,
    searchString,
    rowsPerPage,
    sortColumn
  );

  setSuppliers(response);
};

export const getAllSuppliersAndContacts = async (
  currentUser: LoginResponseUserModel,
  setSuppliers: (values: SellersCompaniesAndContactsModel) => void,
  page?: number | undefined,
  searchString?: string | undefined,
  rowsPerPage?: number | undefined,
  sortColumn?: string | undefined
): Promise<void> => {
  const response = await BuyerApi.getSellersCompaniesAndContacts(
    currentUser.companyId,
    page,
    searchString,
    rowsPerPage,
    sortColumn
  );

  setSuppliers(response);
};

export const getAllSupplierCompanies = async (
  currentUser: LoginResponseUserModel,
  setSuppliers: (values: SellersCompaniesAndUsersModel) => void,
  page?: number | undefined,
  searchString?: string | undefined,
  rowsPerPage?: number | undefined,
  sortColumn?: string | undefined
): Promise<void> => {
  const response = await BuyerApi.getSellersCompanies(
    currentUser.companyId,
    page,
    searchString,
    rowsPerPage,
    sortColumn
  );

  setSuppliers(response);
};

export const getAllSuppliersUsers = async (
  currentUser: LoginResponseUserModel,
  setSuppliersUsers: (values: SellersUsersModel) => void,
  page?: number | undefined,
  searchString?: string | undefined,
  rowsPerPage?: number | undefined,
  sortColumn?: string | undefined
): Promise<void> => {
  const response = await BuyerApi.getSellersUsers(
    currentUser.companyId,
    page,
    searchString,
    rowsPerPage,
    sortColumn
  );

  setSuppliersUsers(response);
};

export const getSpecificSuppliersUsers = async (
  currentUser: LoginResponseUserModel,
  setSuppliersUsers: (values: SellersUsersModel) => void,
  supplierId: number
): Promise<void> => {
  const response = await BuyerApi.getSellersUsers(currentUser.companyId);

  let cloneData = [...response.data];

  cloneData = cloneData.filter(
    (user) => user.companyId === supplierId && user.initials !== null
  );

  setSuppliersUsers({ ...response, data: cloneData });
};

export const getSupplierPricelistExport = async (
  currentUser: LoginResponseUserModel,
  supplierId: number,
  localizationId: number
): Promise<FileBlob> => {
  const response = await BuyerApi.getSupplierPricelistExport(
    currentUser.companyId,
    supplierId,
    localizationId
  );
  return response;
};

export const mapHitHorizonsSuppliersToOptions = (
  response: HitHorizoneGetSuggestionsResultModel
): {
  value: string;
  label: string;
  CountryCode: string;
}[] => {
  let options: {
    value: string;
    label: string;
    CountryCode: string;
  }[] = [];

  response.Result.forEach((item) => {
    options.push({
      value: item.HitHorizonsId,
      label: item.CompanyName,
      CountryCode: item.CountryCode,
    });
  });

  return options;
};

import { BuyerApi } from 'api/BuyerApi';
import { FavoriteProductSwitch } from 'components/shared/products/Product/FavoriteProductSwitch';
import { AppContext } from 'contexts/user/AppContext';
import { useContext, useEffect, useState } from 'react';

type PropTypes = {
  isFavorite: boolean;
  companyId: number;
  userId: number;
  productId: number;
  sellerId: number;
};

export const BuyerFavoriteProductSwitch = ({
  isFavorite,
  companyId,
  userId,
  productId,
  sellerId,
}: PropTypes) => {
  const [on, setOn] = useState<boolean>(isFavorite);

  const { appState } = useContext(AppContext);

  useEffect(() => {
    setOn(isFavorite);
  }, [isFavorite, productId]);

  const add = async () => {
    await BuyerApi.createFavoriteProduct(companyId, userId, {
      sellerCompanyId: sellerId,
      productId: productId,
    });
    setOn(true);
  };

  const remove = async () => {
    await BuyerApi.deleteFavoriteProduct(companyId, userId, productId);
    setOn(false);
  };

  return (
    <FavoriteProductSwitch
      isFavorite={on}
      handleAdd={add}
      handleRemove={remove}
    />
  );
};

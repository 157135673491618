import { AxiosError, AxiosRequestConfig } from 'axios';
import { ErrorToast } from 'components/shared/toasts/ErrorToast';
import i18n from 'i18next';
import { ToastOptions } from 'react-toastify';

const toastOptions: ToastOptions = {
  autoClose: false,
  closeOnClick: true,
  pauseOnHover: true,
};

export const apiResponseInterceptor = (
  error: AxiosError
): Promise<AxiosError> => {
  const response = error.response;
  if (response) {
    switch (response.status) {
      case 405:
      case 500: {
        ErrorToast(i18n.t('server:error.InternalServerError'), true);
        console.error(`[response error] [${JSON.stringify(error)}]`);
        break;
      }
      case 404: {
        ErrorToast(i18n.t('server:error.NotFound'), true);
        break;
      }
      case 401:
      case 403: {
        ErrorToast(i18n.t('server:error.AccessDenied'), true);
        break;
      }
      case 400: {
        // Server business logic error
        if (response.data?.detail) {
          ErrorToast(i18n.t(`server:error.${response.data.detail}`), true);
        }
        // ASP.NET Core model validation errors
        // NB! End user should never see these, they are for developers only. Should not be translated.
        // Corresponding Yup validation schema on a form must cover all such cases.
        // Invalid data should not be passed to server.
        else if (response.data?.errors) {
          let message: string = '';
          for (const [errorKey, errorArray] of Object.entries(
            response.data?.errors
          )) {
            message = message + `${errorKey}: ${errorArray}\n`;
          }
          ErrorToast(message, true);
        }
        break;
      }
    }
  }

  return Promise.reject(error);
};

export const jwtRequestInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const token = localStorage.getItem('_auth'); // Defined in App.tsx
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
};

export const HOHitHorizonsRequestInterceptor = (APIKey: string) => {
  const hitHorizonsRequestInterceptor = (
    config: AxiosRequestConfig
  ): AxiosRequestConfig => {
    const token = localStorage.getItem('_auth'); // Defined in App.tsx
    if (token) {
      config.headers['Ocp-Apim-Subscription-Key'] = APIKey;
    }
    return config;
  };

  return hitHorizonsRequestInterceptor;
};

import { EditableImageWithFallbackButtons } from 'components/shared/controls/EditableImageWithFallback/EditableImageWithFallbackButtons';
import NoProductImage from 'media/ElecZap_new_logo_grayscale_cropped.png';
import { BuyerProductClassModel } from 'models/buyer/product/BuyerProductClassModel';
import { SellerProductClassModel } from 'models/seller/product/SellerProductClassModel';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type PropsType = {
  item: SellerProductClassModel | BuyerProductClassModel;
  productSubClassesRoute: string;
  handleProductClassImageUpload: (productClassId: number) => void;
  imageReadonly: boolean;
};

export const ProductClassWithSubclassesItem = ({
  item,
  productSubClassesRoute,
  handleProductClassImageUpload,
  imageReadonly
}: PropsType) => {
  const { t } = useTranslation('components');

  return (
    <>
      <Link href={`#${item.code}`} to={`${productSubClassesRoute}/${item.id}`}>
      <EditableImageWithFallbackButtons
              noDelete={true}
              alt={item.code}
              readonly={imageReadonly}
              className='product-class-image p-3 mb-2'
              src={item.image}
              fallback={NoProductImage}
              handleImageUpload={() => { handleProductClassImageUpload(item.id) }}
            />
      </Link>
      <Link
        className='product-class-link'
        to={`${productSubClassesRoute}/${item.id}`}
      >
        {item.name}
      </Link>
      <Link
        className='product-class-qty'
        to={`${productSubClassesRoute}/${item.id}`}
      >
        {`${item.productCount} ${t('common.lbl_Products')}`}
      </Link>
    </>
  );
};

import { useEffect, useState } from 'react';
import './userOnboardingIntro.css';

import { UserOnboardingDetails } from 'components/shared/onboarding/UserOnbaording/UserOnboardingDetails/UserOnboardingDetails';
import { UserOnboardingIntro } from 'components/shared/onboarding/UserOnbaording/UserOnboardingIntro';
import { UserOnboardingPassword } from 'components/shared/onboarding/UserOnbaording/UserOnboardingPassword/UserOnboardingPassword';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const UserOnboardingContainer = (): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const [invitationToken, setInvitationToken] = useState<string>('');
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);

  const { t } = useTranslation('components');

  /* Get invitation token from url */
  useEffect(() => {
    const queryString = window && window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('invitationToken');
    token !== null && setInvitationToken(token);
  }, []);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const onBoardingStep = () => {
    switch (step) {
      case 1:
        return (
          <UserOnboardingIntro
            invitationToken={invitationToken}
            nextStep={nextStep}
            isTermsAccepted={isTermsAccepted}
            setIsTermsAccepted={setIsTermsAccepted}
          />
        );

      case 2:
        return (
          <UserOnboardingDetails
            invitationToken={invitationToken}
            nextStep={nextStep}
            prevStep={prevStep}
            isTermsAccepted={isTermsAccepted}
          />
        );

      case 3:
        return (
          <UserOnboardingPassword
            invitationToken={invitationToken}
            prevStep={prevStep}
          />
        );

      default:
    }
  };

  return (
    <div className='main main-onboarding'>
      <div className='container-fluid'>
        <div className='p-4'>
          <Card className='card-onboarding card-onboardingChild'>
            <Card.Header>
              <Row className='justify-content-center'>
                <Col className='col col-lg-2 col-onboardingSteps'>
                  <div
                    className={`rounded-circle onboardingSteps ${
                      step === 1 ? 'active' : ''
                    }`}
                  >
                    1
                  </div>
                  <h6 className='text'>{t('userOnboarding.hd_Welcome')}</h6>
                </Col>
                <Col className='col col-lg-2 col-onboardingSteps'>
                  <div
                    className={`rounded-circle onboardingSteps ${
                      step === 2 ? 'active' : ''
                    }`}
                  >
                    2
                  </div>
                  <h6 className='text'>{t('userOnboarding.hd_YourDetails')}</h6>
                </Col>
                <Col className='col col-lg-2 col-onboardingSteps'>
                  <div
                    className={`rounded-circle onboardingSteps ${
                      step === 3 ? 'active' : ''
                    }`}
                  >
                    3
                  </div>
                  <h6 className='text'>
                    {t('userOnboarding.hd_SetYourPassword')}
                  </h6>
                </Col>
              </Row>
            </Card.Header>
          </Card>
          {onBoardingStep()}
        </div>
      </div>
    </div>
  );
};

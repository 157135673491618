import { AvatarWithFallback } from 'components/shared/AvatarWithFallback';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Trash, Upload } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

type UserProfileImageUploadProps = {
  profileImage: string | null;
  initials: string;
  handleImageUpload: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleImageDelete: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
};

export const UserProfileImageUpload = ({
  profileImage,
  initials,
  handleImageUpload,
  handleImageDelete,
}: UserProfileImageUploadProps) => {
  const [showOperations, setShowOperations] = useState<boolean>(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { t } = useTranslation('components');

  const handleShowOperations = () => {
    setShowOperations(true);
  };

  const handleHideOperations = () => {
    setShowOperations(false);
  };

  const handleImageDeleteAndClose = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    await handleImageDelete(event);
    handleClose();
  };

  return (
    <>
      <Form.Group className='text-center'>
        <input
          type='file'
          id='profileImage'
          name='profileImage'
          accept='.png, .jpeg, .jpg'
          onChange={handleImageUpload}
          className='inputfile'
        />
        <div
          onMouseEnter={handleShowOperations}
          onMouseLeave={handleHideOperations}
          className={`d-flex flex-row justify-content-center align-items-start`}
        >
          <label
            htmlFor='profileImage'
            className={`btn btn-link ${
              showOperations ? 'visible' : 'invisible'
            }`}
          >
            <Upload className='icon-ez' />
          </label>
          <AvatarWithFallback
            imageUrl={profileImage}
            initials={initials}
            size='x-large'
          />
          <Button
            variant='link'
            onClick={handleShow}
            className={`${
              showOperations && profileImage !== null ? 'visible' : 'invisible'
            }`}
          >
            <Trash className='icon-ez-red' />
          </Button>
        </div>
      </Form.Group>

      <Modal
        size='sm'
        show={show}
        onHide={handleClose}
        id='buyerUserDeactivate'
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('userSettings.hd_DeleteProfileImage')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('userSettings.inf_SureToDeleteProfileImage')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button variant='danger' onClick={handleImageDeleteAndClose}>
            {t('common.btn_Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

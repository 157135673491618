import { CustomerOnboardingCompanyInfo } from 'components/shared/onboarding/CustomerOnboarding/CustomerOnboardingCompanyInfo/CustomerOnboardingCompanyInfo';
import { CustomerOnboardingContactDetails } from 'components/shared/onboarding/CustomerOnboarding/CustomerOnboardingContactDetails/CustomerOnboardingContactDetails';
import { CustomerOnboardingIntro } from 'components/shared/onboarding/CustomerOnboarding/CustomerOnboardingIntro';
import { CustomerOnboardingPassword } from 'components/shared/onboarding/CustomerOnboarding/CustomerOnboardingPassword/CustomerUserOnboardingPassword';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './customerOnboardingIntro.css';

export const CustomerOnboardingContainer = (): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const [invitationToken, setInvitationToken] = useState<string>('');

  const { t } = useTranslation('components');

  /* Get invitation token from url */
  useEffect(() => {
    const queryString = window && window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('invitationToken');
    token !== null && setInvitationToken(token);
  }, []);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const onBoardingStep = () => {
    switch (step) {
      case 1:
        return (
          <CustomerOnboardingIntro
            invitationToken={invitationToken}
            nextStep={nextStep}
          />
        );

      case 2:
        return (
          <CustomerOnboardingCompanyInfo
            invitationToken={invitationToken}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );

      case 3:
        return (
          <CustomerOnboardingContactDetails
            invitationToken={invitationToken}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );

      case 4:
        return (
          <CustomerOnboardingPassword
            invitationToken={invitationToken}
            prevStep={prevStep}
          />
        );

      default:
    }
  };

  return (
    <div className='main main-onboarding'>
      <div className='container-fluid'>
        <div className='p-4'>
          <Card className='card-onboarding card-onboardingChild'>
            <Card.Header>
              <Row className='justify-content-center'>
                <Col className='col-onboardingSteps'>
                  <div
                    className={`rounded-circle onboardingSteps ${
                      step === 1 ? 'active' : ''
                    }`}
                  >
                    1
                  </div>
                  <h6 className='text'>{t('userOnboarding.hd_Welcome')}</h6>
                </Col>
                <Col className='col-onboardingSteps'>
                  <div
                    className={`rounded-circle onboardingSteps ${
                      step === 2 ? 'active' : ''
                    }`}
                  >
                    2
                  </div>
                  <h6 className='text'>
                    {t('companySettings.hd_CompanyInformation')}
                  </h6>
                </Col>
                <Col className='col-onboardingSteps'>
                  <div
                    className={`rounded-circle onboardingSteps ${
                      step === 3 ? 'active' : ''
                    }`}
                  >
                    3
                  </div>
                  <h6 className='text'>{t('userOnboarding.hd_YourDetails')}</h6>
                </Col>
                <Col className='col-onboardingSteps'>
                  <div
                    className={`rounded-circle onboardingSteps ${
                      step === 4 ? 'active' : ''
                    }`}
                  >
                    4
                  </div>
                  <h6 className='text'>
                    {t('userOnboarding.hd_SetYourPassword')}
                  </h6>
                </Col>
              </Row>
            </Card.Header>
          </Card>
          {onBoardingStep()}
        </div>
      </div>
    </div>
  );
};

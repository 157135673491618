import { SellerApi } from 'api/SellerApi';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  CaretDownFill,
  FilterSquare,
  FilterSquareFill,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

type CustomerOption = { value: number; label: string; isDisabled: boolean };

export const SellerCustomersDropdownList = () => {
  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([]);
  const [selectedCustomerOption, setSelectedCustomerOption] =
    useState<CustomerOption | null>();
  const [iconHoverClass, setIconHoverClass] = useState<string>('icon-ez');
  const { t } = useTranslation('components');
  const { appState, appContextDispatch } = useContext(AppContext);

  const allCustomers: CustomerOption = useMemo(() => {
    return {
      value: 0,
      label: t('sellerCustomersDropDownList.opt_AllCustomers'),
      isDisabled: false,
    };
  }, [t]);

  useEffect(() => {
    let didCancel = false;

    const getCustomers = async () => {
      const response = await SellerApi.getBuyersCompanies(
        currentUser.companyId,
        1,
        '',
        500
      );

      const activeCustomers = response.data.filter((c) => c.isActive);
      const options = activeCustomers.map((c) => ({
        value: c.id,
        label: c.name,
        isDisabled: !c.isInvitationCompleted,
      }));

      if (!didCancel) {
        options.splice(0, 0, allCustomers);
        setCustomerOptions(options);
      }
    };
    getCustomers();

    return () => {
      didCancel = true;
    };
  }, [currentUser.companyId, allCustomers]);

  /* Set selected value */
  useEffect(() => {
    if (appState.sellerCurrentCustomerId && customerOptions?.length > 0) {
      const selectedCustomer = customerOptions.find(
        (o) => o.value === appState.sellerCurrentCustomerId
      );

      setSelectedCustomerOption(selectedCustomer || null);

      appContextDispatch({
        type: 'SELLER_SET_CUSTOMER',
        customerId: selectedCustomer?.value || null,
      });
    }
  }, [appContextDispatch, appState.sellerCurrentCustomerId, customerOptions]);

  const handleCustomerChange = (option: CustomerOption | null): void => {
    appContextDispatch({
      type: 'SELLER_SET_CUSTOMER',
      customerId: option?.value || allCustomers.value,
    });
    setSelectedCustomerOption(option);
  };

  // const handleMouseEnter = () => {
  //   setIconHoverClass('seller-customer-dropdown-icon-hover');
  // };

  // const handleMouseLeave = (event: MouseEvent<HTMLDivElement>) => {
  //   setIconHoverClass('icon-ez');
  // };

  return (
    <div className='seller-customer-dropdown-container'>
      <Select
        className='col-sm-1 ms-4 customers-dropdown'
        defaultValue={allCustomers}
        value={selectedCustomerOption}
        onChange={handleCustomerChange}
        noOptionsMessage={() => t('common.lbl_NoOptions')}
        options={customerOptions}
        loadingMessage={() => t('common.lbl_Loading')}
        styles={{
          control: (provided, state) => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            width: '170px',
          }),
          menu: (provided, state) => ({
            ...provided,

            width: '170px',
          }),
        }}
        components={{
          DropdownIndicator: () => (
            <CaretDownFill className={`mx-3 icon-ez cursor-pointer`} />
          ),
          IndicatorSeparator: () => null,
          Control: ({ children, ...rest }) => (
            <components.Control
              className='seller-customer-dropdown-control'
              {...rest}
            >
              {selectedCustomerOption?.value === 0 ||
              selectedCustomerOption?.value === undefined ? (
                <FilterSquare className={iconHoverClass} />
              ) : (
                <FilterSquareFill className={iconHoverClass} />
              )}{' '}
              {children}
            </components.Control>
          ),
        }}
      />
    </div>
  );
};

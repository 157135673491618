import { useQueryClient } from '@tanstack/react-query';
import { BuyerApi } from 'api/BuyerApi';
import { ProjectInquiryHeader } from 'components/buyer/projectInquiry/ProjectInquiryHeader/ProjectInquiryHeader';
import { ProjectInquiryProductsGridGrouped } from 'components/buyer/projectInquiry/ProjectInquiryProductsGrid/ProjectInquiryProductsGridGrouped';
import { ProjectInquirySupplierSelector } from 'components/buyer/projectInquiry/ProjectInquirySupplierSelector/ProjectInquirySupplierSelector';
import { LightTooltip } from 'components/shared/LightTooltip';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import {
  ProjectInquiryModel,
  ProjectInquiryRowModel,
} from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  useFormikHookForProject,
  useGetProjectDetails,
} from 'pages/buyer/ProjectInquiry/hooksProjectInquiry';
import { useCallback, useContext, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Card, Form } from 'react-bootstrap';
import { ClipboardFill, Trash } from 'react-bootstrap-icons';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import './projectInquiry.css';

type UrlParamsType = {
  projectId: string;
  projectInquiryId: string;
};

type PropTypes = {
  passedProjectId?: number | null;
  passedProjectInquiryId?: number | null;
  handleHide?: () => void;
};

export const ProjectInquiry = ({
  passedProjectId,
  passedProjectInquiryId,
  handleHide,
}: PropTypes) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectDetails, setProjectDetails] = useState<ProjectInquiryModel>(
    {} as ProjectInquiryModel
  );

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const queryClient = useQueryClient();

  const { appState, appContextDispatch } = useContext(AppContext);

  const [dataSource, setDataSource] = useState([] as ProjectInquiryRowModel[]);
  const [isError, setIsError] = useState<boolean>(true);

  const [comment, setComment] = useState<string>('');

  const [showDisabledRows, setShowDisabledRows] = useState<boolean>(true);
  const canSeePrices = useUserCanSeePrices();

  const history = useHistory();

  let projectId: number | null = null;
  let projectInquiryId: number | null = null;

  if (passedProjectId && passedProjectInquiryId) {
    projectId = passedProjectId;
    projectInquiryId = passedProjectInquiryId;
  } else {
    projectId = appState?.userProfile?.currentProject?.id ?? null;
    projectInquiryId =
      appState?.userProfile?.currentProject?.inquiryProjectId ?? null;
  }

  const { t } = useTranslation('components');
  const setUserProfile = useSetUserProfile();

  const getDataSource = useCallback(async () => {
    if (
      appState.userProfile?.currentProject.id &&
      appState.userProfile?.currentProject?.inquiryProjectId
    ) {
      const response = await BuyerApi.projectInquiry.getProjectInquiryRows(
        currentUser.companyId,
        appState.userProfile?.currentProject?.inquiryProjectId,
        appState.userProfile?.currentProject.id
      );

      setDataSource(response);

      appContextDispatch({
        type: 'BUYER_SET_CURRENT_INQUIRY_PROJECT_ROWS',
        currentInquiryProjectRows: response.length,
      });
    }
  }, [
    appState.userProfile?.currentProject?.id,
    appState.userProfile?.currentProject?.inquiryProjectId,
    currentUser.companyId,
    appContextDispatch,
  ]);

  useGetProjectDetails(
    projectInquiryId,
    projectId,
    currentUser.companyId,
    setProjectDetails,
    setIsLoading
  );

  const handleInquire = async (): Promise<void> => {
    setIsLoading(true);
    projectInquiryId &&
      projectId &&
      (await BuyerApi.projectInquiry.sendProjectInquiry(currentUser.companyId, {
        projectId: projectId,
        inquiryProjectId: projectInquiryId,
        documentUrlTemplate: `${
          window && window.location.origin
        }/external-seller-inquiry?accessToken={accessToken}`,
      }));

    queryClient.invalidateQueries({ queryKey: ['deals'] });

    await setUserProfile(null);

    handleHide && handleHide();

    setIsLoading(false);

    if (window.location.pathname !== '/buyer/deals') {
      history.push('/buyer/deals');
    }
  };

  const formik = useFormikHookForProject(
    projectDetails,
    setProjectDetails,
    projectId
  );

  const handleDelete = async () => {
    if (
      projectId &&
      window.confirm(`${t('projectSettings.lbl_DeleteProject')}?`)
    ) {
      await BuyerApi.projectInquiry.deleteProjectInquiry(
        currentUser.companyId,
        projectDetails.id,
        projectId
      );

      queryClient.invalidateQueries({ queryKey: ['deals'] });

      await setUserProfile(appState.buyerCurrentSupplierId || null);

      handleHide && handleHide();

      history.replace('/buyer/deals');
      window.location.reload();
    }
  };

  // appState.buyerCurrentSuppliers
  //   ?.filter((supplier) => supplier.isSelected && supplier.isActive)
  //   .forEach((seller) => {
  //     let sum = 0;
  //     let currencyCode = '';

  //     dataSource.forEach((row) => {
  //       row.rows
  //         .filter((r) => r.sellerId === seller.value)
  //         .forEach((prod) => {
  //           if (prod.sellerId === seller.value) {
  //             sum += prod.totalPrice;
  //             currencyCode = prod.moqPrices[0].currencyCode;
  //           }
  //         });
  //     });

  //     const totalSum = sum;

  //     sumForEachSupplier.push({
  //       sellerId: seller.value,
  //       sellerName: seller.name,
  //       totalSum: totalSum,
  //       isExternalSeller: seller.isExternalSeller,
  //       currencyCode,
  //     });
  //   });

  /* If there is at least 1 enabled row with supplier quantity greater than 0 */
  // const productRowsOk = useMemo(() => {
  //   return filteredData(dataSource).some((row) => {
  //     return row.rows.some((r) => !r.isDisabled && r.supplierQuantity > 0);
  //   });
  // }, [dataSource, filteredData]);

  return !isLoading ? (
    <>
      {isLoading && <FullScreenLoader />}
      <div className='container-fluid p-3'>
        {/* <ProjectViewGuide /> */}

        <Card className='px-2'>
          {/* User filter and search */}

          <Card.Body>
            <div
              className='pb-0 mb-0 project-product-grid-container'
              style={{ maxWidth: '1553px', margin: '0 auto' }}
            >
              {projectId && (
                <ProjectInquiryHeader
                  getDataSource={getDataSource}
                  setProjectDetails={setProjectDetails}
                  formik={formik}
                  projectDetails={projectDetails}
                  setShowDisabledRows={setShowDisabledRows}
                  showDisabledRows={showDisabledRows}
                  projectId={projectId}
                />
              )}
            </div>
          </Card.Body>
        </Card>

        <div
          style={{ maxWidth: '1553px', margin: '0 auto' }}
          className='d-flex flex-grow-1 justify-content-center'
        >
          <Card
            style={{ height: 'fit-content' }}
            className='py-2 px-0 pb-3 mb-3 pt-0 project-product-grid-container'
          >
            <div
              style={{ minWidth: '800px' }}
              className='project-inquiry-grid pt-1'
            >
              {projectId && (
                <ProjectInquiryProductsGridGrouped
                  getDataSource={getDataSource}
                  dataSource={dataSource}
                  setDataSource={setDataSource}
                  projectDetails={projectDetails}
                  projectId={projectId}
                />
              )}
            </div>
          </Card>

          {/* {!(passedProjectId && passedProjectInquiryId) && ( */}
          <Card className='py-2 px-0 pb-3 mb-4 pt-0 project-product-grid-container'>
            <div className='d-flex flex-column'>
              <>
                {projectId && projectInquiryId && (
                  <ProjectInquirySupplierSelector
                    suppliers={projectDetails.suppliers}
                    projectId={projectId}
                    projectInquiryId={projectInquiryId}
                    setProjectDetails={setProjectDetails}
                  />
                )}

                <Form.Group className='mb-2 mt-2 px-3' controlId='comments'>
                  <Form.Label>{t('common.lbl_Comments')}</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={4}
                    name='comment'
                    onBlur={formik.handleSubmit}
                    placeholder={t('common.plh_EnterComments')}
                    onChange={formik.handleChange}
                    value={formik.values.comment}
                  />
                </Form.Group>
                <div
                  data-tip
                  data-for='project-inquiry-btn-page'
                  className='mt-1 px-3'
                >
                  <Button
                    style={{ width: '100%' }}
                    disabled={
                      projectDetails.suppliers?.length < 1 ||
                      dataSource?.length < 1
                    }
                    onClick={handleInquire}
                    className='btn btn-eleczap'
                  >
                    <ClipboardFill /> {t('projectSettings.lbl_Inquire')}
                  </Button>
                </div>

                {projectDetails.suppliers?.length < 1 && (
                  <LightTooltip place='left' id='project-inquiry-btn-page'>
                    <span>{t('projectSettings.inf_SelectASupplier')}</span>
                  </LightTooltip>
                )}

                <div className='mt-1 px-3'>
                  <Button
                    style={{ width: '100%' }}
                    onClick={handleDelete}
                    className='btn-outline-danger '
                  >
                    <Trash className='icon-ez-red' /> {t('common.btn_Discard')}
                  </Button>
                </div>
              </>
            </div>
          </Card>
          {/* )} */}
        </div>

        {/* {passedProjectId && passedProjectInquiryId && (
          <Card className='align-items-start flex-row'>
            <div className='flex-grow-1'>
              <ProjectInquirySupplierSelector
                suppliers={projectDetails.suppliers}
                projectId={parseInt(projectId)}
                projectInquiryId={parseInt(projectInquiryId)}
                setProjectDetails={setProjectDetails}
              />

              <Form.Group className='mb-2 mt-2 px-3' controlId='comments'>
                <Form.Label>{t('common.lbl_Comments')}</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  name='comment'
                  onBlur={formik.handleSubmit}
                  placeholder={t('common.plh_EnterComments')}
                  onChange={formik.handleChange}
                  value={formik.values.comment}
                />
              </Form.Group>
            </div>

            <div>
              <div
                style={{ width: '300px' }}
                data-tip
                data-for='project-inquiry-btn-drawer'
                className='mt-1 px-3'
              >
                <Button
                  style={{ width: '100%' }}
                  disabled={
                    projectDetails.suppliers.length < 1 ||
                    dataSource.length < 1 ||
                    isLoading
                  }
                  onClick={handleInquire}
                  className='btn btn-eleczap'
                >
                  <ClipboardFill /> {t('projectSettings.lbl_Inquire')}
                </Button>
              </div>
              {projectDetails.suppliers.length < 1 && (
                <LightTooltip place='left' id='project-inquiry-btn-drawer'>
                  <span>{t('projectSettings.inf_SelectASupplier')}</span>
                </LightTooltip>
              )}

              <div style={{ width: '300px' }} className='mt-1 px-3'>
                <Button
                  style={{ width: '100%' }}
                  onClick={handleDelete}
                  className='btn-outline-danger '
                >
                  <Trash className='icon-ez-red' /> {t('common.btn_Discard')}
                </Button>
              </div>
            </div>
          </Card>
        )} */}
      </div>
    </>
  ) : null;
};

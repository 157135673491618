import { BuyerApi } from 'api/BuyerApi';
import { ProjectHeader } from 'components/buyer/project/ProjectHeader/ProjectHeader';
import { ProjectProductsGridGrouped } from 'components/buyer/project/ProjectProductsGrid/ProjectProductsGridGrouped';
import { ProjectSupplierTotalRow } from 'components/buyer/project/ProjectSupplierTotalRow';
import { getSupplierCreditInfo } from 'components/buyer/supplierInfo/utilsSupplierInfo';
import { AppContext } from 'contexts/user/AppContext';
import { useSetCurrentProjectFromUrl } from 'hooks/useSetCurrentProjectFromUrl';
import { useSetUserProfile } from 'hooks/useSetUserProfile';
import { useUserCanOrder } from 'hooks/useUserCanOrder';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { ProjectViewGuide } from 'introGuides/ProjectViewGuide';
import {
  InquiryCreateForSessionModel,
  InquiryRowsModel,
} from 'models/buyer/documents/InquiryModels';
import {
  OrderCreateForSessionModel,
  OrderRowModel,
} from 'models/buyer/documents/OrderModels';
import { BuyerProjectRowInfoGroupedModel } from 'models/buyer/project/GroupedProjectModels';
import {
  ProjectModel,
  SumForEachSupplier,
} from 'models/buyer/project/ProjectModels';
import { SellerCreditInfoModel } from 'models/buyer/suppliers/SellerCreditInfoModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  useFormikHookForProject,
  useGetProjectDetails,
} from 'pages/buyer/Project/hooksProject';
import { getAllProjectRows } from 'pages/buyer/Project/utilsProject';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Cart, Clipboard, Trash } from 'react-bootstrap-icons';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getUrlParams } from 'utils/getUrlParams';
import './project.css';

export const Project = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectDetails, setProjectDetails] = useState<ProjectModel>(
    {} as ProjectModel
  );
  const [creditInfo, setCreditInfo] = useState<SellerCreditInfoModel>(
    {} as SellerCreditInfoModel
  );
  const [dataSource, setDataSource] = useState(
    [] as BuyerProjectRowInfoGroupedModel[]
  );
  const [isError, setIsError] = useState<boolean>(true);
  const [canOrder, cannotOrderExplanation] = useUserCanOrder();
  const [isQuickOrder, setIsQuickOrder] = useState<boolean>(false);

  const [showDisabledRows, setShowDisabledRows] = useState<boolean>(true);
  const canSeePrices = useUserCanSeePrices();

  const history = useHistory();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const setCurrentProjectFromUrl = useSetCurrentProjectFromUrl();

  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');
  const setUserProfile = useSetUserProfile();

  const getDataSource = useCallback(async () => {
    if (projectDetails.id !== undefined) {
      const data = await getAllProjectRows(
        projectDetails.id,
        appState.buyerCurrentSuppliers,
        currentUser
      );
      setDataSource(data);
    }

    const errorClassName = document.querySelectorAll('.supplier-qty-error');
    setIsError(errorClassName.length !== 0);
  }, [projectDetails.id, appState.buyerCurrentSuppliers, currentUser]);

  const formik = useFormikHookForProject(
    currentUser,
    projectDetails,
    setProjectDetails,
    t
  );

  useEffect(() => {
    setCurrentProjectFromUrl();
  }, []);

  useGetProjectDetails(currentUser, setProjectDetails, setIsLoading);

  /* Get buyer's credit information */
  useEffect(() => {
    const getCreditInfo = async (): Promise<void> => {
      appState.buyerCurrentSupplierId !== null &&
        (await getSupplierCreditInfo(
          setCreditInfo,
          currentUser,
          appState.buyerCurrentSupplierId
        ));
    };

    getCreditInfo();
  }, [appState.buyerCurrentSupplierId, currentUser]);

  const handleInquire = async (): Promise<void> => {
    /* Map project rows to inquiry rows */
    const data = await getAllProjectRows(
      projectDetails.id,
      appState.buyerCurrentSuppliers,
      currentUser
    );

    let inquiries: InquiryCreateForSessionModel[] = [];

    /* Make seperate scope for each seller used in project rows */

    appState.buyerCurrentSuppliers
      ?.filter((supplier) => supplier.isSelected && supplier.isActive)
      .forEach((supplier) => {
        let inquiry: InquiryCreateForSessionModel =
          {} as InquiryCreateForSessionModel;

        inquiry.project = projectDetails;

        /* Get attachment IDs in an array */
        const attachmentIds = projectDetails.attachments.map(
          (attachment) => attachment.id
        );

        inquiry.attachmentIds = attachmentIds;

        const suppliersInquiryRows: InquiryRowsModel[] = [];

        let filteredRowsBySupplier: BuyerProjectRowInfoGroupedModel[] = [];

        for (let idx = 0; idx < data.length; ++idx) {
          const rows = data[idx].rows.filter(
            (r) => r.supplierId === supplier.value
          );
          if (rows.length > 0) {
            filteredRowsBySupplier.push({
              productCode: data[idx].productCode,
              rows: rows,
            });
          }
        }

        filteredRowsBySupplier.forEach((topRow) => {
          topRow.rows.forEach((row) => {
            const priceToInsert =
              row.productId === null
                ? null
                : [...row.moqPrices]
                    .reverse()
                    .find((moq) => row.supplierQuantity >= moq.moq)?.price ||
                  row.moqPrices[0].price;

            const priceWithDiscountToInsert =
              row.productId === null
                ? null
                : [...row.moqPrices]
                    .reverse()
                    .find((moq) => row.supplierQuantity >= moq.moq)?.price ||
                  row.moqPrices[0].price;

            const inquiryRow: InquiryRowsModel = {
              id: row.id,
              currencyCode: creditInfo.currencyCode,
              productId: row.productId,
              rowNo: row.rowNo,
              productCode: row.productCode,
              name: row.name,
              companyId: 1,
              quantity: row.supplierQuantity,
              unit: row.unit,
              price: priceToInsert,
              priceWithDiscount: priceWithDiscountToInsert,
              decimalPlaces: row.moqPrices[0].decimalPlaces,
              targetPrice: null,
              allowReplacement: false,
            };

            inquiryRow.quantity > 0 && suppliersInquiryRows.push(inquiryRow);
          });
        });

        let sum = 0;

        if (isQuickOrder) {
          filteredRowsBySupplier.forEach((row) => {
            row.rows.forEach((r) => {
              sum += r.totalPrice;
            });
          });
        } else {
          sum =
            sumForEachSupplier.find(
              (seller) => seller.sellerId === supplier.value
            )?.totalSum ?? 0;
        }

        inquiry.totalSum = sum;
        inquiry.currencyCode =
          filteredRowsBySupplier[0]?.rows[0]?.moqPrices[0]?.currencyCode;

        inquiry.sellerCompanyId = supplier.value;
        inquiry.sellerCompanyName = supplier.name;

        inquiry.isExternalSeller = supplier.isExternalSeller;

        inquiry.sentId = null;

        inquiry.rows = suppliersInquiryRows;

        inquiry.rows.length > 0 && inquiries.push(inquiry);
      });

    sessionStorage.removeItem('inquiries_unsent');

    sessionStorage.setItem('inquiries_unsent', JSON.stringify(inquiries));

    history.push(`/buyer/inquiry-unsent`);
  };

  let sumForEachSupplier: SumForEachSupplier[] = [];

  const handleOrder = useCallback(async (): Promise<void> => {
    /* Map project rows to order rows */
    const data = await getAllProjectRows(
      projectDetails.id,
      appState.buyerCurrentSuppliers,
      currentUser
    );

    let orders: OrderCreateForSessionModel[] = [];

    /* Make seperate scope for each seller used in project rows */

    appState.buyerCurrentSuppliers
      ?.filter((supplier) => supplier.isSelected && supplier.isActive)
      .forEach((supplier) => {
        let order: OrderCreateForSessionModel =
          {} as OrderCreateForSessionModel;

        order.project = projectDetails;
        order.paymentTerm = creditInfo.paymentTerm;
        order.deliveryTerm = creditInfo.deliveryTerm;

        /* Get attachment IDs in an array */
        const attachmentIds = projectDetails.attachments.map(
          (attachment) => attachment.id
        );

        order.attachmentIds = attachmentIds;

        let suppliersOrderRows: OrderRowModel[] = [];
        let filteredRowsBySupplier: BuyerProjectRowInfoGroupedModel[] = [];

        for (let idx = 0; idx < data.length; ++idx) {
          const rows = data[idx].rows.filter(
            (r) => r.sellerId === supplier.value
          );
          if (rows.length > 0) {
            filteredRowsBySupplier.push({
              productCode: data[idx].productCode,
              rows: rows,
            });
          }
        }
        // const filteredRowsBySupplier = data.filter(
        //   (row) => row.supplierId === supplier.value
        // );

        filteredRowsBySupplier.forEach((topRow) => {
          topRow.rows.forEach((row) => {
            const priceToInsert =
              row.productId === null
                ? null
                : [...row.moqPrices]
                    .reverse()
                    .find((moq) => row.supplierQuantity >= moq.moq)?.price ||
                  row.moqPrices[0].price;

            const priceWithDiscountToInsert =
              row.productId === null
                ? null
                : [...row.moqPrices]
                    .reverse()
                    .find((moq) => row.supplierQuantity >= moq.moq)?.price ||
                  row.moqPrices[0].price;

            const total =
              row.productId === null
                ? null
                : row.supplierQuantity *
                  ([...row.moqPrices]
                    .reverse()
                    .find((moq) => row.supplierQuantity >= moq.moq)?.price ||
                    row.moqPrices[0].price);
            const orderRow: OrderRowModel = {
              id: row.id,
              productId: row.productId,
              rowNo: row.rowNo,
              productCode: row.productCode,
              name: row.name,
              quantity: row.supplierQuantity,
              unit: row.unit,
              price: priceToInsert,
              priceWithDiscount: priceWithDiscountToInsert,

              discount: 0,
              total,
              requestedDeliveryTime: '',
              deliveryDate: '',
              deliveredCount: 0,
              invoicedCount: 0,
              decimalPlaces: row.moqPrices[0].decimalPlaces,
              isReplaced: false,
              initialCode: null,
              initialName: null,
            };
            orderRow.quantity > 0 && suppliersOrderRows.push(orderRow);
          });
        });

        let sum = 0;

        if (isQuickOrder) {
          filteredRowsBySupplier.forEach((row) => {
            row.rows.forEach((x) => {
              sum += x.totalPrice;
            });
          });
        } else {
          sum =
            sumForEachSupplier.find(
              (seller) => seller.sellerId === supplier.value
            )?.totalSum ?? 0;
        }

        order.totalSum = sum;
        order.currencyCode =
          filteredRowsBySupplier[0]?.rows[0].moqPrices[0]?.currencyCode;

        order.sentId = null;

        order.rows = suppliersOrderRows;

        order.isExternalSeller = supplier.isExternalSeller;

        order.sellerCompanyId = supplier.value;
        order.sellerCompanyName = supplier.name;

        order.rows.length > 0 && orders.push(order);
      });

    sessionStorage.removeItem('orders_unsent');

    sessionStorage.setItem('orders_unsent', JSON.stringify(orders));

    isQuickOrder
      ? history.push(`/buyer/order-unsent?isQuickOrder=true`)
      : history.push(`/buyer/order-unsent`);
  }, [
    appState.buyerCurrentSuppliers,
    creditInfo.deliveryTerm,
    creditInfo.paymentTerm,
    currentUser,
    history,
    isQuickOrder,
    projectDetails,
    sumForEachSupplier,
  ]);

  const handleDelete = async () => {
    if (window.confirm(`${t('projectSettings.lbl_DeleteProject')}?`)) {
      await BuyerApi.deleteProject(currentUser.companyId, projectDetails.id);

      setUserProfile(appState.buyerCurrentSupplierId || null);

      history.replace('/buyer/deals');
    }
  };

  appState.buyerCurrentSuppliers
    ?.filter((supplier) => supplier.isSelected && supplier.isActive)
    .forEach((seller) => {
      let sum = 0;
      let currencyCode = '';

      dataSource.forEach((row) => {
        row.rows
          .filter((r) => r.sellerId === seller.value)
          .forEach((prod) => {
            if (prod.sellerId === seller.value) {
              sum += prod.totalPrice;
              currencyCode = prod.moqPrices[0].currencyCode;
            }
          });
      });

      const totalSum = sum;

      sumForEachSupplier.push({
        sellerId: seller.value,
        sellerName: seller.name,
        totalSum: totalSum,
        isExternalSeller: seller.isExternalSeller,
        currencyCode,
        isSubSeller: false,
      });
    });

  /* Check from urlParams if isQuickOrder */
  useEffect(() => {
    const comingFromQuickOrder = getUrlParams('isQuickOrder');

    comingFromQuickOrder && setIsQuickOrder(comingFromQuickOrder === 'true');
  }, []);

  useEffect(() => {
    const goToQuickOrder = async () => {
      if (isQuickOrder && projectDetails.id !== undefined) {
        await handleOrder();
      }
    };

    goToQuickOrder();
  }, [handleOrder, isQuickOrder, projectDetails.id]);

  const filteredData = useCallback(
    (
      data: BuyerProjectRowInfoGroupedModel[]
    ): BuyerProjectRowInfoGroupedModel[] => {
      const filter = data
        .map((x) => {
          return {
            productCode: x.productCode,
            rows: x.rows.filter((x) => showDisabledRows || !x.isDisabled),
          } as BuyerProjectRowInfoGroupedModel;
        })
        .filter((x) => x.rows.length > 0);

      return filter;
    },
    [showDisabledRows]
  );

  /* If there is at least 1 enabled row with supplier quantity greater than 0 */
  const productRowsOk = useMemo(() => {
    return filteredData(dataSource).some((row) => {
      return row.rows.some((r) => !r.isDisabled && r.supplierQuantity > 0);
    });
  }, [dataSource, filteredData]);

  return !isLoading && !isQuickOrder ? (
    <div className='container-fluid p-2'>
      <ProjectViewGuide />

      <Card>
        {/* User filter and search */}

        <Card.Body>
          <div
            className='pb-4 mb-5 project-product-grid-container'
            style={{ maxWidth: '1553px', margin: '0 auto' }}
          >
            <ProjectHeader
              setProjectDetails={setProjectDetails}
              getDataSource={getDataSource}
              formik={formik}
              projectDetails={projectDetails}
              setShowDisabledRows={setShowDisabledRows}
              showDisabledRows={showDisabledRows}
            />

            <ProjectProductsGridGrouped
              getDataSource={getDataSource}
              dataSource={dataSource}
              setDataSource={setDataSource}
              projectDetails={projectDetails}
              filteredData={filteredData}
            />

            {/* Add product Form */}

            {/* <ProjectAddProductForm
              getDataSource={getDataSource}
              projectId={projectDetails.id}
            /> */}

            <Row className='p-3 pe-4 pt-5 justify-content-between align-items-end'>
              <Col lg={6}></Col>
              <Col className='project-totals'>
                {sumForEachSupplier.map(
                  (seller) =>
                    seller.currencyCode !== '' && (
                      <ProjectSupplierTotalRow seller={seller} />
                    )
                )}
                <Row>
                  <Col className='text-end'>
                    {t('common.inf_TotalPriceSubtext')}{' '}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='pe-4 pt-5 p-3 justify-content-between align-items-end'>
              <Col lg={6}>
                {!projectDetails.hasDeals && (
                  <Button
                    onClick={handleDelete}
                    className='btn-outline-danger '
                  >
                    <Trash /> {t('projectSettings.lbl_DeleteProject')}
                  </Button>
                )}
              </Col>

              <Col>
                <div className='class="hstack gap-4 float-end'>
                  <Button
                    disabled={isError || !productRowsOk}
                    onClick={handleInquire}
                    className='btn btn-eleczap'
                  >
                    <Clipboard /> {t('projectSettings.lbl_Inquire')}
                  </Button>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='orderButtonTooltip' hidden={canOrder}>
                        {cannotOrderExplanation}
                      </Tooltip>
                    }
                  >
                    <span>
                      <Button
                        disabled={isError || !canOrder || !productRowsOk}
                        onClick={handleOrder}
                        className='btn btn-eleczap  ms-3'
                      >
                        <Cart /> {t('projectSettings.lbl_Order')}
                      </Button>
                    </span>
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </div>
  ) : null;
};

import dayjs from 'dayjs';
import { useEffect } from 'react';
import GitInfo from 'react-git-info/macro';

const buildDateGreaterThan = (
  latestDate: string | number | Date | dayjs.Dayjs | null | undefined,
  currentDate: string | number | Date | dayjs.Dayjs | null | undefined
) => {
  const momLatestDateTime = dayjs(latestDate);
  const momCurrentDateTime = dayjs(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};

const localStorageKey = 'EZ_GIT_COMMIT_ID';

export const useClearCache = () => {
  useEffect(() => {
    const prevHash = localStorage.getItem(localStorageKey);
    const gitInfo = GitInfo();
    const currentHash = gitInfo.commit.shortHash;

    if (currentHash !== prevHash) {
      refreshCacheAndReload();

      localStorage.setItem(localStorageKey, currentHash);
    }
  }, []);

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    localStorage.clear();
    // delete browser cache and hard reload
    window.location.reload();
  };
};

export const getBuildDate = (epoch: any) => {
  const buildDate = dayjs(epoch).format('DD.MM.YYYY | HH:mm');
  return buildDate;
};

import { utils, writeFileXLSX } from 'xlsx';

interface ExportExcelOffersColumnsModel {
  Code: string | null;
  Name: string | null;
  IsReplaced: boolean;
  ReplacementCode: string | null;
  ReplacementName: string | null;
  ExpectedDelivery: string | null;
  Price: number;
  Quantity: number;
  Total: number;
}

interface ExportExcelInquiryColumnsModel {
  Code: string | null;
  Name: string | null;
  ListPrice: number | null;
  TargetPrice: number | null;
  Quantity: number;
  AllowReplacements: boolean;
}

interface ExportExcelOrderColumnsModel {
  Code: string | null;
  Name: string | null;
  IsReplaced: boolean;
  InitialCode: string | null;
  InitialName: string | null;
  Price: number;
  Quantity: number;
  Total: number;
}

export const handleDownloadOfferExcel = (
  dataSource: any[],
  sheetName: string,
  fileName: string,
  sheets?: { sheetName: string; sheetData: any[] }[]
) => {
  if (sheets) {
    const ws = utils.json_to_sheet(dataSource);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);

    sheets.forEach((sheet) => {
      const ws_s = utils.json_to_sheet(
        sanitizesColumnsForOfferExport(sheet.sheetData)
      );
      utils.book_append_sheet(wb, ws_s, sheet.sheetName);
    });

    writeFileXLSX(wb, `${fileName}.xlsx`);
  } else {
    const ws = utils.json_to_sheet(sanitizesColumnsForOfferExport(dataSource));
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);

    writeFileXLSX(wb, `${fileName}.xlsx`);
  }
};

export const handleDownloadInquiryExcel = (
  dataSource: any[],
  sheetName: string,
  fileName: string,
  sheets?: { sheetName: string; sheetData: any[] }[]
) => {
  if (sheets) {
    const ws = utils.json_to_sheet(dataSource);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);

    sheets.forEach((sheet) => {
      const ws_s = utils.json_to_sheet(
        sanitizesColumnsForInquiryExport(sheet.sheetData)
      );
      utils.book_append_sheet(wb, ws_s, sheet.sheetName);
    });

    writeFileXLSX(wb, `${fileName}.xlsx`);
  } else {
    const ws = utils.json_to_sheet(
      sanitizesColumnsForInquiryExport(dataSource)
    );
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);

    writeFileXLSX(wb, `${fileName}.xlsx`);
  }
};

export const handleDownloadOrderExcel = (
  dataSource: any[],
  sheetName: string,
  fileName: string,
  sheets?: { sheetName: string; sheetData: any[] }[]
) => {
  if (sheets) {
    const ws = utils.json_to_sheet(dataSource);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);

    sheets.forEach((sheet) => {
      const ws_s = utils.json_to_sheet(
        sanitizesColumnsForOrderExport(sheet.sheetData)
      );
      utils.book_append_sheet(wb, ws_s, sheet.sheetName);
    });

    writeFileXLSX(wb, `${fileName}.xlsx`);
  } else {
    const ws = utils.json_to_sheet(sanitizesColumnsForOrderExport(dataSource));
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);

    writeFileXLSX(wb, `${fileName}.xlsx`);
  }
};

const sanitizesColumnsForOfferExport = (
  dataSource: any[]
): ExportExcelOffersColumnsModel[] => {
  const clonedDataSource: ExportExcelOffersColumnsModel[] = [];

  dataSource.forEach((data) => {
    clonedDataSource.push({
      Code: data.productCode,
      Name: data.productName ?? data.name,
      IsReplaced: data.isReplaced,
      ReplacementCode: data.replacementCode ?? data.replacedProductCode,
      ReplacementName: data.replacementName ?? data.replacedProductName,
      ExpectedDelivery: data.expectedDelivery,
      Price: data.priceWithDiscount,
      Quantity: data.quantity ?? data.qty,
      Total: data.rowTotal ?? data.total,
    });
  });

  return clonedDataSource;
};

const sanitizesColumnsForInquiryExport = (
  dataSource: any[]
): ExportExcelInquiryColumnsModel[] => {
  const clonedDataSource: ExportExcelInquiryColumnsModel[] = [];

  dataSource.forEach((data) => {
    clonedDataSource.push({
      Code: data.productCode,
      Name: data.name,
      ListPrice: data.priceWithDiscount,
      TargetPrice: data.targetPrice,
      Quantity: data.quantity,
      AllowReplacements: false,
    });
  });

  return clonedDataSource;
};

const sanitizesColumnsForOrderExport = (
  dataSource: any[]
): ExportExcelOrderColumnsModel[] => {
  const clonedDataSource: ExportExcelOrderColumnsModel[] = [];

  dataSource.forEach((data) => {
    clonedDataSource.push({
      Code: data.productCode,
      Name: data.name,
      IsReplaced: data.isReplaced,
      Price: data.priceWithDiscount,
      Quantity: data.quantity,
      Total: data.total,
      InitialCode: data.initialCode,
      InitialName: data.initialName,
    });
  });

  return clonedDataSource;
};

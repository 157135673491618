import { IdentityApi } from 'api/IdentityApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { OnboardingProfileImageUpload } from 'components/shared/onboarding/CustomerOnboarding/CustomerOnboardingContactDetails/onboardingProfileImageUpload';
import { CustomerOnboardingDetailsModel } from 'models/shared/customerOnboarding/customerOnboardingDetailsModel';
import { ChangeEvent, MouseEventHandler, useState } from 'react';
import { PlayFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  useFormikHook,
  useGetCompanyDetails,
} from './hooksCustomerOnboardingCompanyInfo';

type PropTypes = {
  invitationToken: string;
  nextStep: () => void;
  prevStep: MouseEventHandler;
};

export const CustomerOnboardingCompanyInfo = ({
  invitationToken,
  nextStep,
  prevStep,
}: PropTypes) => {
  const [company, setCompany] = useState<CustomerOnboardingDetailsModel>(
    {} as CustomerOnboardingDetailsModel
  );

  const [profileImage, setProfileImage] = useState('');

  const { t } = useTranslation('components');

  const formik = useFormikHook(company, invitationToken, nextStep, t);

  useGetCompanyDetails(setCompany, invitationToken);

  const handleLogoImageUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    toast.dismiss();

    if (event.target.files) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        if (selectedFile.size > 500 * 1024) {
          toast.error(t('account.err_ProfileImageSizeTooBig'));
          event.target.value = '';
          return;
        }
        const data = new FormData();
        data.append('file', selectedFile);
        const imageLocation = await IdentityApi.updateCustomerLogo(
          invitationToken,
          data
        );

        setProfileImage(imageLocation);

        toast.info(t('account.inf_ProfileImageUploaded'));
      }
    }
  };

  console.log(formik.errors);

  return (
    <Card className='onboarding-intro-card'>
      <Card.Body>
        <h4 className='mb-3'>{t('companySettings.hd_CompanyInformation')}</h4>

        <Form noValidate>
          <Row>
            <Col lg={2} className='text-lg-center text-sm-center'>
              <div className='mt-3'>
                <OnboardingProfileImageUpload
                  profileImage={profileImage}
                  initials={
                    company.initials ||
                    `${
                      formik.values.name !== null &&
                      formik.values.name !== undefined
                        ? `${formik.values.name
                            .charAt(0)
                            .toUpperCase()}${formik.values.name
                            .charAt(1)
                            .toUpperCase()}`
                        : ''
                    }`
                  }
                  handleImageUpload={handleLogoImageUpload}
                />
              </div>
            </Col>
            <Col className='mx-3'>
              <Form.Group className='mb-4' as={Row} controlId='validationName'>
                <Form.Label className='required'>
                  {t('companySettings.lbl_CompanyName')}
                </Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='name'
                  onChange={formik.handleChange}
                  placeholder={t('companySettings.plh_CompanyName')}
                  value={formik.values.name}
                  disabled
                  isInvalid={formik.touched.name && !!formik.errors.name}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className='mb-4'
                as={Row}
                controlId='validationRegistryNumber'
              >
                <Form.Label className='required'>
                  {t('companySettings.lbl_RegisterNumber')}
                </Form.Label>
                <Form.Control
                  required
                  name='registryNumber'
                  type='text'
                  disabled
                  placeholder={t('companySettings.plh_RegistryNumber')}
                  value={formik.values.registryNumber}
                  isInvalid={
                    formik.touched.registryNumber &&
                    !!formik.errors.registryNumber
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.registryNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className='mb-4'
                as={Row}
                controlId='validationAddressLine1'
              >
                <Form.Label className='required'>
                  {t('addressSettings.lbl_AddressLine1')}
                </Form.Label>
                <Form.Control
                  value={formik.values.addressLine1}
                  onChange={formik.handleChange}
                  required
                  name='addressLine1'
                  type='text'
                  placeholder={t('addressSettings.lbl_AddressLine1')}
                  isInvalid={
                    formik.touched.addressLine1 && !!formik.errors.addressLine1
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.addressLine1}
                </Form.Control.Feedback>
              </Form.Group>
              <Row className='mb-4'>
                <Form.Group
                  as={Col}
                  className='p-0 me-2'
                  controlId='validationPostalCode'
                >
                  <Form.Label className='required'>
                    {t('addressSettings.lbl_PostalCode')}
                  </Form.Label>
                  <Form.Control
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                    type='text'
                    name='postalCode'
                    placeholder={t('addressSettings.plh_PostalCode')}
                    required
                    isInvalid={
                      formik.touched.postalCode && !!formik.errors.postalCode
                    }
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.postalCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className='p-0' controlId='validationCity'>
                  <Form.Label className='required'>
                    {t('addressSettings.lbl_City')}
                  </Form.Label>
                  <Form.Control
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    type='text'
                    name='city'
                    placeholder={t('addressSettings.plh_City')}
                    required
                    isInvalid={formik.touched.city && !!formik.errors.city}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Form.Group className='mb-4' as={Row} controlId='validationPhone'>
                <Form.Label>{t('userSettings.lbl_Phone')}</Form.Label>
                <Form.Control
                  required
                  type='text'
                  placeholder={t('userSettings.plh_Phone')}
                  name='phone'
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.phone && !!formik.errors.phone}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className='mb-4'
                as={Row}
                controlId='validationWebSite'
              >
                <Form.Label>{t('companySettings.lbl_Website')}</Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='webSite'
                  placeholder={t('companySettings.plh_Website')}
                  value={formik.values.webSite}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.webSite && !!formik.errors.webSite}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.webSite}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className='mx-3'>
              <Form.Group
                className='mb-4'
                as={Row}
                controlId='validationShortName'
              >
                <Form.Label className='required'>
                  {t('companySettings.lbl_ShortName')}
                </Form.Label>
                <Form.Control
                  required
                  type='text'
                  name='shortName'
                  placeholder={t('companySettings.plh_ShortName')}
                  value={formik.values.shortName}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.shortName && !!formik.errors.shortName
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.shortName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className='mb-4'
                as={Row}
                controlId='validationVatNumber'
              >
                <Form.Label>{t('companySettings.lbl_VatNumber')}</Form.Label>
                <Form.Control
                  name='vatNumber'
                  type='text'
                  placeholder={t('companySettings.plh_VatNumber')}
                  value={formik.values.vatNumber}
                  isInvalid={
                    formik.touched.vatNumber && !!formik.errors.vatNumber
                  }
                />

                <Form.Control.Feedback type='invalid'>
                  {formik.errors.vatNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className='mb-4'
                as={Row}
                controlId='validationAddressLine2'
              >
                <Form.Label>{t('addressSettings.lbl_AddressLine2')}</Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t('addressSettings.plh_AddressLine2')}
                  name='addressLine2'
                  value={formik.values.addressLine2}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.addressLine2 && !!formik.errors.addressLine2
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.addressLine2}
                </Form.Control.Feedback>
              </Form.Group>
              <Row className='mb-4'>
                <Form.Group
                  as={Col}
                  className='p-0 me-2'
                  controlId='validationState'
                >
                  <Form.Label className='required'>
                    {t('addressSettings.lbl_State')}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder={t('addressSettings.plh_State')}
                    name='state'
                    required
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.state && !!formik.errors.state}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.state}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  className='p-0'
                  controlId='validationCountryCode'
                >
                  <Form.Label>{t('addressSettings.lbl_Country')}</Form.Label>
                  <Form.Control
                    name='country.code'
                    disabled
                    value={formik.values.country?.shortName}
                  />
                </Form.Group>
              </Row>
              <Form.Group className='mb-4' as={Row} controlId='validationEmail'>
                <Form.Label className='required'>
                  {t('userSettings.lbl_Email')}
                </Form.Label>
                <Form.Control
                  required
                  name='email'
                  placeholder={t('userSettings.plh_Email')}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.email && !!formik.errors.email}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>

        <Button className='btn btn-eleczap float-start ms-3' onClick={prevStep}>
          <PlayFill className='iconPlayBack' />
          {t('common.btn_Back')}
        </Button>
        <Button
          className='btn btn-eleczap float-end mt-3'
          onClick={formik.handleSubmit}
        >
          {t('common.btn_Next')}
          <PlayFill />
        </Button>
      </Card.Body>
    </Card>
  );
};

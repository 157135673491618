import {
  ProjectInquiryRowModel,
  ProjectInquiryUpdateRowModel,
} from 'models/buyer/projectInquiry/ProjectInquiryModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { updateProjectInquiryRows } from 'pages/buyer/ProjectInquiry/utilsProjectInquiry';
import { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';

type PropTypes = {
  inputString: string;
  currentUser: LoginResponseUserModel;
  row: ProjectInquiryRowModel;
  projectId: number;
  projectInquiryId: number;
  getDataSource: () => Promise<void>;
  isHovered: boolean;
};

export const CustomSingleEditableProductCode = ({
  inputString,
  currentUser,
  row,
  projectId,
  projectInquiryId,
  getDataSource,
  isHovered,
}: PropTypes) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(inputString);
  }, [inputString]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSaveName = async () => {
    setIsEditing(false);

    let clonedRow = { ...row };

    const rowUpdate: ProjectInquiryUpdateRowModel = {
      requiredQuantity: clonedRow.requiredQuantity,
      productName: clonedRow.productName,
      productCode: value,
      targetPrice: clonedRow.targetPrice,
      allowReplacement: clonedRow.allowReplacement,
    };

    await updateProjectInquiryRows(
      rowUpdate,
      currentUser.companyId,
      projectId,
      projectInquiryId,
      row.id
    );
    await getDataSource();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSaveName();
    }
  };

  return isEditing ? (
    <Form.Control
      onKeyDown={handleKeyDown}
      onBlur={handleSaveName}
      autoFocus
      onChange={handleInputChange}
      value={value}
      className='borderless border-0 wrap-ellipsis'
    />
  ) : (
    <span className='wrap-ellipsis' onClick={() => setIsEditing(true)}>
      {value}
      {isHovered && (
        <PencilSquare
          className='icon-ez ms-2 cursor-pointer'
          onClick={() => setIsEditing(true)}
        />
      )}
    </span>
  );
};

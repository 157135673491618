import dayjs from 'dayjs';
import {
  ExternalSellerOrderDeliveriesModel,
  ExternalSellerOrderRowModel,
} from 'models/seller/documents/ExternalSellerOrderModels';
import { useEffect, useState } from 'react';
import { FormControl, InputGroup, OverlayTrigger } from 'react-bootstrap';
import { XSquare } from 'react-bootstrap-icons';
import DayPicker from 'react-day-picker/DayPicker';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  disabledDays: { from: Date; to: Date };
  delivery: ExternalSellerOrderDeliveriesModel;
  row: ExternalSellerOrderRowModel;
  deliveryIndex: number;
  handleRowDateChange: (
    rowNo: number,
    day: Date | null,
    deliveryIndex: number
  ) => void;
};

export const ExternalSellerOverlayDatePicker = ({
  disabledDays,
  delivery,
  row,
  handleRowDateChange,
  deliveryIndex,
}: PropTypes) => {
  const [show, setShow] = useState<boolean>(false);

  const { t } = useTranslation('components');

  /* Detect click outside the tags-search div */

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const node = document.querySelector(
        `#external-seller-order-row-datepicker-${row.rowNo}`
      );

      if (node && !node.contains(event.target as Node)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [row.rowNo]);

  const popover = (
    <div
      className='bg-light'
      id={`external-seller-order-row-datepicker-${row.rowNo}`}
    >
      <DayPicker
        fromMonth={new Date()}
        disabledDays={disabledDays}
        onDayClick={(day: Date) => {
          handleRowDateChange(row.rowNo, day, deliveryIndex);
          setShow(false);
        }}
      />
    </div>
  );

  return (
    <>
      <OverlayTrigger
        show={show}
        trigger='click'
        placement='right'
        overlay={popover}
      >
        <InputGroup
          data-tip
          data-event='click focus'
          data-for={`external-order-date-tooltip${row.rowNo}`}
        >
          <FormControl
            value={
              delivery.deliveryDate !== null
                ? dayjs(delivery.deliveryDate).format('DD-MM-YYYY')
                : t('common.lbl_DDMMYYYY')
            }
            readOnly
            onClick={() => setShow(!show)}
            style={{ maxWidth: '170px', float: 'right' }}
          />
          <InputGroup.Text
            onClick={() => handleRowDateChange(row.rowNo, null, deliveryIndex)}
            className='bg-light cursor-pointer'
            id='basic-addon1'
          >
            <XSquare className='icon-ez-gray' />
          </InputGroup.Text>
        </InputGroup>
      </OverlayTrigger>
    </>
  );
};

import axios from 'axios';
import { responseBody } from './ApiClient';
import {
  HOHitHorizonsRequestInterceptor,
  apiResponseInterceptor,
} from './ApiInterceptors';

/** API client for using REST API */
export const HitHorizonsApiClient = (APIKey: string) => {
  const client = axios.create({
    baseURL: '/Company',
  });
  client.interceptors.response.use(undefined, apiResponseInterceptor);
  client.interceptors.request.use(HOHitHorizonsRequestInterceptor(APIKey));

  return {
    get: (url: string) => client.get(url).then(responseBody),
  };
};

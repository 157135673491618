import { SellerApi } from 'api/SellerApi';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { ModalAssignContacts } from 'components/seller/customers/ModalAssignContacts/ModalAssignContacts';
import {
  getAvailableCustomersForOnboarding,
  getSellerCustomerContacts,
  mapCustomerContactsToOptions,
  mapCustomerReponseToOptions,
} from 'components/seller/customers/utilsCustomer';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { AvailableCustomersForOnboardingModel } from 'models/seller/customers/AvailableCustomersForOnboardingModel';
import { CreateCustomerInvitationModel } from 'models/seller/customers/CreateCustomerInvitationModel';
import { LocalizationModel } from 'models/shared/LocalizationModel';
import { ChangeEvent, FocusEvent, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

type PropTypes = {
  currentUser: LoginResponseUserModel;
  localizations: LocalizationModel[];
  refreshCustomers: () => Promise<void>;
};

type CustomerOption = {
  value: number;
  label: string;
  registryNumber: string;
  vatNo: string;
  countryName: string;
  countryCode: string;
};

type CustomerContactOption = { value: number; label: string; email: string };

export const ModalInviteCustomer = ({
  currentUser,
  localizations,
  refreshCustomers,
}: PropTypes) => {
  const { t } = useTranslation('components');
  const [show, setShow] = useState(false);
  const [customerQuery, setCustomerQuery] = useState<string>('');
  const [customer, setCustomer] = useState<CustomerOption>(
    {} as CustomerOption
  );
  const [contactOptions, setContactOptions] = useState<CustomerContactOption[]>(
    []
  );
  const [contact, setContact] = useState<CustomerContactOption>(
    {} as CustomerContactOption
  );
  const [localizationId, setLocalizationId] = useState<number>(1);
  const [registryNumber, setRegistryNumber] = useState<string>('');

  const [modalNumber, setModalNumber] = useState<0 | 1>(0);

  const handleClose = () => {
    setCustomerQuery('');
    setCustomer({} as CustomerOption);
    setContactOptions([]);
    setContact({} as CustomerContactOption);
    setRegistryNumber('');
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const loadOptions = async (input: string): Promise<CustomerOption[]> => {
    const response = await getAvailableCustomersForOnboarding(
      currentUser,
      input
    );

    const options = mapCustomerReponseToOptions(response);

    return options;
  };

  const handleCustomerChange = async (
    option: CustomerOption | null
  ): Promise<void> => {
    if (option !== null) {
      setCustomer(option);

      const defaultLocale = getDefaultLocale(option);
      setLocalizationId(defaultLocale);

      const contacts = await getSellerCustomerContacts(
        currentUser,
        option.value
      );

      const mappedContacts = mapCustomerContactsToOptions(contacts);

      setContactOptions(mappedContacts);
      setRegistryNumber(option.registryNumber || '');
      setContact({} as CustomerContactOption);
    }
  };

  const handleContactChange = (option: CustomerContactOption | null): void => {
    option !== null && setContact(option);
  };

  const handleRegistryCodeChange = async (
    event: FocusEvent<HTMLInputElement>
  ): Promise<void> => {
    const query = event.target.value;

    const customerMatched: AvailableCustomersForOnboardingModel[] =
      await getAvailableCustomersForOnboarding(currentUser, null, query);
    if (customerMatched.length < 1) {
      toast.error(
        t('customers.err_RegistryNumberError', { registryNumber: query })
      );

      setRegistryNumber(customer.registryNumber || '');
    } else {
      const mappedCustomers = mapCustomerReponseToOptions(customerMatched);

      handleCustomerChange(mappedCustomers[0]);
      setCustomer(mappedCustomers[0]);
    }
  };

  const handleInviteCustomer = async (contactUserIds: number[]) => {
    const invitation: CreateCustomerInvitationModel = {
      id: customer.value,
      contactId: contact.value,
      activationUrlTemplate: `${
        window && window.location.origin
      }/customer-onboarding?invitationToken={invitationToken}`,
      localizationId: localizationId,
      connectionAcceptUrl: `${
        window && window.location.origin
      }/buyer/suppliers`,
      contactUserIds,
    };

    await SellerApi.createCustomerInvitation(currentUser.companyId, invitation);

    SuccessToast(t('customers.inf_InvitationSent'));

    await refreshCustomers();
    handleClose();
  };

  const getDefaultLocale = (customer: CustomerOption) => {
    if (localizations && customer) {
      const defaultLocale = localizations.find(
        (l) => l.twoLetterISORegionName === customer?.countryCode
      );
      return defaultLocale?.id || 1;
    }
    return 1;
  };

  const handleLocalizationChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setLocalizationId(Number.parseInt(event.target.value));
  };

  const handleGoNextModal = () => {
    setModalNumber(1);
  };

  const disabledNextButton =
    customer.value === undefined ||
    contact.value === undefined ||
    contact.email === null;

  const modalNumber0 = (
    <>
      <div className='btn btn-eleczap float-end' onClick={handleShow}>
        {t('customers.lbl_InviteCustomer')}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('customers.lbl_InviteCustomer')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className='p-2' noValidate>
            <Col className='mb-3'>
              <h5>{t('customers.lbl_SelectCompany')}</h5>
              <Form.Group controlId='validationCompany'>
                <Form.Label>{t('customers.lbl_Company')}</Form.Label>
                <AsyncSelect
                  onInputChange={(newValue: string) =>
                    setCustomerQuery(newValue)
                  }
                  loadingMessage={() => t('common.lbl_Loading')}
                  noOptionsMessage={() =>
                    customerQuery === ''
                      ? t('common.lbl_StartTyping')
                      : t('common.lbl_NoOptions')
                  }
                  value={customer}
                  onChange={handleCustomerChange}
                  loadOptions={AwesomeDebouncePromise(loadOptions, 400)}
                />
              </Form.Group>
              <Form.Group controlId='validationRegistryCode'>
                <Form.Label>{t('customers.lbl_RegistryCode')}</Form.Label>
                <Form.Control
                  required
                  value={registryNumber}
                  type='text'
                  onBlur={handleRegistryCodeChange}
                  onChange={(event) => setRegistryNumber(event.target.value)}
                  placeholder={t('customers.plh_RegistryCode')}
                />
                <Form.Control.Feedback>
                  {t('customers.err_ProvideRegistryCode')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('customers.lbl_VatNo')}</Form.Label>
                <Form.Control
                  disabled
                  value={customer.vatNo || ''}
                  type='text'
                  placeholder='-'
                />
                <Form.Control.Feedback>
                  {t('customers.err_ProvideVatNo')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('customers.lbl_Country')}</Form.Label>
                <Form.Control
                  required
                  disabled
                  value={customer.countryName || ''}
                  type='text'
                  placeholder={t('customers.plh_SelectCompany')}
                />
                <Form.Control.Feedback>
                  {t('customers.err_ProvideCountry')}
                </Form.Control.Feedback>
              </Form.Group>
              <h5 className='mt-4'> {t('customers.lbl_AddContact')}</h5>
              <Form.Group controlId='validationUser'>
                <Form.Label>{t('customers.lbl_Contact')}</Form.Label>
                <Select
                  onChange={handleContactChange}
                  options={contactOptions}
                  value={contact}
                  loadingMessage={() => t('common.lbl_Loading')}
                  noOptionsMessage={() => t('common.lbl_NoOptions')}
                />
              </Form.Group>
              <Form.Group controlId='validationEmail'>
                <Form.Label>{t('customers.lbl_E-mail')}</Form.Label>
                <Form.Control
                  type='email'
                  value={contact.email || ''}
                  required
                  disabled
                  placeholder={t('customers.plh_SelectContact')}
                />
                <Form.Control.Feedback>
                  {t('customers.err_ValidE-mail')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='validationUserCurrentInterfaceLanguage'>
                <Form.Label column sm={12}>
                  {t('userSettings.lbl_Language')}
                  <Form.Select
                    aria-label='Current active language'
                    name='localization.id'
                    value={localizationId}
                    onChange={handleLocalizationChange}
                  >
                    {localizations.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.cultureLongName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Label>
              </Form.Group>
            </Col>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('common.btn_Close')}
          </Button>
          <Button
            disabled={disabledNextButton}
            className='btn btn-eleczap'
            onClick={handleGoNextModal}
          >
            {t('common.btn_Next')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  const modalNumber1 = (
    <ModalAssignContacts
      parentModal={{ modalNumber, setModalNumber, handleClose }}
      preAssignTaskFunction={handleInviteCustomer}
      fromPage='customerInvitation'
      customerId={customer.value}
      show={show}
      setShow={setShow}
    />
  );

  return modalNumber === 0 ? modalNumber0 : modalNumber1;
};

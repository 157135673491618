import { SellerFavoriteProductSwitch } from 'components/seller/products/SellerFavoriteProductSwitch';
import { ImageWithFallback } from 'components/shared/ImageWithFallback';
import { ProductMoqPrice } from 'components/shared/products/Product/ProductMoqPrice';
import { useUserCanSeePrices } from 'hooks/useUserCanSeePrices';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import NoProductImage from 'media/EZ_new_notext_border_grey.png';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductModel } from 'models/shared/product/ProductModel';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getStockLevelColumns } from 'utils/getStockLevelColumns';

type PropsType = {
  item: ProductModel;
  productDetailRoute: string;
  favoriteStockExists: boolean;
};

export const SellerProductListTile = ({
  item,
  productDetailRoute,
  favoriteStockExists,
}: PropsType) => {
  const { t } = useTranslation('components');
  const [expandRow, setExpandRow] = useState<boolean>(true);
  const canSeePrices = useUserCanSeePrices();
  const validateUnitExists = useValidateUnitExists();

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  let productListClasses = ['card-wrapper', 'product-class-list'];

  const heightOfOnePriceStep = 5;

  const stockLevelColumns = getStockLevelColumns(t, favoriteStockExists);

  const popover = (
    <Popover style={{ minWidth: '500px' }} id='popover-stockLevels'>
      <Popover.Header as='h3'>{t('projectSettings.lbl_Stocks')}</Popover.Header>
      <Popover.Body>
        <DataTable columns={stockLevelColumns} data={item.stockLevels} />
      </Popover.Body>
    </Popover>
  );

  let totalStockLevel = 0;
  let totalArriving = 0;

  item.stockLevels.forEach((stockLevel) => {
    totalStockLevel += stockLevel.inStock;
    totalArriving += stockLevel.arriving;
  });

  const narrowViewListTile = (
    <Col>
      <Row>
        <Col
          style={{
            maxWidth: '125px',
          }}
        >
          <Link
            style={{ cursor: 'pointer' }}
            to={`${productDetailRoute}/${item.id}`}
            className='btn-link-bold'
          >
            {item.code}
          </Link>
        </Col>
        <Col
          className='wrap-ellipsis'
          style={{
            maxWidth: '130px',
          }}
        >
          <span>{item.manufacturerName}</span>
        </Col>
        <Col>
          <span>{item.productClasses[0]?.name}</span>
        </Col>
      </Row>

      <Row>
        <Col>
          <h5>{item.name}</h5>
        </Col>

        <Col>
          {item.stockLevels[0] !== undefined && (
            <Row
              className='float-end justify-content-end'
              style={{ width: '600px' }}
            >
              <Col
                className='text-end product-list-col-in-stock'
                style={{
                  maxWidth: '80px',
                }}
              >
                <>
                  {item.stockLevels.length > 1 ? (
                    <OverlayTrigger
                      trigger='hover'
                      placement='auto'
                      overlay={popover}
                    >
                      <span>
                        <h5>
                          <strong className='btn-link-bold'>
                            {totalStockLevel}
                            <small> {validateUnitExists(item.unit)}</small>
                          </strong>
                        </h5>
                      </span>
                    </OverlayTrigger>
                  ) : (
                    <h5>
                      {totalStockLevel}
                      <small> {validateUnitExists(item.unit)}</small>
                    </h5>
                  )}
                </>
              </Col>

              <Col
                className='text-end'
                style={{
                  maxWidth: '80px',
                }}
              >
                <h5>
                  {totalArriving}
                  <small> {validateUnitExists(item.unit)}</small>
                </h5>
              </Col>
              <Col
                className='text-end'
                style={{
                  maxWidth: '120px',
                }}
              >
                <h5>
                  {item.orderStep}
                  <small> / {item.packageSize}</small>{' '}
                  <small> {validateUnitExists(item.unit)}</small>
                </h5>
              </Col>
              <Col
                style={{
                  maxWidth: '70px',
                  height: expandRow
                    ? `${heightOfOnePriceStep * item.moqPrices.length + 5}px`
                    : `${heightOfOnePriceStep * 3 + 5}px`,
                  // overflowY: expandRow ? 'visible' : 'hidden',
                }}
                className='text-end'
              >
                {!expandRow
                  ? item.moqPrices.slice(0, 3).map((moq) => (
                      <h5 key={moq.price}>
                        {moq.moq}
                        <small> {validateUnitExists(item.unit)}</small>
                      </h5>
                    ))
                  : item.moqPrices.map((moq) => (
                      <h5 key={moq.price}>
                        {moq.moq}
                        <small> {validateUnitExists(item.unit)}</small>
                      </h5>
                    ))}
              </Col>

              <Col
                style={{
                  maxWidth: '90px',
                  height: expandRow
                    ? `${heightOfOnePriceStep * item.moqPrices.length + 5}px`
                    : `${heightOfOnePriceStep * 3 + 5}px`,
                  // overflowY: expandRow ? 'visible' : 'hidden',
                }}
                className='text-end product-list-col-prices'
              >
                {canSeePrices(
                  !expandRow
                    ? item.moqPrices
                        .slice(0, 3)
                        .map((moq) => (
                          <ProductMoqPrice
                            key={moq.price}
                            moq={moq}
                            unit={validateUnitExists(item.unit)}
                          />
                        ))
                    : item.moqPrices.map((moq) => (
                        <ProductMoqPrice
                          key={moq.price}
                          moq={moq}
                          unit={validateUnitExists(item.unit)}
                        />
                      ))
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Col>
  );

  return (
    <Card
      style={{
        height: expandRow
          ? `${heightOfOnePriceStep * (item.moqPrices.length - 3) + 160}px`
          : '135px',

        overflowY: 'hidden',
        marginBottom: '.5rem',
      }}
      className={productListClasses.join(' ')}
    >
      <Card.Body className='p-1 mt-2'>
        <Row className='align-items-start'>
          <Col
            style={{ maxWidth: '160px' }}
            className='d-flex align-items-center flex-row'
          >
            <SellerFavoriteProductSwitch
              isFavorite={item.isFavorite}
              companyId={currentUser.companyId}
              userId={currentUser.id}
              productId={item.id}
            />

            <ImageWithFallback
              alt='Product class image'
              className='product-class-list-image ms-0'
              src={item.image}
              fallback={NoProductImage}
            />
          </Col>
          {window && window.innerWidth < 1000 ? (
            narrowViewListTile
          ) : (
            <Col>
              <Row>
                <Col
                  style={{
                    maxWidth: '200px',
                  }}
                >
                  <Link
                    style={{ cursor: 'pointer' }}
                    to={`${productDetailRoute}/${item.id}`}
                    className='btn-link-bold'
                  >
                    {item.code}
                  </Link>
                </Col>
                <Col
                  className='wrap-ellipsis'
                  style={{
                    maxWidth: '150px',
                  }}
                >
                  <span>{item.manufacturerName}</span>
                </Col>
                <Col>
                  <span>{item.productClasses[0]?.name}</span>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h5>{item.name}</h5>
                </Col>

                <Col>
                  {item.stockLevels[0] !== undefined && (
                    <Row
                      className='float-end justify-content-end'
                      style={{ width: '464px' }}
                    >
                      <Col
                        className='text-end product-list-col-in-stock'
                        style={{
                          maxWidth: '130px',
                        }}
                      >
                        <>
                          {item.stockLevels.length > 1 ? (
                            <OverlayTrigger
                              trigger='hover'
                              placement='auto'
                              overlay={popover}
                            >
                              <span>
                                <h5>
                                  <strong className='btn-link-bold'>
                                    {totalStockLevel}
                                    <small>
                                      {' '}
                                      {validateUnitExists(item.unit)}
                                    </small>
                                  </strong>
                                </h5>
                              </span>
                            </OverlayTrigger>
                          ) : (
                            <h5>
                              {totalStockLevel}
                              <small> {validateUnitExists(item.unit)}</small>
                            </h5>
                          )}
                        </>
                      </Col>

                      <Col
                        className='text-end'
                        style={{
                          maxWidth: '80px',
                        }}
                      >
                        <h5>
                          {totalArriving}
                          <small> {validateUnitExists(item.unit)}</small>
                        </h5>
                      </Col>
                      <Col
                        className='text-end'
                        style={{
                          maxWidth: '120px',
                        }}
                      >
                        <h5>
                          {item.orderStep}
                          <small> / {item.packageSize}</small>{' '}
                          <small> {validateUnitExists(item.unit)}</small>
                        </h5>
                      </Col>
                      <Col
                        style={{
                          maxWidth: '70px',
                          height: expandRow
                            ? `${
                                heightOfOnePriceStep * item.moqPrices.length + 5
                              }px`
                            : `${heightOfOnePriceStep * 3 + 5}px`,
                          // overflowY: expandRow ? 'visible' : 'hidden',
                        }}
                        className='text-end'
                      >
                        {!expandRow
                          ? item.moqPrices.slice(0, 3).map((moq) => (
                              <h5 key={moq.price}>
                                {moq.moq}
                                <small> {validateUnitExists(item.unit)}</small>
                              </h5>
                            ))
                          : item.moqPrices.map((moq) => (
                              <h5 key={moq.price}>
                                {moq.moq}
                                <small> {validateUnitExists(item.unit)}</small>
                              </h5>
                            ))}
                      </Col>

                      <Col
                        style={{
                          maxWidth: '120px',
                          height: expandRow
                            ? `${
                                heightOfOnePriceStep * item.moqPrices.length + 5
                              }px`
                            : `${heightOfOnePriceStep * 3 + 5}px`,
                          // overflowY: expandRow ? 'visible' : 'hidden',
                        }}
                        className='text-end product-list-col-prices'
                      >
                        {canSeePrices(
                          !expandRow
                            ? item.moqPrices
                                .slice(0, 3)
                                .map((moq) => (
                                  <ProductMoqPrice
                                    key={moq.price}
                                    moq={moq}
                                    unit={validateUnitExists(item.unit)}
                                  />
                                ))
                            : item.moqPrices.map((moq) => (
                                <ProductMoqPrice
                                  key={moq.price}
                                  moq={moq}
                                  unit={validateUnitExists(item.unit)}
                                />
                              ))
                        )}
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          )}
          <Col style={{ maxWidth: '10px' }}></Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

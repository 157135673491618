import { ProductListHeaderSorter } from 'components/shared/products/Product/ProductListHeaderSorter';
import { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropsType = {
  getProducts: (didCancel: boolean, orderBy: string) => Promise<void>;
};

export const SellerProductListHeader = ({ getProducts }: PropsType) => {
  const [currentSort, setCurrentSort] = useState<string>('');

  const { t } = useTranslation('components');

  let productClassListHeaderClasses = [
    'seller-product-class-list-sticky-header',
  ];

  const handleSortASC = async (column: string) => {
    let didCancel = false;
    setCurrentSort(column);
    getProducts !== undefined && (await getProducts(didCancel, column));
  };

  const handleSortDESC = async (column: string) => {
    let didCancel = false;
    setCurrentSort(column);

    getProducts !== undefined && (await getProducts(didCancel, column));
  };

  return (
    <div className={productClassListHeaderClasses.join(' ')}>
      <Card className='card-wrapper shadow sticky-top mb-0'>
        <Card.Header className='p-0'>
          <Row>
            <Col style={{ maxWidth: '160px' }}>
              {/* <StarFill fontSize='20px' className='ms-3' />
              <Image fontSize={20} className='ms-7' /> */}
            </Col>
            <Col>
              <Row>
                <ProductListHeaderSorter
                  handleSortASC={() => handleSortASC('code')}
                  handleSortDESC={() => handleSortDESC('code_desc')}
                  currentSort={currentSort}
                  sortProperty='code'
                  sortTitle={t('productListHeader.lbl_ProductCode')}
                  style={{ maxWidth: '200px' }}
                  className='d-flex align-items-end'
                />

                <ProductListHeaderSorter
                  handleSortASC={() => handleSortASC('manufacturer')}
                  handleSortDESC={() => handleSortDESC('manufacturer_desc')}
                  currentSort={currentSort}
                  sortProperty='manufacturer'
                  sortTitle={t('productListHeader.lbl_Producer')}
                  style={{ maxWidth: '150px' }}
                  className='d-flex align-items-end'
                />

                <ProductListHeaderSorter
                  handleSortASC={() => handleSortASC('product_class')}
                  handleSortDESC={() => handleSortDESC('product_class_desc')}
                  currentSort={currentSort}
                  sortProperty='product_class'
                  sortTitle={t('productListHeader.lbl_ProductClass')}
                  className='d-flex align-items-end'
                />

                <ProductListHeaderSorter
                  handleSortASC={() => handleSortASC('in_stock')}
                  handleSortDESC={() => handleSortDESC('in_stock_desc')}
                  currentSort={currentSort}
                  sortProperty='in_stock'
                  sortTitle={t('common.lbl_Instock')}
                  style={{
                    maxWidth: '80px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                  }}
                  className='justify-content-end d-flex align-items-end'
                />

                <ProductListHeaderSorter
                  handleSortASC={() => handleSortASC('arriving')}
                  handleSortDESC={() => handleSortDESC('arriving_desc')}
                  currentSort={currentSort}
                  sortProperty='arriving'
                  sortTitle={t('common.lbl_Arriving')}
                  style={{
                    maxWidth: '80px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                  }}
                  className='justify-content-end d-flex align-items-end'
                />

                <ProductListHeaderSorter
                  handleSortASC={() => handleSortASC('order_step')}
                  handleSortDESC={() => handleSortDESC('order_step_desc')}
                  currentSort={currentSort}
                  sortProperty='order_step'
                  sortTitle={`${t('productListHeader.lbl_OrderStep')}`}
                  style={{
                    maxWidth: '110px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                  }}
                  className='justify-content-end d-flex align-items-end'
                />

                <Col
                  style={{
                    maxWidth: '90px',
                    marginLeft: '15px',
                  }}
                  className='justify-content-start d-flex align-items-end wrap-ellipsis'
                >
                  <h5 className='text-end'>{t('productListHeader.lbl_Moq')}</h5>
                </Col>

                <ProductListHeaderSorter
                  handleSortASC={() => handleSortASC('price')}
                  handleSortDESC={() => handleSortDESC('price_desc')}
                  sortProperty='price'
                  currentSort={currentSort}
                  sortTitle={t('productListHeader.lbl_Price')}
                  style={{
                    maxWidth: '65px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                  }}
                  className='justify-content-end d-flex align-items-end me-1'
                />
              </Row>
            </Col>
            <Col style={{ maxWidth: '10px' }}></Col>
          </Row>
        </Card.Header>
      </Card>
    </div>
  );
};

import { BuyerProductClassWithSubclassesModel } from 'models/buyer/product/BuyerProductClassWithSubclassesModel';
import { SellerProductClassWithSubclassesModel } from 'models/seller/product/SellerProductClassWithSubclassesModel';
import { Col, Row } from 'react-bootstrap';
import { ProductClassWithProductsItem } from './ProductClassWithProductsItem';
import { ProductClassWithSubclassesItem } from './ProductClassWithSubclassesItem';

type PropsType = {
  item:
    | SellerProductClassWithSubclassesModel
    | BuyerProductClassWithSubclassesModel;
  productListRoute: string;
  productSubClassesRoute: string;
  index: number;
  handleProductClassImageUpload: (productClassId: number ) => void;
  imageReadonly: boolean;
};

export const ProductClassWithSubclasses = ({
  item,
  productListRoute,
  productSubClassesRoute,
  index,
  handleProductClassImageUpload,
  imageReadonly,
}: PropsType) => {

  const classNames =
    index === 1
      ? 'product-class-section p-3 mt-1'
      : 'product-class-section p-3 mt-1';
  return (
    <section
      className={classNames}
      id={item.code}
      style={{ minHeight: '210px' }}
    >
      <h3>{item.name}</h3>
      <hr className='icon-ez m-0' />
      <Row>
        {item.subClasses &&
          item.subClasses.map((subClass) => (
            <Col
              className={`product-class-section-child ${item.code} p-3 mx-2 mb-1`}
            >
              {subClass.linkType === 'Subclasses' ? (
                <ProductClassWithSubclassesItem
                  handleProductClassImageUpload={handleProductClassImageUpload}
                  item={subClass}
                  imageReadonly={imageReadonly}
                  productSubClassesRoute={productSubClassesRoute}
                />
              ) : (
                <ProductClassWithProductsItem
                  handleProductClassImageUpload={handleProductClassImageUpload}
                  item={subClass}
                  imageReadonly={imageReadonly}
                  productListRoute={productListRoute}
                />
              )}
            </Col>
          ))}
      </Row>
    </section>
  );
};

import {
  getBuyerExternalStocks,
  getBuyerStocks,
  getCustomerCompanyInfo,
} from 'components/seller/aCustomer/utilsACustomer';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { BuyerExternalStocksModel } from 'models/seller/customers/BuyerExternalStocksModel';
import { BuyerStocksModel } from 'models/seller/customers/BuyerStocksModel';
import { CustomerCompanyModel } from 'models/seller/customers/CustomerCompanyModel';
import { useEffect } from 'react';

export const useGetBuyerStocks = (
  setBuyerStocks: (value: BuyerStocksModel[]) => void,
  currentUser: LoginResponseUserModel,
  customerId: string | null
) => {
  useEffect(() => {
    const getStocks = async () => {
      customerId !== null &&
        (await getBuyerStocks(
          setBuyerStocks,
          currentUser,
          parseInt(customerId)
        ));
    };

    getStocks();
  }, [currentUser, customerId, setBuyerStocks]);
};

export const useGetBuyerExternalStocks = (
  setBuyerExternalStocks: (value: BuyerExternalStocksModel[]) => void,
  currentUser: LoginResponseUserModel,
  customerId: string | null
) => {
  useEffect(() => {
    const getStocks = async () => {
      customerId !== null &&
        (await getBuyerExternalStocks(
          setBuyerExternalStocks,
          currentUser,
          parseInt(customerId)
        ));
    };

    getStocks();
  }, [currentUser, customerId, setBuyerExternalStocks]);
};

export const useGetCustomerCompanyInfo = (
  setCompany: (value: CustomerCompanyModel) => void,
  currentUser: LoginResponseUserModel,
  customerId: number
) => {
  useEffect(() => {
    const getCompanyInfo = async (): Promise<void> => {
      customerId !== null &&
        (await getCustomerCompanyInfo(setCompany, currentUser, customerId));
    };

    getCompanyInfo();
  }, [currentUser, customerId, setCompany]);
};

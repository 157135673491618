import { ProjectRowModel } from 'models/buyer/project/ProjectModels';
import { ProjectOrderRowUpdateModel } from 'models/buyer/projectOrder/ProjectOrderModels';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { updateProjectOrderRows } from 'pages/buyer/ProjectOrder/utilsProjectOrder';
import { ChangeEvent, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

type PropTypes = {
  rows: ProjectRowModel[];
  currentUser: LoginResponseUserModel;
  projectId: number;
  projectOrderId: number;
  getDataSource: () => Promise<void>;
  setCurrentRequiredQtyFocused: (value: number) => void;
  setIsRequiredQtyUpdating: (value: boolean) => void;
};

export const CustomRequiredQuantityInput = ({
  rows,
  currentUser,
  projectId,
  projectOrderId,
  getDataSource,
  setCurrentRequiredQtyFocused,
  setIsRequiredQtyUpdating,
}: PropTypes) => {
  const [internalValue, setInternalValue] = useState<number>(
    !isNaN(rows[0].requiredQuantity) ? rows[0].requiredQuantity : 0
  );
  const [internalRow, setInternalRow] = useState<ProjectRowModel>(rows[0]);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(parseInt(event.target.value));
    setCurrentRequiredQtyFocused(parseInt(event.target.value));

    let clonedRow: ProjectRowModel = { ...internalRow };

    clonedRow.requiredQuantity = parseInt(event.target.value);

    setInternalRow(clonedRow);
  };

  const handleSaveRow = async () => {
    setIsRequiredQtyUpdating(true);

    setIsFocused(false);

    let clonedRow = { ...internalRow };
    if (isNaN(internalValue)) {
      setInternalValue(0);
      clonedRow = { ...clonedRow, requiredQuantity: 0 };
    }

    const rowUpdate: ProjectOrderRowUpdateModel[] = rows.map((x) => {
      return {
        requiredQuantity: internalValue,
        supplierQuantity: x.supplierQuantity,
        productFreeName: x.productId === null ? x.name : null,
        productCode: x.productId === null ? x.productCode : null,
        id: x.id,
        productId: x.productId,
        updateRequiredQuantity: true,
        updateSupplierQuantity: false,
      };
    });

    await updateProjectOrderRows(
      rowUpdate,
      currentUser,
      projectId,
      projectOrderId
    );
    await getDataSource();

    setInternalRow(rows[0]);
    setIsRequiredQtyUpdating(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <InputGroup
      style={{ maxWidth: '110px' }}
      className={
        isFocused
          ? 'custom-project-table-input px-2 required-qty-focused'
          : 'custom-project-table-input px-2'
      }
    >
      <FormControl
        className='borderless-input custom-required-qty-input'
        defaultValue={rows[0].requiredQuantity}
        value={internalValue}
        min={0}
        type='number'
        onFocus={handleFocus}
        onBlur={handleSaveRow}
        style={{ textAlign: 'right' }}
        onChange={handleChange}
      />
      <span
        className={
          rows[0].requiredQuantity !== rows[0].supplierQuantity
            ? 'me-2 icon-ez-red'
            : 'me-2 icon-ez-gray'
        }
      >
        {/* / {row.supplierQuantity} */}
      </span>
    </InputGroup>
  );
};

import { SellerApi } from 'api/SellerApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SellerDealsFilter } from 'components/seller/dealshome/SellerDealsFilter';
import { SellerDealsRow } from 'components/seller/dealshome/SellerDealsRow';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  AllProjectsModel,
  FilterStateModel,
} from 'models/seller/project/ProjectModels';
import { useCallback, useContext, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Button, Spinner } from 'react-bootstrap';
import { CaretDownFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import '../dealshome/deals.css';

export const SellerDeals = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<AllProjectsModel>(
    {} as AllProjectsModel
  );
  const [filterState, setFilterState] = useState<FilterStateModel>(
    {} as FilterStateModel
  );

  const [moreDealsLoading, setMoreDealsLoading] = useState<boolean>(false);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;
  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');

  const selectedCustomerId = appState.sellerCurrentCustomerId;

  const getProjects = useCallback(
    async (loadFromStart?: boolean) => {
      if (loadFromStart) {
        const response = await SellerApi.getAllProjects(
          currentUser.companyId,
          selectedCustomerId,
          { ...filterState, pageNumber: 1 }
        );
        setProjects(response);
      } else {
        const response = await SellerApi.getAllProjects(
          currentUser.companyId,
          selectedCustomerId,
          filterState
        );

        if (
          filterState?.pageNumber !== 1 &&
          filterState?.pageNumber !== undefined
        ) {
          setProjects((prevState) => ({
            ...response,
            data: [...prevState.data, ...response.data],
          }));
        } else {
          setProjects(response);
        }
      }

      setIsLoading(false);
      setMoreDealsLoading(false);
    },
    [currentUser.companyId, filterState, selectedCustomerId]
  );

  const loadMoreDeals = () => {
    setMoreDealsLoading(true);
    if (filterState.pageNumber < projects.totalPages) {
      setFilterState((prevState: FilterStateModel) => ({
        ...prevState,
        pageNumber: prevState.pageNumber + 1,
      }));
    }
  };

  return (
    <>
      <SellerDealsFilter
        currentUser={currentUser}
        setIsLoading={setIsLoading}
        getProjects={getProjects}
        totalNumberOfPages={projects.totalPages}
        setFilterState={setFilterState}
        filterState={filterState}
      />
      {isLoading ? (
        <div style={{ marginTop: '150px' }}>
          <FullScreenLoader />
        </div>
      ) : (
        <div style={{ marginTop: '150px' }}>
          {projects.data.length !== 0 ? (
            <>
              {projects.data.map((project, index) => (
                <SellerDealsRow
                  currentUser={currentUser}
                  project={project}
                  index={index}
                  getProjects={getProjects}
                />
              ))}

              <div className='d-flex justify-content-center mb-4'>
                {filterState.pageNumber < projects.totalPages ||
                moreDealsLoading ? (
                  <Button
                    disabled={moreDealsLoading}
                    onClick={loadMoreDeals}
                    className='btn btn-eleczap'
                  >
                    {t('deals.btn_LoadMoreDeals')}{' '}
                    {moreDealsLoading ? (
                      <Spinner animation='border' size='sm' />
                    ) : (
                      <CaretDownFill />
                    )}
                  </Button>
                ) : (
                  <span>{t('deals.inf_AllDealsLoaded')}</span>
                )}
              </div>
            </>
          ) : (
            <div className='text-center' style={{ marginTop: '150px' }}>
              <h3>{t('deals.inf_NoFilterResults1')}</h3>
              <h3>{t('deals.inf_NoFilterResults2')}</h3>
            </div>
          )}
        </div>
      )}
    </>
  );
};

import { BuyerApi } from 'api/BuyerApi';
import { ProductQuickSearchModel } from 'models/buyer/product/ProductQuickSearchModel';
import { BuyerProjectRowInfoGroupedModel } from 'models/buyer/project/GroupedProjectModels';
import {
  ProductOptionType,
  ProjectModel,
  ProjectRowBulkUpdateModel,
  ProjectRowModel,
  ProjectRowNewModel,
  ProjectRowUpdateModel,
} from 'models/buyer/project/ProjectModels';
import { BuyerCurrentSuppliers } from 'models/buyer/suppliers/CurrentSuppliersModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { toast } from 'react-toastify';

export const mapQuickProductReponseToOptions = (
  response: ProductQuickSearchModel[],
  sellers: BuyerCurrentSuppliers[]
): ProductOptionType[] => {
  let options: ProductOptionType[] = [];

  /* Find out which seller has most products */
  let sellerWithMostProducts;

  sellerWithMostProducts = response.sort(
    (a, b) => b.products.length - a.products.length
  )[0];

  const mostProducts = sellerWithMostProducts?.products.length;

  /* Render products seller by seller */
  for (let j = 0; j < mostProducts; j++) {
    for (let i = 0; i < response.length; i++) {
      if (
        response[i].products[j] !== undefined &&
        response[i].products[j] !== null
      ) {
        const row = response[i].products[j];

        sellers?.findIndex(
          (supplier) => supplier.value === response[i].sellerId
        ) !== -1 &&
          options.push({
            value: row.id,
            label: row.code,
            name: row.name,
            supplierId: response[i].sellerId,
            sellerName: response[i].sellerName,
          });
      }
    }
  }

  return options;
};

export const addProductsToProject = async (
  projectRows: ProjectRowNewModel[],
  currentUser: LoginResponseUserModel,
  projectId: number,
  t: any
) => {
  toast.dismiss();
  await BuyerApi.createProjectRows(
    currentUser.companyId,
    projectId,
    projectRows
  );

  toast.success(t('projectSettings.inf_ProductAdded'));
};

export const updateProjectRows = async (
  projectRows: ProjectRowUpdateModel[],
  currentUser: LoginResponseUserModel,
  projectId: number
) => {
  toast.dismiss();
  await BuyerApi.updateProjectRows(
    currentUser.companyId,
    projectId,
    projectRows
  );
};

export const bulkUpdateProjectRows = async (
  projectRows: ProjectRowBulkUpdateModel[],
  currentUser: LoginResponseUserModel,
  projectId: number
) => {
  toast.dismiss();
  await BuyerApi.bulkUpdateProjectRow(
    currentUser.companyId,
    projectId,
    projectRows
  );
};
export const deleteProjectRow = async (
  projectRow: ProjectRowModel[],
  currentUser: LoginResponseUserModel,
  projectId: number
) => {
  toast.dismiss();
  await BuyerApi.deleteProjectRows(
    currentUser.companyId,
    projectId,
    projectRow.map((x) => x.id)
  );
};

export const getAllProjectRows = async (
  projectId: number,
  suppliers: BuyerCurrentSuppliers[] | null,
  currentUser: LoginResponseUserModel
): Promise<BuyerProjectRowInfoGroupedModel[]> => {
  toast.dismiss();
  const response = await BuyerApi.getProjectRowsGrouped(
    currentUser.companyId,
    projectId
  );

  const getFlooredFixed = (v: number, d: number): number => {
    return parseFloat(
      (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d)
    );
  };

  const getStandardRounded = (v: number) => {
    return Math.round((v + Number.EPSILON) * 100) / 100;
  };

  const rowRender: BuyerProjectRowInfoGroupedModel[] = [];

  const favouriteSupplier = suppliers?.find((x) => x.isFavourite);

  response.forEach((row) => {
    let item = {
      productCode: row.productCode,
      rows: [...row.rows],
    };

    item.rows.forEach((r) => {
      r.isFavorite = favouriteSupplier?.value === r.sellerId;
      r.supplierName = r.sellerName;
      r.supplierId = r.sellerId;

      r.isDisabled =
        suppliers != null &&
        suppliers.findIndex(
          (supplier) => supplier.value === r.sellerId && !supplier.isSelected
        ) > -1;

      r.moqPrices?.forEach((moq) => {
        moq.price = getFlooredFixed(moq.price, moq.decimalPlaces);
      });

      r.moqPrices?.sort((a, b) => a.moq - b.moq);
      if (r.moqPrices !== undefined) {
        r.totalPrice = getStandardRounded(
          r.supplierQuantity *
            ([...r.moqPrices]
              .reverse()
              .find((moq) => r.supplierQuantity >= moq.moq)?.price ||
              r.moqPrices[0].price)
        );
      }
    });

    rowRender.push(item);
  });

  // response?.forEach((seller) => {
  //   seller.rows.forEach((row) => {
  //     rowRender.push({
  //       productCode: row.productCode
  //       rows: [...row.rows]
  //       // supplierName: seller.sellerName,
  //       // isFavorite: seller.isFavorite,
  //       // supplierId: seller.sellerId,
  //       // isDisabled: !suppliers?.find(
  //       //   (supplier) => supplier.value === seller.sellerId
  //       // )?.isSelected,
  //     });
  //   });
  // });

  // const sortedRowsByIndex = rowRender.sort(
  //   (a: ProjectRowWithSupplier, b: ProjectRowWithSupplier) => a.rowNo - b.rowNo
  // );

  // // const disabledSortedRowsByIndex = disabledRowRender.sort(
  // //   (a: ProjectRowWithSupplier, b: ProjectRowWithSupplier) => a.rowNo - b.rowNo
  // // );

  // let clonedResponse = [...sortedRowsByIndex];

  // clonedResponse.forEach((row) => {
  //   row.moqPrices?.forEach((moq) => {
  //     moq.price = getFlooredFixed(moq.price, moq.decimalPlaces);
  //   });
  // });

  // clonedResponse.forEach((row) => {
  //   row.moqPrices?.sort((a, b) => a.moq - b.moq);

  //   if (row.moqPrices !== undefined) {
  //     row.totalPrice = getStandardRounded(
  //       row.supplierQuantity *
  //         ([...row.moqPrices]
  //           .reverse()
  //           .find((moq) => row.supplierQuantity >= moq.moq)?.price ||
  //           row.moqPrices[0].price)
  //     );
  //   }
  // });

  return [...rowRender];
};

export const getProjectDetails = async (
  id: number,
  currentUser: LoginResponseUserModel,
  setProjectDetails: (values: ProjectModel) => void
): Promise<void> => {
  const response = await BuyerApi.getProject(currentUser.companyId, id);

  setProjectDetails(response);
};

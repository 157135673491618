import FilePicker from '@mavedev/react-file-picker';
import { useQueryClient } from '@tanstack/react-query';
import { BuyerApi } from 'api/BuyerApi';
import { CompanyApi } from 'api/CompanyApi';
import { OrderDisabled } from 'assets/icons/StatusIcons';
import { DeliveryAddressesNew } from 'components/buyer/settings/deliveryAddresses/DeliveryAddressNew/DeliveryAddressesNew';
import {
  getAddressById,
  getAllAddresses,
} from 'components/buyer/settings/deliveryAddresses/utilsDeliveryAddresses';
import { useGetSupplierCompanyInfo } from 'components/buyer/supplierInfo/hooksSupplierInfo';
import { getSupplierCreditInfo } from 'components/buyer/supplierInfo/utilsSupplierInfo';
import { useGetAllSupplierCompanies } from 'components/buyer/suppliers/hooksSuppliers';
import { ProjectAttachmentItem } from 'components/shared/deals/ProjectAttachmentItem/ProjectAttachmentItem';
import { SuccessToast } from 'components/shared/toasts/SuccessToast';
import { AppContext } from 'contexts/user/AppContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useFormatMonetaryValue } from 'hooks/useFormatMonetaryValue';
import { useUserCanOrder } from 'hooks/useUserCanOrder';
import { useValidateUnitExists } from 'hooks/useValidateUnitExists';
import { ExternalSellerContactModel } from 'models/buyer/contacts/ExternalSellerContactsModel';
import {
  OrderCreateForSessionModel,
  OrderCreateFromCherryPickOfferModel,
  OrderCreateFromOfferModel,
  OrderRowModel,
  OrderUpdateDraftModel,
} from 'models/buyer/documents/OrderModels';
import { ProjectOrderCreateOrderModel } from 'models/buyer/projectOrder/ProjectOrderModels';
import { SellerCreditInfoModel } from 'models/buyer/suppliers/SellerCreditInfoModel';
import { SellersCompaniesAndUsersModel } from 'models/buyer/suppliers/SellersCompanyAndUsersModel';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import {
  AddressModel,
  AddressModelById,
} from 'models/shared/address/AddressModel';
import { ProjectAttachmentsModel } from 'models/shared/attachments/AttachmentModel';
import {
  createOrder,
  createOrderFromCherryPickOffer,
  createOrderFromOffer,
  updateOrder,
} from 'pages/buyer/Order/utilsOrder';
import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  ArrowCounterclockwise,
  CartFill,
  Paperclip,
  Trash,
  X,
} from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatMonetaryValue } from 'utils/formatMonetaryValue';
import { formatMonetaryValueWithoutDecimals } from 'utils/formatMonetaryValueWithoutDecimals';
import { getUrlParams } from 'utils/getUrlParams';

dayjs.extend(utc);

type PropTypes = {
  orderProp: OrderCreateForSessionModel;
  handleDiscardOrder: (sellerId: number) => void;
  setIsOrdering?: (value: boolean) => void;
  updateOrderSentId?: (orderProp: OrderCreateForSessionModel) => Promise<void>;
  fromOffer?: boolean;
};

export const OrderUnsent = ({
  orderProp,
  handleDiscardOrder,
  setIsOrdering,
  updateOrderSentId,
  fromOffer,
}: PropTypes) => {
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [requestedDate, setRequestedDate] = useState<string>('');
  const [deliveryAddressId, setDeliveryAddressId] = useState<number>(0);
  const [comment, setComment] = useState<string>('');
  const [addresses, setAddresses] = useState<AddressModel>({} as AddressModel);
  const [filteredAddresses, setFilteredAddresses] = useState<
    AddressModelById[]
  >([]);
  const [asOptionShow, setAsOptionShow] = useState<boolean>(false);
  const [isoDate, setIsoDate] = useState<string | null>(null);
  const [canOrder, cannotOrderExplanation] = useUserCanOrder();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<SellersCompaniesAndUsersModel>(
    {} as SellersCompaniesAndUsersModel
  );
  const [files, setFiles] = useState<
    { id: number; file: File; attachments: ProjectAttachmentsModel }[]
  >([]);

  const [isQuickOrder, setIsQuickOrder] = useState<boolean>(false);

  const [creditInfo, setCreditInfo] = useState<SellerCreditInfoModel>(
    {} as SellerCreditInfoModel
  );

  const [company, setCompany] = useState<SupplierCompanyModel>(
    {} as SupplierCompanyModel
  );

  const [externalContacts, setExternalContacts] = useState<
    ExternalSellerContactModel[]
  >([]);

  const [selectedExternalContact, setSelectedExternalContact] = useState<
    number | null
  >(null);

  const [counter, setCounter] = useState<number>(0);

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const queryClient = useQueryClient();

  const { appState } = useContext(AppContext);

  const { t } = useTranslation('components');
  const validateUnitExists = useValidateUnitExists();

  const sellerCompanyId = orderProp.sellerCompanyId;
  useGetAllSupplierCompanies(currentUser, setSuppliers); // TODO: Always use the first supplier. Remove after multi seller rupport

  const offerId = getUrlParams('offerId');
  const offerCode = getUrlParams('offerCode');
  const dealId = getUrlParams('dealId');
  const cherryPicked = getUrlParams('cherryPicked');

  /* Get external contacts */
  useEffect(() => {
    const getExternalContacts = async () => {
      const response = await BuyerApi.getExternalSellerContacts(
        currentUser.companyId,
        sellerCompanyId
      );

      setExternalContacts(response);
      if (orderProp.externalSellerContactId)
        setSelectedExternalContact(orderProp.externalSellerContactId);
      else setSelectedExternalContact(response[0].id);
    };

    orderProp.isExternalSeller &&
      !orderProp.isSubSeller &&
      getExternalContacts();
  }, [
    currentUser.companyId,
    orderProp.externalSellerContactId,
    orderProp.isExternalSeller,
    orderProp.isSubSeller,
    sellerCompanyId,
  ]);

  /* Get all addresses */
  useEffect(() => {
    const getAddresses = async () => {
      await getAllAddresses(
        currentUser,
        setAddresses,
        undefined,
        undefined,
        undefined,
        'isDefault_desc'
      );
    };

    getAddresses();
  }, [currentUser]);

  useEffect(() => {
    if (addresses.data !== undefined) {
      const firstVisibleAddress = addresses.data.find(
        (address) => address.isVisible || address.isDefault
      );

      setDeliveryAddressId(
        orderProp.deliveryAddressId
          ? orderProp.deliveryAddressId
          : firstVisibleAddress !== undefined
          ? firstVisibleAddress.id
          : 0
      );
    }
  }, [addresses.data, orderProp.deliveryAddressId]);

  useEffect(() => {
    /* filter out not visible address */

    let clonedAddress = { ...addresses };

    if (clonedAddress.data !== undefined) {
      const filtered = clonedAddress.data.filter(
        (address) => address.isVisible
      );

      setFilteredAddresses(filtered);
    }
  }, [addresses]);

  /* Get order details */
  useEffect(() => {
    setDeliveryAddressId(orderProp.deliveryAddressId);
    setComment(orderProp.comment);
  }, [orderProp]);

  const columns: TableColumn<OrderRowModel>[] = [
    {
      name: '#',
      selector: (row) => row.rowNo,
      sortable: true,
      width: '60px',
    },
    {
      name: t('productDetailsGeneralInfo.lbl_ProductCode'),
      selector: (row) => row.productCode,
      cell: (row) =>
        row.isReplaced ? (
          <div className='d-flex flex-column align-items-start'>
            <span>{row.productCode}</span>
            {row.productId !== null ? (
              <Link
                to={`/buyer/product-details/${row.productCode}`}
                className='btn-link-bold'
              >
                <small>{row.productCode}</small>{' '}
              </Link>
            ) : (
              <small>
                <del>{row.initialCode}</del>
              </small>
            )}
          </div>
        ) : row.productId === null ? (
          <span>{row.productCode}</span>
        ) : (
          <Link
            to={`/buyer/product-details/${row.productCode}`}
            className='btn-link-bold'
          >
            {row.productCode}
          </Link>
        ),
      sortable: true,
    },
    {
      name: t('projectSettings.lbl_ProductName'),
      selector: (row) => row.name,
      cell: (row) =>
        row.isReplaced ? (
          <div className='d-flex flex-column align-items-start'>
            <span>{row.name}</span>

            <small>{row.initialName}</small>
          </div>
        ) : (
          <span>{row.name}</span>
        ),
      sortable: true,
      grow: 6,
    },
    {
      name: t('documents.lbl_Qty'),
      selector: (row) => row.quantity,
      cell: (row) => (
        <>
          <span>
            {row.quantity} <small>{validateUnitExists(row.unit)}</small>
          </span>
        </>
      ),
      sortable: true,
      right: true,
    },
    {
      name: t('productListHeader.lbl_Price'),
      selector: (row) => row.priceWithDiscount ?? 'priceWithDiscount',
      cell: (row) =>
        row.decimalPlaces
          ? formatMonetaryValue(
              orderProp.currencyCode,
              row.priceWithDiscount,
              appState,
              row.decimalPlaces
            )
          : formatMonetaryValueWithoutDecimals(
              orderProp.currencyCode,
              row.priceWithDiscount,
              appState
            ),
      sortable: true,
      right: true,
    },
    {
      name: t('projectSettings.lbl_TotalPrice'),
      selector: (row) => row.total ?? 'total',
      cell: (row) =>
        row.total === null
          ? '-'
          : `${formatMonetaryValue(
              orderProp.currencyCode,
              row.total,
              appState
            )}`,
      sortable: true,
      right: true,
    },
  ];

  const handleDateChange = async (day: Date) => {
    const pickedDate = dayjs(day).format('DD-MM-YYYY');

    setRequestedDate(pickedDate);
    setIsoDate(dayjs(day).toISOString());
  };

  const handleDeliveryChange = async (event: FormEvent<HTMLSelectElement>) => {
    const valueOfThisFunction =
      parseInt(event.currentTarget.value) === -1
        ? null
        : parseInt(event.currentTarget.value);

    if (deliveryAddressId !== valueOfThisFunction) {
      if (event.currentTarget.value === 'addNewAddress') {
        setAsOptionShow(true);
      } else {
        setDeliveryAddressId(parseInt(event.currentTarget.value));
      }
    }
  };

  const setNewDeliverAddressAsSelected = async (value: number) => {
    const response = await getAddressById(currentUser, value);

    const clonedAddresses: AddressModel = { ...addresses };

    clonedAddresses.data.unshift(response);

    setAddresses(clonedAddresses);

    setDeliveryAddressId(value);
  };

  const handleOrder = async () => {
    setIsOrdering && setIsOrdering(true);
    setIsloading(true);

    try {
      await addAllAttachments();

      let createOrderData: ProjectOrderCreateOrderModel =
        {} as ProjectOrderCreateOrderModel;
      let createOrderDataFromOffer: OrderCreateFromOfferModel =
        {} as OrderCreateFromOfferModel;
      let createOrderDataFromCherryPickOffer: OrderCreateFromCherryPickOfferModel =
        {} as OrderCreateFromCherryPickOfferModel;
      let updateCreatedOrder: OrderUpdateDraftModel =
        {} as OrderUpdateDraftModel;

      createOrderData.orderProjectId = orderProp.project.id;
      createOrderData.sellerCompanyId = sellerCompanyId || 0;
      createOrderData.projectId = orderProp.parentProjectId;
      createOrderData.externalSellerContactId = selectedExternalContact;
      createOrderData.comment = '';

      /* Create an order */
      let createResponse;

      if (offerId !== null && offerId !== undefined) {
        if (cherryPicked) {
          createOrderDataFromCherryPickOffer.offerId = parseInt(offerId);
          createOrderDataFromCherryPickOffer.sellerCompanyId = sellerCompanyId;

          createOrderDataFromCherryPickOffer.offerRowIds = orderProp.rows.map(
            (row) => row.id
          );

          createResponse = await createOrderFromCherryPickOffer(
            currentUser,
            createOrderDataFromCherryPickOffer
          );
        } else {
          createOrderDataFromOffer.offerId = parseInt(offerId);
          createOrderDataFromOffer.sellerCompanyId = sellerCompanyId;

          createResponse = await createOrderFromOffer(
            currentUser,
            createOrderDataFromOffer
          );
        }
      } else createResponse = await createOrder(currentUser, createOrderData);

     

      /* Update order */
      updateCreatedOrder.comment = comment;
      updateCreatedOrder.deliveryAddressId =
        deliveryAddressId !== 0 && deliveryAddressId !== -1
          ? deliveryAddressId
          : null;
      updateCreatedOrder.requestedDeliveryDate = isoDate;
      updateCreatedOrder.customerOrderCode = orderNumber;

      await updateOrder(currentUser, updateCreatedOrder, createResponse);

      /* Send order to ERP */
      await BuyerApi.sendOrder(currentUser.companyId, {
        sellerCompanyId:
          orderProp.isSubSeller && orderProp.marketplaceId
            ? orderProp.marketplaceId
            : sellerCompanyId ?? 0,
        orderId: createResponse,
        externalSellerContactId: selectedExternalContact,
        documentUrlTemplate: `${
          window && window.location.origin
        }/external-seller-order?accessToken={accessToken}`,
      });

      queryClient.invalidateQueries({ queryKey: ['deals'] });

      SuccessToast(t('documents.lbl_OrderMade'));

      const updateSentIdInCacheInqiury: OrderCreateForSessionModel = {
        ...orderProp,
        sentId: createResponse,
      };

      updateOrderSentId &&
        (await updateOrderSentId(updateSentIdInCacheInqiury));

      // sessionStorage.removeItem('orders_unsent');

      // sessionStorage.setItem('orders_unsent', JSON.stringify(filteredOrders));

      setIsloading(false);
    } catch (err) {
      setIsloading(false);
    }
  };

  const disabledDays = {
    from: new Date(
      dayjs().startOf('month').year(),
      dayjs().startOf('month').month(),
      1
    ),
    to: new Date(),
  };

  const handleDiscard = () => {
    handleDiscardOrder(orderProp.sellerCompanyId);
    if (isQuickOrder) window.close();
  };

  const handleDateDelete = async () => {
    setRequestedDate('');
    setIsoDate(null);
  };

  useGetSupplierCompanyInfo(setCompany, currentUser, orderProp.sellerCompanyId);

  useEffect(() => {
    const getCreditInfo = async (): Promise<void> => {
      orderProp.sellerCompanyId !== null &&
        orderProp.sellerCompanyId !== undefined &&
        (await getSupplierCreditInfo(
          setCreditInfo,
          currentUser,
          orderProp.sellerCompanyId
        ));
    };

    !orderProp.isSubSeller && getCreditInfo();
  }, [currentUser, orderProp.isSubSeller, orderProp.sellerCompanyId]);

  /* Check from urlParams if isQuickOrder */
  useEffect(() => {
    const comingFromQuickOrder = getUrlParams('isQuickOrder');

    comingFromQuickOrder && setIsQuickOrder(comingFromQuickOrder === 'true');
  }, []);

  useEffect(() => {
    if (orderProp.requestedDeliveryTime) {
      setRequestedDate(
        dayjs(orderProp.requestedDeliveryTime).format('DD-MM-YYYY')
      );

      setIsoDate(dayjs(orderProp.requestedDeliveryTime).toISOString());
    } else {
      setRequestedDate(t('common.lbl_DDMMYYYY'));

      setIsoDate(null);
    }
  }, [orderProp.requestedDeliveryTime]);

  const handleOpenFilePicker = () => {
    inputFile.current !== null && inputFile.current.click();
  };

  const handleAddNewFile = async (file: File) => {
    const reader = new FileReader();

    if (fromOffer || dealId !== null) {
      const input: HTMLInputElement | null =
        document.querySelector('input[type=file]');

      if (input !== null) {
        input.value = '';
      }

      reader.readAsDataURL(file);
      reader.onload = async function () {
        if (reader.result !== undefined) {
          const filesClones = [...files];

          filesClones.push({
            id: counter,
            file: file,
            attachments: {
              id: counter,
              name: file.name,
              uri: file.webkitRelativePath,
              documentType: 'Order',
            },
          });

          setFiles(filesClones);
        }
      };
    } else {
      const input: HTMLInputElement | null =
        document.querySelector('input[type=file]');

      if (input !== null) {
        input.value = '';
      }

      reader.readAsDataURL(file);
      reader.onload = async function () {
        if (reader.result !== undefined) {
          const filesClones = [...files];

          filesClones.push({
            id: counter,
            file: file,
            attachments: {
              id: counter,
              name: file.name,
              uri: file.webkitRelativePath,
              documentType: 'Order',
            },
          });

          setFiles(filesClones);
        }
      };
    }

    setCounter((prevValue) => prevValue + 1);
  };

  const inputFile = useRef<HTMLInputElement>(null);

  const handleDeleteAttachment = async (id: number) => {
    let timeout: any = 0;

    toast.success(t('projectSettings.inf_DeletingFile'), {
      className: 'delete-attachment-toast',
      autoClose: 5000,
      pauseOnHover: true,
      closeButton: (
        <Button
          className='btn-undo-delete-attachment'
          onClick={() => clearTimeout(timeout)}
        >
          <ArrowCounterclockwise size={20} />
        </Button>
      ),
    });

    if (fromOffer || dealId !== null) {
      timeout = setTimeout(async () => {
        // dealId &&
        //   (await CompanyApi.deleteDocumentAttachment(
        //     currentUser.companyId,
        //     orderProp.project.id,
        //     id,
        //     parseInt(dealId),
        //     'Order'
        //   ));

        const clonedFiles = [...files];

        const filtered = clonedFiles.filter((file) => file.id !== id);

        setFiles(filtered);
      }, 5000);
    } else {
      timeout = setTimeout(async () => {
        // await CompanyApi.deleteOrderAttachment(
        //   currentUser.companyId,
        //   orderProp.parentProjectId,
        //   orderProp.project.id,
        //   id
        // );

        const clonedFiles = [...files];

        const filtered = clonedFiles.filter((file) => file.id !== id);

        setFiles(filtered);
      }, 5000);
    }
  };

  const addSingleAttachment = async (file: File) => {
    const formData = new FormData();

    formData.append('file', file);

    if (fromOffer || dealId !== null) {
      dealId && formData.append('dealId', dealId);
      formData.append('supplierId', orderProp.sellerCompanyId.toString());
      formData.append('documentType', 'Order');

      await CompanyApi.addNewDocumentAttachment(
        currentUser.companyId,
        orderProp.project.id,
        formData
      );
    } else {
      formData.append('orderProjectId', orderProp.project.id.toString());
      formData.append('supplierId', orderProp.sellerCompanyId.toString());

      await CompanyApi.addNewOrderAttachment(
        currentUser.companyId,
        orderProp.parentProjectId,
        formData
      );
    }
  };

  const addAllAttachments = async () => {
    if (files.length > 0) {
      for (const file of files) {
        await addSingleAttachment(file.file);
      }
    }
  };

  return (
    <div className='container-fluid p-2'>
      <Card>
        <Card.Header>
          <Row>
            <Col lg={4}>
              <h2>{orderProp.sellerCompanyName}</h2>
              {orderProp.isExternalSeller && !orderProp.isSubSeller && (
                <Form.Select
                  style={{
                    maxWidth: '200px',
                    color: '#1b9ad2',
                    fontWeight: '600',
                  }}
                  onChange={(event) =>
                    setSelectedExternalContact(parseInt(event.target.value))
                  }
                  className='border-0 borderless p-0 m-0 icon-ez'
                >
                  {externalContacts.map((contact) => (
                    <option value={contact.id} className='icon-ez'>
                      {contact.firstName} {contact.lastName}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Col>
            <Col className='text-center' lg={4}>
              <h2>{t('documents.hd_Order')}</h2>
            </Col>
            <Col className='text-end' lg={4}>
              <Row>
                <Col></Col>
                <Col className='text-center' lg={5}>
                  <h3>
                    {t('documents.lbl_Unsent')}{' '}
                    <OrderDisabled className='icon-ez-gray' />
                  </h3>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle
                  id='project-view-attachments-toggle'
                  className='btn btn-eleczap dropdown-toggle ms-3'
                >
                  <Paperclip /> {t('common.btn_AllFiles')}({files?.length})
                </Dropdown.Toggle>
                <Dropdown.Menu className='super-colors'>
                  {files.map((file, index: number) => (
                    <ProjectAttachmentItem
                      handleDeleteAttachment={handleDeleteAttachment}
                      attachment={file.attachments}
                      index={index}
                    />
                  ))}

                  <Dropdown.Divider />
                  <FilePicker
                    maxSize={10}
                    sizeUnit='MB'
                    extensions={[
                      '.jpg',
                      '.png',
                      '.jpeg',
                      '.pdf',
                      '.txt',
                      '.doc',
                      '.docx',
                      '.xls',
                      '.xlsx',
                      '.ppt',
                      '.pptx',
                      '.dwg',
                      '.dxf',
                    ]}
                    onFilePicked={(file) => {
                      handleAddNewFile(file);
                    }}
                    onError={(code) => {
                      toast.error(code);
                    }}
                  >
                    <Dropdown.Item onClick={handleOpenFilePicker} eventKey='4'>
                      {t('projectSettings.lbl_AddNewAttachment')}
                    </Dropdown.Item>
                  </FilePicker>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col lg={4}>
              <Row className='mb-3'>
                <Col lg={2}>{t('documents.lbl_Date')}:</Col>
                <Col>
                  <strong>{dayjs().format('DD.MM.YYYY')}</strong>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col lg={2}>{t('documents.lbl_SentBy')}:</Col>
                <Col>
                  <strong>
                    {appState.userProfile?.firstName}{' '}
                    {appState.userProfile?.lastName}
                  </strong>
                </Col>
              </Row>
            </Col>
            <Col className='text-center' lg={3} />
            <Col className='text-end' lg={5}>
              <Row className='mb-0'>
                <Col>{t('projectSettings.lbl_ProjectName')}:</Col>
                <Col className='text-start' lg={5}>
                  <Link
                    className='btn-link-bold'
                    to={`buyer/projects/${orderProp.project?.id}`}
                  >
                    {orderProp.project?.name}
                  </Link>
                </Col>
              </Row>

              <Row className='mb-0'>
                <Col>{t('documents.lbl_LinkedOffer')}:</Col>
                <Col className='text-start' lg={5}>
                  <Link
                    to={`/buyer/offer/${offerId}`}
                    className='btn-link-bold'
                  >
                    {offerCode}
                  </Link>
                </Col>
              </Row>

              <Row className='mb-0'>
                <Col>{t('documents.lbl_MyOrderNumber')}:</Col>
                <Col className='text-start' lg={5}>
                  <FormControl
                    max={100}
                    maxLength={100}
                    onChange={(event) => setOrderNumber(event.target.value)}
                    placeholder={t('documents.plh_MyOrderNumber')}
                  />
                </Col>
              </Row>

              <Row className='mb-0'>
                <Col>{t('projectSettings.lbl_RequestedDeliveryDate')}:</Col>
                <Col lg={5}>
                  <DayPickerInput
                    dayPickerProps={{
                      fromMonth: new Date(),
                      disabledDays: disabledDays,
                    }}
                    style={{ width: '100%' }}
                    component={(props: any) => (
                      <InputGroup>
                        <FormControl {...props} />
                        <InputGroup.Text
                          onClick={handleDateDelete}
                          style={{ cursor: 'pointer' }}
                        >
                          <X className='icon-ez-red' size={21} />
                        </InputGroup.Text>
                      </InputGroup>
                    )}
                    value={requestedDate || t('common.lbl_DDMMYYYY')}
                    onDayChange={handleDateChange}
                  />
                </Col>
              </Row>
              <Row className='mb-0'>
                <Col>{t('addressSettings.lbl_DeliveryAddress')}:</Col>
                <Col lg={5}>
                  <Form.Select
                    value={deliveryAddressId !== 0 ? deliveryAddressId : -1}
                    style={{ width: '100%' }}
                    onChange={handleDeliveryChange}
                  >
                    <option value={-1}>
                      {t('addressSettings.lbl_SelectDeliveryAddress')}
                    </option>
                    {filteredAddresses !== undefined &&
                      filteredAddresses.map((address, key) => (
                        <option value={address.id}>
                          {address.name} ({address.fullAddress})
                        </option>
                      ))}
                    <DeliveryAddressesNew
                      asOption
                      asOptionShow={asOptionShow}
                      setAsOptionShow={setAsOptionShow}
                      setAddresses={setAddresses}
                      setNewDeliverAddressAsSelected={
                        setNewDeliverAddressAsSelected
                      }
                      t={t}
                    />
                  </Form.Select>
                </Col>
              </Row>

              <Row className='mb-0'>
                <Col>{t('creditInfo.lbl_PaymentTerms')}:</Col>
                <Col className='text-start' lg={5}>
                  <strong>
                    {creditInfo.paymentTerm !== undefined &&
                    creditInfo.paymentTerm !== null
                      ? creditInfo.paymentTerm
                      : orderProp.paymentTerm ?? ''}
                  </strong>
                </Col>
              </Row>

              <Row className='mb-0'>
                <Col>{t('creditInfo.lbl_DeliveryTerms')}:</Col>
                <Col className='text-start' lg={5}>
                  <strong>
                    {creditInfo.deliveryTerm !== undefined &&
                    creditInfo.deliveryTerm !== null
                      ? creditInfo.deliveryTerm
                      : orderProp.deliveryTerm ?? ''}
                  </strong>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className='my-5'>
            <DataTable
              noDataComponent={
                <span>{t('common.lbl_ThereAreNoRecordsToDisplay')}</span>
              }
              columns={columns}
              data={orderProp.rows}
            />
          </Row>

          <Row className='justify-content-between'>
            <Col lg={5}>
              <Form.Group className='mb-3' controlId='comments'>
                <Form.Label>{t('common.lbl_Comments')}</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  placeholder={t('common.plh_EnterComments')}
                  onChange={(event) => setComment(event.target.value)}
                  value={comment}
                />
              </Form.Group>
            </Col>

            <Col lg={3}>
              <Row className='mt-3'>
                <Col>
                  <h3 className='text-end'>{t('common.lbl_TotalSum')}:</h3>
                </Col>
                <Col>
                  <h3 className='text-end'>
                    {useFormatMonetaryValue(
                      orderProp.currencyCode,
                      orderProp.totalSum
                    )}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col className='text-end'>
                  {t('common.inf_TotalPriceSubtext')}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='discardButtonTooltip' hidden={canOrder}>
                    {cannotOrderExplanation}
                  </Tooltip>
                }
              >
                <span>
                  <Button
                    disabled={!canOrder || isLoading}
                    onClick={handleDiscard}
                    className='btn-outline-danger '
                  >
                    <Trash className='icon-ez-red' /> {t('common.btn_Discard')}
                  </Button>
                </span>
              </OverlayTrigger>
            </Col>
            <Col>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='orderButtonTooltip' hidden={canOrder}>
                    {cannotOrderExplanation}
                  </Tooltip>
                }
              >
                <span className='float-end'>
                  <Button
                    disabled={!canOrder || isLoading}
                    onClick={handleOrder}
                    className='btn btn-eleczap float-end'
                  >
                    <CartFill /> {t('documents.btn_Order')}
                  </Button>
                </span>
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

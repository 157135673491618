import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { MouseEvent } from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export const ProductListGuide = () => {
  const { t } = useTranslation(['components', 'introGuides']);

  const handleStartTour = (event: MouseEvent) => {
    introJs()
      .setOptions({
        scrollToElement: false,
        nextLabel: t('common.btn_Next', { ns: 'components' }),
        prevLabel: t('common.btn_Back', { ns: 'components' }),
        steps: [
          {
            title: t('productListGuide.hd_ProductList', {
              ns: 'introGuides',
            }),
            intro: t('productListGuide.inf_ProductList', {
              ns: 'introGuides',
            }),
            tooltipClass: 'introjs-initial-tooltip',
          },
          {
            element: '.product-class-list:first-of-type',
            title: t('productListGuide.hd_PricesAndAvailability', {
              ns: 'introGuides',
            }),
            intro: t('productListGuide.inf_PricesAndAvailability', {
              ns: 'introGuides',
            }),
          },
          {
            element: '.product-list-col-in-stock',
            title: t('productListGuide.hd_InStock', {
              ns: 'introGuides',
            }),
            intro: t('productListGuide.inf_InStock', {
              ns: 'introGuides',
            }),
          },
          {
            element: '.product-list-col-prices.col',
            title: t('productListGuide.hd_PriceSteps', {
              ns: 'introGuides',
            }),
            intro: t('productListGuide.inf_PriceSteps', {
              ns: 'introGuides',
            }),
          },
          {
            element: '.product-list-col-add-to-project.col',
            title: t('productListGuide.hd_Quantity', {
              ns: 'introGuides',
            }),
            intro: t('productListGuide.inf_Quantity', {
              ns: 'introGuides',
            }),
          },
        ],
      })
      .start();
  };

  return (
    <div>
      <QuestionCircle
        onClick={handleStartTour}
        size={40}
        className='btn-floating-help me-3'
      />
    </div>
  );
};
